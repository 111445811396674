import React, { useCallback } from "react";
import { Formik, Form, FieldArray } from "formik";
import { Card, Table } from "react-bootstrap";
import TableGroup from "components/formik/TableGroup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as companyAPI from "api2/companies";
import { handleFormErrors } from "api/errors";
import useAsync from "hooks/useAsync";
import { Loader } from "../../../../../components/ui/loaders";
import { SaveButton } from "../../utils";

function FortnoxIncorrectProductsTable({ companyId, agencyId, reloadStep }) {
  const { t } = useTranslation("ci");

  const validationSchema = yup.object().shape({
    products: yup.array().of(
      yup.object().shape({
        ArticleNumber: yup.string().nullable(),
        Description: yup.string().required(),
        Type: yup.string().nullable(),
        SalesPrice: yup.number().nullable(),
        VAT: yup.number().nullable(),
        Unit: yup.string().nullable(),
        Active: yup.boolean().nullable(),
      })
    ),
  });

  const dataSource = useCallback(
    (cancelToken) => companyAPI.onboardings.steps.fortnoxDataProductsRecheck(companyId, agencyId, { cancelToken }),
    [companyId, agencyId]
  );
  const [{ data, loading }, { reload }] = useAsync(dataSource, []);

  if (loading) {
    return <Loader />;
  }

  if (data?.incorrect_count === 0) return null;

  return (
    <Formik
      initialValues={{ products: data.incorrect }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        companyAPI.onboardings.steps
          .fortnoxDataProductsUpdate(companyId, agencyId, values.products)
          .then(() => {
            toast.success(t("msg:updated"));
            reload();
            reloadStep();
          })
          .catch((error) => {
            handleFormErrors(error, setErrors);
          });
        setSubmitting(false);
      }}
    >
      {({ values, isSubmitting, errors, touched }) => (
        <Form>
          <Card.Body key="products" className="mb-1">
            <Card.Title>{t("navigation:products")}</Card.Title>
            <FieldArray name="products">
              {() => (
                <>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>{t("articleNumber")}</th>
                        <th>{t("common:name")}</th>
                        <th>{t("common:type")}</th>
                        <th>{t("common:money.unitPrice")} (SEK)</th>
                        <th>{t("common:money.vat")}</th>
                        <th>{t("ci:unit")}</th>
                        <th>{t("common:statuses.active")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.products && values.products.length > 0 ? (
                        values.products.map((product, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <TableGroup.Input name={`products[${index}].ArticleNumber`} />
                              <TableGroup.Input name={`products[${index}].Description`} required />
                              <TableGroup.Input name={`products[${index}].Type`} disabled />
                              <TableGroup.Input name={`products[${index}].SalesPrice`} />
                              <TableGroup.Input name={`products[${index}].VAT`} />
                              <TableGroup.Input name={`products[${index}].Unit`} />
                              <TableGroup.Checkbox name={`products[${index}].Active`} label="" />
                            </tr>
                            <TableGroup.RowErrors errors={product.errors} cols={7} />
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" className="text-center">
                            {t("common:noResultsFound")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <SaveButton disabled={isSubmitting} />
                </>
              )}
            </FieldArray>
          </Card.Body>
        </Form>
      )}
    </Formik>
  );
}

export default FortnoxIncorrectProductsTable;
