import React, { useContext, useEffect } from "react";
import { Form, Formik, useFormikContext } from "formik";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";

import { FormGroup } from "components/formik";
import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import "./PreviousSystemStep.scss";
import {
  OnboardingDispatchContext,
  OnboardingStateContext,
} from "pages/companies/OnboardingWizard/provider/OnboardingProvider";
import { withInitialAsync } from "hooks/useAsync";
import * as companyAPI from "api2/companies";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { confirmExecute } from "components/modals/ConfirmModal";
import { HintButton } from "components/ui/buttons";

function PreviousSystemStep({ companyId, agencyId, initialLoading }) {
  const { prevSystem: prevSystemInitial } = useContext(OnboardingStateContext);
  const { t } = useTranslation("company");
  const { fetchDetails } = useContext(OnboardingDispatchContext);
  const systemOptions = [
    { value: "fortnox", label: "Fortnox" },
    { value: "other", label: t("onboarding.otherSystem") },
  ];

  useEffect(() => {
    if (!initialLoading) {
      fetchDetails();
    }
  }, [initialLoading, fetchDetails]);

  const formikProps = {
    initialValues: {
      previous_system: prevSystemInitial || "fortnox",
      is_reconciled_before_transfer: !!prevSystemInitial,
      is_ledgers_checked: !!prevSystemInitial,
      fortnox_full_access: !!prevSystemInitial,
    },
    onSubmit: (values) => {
      if (values.previous_system === "fortnox") {
        return companyAPI.onboardings.steps
          .previousSystemFortnoxPath(companyId, agencyId)
          .then((response) => {
            window.location = response.data.authorization_url;
          })
          .catch((error) => {
            if (error.data.__all__) {
              toast.error(error.data.__all__);
            }
          });
      }
      return companyAPI.onboardings.steps
        .previousSystemOtherSystemPath(companyId, agencyId)
        .then(() => {
          return fetchDetails(true);
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          }
        });
    },
  };

  const onRollbackSuccess = () => {
    return fetchDetails(false);
  };

  const getRightComponent = (isSubmitting, handleSubmit) => {
    if (!prevSystemInitial) {
      return <NextButton disabled={isSubmitting} onClick={handleSubmit} />;
    }
    return <RollbackButton companyId={companyId} agencyId={agencyId} onSuccess={onRollbackSuccess} />;
  };

  return (
    <Formik {...formikProps}>
      {({ isSubmitting, handleSubmit }) => {
        return (
          <Card className="previous-system-step" data-testid="previous-system-step">
            <Card.Body>
              <StepHeader rightComponent={getRightComponent(isSubmitting, handleSubmit)} />
            </Card.Body>
            <Card.Body className="btn-toggle-grey">
              <CompanyInformationForm systemOptions={systemOptions} />
            </Card.Body>
          </Card>
        );
      }}
    </Formik>
  );
}

function RollbackButton({ companyId, agencyId, onSuccess }) {
  const { t } = useTranslation("common");

  const handle = async () => {
    const confirmed = await confirmExecute(t("company:onboarding.confirmRollback"));
    if (confirmed) {
      companyAPI.onboardings.steps
        .previousSystemRollback(companyId, agencyId)
        .then(() => {
          onSuccess();
        })
        .catch(() => {});
    }
  };

  return (
    <Button variant="red" onClick={handle}>
      <i className="fe-refresh-ccw mr-1" />
      {t("actions.rollback")}
    </Button>
  );
}

function NextButton({ onClick }) {
  const {
    values: { previous_system, fortnox_full_access, is_reconciled_before_transfer, is_ledgers_checked },
  } = useFormikContext();
  const { t } = useTranslation("company");
  if (previous_system === "fortnox") {
    return (
      <Button
        size="lg"
        variant="blue"
        onClick={onClick}
        disabled={!fortnox_full_access || !is_reconciled_before_transfer}
      >
        {t("onboarding.connectFortnox")} <i className="fe-chevron-right" />
      </Button>
    );
  }
  return (
    <Button size="lg" variant="blue" onClick={onClick} disabled={!is_ledgers_checked || !is_reconciled_before_transfer}>
      {t("onboarding.connectOther")} <i className="fe-chevron-right" />
    </Button>
  );
}

function CompanyInformationForm({ systemOptions }) {
  const {
    values: { previous_system },
  } = useFormikContext();

  const { t } = useTranslation("company");
  return (
    <Form>
      <Row>
        <Col>
          <FormGroup.InlineOptionsSelect
            name="previous_system"
            label={
              <>
                {t("onboarding.system")}
                <HintButton
                  className="company-information-step__popover"
                  placement="right-end"
                  hint={t("onboarding.systemTooltip")}
                />
              </>
            }
            options={systemOptions}
          />
        </Col>
        <Col>
          <FormGroup.LabeledInlineYesNoRadio
            name="is_reconciled_before_transfer"
            label={t("onboarding.isReconciledBeforeTransfer")}
          />
        </Col>
        {previous_system === "other" && (
          <Col>
            <FormGroup.LabeledInlineYesNoRadio name="is_ledgers_checked" label={t("onboarding.isLedgersChecked")} />
          </Col>
        )}
        {previous_system === "fortnox" && (
          <Col>
            <FormGroup.LabeledInlineYesNoRadio
              name="fortnox_full_access"
              label={
                <>
                  {t("onboarding.hasFullFortnoxAccess")}
                  <HintButton
                    className="company-information-step__popover"
                    placement="top-end"
                    hint={t("onboarding.fortnox.needAdminAccess")}
                  />
                </>
              }
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col className="mt-2">
          {previous_system === "fortnox" && <Alert variant="warning">{t("onboarding.fortnox.oneTimeInfo")}</Alert>}
        </Col>
      </Row>
    </Form>
  );
}

const EnhancedPreviousSystemStep = withInitialAsync(
  PreviousSystemStep,
  ({ companyId, agencyId }) => {
    return React.useCallback(
      (cancelToken) => companyAPI.onboardings.steps.previousSystem(companyId, agencyId, { cancelToken }),
      [companyId, agencyId]
    );
  },
  {},
  true
);

export default EnhancedPreviousSystemStep;
