import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Button } from "react-bootstrap";

import BTable from "components/tables/btable";
import { truncateText } from "utils/text";
import { formatMoney } from "utils/money";
import Filters from "./GlobalDraftSIFilters";
import Actions from "./GlobalDraftSIActions";
import useTable from "../btable/useTable";

function GlobalDraftSITable({ selectCompany }) {
  const { t } = useTranslation("si");
  const { data } = useTable();
  const strippedData = data.map((item) => [item.id, item.company_id]);

  const goToEditPage = useCallback(
    (companyId, rowId) => {
      sessionStorage.setItem("consult-drafts", JSON.stringify(strippedData));
      selectCompany(companyId, `/consult/supplier-invoices/${rowId}/drafts`);
    },
    [strippedData, selectCompany]
  );

  const headers = useMemo(
    () => [
      {
        field: "company__name",
        label: t("common:companyName"),
        render: (values) => {
          return (
            <Button variant="link" onClick={() => goToEditPage(values.company_id, values.id)}>
              {values.company_name}
            </Button>
          );
        },
      },
      {
        field: "supplier_name",
        label: t("common:supplier"),
        sortField: "supplier_snapshot__name",
        render: (values) =>
          values.supplier_name ? (
            <span title={values.supplier_name}>{truncateText(values.supplier_name, 20)}</span>
          ) : (
            "-"
          ),
      },
      {
        field: "amount_sek",
        label: t("common:money.amount"),
        className: "text-right",
        render: (value) => `${formatMoney(value.amount)} ${value.amount_currency}`,
      },
      {
        field: "booking_date",
        label: t("common:dates.invoiceDate"),
      },
      {
        field: "due_date",
        label: t("common:dates.dueDate"),
      },
    ],
    [t, goToEditPage]
  );

  const getRowProps = (row) => {
    const statusClass = row.sub_status || "draft";
    return {
      className: cx(statusClass, { "has-message": !!row.comments.length }),
    };
  };

  return (
    <div id="supplier-invoice-table">
      <BTable
        headers={headers}
        selectable={false}
        FiltersComponent={<Filters />}
        renderActions={(row) => <Actions row={row} goToEditPage={goToEditPage} />}
        getRowProps={getRowProps}
      />
    </div>
  );
}

export default GlobalDraftSITable;
