import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { addDays, isAfter, isBefore } from "date-fns";
import { FormGroup } from "components/formik";
import { confirmExecute } from "components/modals/ConfirmModal";

export function YearForm({ data, onStart }) {
  const { t } = useTranslation("company");

  const financialYears = data.fortnox_financial_years;
  const [defaultFYearFrom, defaultFYearTo] = (() => {
    if (financialYears.length === 1) {
      return [financialYears[0], financialYears[0]];
    }
    if (financialYears.length > 1) {
      return [financialYears[financialYears.length - 3], financialYears[financialYears.length - 1]];
    }
    return [null, null];
  })();

  const startImport = ({ fYear_from, fYear_to }) => {
    const financialYearsRange = financialYears
      .filter(({ value, from, to }) => {
        return value === fYear_from.value || (isAfter(from, fYear_from.from) && isBefore(to, addDays(fYear_to.to, 1)));
      })
      .map((fYear) => fYear.value);

    onStart(financialYearsRange);
  };

  const formikProps = {
    initialValues: {
      fYear_from: defaultFYearFrom || null,
      fYear_to: defaultFYearTo || null,
    },
    onSubmit: async (values) => {
      const isCashMethod = Object.values(values).some((value) => value.method === "CASH");

      if (isCashMethod) {
        const answer = await confirmExecute(t("onboarding.fortnox.importWarning"));
        if (!answer) return;
      }

      startImport(values);
    },
    validationSchema: yup.object().shape({
      fYear_from: yup.object().nullable().required(),
      fYear_to: yup.object().nullable().required(),
    }),
  };

  const allowedFinancialYearTo = (fYear1) => {
    return financialYears.filter((_fYear) => {
      if (!fYear1) {
        return false;
      }
      return _fYear.value === fYear1.value || isAfter(_fYear.from, fYear1.to);
    });
  };

  const onFYear1Selected = (selected, setValues) => {
    setValues({ fYear_to: null, fYear_from: selected });
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors, setValues }) => {
        const allowedTo = allowedFinancialYearTo(values.fYear_from);
        return (
          <Form id="importFortnoxForm" className="fortnox-control">
            <Row>
              <Col xl={3}>
                <FormGroup.SimpleSelect
                  name="fYear_from"
                  options={financialYears}
                  onChange={(selected) => onFYear1Selected(selected, setValues)}
                  required
                  label={`${t("common:from")} - ${t("common:financialYear")}`}
                />
              </Col>
              <Col xl={3}>
                <FormGroup.SimpleSelect
                  name="fYear_to"
                  isDisabled={!values.fYear_from}
                  options={allowedTo}
                  required
                  label={`${t("common:to")} - ${t("common:financialYear")}`}
                />
              </Col>
              <Col xl={3}>
                <Button
                  type="submit"
                  className="fortnox-control__start"
                  disabled={data.import_status === "in_progress"}
                >
                  <i className="fe-download-cloud" /> {t("onboarding.fortnox.start")}
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}

export default YearForm;
