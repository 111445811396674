import { Col, Modal, Row, Table } from "react-bootstrap";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DraggableModalDialog } from "../DraggableModalDialog";

function KYCModal({ title, modalData, handleClose, withReportDate }) {
  const { t } = useTranslation();
  const [data, setData] = useState(modalData);

  const initialHeaders = useMemo(
    () => [
      {
        field: "name",
        label: t("company:customerName"),
        canSort: true,
        width: withReportDate ? "40%" : "50%",
        render: (values) => values.name,
      },
      {
        field: "manager",
        label: t("common:customerManager"),
        canSort: true,
        width: withReportDate ? "40%" : "50%",
        render: (values) => values.manager,
      },
    ],
    [t, withReportDate]
  );
  if (withReportDate) {
    initialHeaders.push({
      field: "report_date",
      label: t("officeSupport:ReportDate"),
      canSort: true,
      width: "20%",
      render: (values) => values.report_date,
    });
  }

  const [headers, setHeaders] = useState(
    initialHeaders.map((header) => ({
      isSorted: false,
      isSortedDesc: false,
      ...header,
    }))
  );

  function onSortPress(field, index) {
    let isSorted = false;
    let isSortedDesc = false;
    setHeaders(
      headers.map((header, hi) => {
        if (index === hi) {
          isSorted = !header.isSorted || header.isSortedDesc;
          isSortedDesc = isSorted && !header.isSortedDesc;
          return {
            ...header,
            isSorted,
            isSortedDesc,
          };
        }
        return { ...header, isSorted: false, isSortedDesc: undefined };
      })
    );
    const originalData = modalData.map((x) => x);
    const sortDirection = isSortedDesc ? -1 : 1;
    setData(
      isSorted ? originalData.sort((a, b) => (a[field] > b[field] ? sortDirection : -sortDirection)) : originalData
    );
  }

  const SortIcon = useCallback(({ header }) => {
    if (header.isSorted) {
      if (header.isSortedDesc) {
        return <i className="fas fa-sort-down" />;
      }
      return <i className="fas fa-sort-up" />;
    }
    return <i className="fas fa-sort" />;
  }, []);

  return (
    <Modal scrollable show onHide={handleClose} size="lg" className="kyc-modal" dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-2">
          <Col>
            <Table size="sm" className="table-bordered-cells">
              <thead>
                <tr>
                  {headers.map((header, i) => (
                    <th key={`header-${i}`} style={{ width: header.width }}>
                      <div className="sortable" onClick={() => onSortPress(header.field, i)}>
                        {header.label}
                        <SortIcon header={header} />
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((customer, i) => (
                  <tr key={`row-${i}`} className="text-center">
                    <td className="text-left">{customer.name}</td>
                    <td className="text-left">{customer.manager}</td>
                    {withReportDate && <td className="text-left">{customer.report_date}</td>}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default KYCModal;
