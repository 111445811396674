import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function SubmitButton({ isSubmitting, title = "actions.save", disabled = false, icon = "", iconAppend, ...props }) {
  const { t } = useTranslation("common");
  return (
    <Button type="submit" variant="blue" {...props} disabled={isSubmitting || disabled}>
      {isSubmitting ? (
        <span>{t("actions.processing")}...</span>
      ) : (
        <span>
          {!iconAppend && icon && <i className={`fas ${icon} mr-1`} />}
          <span>{t(title)}</span>
          {iconAppend && icon && <i className={`fas ${icon} ml-1`} />}
        </span>
      )}
    </Button>
  );
}

export { SubmitButton };
export default SubmitButton;
