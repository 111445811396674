import { Col, Row } from "react-bootstrap";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import { CompanyLogoForm } from "components/forms/company-settings-forms";
import * as companyAPI from "api2/companies";
import { withInitialAsync } from "hooks/useAsync";
import { codeInList } from "components/perms";
import { CompanySettingStateContext } from "state/providers/CompanySettingProvider";
import { codesForCompanySettings } from "components/perms/PermCodes";

function LogoFormPage({ data: item }) {
  const { companyId } = useParams();
  const { userPermCodes } = useContext(CompanySettingStateContext);
  const canEditLogo = codeInList(codesForCompanySettings.edit, userPermCodes);

  return (
    <Row>
      <Col>
        <CompanyLogoForm companyId={companyId} currentLogo={item.logo} canEditLogo={canEditLogo} />
      </Col>
    </Row>
  );
}

const EnhancedLogoFormPage = withInitialAsync(
  LogoFormPage,
  () => {
    const { companyId } = useParams();
    return React.useCallback((cancelToken) => companyAPI.logoDetail(companyId, { cancelToken }), [companyId]);
  },
  { logo: null },
  true
);

export default EnhancedLogoFormPage;
