import React, { useContext } from "react";

import "./BankList.scss";
import danskeLogo from "assets/images/banks/danske.png";
import handelLogo from "assets/images/banks/handel.png";
import nordeaLogo from "assets/images/banks/nordea.png";
import sebLogo from "assets/images/banks/seb.png";
import swedbankLogo from "assets/images/banks/swedbank.png";
import lsLogo from "assets/images/banks/ls.png";
import sparSydLogo from "assets/images/banks/sparbanken_syd.png";
import { BankConnectionContext } from "../../../state/providers/BankConnectionProvider";

const banks = [
  {
    bicFi: "ESSESESS",
    name: "Skandinaviska Enskilda Banken AB",
    logoUrl: sebLogo,
  },
  {
    bicFi: "HANDSESS",
    name: "Handelsbanken",
    logoUrl: handelLogo,
  },
  {
    bicFi: "SWEDSESS",
    name: "Swedbank",
    logoUrl: swedbankLogo,
  },
  {
    bicFi: "NDEASESS",
    name: "Nordea Bank AB",
    logoUrl: nordeaLogo,
  },
  {
    bicFi: "DABASESX",
    name: "Danske Bank A/S",
    logoUrl: danskeLogo,
  },
  {
    bicFi: "ELLFSESS",
    name: "Länsförsäkringar",
    logoUrl: lsLogo,
  },
  // {
  //   bicFi: "SPSDSE23",
  //   name: "Sparbanken Syd",
  //   logoUrl: sparSydLogo,
  // },
];

function BankItem({ item, onSelect }) {
  return (
    <div className="bank-item" onClick={() => onSelect(item.bicFi)}>
      <img src={item.logoUrl} alt={item.name} title={item.name} />
      <i className="fe-chevron-right" />
    </div>
  );
}

function BankList({ companyId }) {
  const { onBankSelection } = useContext(BankConnectionContext);

  const betaBankSpar = {
    bicFi: "SPSDSE23",
    name: "Sparbanken Syd",
    logoUrl: sparSydLogo,
  };

  return (
    <div className="bank-list">
      {banks.map((item) => (
        <BankItem key={item.bicFi} item={item} onSelect={onBankSelection} />
      ))}
      {(companyId === 878 || companyId === 6660) && (
        <BankItem key={betaBankSpar.bicFi} item={betaBankSpar} onSelect={onBankSelection} />
      )}
    </div>
  );
}

export default BankList;
