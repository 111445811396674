import React from "react";
import { useTranslation } from "react-i18next";
import { FieldArray, Form, Formik } from "formik";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { toast } from "react-toastify";

import { FormGroup, TableGroup } from "components/formik";
import ConditionalOverlayTrigger from "components/ui/OverlayTriggers/ConditionalOverlayTrigger";
import { SubmitButton, TooltipActionButton } from "components/ui/buttons";
import * as companyAPI from "api2/companies";
import * as options from "api/options";

function AnnualReportForm({ companyId, reportData }) {
  const { t } = useTranslation("company");
  const auditorTitles = options.auditorTitles.asList().map((title) => ({ ...title, value: parseInt(title.value, 10) }));
  const annualRoles = options.annualRoles.asList().map((role) => ({ ...role, value: parseInt(role.value, 10) }));

  const formikProps = {
    initialValues: {
      ...reportData,
      auditor: {
        ...reportData.auditor,
        haveAuditor: `${reportData.auditor.haveAuditor}`,
        title: options.auditorTitles.getOption(reportData.auditor.title || 1),
      },
      members: reportData.members.map((member) => ({
        ...member,
        role: options.annualRoles.getOption(member.role),
      })),
    },
    onSubmit: async (values, { setErrors, resetForm }) => {
      await companyAPI.annualReports
        .update(companyId, {
          ...values,
          auditor: {
            ...reportData.auditor,
          },
          members: values.members.map((member) => ({
            ...member,
            role: member.role.value,
          })),
        })
        .then((response) => {
          resetForm({ values });
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ values, touched, isSubmitting }) => {
        return (
          <Form>
            <Card className="pt-2">
              <Card.Body>
                <Card.Title>{t("boardMembers")}</Card.Title>
                <Card.Subtitle className="text-muted">{t("boardSubtitle")}</Card.Subtitle>
                <FieldArray
                  name="members"
                  render={({ remove, push }) => (
                    <Table bordered name="transactions">
                      <thead>
                        <tr>
                          <th>
                            <Button
                              variant="info"
                              size="xs"
                              onClick={() =>
                                push({
                                  firstName: "",
                                  lastName: "",
                                  role: annualRoles[0],
                                })
                              }
                            >
                              {t("addMember")}
                            </Button>
                          </th>
                          <th>{t("common:contact.firstName")}</th>
                          <th>{t("common:contact.lastName")}</th>
                          <th style={{ width: "20%" }}>{t("common:role")}</th>
                          <th style={{ width: 70 }} />
                        </tr>
                      </thead>
                      <tbody>
                        {values.members.map((member, idx) => (
                          <tr key={`m.${idx}`}>
                            <td>
                              {t("representative")} {idx + 1}
                            </td>
                            <TableGroup.Input name={`members[${idx}].firstName`} />
                            <TableGroup.Input name={`members[${idx}].lastName`} />
                            <TableGroup.SimpleSelect name={`members[${idx}].role`} options={annualRoles} />
                            <td>
                              <TooltipActionButton
                                size="xs"
                                icon="fas fa-trash"
                                variant="red"
                                text={t("common:actions.remove")}
                                onClick={() => remove(idx)}
                              />
                            </td>
                          </tr>
                        ))}
                        {!values.members.length && (
                          <tr>
                            <td colSpan={5}>{t("common:noResultsFound")}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  )}
                />
                <Card.Title>{t("auditor")}</Card.Title>
                <Row>
                  <Col>
                    <ConditionalOverlayTrigger>
                      <FormGroup.LabeledRadio
                        title={t("hasAuditor")}
                        name="auditor.haveAuditor"
                        options={[
                          { label: t("common:actions.yes"), value: "1" },
                          { label: t("common:actions.no"), value: "0" },
                        ]}
                        disabled
                      />
                    </ConditionalOverlayTrigger>
                  </Col>
                </Row>
                {values.auditor.haveAuditor === "1" && (
                  <Row>
                    <Col>
                      <ConditionalOverlayTrigger placement="bottom">
                        <FormGroup.Input label={t("auditAgencyName")} name="auditor.name" disabled />
                      </ConditionalOverlayTrigger>
                    </Col>
                    <Col>
                      <ConditionalOverlayTrigger placement="bottom">
                        <FormGroup.Input label={t("common:contact.firstName")} name="auditor.firstName" disabled />
                      </ConditionalOverlayTrigger>
                    </Col>
                    <Col>
                      <ConditionalOverlayTrigger placement="bottom">
                        <FormGroup.Input label={t("common:contact.lastName")} name="auditor.surname" disabled />
                      </ConditionalOverlayTrigger>
                    </Col>
                    <Col>
                      <ConditionalOverlayTrigger placement="bottom" useSpan>
                        <FormGroup.SimpleSelect
                          label={t("common:role")}
                          name="auditor.title"
                          disabled
                          options={auditorTitles}
                        />
                      </ConditionalOverlayTrigger>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <FormGroup.Input label={t("about")} name="annual_report_information" as="textarea" rows={3} />
                  </Col>
                </Row>
                <Card.Title>{t("overview")}</Card.Title>
                <Row>
                  <Col xl={12}>
                    <FormGroup.Input label={t("aboutCompany")} name="aboutCompany" as="textarea" rows={3} />
                  </Col>
                  <Col xl={12}>
                    <FormGroup.Input label={t("eventsDuring")} name="eventsDuring" as="textarea" rows={3} />
                  </Col>
                  <Col xl={12}>
                    <FormGroup.Input label={t("eventsAfter")} name="eventsAfter" as="textarea" rows={3} />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <SubmitButton isSubmitting={isSubmitting} />
              </Card.Footer>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default AnnualReportForm;
