import React, { createContext } from "react";
import axios from "axios";
import { formatISO, isDate } from "date-fns";
import { savedFilters } from "state/providers/UserProvider";

const initialState = {
  loading: false,
  data: [],
  selected: {},
  pagination: {
    page: 1,
    count: 0,
    pageSize: 10,
    hasNext: false,
    hasPrevious: false,
  },
  responseHeaders: {},
  filters: {
    term: "",
    ordering: "",
  },
  counter: 1, // used to force reload table
};

function reducer(state, action) {
  switch (action.type) {
    case "DATA_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "DATA_LOADED": {
      const { data, totalItems, responseHeaders } = action.payload;
      const count = Math.ceil(totalItems / state.pagination.pageSize);
      return {
        ...state,
        data,
        responseHeaders,
        pagination: {
          ...state.pagination,
          count,
          hasNext: state.pagination.page < count,
          hasPrevious: state.pagination.page > 1,
        },
        loading: false,
      };
    }
    case "DATA_ERROR":
      return {
        ...state,
        data: [],
        selected: {},
        // pagination: { // trigger reload on error twice
        //   page: 1,
        //   count: 0,
        //   hasNext: false,
        //   hasPrevious: false,
        // },
        loading: false,
      };
    case "SET_PAGE":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload,
        },
      };
    case "SET_FILTERS":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 1,
        },
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case "UPDATE_ROW": {
      const newData = state.data.map((item) => {
        if (item[action.idKey] === action.payload[action.idKey]) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        data: newData,
      };
    }
    case "TOGGLE_EXPAND":
      return {
        ...state,
        data: state.data.map((item, index) => {
          if (index === action.index) {
            return { ...item, expanded: !item.expanded };
          }
          return { ...item, expanded: false };
        }),
      };
    case "TOGGLE_SELECT": {
      const updated = { ...state.selected };
      if (action.row.id in updated) {
        delete updated[action.row.id];
      } else {
        updated[action.row.id] = action.row;
      }
      return {
        ...state,
        selected: updated,
      };
    }
    case "RELOAD":
      return {
        ...state,
        selected: {},
        counter: state.counter + 1,
      };
    case "CLEAN_FILTERS":
      return {
        ...state,
        filters: action.payload,
      };
    case "MOVE_ROW": {
      const { id, direction } = action.payload;
      const currentIndex = state.data.findIndex((item) => item.id === id);
      if (currentIndex === -1) {
        return state;
      }
      const newIndex = direction === "up" ? currentIndex - 1 : currentIndex + 1;
      if (newIndex < 0 || newIndex >= state.data.length) {
        return state;
      }
      const updatedData = [...state.data];
      [updatedData[currentIndex], updatedData[newIndex]] = [updatedData[newIndex], updatedData[currentIndex]];

      return {
        ...state,
        data: [...updatedData],
      };
    }

    default:
      throw new Error();
  }
}

export const TableContext = createContext({
  state: initialState,
  dispatch: () => {},
});

function formatFilters(filters) {
  const newFilters = { ...filters };
  Object.keys(filters).forEach((key) => {
    if (isDate(filters[key])) {
      newFilters[key] = formatISO(filters[key], { representation: "date" });
    }
  });
  return newFilters;
}

const savedSelection = {}; // preserve selection after reload

function resetSavedFilters() {
  // do not reset company table filters
  Object.keys(savedFilters)
    .filter((key) => !["companies", "office-support-companies"].includes(key))
    .forEach((key) => delete savedFilters[key]);
}

function updateAccountingCompaniesFilters(selected) {
  savedFilters.companies = {
    ...savedFilters.companies,
    ...selected,
  };
}

function updateOsCompaniesFilters(selected) {
  savedFilters["office-support-companies"] = {
    ...savedFilters["office-support-companies"],
    ...selected,
  };
}

function TableProvider({ tableId, dataSource, keepTerm = true, pageSize = 25, initialFilters = {}, children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState, (initial) => ({
    ...initial,
    pagination: {
      ...initial.pagination,
      pageSize,
    },
    selected: savedSelection[tableId] || {},
    filters: savedFilters[tableId] || {
      ...initialState.filters,
      ...initialFilters,
    },
  }));

  const [contextValue, setContextValue] = React.useState({
    state,
    dispatch,
    tableId,
    dataSource,
  });

  // Update context value and trigger re-render
  // This patterns avoids unnecessary deep renders
  // https://reactjs.org/docs/context.html#caveats
  React.useEffect(() => {
    setContextValue((contextValue2) => ({
      ...contextValue2,
      state,
    }));
  }, [state]);

  React.useEffect(() => {
    // saving filters
    savedFilters[tableId] = {
      ...state.filters,
      term: keepTerm ? state.filters.term : "",
    };
  }, [tableId, state.filters, keepTerm]);

  React.useEffect(() => {
    // preserve selection
    return function cleanup() {
      savedSelection[tableId] = state.selected;
    };
  }, [tableId, state.selected]);

  React.useEffect(() => {
    const signal = axios.CancelToken.source();
    dispatch({ type: "DATA_LOADING" });
    dataSource(
      {
        page: state.pagination.page,
        ...formatFilters(state.filters),
        page_size: state.pagination.pageSize,
      },
      { cancelToken: signal.token }
    )
      .then((response) => {
        dispatch({
          type: "DATA_LOADED",
          payload: {
            data: response.data,
            totalItems: response.headers["x-pagination-count"] || 0,
            responseHeaders: response.headers,
          },
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          if (
            (error.status === 404 || (error.response && error.response.status === 404)) &&
            state.pagination.page > 1
          ) {
            dispatch({
              type: "SET_PAGE",
              payload: state.pagination.page - 1,
            });
          } else {
            dispatch({ type: "DATA_ERROR" });
          }
        }
      });
    return function cleanup() {
      signal.cancel("aborted");
    };
  }, [dataSource, state.pagination.page, state.pagination.pageSize, state.filters, state.counter]);

  return <TableContext.Provider value={contextValue}>{children}</TableContext.Provider>;
}

export default TableProvider;
export {
  formatFilters,
  resetSavedFilters,
  savedSelection,
  updateAccountingCompaniesFilters,
  updateOsCompaniesFilters,
  savedFilters,
};
