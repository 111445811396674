import _ from "lodash";

import { formatDate, parseDate } from "utils/date";
import * as reportAPI from "api2/reports";
import * as reconAPI from "api2/reconciliation";
import axios from "axios";
import { assetAccountsList, depreciationAccountsList } from "utils/assets";

const notReconciledAccounts = [];

export function matchVerSpec(specifications, verifications) {
  const specsWithVers = specifications.filter((spec) => !!spec.verification);
  let _spec = null;
  verifications.forEach((ver) => {
    _spec = specsWithVers.find((spec) => spec.verification === ver.id);
    ver.spec = _spec ? _spec.key : null;
  });
}

export function getLedgerData(companyId, account, financialYear, start, end, cancelToken) {
  return reportAPI
    .ledgerReport(
      companyId,
      {
        date_from: formatDate(start),
        date_to: formatDate(end),
        financial_year: financialYear,
        account_from: account,
        account_to: account,
        with_open_balance: true,
        hide_empty_accounts: false,
      },
      { cancelToken }
    )
    .then((response) => {
      const ledger = response.data.length ? response.data[0] : null;
      return {
        ...ledger,
        verifications: ledger.verifications.map((ver) => ({
          ...ver,
          baseChecked: ver.is_checked, // this will limit unnecessary db saving if checking not changed}
        })),
      };
    });
}

export async function getReconciliationData(companyId, financialYear, accountNumber, periodStart, periodEnd, config) {
  const recon = await reconAPI.reconciliation.detail(companyId, accountNumber, periodEnd, {
    cancelToken: config.cancelToken,
  });
  const specifications = await reconAPI.specifications.list(companyId, accountNumber, financialYear, periodEnd, config);
  const ledger = await getLedgerData(
    companyId,
    accountNumber,
    financialYear,
    periodStart,
    periodEnd,
    config.cancelToken
  );
  let accountBalance = 0;

  specifications.forEach((spec) => {
    spec.key = spec.id.toString();
  });

  if (ledger) {
    matchVerSpec(specifications, ledger.verifications);
    accountBalance = ledger.ending_balance.sum;
  }
  return {
    reconciliation: recon.data,
    specifications,
    accountBalance,
    ledger,
  };
}

async function getReconciliationAccounts(companyId, fYearId, dateFrom, dateTo) {
  const signal = axios.CancelToken.source();
  if (!notReconciledAccounts.length) {
    await reportAPI
      .balanceReport(
        companyId,
        {
          date_from: formatDate(dateFrom),
          date_to: formatDate(dateTo),
          financial_year: fYearId,
        },
        { cancelToken: signal.token }
      )
      .then((response) => {
        const report = response.data;
        let account = null;
        for (let i = 0; i < report.categories.length; i++) {
          for (let j = 0; j < report.categories[i].child_categories.length; j++) {
            for (let m = 0; m < report.categories[i].child_categories[j].accounts.length; m++) {
              account = report.categories[i].child_categories[j].accounts[m];
              if (!account.is_reconciled) {
                notReconciledAccounts.push(account.number);
              }
            }
          }
        }
      })
      .catch((error) => {});
  }
}

export async function getNextAccountToReconciliation(companyId, fYearId, dateFrom, dateTo, currentNumber, reconciled) {
  await getReconciliationAccounts(companyId, fYearId, dateFrom, dateTo);
  if (reconciled) {
    _.remove(notReconciledAccounts, (n) => n === currentNumber);
  } else if (!notReconciledAccounts.includes(currentNumber)) {
    notReconciledAccounts.push(currentNumber);
  }
  for (let i = 0; i < notReconciledAccounts.length; i++) {
    if (notReconciledAccounts[i] > currentNumber) {
      return notReconciledAccounts[i];
    }
  }
  return notReconciledAccounts.length ? notReconciledAccounts[0] : null;
}

export async function getPrevAccountToReconciliation(companyId, fYearId, dateFrom, dateTo, currentNumber) {
  await getReconciliationAccounts(companyId, fYearId, dateFrom, dateTo);
  for (let i = notReconciledAccounts.length - 1; i >= 0; i--) {
    if (notReconciledAccounts[i] < currentNumber) {
      return notReconciledAccounts[i];
    }
  }
  return notReconciledAccounts.length ? notReconciledAccounts[notReconciledAccounts.length - 1] : null;
}

export function getEmptySpec(documents, lastPeriodDate) {
  return {
    key: _.uniqueId("sp"),
    booking_date: parseDate(lastPeriodDate),
    title: "",
    amount: null,
    documents: documents || [],
    is_deleted: false,
    verification: null,
  };
}

export function mapVerToSpec(verification, specKey) {
  return {
    key: specKey,
    title: verification.title || "",
    booking_date: parseDate(verification.booking_date),
    amount: verification.debit || -verification.credit,
    verification: verification.id,
    verification_number: verification.verification || verification.verification_number,
    is_deleted: false,
    documents: [],
    transaction_id: verification.transaction_id,
    comment: verification.transaction_title || "",
  };
}

// List of accounts for which specification may be automatically created
export const AUTO_SPECIFICATION_ACCOUNTS = [
  ...assetAccountsList,
  ...depreciationAccountsList,
  1510,
  1791,
  2440,
  2441,
  2514,
  1930,
  1931,
  1932,
  1933,
  1934,
  1935,
  1936,
  1937,
  1938,
  1939,
  1940,
  1950,
  1630,
  2610,
  2614,
  2615,
  2617,
  2620,
  2624,
  2625,
  2627,
  2630,
  2634,
  2635,
  2637,
  2640,
  2645,
  2647,
  2710,
  2730,
  2991,
];
