import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import * as options from "api/options";
import { toast } from "react-toastify";

import { useCompanyState } from "hooks/useCompany";
import { ConfirmButton, SubmitButton } from "components/ui/buttons";
import { AllError, FormGroup } from "components/formik";
import * as billectaAPI from "api/billecta";
import { codesForConsultManage, codesForCustomerInvoices } from "components/perms/PermCodes";
import { HasPermCode, PermCodeRequired } from "components/perms";
import { filterActiveCC } from "utils/others";
import { filterProvidedAccounts, getDefaultAccountForRevenue, getListWithAccountsForRevenue } from "./utils";
import { confirmExecute } from "../../modals/ConfirmModal";

function ProductForm({ companyId, product, successCallback, resetOnSuccess = false }) {
  const { t } = useTranslation("ci");
  const vatOptions = options.vatOptions.asList();
  const productTypes = options.productTypes.asList();
  const [showMore, setShowMore] = useState(false);

  const {
    accounts: { asOptions: accountOptions, byId: accountOptionsById },
  } = useCompanyState();
  const [revenuesAccountsOptions, setRevenuesAccountsOptions] = useState(
    accountOptions.filter(filterProvidedAccounts(getListWithAccountsForRevenue(product.VAT, product.ProductType)))
  );
  const canEditAccount = HasPermCode(codesForConsultManage.enabled);

  const {
    costCenters: { byId: centerById, asOptions: centerOptions },
  } = useCompanyState();
  const initial_center = centerById[product.ProductExternalId];
  const listWithAccountsForRevenue = getListWithAccountsForRevenue(product.VAT, product.ProductType);
  const initialAccount =
    product.BookKeepingAccount && listWithAccountsForRevenue.includes(product.BookKeepingAccount)
      ? product.BookKeepingAccount
      : getDefaultAccountForRevenue(product.VAT, product.ProductType);
  const formikProps = {
    initialValues: {
      ...product,
      cost_center: initial_center && initial_center.is_active ? initial_center : null,
      ArticleNumber: product.ArticleNumber || "",
      ProductType: options.productTypes.getOption(product.ProductType) || productTypes[0],
      VAT: options.vatOptions.getOption(product.VAT) || vatOptions[0],
      BookKeepingAccount: accountOptionsById[initialAccount],
    },
    validationSchema: yup.object().shape({
      ArticleNumber: yup.string().nullable(),
      Description: yup.string().required(),
      ProductType: yup.object().required(),
      Units: yup.string().required(),
      VAT: yup.object().required(),
      UnitPrice: yup.number().nullable().required(),
      BookKeepingAccount: yup.object().nullable().notRequired(),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      if (
        (product.ProductPublicId &&
          !(
            product.BookKeepingAccount === 3001 &&
            values.BookKeepingAccount.value === getDefaultAccountForRevenue(values.VAT.value, values.ProductType.value)
          ) &&
          values.BookKeepingAccount.value !== product.BookKeepingAccount) ||
        (product.ProductPublicId && values.VAT.value !== product.VAT)
      ) {
        const answer = await confirmExecute(t("common:confirm.newVatSettingsOnProductUpdate"));
        if (!answer) {
          return;
        }
      }
      await billectaAPI
        .productSave(companyId, {
          ...product,
          ...values,
          ProductType: values.ProductType.value,
          VAT: parseInt(values.VAT.value, 10),
          b_unit_price: values.b_unit_price || null,
          c_unit_price: values.c_unit_price || null,
          d_unit_price: values.d_unit_price || null,
          BookKeepingAccount: values.BookKeepingAccount?.value,
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (successCallback) {
            successCallback({
              ...product,
              ...values,
              ProductExternalId: values.cost_center ? values.cost_center.id : null,
              ProductType: values.ProductType.value,
              BookKeepingAccount: values.BookKeepingAccount?.value,
              VAT: values.VAT.value,
            });
            if (resetOnSuccess) {
              resetForm();
            }
          } else {
            resetForm();
            if (successCallback) {
              successCallback(response.data);
            }
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  async function activeToggle(newProduct) {
    if (newProduct.IsActive) {
      await billectaAPI.productDeactive(companyId, newProduct.ProductPublicId).then((response) => {
        toast.success(t("msg:deactivated"), { autoClose: 2000 });
        if (successCallback) {
          successCallback({ ...newProduct, IsActive: false });
        }
      });
    } else {
      await billectaAPI.productActive(companyId, newProduct.ProductPublicId).then((response) => {
        toast.success(t("msg:activated"), { autoClose: 2000 });
        if (successCallback) {
          successCallback({ ...newProduct, IsActive: true });
        }
      });
    }
  }

  const activeCenters = centerOptions.filter(filterActiveCC);
  const showCostCenters = activeCenters.length !== 0;
  function updateRevenueAccountOptions(vat, productType, setFieldValue) {
    setRevenuesAccountsOptions(
      accountOptions.filter(filterProvidedAccounts(getListWithAccountsForRevenue(vat, productType)))
    );
    setFieldValue("BookKeepingAccount", accountOptionsById[getDefaultAccountForRevenue(vat, productType)]);
  }
  function onVatChange(selected, setFieldValue, values) {
    setFieldValue("Vat", selected);
    updateRevenueAccountOptions(selected.value, values.ProductType.value, setFieldValue);
  }
  function onProductTypeChange(selected, setFieldValue, values) {
    setFieldValue("ProductType", selected);
    updateRevenueAccountOptions(values.VAT.value, selected.value, setFieldValue);
  }
  return (
    <Formik {...formikProps}>
      {({ values, setFieldValue, isSubmitting, errors }) => {
        return (
          <Form noValidate>
            <Row>
              <Col sm={6} xl={3}>
                <FormGroup.SimpleSelect
                  name="ProductType"
                  label={t("common:type")}
                  options={productTypes}
                  onChange={(selected) => onProductTypeChange(selected, setFieldValue, values)}
                  required
                />
              </Col>
              <Col sm={6} xl={3}>
                <FormGroup.Input label={t("articleNumber")} name="ArticleNumber" />
              </Col>
              <Col sm={6} xl={6}>
                <FormGroup.Input label={t("common:description")} name="Description" required />
              </Col>
            </Row>
            <Row>
              <Col sm={4} xl={3}>
                <FormGroup.Input label={`${t("unit")}`} name="Units" helpText={t("helpUnit")} required />
              </Col>
              <Col sm={4} xl={3}>
                <FormGroup.MoneyInput
                  label={`${t("common:money.unitPrice")} (SEK)`}
                  name="UnitPrice"
                  helpText={t("helpPrice")}
                  required
                />
              </Col>
              <Col sm={4} xl={3}>
                <FormGroup.SimpleSelect
                  name="VAT"
                  label={t("common:money.vat")}
                  options={vatOptions}
                  onChange={(selected) => onVatChange(selected, setFieldValue, values)}
                  required
                />
              </Col>
              {showCostCenters && (
                <Col sm={4} xl={3}>
                  <FormGroup.SimpleSelect
                    name="cost_center"
                    label={t("common:costCenter")}
                    options={activeCenters}
                    required={false}
                    isClearable
                  />
                </Col>
              )}
            </Row>
            <button
              type="button"
              className="btn btn-link p-0 mb-3 mt-2"
              onClick={() => setShowMore((prevValue) => !prevValue)}
            >
              <span style={{ color: "#ADADAD" }}>
                {showMore ? t("common:actions.showLessSettings") : t("common:actions.showMoreSettings")}
              </span>{" "}
              <i className={showMore ? "fas fa-chevron-up" : "fas fa-chevron-down"} />
            </button>
            {showMore && (
              <Row>
                <Col>
                  <fieldset>
                    <legend className="font-20">{t("priceGroups")}</legend>
                    <Row>
                      <Col sm={4} xl={3}>
                        <FormGroup.MoneyInput label={`B - ${t("common:money.unitPrice")} (SEK)`} name="b_unit_price" />
                      </Col>
                      <Col sm={4} xl={3}>
                        <FormGroup.MoneyInput label={`C- ${t("common:money.unitPrice")} (SEK)`} name="c_unit_price" />
                      </Col>
                      <Col sm={4} xl={3}>
                        <FormGroup.MoneyInput label={`D - ${t("common:money.unitPrice")} (SEK)`} name="d_unit_price" />
                      </Col>
                      <Col sm={4} xl={3}>
                        <FormGroup.SimpleSelect
                          options={revenuesAccountsOptions}
                          name="BookKeepingAccount"
                          label={t("common:account")}
                          isDisabled={!canEditAccount}
                          filterOptionStartsWith
                        />
                      </Col>
                    </Row>
                  </fieldset>
                </Col>
              </Row>
            )}
            <AllError errors={errors} />
            <hr />
            <PermCodeRequired code={codesForCustomerInvoices.manage}>
              <SubmitButton isSubmitting={isSubmitting} />
              {product.ProductPublicId &&
                (product.IsActive ? (
                  <ConfirmButton
                    variant="orange"
                    label={t("common:actions.deactivate")}
                    confirmMessage={t("confirm.deactivateProduct", {
                      productName: product.Description,
                    })}
                    className="float-right"
                    onClick={() => activeToggle(product)}
                  />
                ) : (
                  <ConfirmButton
                    variant="orange"
                    label={t("common:actions.activate")}
                    confirmMessage={t("confirm.activateProduct", {
                      productName: product.Description,
                    })}
                    className="float-right"
                    onClick={() => activeToggle(product)}
                  />
                ))}
            </PermCodeRequired>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ProductForm;
