import React from "react";
import { Card, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useInitialAsync from "hooks/useInitialAsync";
import * as statsAPI from "api2/stats";
import { Loader } from "components/ui/loaders";
import { formatMoney } from "utils/money";
import { SalaryBarChart } from "../charts";
import { arrayMonthsBack, pad2, StatusBox } from "../helpers";

function StatsSalariesPage() {
  const { t } = useTranslation("reports");
  const { item: data, loading } = useInitialAsync(({ cancelToken }) => statsAPI.admin.salaries({ cancelToken }), {});
  const chartData = arrayMonthsBack(24, { count: 0 });
  if (!loading) {
    data.newSalaries24months.forEach((item) => {
      try {
        chartData[`${item.year}-${pad2(item.month)}`].count = item.value;
      } catch (error) {
        // pass
      }
    });
  }
  if (loading) {
    return <Loader />;
  }
  return (
    <Card>
      <Card.Body>
        <Row>
          <StatusBox label={t("stats.numberOfSalaries")} value={formatMoney(data.numbers.count, 0, 0)} />
          <StatusBox label={t("stats.awaitingSalaries")} value={formatMoney(data.numbers.awaiting_payments, 0, 0)} />
          <StatusBox label={t("stats.numberOfPaid")} value={formatMoney(data.numbers.paid_count, 0, 0)} />
          <StatusBox label={t("stats.sumPaid")} value={formatMoney(data.numbers.paid_sum / 1000, 0, 0)} />
          <StatusBox label={t("stats.avgPaid")} value={formatMoney(data.numbers.paid_avg, 0, 0)} />
          <StatusBox label={t("stats.medianPaid")} value={formatMoney(data.numbers.paid_median, 0, 0)} />
        </Row>
      </Card.Body>
      <Card.Body>
        <SalaryBarChart t={t} chartData={chartData} />
      </Card.Body>
    </Card>
  );
}

export default StatsSalariesPage;
