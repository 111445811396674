import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Alert, Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FieldArray, useFormikContext } from "formik";
import _ from "lodash";
import { TableGroup } from "components/formik";
import { useCompanyState } from "hooks/useCompany";
import { useAuthState } from "hooks/useAuth";
import { formatMoney, roundMoney } from "utils/money";
import { collectionSum } from "utils/list";
import { toast } from "react-toastify";
import { filterActiveCC, filterActiveProjects } from "utils/others";
import { appendAutoAccounts } from "utils/verification";
import {
  filterPeriodisationCostAccountsForSI,
  periodisationAccounts,
  periodisationToogleAccounts,
} from "utils/periodisation";
import "./ManualBookingForm.scss";
import TransactionBalance from "components/modals/VerificationModal/TransactionBalance";
import { BalanceStateContext } from "state/providers/BalanceProvider";

function ManualBookingForm() {
  const {
    values: { amount, vat_amount, account, manual_booking, amount_currency, booking_date },
    setFieldValue,
  } = useFormikContext();
  const {
    accounts: { byId: accountById, asOptions: accountOptions },
    costCenters: { asOptions: centerOptions },
    projects: { asOptions: projectOptions },
  } = useCompanyState();

  const getAmount2640 = () => {
    const exchangeRate = amount_currency.rate || 1;
    return roundMoney(vat_amount * exchangeRate) * (amount < 0 ? -1 : 1);
  };

  const { updateBalances } = useContext(BalanceStateContext);
  const getBooking = useCallback(() => {
    const exchangeRate = amount_currency.rate || 1;
    const amount2440 = -roundMoney(amount * exchangeRate);
    const amount2640 = getAmount2640();
    const newTransactions = [
      ...manual_booking.filter((tran, idx) => {
        if (idx === 0) {
          return false;
        }
        if (idx === 1 && tran.account?.id === 2640) {
          return false;
        }
        return true;
      }),
    ];
    if (amount2640) {
      newTransactions.unshift({
        uid: _.uniqueId("t2"),
        account: accountById[2640],
        amount: amount2640,
        debit: amount2640 > 0 ? amount2640 : "",
        credit: amount2640 < 0 ? Math.abs(amount2640) : "",
      });
    }
    newTransactions.unshift({
      uid: _.uniqueId("t1"),
      account: accountById[2440],
      amount: amount2440,
      debit: amount2440 > 0 ? amount2440 : "",
      credit: amount2440 < 0 ? Math.abs(amount2440) : "",
    });
    if ((newTransactions.length === 1 && !amount2640) || (newTransactions.length === 2 && !!amount2640)) {
      newTransactions.push({ uid: _.uniqueId("t3") });
    }
    setFieldValue("manual_booking", newTransactions);
    updateBalances(newTransactions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, vat_amount, amount_currency.rate, accountById, setFieldValue]);

  useEffect(() => {
    getBooking();
  }, [getBooking]);

  if (account && account.number === 7399) {
    return <Alert variant="warning">Manual booking not possible with account 7399</Alert>;
  }

  const activeProjects = filterActiveProjects(projectOptions, booking_date);

  return (
    <FieldArray
      name="manual_booking"
      render={(props) => (
        <TransactionsArrayForm
          {...props}
          accountOptions={accountOptions}
          centerOptions={centerOptions}
          projectOptions={activeProjects}
          accountById={accountById}
          indexesToDisable={getAmount2640() ? 1 : 0}
        />
      )}
    />
  );
}

function TransactionsArrayForm({
  remove,
  push,
  replace,
  form: { values, setFieldValue, setValues, setFieldError, errors },
  accountOptions,
  projectOptions,
  centerOptions,
  accountById,
  indexesToDisable,
}) {
  const { t } = useTranslation("common");
  const debit = roundMoney(collectionSum(values.manual_booking, "debit"));
  const credit = roundMoney(collectionSum(values.manual_booking, "credit"));
  const { balances, fetchBalance, updateBalances } = useContext(BalanceStateContext);
  const diff = roundMoney(debit - credit);
  const { user } = useAuthState();
  const isConsult = user.is_superuser || user.is_consult;
  const onAccountSelected = useCallback(
    (account, transaction, index) => {
      if (account && !balances[account.number]) {
        fetchBalance(account.number);
      }
      // try to have only 1 extra empty
      const updatedTrans = {
        ...transaction,
        account,
        periodisation_config: null,
        // key: _.uniqueId(`t${index}`),
      };
      // if (!transaction.debit && !transaction.credit) {
      //   updatedTrans.debit = diff < 0 ? Math.abs(diff) : "";
      //   updatedTrans.credit = diff > 0 ? diff : "";
      // }
      replace(index, updatedTrans);
      const lastIndex = values.manual_booking.length;
      if (lastIndex === index + 1) {
        push({
          uid: _.uniqueId(`t${lastIndex + 1}`),
        });
      }
    },
    [replace, push, values.manual_booking.length, balances, fetchBalance]
  );

  const onAmountChange = (account) => {
    if (account) {
      updateBalances(values.manual_booking);
    }
  };

  const ensureDebitOrCredit = useCallback(
    (transaction, index, field) => {
      if (field === "debit") {
        setFieldValue(`manual_booking.${index}.credit`, "");
      } else if (field === "credit") {
        setFieldValue(`manual_booking.${index}.debit`, "");
      }
    },
    [setFieldValue]
  );

  const creditOrDebitBlur = (index, transaction) => {
    if (diff > 0 && !transaction.debit && !transaction.credit) {
      transaction.credit = diff;
    } else if (diff < 0 && !transaction.debit && !transaction.credit) {
      transaction.debit = Math.abs(diff);
    }
    const newTransactions = appendAutoAccounts(transaction, index, values.manual_booking, accountById);
    newTransactions[index] = transaction;
    setFieldValue("manual_booking", newTransactions, false);
  };

  const activeProjects = projectOptions;
  const activeCenter = centerOptions.filter(filterActiveCC);
  const showProjects = activeProjects.length !== 0;
  const showCostCenter = activeCenter.length !== 0;

  function onClickPerTd(field, index) {
    if (field.account.number === 1790) {
      const trans_account_field = `manual_booking[${index}].account`;
      setFieldValue(trans_account_field, accountById[1791]);
      onAccountSelected(accountById[1791], index);
      toast.warning(t("ver:warning.perAccountChanged", { accountNumber: 1791 }));
    }
    if (field.account.number === 2990) {
      const trans_account_field = `manual_booking[${index}].account`;
      setFieldValue(trans_account_field, accountById[2991]);
      onAccountSelected(accountById[2991], index);
      toast.warning(t("ver:warning.perAccountChanged", { accountNumber: 2991 }));
    }
    const periodisation_config_field_name = `manual_booking[${index}].periodisation_config`;
    if (field.periodisation_config === null) {
      setFieldValue(periodisation_config_field_name, {});
    } else {
      setFieldValue(periodisation_config_field_name, null);
    }
  }

  function tdForPerColumn(field, index) {
    if (field.account && periodisationToogleAccounts.includes(field.account.number)) {
      return (
        <td className="text-center periodisation-toogle" onClick={() => onClickPerTd(field, index)}>
          <i className={field.periodisation_config ? "fa fa-toggle-on" : "fa fa-toggle-off"} />
        </td>
      );
    }
    return <td />;
  }
  const formatRowErrors = (rowErrors) => {
    const _errors = {};
    Object.entries(rowErrors).forEach(([key, val]) => {
      if (key === "periodisation_config") {
        Object.entries(val).forEach(([perKey, perVal]) => {
          const readablePerKey = t(`si:manualBooking.periodisation.${perKey}`);
          _errors[perKey] = `${readablePerKey} - ${perVal}`;
        });
      } else {
        const readableKey = t(`si:manualBooking.${key}`);
        _errors[key] = `${readableKey} - ${val}`;
      }
    });
    return _errors;
  };
  const optionsPerCostAccount = useMemo(() => {
    return accountOptions.filter(filterPeriodisationCostAccountsForSI);
  }, [accountOptions]);

  function trForPeriodisationConfig(field, index) {
    if (!field.account || !periodisationAccounts.includes(field.account.id) || !field.periodisation_config) {
      return null;
    }
    return (
      <tr>
        <td colSpan={12}>
          <table className="periodisation-row" width="100%">
            <tbody>
              <tr>
                <td className="label">{t("ver:perStartOn")}</td>
                <TableGroup.DatePicker
                  name={`manual_booking[${index}].periodisation_config.date_start`}
                  required
                  tdProps={{ style: { width: 130, maxWidth: 130 } }}
                />
                <td className="label">{t("common:months")}</td>
                <TableGroup.NumberInput
                  name={`manual_booking[${index}].periodisation_config.months`}
                  required
                  tdProps={{ style: { width: 95, maxWidth: 95 } }}
                  min={1}
                />
                <td className="label">{t("ver:plAccount")}</td>
                <TableGroup.SimpleSelect
                  options={optionsPerCostAccount}
                  name={`manual_booking[${index}].periodisation_config.pl_account_id`}
                  tdProps={{ style: { width: 160, maxWidth: 160 } }}
                />
                <td />
                <td />
                <td />
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  }

  return (
    <Table bordered responsive size="sm" id="manual-booking-form">
      <thead>
        <tr>
          <th style={{ width: 240 }}>{t("account")}</th>
          {showProjects && <th style={{ minWidth: 100 }}>{t("project")}</th>}
          {showCostCenter && <th style={{ minWidth: 100 }}>{t("costCenter")}</th>}
          <th style={{ width: 100 }} className="text-right">
            {t("money.debit")}
          </th>
          <th style={{ width: 100 }} className="text-right">
            {t("money.credit")}
          </th>
          {isConsult && (
            <th style={{ width: 100 }} className="text-right">
              {t("money.balance")}
            </th>
          )}
          <th style={{ width: 40 }} className="text-right">
            PER
          </th>
          <th style={{ width: 50 }} />
        </tr>
      </thead>
      <tbody>
        {values.manual_booking.map((field, index) => (
          <React.Fragment key={field.uid}>
            <tr>
              <TableGroup.SimpleSelect
                options={accountOptions}
                isClearable
                name={`manual_booking[${index}].account`}
                isDisabled={indexesToDisable >= index || !!field.autoParent}
                onChange={(selected) => {
                  onAccountSelected(selected, field, index);
                }}
                filterOptionStartsWith
                error="testError"
              />
              {showProjects && (
                <TableGroup.SimpleSelect
                  options={activeProjects}
                  isDisabled={indexesToDisable >= index || !!field.autoParent}
                  isClearable
                  name={`manual_booking[${index}].project`}
                />
              )}
              {showCostCenter && (
                <TableGroup.SimpleSelect
                  options={activeCenter}
                  isDisabled={indexesToDisable >= index || !!field.autoParent}
                  isClearable
                  name={`manual_booking[${index}].cost_center`}
                />
              )}
              <TableGroup.MoneyCalculationTableInput
                disabled={indexesToDisable >= index || !field.account || !!field.autoParent}
                name={`manual_booking[${index}].debit`}
                onChange={(values2) => ensureDebitOrCredit(field, index, "debit", values2)}
                onBlur={() => {
                  creditOrDebitBlur(index, field);
                  onAmountChange(field.account);
                }}
              />
              <TableGroup.MoneyCalculationTableInput
                disabled={indexesToDisable >= index || !field.account || !!field.autoParent}
                name={`manual_booking[${index}].credit`}
                onChange={(values2) => ensureDebitOrCredit(field, index, "credit", values2)}
                onBlur={() => {
                  creditOrDebitBlur(index, field);
                  onAmountChange(field.account);
                }}
              />
              {isConsult && (
                <td className="text-right pr-1 disabled">
                  {field.account && <TransactionBalance account={field.account.number} />}
                </td>
              )}
              {tdForPerColumn(field, index)}
              <td className="text-center disabled">
                {!(indexesToDisable + 1 >= index) && (
                  <Button size="xs" variant="red" onClick={() => remove(index)}>
                    <i className="fas fa-trash" />
                  </Button>
                )}
              </td>
            </tr>
            {trForPeriodisationConfig(field, index)}
            <TableGroup.RowErrors
              errors={
                errors.manual_booking && errors.manual_booking[index] && formatRowErrors(errors.manual_booking[index])
              }
            />
          </React.Fragment>
        ))}
      </tbody>
      <tfoot>
        <tr className="summary">
          <td colSpan={1 + showCostCenter + showProjects} />
          <td className="text-right">{formatMoney(debit)}</td>
          <td className="text-right">{formatMoney(credit)}</td>
          <td />
        </tr>
        <tr className="summary">
          <td colSpan={2 + showCostCenter + showProjects} className="text-right">
            {t("common:money.deviation")}
          </td>
          <td className="text-right">{formatMoney(diff)}</td>
        </tr>
      </tfoot>
    </Table>
  );
}

export default ManualBookingForm;
