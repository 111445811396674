import React, { useCallback } from "react";
import { Formik, Form, FieldArray } from "formik";
import { Card, Table } from "react-bootstrap";
import TableGroup from "components/formik/TableGroup";
import { Loader } from "components/ui/loaders";
import { useTranslation } from "react-i18next";
import * as companyAPI from "api2/companies";
import { toast } from "react-toastify";
import * as yup from "yup";
import { handleFormErrors } from "api/errors";
import useAsync from "hooks/useAsync";
import PersonalNumber from "personnummer";
import { SaveButton } from "../../utils";

export default function FortnoxIncorrectCustomersTable({ companyId, agencyId, reloadStep }) {
  const { t } = useTranslation("ci");

  const dataSource = useCallback(
    (cancelToken) => companyAPI.onboardings.steps.fortnoxDataCustomersRecheck(companyId, agencyId, { cancelToken }),
    [companyId, agencyId]
  );
  const [{ data, loading }, { reload }] = useAsync(dataSource, []);
  if (loading) {
    return <Loader />;
  }
  const validationSchema = yup.object().shape({
    customers: yup.array().of(
      yup.object().shape({
        CustomerNumber: yup
          .string()
          .required()
          .matches(/^[A-Za-z0-9]+$/, t("company:onboarding.steps.fortnox_errors.customerNumberAlphanumeric")),
        OrganisationNumber: yup
          .string()
          .nullable()
          .test("is-valid-org-number", function (value) {
            const { createError, parent } = this;

            if (value && value.trim() !== "") {
              if (!PersonalNumber.valid(value)) {
                const errorMessage = t("common:errors.invalidPersonalNumber");
                if (!parent.errors) {
                  parent.errors = {};
                }
                parent.errors.OrganisationNumber = errorMessage;
                return createError({ message: errorMessage });
              }
            }
            if (parent.errors && parent.errors.OrganisationNumber) {
              delete parent.errors.OrganisationNumber;
            }
            return true;
          }),
        Email: yup.string().email(t("common:errors.invalidEmail")).nullable(),
        Active: yup.boolean().nullable(),
      })
    ),
  });

  if (data?.incorrect_count === 0) return null;
  return (
    <Formik
      initialValues={{ customers: data.incorrect }}
      enableReinitialize
      onSubmit={(values, { setSubmitting, setErrors }) => {
        companyAPI.onboardings.steps
          .fortnoxDataCustomersUpdate(companyId, agencyId, values.customers)
          .then(() => {
            toast.success(t("msg:updated"));
            reload();
            reloadStep();
          })
          .catch((error) => {
            handleFormErrors(error, setErrors);
          });
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({ values, errors, touched, isSubmitting }) => (
        <Form>
          <Card.Body key="customers" className="mb-1">
            <Card.Title>{t("navigation:customers")}</Card.Title>
            <FieldArray name="customers">
              {() => (
                <>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>{t("customerNumber")}</th>
                        <th>{t("common:name")}</th>
                        <th>{t("common:orgNo")}</th>
                        <th>{t("common:contact.contactEmail")}</th>
                        <th>{t("common:statuses.active")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.customers &&
                        values.customers.length > 0 &&
                        values.customers.map((customer, index) => {
                          const formikCustomerErrors = errors.customers && errors.customers[index];
                          const normalizeErrors = (errorObj) => {
                            const normalized = {};
                            if (errorObj) {
                              Object.keys(errorObj).forEach((key) => {
                                normalized[key] = Array.isArray(errorObj[key]) ? errorObj[key] : [errorObj[key]];
                              });
                            }
                            return normalized;
                          };

                          const mergedErrors = {
                            ...normalizeErrors(customer.errors),
                            ...normalizeErrors(formikCustomerErrors),
                          };

                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <TableGroup.Input name={`customers[${index}].CustomerNumber`} required />
                                <TableGroup.Input name={`customers[${index}].Name`} disabled />
                                <TableGroup.Input name={`customers[${index}].OrganisationNumber`} required />
                                <TableGroup.Input type="email" name={`customers[${index}].Email`} disabled />
                                <TableGroup.Checkbox name={`customers[${index}].Active`} label="" />
                              </tr>
                              <TableGroup.RowErrors errors={mergedErrors} cols={5} />
                            </React.Fragment>
                          );
                        })}
                    </tbody>
                  </Table>
                  <SaveButton disabled={isSubmitting} />
                </>
              )}
            </FieldArray>
          </Card.Body>
        </Form>
      )}
    </Formik>
  );
}
