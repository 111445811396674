import React, { useCallback, useMemo, useState } from "react";
import { psd2 as psd2Api } from "api2/integrations";
import { formatDate } from "utils/date";

const IsoBasketContext = React.createContext(undefined);

const STAGE_INITIAL = "initial";
const STAGE_ISO_SENT = "iso_sent";

function IsoBasketProvider({ companyId, children }) {
  const [loading, setLoading] = useState(false);
  const [bankFileName, setBankFileName] = useState("");
  const [bankUrl, setBankUrl] = useState("");
  const [payments, setPayments] = useState([]);
  const [error, setError] = useState("");
  const [bic, setBic] = useState("");
  const [stage, setStage] = useState(STAGE_INITIAL);
  const reset = useCallback(() => {
    setError("");
    setStage(STAGE_INITIAL);
  }, []);

  const getInitialList = useCallback(
    (verificationIds) => {
      setLoading(true);
      return psd2Api.iso
        .paymentInitiation(companyId, verificationIds)
        .then((response) => {
          if (response.data.bic) {
            setBic(response.data.bic);
          }
          return response;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [companyId]
  );

  const createBasket = useCallback(
    (paymentsData) => {
      setLoading(true);
      setError("");
      return psd2Api.iso
        .create(
          companyId,
          paymentsData.map((item) => ({
            verification: item.id,
            payment_date: formatDate(item.payment_date),
          }))
        )
        .then((response) => {
          if (response.data.error) {
            setError(response.data.error);
            // setStage(STAGE_SUMMARY);
          } else {
            setPayments(paymentsData);
            setStage(STAGE_ISO_SENT);
            setBankFileName(response.data.bank_file_name);
            setBankUrl(response.data.bank_url);
            setLoading(false);
          }
        })
        .catch((response) => {
          if (response.data.__all__) {
            setError(response.data.__all__);
          } else {
            setError("");
          }
          setLoading(false);
          throw response;
        });
    },
    [companyId]
  );

  const stateValue = useMemo(
    () => ({
      companyId,
      bankFileName,
      bankUrl,
      stage,
      loading,
      bic,
      error,
      payments,
      actions: {
        createBasket,
        getInitialList,
        reset,
        setLoading,
      },
    }),
    [
      companyId,
      stage,
      bic,
      loading,
      error,
      createBasket,
      getInitialList,
      reset,
      bankFileName,
      bankUrl,
      setLoading,
      payments,
    ]
  );
  return <IsoBasketContext.Provider value={stateValue}>{children}</IsoBasketContext.Provider>;
}

export { IsoBasketContext, STAGE_ISO_SENT, STAGE_INITIAL };

export default IsoBasketProvider;
