import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

import { BExpandedTable } from "components/tables/btable";
import OfficeEditWidget from "components/forms/agency-settings-forms/OfficeEditWidget";
import Filters from "./OfficeFilters";

function SubOfficeExtendedTable({ agencyId }) {
  const { t } = useTranslation("company");
  const headers = useMemo(
    () => [
      {
        field: "name",
        label: t("common:name"),
      },
      {
        field: "manager_name",
        label: t("common:officeManager"),
        sortField: "manager__last_name",
      },
    ],
    [t]
  );

  return (
    <Row>
      <Col>
        <BExpandedTable
          headers={headers}
          FiltersComponent={<Filters />}
          renderExpandedRow={(row) => <OfficeEditWidget agencyId={agencyId} office={row} />}
        />
      </Col>
    </Row>
  );
}

export default SubOfficeExtendedTable;
