import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { AllError, FormGroup } from "components/formik";
import * as companyAPI from "api2/companies";
import { toast } from "react-toastify";

function AzoraProgenitorBox({ companyId }) {
  const { t } = useTranslation("company");

  const formikProps = {
    initialValues: {
      progenitor_id: "",
      supplier_invoices: true,
      receipts: true,
    },
    validationSchema: yup.object().shape({
      progenitor_id: yup.number().positive().required(),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      return companyAPI
        .progenitorUpdate(companyId, values)
        .then(() => {
          toast.success(t("msg:saved"));
        })
        .catch((error) => {
          setErrors(error.data);
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ isSubmitting, errors }) => {
        return (
          <Form>
            <Card className="card-border">
              <Card.Header className="border-danger">
                <Card.Title>Azora Progenitor</Card.Title>
              </Card.Header>
              <Card.Body className="pt-0" style={{ minHeight: 100 }}>
                {t("azoraProgenitorInfo")}

                <Row className="mt-1">
                  <Col lg={4}>
                    <FormGroup.Input type="number" label={t("common:companyId")} required name="progenitor_id" />
                  </Col>
                  <Col className="mt-2">
                    <FormGroup.Checkbox label={t("navigation:supplierInvoices")} name="supplier_invoices" />
                    <FormGroup.Checkbox label={t("common:receipts")} name="receipts" />
                  </Col>
                </Row>
                <AllError errors={errors} />
              </Card.Body>
              <Card.Footer>
                <Button variant="red" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? `${t("common:actions.processing")}...` : t("common:actions.execute")}
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default AzoraProgenitorBox;
