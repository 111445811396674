import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

import { BExpandedTable } from "components/tables/btable";
import { AgencyScheduleTemplateEditWidget } from "components/forms/agency-settings-forms";

function AgencyScheduleTemplateExtendedTable({ agencyId }) {
  const { t } = useTranslation("common");

  const headers = useMemo(
    () => [
      {
        field: "template_name",
        label: t("common:name"),
      },
      {
        field: "monday",
        label: t("options:mon"),
      },
      {
        field: "tuesday",
        label: t("options:tue"),
      },
      {
        field: "wednesday",
        label: t("options:wed"),
      },
      {
        field: "thursday",
        label: t("options:thu"),
      },
      {
        field: "friday",
        label: t("options:fri"),
      },
      {
        field: "saturday",
        label: t("options:sat"),
      },
      {
        field: "sunday",
        label: t("options:sun"),
      },
    ],
    [t]
  );

  return (
    <Row>
      <Col>
        <BExpandedTable
          headers={headers}
          renderExpandedRow={(row) => <AgencyScheduleTemplateEditWidget agencyId={agencyId} scheduleTemplate={row} />}
        />
      </Col>
    </Row>
  );
}

export default AgencyScheduleTemplateExtendedTable;
