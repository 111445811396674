import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import * as reportAPI from "api2/reports";
import TaxAuthorityForm from "components/forms/TaxAuthorityForm";
import useInitialAsync from "hooks/useInitialAsync";
import { RocketLoader } from "components/ui/loaders";

function TaxAuthorityAddPage() {
  const company = useOutletContext();
  const navigate = useNavigate();

  const onCreate = (newItem) => {
    navigate(-1);
  };

  const outgoingBalance = company ? company.preliminary_tax : 0;
  const { loading, item: balance1630 } = useInitialAsync(() =>
    reportAPI.accountGlobalBalance(company.id, { account: 1630 }).then((response) => {
      return response.data.length ? response.data[0].balance || 0 : 0;
    })
  );
  if (loading) {
    return <RocketLoader />;
  }

  const tax = {
    booking_date: new Date(),
    due_date: new Date(),
    incoming_balance: balance1630,
    personnel_tax: 0,
    soc_sec_fees: 0,
    amount_currency: "SEK",
    prel_tax: company ? company.preliminary_tax : 0,
    vat: 0,
    interest: 0,
    other: 0,
    fees: 0,
    sick_pay_return: 0,
    company_tax: 0,
    refunded_debited_preliminary_tax: 0,
    special_payroll_tax: 0,
    outgoingBalance,
    amount: outgoingBalance > 0 ? 0 : Math.abs(outgoingBalance),
  };

  return <TaxAuthorityForm companyId={company.id} tax={tax} onSave={onCreate} />;
}

export default TaxAuthorityAddPage;
