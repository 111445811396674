import qs from "qs";
import client from "./client";

export function history(companyId, invoiceId, config) {
  return client.get(`/customer-invoices/${invoiceId}/history/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export const rotRut = {
  list: (companyId, filters, config) => {
    return client.get(`/customer-invoices/rot-rut/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  xml: (ids, companyId, filters, config) => {
    return client.get(`/customer-invoices/rot-rut/xml/`, {
      params: { filters, ids },
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
      headers: {
        "x-company": companyId,
      },
      responseType: "blob",
    });
  },
  moveBackFromApplied: (ciId, companyId, config) => {
    return client.put(
      `/customer-invoices/${ciId}/rot-rut/move-back-from-applied/`,
      {},
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    );
  },
  uploadSkvFile: (companyId, files) => {
    const formData = new FormData();
    files.forEach((file) => {
      if (file) {
        formData.append("file", file.file);
      }
    });
    return client.post(`/customer-invoices/rot-rut/upload-skv-file/`, formData, {
      headers: {
        "x-company": companyId,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  bookPayments: (companyId, data, config) => {
    return client.post(`/customer-invoices/rot-rut/book-payments/`, data, {
      headers: {
        "x-company": companyId,
        ...config,
      },
    });
  },
  materialCostAndHoursUpdate: (ciId, companyId, data, config) => {
    return client.patch(`/customer-invoices/${ciId}/rot-rut/update-material-cost-and-hours/`, data, {
      headers: {
        "x-company": companyId,
        ...config,
      },
    });
  },
};
