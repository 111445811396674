import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatMoney } from "../../../utils/money";

function PaymentStatusLabel({ status }) {
  const { t } = useTranslation("common");
  if (status === 0) {
    return (
      <span>
        <i className="fe-send mr-1" /> {t("statuses.sent")}
      </span>
    );
  }
  if (status === 1) {
    return (
      <span>
        <i className="fe-check-circle mr-1" /> {t("statuses.paid")}
      </span>
    );
  }
  return (
    <span>
      <i className="fe-x mr-1" /> {t("statuses.rejected")}
    </span>
  );
}

function IsoPaymentPreviewList({ basket }) {
  const { t } = useTranslation();
  const { payments } = basket;
  return (
    <div className="iso-payment-preview-list">
      <Table size="sm" bordered={false}>
        <thead>
          <tr>
            <th>{t("receiver")}</th>
            <th>{t("money.amount")}</th>
            <th>{t("dates.paymentDate")}</th>
            <th>{t("statuses.status")}</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => (
            <tr key={payment.id}>
              <td>{payment.receiver_name}</td>
              <td>
                {formatMoney(payment.amount)} {payment.amount_currency}
              </td>
              <td>{payment.booking_date}</td>
              <td>
                <PaymentStatusLabel status={payment.status} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default IsoPaymentPreviewList;
