import React, { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";

import * as options from "api/options";
import { getDefaultRounding } from "utils/others";

function RoundingSwitcher({ onRoundChange }) {
  const roundingChoices = options.roundingChoices.asList();

  const [rounding, setRounding] = useState(() => options.roundingChoices.getOption(getDefaultRounding()));

  function onChange(newRounding) {
    window.localStorage.setItem("rounding", newRounding.value);
    setRounding(newRounding);
    onRoundChange(newRounding.value);
  }

  return (
    <ButtonGroup size="sm" className="rounding-switcher">
      {roundingChoices.map((choice) => (
        <Button
          key={choice.value}
          variant="switch"
          active={rounding.value === choice.value}
          onClick={() => onChange(choice)}
        >
          {choice.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}

export default RoundingSwitcher;
