import React from "react";
import { AGENCY_PERMISSION_ROLES } from "utils/permissions/agencyPermissions";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { PERMISSION_MODULE_CHILD_TYPE } from "../company-settings-forms/EmployeePermissionsForm/models";

function ChildCheckboxes({ child, permsChecked, setPermsChecked, withExpandClass }) {
  const { label, code } = child;

  const {
    i18n: { language },
  } = useTranslation();

  const handleCheckboxChange = () => {
    setPermsChecked((p) => {
      if (p.includes(code)) {
        return p.filter((backendCode) => backendCode !== code);
      }

      return [...p, code];
    });
  };

  return (
    <tr className={cx("child", withExpandClass)} key={label}>
      <td>
        <span className="dot">•</span>
        <span>{label[language]}</span>
      </td>
      {AGENCY_PERMISSION_ROLES.map((role) => {
        const isAllowedToCheck = role === child.role;
        const isChecked = permsChecked.includes(code) && isAllowedToCheck;

        const forChecked = isChecked ? "checked checkbox" : "";
        const forAllowed = isAllowedToCheck ? "allowed checkbox" : "checkbox";

        return (
          <td key={role}>
            <div>
              <button
                onClick={() => isAllowedToCheck && handleCheckboxChange(role)}
                type="button"
                className={forChecked || forAllowed}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
}

ChildCheckboxes.propTypes = {
  child: PERMISSION_MODULE_CHILD_TYPE,
  permsChecked: PropTypes.arrayOf(PropTypes.string.isRequired),
  setPermsChecked: PropTypes.func.isRequired,
  withExpandClass: PropTypes.string.isRequired,
};

export default ChildCheckboxes;
