import React, { useState } from "react";
import { Button, ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { SubmitButton } from "components/ui/buttons";
import { NewDocuments } from "components/ui/documents/verification-documents";
import { SavedDocuments } from "components/ui/documents-refactored/documents";
import { COMPANY_ARCHIVE_ALLOWED_FILE_TYPES } from "components/ui/documents/DropZoneInput";
import * as documentAPI from "api/document";
import { AllError, FormGroup } from "components/formik";
import * as options from "api/options";
import * as documentsAPI from "api2/documents";
import { useCompanyState } from "hooks/useCompany";
import { formatDate, parseDate } from "utils/date";
import { codesFor, HasPermCode } from "../../perms";

function ShareButton({ setFieldValue, forCustomer, hasConsultViewAll }) {
  const { t } = useTranslation("common");
  return (
    <div className="form-group">
      <label className="form-label">{t("common:actions.share")}</label>
      <div className="d-flex" style={{ height: 36 }}>
        <Button
          className="mr-3"
          variant="toggle"
          active={forCustomer || !hasConsultViewAll}
          disabled={!hasConsultViewAll}
          onClick={() => setFieldValue("for_customer", !forCustomer)}
        >
          <i className="fe-user mr-1" /> {t("common:customer")}
        </Button>
        {forCustomer && (
          <FormGroup.LabeledCheckbox
            label={t("common:actions.notifyCustomer")}
            name="notify_customer"
            wrapperClass="pl-1"
          />
        )}
      </div>
    </div>
  );
}

function SalaryArchiveForm({ companyId, archive, onSave }) {
  const { t } = useTranslation("common");
  const archiveCategories = options.salaryArchiveCategories.asList();
  const archiveTypes = options.salaryArchiveTypes.asList();
  const [newFiles, setNewFiles] = useState(!archive.id && [{ key: _.uniqueId("nd.") }]);
  const { perms } = useCompanyState();
  const isCompanyConsult = HasPermCode(codesFor.consultManage.enabled) || perms.isAdmin;
  const hasViewAll = HasPermCode(codesFor.salaries.archive_all) || perms.isAdmin;
  const hasConsultViewAll = isCompanyConsult && hasViewAll;
  const formikProps = {
    initialValues: {
      ...archive,
      month: archive.month ? parseDate(archive.month) : null,
      type: options.salaryArchiveTypes.getOption(archive.type) || archiveTypes[0],
      category:
        options.salaryArchiveCategories.getOption(archive.category) || archiveCategories[archiveCategories.length - 1],
    },
    validationSchema: yup.object().shape({
      title: newFiles.length >= 2 ? yup.string().notRequired() : yup.string().required(),
      category: yup.object().nullable().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      let _newFiles = [];
      if (values.type.value === "document") {
        if (!archive.id) {
          _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
          if (!_newFiles.length) {
            toast.error(t("msg:fixErrors"));
            setErrors({ __all__: t("file.noFile") });
            return false;
          }
        }
      }
      return documentsAPI.salaryDocuments
        .save(companyId, _newFiles, {
          ...values,
          title: values.title,
          category: values.category.value,
          type: values.type.value,
          month: values.month ? formatDate(values.month) : null,
          for_customer: hasConsultViewAll ? values.for_customer : true,
          notify_customer: hasConsultViewAll ? values.notify_customer : false,
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (onSave) {
            onSave(response.data);
          }
        })
        .catch((errors) => {
          toast.error(t("msg:fixErrors"));
        });
    },
  };

  function onFileSelected(file, index, setFieldValue, values) {
    if (values.type.value === "note") {
      return;
    }
    const maxCount = 10;
    if (file) {
      const fileCount = file.length + newFiles.length - 1;
      if (fileCount > maxCount) {
        toast.error(t("msg:fileLengthLimit", { max: maxCount }));
        return;
      }
    }
    documentAPI.onNewFileChange({
      file,
      index,
      allFiles: newFiles,
      setFile: setNewFiles,
      max: maxCount,
    });
    if (newFiles.length >= 2 || file?.length >= 2) {
      setFieldValue("title", "");
    } else if (!values.title && file && file[0]) {
      setFieldValue("title", file[0].name);
    }
  }

  return (
    <Formik {...formikProps}>
      {({ isSubmitting, errors, setFieldValue, values }) => {
        return (
          <Form id="archiveForm">
            <Card>
              <Card.Body>
                <Row>
                  <Col xl={6}>
                    <Row>
                      <Col>
                        <FormGroup.Input
                          label={t("common:name")}
                          name="title"
                          required={newFiles.length - 1 <= 1}
                          disabled={newFiles.length - 1 >= 2}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <FormGroup.SimpleSelect
                          disabled={archive.id}
                          options={archiveTypes}
                          name="type"
                          label={t("common:type")}
                        />
                      </Col>
                      <Col lg={4}>
                        <FormGroup.SimpleSelect
                          options={archiveCategories}
                          name="category"
                          label={t("common:category")}
                        />
                      </Col>
                      <Col lg={4}>
                        <FormGroup.DateMonthPicker name="month" label={t("common:dates.month")} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup.Input
                          as="textarea"
                          label={values.type.value === "note" ? t("common:note") : t("common:description")}
                          name="description"
                          rows={3}
                        />
                      </Col>
                    </Row>
                    {hasConsultViewAll && (
                      <Row>
                        <Col>
                          <ShareButton
                            setFieldValue={setFieldValue}
                            forAuditor={values.for_auditor}
                            forCustomer={values.for_customer}
                            hasConsultViewAll={hasConsultViewAll}
                            isDraft={!!values.id}
                            disableAuditor={values.category.type !== "Other"}
                          />
                        </Col>
                      </Row>
                    )}
                    <AllError errors={errors} />
                    <Row>
                      <Col>
                        <hr />
                        <ButtonGroup>
                          <SubmitButton isSubmitting={isSubmitting} />
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6} className="verification-files">
                    {archive.id && archive.type === "document" && (
                      <SavedDocuments
                        companyId={companyId}
                        documents={[archive.id]}
                        partUrl="/documents/salary"
                        removable={false}
                      />
                    )}
                    {!values.id && values.type.value === "document" && (
                      <>
                        <div>
                          <i className="fas fa-info-circle mr-1" />
                          {t("infoAboutMultiUpload")}
                        </div>
                        <NewDocuments
                          fileSize={1024 * 1024 * 20}
                          fileTypes={[...COMPANY_ARCHIVE_ALLOWED_FILE_TYPES, ".doc", ".docx"]}
                          documents={newFiles}
                          onChange={({ file, index }) => onFileSelected(file, index, setFieldValue, values)}
                          multiple
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default SalaryArchiveForm;
