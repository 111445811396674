import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import BTable from "components/tables/btable";
import { formatDurationShort } from "utils/date";
import Filters from "./EmployeeAbsenceFilters";
import Actions from "./EmployeeAbsenceActions";

function EmployeeAbsenceAgencyTable({ companyId, allowedModes, forStatus, selectable = false }) {
  const { t } = useTranslation("common");
  const headers = useMemo(
    () => [
      {
        field: "user_name",
        label: t("common:employee"),
        sortField: "user__last_name",
      },
      {
        field: "month_holder",
        label: t("common:dates.month"),
      },
      {
        field: "absence_time",
        label: t("common:absence"),
        render: (values) => formatDurationShort(values.absence_time),
      },
      {
        field: "customer_time",
        label: t("common:customerTime"),
        render: (values) => formatDurationShort(values.customer_time),
      },
      {
        field: "internal_time",
        label: t("common:internalTime"),
        render: (values) => formatDurationShort(values.work_time - values.customer_time),
        canSort: false,
      },
      {
        field: "reported_time",
        label: t("common:reportedTime"),
        render: (values) => formatDurationShort(values.work_time + values.absence_time),
        canSort: false,
      },
      {
        field: "scheduled_time",
        label: t("common:scheduledTime"),
        render: (values) => formatDurationShort(values.scheduled_time * 3600),
      },
    ],
    [t]
  );

  const getRowProps = (row) => {
    return {
      className: cx(row.status || "draft", {
        "has-message": !!row.comments.length,
      }),
    };
  };

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      FiltersComponent={<Filters companyId={companyId} allowedModes={allowedModes} forStatus={forStatus} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} forAgency />}
      renderTotals={null}
      getRowProps={getRowProps}
    />
  );
}

export default EmployeeAbsenceAgencyTable;
