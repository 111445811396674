import React from "react";
import * as companyAPI from "api2/companies";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { formatISO, parseISO } from "date-fns";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";

import { AllError, FormGroup } from "components/formik";
import { RemoveButton, SubmitButton } from "components/ui/buttons";

function ProjectForm({ companyId, project, successCallback, deleteCallback }) {
  const { t } = useTranslation("company");

  const formikProps = {
    initialValues: {
      ...project,
      date_start: project.date_start && parseISO(project.date_start),
      date_end: project.date_end && parseISO(project.date_end),
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      serial: yup.number().typeError(t("common:errors.mustBeNumber")).required(),
      date_start: yup.date().nullable().required(),
      date_end: yup
        .date()
        .nullable()
        .when("date_start", ([startDate], schema) => {
          return startDate ? schema.min(startDate, t("common:errors.mustLaterStartDate")) : schema;
        }),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await companyAPI.projects
        .save(companyId, {
          ...values,
          id: project.id,
          date_start: formatISO(values.date_start, { representation: "date" }),
          date_end: values.date_end ? formatISO(values.date_end, { representation: "date" }) : null,
        })
        .then((response) => {
          if (!project.id) {
            resetForm();
          }
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (successCallback) {
            successCallback(response.data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  const onDelete = async () => {
    companyAPI.projects
      .delete(companyId, project.id)
      .then(() => {
        toast.success(t("msg:deleted"));
        if (deleteCallback) {
          deleteCallback();
        }
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__, { autoClose: 4000 });
        } else {
          toast.error(t("msg:canNotExecuteAction"));
        }
      });
  };

  return (
    <Formik {...formikProps}>
      {({ isSubmitting, errors }) => {
        return (
          <Form>
            <Row>
              <Col sm={6} xl={3}>
                <FormGroup.Input label={t("common:name")} name="name" required />
              </Col>
              <Col sm={6} xl={3}>
                <FormGroup.Input label={t("serialOrNumber")} name="serial" required />
              </Col>
              <Col sm={6} xl={3}>
                <FormGroup.DatePicker
                  label={t("common:starts")}
                  name="date_start"
                  isClearable
                  required
                  popperClassName="popper-in-modal"
                />
              </Col>
              <Col sm={6} xl={3}>
                <FormGroup.DatePicker
                  label={t("common:ends")}
                  name="date_end"
                  isClearable
                  popperClassName="popper-in-modal"
                />
              </Col>
            </Row>
            <AllError errors={errors} />
            <hr />
            <div className="space-1">
              <SubmitButton isSubmitting={isSubmitting} />
              {project.id && (
                <RemoveButton
                  variant="red"
                  disabled={isSubmitting}
                  confirmMessage={t("confirm.removeProject", {
                    name: project.name,
                  })}
                  onClick={onDelete}
                />
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ProjectForm;
