import React, { useContext } from "react";
import i18n from "i18n";
import { TimeGridDispatchContext, TimeGridStateContext } from "state/providers/TimeGridProvider";
import { formatDate, formatDurationShort } from "utils/date";
import cx from "classnames";
import { TimeTrackerStateContext } from "state/providers/TimeTrackerProvider";

function DayHeader({ date, dow: idx }) {
  const { selected: selectedAgency } = useContext(TimeTrackerStateContext);
  const { lockedDays, scheduled, reported } = useContext(TimeGridStateContext);
  const { toggleLockDay } = useContext(TimeGridDispatchContext);
  const isAgencyCompanyEmployee = selectedAgency.is_main_company_employee;
  const isLocked = lockedDays.includes(formatDate(date));
  const dayMonth = date.getDate();
  const dayName = date.toLocaleString(i18n.language, {
    weekday: "short",
  });
  const scheduledTime = 3600 * (idx === 0 ? scheduled[6] : scheduled[idx - 1]);
  const reportedTime =
    (isAgencyCompanyEmployee ? reported.dates[formatDate(date)] : reported.logsDates[formatDate(date)]) || 0;
  const deviation = scheduledTime - reportedTime;
  return (
    <div className={cx("day", { locked: isLocked })} onClick={() => toggleLockDay(date)}>
      <div className="day__lock">{isLocked ? <i className="fas fa-lock" /> : <i className="fas fa-unlock" />}</div>
      <div className="day__name">{dayName}</div>
      <div className="day__month">{dayMonth}</div>
      <div className="day__summary">
        <p title="Reported work time">
          <i className="far fa-clock" /> <span>{formatDurationShort(reportedTime)}</span>
        </p>
        {isAgencyCompanyEmployee && (
          <>
            <p title="According to schedule">
              <i className="far fa-calendar" /> <span>{formatDurationShort(scheduledTime)}</span>
            </p>
            <p title="Deviation from schedule">
              <i className="fas fa-bars" />{" "}
              <span>
                {deviation > 0 && "-"}
                {formatDurationShort(Math.abs(deviation))}
              </span>
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default DayHeader;
