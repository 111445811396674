import React, { useState } from "react";
import { Button, ButtonGroup, Card, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import useInitialAsync from "hooks/useInitialAsync";
import * as statsAPI from "api2/stats";
import { Loader } from "components/ui/loaders";

function StatsCompanyPage({ forYear = false, insideAgency = false }) {
  const { t } = useTranslation("reports");
  const [showPercentage, setShowPercentage] = useState(false);
  const { agencyId: _agencyId } = useParams();
  const agencyId = insideAgency && _agencyId ? _agencyId : null;
  const { item: data, loading } = useInitialAsync(({ cancelToken }) => {
    if (agencyId) {
      return statsAPI.agency.activeCompanies(agencyId, forYear ? 12 : 6, { cancelToken });
    }
    return statsAPI.admin.allActiveCompanies(forYear ? 12 : 6, { cancelToken });
  }, {});

  if (loading) {
    return <Loader />;
  }

  function gotTo(location) {
    window.location = location;
  }

  const {
    months,
    percentage_company_selection_data,
    per_month_by_agency_id,
    total_per_agency,
    stats_before_time_period_by_agency_id,
    total_per_month,
    total,
    // total_drafts,
  } = data;
  const generalWidth = 700 / months.length + 25;
  const colspan_extra = 2;
  const smallWidth = generalWidth - 20;
  const hidePercentage = true;
  return (
    <Card>
      <Card.Body>
        <Row className="pb-3">
          <Col>
            <ButtonGroup toggle className="d-block">
              <Button variant="switch" active={months.length === 6} onClick={(e) => gotTo("./companies")}>
                6 {t("common:months").toLowerCase()}
              </Button>
              <Button variant="switch" active={months.length === 12} onClick={(e) => gotTo("./companies-yearly")}>
                12 {t("common:months").toLowerCase()}
              </Button>
            </ButtonGroup>
          </Col>
          {!hidePercentage && (
            <Col className="text-right">
              <Button
                className="btn-link"
                variant=""
                size="lg"
                onClick={() => {
                  setShowPercentage(!showPercentage);
                }}
              >
                {t("loginPercentage")} <i className={showPercentage ? "fa fa-toggle-on" : "fa fa-toggle-off"} />{" "}
              </Button>
            </Col>
          )}
        </Row>
        <Table bordered striped className="stats-table">
          <thead>
            <tr>
              <th key="agencies" className="text-left">
                {t("agencies")}
              </th>
              {/*  <th key="drafts" style={{ minWidth: smallWidth, width: smallWidth }} className="draft">  */}
              {/*  {t("common:drafts")}  */}
              {/*  </th>  */}
              <th key="acc" style={{ minWidth: smallWidth, width: smallWidth }}>
                {t("acc")}
              </th>
              {months.map((item, index) => (
                <th key={item} style={{ minWidth: generalWidth, width: generalWidth }}>
                  {t(item)}
                </th>
              ))}
              <th key="total-right" style={{ minWidth: generalWidth + 20, width: generalWidth }}>
                {t("common:total")}
              </th>
            </tr>
          </thead>
          <tbody>
            {!loading && ![per_month_by_agency_id].length && (
              <tr>
                <td key="no results" colSpan={5}>
                  {t("noResultsFound")}
                </td>
              </tr>
            )}
            {Object.entries(per_month_by_agency_id).map(([agency_id, agency_monthly], index) => (
              <tr key={`agency_${agency_id}`}>
                <td key={`agency_${agency_id}_name`} className="text-left">
                  {agency_monthly.name}
                </td>
                {/*  <td key={`agency_${agency_id}_drafts`} className="draft">  */}
                {/*  {total_per_agency[agency_id].drafts}  */}
                {/*  </td>  */}
                <td key={`agency_${agency_id}_data`} colSpan={months.length + colspan_extra} style={{ padding: 0 }}>
                  <table>
                    <tbody className="nested">
                      <tr>
                        <td
                          key={`agency_${agency_id}_acc_activated`}
                          style={{ borderTop: 0, borderLeft: 0, minWidth: smallWidth, width: smallWidth }}
                        >
                          {stats_before_time_period_by_agency_id[agency_id].activated}
                        </td>
                        {months.map((month) => (
                          <td
                            key={`agency_${agency_id}_activated_${month}`}
                            style={{ borderTop: 0, width: generalWidth }}
                          >
                            {per_month_by_agency_id[agency_id][month]?.activated || 0}
                            {showPercentage && (
                              <span>{` ${percentage_company_selection_data[agency_id].active ?? "-"}%`}</span>
                            )}
                          </td>
                        ))}
                        <td
                          key={`agency_${agency_id}_total_activated`}
                          className="total-right-activated"
                          style={{ borderTop: 0, borderRight: 0, width: generalWidth + 20 }}
                        >
                          {total_per_agency[agency_id].activated}
                          {showPercentage && (
                            <span>{` ${percentage_company_selection_data[agency_id].active ?? "-"}%`}</span>
                          )}
                        </td>
                      </tr>
                      <tr style={{ border: 0 }}>
                        <td
                          key={`agency_${agency_id}_acc_tt`}
                          style={{ borderBottom: 0, borderLeft: 0, width: smallWidth }}
                        >
                          {stats_before_time_period_by_agency_id[agency_id].total}
                        </td>
                        {months.map((month) => (
                          <td key={`agency_${agency_id}_tt_${month}`} style={{ borderBottom: 0, width: generalWidth }}>
                            {per_month_by_agency_id[agency_id][month]?.total || "0"}
                            {showPercentage && (
                              <span>{` ${percentage_company_selection_data[agency_id].tt_only_active ?? "-"}%`}</span>
                            )}
                          </td>
                        ))}
                        <td
                          key={`agency_${agency_id}_total_tt`}
                          className="total-right-activated-tt-only"
                          style={{ borderBottom: 0, borderRight: 0, width: generalWidth + 20, background: "#BC7BBF" }}
                        >
                          {total_per_agency[agency_id].total}
                          {showPercentage && (
                            <span>{` ${percentage_company_selection_data[agency_id].tt_only_active ?? "-"}%`}</span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td key="total-bottom" className="text-left total-separator">
                {t("common:total")}
              </td>
              {/*  <td  */}
              {/*  style={{ minWidth: smallWidth, width: smallWidth }}  */}
              {/*  key="total-drafts"  */}
              {/*  className="total-separator draft"  */}
              {/*  >  */}
              {/*  {total_drafts}  */}
              {/*  </td>  */}
              <td
                key="total-separator"
                className="total-separator"
                colSpan={months.length + colspan_extra}
                style={{ padding: 0 }}
              >
                <table className="stats-table">
                  <tbody className="nested">
                    <tr className="highlighted">
                      <td key="total-bottom-acc-activated" style={{ borderLeft: 0, width: smallWidth }}>
                        {total.before_time_period_activated}
                      </td>
                      {months.map((month) => (
                        <td key={`total-activated-bottom_${month}`} style={{ width: generalWidth }}>
                          {total_per_month[month]?.activated || "0"}
                          {showPercentage && (
                            <span>{` ${percentage_company_selection_data.total_avg_active ?? "-"}%`}</span>
                          )}
                        </td>
                      ))}
                      <td
                        key="total-acctivated-bottom"
                        className="total-right-activated"
                        style={{ borderRight: 0, width: generalWidth + 20 }}
                      >
                        {total.activated}
                        {showPercentage && (
                          <span>{` ${percentage_company_selection_data.total_avg_active ?? "-"}%`}</span>
                        )}
                      </td>
                    </tr>
                    <tr className="highlighted" style={{ border: 0 }}>
                      <td key="total-bottom-acc-tt" style={{ borderBottom: 0, borderLeft: 0, width: smallWidth }}>
                        {total.totalBefore}
                      </td>
                      {months.map((month) => (
                        <td key={`total-tt-bottom_${month}`} style={{ borderBottom: 0, width: generalWidth }}>
                          {total_per_month[month]?.total || "0"}
                          {showPercentage && (
                            <span>{` ${percentage_company_selection_data.total_avg_tt_only_active ?? "-"}%`}</span>
                          )}
                        </td>
                      ))}
                      <td
                        key="total-tt-bottom"
                        className="total-right-activated-tt-only"
                        style={{ borderBottom: 0, borderRight: 0, width: generalWidth + 20 }}
                      >
                        {total.totalCurrent}
                        {showPercentage && (
                          <span>{` ${percentage_company_selection_data.total_avg_tt_only_active ?? "-"}%`}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tfoot>
        </Table>
        <Row>
          <Col className="text-right" style={{ color: "#5DB870" }}>
            <span>
              <i className="fas fa-square" /> {t("common:statuses.full")}
            </span>
          </Col>
          <Col className="text-left" style={{ color: "#BC7BBF" }}>
            <span>
              <i className="fas fa-square" /> {t("common:statuses.basic")} + {t("common:statuses.full")}
            </span>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default StatsCompanyPage;
