import { Button } from "react-bootstrap";
import React, { useRef } from "react";
import { toast } from "react-toastify";
import * as billectaAPI from "api/billecta";
import { useTranslation } from "react-i18next";

function ProductImportButton({ companyId, onSuccess, ...props }) {
  const { t } = useTranslation("common");
  const fileRef = useRef(null);

  function onFileSelected(event) {
    event.preventDefault();
    const selectedFile = (event.target.files || event.dataTransfer.files)[0];
    if (selectedFile) {
      if (selectedFile.type !== "text/csv") {
        // allowed extension...
        toast.error(t("common:file.notSupported", { formats: "text/csv" }), {
          autoClose: 4000,
        });
        fileRef.current.value = null;
      } else {
        // handle
        billectaAPI
          .importProductsCSV(companyId, selectedFile)
          .then((response) => {
            if (onSuccess) {
              onSuccess(response.data);
            } else {
              window.location.reload();
            }
          })
          .catch((error) => {
            fileRef.current.value = null;
            if (error.data.__all__) {
              toast.error(error.data.__all__, {
                autoClose: 10000,
              });
            }
          });
      }
    }
  }

  return (
    <div className="upload-btn">
      <Button variant="secondary" onClick={() => fileRef.current.click()} {...props}>
        <i className="fe-upload" /> {t("actions.import")} CSV
      </Button>
      <input ref={fileRef} type="file" onChange={onFileSelected} />
    </div>
  );
}

export default ProductImportButton;
