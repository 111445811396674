import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { FilterButton } from "components/ui/buttons";
import useTable from "components/tables/btable/useTable";
import { DateRangeFilters, MoneyRangeFilter, SearchFilter } from "components/filters";
import { Button } from "react-bootstrap";

function GlobalDraftSIFilters() {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");
  const filtersCount =
    !!filters.booking_date__start +
    !!filters.booking_date__end +
    !!filters.due_date__start +
    !!filters.due_date__end +
    !!filters.amount_sek_min +
    !!filters.amount_sek_max;
  const [more, setMore] = useState(!!filtersCount);

  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        <Button
          variant="toggle"
          onClick={() => setFilters({ favourites: filters.favourites ? null : true })}
          active={filters.favourites}
        >
          {t("company:favouritesOnly")}
        </Button>
      </section>
      {more && (
        <section className="more-filters">
          <DateRangeFilters
            label={t("common:dates.invoiceDate")}
            dateStart={filters.booking_date__start}
            dateEnd={filters.booking_date__end}
            onChange={({ start, end }) => setFilters({ booking_date__start: start, booking_date__end: end })}
          />
          <DateRangeFilters
            label={t("common:dates.dueDate")}
            dateStart={filters.due_date__start}
            dateEnd={filters.due_date__end}
            onChange={({ start, end }) => setFilters({ due_date__start: start, due_date__end: end })}
          />
          <MoneyRangeFilter
            label={t("common:money.amountRange")}
            numberFrom={filters.amount_sek_min}
            numberTo={filters.amount_sek_max}
            onChange={(values) =>
              setFilters({
                amount_sek_min: values.numberFrom,
                amount_sek_max: values.numberTo,
              })
            }
          />
        </section>
      )}
    </>
  );
}

export default GlobalDraftSIFilters;
