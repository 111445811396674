import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useResolvedPath } from "react-router-dom";

import { TooltipActionButton, TooltipActionLink } from "components/ui/buttons";
import { confirmRemove } from "components/modals/ConfirmModal";
import * as contractAPI from "api/contracts";
import useTable from "components/tables/btable/useTable";

function ContractTemplateActions({ companyId, row }) {
  const { t } = useTranslation("ci");
  const path = useResolvedPath("").pathname;
  const {
    dataActions: { reload },
  } = useTable();

  const onDelete = async () => {
    const answer = await confirmRemove(t("confirm.removeContractTemplate", { name: row.name }));
    if (answer) {
      contractAPI.templates
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reload();
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    }
  };

  return (
    <>
      <TooltipActionLink text={t("common:actions.change")} link={`${path}/${row.id}/change`} icon="fas fa-pen" />
      <TooltipActionLink text={t("common:actions.duplicate")} link={`${path}/${row.id}/duplicate`} icon="fas fa-copy" />
      <TooltipActionButton
        variant="action-red"
        text={t("common:actions.remove")}
        onClick={onDelete}
        icon="fas fa-trash"
      />
    </>
  );
}

export default ContractTemplateActions;
