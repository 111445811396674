import React, { useMemo, useState } from "react";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ActivityChecklist } from "./StatusListChecklist";
import { confirmExecute } from "../../../components/modals/ConfirmModal";

function StatusListChecklistModal({ activity, companyId, handleClose, index }) {
  const { t, i18n } = useTranslation("officeSupport");
  const isEnglish = useMemo(() => i18n.language === "en", [i18n.language]);
  const [dirty, setDirty] = useState(false);
  const onHide = async () => {
    let confirmed = true;
    if (dirty === true) {
      confirmed = await confirmExecute(t("common:confirm.unsavedData"), "actions.yes");
    }
    if (confirmed) {
      handleClose();
    }
  };
  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={onHide}
      size="md"
      dialogClassName="status-list-config-modal checklist-modal"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <div>
          <h1>{t("common:checklist")}</h1>
          <h2>{isEnglish ? activity.name_en : activity.name_sv}</h2>
        </div>
      </Modal.Header>
      <Modal.Body style={{ minHeight: 624, paddingBottom: 16 }}>
        <ActivityChecklist activity={activity} companyId={companyId} index={index} dirty={dirty} setDirty={setDirty} />
      </Modal.Body>
    </Modal>
  );
}

export default StatusListChecklistModal;
