import React, { useContext, useState } from "react";
import { Button, ButtonGroup, Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import _ from "lodash";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { SubmitButton } from "components/ui/buttons";
import { NewDocuments } from "components/ui/documents/verification-documents";
import * as documentAPI from "api/document";
import * as salaryAPI from "api2/salaries";
import { formatDate } from "utils/date";
import { AllError, FormGroup } from "components/formik";
import { toast } from "react-toastify";
import { FinancialYearDispatchContext } from "state/providers/FinancialYearProvider";
import { useCompanyState } from "hooks/useCompany";
import { filterActiveCC, filterActiveProjects } from "utils/others";
import * as verAPI from "api2/verification";
import { BaseVerificationsPreviewModal } from "../../modals/VerificationPreviewModals";
import useModal from "../../../hooks/useModal";

function HolidaySocForm({ companyId, item, onSave }) {
  const { t } = useTranslation("salary");
  const verPreviewModal = useModal();
  const [newFiles, setNewFiles] = useState([{ key: _.uniqueId("nd.") }]);
  const [previewVerMode, setPreviewVerMode] = useState(false);

  const { checkYears } = useContext(FinancialYearDispatchContext);
  const {
    costCenters: { asOptions: centerOptions },
    projects: { asOptions: projectOptions },
  } = useCompanyState();
  const activeCenters = centerOptions.filter(filterActiveCC);
  const showCostCenters = activeCenters.length !== 0;
  const formikProps = {
    initialValues: {
      ...item,
    },
    validationSchema: yup.object().shape({
      booking_date: yup.date().nullable().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      const verificationPreviewOnly = previewVerMode;
      setPreviewVerMode(false);
      const formatedPayload = () => {
        return {
          ...values,
          booking_date: formatDate(values.booking_date),
          project_id: values.project ? values.project.value : null,
          cost_center_id: values.cost_center ? values.cost_center.value : null,
        };
      };
      if (values.holiday_allowance === 0 && values.soc_sec === 0) {
        setErrors({ __all__: t("errors.diff0") });
        return;
      }
      const executeAnyway = await checkYears(values.booking_date);
      if (!executeAnyway) {
        return;
      }
      if (!verificationPreviewOnly) {
        await salaryAPI
          .holidayAllSocCreate(companyId, formatedPayload())
          .then(async (response) => {
            const _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
            if (_newFiles.length) {
              await verAPI.document.uploadMany(companyId, response.data.id, _newFiles).catch(() => {
                // pass silently
              });
            }
            toast.success(t("msg:saved"), { autoClose: 2000 });
            if (onSave) {
              onSave(response.data);
            }
          })
          .catch((error) => {
            toast.error(t("msg:fixErrors"));
            setErrors(error.data);
          });
      } else {
        await salaryAPI
          .holidayAllSocCreateVerificationPreview(companyId, formatedPayload())
          .then(async (response) => {
            verPreviewModal.open({ verifications: response.data });
          })
          .catch((error) => {
            setErrors(error.data);
            toast.error(t("msg:fixErrors"));
          });
      }
    },
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors }) => {
        const activeProjects = filterActiveProjects(projectOptions, values.booking_date);
        const showProjects = activeProjects.length !== 0;

        return (
          <Form id="holidayForm">
            <Card>
              <Card.Body>
                <Row>
                  <Col xl={5} className="verification-files">
                    <NewDocuments
                      documents={newFiles}
                      onChange={({ file, index }) =>
                        documentAPI.onNewFileChange({
                          file,
                          index,
                          allFiles: newFiles,
                          setFile: setNewFiles,
                          max: 3,
                        })
                      }
                    />
                  </Col>
                  <Col xl={7}>
                    <Row>
                      <Col xl={4}>
                        <FormGroup.BookingDatePicker name="booking_date" label={t("common:dates.bookingDate")} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup.MoneyInput name="holiday_allowance" label={t("cumulative2920")} required />
                      </Col>
                      <Col>
                        <FormGroup.MoneyInput name="soc_sec" label={t("cumulative2940")} required />
                      </Col>
                    </Row>
                    <Row>
                      {showProjects && (
                        <Col xl={5}>
                          <FormGroup.SimpleSelect
                            options={activeProjects}
                            isClearable
                            name="project"
                            label={t("common:project")}
                          />
                        </Col>
                      )}
                      {showCostCenters && (
                        <Col xl={5}>
                          <FormGroup.SimpleSelect
                            options={activeCenters}
                            isClearable
                            name="cost_center"
                            label={t("common:costCenter")}
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
                <AllError errors={errors} />
              </Card.Body>
              <Card.Footer>
                <ButtonGroup>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("ver:previewVer")}</Tooltip>}>
                    <Button
                      type="submit"
                      className="mr-2"
                      variant="toggle"
                      isSubmitting={isSubmitting}
                      onClick={() => {
                        setPreviewVerMode(true);
                      }}
                    >
                      <i className="mdi mdi-file-search-outline" /> {t("ver:verShort")}
                    </Button>
                  </OverlayTrigger>
                  <SubmitButton isSubmitting={isSubmitting} />
                </ButtonGroup>
              </Card.Footer>
            </Card>
            {verPreviewModal.show && (
              <BaseVerificationsPreviewModal
                handleClose={verPreviewModal.close}
                verifications={verPreviewModal.data.verifications}
                isLoading={false}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default HolidaySocForm;
