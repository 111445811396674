import React, { useState } from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";

import { FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import * as outAPI from "api2/outlays";
import { formatDate } from "utils/date";
import { useCompanyState } from "hooks/useCompany";
import { DraggableModalDialog } from "../DraggableModalDialog";

function OutlayBookPaymentModal({ handleClose, companyId, invoiceData }) {
  const { t } = useTranslation("common");
  const onSave = (values) => {
    return outAPI.manualPay(companyId, invoiceData.id, values).then((response) => {
      toast.success(t("msg:saved"));
      handleClose(true);
    });
  };

  return (
    <Modal show onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">
          {t("common:registerPaymentOutlay")} ({invoiceData.title})
        </Modal.Title>
      </Modal.Header>
      <BookForm t={t} onSave={onSave} invoiceData={invoiceData} />
    </Modal>
  );
}

function BookForm({ invoiceData, onSave, t }) {
  const [sending, setSending] = useState(false);
  const foreignCurrency = invoiceData.amount_currency !== "SEK";
  const {
    paymentAccounts: { byId: paymentAccountsById, asOptions: paymentAccountsOptions },
  } = useCompanyState();
  const formikProps = {
    initialValues: {
      payment_date: new Date(),
      amount: invoiceData.balance / invoiceData.exchange_rate || 0,
      amount_sek: invoiceData.balance,
      exchange_rate: invoiceData.amount_currency === "SEK" ? 1 : invoiceData.exchange_rate,
      contra_account: paymentAccountsById[1930],
      before: {
        amount: invoiceData.balance / invoiceData.exchange_rate || 0,
        currency: invoiceData.amount_currency,
        balance: invoiceData.balance,
        exchange_rate: invoiceData.exchange_rate,
      },
    },
    validationSchema: yup.object().shape({
      payment_date: yup.date().nullable().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      setSending(true);
      await onSave({
        payment_date: formatDate(values.payment_date),
        amount: values.amount.toFixed(2),
        amount_currency: invoiceData.amount_currency,
        exchange_rate: values.exchange_rate.toFixed(6),
        account_id: values.contra_account.value,
      })
        .catch((error) => {
          if (error.data.booking_date) {
            error.data.__all__ = error.data.booking_date;
          }
          setErrors(error.data);
        })
        .finally(() => {
          setSending(false);
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ values, errors, setFieldValue, isValid }) => {
        return (
          <Form id="bookSIForm">
            <Modal.Body>
              <Row>
                <Col lg={6}>
                  <FormGroup.BookingDatePicker
                    name="payment_date"
                    label={t("common:dates.paymentDate")}
                    popperClassName="popper-in-modal"
                    required
                  />
                </Col>
              </Row>
              <fieldset>
                <legend>{t("amountReceivedAccBank")}</legend>
                <Row>
                  <Col lg={3}>
                    <FormGroup.MoneyInput
                      name="amount"
                      label={t("common:money.amount")}
                      onChange={(val) => {
                        if (foreignCurrency && val && values.amount_sek) {
                          setFieldValue("exchange_rate", values.amount_sek / val);
                        }
                      }}
                      required
                    />
                  </Col>
                  <Col lg={3}>
                    <FormGroup.MoneyInput
                      name="exchange_rate"
                      label={t("common:money.exchangeRate")}
                      disabled
                      decimalPlaces={6}
                    />
                  </Col>
                  {foreignCurrency && (
                    <>
                      <Col lg={3}>
                        <FormGroup.MoneyInput
                          name="amount_sek"
                          label={`${t("common:money.amount")} SEK`}
                          onChange={(val) => {
                            if (foreignCurrency && val && values.amount) {
                              setFieldValue("exchange_rate", val / values.amount);
                            }
                          }}
                          required
                        />
                      </Col>
                      <Col lg={3}>
                        <FormGroup.MoneyInput
                          name="exchange_rate"
                          label={t("common:money.exchangeRate")}
                          disabled
                          decimalPlaces={6}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </fieldset>
              <fieldset>
                <legend>{t("balanceBeforeTrans")}</legend>
                <Row>
                  <Col lg={3}>
                    <FormGroup.MoneyInput name="before.amount" label={t("common:money.amount")} disabled />
                  </Col>
                  <Col lg={3}>
                    <FormGroup.Input name="before.currency" label={t("common:money.currency")} disabled />
                  </Col>
                  <Col lg={3}>
                    <FormGroup.MoneyInput name="before.balance" label={`${t("common:money.balance")} SEK`} disabled />
                  </Col>
                  <Col lg={3}>
                    <FormGroup.MoneyInput
                      decimalPlaces={6}
                      name="before.exchange_rate"
                      label={t("common:money.exchangeRate")}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup.SimpleSelect
                      name="contra_account"
                      label={t("common:paymentAccount")}
                      options={paymentAccountsOptions}
                      helpText={t("company:helpText.paymentAccount")}
                    />
                  </Col>
                </Row>
              </fieldset>

              {errors && errors.__all__ && (
                <Alert className="mt-2 mb-0" variant="danger">
                  {errors.__all__}
                </Alert>
              )}
            </Modal.Body>
            <Modal.Footer>
              <SubmitButton disabled={!isValid || sending} />
            </Modal.Footer>
          </Form>
        );
      }}
    </Formik>
  );
}

export default OutlayBookPaymentModal;
