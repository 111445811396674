import React from "react";
import { Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import AgencyConsultPermDetailedForm from "./AgencyConsultPermDetailedForm";
import "./AgencyConsultPermDetailedModal.scss";

function AgencyConsultPermDetailedModal({ agencyId, consultId, handleClose }) {
  const { t } = useTranslation("common");
  return (
    <Modal
      show
      onHide={handleClose}
      size="lg"
      scrollable
      dialogAs={DraggableModalDialog}
      className="agency-consult-perm-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">
          <Card.Title>{t("actions.editPermissions")}</Card.Title>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AgencyConsultPermDetailedForm agencyId={agencyId} consultId={consultId} handleModalClose={handleClose} />
      </Modal.Body>
    </Modal>
  );
}

export default AgencyConsultPermDetailedModal;
