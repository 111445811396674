import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import AssetEditWidget from "components/forms/AssetForm/AssetEditWidget";
import { BExpandedTable } from "components/tables/btable";
import cx from "classnames";
import { formatDate } from "utils/date";
import Actions from "./AssetActions";
import Filters from "./AssetFilters";

function AssetExtendedTable({ companyId, selectable = false }) {
  const { t } = useTranslation("asset");

  const headers = useMemo(
    () => [
      {
        field: "number",
        label: t("number"),
        canSort: true,
        render: (values) => (values.number ? values.number : "-"),
      },
      {
        field: "description",
        label: t("common:description"),
        canSort: true,
        render: (values) =>
          values.description.length > 22 ? `${values.description.substring(0, 22)}...` : values.description,
      },
      {
        field: "asset_account",
        label: t("assetAccount"),
        canSort: true,
      },
      {
        field: "acquisition_value",
        label: t("acquisitionValue"),
        canSort: true,
      },
      {
        field: "acquisition_date",
        label: t("acquisitionDate"),
        canSort: true,
      },
      {
        field: "output_balance",
        label: t("outputBalance"),
        canSort: true,
      },
      {
        field: "created",
        label: t("common:created"),
        canSort: true,
        render: (values) => formatDate(values.created),
      },
    ],
    [t]
  );
  const getRowProps = (row) => {
    let statusClass = row.status;
    if (statusClass === "material_in_approval") {
      statusClass = "prelim";
    } else if (statusClass === "inactive") {
      statusClass = "unpaid";
    } else {
      statusClass = ["active_ongoing", "active_finished"].includes(statusClass) ? "active" : statusClass;
    }
    return {
      className: cx(statusClass),
    };
  };
  return (
    <BExpandedTable
      headers={headers}
      selectable={selectable}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      FiltersComponent={<Filters companyId={companyId} />}
      renderExpandedRow={(row) => <AssetEditWidget companyId={companyId} assetId={row.id} />}
      getRowProps={getRowProps}
    />
  );
}

export default AssetExtendedTable;
