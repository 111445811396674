import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function SaveButton({ onSave, buttonText, withIcon = false, dataTestId = "", ...props }) {
  const { t } = useTranslation("common");
  const testId = dataTestId || "save-button";

  return (
    <Button type="submit" size="lg" variant="blue" onClick={onSave} data-testid={testId} {...props}>
      {withIcon && <i className="fe-folder" />}
      {` ${buttonText || t("actions.save")}`}
    </Button>
  );
}

export { SaveButton };
