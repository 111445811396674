import React from "react";

import useModal from "hooks/useModal";
import { DocumentsModalRefactored, FileUrlModal } from "components/modals";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { FileArchiveDocumentModal } from "components/modals/DocumentsModal";
import { TooltipActionButton } from "./TooltipButton";

function DocumentsButtonRefactored({ companyId, documentsIds, partUrl, text, removable = false }) {
  const { t } = useTranslation("common");
  const fileModal = useModal();

  const openModal = () => {
    fileModal.open(documentsIds);
  };

  if (!documentsIds.length) {
    return null;
  }

  return (
    <>
      <TooltipActionButton text={text || t("documents")} onClick={openModal} icon="fas fa-images" />
      {fileModal.show && (
        <DocumentsModalRefactored
          partUrl={partUrl}
          companyId={companyId}
          documentIds={fileModal.data}
          handleClose={fileModal.close}
          removable={removable}
        />
      )}
    </>
  );
}

function VerificationDocumentsButton({ companyId, verificationId, documentsIds, text }) {
  return (
    <DocumentsButtonRefactored
      text={text}
      companyId={companyId}
      documentsIds={documentsIds}
      partUrl={`/verifications/${verificationId}/documents`}
    />
  );
}

function AssetsDocumentsButton({ companyId, assetId, documentsIds }) {
  return (
    <DocumentsButtonRefactored
      companyId={companyId}
      documentsIds={documentsIds}
      partUrl={`assets/${assetId}/documents`}
      removable
    />
  );
}

// function DocumentDocumentsButton({ companyId, documentsIds, partUrl = "company-documents" }) {
//   return <DocumentsButton companyId={companyId} documentsIds={documentsIds} partUrl={partUrl} />;
// }

function DocumentDocumentsButton({ companyId, documentsIds, partUrl }) {
  return <DocumentsButtonRefactored companyId={companyId} documentsIds={documentsIds} partUrl={partUrl} />;
}

function DocumentsLink({ companyId, documentsIds, partUrl, text }) {
  const fileModal = useModal();

  const openModal = () => {
    fileModal.open(documentsIds);
  };

  if (!documentsIds.length) {
    return text;
  }

  return (
    <>
      <Button onClick={openModal} variant="link">
        {text}
      </Button>
      {fileModal.show && (
        <DocumentsModalRefactored
          partUrl={partUrl}
          companyId={companyId}
          documentIds={fileModal.data}
          handleClose={fileModal.close}
        />
      )}
    </>
  );
}

function CompanyDocumentsLink({ companyId, documentsIds, text }) {
  return <DocumentsLink companyId={companyId} documentsIds={documentsIds} text={text} partUrl="documents/company" />;
}

function SalaryDocumentsLink({ companyId, documentsIds, text }) {
  return <DocumentsLink companyId={companyId} documentsIds={documentsIds} text={text} partUrl="documents/salary" />;
}

function ConsultDocumentsLink({ companyId, documentsIds, text }) {
  return <DocumentsLink companyId={companyId} documentsIds={documentsIds} text={text} partUrl="documents/consultant" />;
}

function FileArchiveDocumentsLink({ companyId, archiveId, text, filters }) {
  const fileModal = useModal();

  const openModal = () => {
    fileModal.open(archiveId);
  };

  if (!archiveId) {
    return null;
  }
  return (
    <>
      <Button onClick={openModal} variant="link">
        {text}
      </Button>
      {fileModal.show && (
        <FileArchiveDocumentModal
          companyId={companyId}
          archiveId={fileModal.data}
          handleClose={fileModal.close}
          handleOpen={fileModal.open}
          filters={filters}
        />
      )}
    </>
  );
}

function FileArchiveDocumentsButton({ companyId, archiveId, filters }) {
  const { t } = useTranslation("common");
  const fileModal = useModal();

  const openModal = () => {
    fileModal.open(archiveId);
  };

  if (!archiveId) {
    return null;
  }
  return (
    <>
      <TooltipActionButton text={t("documents")} onClick={openModal} icon="fas fa-images" />
      {fileModal.show && (
        <FileArchiveDocumentModal
          companyId={companyId}
          archiveId={fileModal.data}
          handleClose={fileModal.close}
          handleOpen={fileModal.open}
          filters={filters}
        />
      )}
    </>
  );
}

function PaymentSpecificationPdfButton({ companyId, verification }) {
  const pdfModal = useModal();
  const { t } = useTranslation("common");

  if (!verification) {
    return null;
  }

  return (
    <>
      <TooltipActionButton
        text={t("paymentSpecification")}
        onClick={pdfModal.open}
        icon="fas fa-info"
        btnProps={{ className: "pay-spec-btn" }}
      />
      {pdfModal.show && (
        <FileUrlModal
          show
          fileUrl={`payments/${verification.id}/specification/pdf/`}
          companyId={companyId}
          handleClose={pdfModal.close}
        />
      )}
    </>
  );
}

export {
  VerificationDocumentsButton,
  FileArchiveDocumentsButton,
  CompanyDocumentsLink,
  DocumentDocumentsButton,
  FileArchiveDocumentsLink,
  SalaryDocumentsLink,
  ConsultDocumentsLink,
  AssetsDocumentsButton,
  PaymentSpecificationPdfButton,
};
