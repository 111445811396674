import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

import { BTable } from "components/tables/btable";
import cx from "classnames";
import EmployeeExtendedFilters from "./EmployeeExtendedFilters";
import Actions from "./EmployeeActions";

function EmployeeExtendedTable({ companyId, forOnboarding = false }) {
  const { t } = useTranslation("company");
  const headers = useMemo(
    () => [
      {
        field: "user_name",
        label: t("common:name"),
        sortField: "user__first_name",
      },
      {
        field: "user_email",
        label: t("common:contact.emailShort"),
        sortField: "user__email",
      },
      {
        field: "employee_number",
        label: t("employeeNo"),
      },
      {
        field: "account",
        label: t("common:settlementAccount"),
      },
      {
        field: "perms",
        label: t("common:permissions"),
        canSort: false,
        render: (values) =>
          values.permissions_summary.length ? (
            <div className={cx("d-flex flex-wrap")} style={{ gap: "0.375rem" }}>
              {values.permissions_summary.map((summary) => (
                <span
                  key={summary.name}
                  className={cx("badge badge-dark badge-pill p-1", {
                    "badge-primary": summary.value === 1,
                    "badge-dark-light": summary.value === 2,
                  })}
                >
                  {summary.name}
                </span>
              ))}
            </div>
          ) : (
            "-"
          ),
      },
      {
        field: "welcome_email_sent_at",
        label: t("welcomeEmailSentAt"),
      },
    ],
    [t]
  );

  return (
    <Row>
      <Col>
        <BTable
          headers={headers}
          renderActions={(row) => <Actions companyId={companyId} row={row} forOnboarding={forOnboarding} />}
          FiltersComponent={<EmployeeExtendedFilters />}
        />
      </Col>
    </Row>
  );
}

export default EmployeeExtendedTable;
