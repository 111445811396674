import React, { useMemo, useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { AllError, FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import { useCompanyState } from "hooks/useCompany";
import { filterActiveCC, filterActiveProjects } from "utils/others";
import { filterPeriodisationCostAccountsForSI } from "utils/periodisation";

function formChanged(original, newData) {
  const originalAccountId = original.account?.id ?? null;
  const newAccountId = newData.account?.id ?? null;
  if (originalAccountId !== newAccountId) {
    return true;
  }
  if (
    (original.project && !newData.project) ||
    (!original.project && newData.project) ||
    (original.project && newData.project && original.project.id !== newData.project.id)
  ) {
    return true;
  }
  if (
    (original.cost_center && !newData.cost_center) ||
    (!original.cost_center && newData.cost_center) ||
    (original.cost_center && newData.cost_center && original.cost_center.id !== newData.cost_center.id)
  ) {
    return true;
  }
  return false;
}

function ApprovalDataUpdateForm({ invoice, onSubmit, onVerificationPreview }) {
  const { t } = useTranslation("si");
  const {
    accounts: { asOptions: accountOptions },
    costCenters: { asOptions: centerOptions },
    projects: { asOptions: projectOptions },
  } = useCompanyState();
  const [isProjectRequired, setIsProjectRequired] = useState(false);
  const [isCenterRequired, setIsCenterRequired] = useState(false);

  const formikProps = {
    initialValues: {
      id: invoice.id,
      account: invoice.account,
      project: invoice.project,
      cost_center: invoice.cost_center,
    },
    validationSchema: yup.object().shape({
      account: yup.object().nullable().required(),
      cost_center: yup
        .object()
        .nullable()
        .when("account", ([account], schema) => {
          return account && account.cost_center_option === "mandatory" ? schema.required() : schema;
        }),
      project: yup
        .object()
        .nullable()
        .when("account", ([account], schema) => {
          return account && account.project_option === "mandatory" ? schema.required() : schema;
        }),
    }),
    onSubmit: async (values, { setErrors }) => {
      await onSubmit({
        id: invoice.id,
        account: values.account ? values.account.value : null,
        project: values.project ? values.project.value : null,
        cost_center: values.cost_center ? values.cost_center.value : null,
      })
        .then((response) => {
          toast.success(t("msg:updated"));
        })
        .catch((error) => {
          if (error.status !== 403) {
            toast.error(t("msg:fixErrors"));
            setErrors(error.data);
          }
        });
    },
  };

  function onVerificationPreviewClick(values) {
    onVerificationPreview(values);
  }

  function onAccountChange(account) {
    setIsProjectRequired(account && account.project_option === "mandatory");
    setIsCenterRequired(account && account.cost_center_option === "mandatory");
  }

  const activeProjects = useMemo(() => {
    return filterActiveProjects(projectOptions, invoice.booking_date);
  }, [projectOptions, invoice.booking_date]);
  const activeCenters = centerOptions.filter(filterActiveCC);
  const showProjects = invoice.project || activeProjects.length !== 0 || isProjectRequired;
  const showCostCenters = invoice.cost_center || activeCenters.length !== 0 || isCenterRequired;
  const optionsPeriodisationCostAccount = useMemo(() => {
    return accountOptions.filter(filterPeriodisationCostAccountsForSI);
  }, [accountOptions]);
  // const isChanged = invoice

  return (
    <fieldset className="p-1 border-left-content">
      <Formik {...formikProps}>
        {({ values, isSubmitting, setFieldValue, errors, touched }) => {
          const displayWarn = formChanged(invoice, values);
          return (
            <Form noValidate>
              <Row>
                <Col xl={4}>
                  <FormGroup.SimpleSelect
                    name="account"
                    label={t("common:costAccount")}
                    options={invoice.periodisation_enabled ? optionsPeriodisationCostAccount : accountOptions}
                    onChange={(selected) => onAccountChange(selected)}
                    required
                    filterOptionStartsWith
                  />
                </Col>
                {showProjects && (
                  <Col xl={4}>
                    <FormGroup.SimpleSelect
                      name="project"
                      label={t("common:project")}
                      options={activeProjects}
                      isClearable
                      required={isProjectRequired}
                    />
                  </Col>
                )}
                {showCostCenters && (
                  <Col xl={4}>
                    <FormGroup.SimpleSelect
                      name="cost_center"
                      label={t("common:costCenter")}
                      options={activeCenters}
                      isClearable
                      required={isCenterRequired}
                    />
                  </Col>
                )}
              </Row>
              {displayWarn && (
                <Alert variant="danger" id="updateWarn">
                  <Trans i18nKey="msg:updateOnPrelimWarn">
                    Cost account, Project or Cost Center changed. Press
                    <strong>Update</strong> button bellow in order to submit changes.
                  </Trans>
                </Alert>
              )}
              <AllError errors={errors} />
              {onVerificationPreview && (
                <Button
                  className="mr-1"
                  variant="secondary"
                  size="sm"
                  onClick={() => onVerificationPreviewClick(values)}
                >
                  <i className="mdi mdi-file-search-outline" /> {t("ver:verShort")}
                </Button>
              )}
              <SubmitButton size="sm" isSubmitting={isSubmitting} title="actions.save" />
            </Form>
          );
        }}
      </Formik>
    </fieldset>
  );
}

export default ApprovalDataUpdateForm;
