import React, { useEffect, useState } from "react";
import { Alert, Col, Modal, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as yup from "yup";

import { SubmitButton } from "components/ui/buttons";
import { CustomerPicker } from "components/formik";
import { DraggableModalDialog } from "../DraggableModalDialog";
import { useModalOnPage } from "../hooks/useModalOnPage";
import { useModalScroll } from "../hooks/useModalScroll";
import * as tableAPI from "../../../api/table";
import "./reinvoice_modal.scss";

function ReInvoiceModal({ companyId, handleClose, onSubmit }) {
  const { t } = useTranslation(["common", "msg"]);
  const [customerId, setCustomerId] = useState(null);
  const [customerDrafts, setCustomerDrafts] = useState([]);
  useEffect(() => {
    if (customerId) {
      tableAPI
        .companyCustomerInvoices(companyId, {
          billecta_customer_id: customerId,
          page_size: 9999,
          mode: "draft",
          ordering: "-booking_date",
        })
        .then((response) => {
          setCustomerDrafts(response.data);
        })
        .catch((response) => {
          // pass silent
        });
    } else {
      setCustomerDrafts([]);
    }
  }, [companyId, customerId]);

  const isModal = useModalOnPage();
  const { shouldScroll } = useModalScroll();
  const handleSetCustomer = (setFieldValue, customer) => {
    setFieldValue("customer", customer);
    setFieldValue("customer_invoice_id", null);
    setCustomerId(customer ? customer.DebtorPublicId : null);
  };
  const onCustomerInvoiceCheck = (setFieldValue, ciId, values) => {
    if (values.customer_invoice_id !== ciId) {
      setFieldValue("customer_invoice_id", ciId);
    } else {
      setFieldValue("customer_invoice_id", null);
    }
  };
  const formikProps = {
    initialValues: {
      customer: null,
      customer_invoice_id: null,
    },
    validationSchema: yup.object().shape({
      customer: yup.object().nullable().required(),
      invoice_id: yup.number().nullable().notRequired(),
    }),
    onSubmit: async (values, { setErrors }) => {
      return onSubmit(values).catch((error) => {
        setErrors(error.data);
      });
    },
  };
  return (
    <Modal
      show
      onHide={handleClose}
      size="lg"
      dialogAs={DraggableModalDialog}
      scrollable={shouldScroll}
      className="reinvoice_modal"
    >
      <Formik {...formikProps}>
        {({ values, errors, setFieldValue, isSubmitting }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title className="m-0">{t("common:actions.reInvoice")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <CustomerPicker
                    name="customer"
                    label={t("common:customer")}
                    companyId={companyId}
                    customer={values.customer}
                    onReset={() => handleSetCustomer(setFieldValue, null)}
                    required
                    menuPlacement={isModal ? "bottom" : "auto"}
                    menuPosition={isModal ? "absolute" : "fixed"}
                    onChange={(selected) => {
                      handleSetCustomer(setFieldValue, selected);
                    }}
                  />
                </Col>
                <Col>
                  {customerDrafts.length > 0 && (
                    <div className="drafts">
                      <label>Connect draft</label>
                      <Table>
                        {customerDrafts.map((ci, idx) => (
                          <tr key={ci.id}>
                            <td>
                              <input
                                type="checkbox"
                                name={ci.id}
                                checked={values.customer_invoice_id === ci.id}
                                onChange={() => onCustomerInvoiceCheck(setFieldValue, ci.id, values)}
                              />
                            </td>
                            <td className="date">{ci.booking_date}</td>
                            <td>
                              ({ci.amount} {ci.amount_currency})
                            </td>
                          </tr>
                        ))}
                      </Table>
                    </div>
                  )}
                </Col>
              </Row>
              {values.customer && (
                <Alert variant="warning">
                  {values.customer_invoice_id
                    ? t("msg:reInvoiceConnectText")
                    : t("msg:reInvoiceText", { customer: values.customer.Name })}
                </Alert>
              )}
              {errors && errors.__all__ && <Alert variant="danger">{errors.__all__}</Alert>}
            </Modal.Body>
            <Modal.Footer>
              <SubmitButton isSubmitting={isSubmitting} />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ReInvoiceModal;
