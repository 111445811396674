export function filterByTextStatsWithAndIncludes(inputValue, options) {
  const lowercasedInput = inputValue.toString().trim().toLowerCase();

  const optionsStartingWith = options.filter((option) => {
    const optionLabel = option.label.toString().trim().toLowerCase();
    return optionLabel.startsWith(lowercasedInput);
  });

  const optionsIncluding = options.filter((option) => {
    const optionLabel = option.label.toString().trim().toLowerCase();
    return !optionLabel.startsWith(lowercasedInput) && optionLabel.includes(lowercasedInput);
  });

  return [...optionsStartingWith, ...optionsIncluding];
}
