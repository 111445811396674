import React, { useCallback, useEffect, useMemo, useState } from "react";
import { psd2 as psd2Api } from "api2/integrations";

const BankConnectionContext = React.createContext(undefined);

const initialState = {
  step: "banklist",
  loading: false,
  error: "",
  qrcode: "",
  link: "",
  consentId: "",
};

function BankConnectionProvider({ companyId, consentId, renewForBic, children }) {
  const [data, setData] = useState(() => {
    if (consentId) {
      return {
        ...initialState,
        consentId,
        step: "result",
      };
    }
    return initialState;
  });

  const reset = useCallback(() => {
    setData(initialState);
  }, []);

  const onBankSelection = useCallback(
    (bicFi, forRenew = false) => {
      setData((prevState) => ({ ...prevState, loading: true }));
      psd2Api.consents
        .create(companyId, bicFi, forRenew)
        .then((response) => {
          setData({
            step: response.data.method === "DECOUPLED" ? "dflow" : "rflow",
            consentId: response.data.id,
            qrcode: response.data.qrcode ? `${response.data.qrcode}` : "",
            link: response.data.link,
          });
        })
        .catch((error) => {
          const errorMsg = error.data.__all__ || error.data.error;
          setData({ ...initialState, step: "error", error: errorMsg });
        });
    },
    [companyId]
  );

  useEffect(() => {
    if (renewForBic) {
      onBankSelection(renewForBic, true);
    }
  }, [renewForBic, onBankSelection]);

  const checkSCAStatus = useCallback(() => {
    return psd2Api.consents
      .scaStatus(companyId, data.consentId)
      .then((response) => {
        if (response.data.sca_status === "finalised") {
          setData((prevState) => ({ ...prevState, qrcode: "", step: "result" }));
        } else if (
          response.data.error ||
          response.data.sca_status === "failed" ||
          response.data.sca_status === "exempted"
        ) {
          setData((prevState) => ({
            ...prevState,
            qrcode: "",
            step: "error",
            error: response.data.error || "Connection rejected. Try again",
          }));
        } else {
          const newQR = response.data.qrcode;
          if (newQR) {
            setData((prevState) => ({ ...prevState, qrcode: newQR }));
          }
        }
      })
      .catch((error) => {});
  }, [companyId, data.consentId]);

  const showError = useCallback((message) => {
    setData((prevState) => ({ ...prevState, step: "error", error: message }));
  }, []);

  const stateValue = useMemo(
    () => ({
      companyId,
      step: data.step,
      consentId: data.consentId,
      qrcode: data.qrcode,
      link: data.link,
      loading: data.loading,
      error: data.error,
      reset,
      onBankSelection,
      checkSCAStatus,
      showError,
    }),
    [
      companyId,
      data.step,
      data.loading,
      data.consentId,
      data.qrcode,
      data.link,
      data.error,
      reset,
      onBankSelection,
      checkSCAStatus,
      showError,
    ]
  );

  return <BankConnectionContext.Provider value={stateValue}>{children}</BankConnectionContext.Provider>;
}

export { BankConnectionContext };

export default BankConnectionProvider;
