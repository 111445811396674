import React from "react";

import * as reportAPI from "api2/reports";
import useTable from "components/tables/btable/useTable";
import BaseCommentsModal from "./BaseCommentsModal";

function TimeLogCommentsModal({ handleClose, companyId, log }) {
  const {
    dataActions: { updateRow },
  } = useTable();

  const onClose = (comments) => {
    handleClose();
    updateRow({ ...log, comments });
  };

  return (
    <BaseCommentsModal
      handleClose={onClose}
      companyId={companyId}
      itemId={log.id}
      apiCreate={reportAPI.timeReport.comment.create}
      apiList={reportAPI.timeReport.comment.list}
      apiRemove={reportAPI.timeReport.comment.remove}
    />
  );
}

export default TimeLogCommentsModal;
