import React, { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import * as documentAPI from "api/document";
import { NewDocuments } from "components/ui/documents/verification-documents";
import { SubmitButton } from "components/ui/buttons";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";

function UploadBase({ onFileUpload }) {
  const { t } = useTranslation("msg");
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();

  const [newFiles, setNewFiles] = useState([{ key: _.uniqueId("nd.") }]);
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = () => {
    const _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);

    if (!_newFiles.length) {
      toast.error(t("msg:fileMinOneRequired"));
      return;
    }
    setSubmitting(true);
    const promises = [];
    // NEW
    promises.push(onFileUpload(_newFiles));
    // OLD
    // for (let i = 0; i < _newFiles.length; i++) {
    //   promises.push(onFileUpload(_newFiles[i]));
    // }
    Promise.all(promises)
      .then((results) => {
        toast.success(t("msg:uploaded"), { autoClose: 2000 });
        reloadMenuNumbers();
      })
      .catch((errors) => {
        if (errors.data.__all__) {
          toast.error(errors.data.__all__);
        } else {
          toast.error(`${t("msg:fileAtLeastOne")} ${t("msg:pdfOrImageAllowed")}`);
        }
      })
      .finally(() => {
        setNewFiles([{ key: _.uniqueId("nd.") }]);
        setSubmitting(false);
      });
  };

  return (
    <div>
      <NewDocuments
        documents={newFiles}
        multiple
        onChange={({ file, index }) =>
          documentAPI.onNewFileChange({
            file,
            index,
            allFiles: newFiles,
            setFile: setNewFiles,
          })
        }
      />
      <SubmitButton onClick={onSubmit} isSubmitting={isSubmitting} />
    </div>
  );
}

export default UploadBase;
