import React, { useEffect, useMemo } from "react";
import { Link, useResolvedPath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { isBefore, parseDate } from "utils/date";

import BTable from "components/tables/btable";
import { useCompanyState } from "hooks/useCompany";
import { truncateText } from "utils/text";
import { formatMoney } from "utils/money";
import { codesFor, HasPermCode } from "components/perms";
import Filters from "./SupplierInvoiceFilters";
import Actions from "./SupplierInvoiceActions";
import useTable from "../btable/useTable";

function SupplierInvoiceTable({ companyId, forStatus, allowedModes, selectable = false }) {
  const { t } = useTranslation("si");
  const {
    company: { payment_provider: paymentProvider, kyc_enabled },
  } = useCompanyState();
  const url = useResolvedPath("").pathname;
  const canEdit = HasPermCode(codesFor.supplierInvoices.manage);
  const {
    dataActions: { reload },
  } = useTable();
  useEffect(() => {
    // in case SI deleted from Verification modal
    document.body.addEventListener("verification/deleted", reload);
    return () => document.body.removeEventListener("verification/deleted", reload);
  }, [reload]);

  const headers = useMemo(() => {
    const _headers = [
      {
        field: "invoice_no",
        label: t("common:invoiceNo"),
        render: (values) => {
          const title = values.invoice_no || t("common:notSet");
          if (values.status === 0 && !values.is_paid_in_cash_system && canEdit) {
            return (
              <Link to={`${url}/${values.id}/change`} className="table-link">
                {title} <i className="fas fa-pen" />
              </Link>
            );
          }
          return (
            <Link to={`${url}/${values.id}/preview`} className="table-link">
              {title} <i className="fa fa-search" />
            </Link>
          );
        },
      },
      {
        field: "supplier_name",
        label: t("common:supplier"),
        sortField: "supplier_snapshot__name",
        render: (values) =>
          values.supplier_name ? (
            <span title={values.supplier_name}>{truncateText(values.supplier_name, 20)}</span>
          ) : (
            "-"
          ),
      },
      {
        field: "amount",
        label: t("common:money.amount"),
        sortField: "amount_sek",
        className: "text-right",
        render: (value) => `${formatMoney(value.amount)} ${value.amount_currency}`,
      },
      {
        field: "balance",
        label: t("common:money.toPay"),
        className: "text-right",
        render: (value) => `${formatMoney(value.balance / value.exchange_rate, 2, 2)} ${value.amount_currency}`,
      },
      {
        field: "booking_date",
        label: t("common:dates.invoiceDate"),
      },
      {
        field: "due_date",
        label: t("common:dates.dueDate"),
      },
      {
        field: "payment_date",
        label: t("common:dates.paymentDate"),
      },
    ];
    if (forStatus === "paid") {
      _headers.splice(3, 1); // no balance
    }

    return _headers;
  }, [canEdit, url, forStatus, t]);

  const getRowProps = (row) => {
    let statusClass = row.sub_status || "draft";
    let showKycInfo = false;
    if (row.sub_status === "unpaid" && row.due_date && isBefore(parseDate(row.due_date), new Date())) {
      statusClass = "expired";
    }
    if (forStatus === "unpaid") {
      if (row.amount_currency !== "SEK" && paymentProvider === "pis" && !kyc_enabled) {
        showKycInfo = true;
      }
    }
    return {
      className: cx(statusClass, { "has-message": !!row.comments.length, muted: showKycInfo }),
      title: showKycInfo ? t("company:kyc.enableKycInfo") : "",
    };
  };

  const renderTotals = (responseHeaders) => (
    <>
      {forStatus !== "unpaid" && (
        <div>
          <strong>{t("common:money.amount")}:</strong>
          {formatMoney(responseHeaders["x-pagination-amount"])}
        </div>
      )}
      {forStatus !== "paid" && forStatus !== "unpaid" && (
        <div>
          <strong>{t("common:money.toPay")}:</strong>
          {formatMoney(responseHeaders["x-pagination-balance"])}
        </div>
      )}
    </>
  );

  const renderSelect = (row) => {
    if (row.sub_status === "") {
      return "";
    }
    if (row.sub_status === "unpaid") {
      if (row.in_signing) {
        return "SIGN";
      }
      if (row.deactivate_payment) {
        return "MAN";
      }
      if (row.amount_currency !== "SEK") {
        if (paymentProvider !== "pis" || !kyc_enabled) {
          return "MAN";
        }
      }
    }
    return undefined;
  };

  return (
    <div id="supplier-invoice-table">
      <BTable
        headers={headers}
        selectable={selectable}
        FiltersComponent={<Filters companyId={companyId} forStatus={forStatus} allowedModes={allowedModes} />}
        renderActions={(row) => <Actions companyId={companyId} row={row} />}
        renderTotals={renderTotals}
        renderSelect={renderSelect}
        getRowProps={getRowProps}
      />
    </div>
  );
}

export default SupplierInvoiceTable;
