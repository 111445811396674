import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Button } from "react-bootstrap";
import _ from "lodash";

import BTable from "components/tables/btable";
import { useVerificationDispatch } from "hooks/useVerification";
import useTable from "components/tables/btable/useTable";
import { FinancialYearStateContext } from "state/providers/FinancialYearProvider";
import Filters from "./VerificationFilters";
import Actions from "./VerificationActions";

function VerificationTable({ companyId, selectable = false, initialFilters = {} }) {
  const { t } = useTranslation("ver");
  const {
    dataActions: { reload },
    cleanFilters,
  } = useTable();
  const { financialYear } = useContext(FinancialYearStateContext);
  React.useEffect(() => {
    // skip initial render
    return () => {
      cleanFilters(initialFilters);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialYear.id, initialFilters]);
  const { open: openVerificationModal } = useVerificationDispatch();

  function handleVerificationSaved(event) {
    const savedVer = event.detail;
    // After close verification modal we need to update table  with new/changed verifications
    if (!_.isEmpty(savedVer)) {
      reload();
    }
  }

  useEffect(() => {
    // After close verification modal we need to update ledger with new/changed verifications
    document.body.addEventListener("verification/saved", handleVerificationSaved);
    return () => document.body.removeEventListener("verification/saved", handleVerificationSaved);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headers = useMemo(
    () => [
      {
        field: "verification_number",
        label: t("verNumber"),
        sortField: ["series", "series_number"],
        render: (values) => (
          <Button variant="link" onClick={() => openVerificationModal(companyId, values)}>
            {values.verification_number}
          </Button>
        ),
      },
      {
        field: "booking_date",
        label: t("common:dates.bookingDate"),
        sortField: ["booking_date", "id"],
      },
      {
        field: "title",
        label: t("common:description"),
        canSort: false,
      },
      {
        field: "custom",
        label: "",
        canSort: false,
        className: "text-right td-actions",
        render: (values) => <Actions row={values} companyId={companyId} />,
      },
    ],
    [t, companyId, openVerificationModal]
  );

  const getRowProps = (row) => {
    return {
      className: cx({
        removed: row.status === 2,
        "has-message": !!row.comments.length,
      }),
    };
  };

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      FiltersComponent={<Filters companyId={companyId} />}
      // renderActions={(row) => <Actions companyId={companyId} row={row} />}
      getRowProps={getRowProps}
    />
  );
}

export default VerificationTable;
