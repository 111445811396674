import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useOutletContext, useParams } from "react-router-dom";

import TableProvider from "components/tables/btable/provider";
import ConsultArchiveTable from "components/tables/ConsultArchive/ConsultArchiveTable";
import * as documentsAPI from "api2/documents";
import ConsultArchiveDropzonesWidget from "components/forms/ConsultArchiveForm/ConsultArchiveDropzonesWidget";

function ConsultArchiveListPage({ initialCategory, selectable = false, tableId = "consultArchive" }) {
  const { company, reloadIndicators } = useOutletContext();
  const { category } = useParams();
  const initialFilters = {
    category: initialCategory || category || "",
  };
  const [counter, setCounter] = useState(1); // to refresh widget

  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) => documentsAPI.consultDocuments.list(company.id, filters, config)}
          initialFilters={initialFilters}
        >
          <ConsultArchiveDropzonesWidget
            companyId={company.id}
            counter={counter}
            setCounter={setCounter}
            reloadIndicators={reloadIndicators}
          />
          <hr style={{ paddingBottom: 0, paddingTop: 0, borderTop: "2px solid #ffaa30" }} />
          <ConsultArchiveTable
            companyId={company.id}
            selectable={selectable}
            setCounter={setCounter}
            reloadIndicators={reloadIndicators}
          />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default ConsultArchiveListPage;
