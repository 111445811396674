import { formatDate } from "utils/date";
import client from "./client";

export function customerActive(companyId, customerId) {
  return client.put(`/companies/${companyId}/billecta-customers/${customerId}/active/`);
}

export function customerDeactive(companyId, customerId) {
  return client.put(`/companies/${companyId}/billecta-customers/${customerId}/deactive/`);
}

export function customerDestroy(companyId, customerId) {
  return client.delete(`/companies/${companyId}/billecta-customers/${customerId}/`);
}

export function getNextCustomerNumber(companyId, config) {
  return client.get(`/companies/${companyId}/billecta-customers/next_customer_number/`, config);
}

export function customerSave(companyId, customerData) {
  if (customerData.DebtorPublicId) {
    return client.put(`/companies/${companyId}/billecta-customers/${customerData.DebtorPublicId}/`, {
      ...customerData,
      company: companyId,
    });
  }
  return client.post(`/companies/${companyId}/billecta-customers/`, {
    ...customerData,
    company: companyId,
  });
}

export function customerShortUpdate(companyId, customerId, customerData) {
  return client.patch(`/companies/${companyId}/billecta-customers/${customerId}/`, {
    ...customerData,
    company: companyId,
  });
}

export function importCustomersCSV(companyId, csvFile) {
  const formData = new FormData();
  formData.append("file", csvFile, csvFile.name);
  return client.post(`/companies/${companyId}/billecta-customers/import_csv/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function productSave(companyId, productData) {
  if (productData.ProductPublicId) {
    return client.put(`/companies/${companyId}/billecta-products/${productData.ProductPublicId}/`, {
      ...productData,
      company: companyId,
    });
  }
  return client.post(`/companies/${companyId}/billecta-products/`, {
    ...productData,
    company: companyId,
  });
}

export function productActive(companyId, customerId) {
  return client.put(`/companies/${companyId}/billecta-products/${customerId}/active/`);
}

export function productDeactive(companyId, customerId) {
  return client.put(`/companies/${companyId}/billecta-products/${customerId}/deactive/`);
}

export function importProductsCSV(companyId, csvFile) {
  const formData = new FormData();
  formData.append("file", csvFile, csvFile.name);
  return client.post(`/companies/${companyId}/billecta-products/import_csv/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function billectaPaymentDate(selectedDate, config) {
  return client.get("/payments/calculate-billecta-payment-date/", {
    params: { date: formatDate(selectedDate) },
    ...config,
  });
}

// export function eInvoiceReceivers(companyId, gln, orgNo, name) {
//   return client.get(`/companies/${companyId}/einvoice-receivers/`, {
//     params: { gln, org_no: orgNo, name },
//   });
// }
//
// export function eInvoiceReceiverInfo(companyId, customerId) {
//   return client.get(`/companies/${companyId}/einvoice-receivers/${customerId}/`);
// }
//
// export function eInvoiceReceiverSet(companyId, customerId, data) {
//   return client.put(`/companies/${companyId}/einvoice-receivers/${customerId}/`, data);
// }
//
// export function eInvoiceReceiverDestroy(companyId, customerId) {
//   return client.delete(`/companies/${companyId}/einvoice-receivers/${customerId}/`);
// }
