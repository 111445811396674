import React from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import cx from "classnames";

import * as companyAPI from "api2/companies";
import { GeneralForm } from "components/forms/company-settings-forms";
import { withInitialAsync } from "hooks/useAsync";
import { useTranslation } from "react-i18next";

function GeneralFormPage({ data: item }) {
  const { t } = useTranslation("company");
  return (
    <>
      <GeneralForm company={item} />
      <Row>
        <Col lg={3}>
          <Card className="card-border">
            <Card.Header className={cx(item.mandrill_emails ? "border-success" : "border-danger")}>
              <Card.Title>{t("common:emailAddresses")}</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0 mandrill-emails">
              {item.mandrill_emails ? (
                <>
                  <div>
                    <strong>{t("navigation:supplierInvoices")}: </strong>
                    <span>{item.mandrill_emails.invoices}</span>
                  </div>
                  <div>
                    <strong>{t("navigation:fileArchive")}: </strong>
                    <span>{item.mandrill_emails.archives}</span>
                  </div>
                  <div>
                    <strong>{t("common:receipt")}: </strong>
                    <span>{item.mandrill_emails.receipts}</span>
                  </div>
                  <small className="d-block mt-3">{t("mandrillNote1")}</small>
                </>
              ) : (
                <small className="d-block">{t("mandrillNote3")}</small>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3}>
          <Card className="card-border">
            <Card.Header className={cx(item.einvoice_enabled ? "border-success" : "border-danger")}>
              <Card.Title>E-Invoice</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              <strong>{t("common:statuses.status")}:</strong> {item.einvoice_enabled ? "Enabled" : "Disabled"}
              <br />
              <strong>{t("common:intermediator")}:</strong> InExchange
              <br />
              <strong>GLN:</strong> {item.inexchange_gln}
              <br />
              <br />
              {item.einvoice_enabled ? <small>{t("eInvoiceActive")}</small> : <small>{t("eInvoiceInactive")}</small>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

const EnhancedGeneralFormPage = withInitialAsync(
  GeneralFormPage,
  () => {
    const { companyId } = useParams();
    return React.useCallback((cancelToken) => companyAPI.generalDetails(companyId, { cancelToken }), [companyId]);
  },
  {},
  true
);

export default EnhancedGeneralFormPage;
