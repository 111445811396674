import React from "react";

import * as supportAPI from "api2/office-support";
import { withInitialAsync } from "hooks/useAsync";
import OfficeSupportInternalForm from "./OfficeSupportInternalForm";

function OfficeSupportInternalWidget({ data: item, companyId, changeEditedForm }) {
  return <OfficeSupportInternalForm companyId={companyId} changeEditedForm={changeEditedForm} initialData={item} />;
}

const EnhancedOfficeSupportInternalWidget = withInitialAsync(
  OfficeSupportInternalWidget,
  ({ companyId }) =>
    React.useCallback((cancelToken) => supportAPI.internal.details(companyId, { cancelToken }), [companyId]),
  [],
  false,
  true
);

export default EnhancedOfficeSupportInternalWidget;
