import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";

import {
  HistoryButton,
  TooltipActionButton,
  VerificationCommentsButton,
  VerificationDocumentsButton,
} from "components/ui/buttons";
import { confirmExecute, confirmRemove } from "components/modals/ConfirmModal";
import useTable from "components/tables/btable/useTable";
import * as siAPI from "api2/supplier-invoices";
import { handleActionErrors } from "api/errors";

function GlobalDraftSIActions({ row, goToEditPage }) {
  const { t } = useTranslation("si");

  const companyId = row.company_id;
  const {
    dataActions: { reload },
  } = useTable();
  const show = {
    deleteBtn: _.includes(["", "prelim", "investigate"], row.sub_status),
    moveDraftBtn: _.includes(["prelim", "unpaid"], row.sub_status),
    moveArchiveBtn: row.sub_status === "" && row.documents.length,
  };

  const onDelete = async () => {
    const answer = await confirmRemove(t("confirm.remove", { invoiceNo: row.invoice_no || row.ocr }));
    if (answer) {
      siAPI
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reload();
        })
        .catch((error) => {
          toast.error(t("msg:canNotExecuteAction"));
        });
    }
  };

  const onMoveDraft = async () => {
    const answer = await confirmExecute(t("confirm.moveDraft", { invoiceNo: row.invoice_no || row.ocr }));
    if (answer) {
      siAPI
        .moveDraft(companyId, row.id)
        .then(() => {
          reload();
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    }
  };

  const onMoveArchive = async () => {
    const answer = await confirmExecute(t("confirm.moveArchive", { invoiceNo: row.invoice_no || row.ocr }));
    if (answer) {
      siAPI
        .moveToArchiveDocument(companyId, row.id)
        .then(() => {
          reload();
        })
        .catch((error) => {
          toast.error(t("msg:canNotExecuteAction"));
        });
    }
  };

  return (
    <>
      <HistoryButton apiResource={siAPI.history} apiParams={[companyId, row.id]} />
      <VerificationDocumentsButton verificationId={row.id} companyId={companyId} documentsIds={row.documents} />
      <VerificationCommentsButton companyId={companyId} verification={row} />
      <TooltipActionButton
        text={t("common:actions.change")}
        onClick={() => goToEditPage(companyId, row.id)}
        icon="fas fa-pen"
      />
      {show.moveDraftBtn && (
        <TooltipActionButton
          variant="action"
          text={t("common:actions.moveDraft")}
          onClick={onMoveDraft}
          icon="fas fa-reply"
        />
      )}
      {show.moveArchiveBtn ? (
        <TooltipActionButton
          variant="action-red"
          text={t("common:actions.moveArchive")}
          onClick={onMoveArchive}
          icon="fas fa-reply"
        />
      ) : null}
      {show.deleteBtn && (
        <TooltipActionButton text={t("common:actions.remove")} onClick={onDelete} icon="fas fa-trash" />
      )}
    </>
  );
}

export default GlobalDraftSIActions;
