import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import qs from "qs";

import {
  DocumentCommentsButton,
  FileArchiveDocumentsButton,
  HistoryButton,
  TooltipActionButton,
  VerificationBookingButton,
} from "components/ui/buttons";
import { useVerificationDispatch } from "hooks/useVerification";
import useModal from "hooks/useModal";
import { confirmExecute, confirmRemove } from "components/modals/ConfirmModal";
import { VerificationPickerModal } from "components/modals";
import * as documentsAPI from "api2/documents";
import useTable from "components/tables/btable/useTable";
import { PermCodeRequired } from "components/perms";
import {
  codesForCompanyArchive,
  codesForFileArchive,
  codesForSupplierInvoices,
  codesForVerifications,
} from "components/perms/PermCodes";
import { useNavigate, useResolvedPath } from "react-router-dom";
import ToCompanyArchiveModal from "components/modals/ToCompanyArchiveModal";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";

function FileArchiveActions({ row, companyId }) {
  const { t } = useTranslation("common");
  const {
    filters,
    dataActions: { reload },
  } = useTable();
  const navigate = useNavigate();
  const { open: openVerificationModal } = useVerificationDispatch();
  const verModal = useModal();
  const caModal = useModal();
  const url = useResolvedPath("").pathname;
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();

  const createForVer = async () => {
    await openVerificationModal(companyId, {
      archive: row.id,
    });
  };

  const onDelete = async () => {
    const answer = await confirmRemove(t("others:confirm.removeFileArchive", { title: row.title }));
    if (answer) {
      documentsAPI.archiveDocuments
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reload();
          reloadMenuNumbers();
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
    }
  };

  const onDuplicate = async () => {
    const answer = await confirmExecute(t("others:confirm.duplicateFileArchive", { title: row.title }));
    if (answer) {
      documentsAPI.archiveDocuments
        .duplicate(companyId, row.id)
        .then(() => {
          toast.success(t("msg:generated"));
          reload();
          reloadMenuNumbers();
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
    }
  };

  const goToEdit = (docId) => {
    const parsedFilters = qs.stringify(
      {
        status: "new",
        ordering: filters.ordering,
        term: filters.term,
      },
      { indices: false }
    );
    navigate(`${url}/${docId}/change?${parsedFilters}`);
  };

  const openVerPickerModal = () => {
    verModal.open(row);
  };

  const onAttachVerification = (selectedVerification) => {
    verModal.close();
    if (selectedVerification) {
      documentsAPI.archiveDocuments
        .verificationAppend(companyId, verModal.data.id, selectedVerification.id)
        .then(() => {
          reload();
          reloadMenuNumbers();
          toast.success(t("msg:saved"), { autoClose: 3000 });
        })
        .catch((error) => {
          toast.error(t("msg:canNotExecuteAction"));
        });
    }
  };

  const onMoveToCompanyArchive = (moved) => {
    caModal.close();
    if (moved) {
      reload();
      reloadMenuNumbers();
    }
  };

  const onMoveToSupplierInvoice = async () => {
    const confirmed = await confirmExecute(t("others:confirm.moveArchiveToSi"));
    if (confirmed) {
      documentsAPI.archiveDocuments
        .supplierInvoiceCreate(companyId, row.id)
        .then(() => {
          toast.success(t("msg:saved"), { autoClose: 3000 });
          reload();
          reloadMenuNumbers();
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          }
        });
    }
  };

  return (
    <>
      <HistoryButton apiResource={documentsAPI.archiveDocuments.history} apiParams={[companyId, row.id]} />
      <FileArchiveDocumentsButton companyId={companyId} archiveId={row.id} filters={filters} />
      <DocumentCommentsButton companyId={companyId} document={row} />
      {row.verification ? (
        <VerificationBookingButton companyId={companyId} verification={{ id: row.verification }} />
      ) : (
        <PermCodeRequired code={codesForVerifications.manage}>
          <TooltipActionButton
            text={t("common:actions.attachToVer")}
            onClick={openVerPickerModal}
            icon="fas fa-share-alt"
          />
          <TooltipActionButton text={t("common:actions.addVer")} onClick={createForVer} icon="fas fa-upload" />
        </PermCodeRequired>
      )}
      <PermCodeRequired code={codesForFileArchive.manage}>
        <TooltipActionButton text={t("common:actions.duplicate")} onClick={onDuplicate} icon="fas fa-copy" />
      </PermCodeRequired>
      <PermCodeRequired code={codesForSupplierInvoices.manage}>
        <TooltipActionButton
          text={t("common:actions.moveToSupplierInvoice")}
          onClick={onMoveToSupplierInvoice}
          icon="fas fa-money-bill-alt"
        />
      </PermCodeRequired>
      <PermCodeRequired code={codesForCompanyArchive.manage}>
        <TooltipActionButton
          text={t("common:actions.moveToCompanyArchive")}
          onClick={caModal.open}
          icon="fas fa-arrow-right"
        />
      </PermCodeRequired>
      <TooltipActionButton
        text={t("common:actions.change")}
        onClick={() => {
          goToEdit(row.id);
        }}
        icon="fas fa-pen"
      />
      <TooltipActionButton
        variant="action-red"
        text={t("common:actions.remove")}
        onClick={onDelete}
        icon="fas fa-trash"
      />
      {verModal.show && <VerificationPickerModal companyId={companyId} handleClose={onAttachVerification} />}
      {caModal.show && (
        <ToCompanyArchiveModal companyId={companyId} fileArchive={row} handleClose={onMoveToCompanyArchive} />
      )}
    </>
  );
}

export default FileArchiveActions;
