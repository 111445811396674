import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";

import {
  HistoryButton,
  PreviewButton,
  TooltipActionButton,
  VerificationBookingButton,
  VerificationCommentsButton,
} from "components/ui/buttons";
import { confirmRemove } from "components/modals/ConfirmModal";
import useTable from "components/tables/btable/useTable";
import * as taxAPI from "api2/taxes";

function TaxAuthorityActions({ row, companyId }) {
  const { t } = useTranslation("tax");

  const {
    dataActions: { reload },
  } = useTable();

  const show = {
    bookingBtn: row.status === 1,
    deleteBtn: _.includes(["", "unpaid"], row.sub_status),
  };

  const onDelete = async () => {
    const answer = await confirmRemove(t("confirm.removeAuthority", { dueDate: row.due_date }));
    if (answer) {
      taxAPI.authority
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reload();
        })
        .catch((error) => {
          toast.error(t("msg:canNotExecuteAction"));
        });
    }
  };

  return (
    <>
      <HistoryButton apiResource={taxAPI.authority.history} apiParams={[companyId, row.id]} />
      <VerificationCommentsButton companyId={companyId} verification={row} />
      <PreviewButton link={`/consult/taxes/${row.id}/preview`} />
      {show.bookingBtn && <VerificationBookingButton companyId={companyId} verification={row} />}
      {show.deleteBtn && (
        <TooltipActionButton text={t("common:actions.remove")} onClick={onDelete} icon="fas fa-trash" />
      )}
    </>
  );
}

export default TaxAuthorityActions;
