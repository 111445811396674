import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import "./ImportFortnoxStep.scss";
import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import { withInitialAsync } from "hooks/useAsync";
import * as companyApi from "api2/companies";
import { OnboardingDispatchContext } from "../../provider/OnboardingProvider";
import { FortnoxWrapper } from "./FortnoxWrapper";

function ImportFortnoxStep({ data: initialData, companyId, agencyId, initialLoading }) {
  const { t } = useTranslation("common");
  const [data, setData] = useState(initialData);
  const { fetchDetails } = useContext(OnboardingDispatchContext);

  useEffect(() => {
    if (!initialLoading) {
      fetchDetails();
    }
  }, [initialLoading, fetchDetails]);

  const fetchChecklist = useCallback(() => {
    companyApi.onboardings.steps.importFortnox(companyId, agencyId).then((response) => {
      setData(response.data);
      if (response.data.import_status !== "finished" || response.data.import_status !== "error") {
        fetchDetails(false);
      }
    });
  }, [companyId, agencyId, fetchDetails]);

  const onStart = useCallback(
    (financialYearIds) => {
      companyApi.onboardings.steps.importFortnoxStart(companyId, agencyId, financialYearIds).then(() => {
        // fetch steps details
        fetchDetails();
        setData((_data) => ({ ..._data, import_status: "in_progress" }));
      });
    },
    [companyId, agencyId, fetchDetails]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      fetchChecklist();
    }, 1000 * 10);

    return () => {
      clearInterval(interval);
    };
  }, [fetchChecklist]);

  return (
    <Card className="import-fortnox-step" data-testid="import-fortnox-step">
      <Card.Body>
        <StepHeader
          rightComponent={
            (data.import_status === "in_progress" || data.import_status === "finished") && (
              <Button onClick={() => fetchDetails(true)} size="lg">
                {t("actions.goNext")}
              </Button>
            )
          }
        />
      </Card.Body>
      <Card.Body>
        <FortnoxWrapper companyId={companyId} data={data} onStart={onStart} />
      </Card.Body>
    </Card>
  );
}

const EnhancedImportFortnoxStep = withInitialAsync(
  ImportFortnoxStep,
  ({ companyId, agencyId }) => {
    return React.useCallback(
      (cancelToken) => companyApi.onboardings.steps.importFortnox(companyId, agencyId, { cancelToken }),
      [companyId, agencyId]
    );
  },
  {},
  true
);

export default EnhancedImportFortnoxStep;
