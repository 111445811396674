import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as companyAPI from "api2/companies";
import { useAuthState } from "hooks/useAuth";
import "./ExportProgressModal.scss";

function ExportProgressModal({ companyId, financialYearId, category, handleClose }) {
  const { t } = useTranslation("company");
  const { user } = useAuthState();
  const [error, setError] = useState("");
  const [notifyEmail, setNotifyEmail] = useState(false);
  const [exportId, setExportId] = useState(null);
  useEffect(() => {
    companyAPI.exporter
      .create(companyId, financialYearId, category)
      .then((response) => {
        setExportId(response.data.id);
      })
      .catch((response) => {
        if (response.data.__all__) {
          setError(response.data.__all__);
        } else {
          setError("Can not export");
        }
      });
  }, [companyId, financialYearId, category]);
  useEffect(() => {
    const checkStatus = () => {
      if (exportId) {
        companyAPI.exporter.statusCheck(companyId, exportId).then((response) => {
          if (response.data.status === "success") {
            handleClose(true);
          }
        });
      }
    };
    const interval = setInterval(() => {
      checkStatus();
    }, 2000);
    const timeout = setTimeout(() => {
      setNotifyEmail(true);
      clearInterval(interval);
    }, 30000);
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [companyId, exportId, handleClose]);
  return (
    <Modal show onHide={handleClose} size="md" className="export-progress-modal" scrollable>
      <Modal.Header closeButton />
      {error && (
        <Modal.Body>
          <i className="fe-alert-triangle" />
          <p>{error}</p>
        </Modal.Body>
      )}
      {!error && (
        <Modal.Body>
          {notifyEmail ? (
            <>
              <i className="fe-refresh-cw" />
              <p>{t("exporter.fileNotifyEmail", { email: user.email })}</p>
            </>
          ) : (
            <>
              <i className="fa fa-spinner fa-spin" />
              <p>
                {t("exporter.fileRdy30")}
                <br />
                {t("common:pleaseWait")}
              </p>
            </>
          )}
        </Modal.Body>
      )}
    </Modal>
  );
}

export default ExportProgressModal;
