import React from "react";
import { Alert, Button, ButtonGroup, Card, Col, Row, ToggleButton } from "react-bootstrap";
import { SubmitButton } from "components/ui/buttons";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { AllError, FormGroup } from "components/formik";
import * as outlayAPI from "api2/outlays";
import { formatDate } from "utils/date";
import { formatMoney } from "utils/money";
import { toast } from "react-toastify";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { codesFor, HasPermCode } from "components/perms";

function MileageForm({ companyId, outlay, onSave }) {
  const { t } = useTranslation("outlay");
  const draftRef = React.useRef(false);
  const investigateRef = React.useRef(false);
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const isCompanyConsult = HasPermCode(codesFor.consultManage.enabled);

  function calculateRate(values) {
    const year = values.booking_date ? values.booking_date.getFullYear() : new Date().getFullYear();
    if (year <= 2022) {
      if (values.ownership === "private") {
        return 1.85;
      }
      return values.fuel_type === "diesel" ? 0.65 : 0.95; // electricity same as petrol
    }
    if (values.ownership === "private") {
      return 2.5;
    }
    return values.fuel_type === "electricity" ? 0.95 : 1.2;
  }

  function calculateAmount(values) {
    if (!values.distance || values.distance <= 0) {
      return 0;
    }
    return values.distance * calculateRate(values);
  }

  const formikProps = {
    initialValues: {
      ...outlay,
    },
    validationSchema: yup.object().shape({
      booking_date: yup.date().nullable().required(),
      distance: yup.number().nullable().integer().positive().required(),
      trip_from: yup.string().required(),
      trip_to: yup.string().required(),
      trip_purpose: yup.string().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      await outlayAPI
        .mileageSave(companyId, {
          ...values,
          paid_by: "P",
          booking_date: formatDate(values.booking_date),
          receipt_date: formatDate(values.booking_date),
          amount_currency: values.amount_currency.value || "SEK",
          amount: calculateAmount(values).toFixed(2),
          draft: draftRef.current || false,
          investigate: investigateRef.current || false,
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          reloadMenuNumbers();
          if (onSave) {
            onSave(response.data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
      draftRef.current = false;
    },
  };

  return (
    <Formik {...formikProps}>
      {({ values, handleSubmit, setFieldValue, isSubmitting, errors }) => {
        return (
          <Form id="mileageForm">
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <FormGroup.BookingDatePicker name="booking_date" required label={t("whenTrip")} />
                  </Col>
                  <Col>
                    <FormGroup.Input name="trip_from" label={t("whereTrip")} required />
                  </Col>
                  <Col>
                    <FormGroup.Input name="trip_to" label={`${t("whereTripEnds")}`} required />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={6}>
                    <div className="form-group">
                      <label className="form-label">{t("howTrip")}</label>
                      <div>
                        <ButtonGroup toggle className="mb-2">
                          <ToggleButton
                            type="checkbox"
                            variant="toggle"
                            checked={values.ownership === "private"}
                            value="private"
                            onChange={(e) => setFieldValue("ownership", e.currentTarget.value)}
                          >
                            {t("privateCar")}
                          </ToggleButton>
                          <ToggleButton
                            type="checkbox"
                            variant="toggle"
                            checked={values.ownership === "company"}
                            value="company"
                            onChange={(e) => setFieldValue("ownership", e.currentTarget.value)}
                          >
                            {t("companyCar")}
                          </ToggleButton>
                        </ButtonGroup>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      <label className="form-label">{t("fuelType")}</label>
                      <div>
                        <ButtonGroup toggle className="mb-2">
                          <ToggleButton
                            type="checkbox"
                            variant="toggle"
                            checked={values.fuel_type === "petrol"}
                            value="petrol"
                            onChange={(e) => setFieldValue("fuel_type", e.currentTarget.value)}
                          >
                            {t("petrol")}
                          </ToggleButton>
                          <ToggleButton
                            type="checkbox"
                            variant="toggle"
                            checked={values.fuel_type === "diesel"}
                            value="diesel"
                            onChange={(e) => setFieldValue("fuel_type", e.currentTarget.value)}
                          >
                            {t("diesel")}
                          </ToggleButton>
                          <ToggleButton
                            type="checkbox"
                            variant="toggle"
                            checked={values.fuel_type === "electricity"}
                            value="electricity"
                            onChange={(e) => setFieldValue("fuel_type", e.currentTarget.value)}
                          >
                            {t("electricity")}
                          </ToggleButton>
                        </ButtonGroup>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <FormGroup.Input type="number" name="distance" label={t("longTrip")} required />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {values.distance > 0 && (
                      <Alert variant="primary">
                        {values.distance} km x {calculateRate(values)} SEK/km = {formatMoney(calculateAmount(values))}{" "}
                        SEK
                      </Alert>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup.Input as="textarea" name="trip_purpose" label={t("tripPurpose")} required />
                  </Col>
                </Row>
                <AllError errors={errors} />
              </Card.Body>
              <Card.Footer>
                <ButtonGroup>
                  {(!outlay.id || [""].includes(outlay.sub_status)) && (
                    <Button
                      variant="toggle-grey"
                      className="ml-2"
                      onClick={() => {
                        draftRef.current = true;
                        handleSubmit();
                      }}
                    >
                      <i className="fas fa-sticky-note" /> {t("common:actions.saveAsDraft")}
                    </Button>
                  )}
                  {(!outlay.id || ["", "disputed"].includes(outlay.sub_status)) && (
                    <Button
                      variant="toggle-grey"
                      className="ml-2"
                      onClick={() => {
                        investigateRef.current = true;
                        handleSubmit();
                      }}
                    >
                      {t("common:actions.sendToConsult")}
                    </Button>
                  )}
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    title={outlay.sub_status !== "" && !isCompanyConsult ? "actions.save" : "actions.saveToApproval"}
                  />
                </ButtonGroup>
              </Card.Footer>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default MileageForm;
