import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import * as yup from "yup";

import * as agencyAPI from "api2/agencies";
import { AllError, FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";

function AgencyForm({ agency }) {
  const { t } = useTranslation("common");

  const formikProps = {
    initialValues: {
      ...agency,
    },
    validationSchema: yup.object(),
    onSubmit: async (values, { setErrors }) => {
      await agencyAPI.general
        .update(agency.id, {
          ...values,
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, setFieldValue, errors }) => {
        return (
          <Form>
            <Card className="pt-2">
              <Card.Body>
                <Row>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("agencyName")} name="name" />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("contact.address")} name="address" />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("contact.zipCode")} name="zip_code" />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("contact.city")} name="city" />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("contact.phone")} name="phone" />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("contact.agencyInbox")} name="inbox_email" />
                  </Col>
                </Row>
                <Card.Title className="mt-2">{t("contact.contactPerson")}</Card.Title>
                <Row>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("contact.firstName")} name="contact_first_name" />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("contact.lastName")} name="contact_last_name" />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("contact.contactEmail")} name="contact_email" type="email" />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("contact.phone")} name="contact_phone" />
                  </Col>
                </Row>
                <AllError errors={errors} />
              </Card.Body>
              <Card.Footer>
                <SubmitButton isSubmitting={isSubmitting} />
              </Card.Footer>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default AgencyForm;
