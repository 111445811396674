import React, { useState } from "react";

import { Modal, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import _ from "lodash";
import { DraggableModalDialog } from "../DraggableModalDialog";
import BaseVerificationsPreviewContainer from "./BaseVerificationsPreviewContainer";

function AssetDepreciationsPreviewModal({ handleClose, fys_verifications }) {
  const { t } = useTranslation("ver");
  const fys_verifications_by_date_end = _.keyBy(fys_verifications, "date_end");
  const assetWithoutDepreciations = fys_verifications.length === 0;
  const [selectedFYEndDate, setFYEndDate] = useState(!assetWithoutDepreciations && fys_verifications[0].date_end);
  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={() => handleClose()}
      dialogClassName="task-modal"
      dialogAs={DraggableModalDialog}
      size="xl"
      className="verification-preview-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("previewVers")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!assetWithoutDepreciations && <h3>{t("navigation:financialYears")}</h3>}
        <Row className="overflow-y-auto">
          {fys_verifications.map((fy_data) => (
            <Col style={{ maxWidth: "140px" }} key={fy_data.date_end}>
              <span
                className={cx("btn badge badge-dark-light badge-pill", {
                  "badge-primary": selectedFYEndDate === fy_data.date_end,
                })}
                onClick={() => setFYEndDate(fy_data.date_end)}
              >
                {`${fy_data.date_start} - ${fy_data.date_end}`}
              </span>
            </Col>
          ))}
        </Row>
        <hr />
        <BaseVerificationsPreviewContainer
          verifications={
            !assetWithoutDepreciations ? fys_verifications_by_date_end[selectedFYEndDate].verifications : []
          }
        />
      </Modal.Body>
    </Modal>
  );
}

export default AssetDepreciationsPreviewModal;
