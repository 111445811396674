import React from "react";
import { ActionButton, AssetsDocumentsButton, TooltipActionButton } from "components/ui/buttons";
import useModal from "hooks/useModal";
import AssetHistoryLogsModal from "components/modals/historyLogs/AssetHistoryLogsModal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { confirmRemove } from "components/modals/ConfirmModal";
import useTable from "components/tables/btable/useTable";
import * as assetAPI from "api2/assets";

function AssetActions({ row, companyId }) {
  const historyModal = useModal();
  const { t } = useTranslation(["common", "asset", "historySnapshot"]);
  const {
    dataActions: { reload },
  } = useTable();
  const onDelete = async () => {
    const answer = await confirmRemove(
      t("asset:confirm.remove", {
        assetDescription: row.description,
      })
    );
    if (answer) {
      assetAPI
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reload();
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
    }
  };
  return (
    <>
      <ActionButton className="history-btn" icon="fas fa-history" onClick={historyModal.open} />
      <AssetsDocumentsButton companyId={companyId} assetId={row.id} documentsIds={row.documents} />
      {historyModal.show && (
        <AssetHistoryLogsModal companyId={companyId} assetId={row.id} handleClose={historyModal.close} t={t} />
      )}
      {["draft", "material_in_approval"].includes(row.status) && (
        <TooltipActionButton
          variant="action-red"
          text={t("common:actions.remove")}
          onClick={onDelete}
          icon="fas fa-trash"
        />
      )}
    </>
  );
}

export default AssetActions;
