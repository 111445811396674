import React from "react";

import * as supportApi from "api2/office-support";
import { withInitialAsync } from "hooks/useAsync";
import OfficeSupportRolesBankAttorneyForm from "./OfficeSupportRolesBankAttorneyForm";

function OfficeSupportRolesBankAttorneyWidget({ data: item, company, changeEditedForm }) {
  return (
    <OfficeSupportRolesBankAttorneyForm company={company} changeEditedForm={changeEditedForm} initialData={item} />
  );
}

const EnhancedOfficeSupportRolesBankAttorneyWidget = withInitialAsync(
  OfficeSupportRolesBankAttorneyWidget,
  ({ company }) =>
    React.useCallback((cancelToken) => supportApi.roles.bank.list(company.id, { cancelToken }), [company.id]),
  [],
  false,
  true
);

export default EnhancedOfficeSupportRolesBankAttorneyWidget;
