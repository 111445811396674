import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import useInitialAsync from "hooks/useInitialAsync";
import * as orderAPI from "api2/orders";
import { RocketLoader } from "components/ui/loaders";
import CustomerInvoiceForm from "components/forms/CustomerInvoiceForm";

function OrderDuplicatePage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const { id: orderId } = useParams();
  const { loading, item } = useInitialAsync(() => {
    return orderAPI.orderDetails(company.id, orderId);
  });

  const onCreate = (invoice) => {
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }
  const connectedProducts = item.connected_products || {};
  delete item.connected_products;
  return (
    <CustomerInvoiceForm
      invoice={{
        ...item,
        id: null,
        booking_date: new Date(),
        delivery_method: "Email",
      }}
      invoiceConnectedProducts={connectedProducts}
      company={company}
      onSave={onCreate}
      forOrder
    />
  );
}

export default OrderDuplicatePage;
