import client from "./client";

export const adminNotification = {
  list: (companyId, config) =>
    client.get("/notifications/admin/", {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  listPublic: (config) =>
    client.get("/notifications/admin/public/", {
      ...config,
    }),
  hide: (companyId, notificationId, config) =>
    client.delete(`/notifications/admin/${notificationId}/hide/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
};
