import client from "./client";

export const company = {
  yearToDateDetailed: (companyId, financialYearId, config) =>
    client.get("/stats/company/year-to-date/detailed/", {
      params: { financial_year: financialYearId },
      headers: { "x-company": companyId },
      ...config,
    }),
  materials: (companyId, config) =>
    client.get("/stats/company/materials/", {
      headers: { "x-company": companyId },
      ...config,
    }),
  authorities: (companyId, financialYearId, config) =>
    client.get("/stats/company/authorities/", {
      params: { financial_year: financialYearId },
      headers: { "x-company": companyId },
      ...config,
    }),
  materialsByStatus: (companyId, config) =>
    client.get("/stats/company/materials-by-status/", {
      headers: { "x-company": companyId },
      ...config,
    }),
};

function formatAgencyCompanyStat(response) {
  Object.keys(response.data.per_month_by_agency_id).forEach((_agencyId) => {
    response.data.stats_before_time_period_by_agency_id[_agencyId].total =
      response.data.stats_before_time_period_by_agency_id[_agencyId].activated +
      response.data.stats_before_time_period_by_agency_id[_agencyId].tt_only_activated;
    response.data.total_per_agency[_agencyId].total =
      response.data.total_per_agency[_agencyId].activated + response.data.total_per_agency[_agencyId].tt_only_activated;
    response.data.months.forEach((month) => {
      if (response.data.per_month_by_agency_id[_agencyId][month]) {
        response.data.per_month_by_agency_id[_agencyId][month].total =
          response.data.per_month_by_agency_id[_agencyId][month].activated +
          response.data.per_month_by_agency_id[_agencyId][month].tt_only_activated;
      }
    });
    response.data.months.forEach((month) => {
      if (response.data.total_per_month[month]) {
        response.data.total_per_month[month].total =
          response.data.total_per_month[month].activated + response.data.total_per_month[month].tt_only_activated;
      }
    });
    response.data.total.totalCurrent = response.data.total.activated + response.data.total.tt_only_activated;
    response.data.total.totalBefore =
      response.data.total.before_time_period_activated + response.data.total.before_time_period_activated_tt_only;
  });
  return response;
}

export const agency = {
  activeCompanies: (agencyId, numberOfMonths, config) =>
    client
      .get("/stats/agency/active-companies/", {
        params: { months: numberOfMonths },
        headers: { "x-agency": agencyId },
        timeout: 30000,
        ...config,
      })
      .then((response) => formatAgencyCompanyStat(response)),
};

export const admin = {
  allActiveCompanies: (numberOfMonths, config) =>
    client
      .get("/stats/admin/active-companies-all/", {
        params: { months: numberOfMonths },
        timeout: 30000,
        ...config,
      })
      .then((response) => formatAgencyCompanyStat(response)),
  verifications: (config) =>
    client
      .get("/stats/admin/verifications/", {
        ...config,
      })
      .then((response) => {
        response.data.newManualVerifications24months = response.data.new_manual_verifications_24_months;
        response.data.newVerifications24months = response.data.new_verifications_24_months;
        response.data.verificationsType24months = response.data.verifications_type_24_months;
        return response;
      }),
  manualVerifications: (selectedDate, config) =>
    client.get("/stats/admin/verifications-manual/", {
      params: { date: selectedDate },
      ...config,
    }),
  customerInvoices: (config) =>
    client
      .get("/stats/admin/customer-invoices/", {
        ...config,
      })
      .then((response) => {
        response.data.newCustomerInvoices24months = response.data.new_customer_invoices_24_months;
        response.data.newInternationalCustomerInvoices24months =
          response.data.new_international_customer_invoices_24_months;
        return response;
      }),
  supplierInvoices: (config) =>
    client
      .get("/stats/admin/supplier-invoices/", {
        ...config,
      })
      .then((response) => {
        response.data.newInternationalSupplierInvoices24months =
          response.data.new_international_supplier_invoices_24_months;
        response.data.newSupplierInvoices24months = response.data.new_supplier_invoices_24_months;
        return response;
      }),
  outlays: (config) =>
    client
      .get("/stats/admin/outlays/", {
        ...config,
      })
      .then((response) => {
        response.data.newReceipts24months = response.data.new_outlays_24_months;
        return response;
      }),
  salaries: (config) =>
    client
      .get("/stats/admin/salaries/", {
        ...config,
      })
      .then((response) => {
        response.data.newSalaries24months = response.data.new_salaries_24_months;
        return response;
      }),
  users: (config) =>
    client
      .get("/stats/admin/users/", {
        ...config,
      })
      .then((response) => {
        response.data.companyEmployeesPerCompany = response.data.company_employees_per_company;
        return response;
      }),
  others: (config) =>
    client
      .get("/stats/admin/others/", {
        ...config,
      })
      .then((response) => {
        response.data.newArchivesCountPerMonth = {
          archive_files: response.data.new_archives_count_per_month,
          company_archive_files: response.data.new_company_archives_count_per_month,
        };
        return response;
      }),
};
