import React, { useMemo, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { StepProgressBar } from "components/ui/progress";
import useInitialAsync from "hooks/useInitialAsync";
import { Loader } from "components/ui/loaders";
import DragProvider, { DraggableItem } from "state/providers/DragProvider";
import "./Checklist.scss";
import { toast } from "react-toastify";

function TextForm({ defaultValue = "", handleSubmit, handleReset }) {
  const { t } = useTranslation("common");
  const [text, setText] = useState(defaultValue);

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setText(defaultValue);
    if (handleSubmit) {
      if (text.trim() && text.trim() !== defaultValue.trim()) {
        handleSubmit(text);
      } else if (handleReset) {
        handleReset();
      }
    }
  };

  const onReset = () => {
    setText(defaultValue);
    if (handleReset) {
      handleReset();
    }
  };

  return (
    <form className="edit-form" noValidate onSubmit={onSubmit}>
      <div className="form-group">
        <input
          type="text"
          name="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          className="form-control"
          placeholder={t("common:actions.add")}
          autoComplete="off"
          autoCorrect="off"
        />
      </div>
      {(text || defaultValue) && (
        <div className="controls">
          <Button type="submit" variant="toggle-grey" disabled={!text}>
            <i className="fe-check" />
          </Button>
          <Button className="ml-1" variant="toggle-grey" onClick={onReset}>
            <i className="fe-x" />
          </Button>
        </div>
      )}
    </form>
  );
}

function Checkbox({ item, disabled, handleChange }) {
  const ref = useRef(null);
  const key = `text_${item.id}`;

  const onChange = (e) => {
    handleChange({ ...item, finished: e.target.checked });
  };

  return (
    <div className="checkbox checkbox-gray d-block" onClick={() => !disabled && ref.current.click()}>
      <input
        ref={ref}
        type="checkbox"
        id={key}
        name={key}
        onChange={onChange}
        disabled={disabled}
        checked={item.finished}
      />
      <label className="mb-0">&nbsp;</label>
    </div>
  );
}

function ChecklistItem({
  item,
  checkDisabled,
  inEditMode,
  handleRemove,
  handleChange,
  enableEditMode,
  disableEditMode,
  showControls,
}) {
  if (inEditMode) {
    return (
      <div className="checklist-item">
        <TextForm
          defaultValue={item.text}
          handleSubmit={(text) => handleChange({ ...item, text })}
          handleReset={disableEditMode}
        />
      </div>
    );
  }
  return (
    <div className="checklist-item">
      <Checkbox item={item} handleChange={handleChange} disabled={checkDisabled} />
      <div className={cx("text", { finished: item.finished })} onClick={enableEditMode}>
        {item.text}
      </div>
      {showControls && (
        <div className="item-controls" onClick={handleRemove}>
          <div className="remove">
            <i className="fe-trash" />
          </div>
          <div className="move">
            <i className="fe-more-vertical" />
          </div>
        </div>
      )}
    </div>
  );
}

function ChecklistWrapper({
  dataSource,
  actions,
  dirty,
  setDirty,
  checkDisabled = false,
  dragEnabled = false,
  showControls = false,
  forEvent = false,
}) {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(null);
  const [refreshCount, setRefreshCount] = useState(1);
  const {
    loading,
    item: checklist,
    set: setChecklist,
  } = useInitialAsync(
    ({ cancelToken }) =>
      dataSource({ cancelToken }).then((response) => {
        response.data.forEach((item) => {
          item.key = item.id;
        });
        return response;
      }),
    [],
    [dataSource, refreshCount]
  );
  const currentProgress = useMemo(() => checklist.filter((item) => item.finished).length, [checklist]);

  const handleSwapItems = (originIndex, targetIndex) => {
    const newChecklist = [...checklist];
    const dragItemContent = newChecklist[originIndex];
    const dragOverItemContent = newChecklist[targetIndex];
    newChecklist[originIndex] = { ...dragItemContent, duplicate: true };
    if (Math.abs(targetIndex - originIndex) === 1) {
      newChecklist[originIndex] = dragOverItemContent;
      newChecklist[targetIndex] = dragItemContent;
    } else {
      if (targetIndex === checklist.length - 1) {
        newChecklist.splice(targetIndex + 1, 0, { ...dragItemContent });
      } else {
        newChecklist.splice(targetIndex, 0, { ...dragItemContent });
      }
      const duplicateIndex = _.findIndex(newChecklist, (item) => item.duplicate);
      newChecklist.splice(duplicateIndex, 1);
    }

    for (let i = 0; i < newChecklist.length; i++) {
      newChecklist[i].priority = 1000 - i * 10;
    }
    setChecklist(newChecklist);
    if (setDirty) {
      setDirty(true);
    }
  };

  const handleDeleteAll = () => {
    setChecklist([]);
    if (setDirty) {
      setDirty(true);
    }
  };

  const handleRemoveItem = (index, item) => {
    const newChecklist = [...checklist];
    newChecklist.splice(index, 1);
    setChecklist(newChecklist);
    setEditMode(null);
    if (setDirty) {
      setDirty(true);
    }
  };

  const handleChangeItem = (index, item) => {
    const newChecklist = [...checklist];
    if (forEvent) {
      actions.update(item);
    }
    newChecklist[index] = item;
    setChecklist(newChecklist);
    setEditMode(null);
    if (setDirty) {
      setDirty(true);
    }
  };

  const handleCreate = (text) => {
    setChecklist([
      ...checklist,
      {
        id: null,
        key: _.uniqueId(),
        text,
        finished: false,
        priority: checklist.length ? checklist[checklist.length - 1].priority - 10 : 100,
      },
    ]);
    if (setDirty) {
      setDirty(true);
    }
  };
  const handleSubmit = () => {
    if (setDirty) {
      setDirty(false);
    }
    actions
      .update(checklist)
      .then(() => {
        toast.success(t("msg:saved"));
      })
      .catch(() => {
        if (setDirty) {
          setDirty(true);
        }
      });
  };

  const handleReset = () => {
    setRefreshCount((c) => c + 1);
    if (setDirty) {
      setDirty(false);
    }
  };

  const enableEditMode = (index, item) => {
    if (!showControls) {
      return;
    }
    if (!item.finished) {
      setEditMode(index);
    }
  };

  return (
    <div className="sl-checklist">
      {!checkDisabled && <StepProgressBar current={currentProgress} max={checklist.length} />}
      {showControls && <TextForm handleSubmit={handleCreate} />}
      <div style={{ height: 520 }}>
        {loading ? (
          <Loader />
        ) : (
          <Scrollbars style={{ height: 520 }}>
            <DragProvider handleSwapItems={handleSwapItems}>
              {checklist.map((item, index) => (
                <DraggableItem key={item.key} dragEnabled={dragEnabled && editMode === null} index={index}>
                  <ChecklistItem
                    item={item}
                    checkDisabled={checkDisabled}
                    inEditMode={editMode === index}
                    enableEditMode={() => enableEditMode(index, item)}
                    disableEditMode={() => setEditMode(null)}
                    handleRemove={() => handleRemoveItem(index, item)}
                    handleChange={(newItem) => handleChangeItem(index, newItem)}
                    showControls={showControls}
                  />
                </DraggableItem>
              ))}
            </DragProvider>
          </Scrollbars>
        )}
      </div>
      {showControls && (
        <div className="d-flex justify-content-between">
          <Button variant="link" className="text-danger" onClick={handleDeleteAll} disabled={checklist.length === 0}>
            {t("common:actions.deleteAll")}
          </Button>
          <div>
            <Button variant="link" className="text-dark" disabled={!dirty} onClick={handleReset}>
              {t("common:actions.cancel")}
            </Button>
            <Button variant="primary" disabled={!dirty} onClick={handleSubmit}>
              {t("common:actions.save")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChecklistWrapper;
