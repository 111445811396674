import React, { useContext, useEffect } from "react";
import { Form, Formik, useFormikContext } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { handleFormErrors } from "api/errors";
import * as options from "api/options";
import { AllError, FormGroup } from "components/formik";
import { CompanyLogoForm } from "components/forms/company-settings-forms";
import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import { OnboardingDispatchContext } from "pages/companies/OnboardingWizard/provider/OnboardingProvider";
import { withInitialAsync } from "hooks/useAsync";
import * as companyApi from "api2/companies";
import InvoicingDetailsButtons from "./InvoicingDetailsButtons";

function InvoicingDetailsStep({ data: item, companyId, agencyId, initialLoading }) {
  const { fetchDetails } = useContext(OnboardingDispatchContext);

  useEffect(() => {
    if (!initialLoading) {
      fetchDetails();
    }
  }, [initialLoading, fetchDetails]);

  const formikProps = {
    initialValues: {
      ...item,
      payment_method: options.companyPaymentMethods.getOption(item.payment_method),
      payment_provider: options.paymentProviders.getOption(item.payment_provider),
      plusgiro_no: item?.plusgiro_no || "",
      customer_invoice_enabled:
        item.customer_invoice_enabled !== undefined && item.customer_invoice_enabled !== null
          ? item.customer_invoice_enabled
          : true,
      default_invoice_payment_terms: options.paymentTerms.getOption(item?.default_invoice_payment_terms),
      billecta_reminder_fee: options.invoiceReminderFees.getOption(item?.billecta_reminder_fee),
      logo: item?.logo_url,
    },
    validationSchema: yup.object().shape({
      contact_first_name: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      contact_last_name: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      contact_email: yup
        .string()
        .email()
        .when("customer_invoice_enabled", {
          is: true,
          then: () => yup.string().required(),
          otherwise: () => yup.string().notRequired(),
        }),
      contact_phone: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      address: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      city: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      zip_code: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      phone: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      bankgiro_no: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when(["payment_method", "payment_provider"], ([paymentMethod, paymentProvider], schema) => {
            const isBankgiro = paymentMethod && paymentMethod.value === "bankgiro";
            const isBgc = paymentProvider && paymentProvider.value === "bgc";
            if (isBankgiro || isBgc) {
              return schema.required();
            }
            return schema.notRequired();
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      plusgiro_no: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when("payment_method", {
            is: (method) => method && method.value === "plusgiro",
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      bic: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when("payment_method", {
            is: (method) => method && method.value === "bankaccount",
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      iban: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when("payment_method", {
            is: (method) => method && method.value === "bankaccount",
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      clearing_number: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when("payment_method", {
            is: (method) => method && method.value === "bankaccount",
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      bank_account_number: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when("payment_method", {
            is: (method) => method && method.value === "bankaccount",
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      preliminary_tax: yup.number().max(0),
      next_invoice_number: yup.number().positive(),
    }),
    onSubmit: (values, { setErrors }) => {
      return companyApi.onboardings.steps
        .invoicingDetailsFinish(companyId, agencyId, {
          ...values,
          payment_method: values.payment_method.value,
          default_invoice_payment_terms: values.default_invoice_payment_terms.value,
          billecta_reminder_fee: values.billecta_reminder_fee.value,
        })
        .then(() => {
          fetchDetails(true);
        })
        .catch((error) => {
          handleFormErrors(error, setErrors);
        });
    },
  };

  const handleSkip = (setErrors) => {
    companyApi.onboardings.steps
      .invoicingDetailsSkip(companyId, agencyId, null)
      .then(() => {
        fetchDetails(true);
      })
      .catch((error) => {
        handleFormErrors(error, setErrors);
      });
  };
  const isCustomerInvoiceEnabled = item?.customer_invoice_enabled || false;
  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors, setErrors, handleSubmit }) => {
        return (
          <Card data-testid="invoicing-details-step">
            <Card.Body>
              <StepHeader
                rightComponent={
                  <InvoicingDetailsButtons
                    isCustomerInvoiceEnabled={isCustomerInvoiceEnabled}
                    isSubmitting={isSubmitting}
                    handleSubmit={handleSubmit}
                    handleSkip={() => handleSkip(setErrors)}
                  />
                }
              />
            </Card.Body>
            <Card.Body>
              <InvoicingDetailsForm isCustomerInvoiceEnabled={isCustomerInvoiceEnabled} />
              <AllError errors={errors} />
            </Card.Body>
            <CompanyLogoForm companyId={companyId} currentLogo={values.logo} canEditLogo />
          </Card>
        );
      }}
    </Formik>
  );
}

function InvoicingDetailsForm() {
  const { t } = useTranslation("company");
  const { values, setFieldValue } = useFormikContext();
  const companyPaymentMethods = options.companyPaymentMethods.asList();
  const paymentTerms = options.paymentTermsOptionsWithoutDefault();
  const reminderFeeOptions = options.invoiceReminderFees.asList();
  const onBlurContactPhone = () => {
    if (!values.phone && values.contact_phone) {
      setFieldValue("phone", values.contact_phone);
    }
  };
  return (
    <Form noValidate>
      <h5>{t("onboarding.invoicingInformationContact")}</h5>
      <Row>
        <Col sm={6} xl={3}>
          <FormGroup.Input label={t("common:contact.firstName")} name="contact_first_name" required />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input label={t("common:contact.lastName")} name="contact_last_name" required />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input label={t("common:contact.emailLong")} name="contact_email" type="email" required />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input
            label={t("common:contact.phone")}
            name="contact_phone"
            required
            onBlur={onBlurContactPhone}
          />
        </Col>
      </Row>
      <h5>{t("onboarding.invoicingInformationAddress")}</h5>
      <Row>
        <Col sm={6} xl={3}>
          <FormGroup.Input label={t("common:contact.address")} name="address" required />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input label={t("common:contact.zipCode")} name="zip_code" required />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input label={t("common:contact.city")} name="city" required />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input label={t("common:contact.phone")} name="phone" required />
        </Col>
      </Row>
      <h5>{t("onboarding.invoicingInformation")}</h5>
      <Row>
        <Col sm={6} xl={3}>
          <FormGroup.Input label={t("nextInvoiceNumber")} name="next_invoice_number" />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.SimpleSelect
            name="default_invoice_payment_terms"
            label={t("defaultInvoicePaymentTerms")}
            options={paymentTerms}
          />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.SimpleSelect
            name="billecta_reminder_fee"
            label={t("invoiceReminderFee")}
            options={reminderFeeOptions}
          />
        </Col>
      </Row>
      <h5>{t("bankDetails")}</h5>
      <Row>
        <Col sm={6} xl={3}>
          <FormGroup.SimpleSelect
            name="payment_method"
            label={t("common:paymentMethod")}
            options={companyPaymentMethods}
          />
        </Col>
        {values.payment_method.value === "bankgiro" && (
          <Col sm={6} xl={3}>
            <FormGroup.Input label={t("common:bankGiroNo")} name="bankgiro_no" required />
          </Col>
        )}
        {values.payment_method.value === "plusgiro" && (
          <Col sm={6} xl={3}>
            <FormGroup.Input label={t("common:plusGiroNo")} name="plusgiro_no" required />
          </Col>
        )}
        <Col sm={6} xl={3}>
          <FormGroup.Input label="BIC" name="bic" required={values.payment_method.value === "bankaccount"} />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input label="IBAN" name="iban" required={values.payment_method.value === "bankaccount"} />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input
            type="number"
            name="clearing_number"
            label={t("common:clearingNo")}
            required={values.payment_method.value === "bankaccount"}
          />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input
            name="bank_account_number"
            label={t("common:bankAccountNo")}
            required={values.payment_method.value === "bankaccount"}
          />
        </Col>
      </Row>
    </Form>
  );
}

const EnhancedInvoicingDetailsStep = withInitialAsync(
  InvoicingDetailsStep,
  ({ companyId, agencyId }) => {
    const { fetchDetails } = useContext(OnboardingDispatchContext);
    return React.useCallback(
      (cancelToken) =>
        companyApi.onboardings.steps.invoicingDetails(companyId, agencyId, { cancelToken }).then(fetchDetails()),
      [companyId, agencyId, fetchDetails]
    );
  },
  {},
  true
);

export default EnhancedInvoicingDetailsStep;
