import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, Dropdown, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { subBusinessDays, subDays } from "date-fns";
import axios from "axios";

import { CheckboxFilter, SearchFilter, SelectSimpleFilter } from "components/filters";
import * as tableAPI from "api/table";
import * as trackerAPI from "api/time-tracker";
import { FiltersContext } from "state/providers/FiltersProvider";
import { FilterButton } from "components/ui/buttons";
import { formatDate, formatDay, formatDuration, isBefore, parseDate } from "utils/date";
import TaskFormModal from "components/modals/TaskFormModal";
import useModal from "hooks/useModal";
import { toast } from "react-toastify";
import { formatFilters } from "utils/others";
import confirmExtraCharge from "components/modals/ConfirmModal/ExtraChargeModal";
import confirmInternalFinish from "components/modals/ConfirmModal/InternalTaskFinishModal";
import { useAuthState } from "hooks/useAuth";
import { TimeTrackerStateContext } from "state/providers/TimeTrackerProvider";
import Task from "./Task";
import { getDaysTitle, getNextDays, startTaskTimer } from "./helpers";
import TrackCalendarHotKeys from "./TrackCalendarHotKeys";

function stopTask(stoppedTask, data, selectedCalendarDate) {
  stoppedTask.overdue = isBefore(parseDate(stoppedTask.deadline_date), selectedCalendarDate || new Date());
  if (stoppedTask.overdue) {
    const stopIdx = data.overdue.findIndex((_task) => _task.id === stoppedTask.id);
    if (stopIdx < 0) {
      // to treat as regular in days
      stopTask(stoppedTask, data, subDays(parseDate(stoppedTask.deadline_date), 1));
    } else {
      data.overdue[stopIdx].overdue = true;
      data.overdue[stopIdx].playing = false;
      data.overdue[stopIdx].duration = stoppedTask.duration;
      data.overdue[stopIdx].durationStr = formatDuration(stoppedTask.duration);
    }
  } else if (data.daily[stoppedTask.deadline_date]) {
    const stopIdx = data.daily[stoppedTask.deadline_date].findIndex((_task) => _task.id === stoppedTask.id);
    data.daily[stoppedTask.deadline_date][stopIdx].overdue = false;
    data.daily[stoppedTask.deadline_date][stopIdx].playing = false;
    data.daily[stoppedTask.deadline_date][stopIdx].duration = stoppedTask.duration;
    data.daily[stoppedTask.deadline_date][stopIdx].durationStr = formatDuration(stoppedTask.duration);
  }
}

function CalendarHeaders({ days }) {
  const { t, i18n } = useTranslation("tt");
  return (
    <thead>
      <tr>
        <th className="overdue">{t("overdue")}</th>
        {Object.values(days).map((day) => (
          <th key={`th.${day.key}`}>{formatDay(day.date, i18n.language)}</th>
        ))}
      </tr>
    </thead>
  );
}

function CalendarBody({ days, tasks, setTasks, openTask, reload }) {
  const { t } = useTranslation("tt");

  async function playTimer(event, task, index) {
    event.stopPropagation();
    const newData = await startTaskTimer(tasks, task, index);
    if (newData) {
      setTasks(newData);
    }
  }

  async function stopTimer(event, task) {
    event.stopPropagation();
    await trackerAPI
      .stopTimer(task.id)
      .then((response) => {
        const newData = {
          ...tasks,
          currentId: null,
          daily: { ...tasks.daily },
          overdue: [...tasks.overdue],
        };
        const stoppedTask = response.data;
        if (stoppedTask) {
          stopTask(stoppedTask, newData);
        }
        document.body.dispatchEvent(new Event("task/changed"));
        setTasks(newData);
      })
      .catch(() => {
        document.body.dispatchEvent(new Event("task/changed"));
      });
  }

  async function finish(event, task, index) {
    event.stopPropagation();
    if (task.permanent || task.finished) {
      return;
    }
    if (task.manually_created) {
      if (task.category !== "internal") {
        const { answer, comment } = await confirmExtraCharge();
        if (answer === undefined) {
          return;
        }
        await trackerAPI.finish(task.id, { extra_charge: comment || "" });
      } else {
        const { answer, comment } = await confirmInternalFinish(task);
        if (!answer) {
          return;
        }
        await trackerAPI.finish(task.id, { comment: comment || "" });
      }
    } else {
      await trackerAPI.finish(task.id, {});
    }
    if (task.overdue) {
      setTasks({
        ...tasks,
        currentId: null,
        overdue: [...tasks.overdue.slice(0, index), ...tasks.overdue.slice(index + 1)],
      });
    } else if (tasks.daily[task.deadline_date]) {
      setTasks({
        ...tasks,
        currentId: null,
        daily: {
          ...tasks.daily,
          [task.deadline_date]: [
            ...tasks.daily[task.deadline_date].slice(0, index),
            ...tasks.daily[task.deadline_date].slice(index + 1),
          ],
        },
      });
    }
    document.body.dispatchEvent(new Event("task/changed"));
    toast.success(t("taskFinished", { title: task.title }));
  }

  async function revertFinish(event, task, index) {
    event.stopPropagation();
    event.preventDefault();
    await trackerAPI.revertFinish(task.id);
    reload();
    toast.success(`'${task.title}' has been moved to NOT FINISHED`);
  }

  return (
    <tbody>
      <tr>
        <td>
          {tasks.overdue.map((task, index) => (
            <Task
              key={`task.${task.id}`}
              task={task}
              overdue
              playTimer={(event) => playTimer(event, task, index)}
              stopTimer={(event) => stopTimer(event, task, index)}
              finish={(event) => finish(event, task, index)}
              openTask={openTask}
            />
          ))}
        </td>
        {Object.values(days).map((day) => (
          <td key={`td.${day.key}`} data-day={day.key}>
            {tasks.daily[day.key] &&
              tasks.daily[day.key].map((task, index) => (
                <Task
                  key={`task.${task.id}`}
                  task={task}
                  overdue={false}
                  playTimer={(event) => playTimer(event, task, index)}
                  stopTimer={(event) => stopTimer(event, task, index)}
                  finish={(event) => finish(event, task, index)}
                  revertFinish={(event) => revertFinish(event, task, index)}
                  openTask={openTask}
                />
              ))}
          </td>
        ))}
      </tr>
    </tbody>
  );
}

function DaySwitcher({ days, setDays, numberOfDays, title }) {
  const prevDays = () => {
    const daysList = Object.values(days);
    const _days = getNextDays(subBusinessDays(daysList[0].date, numberOfDays - 1), numberOfDays);
    setDays(_days);
  };

  const nextDays = () => {
    const daysList = Object.values(days);
    const _days = getNextDays(daysList[numberOfDays - 1].date, numberOfDays);
    setDays(_days);
  };

  const today = () => {
    setDays(getNextDays(new Date(), numberOfDays));
  };

  return (
    <div className="day-switcher">
      <ButtonGroup>
        <Button variant="toggle" title="Prev" onClick={prevDays}>
          <i className="fe-chevron-left" />
        </Button>
        <Button variant="toggle" title="Today" className="now" onClick={today}>
          {title}
        </Button>
        <Button variant="toggle" title="Next" onClick={nextDays}>
          <i className="fe-chevron-right" />
        </Button>
      </ButtonGroup>
    </div>
  );
}

function TrackCalendar({ numberOfDays = 5 }) {
  const { t, i18n } = useTranslation("tt");
  const { options, selected: selectedAgency } = useContext(TimeTrackerStateContext);
  const { filters, updateFilter } = useContext(FiltersContext);
  const { user } = useAuthState();
  const taskModal = useModal();
  const [counter, setCounter] = useState(1); // to refresh
  const [days, setDays] = useState(getNextDays(new Date(), numberOfDays, i18n.language));
  const internalTasks = useMemo(
    () => [
      "Development - Bflow",
      "Non deb time - system fault",
      "Non deb time - human error",
      "Internal meeting",
      "Fika",
      "Education",
    ],
    []
  );

  const [tasks, setTasks] = useState({
    overdue: [],
    daily: {},
    currentId: null,
  });
  const title = useMemo(() => getDaysTitle(days, i18n.language), [days, i18n.language]);
  const filtersCount = !!filters.user + !!filters.company;
  const [more, setMore] = useState(false);

  useEffect(() => {
    document.body.addEventListener("work/stopped", () => {
      setCounter((prevCounter) => prevCounter + 1);
    });
  }, []);

  useEffect(() => {
    const daysList = Object.values(days);
    const signal = axios.CancelToken.source();
    tableAPI
      .trackerTasks(
        {
          agency: selectedAgency.agency,
          ...formatFilters(filters),
          deadline_date__start: daysList[0].key,
          deadline_date__end: daysList[daysList.length - 1].key,
        },
        { cancelToken: signal.token }
      )
      .then((response) => {
        setTasks({
          overdue: response.data.outdated_tasks,
          daily: response.data.tasks_by_day,
          currentId: response.data.running_task_id,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          // pass
        }
      });
    return function cleanup() {
      signal.cancel("aborted");
    };
  }, [days, selectedAgency.agency, filters, counter]);

  const onTaskSave = (taskData, created) => {
    taskModal.close();
    updateFilter({ counter: (filters.counter || 0) + 1 });
  };

  const reload = () => {
    setCounter((state) => state + 1);
  };

  const addAndTick = (taskName) => {
    trackerAPI
      .save({
        title: taskName,
        deadline_date: formatDate(new Date()),
        company: selectedAgency.main_company_id,
        assignee: user.id,
        comment: "",
        category: "internal",
      })
      .then(async (response) => {
        await trackerAPI.startTimer(response.data.id).catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          }
        });
        document.body.dispatchEvent(new Event("task/changed"));
        onTaskSave();
      });
  };

  return (
    <div id="tt-calendar">
      <TrackCalendarHotKeys addAndTick={addAndTick} />
      <div className="table-filters-group pl-2 pt-2">
        <section className="table-filters-left">
          <SearchFilter onFilter={updateFilter} defaultValue={filters.term} width={300} />
          <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
          <DaySwitcher title={title} days={days} setDays={setDays} numberOfDays={numberOfDays} />
        </section>
        <section className="table-filters-right mr-2">
          <Dropdown>
            <Dropdown.Toggle variant="blue" className="dropdown-toggle mr-0">
              <i className="fas fa-plus" /> {t("common:actions.create")}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-right">
              <Dropdown.Item
                onClick={() =>
                  taskModal.open({
                    company_id: filters.company,
                    assignee: filters.user,
                    deadline_date: formatDate(new Date()),
                    comment: "",
                    title: "",
                    category: "custom",
                  })
                }
              >
                {t("customTask")}
              </Dropdown.Item>
              {internalTasks.map((taskName) => (
                <Dropdown.Item key={taskName} onClick={() => addAndTick(taskName)}>
                  {taskName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </section>
        {more && (
          <section className="more-filters">
            <SelectSimpleFilter
              label={t("common:company")}
              defaultValue={filters.company}
              options={options.companies}
              isClearable
              name="company"
              onFilter={updateFilter}
            />
            <SelectSimpleFilter
              label={t("assignee")}
              defaultValue={filters.user}
              options={options.consults}
              isClearable={false}
              name="user"
              onFilter={updateFilter}
            />
            <CheckboxFilter
              wrapperClass="hacked-checkbox"
              title={t("finishedOnly")}
              name="finished"
              defaultChecked={filters.finished}
              onFilter={updateFilter}
            />
          </section>
        )}
      </div>
      <div className="table-wrapper">
        <Table bordered className="sticky-header">
          <CalendarHeaders days={days} />
          <CalendarBody
            days={days}
            tasks={tasks}
            setTasks={setTasks}
            reload={reload}
            openTask={(task) => taskModal.open(task)}
          />
        </Table>
      </div>
      {taskModal.show && (
        <TaskFormModal handleClose={taskModal.close} handleSave={onTaskSave} task={taskModal.data} options={options} />
      )}
    </div>
  );
}

export default TrackCalendar;
