import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import { Button, Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { FormGroup } from "components/formik";
import * as yup from "yup";

import { SubmitButton } from "components/ui/buttons";
import * as supportAPI from "api2/office-support";

function OfficeSupportContactPersonForm({ companyId, changeEditedForm, data }) {
  const [showMore, setShowMore] = useState(true);
  const { t } = useTranslation("company");
  const emptyPerson = {
    id: null,
    name: "",
    email: "",
    roles: [],
    phone: "",
    is_business_owner: false,
  };
  const getInitialValues = (_data) => {
    if (_data.length === 0) {
      return {
        persons: [emptyPerson],
      };
    }
    return {
      persons: _data.map((person) => ({
        ...person,
      })),
    };
  };
  const formikProps = {
    initialValues: getInitialValues(data),
    validationSchema: yup.object().shape({
      persons: yup.array().of(
        yup.object().shape({
          name: yup.string().required(),
          email: yup.string().email(),
        })
      ),
    }),
    onSubmit: async (values, { setErrors, setValues, resetForm }) => {
      await supportAPI.contacts
        .updateBulk(companyId, [
          ...values.persons.map((person) => ({
            ...person,
          })),
        ])
        .then((response) => {
          const newInitialValues = getInitialValues(response.data);
          resetForm({ values: newInitialValues });
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors({ ...error.data, persons: error.data });
        });
    },
  };
  const onRemove = (person, arrayRemove, idx, resetForm, values) => {
    arrayRemove(idx);
    // on remove when the only one is the fresh to add, then remove item and show empty form (also FormUpdateChecker works ok)
    if (values.persons.length === 1 && !values.persons[0].id) {
      resetForm({ ...emptyPerson });
    }
  };

  function FormUpdateChecker() {
    const { dirty } = useFormikContext();
    React.useEffect(() => {
      changeEditedForm({ id: "contact-person", dirty });
    }, [dirty]);
    return null;
  }

  return (
    <Formik {...formikProps}>
      {({ values, touched, isSubmitting, setFieldValue, resetForm, dirty }) => {
        return (
          <Form>
            <FormUpdateChecker />
            <Card>
              <Card.Header onClick={() => setShowMore((prevValue) => !prevValue)}>
                <Card.Title style={{ color: "#ADADAD", fontWeight: 400, fontSize: "12px" }}>
                  {t("header.contactPersons")}
                  <i
                    style={{ textAlign: "right", float: "right" }}
                    className={showMore ? "fas fa-chevron-up fa-1x" : "fas fa-chevron-down fa-1x"}
                  />
                </Card.Title>
              </Card.Header>
              {showMore && (
                <Card.Body className="pt-0">
                  <FieldArray
                    name="persons"
                    render={({ remove, push }) => (
                      <div>
                        {values.persons.map((person, idx) => (
                          <React.Fragment key={idx}>
                            <Row key={`m.${idx}`}>
                              <Col>
                                {(person.id || dirty) && (
                                  <>
                                    {idx + 1}.
                                    <Button style={{ color: "#FD6767" }} variant="text" size="lg" className="pl-1">
                                      <i
                                        className="fe-trash"
                                        onClick={() => {
                                          onRemove(person, remove, idx, resetForm, values);
                                        }}
                                      />
                                    </Button>
                                  </>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup.Input
                                  label={t("common:contact.contactName")}
                                  name={`persons[${idx}].name`}
                                  required
                                />
                              </Col>
                              <Col>
                                <FormGroup.Input
                                  label={t("common:contact.contactEmail")}
                                  name={`persons[${idx}].email`}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup.Input label={t("common:contact.phone")} name={`persons[${idx}].phone`} />
                              </Col>
                              <Col className="mt-3">
                                <FormGroup.Checkbox
                                  label={t("common:businessOwner")}
                                  name={`persons[${idx}].is_business_owner`}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup.MultiText
                                  name={`persons[${idx}].roles`}
                                  label={t("common:roles")}
                                  required={false}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        ))}
                        {values.persons.length <= 5 && (
                          <Row className="pt-3">
                            <Col className="pl-0">
                              <Button
                                style={{ color: "#6c757d" }}
                                color=""
                                variant="text"
                                onClick={() => push(emptyPerson)}
                              >
                                <i style={{ color: "#0B1957" }} className="fas fa-plus" /> {t("addContactPerson")}
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </div>
                    )}
                  />
                  <Row className="pt-2">
                    <Col className="text-right">
                      {dirty && (
                        <Button variant="link" size="lg" onClick={() => resetForm()} className="cancel-btn">
                          {t("common:actions.cancel")}
                        </Button>
                      )}
                      <SubmitButton isSubmitting={isSubmitting} disabled={!dirty} />
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default OfficeSupportContactPersonForm;
