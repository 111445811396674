import React from "react";
import { Button, Table } from "react-bootstrap";

import { formatMoney } from "utils/money";
import { useTranslation } from "react-i18next";
import { useVerificationDispatch } from "hooks/useVerification";
import LedgerRowActions from "./LedgerRowActions";

function LedgerRow({ ver, companyId, handleAddVerToSpec, handleVerCheck, openVerificationModal }) {
  return (
    <tr>
      <td>
        <Button variant="link" className="p-0 m-0" onClick={() => openVerificationModal(companyId, { id: ver.id })}>
          {ver.verification}
        </Button>
      </td>
      <td>{ver.booking_date}</td>
      <td>{ver.title}</td>
      <td>{ver.transaction_title}</td>
      <td className="text-right">{ver.debit}</td>
      <td className="text-right">{ver.credit}</td>
      <td className="text-right">{formatMoney(ver.balance)}</td>
      <td className="td-actions">
        <LedgerRowActions
          hasSpec={!!ver.spec}
          isChecked={ver.is_checked}
          handleAddVerToSpec={() => handleAddVerToSpec(ver, ver.targetIndex)}
          handleVerCheck={() => handleVerCheck(ver, ver.targetIndex)}
        />
      </td>
    </tr>
  );
}

function LedgerTable({ ledger, companyId, handleAddVerToSpec, handleVerCheck }) {
  const { t } = useTranslation("reports");
  const { open: openVerificationModal } = useVerificationDispatch();
  const ledgerRows = ledger.groups.map((ver) => (
    <LedgerRow
      key={ver.transaction_id}
      ver={ver}
      companyId={companyId}
      handleAddVerToSpec={handleAddVerToSpec}
      handleVerCheck={handleVerCheck}
      openVerificationModal={openVerificationModal}
    />
  ));

  return (
    <Table bordered className="sticky-header ledger-report">
      <thead>
        <tr>
          <th colSpan={8}>{t("ledgerReport")}</th>
        </tr>
        <tr className="parent-category--name sticky1">
          <th colSpan={8}>{ledger.title}</th>
        </tr>
        <tr className="sticky2">
          <th>{t("ver")}</th>
          <th>{t("common:dates.date")}</th>
          <th>{`${t("common:verShort")} ${t("common:name")}`}</th>
          <th>{t("common:description")}</th>
          <th>{t("common:money.debit")}</th>
          <th>{t("common:money.credit")}</th>
          <th>{t("common:money.balance")}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t("initialBalance")}</td>
          <td />
          <td />
          <td />
          <td className="text-right">{formatMoney(ledger.initialBalance.debit)}</td>
          <td className="text-right">{formatMoney(ledger.initialBalance.credit)}</td>
          <td className="text-right">{formatMoney(ledger.initialBalance.sum)}</td>
          <td />
        </tr>
        {ledgerRows}
        <tr>
          <td>{t("closingBalance")}</td>
          <td />
          <td />
          <td />
          <td className="text-right">{formatMoney(ledger.endingBalance.debit)}</td>
          <td className="text-right">{formatMoney(ledger.endingBalance.credit)}</td>
          <td className="text-right">{formatMoney(ledger.endingBalance.sum)}</td>
          <td />
        </tr>
      </tbody>
    </Table>
  );
}

export default LedgerTable;
