import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import _ from "lodash";

import * as companyAPI from "api2/companies";
import AdminForm, { ExtraActions } from "components/forms/company-settings-forms/AdminForm";

import { withInitialAsync } from "hooks/useAsync";

function AdminFormPage({ data: company }) {
  const { companyId } = useParams();
  const ttOnly = company.status === "basic";

  const accounts = company.bank_accounts.map((account) => ({
    id: account.number,
    value: account.number,
    label: `${account.number} - ${account.name}`,
  }));

  const bgcAccounts = {
    asList: accounts,
    byId: _.keyBy(accounts, (account) => account.id),
  };
  // delete company.bank_accounts;

  return (
    <>
      <Row>
        <Col>
          <AdminForm ttOnly={ttOnly} company={company} bgcAccounts={bgcAccounts} />
        </Col>
      </Row>
      {!ttOnly && <ExtraActions companyId={companyId} />}
    </>
  );
}

const EnhancedAdminFormPage = withInitialAsync(
  AdminFormPage,
  () => {
    const { companyId } = useParams();
    return React.useCallback((cancelToken) => companyAPI.advancedDetail(companyId, { cancelToken }), [companyId]);
  },
  {},
  true
);

export default EnhancedAdminFormPage;
