import React from "react";
import { codesFor, HasPermCode } from "components/perms";
import OutlayListPage from "./OutlayListPage";

function ApproveOutlayListPage() {
  const allowedModes = [
    { value: "approvalAndFix", label: "common:statuses.all", variant: "toggle" },
    { value: "approval", label: "common:statuses.prelim", variant: "toggle-orange" },
    { value: "fix", label: "common:statuses.draft", variant: "toggle-grey" },
    { value: "my-investigate", label: "common:statuses.investigate", variant: "toggle-purple" },
  ];
  const canApproveOutlays = HasPermCode(codesFor.outlays.approve);
  return (
    <OutlayListPage
      tableId="prelimOutlays"
      initialMode={allowedModes[0].value}
      allowedModes={allowedModes}
      selectable={canApproveOutlays}
    />
  );
}

export default ApproveOutlayListPage;
