import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useTable from "components/tables/btable/useTable";
import { DateRangeFilters, HistoryUserFilter, SearchFilter, SelectSimpleFilter } from "components/filters";
import { Button, ButtonGroup } from "react-bootstrap";
import qs from "qs";
import { formatDate } from "utils/date";
import FileUrlModal from "components/modals/FileUrlModal/FileUrlModal";
import { FilterButton } from "../../ui/buttons";
import useModal from "../../../hooks/useModal";

function HistoryFilters({ companyId }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation();
  const filtersCount = !!filters.receiver + !!filters.payment_date;
  const [more, setMore] = useState(!!filtersCount);
  const CSVModal = useModal();
  const PDFModal = useModal();

  const getParams = () => {
    return qs.stringify({
      created_start: formatDate(filters.created_start),
      created_end: formatDate(filters.created_end),
      user_id: filters.user_id,
      section: filters.section,
      term: filters.term,
      ordering: filters.ordering,
    });
  };

  const openCSVModal = () => {
    const params = getParams();
    return CSVModal.open(`/history/csv/?${params}`);
  };

  const openPDFModal = () => {
    const params = getParams();
    return PDFModal.open(`/history/pdf/?${params}`);
  };

  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
      </section>
      <section className="table-filters-right">
        <div className="form-group">
          <div>
            <ButtonGroup>
              <Button variant="secondary" onClick={openPDFModal}>
                <i className="fas fa-file-pdf" /> {t("reports:reportPDF")}
              </Button>
              <Button variant="secondary" onClick={openCSVModal}>
                <i className="fas fa-file-csv" /> {t("reports:reportCSV")}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </section>
      {more && (
        <section className="more-filters">
          <DateRangeFilters
            label={t("common:dates.period")}
            dateStart={filters.created_start}
            dateEnd={filters.created_end}
            onChange={({ start, end }) => setFilters({ created_start: start, created_end: end })}
          />
          <HistoryUserFilter
            label={t("common:user")}
            isClearable
            companyId={companyId}
            name="user_id"
            onFilter={setFilters}
            defaultValue={filters.user_id}
          />
          <SelectSimpleFilter
            isClearable
            label={t("history:partOfSystem")}
            name="section"
            onFilter={setFilters}
            defaultValue={filters.section}
            options={[
              { label: t("history:absences"), value: "absences" },
              { label: t("history:asset"), value: "asset" },
              { label: t("navigation:bankMatching"), value: "bank_matching" },
              { label: t("history:companySettings"), value: "company_settings" },
              { label: t("navigation:customerInvoices"), value: "customer_invoices" },
              { label: t("navigation:directPayments"), value: "direct_payment" },
              { label: t("common:documents"), value: "documents" },
              { label: t("company:euVAT"), value: "eu_vat" },
              { label: t("history:offer"), value: "offer" },
              { label: t("navigation:outlays"), value: "outlays" },
              { label: t("history:payments"), value: "payments" },
              { label: t("common:periodisation"), value: "periodisation" },
              { label: t("history:salary"), value: "salary" },
              { label: t("navigation:supplierInvoices"), value: "supplier_invoices" },
              { label: t("navigation:taxAuthorities"), value: "tax_authorities" },
              { label: t("history:taxDirectPayment"), value: "tax_direct_payment" },
              { label: t("navigation:users"), value: "users" },
              { label: t("company:vatReport"), value: "vat_report" },
              { label: t("navigation:verifications"), value: "verifications" },
            ]}
          />
        </section>
      )}
      {CSVModal.show && (
        <FileUrlModal
          companyId={companyId}
          fileUrl={CSVModal.data}
          downloadFileName="history_report.csv"
          handleClose={CSVModal.close}
        />
      )}
      {PDFModal.show && (
        <FileUrlModal
          companyId={companyId}
          fileUrl={PDFModal.data}
          downloadFileName="history_report.pdf"
          handleClose={PDFModal.close}
        />
      )}
    </>
  );
}

export default HistoryFilters;
