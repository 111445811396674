import React from "react";
import { TooltipActionButton, TooltipActionLink } from "components/ui/buttons";
import { useTranslation } from "react-i18next";
import { FileUrlModal } from "components/modals";
import useModal from "hooks/useModal";

function AgencyActions({ row }) {
  const { t } = useTranslation("navigation");
  const fileModal = useModal();

  const downloadAgencyData = () => {
    fileModal.open(`/agencies/contacts/csv/`);
  };

  return (
    <>
      <TooltipActionButton text={t("common:actions.download")} onClick={downloadAgencyData} icon="fas fa-download" />
      <TooltipActionLink text={t("settings")} icon="fa fa-cog" link={`/agencies/${row.id}/settings`} />
      {fileModal.show && (
        <FileUrlModal
          fileUrl={fileModal.data}
          handleClose={fileModal.close}
          downloadFileName="agency.csv"
          extraHeaders={{ "x-agency": row.id }}
          autoDownload
        />
      )}
    </>
  );
}

export default AgencyActions;
