import React from "react";
import { FieldArray, useFormikContext } from "formik";
import { Alert, Col, Row, Table } from "react-bootstrap";
import { FormGroup, TableGroup } from "components/formik";
import { TooltipActionButton } from "components/ui/buttons";
import * as options from "api/options";

function RepresentationForm({ t, isNonDeductibleRepresentationOn6071 }) {
  const repActivities = options.repActivities.asList();
  const repPurposes = options.repPurposes.asList();
  const { values } = useFormikContext();
  if (!values.account || ![6071, 6072].includes(values.account.number)) {
    return null;
  }
  function filterActivites(option) {
    if (values.account?.number === 6071) {
      return option.value === "3"; // lunch dinner
    }
    return true;
  }
  return (
    <fieldset>
      <h5>{t("common:rep.title")}</h5>
      {isNonDeductibleRepresentationOn6071 && (
        <Alert variant="warning">{t("common:rep.nonDeductibleWarningOn6071")}</Alert>
      )}
      <Row className="p-2">
        <Col lg={6}>
          <FormGroup.SimpleSelect
            name="representation.activity"
            label={t("common:rep.activity")}
            options={repActivities.filter(filterActivites)}
          />
        </Col>
        <Col lg={6}>
          <FormGroup.SimpleSelect name="representation.purpose" label={t("common:rep.purpose")} options={repPurposes} />
        </Col>
        <Col lg={12}>
          <FieldArray
            name="representation.participants"
            render={({ remove, push }) => (
              <Table size="sm" bordered>
                <thead>
                  <tr>
                    <th>{t("common:rep.participant")}</th>
                    <th>{t("common:company")}</th>
                    <th>
                      <TooltipActionButton
                        size="xs"
                        icon="fas fa-plus"
                        variant="info"
                        text={t("common:rep.addParticipant")}
                        onClick={() =>
                          push({
                            name: "",
                            company: "",
                          })
                        }
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {values.representation?.participants?.map((member, idx) => (
                    <tr key={`p.${idx}`}>
                      <TableGroup.Input name={`representation.participants[${idx}].name`} />
                      <TableGroup.Input name={`representation.participants[${idx}].company`} />
                      <td>
                        {idx >= 0 && (
                          <TooltipActionButton
                            size="xs"
                            icon="fas fa-trash"
                            variant="red"
                            text={t("common:actions.remove")}
                            onClick={() => remove(idx)}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          />
        </Col>
      </Row>
    </fieldset>
  );
}

export default RepresentationForm;
