import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";

import FilePreviewLayout from "components/layouts/FilePreviewLayout";
import client from "api2/client";
import { RocketLoader } from "components/ui/loaders";

function FileBox({ companyId, fileId, height = "80vh" }) {
  const { t } = useTranslation("common");
  const [data, setData] = useState({
    url: "",
    type: "",
    size: 0,
    loading: true,
    error: "",
  });

  useEffect(() => {
    const signal = axios.CancelToken.source();
    client
      .get(`/documents/${fileId}/preview/`, {
        responseType: "blob",
        cancelToken: signal.token,
        headers: {
          "x-company": companyId,
        },
      })
      .then((response) => {
        const blob = response.data;
        setData({
          url: window.URL.createObjectURL(blob),
          type: blob.type,
          size: blob.size,
          loading: false,
          error: "",
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setData({
            url: "",
            type: "",
            size: 0,
            rotation: 0,
            loading: false,
            error:
              error.response.status === 404 ? "File not found or does not exists" : t("common:file.cannotLoadFile"),
          });
        }
      });
    return () => {
      signal.cancel("aborted");
    };
  }, [companyId, fileId, t]);

  const { loading, error, url, type } = data;
  if (loading) {
    return <RocketLoader />;
  }
  if (!loading && error) {
    return <Alert variant="danger">{data.error}</Alert>;
  }
  return (
    <object width="100%" style={{ height: window.innerHeight - 100 }} data={url} type={type}>
      File can not be loaded
    </object>
  );
}

function FilePreviewPage() {
  const { companyId, fileId } = useParams();
  return (
    <FilePreviewLayout>
      <FileBox companyId={companyId} fileId={fileId} />
    </FilePreviewLayout>
  );
}

export default FilePreviewPage;
