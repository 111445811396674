import { NavLink, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";
import Pill from "components/ui/pills/Pill";
import { useMenuNumbersState } from "hooks/useMenuNumbers";
import { useCompanyState } from "hooks/useCompany";
import { codesFor, PermCodeRequired } from "components/perms";
import { Button } from "react-bootstrap";
import cx from "classnames";

function PayNavTabs() {
  const { t } = useTranslation("navigation");
  const {
    company: { payment_provider: paymentProvider },
  } = useCompanyState();

  const navigate = useNavigate();
  const location = useLocation();
  const { sent_bank: sentBank } = useMenuNumbersState();
  const badgeClass = "badge-sentBank";

  const isActive = (subPath) => {
    return location.pathname.includes(subPath);
  };

  return (
    <div className="d-flex justify-content-between">
      <ul className="nav nav-tabs nav-bordered nav-card pt-3" role="tablist">
        <PermCodeRequired code={codesFor.supplierInvoices.enabled}>
          <li className="nav-item">
            <NavLink to="/payments/supplier-invoices" className="nav-link">
              {t("supplierInvoices")} <Pill number={sentBank.supplier_invoices} badgeClass={badgeClass} />
            </NavLink>
          </li>
        </PermCodeRequired>
        <PermCodeRequired code={codesFor.outlays.enabled}>
          <li className="nav-item">
            <NavLink to="/payments/outlays" className="nav-link">
              {t("outlays")} <Pill number={sentBank.outlays} badgeClass={badgeClass} />
            </NavLink>
          </li>
        </PermCodeRequired>
        <PermCodeRequired code={codesFor.directPayments.enabled}>
          <li className="nav-item">
            <NavLink to="/payments/direct-payments" className="nav-link">
              {t("directPayments")} <Pill number={sentBank.direct_payments} badgeClass={badgeClass} />
            </NavLink>
          </li>
        </PermCodeRequired>
        <PermCodeRequired code={codesFor.salaries.enabled}>
          <li className="nav-item">
            <NavLink to="/payments/salaries" className="nav-link">
              {t("salaries")} <Pill number={sentBank.salaries} badgeClass={badgeClass} />
            </NavLink>
          </li>
        </PermCodeRequired>
        <PermCodeRequired code={codesFor.taxes.enabled}>
          <li className="nav-item">
            <NavLink to="/payments/taxes" className="nav-link">
              {t("taxes")} <Pill number={sentBank.taxes} badgeClass={badgeClass} />
            </NavLink>
          </li>
        </PermCodeRequired>
      </ul>
      {paymentProvider === "iso" && (
        <ul className="nav pt-3" role="tablist">
          <Button
            variant=""
            className={cx("btn-custom", isActive("iso") && "btn-custom-active")}
            onClick={() => navigate("/payments/iso")}
          >
            <i className="fe-users mr-1" /> {t("common:isoPayments")}
          </Button>
        </ul>
      )}
    </div>
  );
}

export default PayNavTabs;
