import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { SearchFilter } from "components/filters";
import useTable from "components/tables/btable/useTable";
import { codesForCustomerInvoices } from "components/perms/PermCodes";
import { PermCodeRequired } from "components/perms";
import { scrollToSection } from "utils/others";
import ProductImportButton from "components/forms/ProductForm/ProductImportModal";
import enProductTemplate from "assets/templates/csv/en/productTemplate.csv";
import svProductTemplate from "assets/templates/csv/sv/productTemplate.csv";
import DownloadTemplateButton from "components/ui/buttons/DownloadTemplateButton";

function ProductFilters({ companyId }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");

  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
        <ButtonGroup aria-label="Status filter" className="status-filter">
          <Button
            variant="toggle"
            active={!filters.mode || filters.mode === "all"}
            onClick={() => setFilters({ mode: "" })}
          >
            {t("common:statuses.all")}
          </Button>
          <Button
            variant="toggle-green"
            active={filters.mode === "active"}
            onClick={() => setFilters({ mode: "active" })}
          >
            {t("common:statuses.active")}
          </Button>
          <Button
            variant="toggle-orange"
            active={filters.mode === "inactive"}
            onClick={() => setFilters({ mode: "inactive" })}
          >
            {t("common:statuses.inactive")}
          </Button>
        </ButtonGroup>
      </section>
      <section className="table-filters-right">
        <PermCodeRequired code={codesForCustomerInvoices.manage}>
          <Button onClick={() => scrollToSection("create-product-section")} className="btn btn-blue">
            <i className="fas fa-plus" /> {t("common:actions.create")}
          </Button>
          <ProductImportButton companyId={companyId} />
          <DownloadTemplateButton
            templatePaths={{ en: enProductTemplate, sv: svProductTemplate }}
            tooltipTranslationKey="onboarding.downloadTemplate"
            downloadFileName="productTemplate"
          />
        </PermCodeRequired>
      </section>
    </>
  );
}

export default ProductFilters;
