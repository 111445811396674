import _ from "lodash";
import client from "./client";
import { formatDate, parseDate } from "../utils/date";

export const specifications = {
  list: (companyId, accountNumber, financialYear, periodEnd, config) => {
    return client
      .get(`/specifications/`, {
        params: {
          financial_year: financialYear,
          selected_date: formatDate(periodEnd),
          account: accountNumber,
        },
        headers: {
          "x-company": companyId,
        },
        ...config,
      })
      .then((response) =>
        response.data.map((spec) => ({
          ...spec,
          booking_date: parseDate(spec.booking_date),
        }))
      );
  },
  fetch: (companyId, filters, config) => {
    return client.post(`/specifications/fetch/`, filters, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  fetchAll: (companyId, filters, config) => {
    return client.post(`/specifications/fetch-all/`, filters, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

function _uploadSpecificationDocuments(companyId, specificationId, files) {
  const formData = new FormData();
  files.forEach((file, i) => {
    if (file) {
      formData.append("files", file, file.name);
      if (file.description) {
        formData.append("description", file.description);
      }
    }
  });

  return client.post(`/specifications/${specificationId}/documents/upload/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-company": companyId,
    },
  });
}

export const reconciliation = {
  detail: (companyId, accountNumber, selectedDate, config) => {
    return client.get("/reconciliations/detail/", {
      params: {
        selected_date: formatDate(selectedDate),
        account: accountNumber,
      },
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  save: (companyId, data) => {
    return client
      .put("/reconciliations/update/", data, {
        headers: {
          "x-company": companyId,
        },
      })
      .then((response) => {
        const specificationIds = response.data.specifications;
        data.specifications.forEach((spec, index) => {
          if (!spec.verification) {
            // only spec not being a ver. may upload file
            const specFilesToSave = spec.documents.filter((doc) => !_.isNumber(doc)).map((doc) => doc.file);
            _uploadSpecificationDocuments(companyId, specificationIds[index], specFilesToSave).catch(() => {});
            // for (let i = 0; i < specFilesToSave.length; i++) {
            //   _uploadSpecificationDocument(companyId, specificationIds[index], specFilesToSave[i]).catch((error) => {
            //     // pass silently
            //   });
            // }
          }
        });
        return response;
      });
  },
  saveRoutine: (companyId, account, text) => {
    return client.put(
      `/reconciliations/routine/update/`,
      {
        account,
        text,
      },
      {
        headers: {
          "x-company": companyId,
        },
      }
    );
  },
};
