import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";

import {
  HistoryButton,
  PayAtOnceButton,
  PreviewButton,
  TooltipActionButton,
  TooltipActionLink,
  VerificationBookingButton,
  VerificationCommentsButton,
  VerificationDocumentsButton,
} from "components/ui/buttons";
import { SIBookPaymentModal, SIUseCreditModal } from "components/modals";
import { confirmExecute, confirmRemove } from "components/modals/ConfirmModal";
import useTable from "components/tables/btable/useTable";
import * as siAPI from "api2/supplier-invoices";
import useModal from "hooks/useModal";
import { HasPermCode, PermCodeRequired } from "components/perms";
import { codesFor } from "components/perms/PermCodes";
import { SelectionContext } from "state/providers/SelectionProvider";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { useCompanyState } from "hooks/useCompany";
import { useResolvedPath } from "react-router-dom";
import { handleActionErrors } from "api/errors";

function SupplierInvoiceActions({ row, companyId }) {
  const { t } = useTranslation("si");
  const bookPayModal = useModal();
  const useCreditModal = useModal();
  const {
    company: { payment_provider: paymentProvider },
  } = useCompanyState();
  const url = useResolvedPath("").pathname;
  const {
    tableId,
    dataActions: { reload },
  } = useTable();
  const { removeSelection } = useContext(SelectionContext);

  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const show = {
    bookBtn:
      _.includes(["unpaid", "prelim"], row.sub_status) || (paymentProvider === "pis" && row.sub_status === "sent_bank"),
    bookingBtn: row.status === 1,
    editBtn: _.includes(["", "investigate"], row.sub_status),
    creditBtn: row.sub_status === "unpaid" && row.amount_sek > 0,
    deleteBtn: _.includes(["", "prelim", "investigate"], row.sub_status),
    payAtOnceBtn: paymentProvider === "bgc" && row.sub_status === "unpaid",
    cancelPayBtn: row.sub_status === "sent_bank",
    moveDraftBtn: _.includes(["prelim", "unpaid"], row.sub_status),
    moveArchiveBtn: row.sub_status === "" && row.documents.length,
    toggleDeactivatePayment: _.includes(["", "prelim", "investigate", "unpaid"], row.sub_status),
  };
  const canManage = HasPermCode(codesFor.supplierInvoices.manage);
  const onDelete = async () => {
    const answer = await confirmRemove(t("confirm.remove", { invoiceNo: row.invoice_no || row.ocr }));
    if (answer) {
      siAPI
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          if (removeSelection) {
            removeSelection(tableId, row);
          }
          reload();
          reloadMenuNumbers();
        })
        .catch((error) => {
          toast.error(t("msg:canNotExecuteAction"));
        });
    }
  };

  const onBookPayModalClose = (saved) => {
    bookPayModal.close();
    if (saved) {
      if (removeSelection) {
        removeSelection(tableId, row);
      }
      reload();
      reloadMenuNumbers();
    }
  };

  const onCreditModalClose = (creditInvoice) => {
    useCreditModal.close();
    if (creditInvoice) {
      if (removeSelection) {
        removeSelection(tableId, row);
        removeSelection(tableId, creditInvoice);
      }
      reload();
      reloadMenuNumbers();
    }
  };

  const onCancelPayment = async () => {
    const answer = await confirmExecute(
      t("confirm.cancelPayment", {
        invoiceNo: row.invoice_no || row.ocr,
      })
    );
    if (answer) {
      siAPI
        .cancelPayment(companyId, row.id)
        .then(() => {
          toast.success(t("msg:updated"));
          reload();
          reloadMenuNumbers();
        })
        .catch((error) => {
          if (error.data && error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    }
  };

  const onMoveDraft = async () => {
    const answer = await confirmExecute(
      t(row.assets.length ? "confirm.moveDraftWithAssets" : "confirm.moveDraft", {
        invoiceNo: row.invoice_no || row.ocr,
      })
    );
    if (answer) {
      siAPI
        .moveDraft(companyId, row.id)
        .then(() => {
          if (removeSelection) {
            removeSelection(tableId, row);
          }
          reload();
          reloadMenuNumbers();
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    }
  };

  const onMoveArchive = async () => {
    const answer = await confirmExecute(t("confirm.moveArchive", { invoiceNo: row.invoice_no || row.ocr }));
    if (answer) {
      siAPI
        .moveToArchiveDocument(companyId, row.id)
        .then(() => {
          reload();
          reloadMenuNumbers();
        })
        .catch((error) => {
          toast.error(t("msg:canNotExecuteAction"));
        });
    }
  };

  const onToggleDeactivatePayment = async () => {
    const answer = await confirmExecute(
      t("confirm.deactivatePayment", {
        invoiceNo: row.invoice_no || row.ocr,
        status: row.deactivate_payment ? "checked" : "NOT checked",
      })
    );
    if (answer) {
      siAPI
        .toggleDeactivatePayment(companyId, row.id)
        .then(() => {
          if (removeSelection) {
            removeSelection(tableId, row);
          }
          reload();
        })
        .catch((error) => {
          toast.error(t("msg:canNotExecuteAction"));
        });
    }
  };

  return (
    <>
      <HistoryButton apiResource={siAPI.history} apiParams={[companyId, row.id]} />
      <VerificationDocumentsButton companyId={companyId} documentsIds={row.documents} verificationId={row.id} />
      <VerificationCommentsButton companyId={companyId} verification={row} />
      <PreviewButton link={`${url}/${row.id}/preview`} />
      {show.bookingBtn && <VerificationBookingButton companyId={companyId} verification={row} />}
      <PermCodeRequired code={codesFor.supplierInvoices.bookPayment}>
        {show.bookBtn && (
          <TooltipActionButton
            text={t("common:actions.bookPayment")}
            onClick={bookPayModal.open}
            icon="fas fa-dollar-sign"
          />
        )}
        {show.editBtn && canManage && (
          <TooltipActionLink text={t("common:actions.change")} link={`${url}/${row.id}/change`} icon="fas fa-pen" />
        )}
        {show.toggleDeactivatePayment && (
          <TooltipActionButton
            variant={row.deactivate_payment ? "action-toggle active" : "action-toggle"}
            text={t("actions.toggleDeactivatePayment")}
            icon="fas fa-sync"
            onClick={onToggleDeactivatePayment}
          />
        )}
      </PermCodeRequired>
      <PermCodeRequired code={codesFor.supplierInvoices.creditInvoice}>
        {show.creditBtn && (
          <TooltipActionButton
            text={t("common:actions.useCredit")}
            icon="fas fa-minus-circle"
            onClick={useCreditModal.open}
          />
        )}
      </PermCodeRequired>
      <PermCodeRequired code={codesFor.supplierInvoices.pay}>
        {show.payAtOnceBtn && <PayAtOnceButton companyId={companyId} invoice={row} />}
      </PermCodeRequired>
      <PermCodeRequired code={codesFor.supplierInvoices.cancelPayment}>
        {show.cancelPayBtn && (
          <TooltipActionButton
            variant="action-red"
            text={t("common:actions.cancelPayment")}
            onClick={onCancelPayment}
            icon="fas fa-ban"
          />
        )}
      </PermCodeRequired>
      <PermCodeRequired code={codesFor.supplierInvoices.manage}>
        {show.moveDraftBtn && (
          <TooltipActionButton
            variant="action"
            text={t("common:actions.moveDraft")}
            onClick={onMoveDraft}
            icon="fas fa-reply"
          />
        )}
        {show.moveArchiveBtn ? (
          <TooltipActionButton
            variant="action-red"
            text={t("common:actions.moveArchive")}
            onClick={onMoveArchive}
            icon="fas fa-reply"
          />
        ) : null}
        {show.deleteBtn && (
          <TooltipActionButton text={t("common:actions.remove")} onClick={onDelete} icon="fas fa-trash" />
        )}
      </PermCodeRequired>
      {bookPayModal.show && (
        <SIBookPaymentModal companyId={companyId} invoiceData={row} handleClose={onBookPayModalClose} />
      )}
      {useCreditModal.show && (
        <SIUseCreditModal companyId={companyId} invoiceData={row} handleClose={onCreditModalClose} />
      )}
    </>
  );
}

export default SupplierInvoiceActions;
