import React from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";

import { FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import * as dpAPI from "api2/direct-payments";
import { formatDate } from "utils/date";
import { useCompanyState } from "hooks/useCompany";
import { useModalOnPage } from "components/modals/hooks/useModalOnPage";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";

function DPBookPaymentModal({ handleClose, companyId, dpData }) {
  const { t } = useTranslation("common");

  const onSave = (values) => {
    return dpAPI.manualPay(companyId, dpData.id, values).then((response) => {
      toast.success(t("msg:saved"));

      handleClose(true);
    });
  };

  return (
    <Modal show onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">
          {t("common:registerPaymentDP")} ({dpData.receiver})
        </Modal.Title>
      </Modal.Header>
      <BookForm t={t} onSave={onSave} dpData={dpData} />
    </Modal>
  );
}

function BookForm({ dpData, onSave, t }) {
  const isModal = useModalOnPage();
  const {
    paymentAccounts: { byId: paymentAccountsById, asOptions: paymentAccountsOptions },
  } = useCompanyState();
  const formikProps = {
    initialValues: {
      payment_date: new Date(),
      amount: dpData.amount_sek,
      amount_sek: dpData.amount_sek,
      contra_account: paymentAccountsById[1930],
      before: {
        amount: dpData.amount_sek,
        currency: dpData.amount_currency,
        balance: dpData.amount_sek,
      },
    },
    validationSchema: yup.object().shape({
      payment_date: yup.date().nullable().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      await onSave({
        payment_date: formatDate(values.payment_date),
        amount: values.amount,
        amount_currency: dpData.amount_currency,
        exchange_rate: 1,
        account_id: values.contra_account.value,
      }).catch((error) => {
        if (error.data.booking_date) {
          error.data.payment_date = error.data.booking_date;
        }
        setErrors(error.data);
      });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ values, errors, setFieldValue, isValid }) => {
        return (
          <Form id="bookDPForm">
            <Modal.Body>
              <Row>
                <Col lg={6}>
                  <FormGroup.BookingDatePicker
                    name="payment_date"
                    label={t("common:dates.paymentDate")}
                    popperClassName="popper-in-modal"
                    required
                  />
                </Col>
              </Row>
              <fieldset>
                <legend>{t("amountReceivedAccBank")}</legend>
                <Row>
                  <Col lg={3}>
                    <FormGroup.MoneyInput name="amount" label={t("common:money.amount")} required disabled />
                  </Col>
                </Row>
              </fieldset>
              <fieldset>
                <legend>{t("balanceBeforeTrans")}</legend>
                <Row>
                  <Col lg={3}>
                    <FormGroup.MoneyInput name="before.amount" label={t("common:money.amount")} disabled />
                  </Col>
                  <Col lg={3}>
                    <FormGroup.MoneyInput name="before.balance" label={`${t("common:money.balance")} SEK`} disabled />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup.SimpleSelect
                      name="contra_account"
                      menuPlacement={isModal ? "bottom" : "auto"}
                      menuPosition={isModal ? "absolute" : "fixed"}
                      label={t("common:paymentAccount")}
                      options={paymentAccountsOptions}
                      helpText={t("company:helpText.paymentAccount")}
                    />
                  </Col>
                </Row>
              </fieldset>

              {errors && errors.__all__ && (
                <Alert className="mt-2 mb-0" variant="danger">
                  {errors.__all__}
                </Alert>
              )}
            </Modal.Body>
            <Modal.Footer>
              <SubmitButton disabled={!isValid} />
            </Modal.Footer>
          </Form>
        );
      }}
    </Formik>
  );
}

export default DPBookPaymentModal;
