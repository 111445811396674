import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { formatDatetime, formatDuration } from "utils/date";
import BTable from "components/tables/btable";
import Filters from "./TimeLogTTFilters";
import Actions from "./TimeLogTTActions";

function TimeLogTTTable({ selectedAgency, options }) {
  const { t } = useTranslation("tt");
  const headers = useMemo(
    () => [
      {
        field: "task_title",
        label: t("task"),
        sortField: "title",
      },
      {
        field: "company_name",
        label: t("common:company"),
        sortField: "company",
      },
      {
        field: "user_name",
        label: t("common:createdBy"),
        sortField: "created_by_name",
      },
      {
        field: "started",
        label: t("started"),
        render: (values) => formatDatetime(values.started),
      },
      {
        field: "stopped",
        label: t("stopped"),
        render: (values) => (values.stopped ? formatDatetime(values.stopped) : "-"),
      },
      {
        field: "duration",
        label: t("duration"),
        render: (values) => formatDuration(values.duration),
      },
      {
        field: "task_extra_charge",
        label: t("extraCharge"),
        canSort: false,
      },
    ],
    [t]
  );

  const renderTotals = (responseHeaders) => (
    <div>
      <strong>{t("totalTime")}:</strong>
      {formatDuration(responseHeaders["x-pagination-balance"])}
    </div>
  );

  const getRowProps = (row) => {
    return { className: row.stopped ? "active" : "inactive" };
  };

  return (
    <BTable
      headers={headers}
      FiltersComponent={<Filters options={options} selectedAgency={selectedAgency} />}
      renderTotals={renderTotals}
      renderActions={(row) => <Actions row={row} />}
      getRowProps={getRowProps}
    />
  );
}

export default TimeLogTTTable;
