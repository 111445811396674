import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, useFormikContext } from "formik";
import { Button, Card, Col, Row } from "react-bootstrap";
import { ConsultPicker } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import { toast } from "react-toastify";
import * as supportApi from "api2/office-support";
import { useCompanyState } from "hooks/useCompany";

function OfficeSupportRolesTaxAgencyAttorneyForm({ companyId, changeEditedForm, initialData }) {
  const { t } = useTranslation("company");
  const [showMore, setShowMore] = useState(true);
  const {
    company: { agency_id },
  } = useCompanyState();
  const formikProps = {
    initialValues: {
      tax_attorneys: initialData.map((item) => ({ ...item, label: item.consult_name, value: item.consult })),
    },
    onSubmit: async (values, { setErrors, resetForm }) => {
      await supportApi.roles.tax
        .bulkUpdate(companyId, {
          tax_attorneys: values.tax_attorneys.map((item) => ({ ...item, consult: item.value })),
        })
        .then((response) => {
          resetForm({ values });
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          if (error.data.__all__) {
            setErrors({ __all__: error.data.__all__ });
          } else {
            setErrors({ ...error.data });
          }
        });
    },
  };

  function FormUpdateChecker() {
    const { dirty } = useFormikContext();
    React.useEffect(() => {
      changeEditedForm({ id: "roles-tax-agency-form", dirty });
    }, [dirty]);
    return null;
  }

  return (
    <Formik {...formikProps}>
      {({ values, touched, isSubmitting, setFieldValue, errors, resetForm, dirty }) => {
        return (
          <Form>
            <FormUpdateChecker />
            <Card>
              <Card.Header onClick={() => setShowMore((prevValue) => !prevValue)}>
                <Card.Title style={{ color: "#ADADAD", fontWeight: 400, fontSize: "12px" }}>
                  {t("header.taxAgencyAttorney")}
                  <i
                    style={{ textAlign: "right", float: "right" }}
                    className={showMore ? "fas fa-chevron-up fa-1x" : "fas fa-chevron-down fa-1x"}
                  />
                </Card.Title>
              </Card.Header>
              {showMore && (
                <Card.Body className="pt-0">
                  <Row>
                    <Col>
                      <ConsultPicker
                        agencyId={agency_id}
                        className="react-select-multi"
                        name="tax_attorneys"
                        label={t("common:consultants")}
                        isMulti
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-right">
                      {dirty && (
                        <Button variant="link" size="lg" onClick={() => resetForm()} className="cancel-btn">
                          {t("common:actions.cancel")}
                        </Button>
                      )}
                      <SubmitButton isSubmitting={isSubmitting} disabled={!dirty} />
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default OfficeSupportRolesTaxAgencyAttorneyForm;
