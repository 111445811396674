import React, { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Alert, Button, ButtonGroup, Card, Col, Row, Table } from "react-bootstrap";
import _ from "lodash";
import qs from "qs";

import * as options from "api/options";
import * as ciAPIOld from "api/customer-invoices";
import * as ciApi from "api2/customer-invoices";
import PageNotFound from "pages/PageNotFound";
import useInitialAsync from "hooks/useInitialAsync";
import { useCompanyState } from "hooks/useCompany";
import useModal from "hooks/useModal";
import { RocketLoader } from "components/ui/loaders";
import { SavedDocuments } from "components/ui/documents-refactored/documents";
import { NoDocumentBox } from "components/ui/documents/document-box";
import { PreviewInput } from "components/formik/PreviewInput";
import FileUrlModal from "components/modals/FileUrlModal/FileUrlModal";
import CIPeriodisationModal from "components/modals/invoices/CIPeriodisationModal";
import { PermCodeRequired } from "components/perms";
import { codesForCustomerInvoices } from "components/perms/PermCodes";
import { RotRutModal } from "components/modals/invoices";
import { formatDate } from "utils/date";
import { formatMoney, roundMoney } from "utils/money";
import {
  calculateRecordTotal,
  formatDiscount,
  getSummaryNet,
  getSummaryVat,
  getTaxReduction,
  toBillectaAmount,
} from "utils/calc/ci";
import { toast } from "react-toastify";

function CustomerInvoicePreviewPage() {
  const [counter, setCounter] = useState(1); // to refresh
  const { t } = useTranslation("ci");
  const company = useOutletContext();
  const { id: invoiceId } = useParams();
  const xmlModal = useModal();
  const rotRutModal = useModal();
  const { loading, item } = useInitialAsync(() => ciAPIOld.details(company.id, invoiceId), {}, [counter]);

  if (loading) {
    return <RocketLoader />;
  }

  if (!loading && _.isEmpty(item)) {
    return <PageNotFound />;
  }
  const rotRutDownloadAllowed = item.rot_rut_enabled === true && item.status === 1;
  const invoice = {
    ...item,
    booking_date: formatDate(item.booking_date),
    due_date: item.due_date ? formatDate(item.due_date) : "-",
    amount: `${formatMoney(item.amount)} ${item.amount_currency}`,
    delivery_method: {
      Email: t("actions.sendEmail"),
      Mail: t("actions.sendMail"),
      EInvoice: t("actions.sendEInvoice"),
      Manually: t("actions.sendDoNot"),
    }[item.delivery_method],
  };

  const downloadRotRutXML = () => {
    const params = qs.stringify(
      {
        ids: [item.id],
      },
      { indices: false }
    );
    xmlModal.open(`/customer-invoices/rot-rut/xml/?${params}`);
  };

  const onUpdate = (records) => {
    const data = {
      records: records.map((record, idx) => {
        delete record.key;
        if (record.RecordType === "Standard") {
          return {
            ...record,
            UnitPrice: {
              Value: roundMoney(toBillectaAmount(record.UnitPrice, item.amount_currency)),
              CurrencyCode: item.amount_currency,
            },
            Quantity: roundMoney(record.Quantity),
            RotRutAmount: {
              Value: roundMoney(toBillectaAmount(record.RotRutAmount || 0, item.amount_currency)),
              CurrencyCode: item.amount_currency,
            },
            RotRutHours: parseInt(record.RotRutHours || 0, 10),
            RotRutMaterialCostAmount: {
              Value: roundMoney(toBillectaAmount(record.RotRutMaterialCostAmount) || 0, item.amount_currency),
              CurrencyCode: item.amount_currency,
            },
            SequenceNo: idx + 1,
            ...formatDiscount(record, item.amount_currency),
            VAT: record.VAT && !item.reversed_vat ? parseInt(record.VAT.value, 10) : 0,
            BookKeepingAccount: record.BookKeepingAccount,
          };
        }
        return {
          RecordType: record.RecordType,
          ArticleNumber: record.ArticleNumber,
          ArticleDescription: record.ArticleDescription,
          SequenceNo: idx + 1,
        };
      }),
    };
    ciApi.rotRut
      .materialCostAndHoursUpdate(item.id, company.id, data)
      .then(() => {
        setCounter((c) => c + 1);
        rotRutModal.close();
      })
      .catch((error) => {
        toast.error(t("msg:fixErrors"));
      });
  };

  return (
    <>
      <Card>
        {item.rot_rut_enabled && (
          <Card.Body className="pb-0">
            <Button variant="blue" size="sm" onClick={rotRutModal.open}>
              ROT/RUT
            </Button>
          </Card.Body>
        )}
        <Card.Body>
          {item.einvoice_error && <Alert variant="danger">{item.einvoice_error}</Alert>}
          <InvoiceContent company={company} invoice={invoice} t={t} />
        </Card.Body>
        <Card.Footer>
          <ButtonGroup>
            <PermCodeRequired code={codesForCustomerInvoices.manage}>
              {rotRutDownloadAllowed && (
                <Button onClick={downloadRotRutXML} variant="secondary">
                  {t("actions.downloadRotRutXml")}
                </Button>
              )}
            </PermCodeRequired>
          </ButtonGroup>
        </Card.Footer>
      </Card>
      {xmlModal.show && (
        <FileUrlModal companyId={company.id} fileUrl={xmlModal.data} handleClose={xmlModal.close} autoDownload />
      )}
      {rotRutModal.show && (
        <RotRutModal
          previewOnly
          currency={item.amount_currency}
          companyId={company.id}
          customer={item.customer}
          records={item.records.map((record, i) => ({
            ...record,
            index: i,
          }))}
          rotRutDetails={item.rot_rut}
          onCancel={rotRutModal.close}
          onSave={({ records, RotRutDetails }) => {
            onUpdate(records, RotRutDetails);
          }}
          isFullyPaid={item.balance === 0}
        />
      )}
    </>
  );
}

function InvoiceContent({ company, invoice, t }) {
  const {
    costCenters: { byId: centerById },
    projects: { byId: projectById },
    accounts: { byId: accountById, asOptions: accountOptions },
  } = useCompanyState();
  const standardRecords = invoice.records.filter((item) => item.RecordType === "Standard");
  const currency = invoice.amount_currency;
  const periodisationModal = useModal();
  const summaryNet = getSummaryNet(standardRecords);
  const summaryVat = getSummaryVat(invoice.reversed_vat, standardRecords);
  const basis = summaryNet + summaryVat;
  const taxReduction = getTaxReduction(standardRecords);
  const summaryTotal = summaryNet + summaryVat + taxReduction;
  const summaryTotalRounded = Math.round(summaryTotal);
  const centRounding = summaryTotalRounded - summaryTotal;
  const [periodisationStatus, setPeriodisationStatus] = useState({
    toConfigure: invoice.periodisation_config !== null && _.isEmpty(invoice.periodisation_config),
    configured: invoice.periodisation_config && !_.isEmpty(invoice.periodisation_config),
    notEnabled: invoice.periodisation_config === null,
  });
  const setPeriodisationStatusAsConfigured = () => {
    setPeriodisationStatus({
      toConfigure: false,
      configured: true,
      notEnabled: false,
    });
    periodisationModal.close();
  };
  return (
    <>
      <Row>
        <Col lg={10}>
          <Row>
            <Col lg={3}>
              <PreviewInput label={t("common:customer")} value={invoice.customer_snapshot.Name} />
            </Col>
            <Col lg={3}>
              <PreviewInput label={t("common:contact.address")} value={invoice.customer_snapshot.Address} />
            </Col>
            <Col lg={3}>
              <PreviewInput
                label={t("common:contact.post")}
                value={`${invoice.customer_snapshot.ZipCode} ${invoice.customer_snapshot.City}`}
              />
            </Col>
            <Col lg={3}>
              <PreviewInput label={t("common:contact.emailLong")} value={invoice.customer_snapshot.Email} />
            </Col>
            <Col lg={3}>
              <PreviewInput label={t("common:yourReference")} value={invoice.your_reference} />
            </Col>
            <Col lg={3}>
              <PreviewInput label={t("common:ourReference")} value={invoice.our_reference} />
            </Col>
            {invoice.reversed_vat && (
              <Col lg={3}>
                <PreviewInput label={t("common:money.vatNo")} value={invoice.reversed_vat_receiver_no} />
              </Col>
            )}
            <Col lg={3}>
              <PreviewInput label={t("sendInvoiceBy")} value={invoice.delivery_method} />
            </Col>
          </Row>
        </Col>
        <Col lg={2}>
          <Button
            className="mb-auto"
            variant={periodisationStatus.toConfigure ? "toggle-red" : "secondary"}
            onClick={periodisationModal.open}
          >
            {periodisationStatus.configured && <i className="fas fa-check" />}
            {periodisationStatus.notEnabled && <i className="fas fa-plus" />}
            {periodisationStatus.toConfigure && <i className="fas fa-clock" />}
            {t("common:periodisation")}
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col lg={2}>
          <PreviewInput label={t("common:invoiceNo")} value={invoice.invoice_no} />
        </Col>
        <Col lg={2}>
          <PreviewInput label={t("common:dates.invoiceDate")} value={invoice.booking_date} />
        </Col>
        <Col lg={2}>
          <PreviewInput label={t("common:dates.dueDate")} value={invoice.due_date} />
        </Col>
        <Col lg={2}>
          <PreviewInput label={t("common:money.amount")} value={invoice.amount} />
        </Col>
        <Col lg={2}>
          <PreviewInput label={t("ci:viesConsultationNumber")} value={invoice.vies_consultation_number} />
        </Col>
        <Col lg={2}>
          <PreviewInput label="OCR" value={invoice.ocr} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Table bordered size="sm" className="records">
            <thead>
              <tr>
                <th style={{ width: 40 }} />
                <th style={{ width: "5%", minWidth: 100 }}>{t("artNo")}</th>
                <th>{t("common:description")}</th>
                <th style={{ width: "5%", minWidth: 100 }}>{t("common:money.quantity")}</th>
                <th style={{ width: "5%", minWidth: 100 }}>{t("unit")}</th>
                <th style={{ width: "10%", minWidth: 200 }} className="text-right">
                  {t("common:money.unitPrice")} ({invoice.amount_currency})
                </th>
                <th style={{ width: "5%", minWidth: 100 }}>{t("common:money.vat")}</th>
                <th style={{ width: "5%", minWidth: 100 }}>{t("common:money.discount")}</th>
                <th style={{ width: 150 }} className="text-right">
                  {t("common:money.total")}
                </th>
              </tr>
            </thead>
            <tbody>
              {invoice.records.map((record, idx) => (
                <tr key={idx}>
                  {
                    {
                      Message: (
                        <>
                          <td className="text-muted">{idx + 1}.</td>
                          <td />
                          <td colSpan={7}>{record.ArticleDescription}</td>
                        </>
                      ),
                      Standard: (
                        <>
                          <td className="text-muted">{idx + 1}.</td>
                          <td>{record.ArticleNumber}</td>
                          <td>{record.ArticleDescription}</td>
                          <td>{record.Quantity}</td>
                          <td>{record.Units}</td>
                          <td className="text-right">{record.UnitPrice}</td>
                          <td>{options.vatOptions.getLabel(record.VAT.value)}</td>
                          <td className="text-right">
                            {record.DiscountType ? (
                              <>
                                {record.Discount} {record.DiscountType === "Amount" ? currency : "%"}
                              </>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="text-right">
                            {formatMoney(calculateRecordTotal(record), 2, 2)} {currency}
                          </td>
                        </>
                      ),
                    }[record.RecordType]
                  }
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={7} className="text-right">
                  {t("common:money.net")}
                </td>
                <td className="text-right">
                  {formatMoney(summaryNet, 2, 2)} {currency}
                </td>
              </tr>
              <tr>
                <td colSpan={7} className="text-right">
                  {t("common:money.vat")}
                </td>
                <td className="text-right">
                  {formatMoney(summaryVat, 2, 2)} {currency}
                </td>
              </tr>
              {centRounding !== 0 && (
                <tr>
                  <td colSpan={7} className="text-right">
                    {t("common:money.centRounding")}
                  </td>
                  <td className="text-right">
                    {formatMoney(centRounding, 2, 2)} {currency}
                  </td>
                </tr>
              )}
              {taxReduction !== 0 && (
                <tr>
                  <td colSpan={7} className="text-right">
                    {t("basisTaxReduction")}
                  </td>
                  <td className="text-right">
                    {formatMoney(basis)} {currency}
                  </td>
                </tr>
              )}
              {taxReduction !== 0 && (
                <tr>
                  <td colSpan={7} className="text-right">
                    {t("taxReduction")}
                  </td>
                  <td className="text-right">
                    {formatMoney(taxReduction)} {currency}
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={7} className="text-right">
                  <strong>{t("common:money.totalInclVat")}</strong>
                </td>
                <td className="text-right">
                  <strong>
                    {formatMoney(summaryTotalRounded, 2, 2)} {currency}
                  </strong>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="verification-files">
          <SavedDocuments
            companyId={company.id}
            partUrl={`/verifications/${invoice.id}/documents`}
            documents={invoice.documents}
            removable={false}
          />
          {!invoice.documents.length && <NoDocumentBox />}
        </Col>
      </Row>
      {periodisationModal.show && (
        <CIPeriodisationModal
          companyId={company.id}
          invoiceId={invoice.id}
          handleClose={periodisationModal.close}
          onSubmitSuccess={setPeriodisationStatusAsConfigured}
          centerById={centerById}
          projectById={projectById}
          accountById={accountById}
          accountOptions={accountOptions}
        />
      )}
    </>
  );
}

export default CustomerInvoicePreviewPage;
