import React from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";

import TableProvider from "components/tables/btable/provider";
import { AccountPlanExtendedTable } from "components/tables";
import * as companyAPI from "api2/companies";

function AccountPlanListPage({ tableId = "accountPlans" }) {
  const { companyId } = useParams();
  const initialFilters = {};

  return (
    <Card>
      <Card.Body>
        <TableProvider
          idKey="number"
          tableId={tableId}
          dataSource={(filters, config) => companyAPI.accounts.list(companyId, filters, config)}
          initialFilters={initialFilters}
        >
          <AccountPlanExtendedTable companyId={companyId} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default AccountPlanListPage;
