import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import {
  CheckboxFilter,
  ConsultFilter,
  DateRangeFilters,
  SearchFilter,
  SelectSimpleFilter,
} from "../../../components/filters";
import * as options from "../../../api/options";

function Filters({ filters, setFilters, disabled = false }) {
  const { t } = useTranslation("common");
  const categoriesOptions = options.notesCategory.asList();

  const onPeriodChange = (start, end) => {
    setFilters({
      ...filters,
      period_start: start,
      period_end: end,
    });
  };

  const onUserChange = (newData) => {
    setFilters({ ...filters, ...newData });
  };

  const onCategoryChange = (newData) => {
    setFilters({ ...filters, ...newData });
  };

  return (
    <div className={cx("table-filters-group", { disabled })}>
      <section className="table-filters-left">
        <SearchFilter
          onFilter={(selected) => {
            setFilters({ ...filters, ...selected });
          }}
          defaultValue={filters.term}
          width={300}
          label="as"
          hiddenLabel
        />
        <ConsultFilter
          label={t("common:user")}
          defaultValue={filters.category}
          options={categoriesOptions}
          isClearable
          name="user_id"
          onFilter={onUserChange}
        />
        <DateRangeFilters
          label={t("common:dates.period")}
          dateStart={filters.period_start}
          dateEnd={filters.period_end}
          isClearable
          onChange={({ start, end }) => {
            onPeriodChange(start, end);
          }}
        />
        <SelectSimpleFilter
          label={t("common:category")}
          defaultValue={filters.category}
          options={categoriesOptions}
          isClearable
          name="category"
          onFilter={onCategoryChange}
        />
        <CheckboxFilter
          label={t("others:archivedLogs")}
          name="is_archived"
          defaultChecked={!!filters.is_archived}
          defaultValue={!!filters.is_archived}
          onFilter={(selected) => {
            setFilters({ ...filters, ...selected });
          }}
        />
      </section>
    </div>
  );
}

export default Filters;
