import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, ButtonGroup, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import { Trans, useTranslation } from "react-i18next";
import FileUrlModal from "components/modals/FileUrlModal/FileUrlModal";
import { SubmitButton } from "components/ui/buttons";
import * as vatAPI from "api2/vat";
import { confirmExecute } from "components/modals/ConfirmModal";
import { FinancialYearStateContext } from "state/providers/FinancialYearProvider";
import { saveBlobToComputer } from "utils/file";

function BookVATReportModal({
  handleClose,
  companyId,
  data,
  downloadXML,
  openVatIssuedVerifications,
  onVerificationPreviewClick,
}) {
  const { t } = useTranslation("tax");
  const [isSubmitting, setSubmitting] = useState(false);
  const hasVatReport = !!data.vat_report;
  const [showWarning, setShowWarning] = useState(false);
  const { financialYear } = useContext(FinancialYearStateContext);
  useEffect(() => {
    const fetchData = () => {
      vatAPI.reports
        .checkPeriodBalance(companyId, data.id, financialYear.id)
        .then((response) => {
          const shouldShowWarning = !response.data.ok;
          setShowWarning(shouldShowWarning);
        })
        .catch((error) => {
          toast.error(t("msg:canNotExecuteAction"));
        });
    };

    fetchData();
  }, [companyId, financialYear.id, data.id, t]);

  function autoDownloadOnBook(eventId) {
    if (!eventId) {
      return;
    }
    vatAPI.reports.reportXml(companyId, eventId).then((response) => {
      saveBlobToComputer(response, "Momsrapport.xml", false);
    });
  }
  const saveVatReport = async () => {
    const canLock = await vatAPI.reports
      .checkPeriodLock(companyId, data.id, financialYear.id)
      .then((response) => response.data.ok)
      .catch(() => false);
    let answer = true;
    if (!canLock) {
      answer = await confirmExecute(t("confirm.sendVATNoLock"), "tax:confirm.bookVATWithoutLock");
    }
    if (!answer) {
      return;
    }
    setSubmitting(true);
    await vatAPI.reports
      .book(companyId, data.id, financialYear.id)
      .then((response) => {
        handleClose(true);
        autoDownloadOnBook(data.id);
        toast.success(t("vatBooked"));
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.status === 400) {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(error.data);
          }
        } else {
          toast.error(t("vatCanNotBook"));
        }
      });
  };
  return (
    <FileUrlModal
      show
      handleClose={handleClose}
      companyId={companyId}
      fileUrl={`/vat/reports/draft/${data.id}/pdf/`}
      headerChildren={
        showWarning && (
          <Alert variant="warning">
            <Trans i18nKey="tax:vatWarning">Note that there is an opening balance on one or more VAT accounts.</Trans>
          </Alert>
        )
      }
    >
      <Modal.Footer className="space">
        <Button variant="secondary" onClick={openVatIssuedVerifications}>
          {t("checkVerificationsDeviations")}
        </Button>
        {hasVatReport ? (
          <Button variant="primary" size="lg" onClick={downloadXML}>
            {t("common:actions.download")} XML
          </Button>
        ) : (
          <ButtonGroup className="float-right">
            <Button className="mr-1" variant="second" onClick={() => onVerificationPreviewClick()}>
              <i className="mdi mdi-file-search-outline" /> {t("ver:verShort")}
            </Button>
            <SubmitButton isSubmitting={isSubmitting} onClick={saveVatReport} title="tax:confirm.bookVAT" />
          </ButtonGroup>
        )}
      </Modal.Footer>
    </FileUrlModal>
  );
}

export default BookVATReportModal;
