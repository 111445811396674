import React from "react";

import useTable from "components/tables/btable/useTable";
import { MultiOfficeFilter, SearchFilter } from "components/filters";
import { useTranslation } from "react-i18next";

function AgencyConsultFilters({ agencyId, insideCompaniesFilters, setInsideCompaniesFilters }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("company");
  return (
    <section className="table-filters-left">
      <SearchFilter
        label={t("common:consult")}
        placeholder="Search consultants"
        onFilter={setFilters}
        defaultValue={filters.term}
        width={300}
      />
      <SearchFilter
        label={t("common:company")}
        onFilter={(selected) => {
          setInsideCompaniesFilters({ ...insideCompaniesFilters, ...selected });
        }}
        defaultValue={insideCompaniesFilters.term}
        width={300}
      />
      <MultiOfficeFilter
        label={t("common:office")}
        name="offices"
        onFilter={(selected) => {
          setInsideCompaniesFilters({ ...insideCompaniesFilters, ...selected });
        }}
        placeholder={t("common:office")}
        defaultValue={insideCompaniesFilters.offices}
        queryParams={{ agencies: [agencyId] }}
        hideIfEmpty
      />
    </section>
  );
}

export default AgencyConsultFilters;
