import qs from "qs";
import client from "./client";

export const events = {
  list: (filters, config) => client.get(`/vat/events/`, { params: filters, ...config }),
  create: (payload, config) => client.post(`/vat/events/create/`, payload, config),
  remove: (eventId, config) => client.delete(`/vat/events/${eventId}/delete/`, config),
  upcoming: (companyId, financialYearId, config) =>
    client.get("/vat/events/upcoming/", {
      params: { financial_year: financialYearId },
      headers: { "x-company": companyId },
      ...config,
    }),
};

export const reports = {
  list: (companyId, filters, config) =>
    client.get(`/vat/reports/`, {
      params: filters,
      headers: { "x-company": companyId },
      ...config,
    }),
  reportXml: (companyId, eventId, config) =>
    client.get(`/vat/reports/draft/${eventId}/xml/`, {
      headers: { "x-company": companyId },
      responseType: "blob",
      ...config,
    }),
  book: (companyId, eventId, financialYearId, config) =>
    client.post(
      `/vat/reports/draft/${eventId}/book/`,
      {
        financial_year: financialYearId,
      },
      {
        headers: { "x-company": companyId },
        ...config,
      }
    ),
  verificationsPreviewBook: (companyId, eventId, financialYearId, config) =>
    client.post(
      `/vat/reports/draft/${eventId}/book/verifications-preview/`,
      {
        financial_year: financialYearId,
      },
      {
        headers: { "x-company": companyId },
        ...config,
      }
    ),
  checkPeriodLock: (companyId, eventId, financialYearId, config) =>
    client.get(`/vat/reports/draft/${eventId}/check-period-lock/`, {
      params: { financial_year: financialYearId },
      headers: { "x-company": companyId },
      ...config,
    }),
  checkPeriodBalance: (companyId, eventId, financialYearId, config) =>
    client.get(`/vat/reports/draft/${eventId}/check-period-balance/`, {
      params: { financial_year: financialYearId },
      headers: { "x-company": companyId },
      ...config,
    }),
  remove: (companyId, vatReportId, config) =>
    client.delete(`/vat/reports/${vatReportId}/delete/`, {
      headers: { "x-company": companyId },
      ...config,
    }),
  history: (companyId, vatReportId, config) => {
    return client.get(`/vat/reports/${vatReportId}/history/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const vatEU = {
  list: (filters, config) =>
    client
      .get(`/vat/eu/`, {
        params: filters,
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false });
        },
        ...config,
      })
      .then((response) => {
        response.data.forEach((item) => {
          item.id = `c.${item.company_id}`;
        });
        return response;
      }),
  scan: (companyId, period, periodMonth, config) =>
    client.get(`/vat/eu/scan/`, {
      params: { company_id: companyId, period, period_month: periodMonth },
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
      ...config,
    }),
  scanAll: (filters, config) =>
    client.get(`/vat/eu/scan-all/`, {
      params: filters,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
      ...config,
    }),
  downloadSKV: (companyId, vatId, contactData, config) =>
    client.post(
      `/vat/eu/${vatId}/skv/`,
      { ...contactData, company_id: companyId },
      {
        responseType: "blob",
        ...config,
      }
    ),
  uploadSkv: (companyId, vatId, file, config) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("company_id", companyId);
    return client.post(`/vat/eu/${vatId}/upload-skv/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...config,
    });
  },
  toggleReported: (companyId, vatId, config) =>
    client.patch(`/vat/eu/${vatId}/toggle-reported/`, { company_id: companyId }, config),
  history: (companyId, vatId, config) => {
    return client.get(`/vat/eu/${vatId}/history/`, {
      params: {
        company_id: companyId,
      },
      ...config,
    });
  },
};

export const vatPeriods = {
  list: (companyId, filters, config) =>
    client.get(`/vat/periods/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  create: (companyId, data, config) =>
    client.post(`/vat/periods/create/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  remove: (companyId, periodId, config) =>
    client.delete(`/vat/periods/${periodId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
};
