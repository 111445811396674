import React from "react";
import { Card } from "react-bootstrap";
import { useOutletContext, useSearchParams } from "react-router-dom";

import TableProvider from "components/tables/btable/provider";
import { OutlayTable } from "components/tables";
import * as outlayAPI from "api2/outlays";

function OutlayListPage({ initialMode, allowedModes, selectable = false, tableId = "outlays" }) {
  const company = useOutletContext();
  const [search] = useSearchParams();
  const mode = search.get("mode");
  const createdBy = search.get("created_by");
  const initialFilters = {
    mode: initialMode || mode || "",
    created_by: createdBy || "",
  };

  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) => outlayAPI.companyOutlays(company.id, filters, config)}
          initialFilters={initialFilters}
        >
          <OutlayTable
            companyId={company.id}
            forStatus={initialMode}
            allowedModes={allowedModes}
            selectable={selectable}
          />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default OutlayListPage;
