import React from "react";

import { withInitialAsync } from "hooks/useAsync";
import * as companyAPI from "api2/companies";
import useTable from "components/tables/btable/useTable";
import EmployeeForm from "./EmployeeForm";

function EmployeeEditWidget({ data: item, companyId, id }) {
  const {
    dataActions: { reload, updateRow },
  } = useTable();

  const successCallback = (newData) => {
    updateRow(newData);
  };

  const deleteCallback = () => {
    reload();
  };

  return (
    <EmployeeForm
      formName="employee-edit-form"
      companyId={companyId}
      employee={item}
      successCallback={successCallback}
      deleteCallback={deleteCallback}
    />
  );
}

const EnhancedEmployeeEditWidget = withInitialAsync(
  EmployeeEditWidget,
  ({ companyId, id }) =>
    React.useCallback((cancelToken) => companyAPI.employees.details(companyId, id, { cancelToken }), [companyId, id]),
  {},
  false
);

export default EnhancedEmployeeEditWidget;
