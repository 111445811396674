import React, { useContext } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { FiltersProviderRelatedToFY } from "state/providers/FiltersProviderRelatedToFY";
import { FinancialYearStateContext } from "state/providers/FinancialYearProvider";
import CompanyArchiveNavTabs from "./CompanyArchiveNavTabs";

function CompanyArchiveLayout() {
  const company = useOutletContext();
  const { financialYear } = useContext(FinancialYearStateContext);
  return (
    <FiltersProviderRelatedToFY initial={{ financial_year: financialYear ? financialYear.id : null }}>
      <div className="tab-card">
        <CompanyArchiveNavTabs />
        <Outlet context={company} />
      </div>
    </FiltersProviderRelatedToFY>
  );
}

export default CompanyArchiveLayout;
