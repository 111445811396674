import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import { useCompanyState } from "../../../hooks/useCompany";
import { DraggableModalDialog } from "../DraggableModalDialog";
import { PeriodisationActionPreviewsContainer } from "../VerificationPreviewModals/PeriodisationEndUndoStopPreviewsModal";
import { SubmitButton } from "../../ui/buttons";
import * as verAPI from "../../../api2/verification";
import { handleActionErrors } from "../../../api/errors";

function PeriodisationActionModal({ perConfigId, action, onSuccess, onCancel }) {
  const { t } = useTranslation("ver");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    company: { id: companyId },
  } = useCompanyState();
  const onSubmit = () => {
    setIsSubmitting(true);
    return verAPI
      .changePeriodisationStatus(companyId, perConfigId, action)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((error) => {
        handleActionErrors(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  const getWarningText = () => {
    if (action === "end") {
      return t("ver:confirm.endPeriodisation");
    }
    if (action === "stop") {
      return t("ver:confirm.stopPeriodisation");
    }
    return t("ver:confirm.undoPeriodisation");
  };
  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={() => onCancel()}
      dialogClassName="task-modal"
      dialogAs={DraggableModalDialog}
      size="xl"
      className="verification-preview-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("common:actions.areYouSure")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <h4 className="text-center pb-3">{getWarningText()}</h4>
          <PeriodisationActionPreviewsContainer perConfigId={perConfigId} action={action} />
        </>
      </Modal.Body>
      <Modal.Footer className="space">
        <Button type="button" variant="secondary" onClick={() => onCancel()}>
          {t("common:actions.close")}
        </Button>
        <SubmitButton variant="danger" isSubmitting={isSubmitting} title="actions.yesExecute" onClick={onSubmit} />
      </Modal.Footer>
    </Modal>
  );
}

export default PeriodisationActionModal;
