import React, { useCallback } from "react";
import { Formik, Form, FieldArray } from "formik";
import { Card, Table } from "react-bootstrap";
import TableGroup from "components/formik/TableGroup";
import { useTranslation } from "react-i18next";
import * as companyAPI from "api2/companies";
import { toast } from "react-toastify";
import * as yup from "yup";
import { handleFormErrors } from "api/errors";
import useAsync from "hooks/useAsync";
import { SaveButton } from "../../utils";
import { Loader } from "../../../../../components/ui/loaders";

export default function FortnoxIncorrectCostCentersTable({ companyId, agencyId, reloadStep }) {
  const { t } = useTranslation("company");

  const dataSource = useCallback(
    (cancelToken) => companyAPI.onboardings.steps.fortnoxDataCostCentersRecheck(companyId, agencyId, { cancelToken }),
    [companyId, agencyId]
  );
  const [{ data, loading }, { reload }] = useAsync(dataSource, []);

  if (loading) {
    return <Loader />;
  }

  const validationSchema = yup.object().shape({
    cost_centers: yup.array().of(
      yup.object().shape({
        Code: yup.string().required(),
        Description: yup.string().required(),
        Active: yup.boolean().nullable(),
      })
    ),
  });

  if (data?.incorrect_count === 0) return null;
  return (
    <Formik
      initialValues={{ cost_centers: data.incorrect }}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        companyAPI.onboardings.steps
          .fortnoxDataCostCentersUpdate(companyId, agencyId, values.cost_centers)
          .then(() => {
            toast.success(t("msg:updated"));
            reload();
            reloadStep();
          })
          .catch((error) => {
            handleFormErrors(error, setErrors);
          });
        setSubmitting(false);
      }}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ values, errors, touched, isSubmitting }) => {
        return (
          <Form>
            <Card.Body key="cost_centers" className="mb-1">
              <Card.Title>{t("navigation:costCenters")}</Card.Title>
              <FieldArray name="cost_centers">
                {() => (
                  <>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>{t("serialOrNumber")}</th>
                          <th>{t("common:name")}</th>
                          <th>{t("common:statuses.active")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.cost_centers && values.cost_centers.length > 0 ? (
                          values.cost_centers.map((cc, index) => (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <TableGroup.Input name={`cost_centers[${index}].Code`} required />
                                <TableGroup.Input name={`cost_centers[${index}].Description`} />
                                <TableGroup.Checkbox name={`cost_centers[${index}].Active`} label="" />
                              </tr>
                              <TableGroup.RowErrors errors={cc.errors} />
                            </React.Fragment>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3" className="text-center">
                              {t("common:noResultsFound")}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <SaveButton disabled={isSubmitting}>Save Cost Centers</SaveButton>
                  </>
                )}
              </FieldArray>
            </Card.Body>
          </Form>
        );
      }}
    </Formik>
  );
}
