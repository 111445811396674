import React, { useState } from "react";
import { Button } from "react-bootstrap";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { truncateText } from "utils/text";
import { formatDatetime } from "utils/date";
import useModal from "hooks/useModal";
import "./BankConnectionItem.scss";
import BankAccountList from "./BankAccountList";
import BankConnectModal from "./BankConnectModal";

function BankConnectionItem({ connection, companyId, handleRemove }) {
  const { t } = useTranslation("company");
  const [more, setMore] = useState(false);

  const toggleMore = () => {
    setMore((prevValue) => !prevValue);
  };

  return (
    <div className={cx("bank-connection-item", connection.expire_class, { "show-more": more })}>
      <div className="connection__header">
        <div className="bank__title">{truncateText(t(`options:bank.${connection.bic_fi}`), 20)}</div>
        <Button type="button" onClick={handleRemove}>
          <i className="fe-x" /> {t("common:actions.remove")}
        </Button>
      </div>
      <BankAccountList
        switchDisabled={connection.status !== "valid"}
        consentId={connection.id}
        companyId={companyId}
        accounts={connection.accounts}
        showBflowAccounts
      />
      <div className="connection_expire">
        <ExpireProgress expireInDays={connection.expire_in_days} />
        <div className="connection_expire__text">
          {connection.expire_in_days ? (
            <span>
              {connection.expire_in_days} {t("common:daysLeft")}
            </span>
          ) : (
            t("common:expired")
          )}
        </div>
        <RenewButton connection={connection} companyId={companyId} />
      </div>
      <div className="connection_more">
        <div className="more-btn" onClick={toggleMore}>
          {more ? (
            <>
              {t("common:actions.seeLess")} <i className="fe-chevron-up" />
            </>
          ) : (
            <>
              {t("common:actions.seeMore")} <i className="fe-chevron-down" />
            </>
          )}
        </div>
        <div className="connection_more__details">
          <MoreBox label={t("common:dates.expirationDate")} icon="fe-clock" value={connection.valid_until} />
          <MoreBox label={t("common:dates.dateAdded")} icon="fe-calendar" value={formatDatetime(connection.created)} />
          <MoreBox
            label={t("company:psd.accountHolder")}
            icon="fe-user"
            value={truncateText(connection.created_by_name, 15)}
          />
        </div>
      </div>
    </div>
  );
}

function RenewButton({ connection, companyId, onClose }) {
  const { t } = useTranslation("common");
  const renewModal = useModal();

  const handleClose = () => {
    document.body.dispatchEvent(new Event("psd/reload"));
    renewModal.close();
  };

  return (
    <>
      <Button type="button" onClick={renewModal.open}>
        {t("actions.renew")}
      </Button>
      {renewModal.show && (
        <BankConnectModal companyId={companyId} handleClose={handleClose} renewForBic={connection.bic_fi} />
      )}
    </>
  );
}

function MoreBox({ label, icon, value }) {
  return (
    <div className="box">
      <div className="box-label">
        <i className={icon} /> {label}
      </div>
      <div className="box-value">{value}</div>
    </div>
  );
}

function ExpireProgress({ expireInDays }) {
  const percentage = Math.floor((expireInDays / 90) * 100);
  return (
    <div className="progress">
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${percentage + 1}%` }}
        aria-valuenow={percentage}
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  );
}

export default BankConnectionItem;
