import React, { useContext, useEffect } from "react";
import { Form, Formik } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { FormGroup } from "components/formik";
import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import { OnboardingDispatchContext } from "pages/companies/OnboardingWizard/provider/OnboardingProvider";
import { SaveButton } from "pages/companies/OnboardingWizard/utils";
import { withInitialAsync } from "hooks/useAsync";
import * as companyAPI from "api2/companies";
import { handleFormErrors } from "api/errors";

function CompanySettingsStep({ data: initialData, companyId, agencyId, initialLoading }) {
  const { fetchDetails } = useContext(OnboardingDispatchContext);

  useEffect(() => {
    if (!initialLoading) {
      fetchDetails();
    }
  }, [initialLoading, fetchDetails]);

  const formikProps = {
    initialValues: {
      ...initialData,
    },
    validationSchema: yup.object().shape({
      mandrill_email_pattern: yup.string(),
    }),
    onSubmit: (values, { setErrors }) => {
      delete values.logo;
      return companyAPI.onboardings.steps
        .companySettingsFinish(companyId, agencyId, values)
        .then(() => {
          fetchDetails(true);
        })
        .catch((error) => {
          handleFormErrors(error, setErrors);
        });
    },
  };
  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, handleSubmit }) => {
        const customerInvoiceEnabled = values.customer_invoice_enabled;
        return (
          <Card className="company-settings-step" data-testid="company-settings-step">
            <Card.Body>
              <StepHeader rightComponent={<SaveButton disabled={isSubmitting} onSave={handleSubmit} />} />
            </Card.Body>
            <Card.Body>
              <CompanySettingsForm
                receiveEmailDomain={initialData.mandrill_receive_domain}
                customerInvoiceEnabled={customerInvoiceEnabled}
              />
            </Card.Body>
          </Card>
        );
      }}
    </Formik>
  );
}

function CompanySettingsForm({ receiveEmailDomain, customerInvoiceEnabled = true }) {
  const { t } = useTranslation("company");

  return (
    <Form noValidate>
      <Row>
        <Col xl={2}>
          <FormGroup.Input label={t("preliminaryTax")} name="preliminary_tax" type="number" />
        </Col>
        <Col xl={4}>
          <FormGroup.MandrillReceiveEmailInput
            allowedDomain={receiveEmailDomain}
            label={t("invoiceReceiveEmail")}
            name="mandrill_email_pattern"
          />
        </Col>
      </Row>
      <h5>{t("modules")}</h5>
      <Row xl={4}>
        <Col>
          <FormGroup.LabeledInlineYesNoRadio
            name="offer_order_enabled"
            label={t("navigation:offerAndOrder")}
            disabled={!customerInvoiceEnabled}
          />
        </Col>
        <Col>
          <FormGroup.LabeledInlineYesNoRadio
            name="contract_invoice_enabled"
            label={t("contractInvoicing")}
            disabled={!customerInvoiceEnabled}
          />
        </Col>
        <Col>
          <FormGroup.LabeledInlineYesNoRadio
            name="invoice_tax_deduction_enabled"
            label={t("invoiceRotRut")}
            disabled={!customerInvoiceEnabled}
          />
        </Col>
        <Col>
          <FormGroup.LabeledInlineYesNoRadio
            name="einvoice_enabled"
            label={t("common:einvoice")}
            disabled={!customerInvoiceEnabled}
          />
        </Col>
      </Row>
      <Row xl={4}>
        <Col>
          <FormGroup.LabeledInlineYesNoRadio name="time_reporting_enabled" label={t("timeReporting")} />
        </Col>
        <Col>
          <FormGroup.LabeledInlineYesNoRadio name="budget_enabled" label={t("reports:budget")} />
        </Col>
      </Row>
    </Form>
  );
}

const EnhancedCompanySettingsStep = withInitialAsync(
  CompanySettingsStep,
  ({ companyId, agencyId }) => {
    return React.useCallback(
      (cancelToken) => companyAPI.onboardings.steps.companySettings(companyId, agencyId, { cancelToken }),
      [companyId, agencyId]
    );
  },
  {},
  true
);

export default EnhancedCompanySettingsStep;
