import React from "react";
import { Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PermCodeRequired } from "components/perms";
import { formatMoney } from "utils/money";
import { codesForSalaries } from "components/perms/PermCodes";

function UnpaidTable({ data, t }) {
  return (
    <Card>
      <Card.Body className="p-0">
        <Table responsive>
          <thead>
            <tr>
              <th>{t("common:statuses.unpaid")}</th>
              <th className="text-right">{t("common:money.total")}</th>
              <th className="text-right">SEK</th>
              <th className="text-right">{t("dashboard.dueInSEK")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Link to="/invoicing/customer-invoices?mode=open">{t("navigation:customerInvoices")}</Link>
              </td>
              <td className="text-right">{data.customer_invoices.unpaid_count}</td>
              <td className="text-right">{formatMoney(data.customer_invoices.unpaid_sum, 0, 0)}</td>
              <td className="text-right">{formatMoney(data.customer_invoices.unpaid_overdue, 0, 0)}</td>
            </tr>
            <tr>
              <td>
                <Link to="/payments/supplier-invoices">{t("navigation:supplierInvoices")}</Link>
              </td>
              <td className="text-right">{data.supplier_invoices.unpaid_count}</td>
              <td className="text-right">{formatMoney(data.supplier_invoices.unpaid_sum, 0, 0)}</td>
              <td className="text-right">{formatMoney(data.supplier_invoices.unpaid_overdue, 0, 0)}</td>
            </tr>
            <PermCodeRequired code={codesForSalaries.enabled}>
              <tr>
                <td>
                  <Link to="/payments/salaries">{t("navigation:salaries")}</Link>
                </td>
                <td className="text-right">{data.salaries.unpaid_count}</td>
                <td className="text-right">{formatMoney(data.salaries.unpaid_sum, 0, 0)}</td>
                <td className="text-right">{formatMoney(data.salaries.unpaid_overdue, 0, 0)}</td>
              </tr>
            </PermCodeRequired>
            <tr>
              <td>
                <Link to="/payments/outlays">{t("navigation:outlays")}</Link>
              </td>
              <td className="text-right">{data.outlays.unpaid_count}</td>
              <td className="text-right">{formatMoney(data.outlays.unpaid_sum, 0, 0)}</td>
              <td className="text-right">-</td>
            </tr>
            <tr>
              <td>
                <Link to="/payments/taxes">{t("navigation:taxes")}</Link>
              </td>
              <td className="text-right">{data.taxes.unpaid_count}</td>
              <td className="text-right">{formatMoney(data.authorities.tax_balance, 0, 0)}</td>
              <td className="text-right">{formatMoney(data.taxes.unpaid_overdue, 0, 0)}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

export default UnpaidTable;
