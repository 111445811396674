import React, { useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { eInvoice as eInvoiceAPI } from "api2/integrations";
import { SubmitButton } from "components/ui/buttons";
import { DraggableModalDialog } from "../DraggableModalDialog";

function EInvoiceReceiverModal({ handleClose, companyId, initialGln, initialOrgNo, onSelected }) {
  const { t } = useTranslation("ci");
  const [gln, setGLN] = useState(initialGln);
  const [orgNo, setOrgNo] = useState(initialOrgNo);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [receivers, setReceivers] = useState([]);

  const search = async () => {
    setLoading(true);
    try {
      const response = await eInvoiceAPI.search(companyId, { gln, org_no: orgNo, name });
      setReceivers(response.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Modal show onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("eInvoiceReceiverConnect")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <div className="form-group">
              <label className="form-label" htmlFor="OrgNo">
                {`${t("common:contact.personalNumber")}/${t("common:orgNo")}`}
              </label>
              <input
                name="org_no"
                autoComplete="off"
                type="text"
                id="org_no"
                className="form-control"
                value={orgNo}
                onChange={(e) => setOrgNo(e.target.value)}
              />
            </div>
          </Col>
          <Col>
            <div className="form-group">
              <label className="form-label" htmlFor="gln">
                GLN/PEPPOL
              </label>
              <input
                name="gln"
                autoComplete="off"
                type="text"
                id="gln"
                className="form-control"
                value={gln}
                onChange={(e) => setGLN(e.target.value)}
              />
            </div>
          </Col>
          <Col>
            <div className="form-group">
              <label className="form-label" htmlFor="name">
                {t("common:name")}
              </label>
              <input
                name="name"
                autoComplete="off"
                type="text"
                id="name"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <SubmitButton
              type="button"
              isSubmitting={loading}
              onClick={search}
              icon="fas fa-search"
              title="common:search"
            />
          </Col>
        </Row>
        <ReceiverResultTable receivers={receivers} onSelect={onSelected} />
        <small className="font-11">{t("helpPeppol")}</small>
      </Modal.Body>
    </Modal>
  );
}

function ReceiverResultTable({ receivers, onSelect }) {
  const { t } = useTranslation("common");
  return (
    <Table size="sm" className="mt-2">
      <thead>
        <tr>
          <th>{t("common:name")}</th>
          <th>GLN/PEPPOL</th>
          <th>{t("common:orgNo")}</th>
          <th>{t("common:contact.address")}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {receivers.map((receiver) => (
          <tr key={receiver.external_id}>
            <td>{receiver.name}</td>
            <td>{receiver.gln}</td>
            <td>{receiver.org_no}</td>
            <td>
              {receiver.address} {receiver.city}
            </td>
            <td>
              <Button type="button" size="sm" onClick={() => onSelect(receiver)}>
                {t("common:actions.select")}
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
      {!receivers.length && (
        <tfoot>
          <tr>
            <th colSpan={5}>{t("common:noResultsFound")}</th>
          </tr>
        </tfoot>
      )}
    </Table>
  );
}

export default EInvoiceReceiverModal;
