import React from "react";
import { useTranslation } from "react-i18next";

import { useQuery } from "utils/others";
import PasswordResetForm from "./PasswordResetForm";

function PasswordResetPage() {
  const { t } = useTranslation();
  const queryParams = useQuery();
  const token = queryParams.get("token");

  if (!token) {
    return (
      <div className="box">
        <h3>Malformed</h3>
      </div>
    );
  }

  return (
    <div className="box w-350">
      <h3>{t("others:auth.toResetPasswordPartOne")}</h3>
      <div className="inner-box">
        <PasswordResetForm token={token} />
      </div>
    </div>
  );
}

export default PasswordResetPage;
