import client from "api2/client";
import _ from "lodash";
import * as options from "../api/options";
import { _getDiscount } from "../api/customer-invoices";

export function companyOrders(companyId, filters, config) {
  return client.get(`/orders/`, {
    params: filters,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function orderDetails(companyId, invoiceId, config) {
  const vatOptions = options.vatOptions.asList();

  return client
    .get(`/orders/${invoiceId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    })
    .then((response) => {
      return {
        ...response.data,
        customer: !_.isEmpty(response.data.customer_snapshot) && {
          value: response.data.customer_snapshot.DebtorPublicId,
          label: response.data.customer_snapshot.Name,
          ...response.data.customer_snapshot,
        },
        extra_receivers: response.data.extra_receivers
          ? response.data.extra_receivers.filter((item) => item.length > 0)
          : [],
        rot_rut: {
          Customers:
            response.data.rot_rut && response.data.rot_rut.Customers
              ? response.data.rot_rut.Customers.map((customer) => ({
                  ...customer,
                  AskedAmount: customer.AskedAmount.Value / 100,
                }))
              : [],
          PropertyDesignation: response.data.rot_rut ? response.data.rot_rut.PropertyDesignation : "",
          ResidenceAssociationOrgNo: response.data.rot_rut ? response.data.rot_rut.ResidenceAssociationOrgNo : "",
        },
        records: response.data.records.map((record) => ({
          ...record,
          VAT: options.vatOptions.getOption(record.VAT) || vatOptions[0],
          UnitPrice: record.UnitPrice && record.UnitPrice.Value / 100,
          RotRutAmount: record.RotRutAmount && record.RotRutAmount.Value / 100,
          Discount: _getDiscount(record),
          key: _.uniqueId("ln"),
        })),
      };
    });
}

export function cancelOrder(companyId, data, config) {
  return client.patch(`/orders/${data.id}/cancel/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function invoiceOrderUpdate(companyId, data, config) {
  return client.patch(
    `/orders/${data.id}/invoice/`,
    {},
    {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }
  );
}

export function orderSave(companyId, data, config) {
  if (data.id) {
    return client.put(`/orders/${data.id}/update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  }
  return client.post(`/orders/create/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export const documents = {
  save(companyId, orderId, files) {
    const formData = new FormData();
    files.forEach((file) => {
      if (file) {
        formData.append("files", file, file.name);
      }
    });
    return client.post(`/orders/${orderId}/documents/`, formData, {
      headers: {
        "x-company": companyId,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  remove: (companyId, orderId, documentId, config) =>
    client.delete(`/orders/${orderId}/documents/${documentId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
};

export function sendToCustomer(companyId, orderId) {
  return client.patch(
    `/orders/${orderId}/send/`,
    {},
    {
      headers: {
        "x-company": companyId,
      },
    }
  );
}
