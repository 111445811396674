import React, { Fragment } from "react";
import { Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DraggableModalDialog } from "../DraggableModalDialog";

function ActionResultsModal({ data, handleClose, successText, children }) {
  const { t } = useTranslation("common");

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={handleClose}
      backdrop="static"
      dialogClassName="results-modal"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("results")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Table responsive>
          <thead>
            <tr>
              <th>{t("common:name")}</th>
              <th>{t("common:statuses.status")}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <Fragment key={item.id}>
                <tr>
                  <td>{item.title || t(`common:objectTypes.${item.object_type}`)}</td>
                  <td>
                    {!item.progress && <span className="status-wait">{t("wait")}</span>}
                    {item.progress === 1 && (
                      <span className="status-ok">{successText ? t(successText) : t("common:statuses.approved")}</span>
                    )}
                    {item.progress === -1 && <span className="status-fail">{t("fail")}</span>}
                  </td>
                </tr>
                {item.error ? (
                  <tr style={{ borderBottom: "2px solid #ccc" }}>
                    <td colSpan={3} className="has-errors">
                      {item.error}
                    </td>
                  </tr>
                ) : null}
              </Fragment>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      {children}
    </Modal>
  );
}

export default ActionResultsModal;
