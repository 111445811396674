import React, { useCallback, useContext, useRef, useState } from "react";
import { Alert, Button, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SubmitButton } from "components/ui/buttons";
import { toast } from "react-toastify";
import * as agencyAPI from "api2/agencies";
import useAsync from "hooks/useAsync";
import { Loader } from "components/ui/loaders";
import { CodeRequiredInList } from "components/perms";
import {
  AgencyConsultPermDetailedModal,
  CompanyConsultPermDetailedModal,
} from "components/forms/AgencyConsultPermForm";
import useModal from "hooks/useModal";
import { AgencySettingStateContext } from "state/providers/AgencySettingProvider";
import { codesFor } from "components/perms/PermCodes";
import { handleActionErrors } from "api/errors";

function AgencyConsultPermForm({ agencyId, userId, companiesFilters }) {
  const { info: agency_info } = useContext(AgencySettingStateContext);
  const userPermCodes = agency_info.perm_codes;
  const { t } = useTranslation(["common", "msg"]);
  const dataSource = useCallback(
    (cancelToken) => agencyAPI.permissions.summaryList(agencyId, userId, companiesFilters, { cancelToken }),
    [agencyId, userId, companiesFilters]
  );
  const [{ data, loading }, { updateData: setData }] = useAsync(dataSource, []);
  const checkboxNormalRef = useRef();
  const checkboxSalaryRef = useRef();
  const checkboxOfficeSupportRef = useRef();
  const detailedCompanyPermissionsModal = useModal();
  const detailedAgencyPermissionsModal = useModal();
  const [isSubmitting, setSubmitting] = useState(false);

  const togglePerm = useCallback(
    (key, index) => {
      if (key === "has_access" && checkboxNormalRef.current.checked) {
        checkboxNormalRef.current.checked = false;
      } else if (key === "has_salary_access" && checkboxSalaryRef.current.checked) {
        checkboxSalaryRef.current.checked = false;
      } else if (key === "has_office_support_access" && checkboxOfficeSupportRef.current.checked) {
        checkboxOfficeSupportRef.current.checked = false;
      }
      const updatedItem = (item) => {
        if (key === "has_access" && !item.has_access) {
          return {
            ...item,
            has_access: true,
            has_office_support_access: true,
          };
        }
        return {
          ...item,
          [key]: !item[key],
        };
      };
      setData(
        data.map((item, index2) => {
          if (index !== index2) {
            return item;
          }
          return updatedItem(item);
        })
      );
    },
    [data, setData]
  );

  const openPermDetailsModal = useCallback(
    (companyId, companyName) => {
      detailedCompanyPermissionsModal.open({ companyId, companyName });
    },
    [detailedCompanyPermissionsModal]
  );
  function toggleAllOfficeSupport() {
    const newValue = !checkboxOfficeSupportRef.current.checked;
    checkboxOfficeSupportRef.current.checked = newValue;
    setData(data.map((perm) => ({ ...perm, has_office_support_access: newValue })));
  }
  function toggleAllNormal() {
    const newValue = !checkboxNormalRef.current.checked;
    checkboxNormalRef.current.checked = newValue;
    setData(data.map((perm) => ({ ...perm, has_access: newValue })));
    if (!!checkboxNormalRef.current.checked && !checkboxOfficeSupportRef.current.checked) {
      toggleAllOfficeSupport();
    }
  }

  function toggleAllSalary() {
    const newValue = !checkboxSalaryRef.current.checked;
    checkboxSalaryRef.current.checked = newValue;
    setData(data.map((perm) => ({ ...perm, has_salary_access: newValue })));
  }

  const savePerms = () => {
    setSubmitting(true);
    agencyAPI.permissions
      .summaryUpdate(
        agencyId,
        userId,
        data.map((perm) => ({
          company_id: perm.company_id,
          has_access: perm.has_access === 2 ? 2 : Number(perm.has_access),
          has_office_support_access: perm.has_office_support_access === 2 ? 2 : Number(perm.has_office_support_access),
          has_salary_access: perm.has_salary_access === 2 ? 2 : Number(perm.has_salary_access),
        }))
      )
      .then(() => {
        setSubmitting(false);
        toast.success(t("msg:updated"));
      })
      .catch((error) => {
        setSubmitting(false);
        handleActionErrors(error);
      });
  };

  const CompanyPerm = useCallback(
    ({ perm, index }) => {
      return (
        <tr className="text-center">
          <th className="text-left">{perm.company_name}</th>
          <td>
            <Button variant="toggle" size="xs" onClick={() => openPermDetailsModal(perm.company_id, perm.company_name)}>
              <i className="fe-edit" />
            </Button>
          </td>
          <td onClick={() => togglePerm("has_office_support_access", index)}>
            <input
              defaultChecked={perm.has_office_support_access}
              type="checkbox"
              ref={(input) => {
                if (input) {
                  input.indeterminate = perm.has_office_support_access === 2;
                }
              }}
            />
          </td>
          <td onClick={() => togglePerm("has_access", index)}>
            <input
              defaultChecked={perm.has_access}
              type="checkbox"
              ref={(input) => {
                if (input) {
                  input.indeterminate = perm.has_access === 2;
                }
              }}
            />
          </td>
          <td onClick={() => togglePerm("has_salary_access", index)}>
            <input
              type="checkbox"
              defaultChecked={perm.has_salary_access}
              ref={(input) => {
                if (input) {
                  input.indeterminate = perm.has_salary_access === 2;
                }
              }}
            />
          </td>
        </tr>
      );
    },
    [openPermDetailsModal, togglePerm]
  );

  const items = data.map((perm, index) => <CompanyPerm key={perm.company_id} perm={perm} index={index} />);

  const handleClose = (values) => {
    detailedCompanyPermissionsModal.close();
  };
  if (loading) {
    return <Loader />;
  }
  if (!data || data.length === 0) {
    return <Alert variant="info">{t("common:noResultsFound")}</Alert>;
  }
  return (
    <>
      <div>
        <Row>
          <CodeRequiredInList code={codesFor.agency.officeSupervisior} codeList={userPermCodes}>
            <Col>
              <h4>{t("others:agency.permissions")}</h4>
              <button
                type="button"
                className="btn btn-sm btn-outline-dark ml-2"
                onClick={detailedAgencyPermissionsModal.open}
              >
                {t("common:actions.editPermissions")}
              </button>
            </Col>
          </CodeRequiredInList>
          <Col>
            <Table size="sm">
              <thead>
                <tr className="text-center">
                  <th className="text-left">{t("company")}</th>
                  <th />
                  <th style={{ width: 150 }}>{t("officeSupport")}</th>
                  <th style={{ width: 150 }}>{t("standardAccess")}</th>
                  <th style={{ width: 150 }}>{t("salaryAccess")}</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td />
                  <td />
                  <td onClick={toggleAllOfficeSupport}>
                    <input type="checkbox" ref={checkboxOfficeSupportRef} onClick={toggleAllOfficeSupport} />
                  </td>
                  <td onClick={toggleAllNormal}>
                    <input type="checkbox" ref={checkboxNormalRef} onClick={toggleAllNormal} />
                  </td>
                  <td onClick={toggleAllSalary}>
                    <input type="checkbox" ref={checkboxSalaryRef} onClick={toggleAllSalary} />
                  </td>
                </tr>
                {items}
              </tbody>
            </Table>
          </Col>
        </Row>
        <SubmitButton isSubmitting={isSubmitting} type="button" className="float-right" onClick={savePerms} />
      </div>
      {detailedCompanyPermissionsModal.show && (
        <CompanyConsultPermDetailedModal
          agencyId={agencyId}
          companyData={detailedCompanyPermissionsModal.data}
          consultId={userId}
          handleClose={handleClose}
        />
      )}
      {detailedAgencyPermissionsModal.show && (
        <AgencyConsultPermDetailedModal
          agencyId={agencyId}
          consultId={userId}
          handleClose={detailedAgencyPermissionsModal.close}
        />
      )}
    </>
  );
}

export default AgencyConsultPermForm;
