import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Form, Formik, useFormikContext } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import * as selectAPI from "api2/selects";
import * as companyAPI from "api2/companies";
import { SubmitButton } from "components/ui/buttons";
import { AllError, ConsultPicker, FormGroup } from "components/formik";
import { StaffPermRequired } from "components/perms";
import * as options from "api/options";
import { formatDate } from "utils/date";
import { isLastDayOfMonth } from "date-fns";
import { DraggableModalDialog } from "../DraggableModalDialog";

function AgencySetter({ availableAgencies }) {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (availableAgencies.length === 1) {
      const [firstAgency] = availableAgencies;
      setFieldValue("agency", firstAgency);
    }
  }, [availableAgencies, setFieldValue]);

  return null;
}

function RegisterCompanyModal({ handleClose }) {
  const { t } = useTranslation("company");
  const companyTypeOptions = options.companyTypes.asList();

  const [availableAgencies, setAvailableAgencies] = useState([]);
  const [agencyOptions, setAgencyOptions] = useState([]);
  const [officeOptions, setOfficeOptions] = useState([]);

  const formikProps = {
    initialValues: {
      name: "",
      org_no: "",
      company_type: options.companyTypes.getOption("limited"),
      agency: availableAgencies.length === 1 ? availableAgencies[0] : null,
      create_agency: false,
      financial_year_date_start: null,
      financial_year_date_end: null,
      consult: null,
      client_no: null,
      office: null,
    },
    validationSchema: yup.object().shape({
      org_no: yup.string().required(),
      name: yup.string().required(),
      company_type: yup.object().nullable().required(),
      agency: yup
        .object()
        .nullable()
        .when("create_agency", ([value], schema) => {
          return value ? schema : schema.required();
        }),
      financial_year_date_end: yup
        .date()
        .nullable()
        .test("lastDayMonth", function (dateEnd) {
          if (dateEnd && !isLastDayOfMonth(dateEnd)) {
            return this.createError({
              path: this.path,
              message: t("msg:lastDayOfMonth"),
            });
          }
          return true;
        })
        .when("financial_year_date_start", ([startDate], schema) => {
          return startDate ? schema.min(startDate, t("common:errors.mustLaterStartDate")) : schema;
        }),
      consult: yup
        .object()
        .nullable()
        .when("create_agency", ([value], schema) => {
          return !value ? schema.required() : schema;
        }),
    }),
    onSubmit: async (values, { setErrors }) => {
      await companyAPI
        .create({
          ...values,
          agency: values.create_agency ? null : values.agency.value,
          company_type: values.company_type.value,
          consult: !values.create_agency && values.consult ? values.consult.value : null,
          financial_year_date_start: values.financial_year_date_start && formatDate(values.financial_year_date_start),
          financial_year_date_end: values.financial_year_date_end && formatDate(values.financial_year_date_end),
          office: values.office ? values.office.value : null,
        })
        .then((response) => {
          handleClose({ name: values.name, id: response.data.id });
        })
        .catch((error) => {
          setErrors(error.data);
        });
    },
  };

  async function fetchOffices(agencyId) {
    if (agencyId) {
      const data = await selectAPI.offices({ agencies: agencyId });
      setOfficeOptions(data.map((office) => ({ label: office.name, value: office.id })));
    } else {
      setOfficeOptions([]);
    }
  }

  useEffect(() => {
    async function fetchAgencies() {
      if (availableAgencies.length === 0) {
        const data = await selectAPI.agencies();
        setAvailableAgencies(data);
        if (data.length === 1) {
          const [firstAgency] = data;
          formikProps.initialValues.agency = firstAgency;
          fetchOffices(firstAgency.value);
        } else {
          setAgencyOptions(data.map((agency) => ({ label: agency.name, value: agency.id })));
        }
      }
    }
    fetchAgencies();
  }, [availableAgencies.length, formikProps.initialValues]);

  async function onAgencyChange(agency, setFieldValue) {
    setFieldValue("agency", agency);
    setFieldValue("consult", null);
    setFieldValue("office", null);
    if (agency) {
      fetchOffices(agency.value);
    } else {
      setOfficeOptions([]);
    }
  }

  return (
    <Modal
      show
      size="lg"
      className="register-company-modal btn-toggle-grey"
      onHide={handleClose}
      animation={false}
      dialogAs={DraggableModalDialog}
      scrollable
    >
      <Formik {...formikProps}>
        {({ values, isSubmitting, errors, setFieldValue }) => {
          return (
            <Form>
              <AgencySetter availableAgencies={availableAgencies} />
              <Modal.Header closeButton>
                <Modal.Title as="h4">{t("navigation:registerCompany")}</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ height: 550 }}>
                <Row>
                  <Col>
                    <FormGroup.Input label={t("common:companyName")} name="name" required />
                  </Col>
                  <Col>
                    <FormGroup.Input label={t("common:orgNo")} name="org_no" required />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup.SimpleSelect
                      label={t("company:companyType")}
                      name="company_type"
                      options={companyTypeOptions}
                      required
                    />
                  </Col>
                  <Col>
                    <FormGroup.Input label={t("common:customerNo")} name="client_no" min={1} />
                  </Col>
                </Row>
                <StaffPermRequired>
                  <FormGroup.YesNoToggle label={t("createAgencyCompany")} name="create_agency" />
                </StaffPermRequired>
                {!values.create_agency && availableAgencies.length > 1 && !formikProps.initialValues.agency && (
                  <FormGroup.SimpleSelect
                    label={t("common:agency")}
                    name="agency"
                    options={agencyOptions}
                    required={!values.create_agency}
                    onChange={(selected) => onAgencyChange(selected, setFieldValue)}
                    maxMenuHeight={150}
                  />
                )}
                <>
                  <Row lg={2}>
                    <Col>
                      <ConsultPicker
                        key={values.agency?.value}
                        name="consult"
                        agencyId={values.agency?.value}
                        label={t("common:customerManager")}
                        isDisabled={values.create_agency || !values.agency}
                        required={!values.create_agency}
                        maxMenuHeight={150}
                      />
                    </Col>
                    <Col>
                      {!values.create_agency && values.agency && officeOptions.length > 0 && (
                        <FormGroup.SimpleSelect
                          label={t("common:office")}
                          name="office"
                          options={officeOptions}
                          isClearable
                          onChange={(selected) => setFieldValue("office", selected)}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <FormGroup.DatePicker
                            label={`${t("common:financialYear")} ${t("common:starts")}`}
                            name="financial_year_date_start"
                            popperClassName="popper-in-modal"
                            required
                          />
                        </Col>
                        <Col>
                          <FormGroup.DatePicker
                            label={t("common:ends")}
                            popperClassName="popper-in-modal"
                            name="financial_year_date_end"
                            required
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
                <AllError errors={errors} />
              </Modal.Body>
              <Modal.Footer>
                <SubmitButton isSubmitting={isSubmitting} />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default RegisterCompanyModal;
