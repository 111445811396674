import React from "react";
import { Card } from "react-bootstrap";
import * as agencyAPI from "api2/agencies";
import TableProvider from "components/tables/btable/provider";
import AgencyScheduleTemplateExtendedTable from "components/tables/AgencyScheduleTemplateExtendedTable/AgencyScheduleTemplateExtendedTable";
import AgencyScheduleTemplateAddWidget from "components/forms/agency-settings-forms/AgencyScheduleTemplateAddWidget";
import { useParams } from "react-router-dom";

function AgencyScheduleTemplatePage() {
  const { agencyId } = useParams();
  const initialFilters = {};

  return (
    <TableProvider
      tableId="scheduleTemplates"
      dataSource={(filters, config) => agencyAPI.scheduleTemplates.list(agencyId, filters, config)}
      initialFilters={initialFilters}
      keepTerm={false}
    >
      <Card>
        <Card.Body>
          <AgencyScheduleTemplateExtendedTable agencyId={agencyId} />
        </Card.Body>
      </Card>
      <AgencyScheduleTemplateAddWidget agencyId={agencyId} />
    </TableProvider>
  );
}

export default AgencyScheduleTemplatePage;
