import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

import { BExpandedTable } from "components/tables/btable";
import { SupplierEditWidget } from "components/forms/SupplierForm";
import Filters from "./SupplierFilters";

function SupplierExtendedTable({ companyId }) {
  const { t } = useTranslation("common");

  const headers = useMemo(
    () => [
      {
        field: "supplier_name",
        label: t("common:name"),
        sortField: "supplier__name",
        render: (row) => row.global_supplier.name,
      },
      {
        field: "supplier_org_no",
        label: t("common:orgNo"),
        canSort: false,
        render: (row) => row.global_supplier.org_no,
      },
      {
        field: "supplier_country",
        label: t("common:contact.country"),
        sortField: "supplier__country",
        render: (row) => row.global_supplier.country,
      },
    ],
    [t]
  );

  return (
    <Row>
      <Col>
        <BExpandedTable
          headers={headers}
          FiltersComponent={<Filters />}
          renderExpandedRow={(row) => <SupplierEditWidget id={row.id} companyId={companyId} />}
        />
      </Col>
    </Row>
  );
}

export default SupplierExtendedTable;
