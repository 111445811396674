import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Alert, Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { Form, Formik } from "formik";
import { AllError, ConsultPicker, FormGroup } from "components/formik";
import { RemoveButton, SubmitButton } from "components/ui/buttons";
import _ from "lodash";
import * as options from "api/options";
import * as companyAPI from "api/company";
import { formatDate } from "utils/date";
import { toast } from "react-toastify";

function RepeatedTaskForm({ companyId, task, successCallback, deleteCallback }) {
  const { t } = useTranslation("company");
  const byWeekDays = options.byWeekDays.asList();
  const byMonths = options.byMonths.asList();
  const byMonthDays = [...Array(31).keys()].map((i) => ({
    value: `${i + 1}`,
    label: i + 1,
  }));

  const onDelete = () => {
    return companyAPI.removeCompanyTTRepeatedTask(companyId, task.id).then(() => {
      if (deleteCallback) {
        deleteCallback();
      }
      toast.success(t("msg:deleted"), { autoClose: 2000 });
    });
  };

  const showOccurrences = React.useCallback(
    async (task2, validateForm) => {
      const _errors = await validateForm();
      if (!_.isEmpty(_errors)) {
        return;
      }
      companyAPI.getOccurrences(companyId, companyAPI.formatRepeatedTask(task2)).then((response) => {
        let text = "First 10 occurrences\n\n";
        for (let i = 0; i < response.data.length; i++) {
          text += `${formatDate(response.data[i])}\n`;
        }
        window.alert(text);
      });
    },
    [companyId]
  );

  const formikProps = {
    initialValues: {
      ...task,
    },
    initialTouched: { title: true, assignee: true },
    validationSchema: yup
      .object()
      .shape({
        title: yup.string().required(),
        assignee: yup.object().nullable().required(),
        bysetpos: yup.number().min(-16).max(16),
      })
      .test("configurationOk", function (values) {
        const { bymonth, bymonthday, bysetpos, byweekday } = values;
        if (_.isEmpty(bymonth) && _.isEmpty(bymonthday) && !bysetpos && _.isEmpty(byweekday)) {
          return this.createError({
            path: "__all__",
            message: t("errors.ttConfigurationInvalid"),
          });
        }
        return true;
      }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      return companyAPI
        .saveRepeatedTask(companyId, companyAPI.formatRepeatedTask(values))
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (successCallback) {
            successCallback();
          }
          resetForm();
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors({ tasks: error.data });
        });
    },
  };
  return (
    <Formik {...formikProps}>
      {({ isSubmitting, values, errors, validateForm }) => {
        const disabled = {
          byweekday: !!(values.bymonthday && values.bymonthday.length) || !!values.bysetpos,
          bymonthday: !!(values.byweekday && values.byweekday.length) || !!values.bysetpos,
          bysetpos:
            !!(values.byweekday && values.byweekday.length) || !!(values.bymonthday && values.bymonthday.length),
        };

        return (
          <Form>
            <Row>
              <Col sm={6} xl={3}>
                <FormGroup.Input label={t("common:name")} name="title" required />
              </Col>
              <Col sm={6} xl={3}>
                <ConsultPicker name="assignee" company={companyId} required for="repeated_task" />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup.SimpleSelect
                  label={t("byWeekDay")}
                  className="multi-select"
                  options={byWeekDays}
                  isMulti
                  isClearable
                  isDisabled={disabled.byweekday}
                  required={!disabled.byweekday}
                  name="byweekday"
                />
              </Col>
              <Col>
                <FormGroup.SimpleSelect
                  options={byMonthDays}
                  className="multi-select"
                  label={t("byDate")}
                  isMulti
                  isDisabled={disabled.bymonthday}
                  required={!disabled.bymonthday}
                  isClearable
                  name="bymonthday"
                />
              </Col>
              <Col>
                <FormGroup.SimpleSelect
                  className="multi-select"
                  label={t("byMonth")}
                  options={byMonths}
                  isMulti
                  isClearable
                  name="bymonth"
                />
              </Col>
              <Col md={2}>
                <FormGroup.Input
                  label={t("bySetPos")}
                  type="number"
                  name="bysetpos"
                  disabled={disabled.bysetpos}
                  required={!disabled.bysetpos}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup.Checkbox name="active" label={t("common:statuses.active")} />
              </Col>
            </Row>
            <hr />
            <AllError errors={errors} />
            {values.system && <Alert variant="warning">{t("company:ttSchemaChangeWarn")}</Alert>}
            <div className="space-1">
              <ButtonGroup>
                <SubmitButton isSubmitting={isSubmitting} />
                <Button variant="secondary" onClick={() => showOccurrences(values, validateForm)}>
                  <i className="fas fa-search" /> {t("showOccurrences")}
                </Button>
              </ButtonGroup>
              {task.id && !task.system && (
                <RemoveButton
                  variant="danger"
                  confirmMessage={t("confirm.removeRTask", {
                    title: task.title,
                  })}
                  disabled={isSubmitting}
                  onClick={onDelete}
                />
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default RepeatedTaskForm;
