import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { codesForConsultManage } from "components/perms/PermCodes";
import { useTranslation } from "react-i18next";
import { PermCodeRequired } from "components/perms";

function MenuSwitcher({ menuRegular, onChange }) {
  const { t } = useTranslation("common");

  return (
    <PermCodeRequired code={codesForConsultManage.enabled}>
      <ButtonGroup size="sm" className="menu-switcher">
        <Button active={menuRegular} variant="switch" onClick={() => onChange(true)}>
          {t("regular")}
        </Button>
        <Button active={!menuRegular} variant="switch" onClick={() => onChange(false)}>
          {t("consult")}
        </Button>
      </ButtonGroup>
    </PermCodeRequired>
  );
}

export default MenuSwitcher;
