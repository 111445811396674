import React from "react";
import { useTranslation } from "react-i18next";
import useTable from "components/tables/btable/useTable";
import * as userAPI from "api2/users";
import { TooltipActionButton } from "components/ui/buttons";

function OfficeSupportCompanyActions({ row, forStaff, userId }) {
  const { t } = useTranslation(["company", "navigation"]);
  const {
    dataActions: { updateRow },
  } = useTable();
  function toggleFavourite(companyId) {
    return userAPI
      .consultToggleFavourite(companyId)
      .then((response) => {
        updateRow({ ...row, ...response.data });
      })
      .catch(() => {});
  }
  return (
    <TooltipActionButton
      text={t("toggleFavourite")}
      icon="fas fa-heart"
      onClick={() => toggleFavourite(row.id)}
      variant={row.is_favourite ? "action-toggle active" : "action-toggle"}
    />
  );
}

export default OfficeSupportCompanyActions;
