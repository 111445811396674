import React from "react";
import * as supportAPI from "api2/office-support";
import { withInitialAsync } from "hooks/useAsync";
import OfficeSupportContactPersonForm from "./OfficeSupportContactPersonForm";

function OfficeSupportContactPersonWidget({ data: item, companyId, changeEditedForm }) {
  return <OfficeSupportContactPersonForm companyId={companyId} changeEditedForm={changeEditedForm} data={item} />;
}

const EnhancedOfficeSupportContactPersonWidget = withInitialAsync(
  OfficeSupportContactPersonWidget,
  ({ companyId }) =>
    React.useCallback((cancelToken) => supportAPI.contacts.list(companyId, { cancelToken }), [companyId]),
  [],
  false,
  true
);

export default EnhancedOfficeSupportContactPersonWidget;
