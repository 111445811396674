import React, { useCallback, useContext, useMemo, useState } from "react";
import { savedFilters } from "state/providers/UserProvider";
import { FinancialYearStateContext } from "./FinancialYearProvider";

const FiltersContext = React.createContext({
  filters: {},
  setFilters: () => {},
});

function FiltersProviderRelatedToFY({ filtersId, initial, options, children }) {
  const { financialYear } = useContext(FinancialYearStateContext);

  const [filters, setFilters] = useState(() => initial || savedFilters[filtersId] || {});
  function clearSelection(newFilters) {
    setFilters(newFilters);
  }
  React.useEffect(() => {
    // skip initial render
    return () => {
      clearSelection(initial || {});
    };
  }, [initial, financialYear.id]);
  const updateFilter = useCallback(
    (newFilter) => {
      setFilters((state) => ({ ...state, ...newFilter }));
    },
    [setFilters]
  );
  React.useEffect(() => {
    // saving filters
    if (filtersId) {
      savedFilters[filtersId] = {
        ...filters,
      };
    }
  }, [filtersId, filters]);
  const stateValue = useMemo(
    () => ({
      options,
      filters,
      updateFilter,
      clearSelection,
    }),
    [filters, options, updateFilter]
  );

  return <FiltersContext.Provider value={stateValue}>{children}</FiltersContext.Provider>;
}

export { FiltersContext, FiltersProviderRelatedToFY };
