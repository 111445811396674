import client from "./client";

export function cashflowReport(companyId, params, config) {
  return client.get(`/companies/${companyId}/statistics/cashflow/`, {
    params,
    ...config,
  });
}

export function cashflowSimulation(companyId, simulation) {
  return client.put(`/companies/${companyId}/statistics/cashflow/`, simulation);
}
