import React from "react";
import { useTranslation } from "react-i18next";

import { formatMoney } from "utils/money";
import { withInitialAsync } from "hooks/useAsync";
import * as statsAPI from "api2/stats";

function CashBox({ data: info, selectedAmount }) {
  const { t } = useTranslation("common");
  const cashAvailable = info.overdraft_facility + info.bank_balance - info.money_sent_bank - (selectedAmount || 0);
  return (
    <div className="cash-box">
      <div className="box">
        <span>{t("overdraftFacility")}</span>
        <p>{formatMoney(info.overdraft_facility, 0, 0)}</p>
      </div>
      <div className="box">
        <span>{t("cashInBank")}</span>
        <p>{info.bank_balance === null ? "N/A" : formatMoney(info.bank_balance, 0, 0)}</p>
        {info.bank_balance_date && info.bank_balance ? <small>{info.bank_balance_date}</small> : null}
      </div>
      <div className="box">
        <span>{t("paymentsInBank")}</span>
        <p>{formatMoney(-info.money_sent_bank, 0, 0)}</p>
      </div>
      <div className="box">
        <span>{t("paymentsMarked")}</span>
        <p className="text-primary">{formatMoney(-selectedAmount, 0, 0)}</p>
      </div>
      <div className="box">
        <span>{t("cashAvailable")}</span>
        <p className="text-success">{formatMoney(cashAvailable, 0, 0)}</p>
      </div>
    </div>
  );
}

const EnhancedCashBox = withInitialAsync(
  CashBox,
  ({ companyId }) =>
    React.useCallback((cancelToken) => statsAPI.company.authorities(companyId, null, { cancelToken }), [companyId]),
  {
    bank_balance: 0,
    money_sent_bank: 0,
    overdraft_facility: 0,
  },
  false
);

export default EnhancedCashBox;
