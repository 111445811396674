import React from "react";
import { Col, Row } from "react-bootstrap";
import { SavedVerificationDocuments } from "components/ui/documents-refactored";
import { NoDocumentBox } from "components/ui/documents/document-box";
import { PreviewInput } from "components/formik/PreviewInput";
import { ReInvoicedAlert } from "components/ui/alerts";
import ApprovalDataUpdateForm from "components/forms/ApprovalDataUpdateForm";
import { VerificationInlineComments } from "components/modals/CommentsModal";
import { HasPermCode } from "components/perms";
import { codesForOutlays } from "components/perms/PermCodes";
import RepresentationPreviewContent from "pages/dashboard/expenses/RepresentationPreviewContent/RepresentationPreviewContent";

function ReceiptPreviewContent({
  company,
  outlay,
  inApproval,
  handlePartialUpdate,
  handleReceiptVerificationPreview,
  t,
  children,
}) {
  const canApprove = HasPermCode(codesForOutlays.approve);
  return (
    <Row>
      <Col xl={5}>
        <Row>
          <Col lg={4}>
            <PreviewInput label={t("common:employee")} value={<strong>{outlay.created_by}</strong>} />
          </Col>
          <Col lg={4}>
            <PreviewInput label={t("paidWith")} value={outlay.paid_by} />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <PreviewInput label={t("common:dates.receiptDate")} value={outlay.receipt_date} />
          </Col>
          <Col lg={4}>
            <PreviewInput label={t("common:money.amount")} value={outlay.amount} />
          </Col>
          <Col lg={4}>
            <PreviewInput label={t("common:money.vatAmount")} value={outlay.vat_amount} />
          </Col>
          {!inApproval && (
            <Col lg={12}>
              <PreviewInput label={t("common:account")} value={outlay.account ? outlay.account.label : "-"} />
            </Col>
          )}
        </Row>
        {outlay.account && outlay.account.number === 6072 && <RepresentationPreviewContent t={t} instance={outlay} />}
        {!inApproval && (
          <Row>
            <Col lg={4}>
              <PreviewInput label={t("common:costCenter")} value={outlay.cost_center && outlay.cost_center.label} />
            </Col>
            <Col lg={4}>
              <PreviewInput label={t("common:project")} value={outlay.project && outlay.project.label} />
            </Col>
          </Row>
        )}
        <PreviewInput label={t("common:description")} value={outlay.description} />
        {inApproval && canApprove && (
          <ApprovalDataUpdateForm
            invoice={outlay}
            onSubmit={handlePartialUpdate}
            onVerificationPreview={handleReceiptVerificationPreview}
          />
        )}
        <VerificationInlineComments companyId={company.id} itemId={outlay.id} />
        <ReInvoicedAlert invoiceId={outlay.reinvoice_to} />
        <div className="mt-3">
          <hr />
          {children}
        </div>
      </Col>
      <Col xl={7} className="verification-files">
        <SavedVerificationDocuments
          verificationId={outlay.id}
          companyId={company.id}
          documents={outlay.documents}
          removable={false}
        />
        {!outlay.documents?.length && <NoDocumentBox />}
      </Col>
    </Row>
  );
}

export default ReceiptPreviewContent;
