import React, { useCallback } from "react";
import { toast } from "react-toastify";
import * as verAPI from "api2/verification";
import { useTranslation } from "react-i18next";
import { Alert, Button, Modal } from "react-bootstrap";
import useAsync from "../../../hooks/useAsync";
import { useCompanyState } from "../../../hooks/useCompany";
import BaseVerificationsPreviewContainer from "./BaseVerificationsPreviewContainer";
import { DraggableModalDialog } from "../DraggableModalDialog";

function PeriodisationEndUndoStopPreviewsModal({ perConfigId, handleClose, undoOnly }) {
  const { t } = useTranslation("ver");
  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={() => handleClose()}
      dialogClassName="task-modal"
      dialogAs={DraggableModalDialog}
      size="xl"
      className="verification-preview-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("previewVers")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          {!undoOnly && (
            <>
              <h4 className="text-center">{t("common:actions.end")}</h4>
              <PeriodisationActionPreviewsContainer perConfigId={perConfigId} action="end" />
              <hr style={{ marginTop: "-10px", marginBottom: "30px", borderTop: "2px solid #348cd4" }} />
            </>
          )}
          <h4 className="text-center">{t("common:actions.undo")}</h4>
          <PeriodisationActionPreviewsContainer perConfigId={perConfigId} action="undo" />
          {!undoOnly && (
            <>
              <hr style={{ marginTop: "-10px", marginBottom: "30px", borderTop: "2px solid #348cd4" }} />
              <h4 className="text-center">{t("common:actions.stop")}</h4>
              <PeriodisationStopPreviewsContainer />
            </>
          )}
        </>
      </Modal.Body>
      <Modal.Footer className="space">
        <Button type="button" variant="secondary" onClick={() => handleClose()}>
          {t("common:actions.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function PeriodisationActionPreviewsContainer({ perConfigId, action }) {
  const { t } = useTranslation("ver");
  if (action === "stop") {
    return <PeriodisationStopPreviewsContainer />;
  }
  if (action === "undo") {
    return (
      <FetchPeriodisationActionPreviewsContainer
        perConfigId={perConfigId}
        action={action}
        topText={t("ver:perUndoPreviewDesc")}
        extraMissingVerText={t("ver:perNoVerForPreview")}
      />
    );
  }
  if (action === "end") {
    return (
      <FetchPeriodisationActionPreviewsContainer
        perConfigId={perConfigId}
        action={action}
        topText={t("ver:perEndPreviewDesc")}
      />
    );
  }
  return null;
}

function FetchPeriodisationActionPreviewsContainer({ perConfigId, action, topText, extraMissingVerText }) {
  const {
    company: { id: companyId },
  } = useCompanyState();
  const dataSource = useCallback(
    (cancelToken) =>
      verAPI.changePeriodisationStatusVerificationsPreview(companyId, perConfigId, action).catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__);
        }
      }),
    [companyId, perConfigId, action]
  );
  const [{ data: verifications, loading }] = useAsync(dataSource, []);
  return (
    <BaseVerificationsPreviewContainer
      verifications={verifications}
      isLoading={loading}
      topText={topText}
      extraMissingVerText={extraMissingVerText}
    />
  );
}

function PeriodisationStopPreviewsContainer() {
  const { t } = useTranslation("ver");
  return (
    <div className="verification-preview">
      <p className="text-center pb-1">{t("ver:perStopPreviewDesc")}</p>
      <Alert variant="secondary">{t("ver:perNoVerForPreview")}</Alert>
    </div>
  );
}

export default PeriodisationEndUndoStopPreviewsModal;
export { PeriodisationActionPreviewsContainer };
