import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, useFormikContext } from "formik";
import { Button, Card, Col, Row } from "react-bootstrap";
import { AllError, FormGroup } from "components/formik";
import { debounce } from "lodash";
import { SubmitButton } from "components/ui/buttons";
import * as options from "api/options";
import * as supportAPI from "api2/office-support";
import * as selectAPI from "api2/selects";
import { toast } from "react-toastify";
import { useCompanyState } from "../../../../hooks/useCompany";

const getOffices = debounce((params, callback) => {
  selectAPI.offices(params).then((data) => {
    return callback(data);
  });
}, 500);

function OfficeSupportInternalForm({ companyId, changeEditedForm, initialData }) {
  const { t } = useTranslation("company");
  const [showMore, setShowMore] = useState(true);
  const internalCustomerCategory = options.internalCustomerCategory.asList();
  const { company } = useCompanyState();
  const formikProps = {
    initialValues: {
      agency_name: initialData.agency_name,
      office: initialData.office && {
        value: initialData.office,
        label: initialData.office_name,
      },
      customer_category: options.internalCustomerCategory.getOption(initialData.customer_category),
      customer_category_comment: initialData.customer_category_comment || "",
    },
    onSubmit: async (values, { setErrors, resetForm }) => {
      await supportAPI.internal
        .update(companyId, {
          ...values,
          customer_category: values.customer_category?.value,
          agency: values.agency?.value || null,
          office: values.office?.value || null,
        })
        .then((response) => {
          resetForm({ values });
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          if (error.data.__all__) {
            setErrors({ __all__: error.data.__all__ });
          } else {
            setErrors({ ...error.data });
          }
        });
    },
  };

  function FormUpdateChecker() {
    const { dirty } = useFormikContext();
    React.useEffect(() => {
      changeEditedForm({ id: "internal-form", dirty });
    }, [dirty]);
    return null;
  }

  return (
    <Formik {...formikProps}>
      {({ values, touched, isSubmitting, setFieldValue, errors, resetForm, dirty }) => {
        return (
          <Form>
            <FormUpdateChecker />
            <Card>
              <Card.Header onClick={() => setShowMore((prevValue) => !prevValue)}>
                <Card.Title style={{ color: "#ADADAD", fontWeight: 400, fontSize: "12px" }}>
                  {t("header.internal")}
                  <i
                    style={{ textAlign: "right", float: "right" }}
                    className={showMore ? "fas fa-chevron-up fa-1x" : "fas fa-chevron-down fa-1x"}
                  />
                </Card.Title>
              </Card.Header>
              {showMore && (
                <Card.Body className="pt-0">
                  <Row>
                    <Col xl={6}>
                      <FormGroup.Input label={t("title.agency")} name="agency_name" disabled />
                    </Col>
                    {initialData.office_count > 0 && (
                      <Col xl={6}>
                        <FormGroup.AsyncSelect
                          label={t("title.office")}
                          name="office"
                          cacheOptions
                          minSearchLength={0}
                          loadOptions={(params, callback) =>
                            getOffices({ ...params, agencies: [company.agency_id] }, callback)
                          }
                          isClearable
                        />
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col xl={6}>
                      <FormGroup.SimpleSelect
                        label={t("title.customerCategory")}
                        name="customer_category"
                        options={internalCustomerCategory}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup.Input
                        as="textarea"
                        label={t("title.customerCategoryComment")}
                        name="customer_category_comment"
                        rows={3}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <AllError errors={errors} />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-right">
                      {dirty && (
                        <Button variant="link" size="lg" onClick={() => resetForm()} className="cancel-btn">
                          {t("common:actions.cancel")}
                        </Button>
                      )}
                      <SubmitButton isSubmitting={isSubmitting} disabled={!dirty} />
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default OfficeSupportInternalForm;
