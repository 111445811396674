import React, { useContext, useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";

import { useAuthState } from "hooks/useAuth";
import { TimeTrackerStateContext } from "state/providers/TimeTrackerProvider";
import TimeGridProvider, { TimeGridDispatchContext, TimeGridStateContext } from "state/providers/TimeGridProvider";
import { subDays } from "date-fns";
import WeekSummary from "./timegrid/WeekSummary";
import WeekPicker from "./timegrid/WeekPicker";
import TimeGridCalendar from "./timegrid/TimeGridCalendar";
import DisputedAbsenceReportBox from "./timegrid/DisputedAbsenceReportBox";

function TimeTrackerTimeReportPage({ agencyId }) {
  const calendarRef = useRef();
  const {
    reported: { scheduleDateStart },
  } = useContext(TimeGridStateContext);
  const { fetchLogs } = useContext(TimeGridDispatchContext);
  const changeWeek = (selectedDate) => {
    const api = calendarRef.current.getApi();
    api.gotoDate(selectedDate);
    fetchLogs(api.view.activeStart, subDays(api.view.activeEnd, 1));
  };

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col xl={8} md={12}>
            <TimeGridCalendar ref={calendarRef} />
          </Col>
          <Col xl={4} md={12}>
            <WeekPicker agencyId={agencyId} changeWeek={changeWeek} scheduleDateStart={scheduleDateStart} />
            <WeekSummary />
            <div className="absence-box-wrapper">
              <DisputedAbsenceReportBox agencyId={agencyId} />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

function TimeTrackerTimeReportPageContainer() {
  const { user } = useAuthState();
  const { selected: selectedAgency } = useContext(TimeTrackerStateContext);
  return (
    <TimeGridProvider agencyId={selectedAgency.agency} userId={user.id}>
      <TimeTrackerTimeReportPage agencyId={selectedAgency.agency} />
    </TimeGridProvider>
  );
}

export default TimeTrackerTimeReportPageContainer;
