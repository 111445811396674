import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import logoBeige from "assets/images/bflow-beige.png";
import LanguageSwitcher from "components/ui/LanguageSwitcher";
import "./AuthLayout.scss";

function AuthLayout() {
  useEffect(() => {
    document.body.classList.add("authentication-bg2");
    return () => {
      document.body.classList.remove("authentication-bg2");
    };
  });
  return (
    <div className="auth-layout">
      <div className="header">
        <a href="/public" className="logo">
          <img src={logoBeige} alt="" height="100" />
        </a>
        <a href="https://bfree.se" className="home" target="_blank" rel="noreferrer">
          <i className="fe-home h2" />
        </a>
      </div>
      <div className="body">
        <Outlet />
      </div>
      <LanguageSwitcher />
    </div>
  );
}

export default AuthLayout;
