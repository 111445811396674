import React, { useState } from "react";
import * as yup from "yup";
import { Alert, Card, Col, Row } from "react-bootstrap";
import { Form, Formik } from "formik";

import * as companyAPI from "api2/companies";
import { SubmitButton } from "components/ui/buttons";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import _ from "lodash";
import * as options from "api/options";
import { NewDocuments } from "components/ui/documents/verification-documents";
import * as documentAPI from "api/document";
import { FormGroup } from "components/formik";
import { confirmExecute } from "components/modals/ConfirmModal";

function SIEUploadForm({ companyId, onUploadSuccess, forOnboarding = false }) {
  const { t } = useTranslation("company");

  const seriesOptions = options.sieFileVerSeries.asList();
  const [newFiles, setNewFiles] = useState([{ key: _.uniqueId("nd.") }]);
  const _onSubmit = (_newFiles, data, setErrors) => {
    return companyAPI.sieFiles
      .upload(companyId, _newFiles[0], data)
      .then((response) => {
        onUploadSuccess(response.data);
      })
      .catch(async (error) => {
        if (error.data.__all__ || error.data?.file?.file) {
          toast.error(error.data.__all__ || error.data.file.file[0]);
        } else if (error.data.confirm) {
          const confirmed = await confirmExecute(error.data.confirm);
          if (confirmed) {
            data.skip_existing_verification_check = true;
            await _onSubmit(_newFiles, data, setErrors);
          }
        }
        setNewFiles([{ key: _.uniqueId("nd.") }]);
        setErrors(error.data);
      });
  };
  const formikProps = {
    initialValues: {
      series: options.sieFileVerSeries.getOption("Z"),
      allowCreateFyears: false,
    },
    validationSchema: yup.object().shape({
      series: yup.object().nullable().required(),
      allowCreateFyears: yup.boolean(),
    }),
    async onSubmit(values, setErrors) {
      const _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
      if (!_newFiles.length) {
        toast.error(t("SIEFileRequired"), { autoClose: 4000 });
        return null;
      }
      const data = {
        allow_create_fyears: values.allowCreateFyears,
        series: values.series.value,
        skip_existing_verification_check: false,
      };
      return _onSubmit(_newFiles, data, setErrors);
    },
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors }) => {
        return (
          <Form>
            <Card.Body data-testid={`sie-upload-card-body${forOnboarding ? "-onboarding" : ""}`}>
              <Card.Title>{t("uploadSIEFile")}</Card.Title>
              <NewDocuments
                documents={newFiles}
                multiple={false}
                fileTypes={[""]}
                customValidate={(selectedFile) => {
                  let valid = false;
                  const fileName = selectedFile.name;
                  const allowedFormats = ["si", "se", "sie"];
                  if (allowedFormats.some((v) => fileName.toLowerCase().split(".").pop() === v)) {
                    valid = true;
                  }
                  if (!valid) {
                    toast.error(t("common:file.notSupported", { formats: "SIE (.se, .si, .sie)" }), {
                      autoClose: 4000,
                    });
                  }
                  return valid;
                }}
                onChange={({ file, index }) =>
                  documentAPI.onNewFileChange({
                    file,
                    index,
                    allFiles: newFiles,
                    setFile: setNewFiles,
                    max: 1,
                  })
                }
              />
              <Row>
                {!forOnboarding && (
                  <Col sm={6} xl={3}>
                    <FormGroup.SimpleSelect
                      name="series"
                      label={t("ver:series")}
                      options={seriesOptions}
                      required
                      width="200px"
                    />
                  </Col>
                )}
                <Col>
                  <p className="pt-3">
                    {values.series.value === "Z"
                      ? t("company:sie.infoAboutZSeries")
                      : t("company:sie.infoAboutPartialSeries")}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6} xl={3}>
                  <label>{t("common:options")}</label>
                  <FormGroup.Checkbox
                    title={t("common:options")}
                    label={t("company:allowCreateFyears")}
                    name="allowCreateFyears"
                    disabled={values.series.value !== options.sieFileVerSeries.getOption("Z").value}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Alert variant="info">{t("SIEUploadInfo")}</Alert>
              <SubmitButton title="common:actions.upload" isSubmitting={isSubmitting} />
            </Card.Footer>
          </Form>
        );
      }}
    </Formik>
  );
}

export default SIEUploadForm;
