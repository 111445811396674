import { isFirstDayOfMonth } from "date-fns";

function filterPeriodisationCostAccountsForSI(option) {
  return option.id >= 4000 && option.id <= 7999;
}

function filterPeriodisationCostAccountsForVer(option) {
  return option.id === 3800 || (option.id >= 4000 && option.id <= 7999);
}

const periodisationAccounts = [1791, 2991];
const periodisationToogleAccounts = [1790, 2990, 1791, 2991];

function getPeriodisationStartDateInvalidMsg(t, startDate, bookingDate, isForInvoice = false) {
  // first day of month validation
  if (!isFirstDayOfMonth(startDate)) {
    return t("ver:errors.periodisationStartOnNotFirstDay");
  }
  // valid if not to early
  const today = new Date();
  const earielstPeriodisationDateStartBasedOnToday = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  if (earielstPeriodisationDateStartBasedOnToday <= startDate) {
    return null;
  }
  if (bookingDate >= today) {
    // earliest day is first day of 1 month before from today
    return t("ver:errors.periodisationStartOnToEarlyBasedToday");
  }
  const earielstPeriodisationDateStartBasedOnBookingDate = new Date(
    bookingDate.getFullYear(),
    bookingDate.getMonth() - 1,
    1
  );
  if (earielstPeriodisationDateStartBasedOnBookingDate > startDate) {
    // earliest day is first day of 1 month before from booking_date (also 1m before today)
    return t(`ver:errors.periodisationStartOnToEarlyBasedBookingDateFor${isForInvoice ? "Invoice" : "Booking"}Date`);
  }
  return null;
}

export {
  filterPeriodisationCostAccountsForVer,
  filterPeriodisationCostAccountsForSI,
  periodisationToogleAccounts,
  periodisationAccounts,
  getPeriodisationStartDateInvalidMsg,
};
