import React, { useContext } from "react";

import NavBar from "components/ui/navbar";
import AgencySettingProvider, { AgencySettingStateContext } from "state/providers/AgencySettingProvider";
import { RocketLoader } from "components/ui/loaders";
import { Outlet, useParams } from "react-router-dom";
import { codesForAgency } from "components/perms/PermCodes";
import { codeInList } from "components/perms";
import AgencySettingsMenu from "./AgencySettingsMenu";
import { useAuthState } from "../../../hooks/useAuth";

function AgencySettingsLayout({ children }) {
  const { info, loading } = useContext(AgencySettingStateContext);

  if (loading) {
    return <RocketLoader />;
  }
  return (
    <>
      <NavBar company={info} forDashboard={false} />
      <AgencySettingsMenu />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

function AgencySettingsWrapper() {
  const { agencyId } = useParams();
  const { user } = useAuthState();
  const agency = user.agencies.find((agencyUser) => agencyUser.agency === parseInt(agencyId, 10));

  if ((agency && codeInList(codesForAgency.enabled, agency.perm_codes)) || user.is_superuser) {
    return (
      <AgencySettingProvider agencyId={agencyId}>
        <AgencySettingsLayout />
      </AgencySettingProvider>
    );
  }
  return <div>NO PERMISSIONS</div>;
}

export default AgencySettingsWrapper;
