import React from "react";
import useModal from "hooks/useModal";
import { toast } from "react-toastify";
import * as supportApi from "api2/office-support";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import cx from "classnames";
import { DocumentsModalRefactored } from "../../../components/modals";
import { confirmExecute, confirmRemove } from "../../../components/modals/ConfirmModal";
import { useAuthState } from "../../../hooks/useAuth";

function ExistsAttachmentBadge({ companyId, note, onDeleteSuccess, forceShowDeleteButton = false }) {
  const { t } = useTranslation("company");
  const { user } = useAuthState();
  const userCanManage = user.id === note.created_by || user.is_superuser;
  const fileModal = useModal();
  const fileName = note.documents[0].title;
  const fileId = note.documents[0].id;

  const openModal = () => {
    fileModal.open();
  };
  const onDelete = async () => {
    const answer = await confirmRemove(t("common:actions.remove"));
    if (answer) {
      supportApi.notes
        .deleteDocument(companyId, note.id, fileId)
        .then(() => {
          toast.success(t("msg:deleted"));
          onDeleteSuccess();
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
    }
  };
  return (
    <>
      <AttachmentBadge
        fileName={fileName}
        onClick={openModal}
        onClickDelete={onDelete}
        forceShowDeleteButton={forceShowDeleteButton}
        forceHideDeleteButton={!userCanManage}
      />
      {fileModal.show && (
        <DocumentsModalRefactored
          partUrl={`/office-support/customer-notes/${note.id}/documents`}
          companyId={companyId}
          documentIds={[fileId]}
          handleClose={fileModal.close}
          removable={false}
        />
      )}
    </>
  );
}

function DeleteNoteButton({ companyId, note, onSuccessCallback }) {
  const { t } = useTranslation("company");
  const onDelete = async () => {
    const answer = await confirmRemove(t("common:actions.remove"));
    if (answer) {
      if (!note.parentId) {
        supportApi.notes
          .delete(companyId, note.id)
          .then(() => {
            toast.success(t("msg:deleted"));
            onSuccessCallback();
          })
          .catch(() => toast.error(t("msg:canNotExecuteAction")));
      } else {
        supportApi.notes.child
          .delete(companyId, note.parentId, note.id)
          .then(() => {
            toast.success(t("msg:deleted"));
            onSuccessCallback();
          })
          .catch(() => toast.error(t("msg:canNotExecuteAction")));
      }
    }
  };
  return (
    <Button variant="link" onClick={() => onDelete(note.id)}>
      <i className="fe-trash" />
    </Button>
  );
}

function ArchiveNoteButton({ companyId, note, onSuccessCallback }) {
  const { t } = useTranslation("common");
  const onClick = async () => {
    const answer = await confirmExecute(t("actions.archive"));
    if (answer) {
      supportApi.notes
        .archive(companyId, note.id)
        .then(() => {
          toast.success(t("archived"));
          onSuccessCallback();
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
    }
  };
  return (
    <Button variant="link" onClick={() => onClick(note.id)}>
      <i className="fe-archive" />
    </Button>
  );
}

function UnarchiveNoteButton({ companyId, note, onSuccessCallback }) {
  const { t } = useTranslation("common");
  const onClick = async () => {
    const answer = await confirmExecute(t("actions.unarchive"));
    if (answer) {
      supportApi.notes
        .unarchive(companyId, note.id)
        .then(() => {
          toast.success(t("unarchived"));
          onSuccessCallback();
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
    }
  };
  return (
    <Button className="unarchive-btn" variant="link" onClick={() => onClick(note.id)}>
      <i className="fe-rotate-cw" />
    </Button>
  );
}

function NewAttachmentBadge({ fileName, onDelete }) {
  return <AttachmentBadge fileName={fileName} onClick={() => {}} onClickDelete={onDelete} forceShowDeleteButton />;
}

function AttachmentBadge({
  fileName,
  onClick,
  onClickDelete,
  forceShowDeleteButton = false,
  forceHideDeleteButton = false,
}) {
  const displayFileName = fileName.length > 31 ? `${fileName.substring(0, 28)}...` : fileName;
  return (
    <span className="badge badge-attachment badge-pill onHoverAttachment">
      <Row className="badge-attachment-row">
        <Col className="badge-file-name-col">
          <span className="badge-file-name" onClick={onClick}>
            {displayFileName}
          </span>
        </Col>
        {!forceHideDeleteButton && (
          <Col
            className={cx(
              { "show-always": forceShowDeleteButton, showOnHoverAttachment: !forceShowDeleteButton },
              "vertical-divider"
            )}
          >
            <span>
              <i className="fe-x" onClick={() => onClickDelete()} />
            </span>
          </Col>
        )}
      </Row>
    </span>
  );
}

function EditButton({ onClick }) {
  return (
    <Button variant="link">
      <i className="fe-edit-2" onClick={() => onClick()} />
    </Button>
  );
}

export {
  DeleteNoteButton,
  ExistsAttachmentBadge,
  NewAttachmentBadge,
  ArchiveNoteButton,
  UnarchiveNoteButton,
  EditButton,
};
