import React from "react";
import { Card } from "react-bootstrap";

import TableProvider from "components/tables/btable/provider";
import { GlobalSupplierExtendedTable } from "components/tables";
import * as supplierAPI from "api2/suppliers";
import { GlobalSupplierAddWidget } from "components/forms/GlobalSupplierForm";

function GlobalSupplierListPage({ tableId = "globalSuppliers" }) {
  const initialFilters = {};

  return (
    <TableProvider
      tableId={tableId}
      dataSource={(filters, config) => supplierAPI.globals.list(filters, config)}
      initialFilters={initialFilters}
    >
      <Card>
        <Card.Body>
          <GlobalSupplierExtendedTable />
        </Card.Body>
      </Card>
      <GlobalSupplierAddWidget />
    </TableProvider>
  );
}

export default GlobalSupplierListPage;
