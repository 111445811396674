import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useTable from "components/tables/btable/useTable";

function TaxDirectPaymentFilters({ companyId, forStatus, allowedModes }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("tax");
  return (
    <section className="table-filters-left">
      {forStatus && allowedModes && (
        <div className="status-filter btn-group">
          {allowedModes.map((mode) => (
            <Button
              key={mode.value}
              variant={mode.variant}
              active={filters.mode === mode.value}
              onClick={() => setFilters({ mode: mode.value })}
            >
              {t(mode.label)}
            </Button>
          ))}
        </div>
      )}
      {forStatus && !allowedModes && (
        <Button variant={forStatus} onClick={() => {}}>
          {t(`common:statuses.${forStatus}`)}
        </Button>
      )}
      {!forStatus && (
        <ButtonGroup aria-label="Status filter" className="status-filter">
          <Button
            variant="toggle"
            active={!filters.mode || filters.mode === "all"}
            onClick={() => setFilters({ mode: "" })}
          >
            {t("common:statuses.all")}
          </Button>
          <Button
            variant="toggle-red"
            active={filters.mode === "unpaid"}
            onClick={() => setFilters({ mode: "unpaid" })}
          >
            {t("common:statuses.unpaid")}
          </Button>
          <Button
            variant="toggle-blue"
            active={filters.mode === "sent_bank"}
            onClick={() => setFilters({ mode: "sent_bank" })}
          >
            {t("common:statuses.sentBank")}
          </Button>
          <Button variant="toggle-green" active={filters.mode === "paid"} onClick={() => setFilters({ mode: "paid" })}>
            {t("common:statuses.paid")}
          </Button>
        </ButtonGroup>
      )}
    </section>
  );
}

export default TaxDirectPaymentFilters;
