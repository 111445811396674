import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";

import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import "./FinishedStep.scss";
import { withInitialAsync } from "hooks/useAsync";
import * as companyAPI from "api2/companies";
import { toast } from "react-toastify";
import { OnboardingDispatchContext } from "pages/companies/OnboardingWizard/provider/OnboardingProvider";
import { SaveButton } from "pages/companies/OnboardingWizard/utils";

function FinishedStep({ companyId, agencyId, initialLoading }) {
  const { t } = useTranslation("company");
  const { fetchDetails } = useContext(OnboardingDispatchContext);

  useEffect(() => {
    if (!initialLoading) {
      fetchDetails();
    }
  }, [initialLoading, fetchDetails]);

  const onSave = useCallback(() => {
    companyAPI.onboardings.steps
      .finishedFinish(companyId, agencyId, null)
      .then(() => {
        toast.success(t("msg:updated"));
        fetchDetails(false, false, true);
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__);
        }
      });
  }, [companyId, agencyId, fetchDetails, t]);

  return (
    <Card className="finished-step" data-testid="finished-step">
      <Card.Body className="pb-5">
        <StepHeader />
      </Card.Body>
      <Card.Body>
        <Card.Title>{t("onboarding.confirmFinishAndActivate")}</Card.Title>
        <SaveButton onSave={onSave} buttonText={t("company:finish&activate")} withIcon={false} />
      </Card.Body>
    </Card>
  );
}

const EnhancedFinishedStep = withInitialAsync(
  FinishedStep,
  ({ companyId, agencyId }) => {
    return React.useCallback(
      (cancelToken) => companyAPI.onboardings.steps.finished(companyId, agencyId, { cancelToken }),
      [companyId, agencyId]
    );
  },
  {},
  true
);

export default EnhancedFinishedStep;
