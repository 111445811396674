import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { useOutletContext, useParams } from "react-router-dom";

import TableProvider from "components/tables/btable/provider";
import { VerificationTable } from "components/tables";
import { FinancialYearStateContext } from "state/providers/FinancialYearProvider";
import * as verAPI from "api2/verification";

function VerificationListPage({ initialSeries, selectable = false, tableId = "verifications" }) {
  const company = useOutletContext();
  const { series } = useParams();
  const { financialYear } = useContext(FinancialYearStateContext);
  const initialFilters = {
    series: initialSeries || series || "",
    financial_year: financialYear && financialYear.id,
    ordering: "booking_date,id",
  };
  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) =>
            verAPI.companyVerifications(
              company.id,
              { ...filters, financial_year: financialYear && financialYear.id },
              config
            )
          }
          initialFilters={initialFilters}
        >
          <VerificationTable companyId={company.id} selectable={selectable} initialFilters={initialFilters} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default VerificationListPage;
