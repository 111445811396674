import React from "react";
import { codesFor, HasPermCode } from "components/perms";
import EmployeeAbsenceListPage from "./EmployeeAbsenceListPage";

function ApproveEmployeeAbsenceListPage() {
  const allowedModes = [{ value: "prelim", label: "common:statuses.prelim", variant: "toggle-orange" }];
  const canApprove = HasPermCode(codesFor.absences.approve);
  return (
    <EmployeeAbsenceListPage
      tableId="prelimAbsences"
      allowedModes={allowedModes}
      initialMode={allowedModes[0].value}
      selectable={canApprove}
    />
  );
}

export default ApproveEmployeeAbsenceListPage;
