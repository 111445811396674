import React from "react";
import { codesFor, HasPermCode } from "components/perms";
import OutlayListPage from "./OutlayListPage";

function PayOutlayListPage() {
  const canPayOutlays = HasPermCode(codesFor.outlays.pay);
  const allowedModes = [{ value: "unpaid", label: "common:statuses.unpaid", variant: "toggle-red" }];
  return (
    <OutlayListPage
      tableId="unpaidOutlays"
      initialMode="unpaid"
      allowedModes={allowedModes}
      selectable={canPayOutlays}
    />
  );
}

export default PayOutlayListPage;
