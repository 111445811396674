import React from "react";

import { UserDispatchContext, UserStateContext } from "state/providers/UserProvider";
import * as authAPI from "api2/auth";
import { LOGGED_IN, LOGGED_OUT, UPDATE_USER_PROPS } from "state/types";

function useAuthState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a UserProvider");
  }
  return context;
}

function useAuthDispatch() {
  const dispatch = React.useContext(UserDispatchContext);
  if (dispatch === undefined) {
    throw new Error("useAuthState must be used within a UserProvider");
  }

  function loggedInAction(token, user) {
    dispatch({
      type: LOGGED_IN,
      payload: { token, user },
    });
  }

  function updateUserProps(user) {
    dispatch({
      type: UPDATE_USER_PROPS,
      payload: { user },
    });
  }

  function standardLogin({ email, password, code, save_device, resend_code }) {
    return authAPI.login({ email, password, code, save_device, resend_code }).then((response) => {
      if (response.status === 200) {
        loggedInAction(response.data.token, response.data.user);
      }
      return response;
    });
  }

  function login2fResendCode(email, password) {
    return authAPI.login2fResendCode(email, password);
  }

  function bankIDLoginCheck(token) {
    const interval = setInterval(() => {
      authAPI
        .bankIDLoginCheck(token)
        .then((response) => {
          if (response.status === 201) {
            clearInterval(interval);
            loggedInAction(response.data.token, response.data.user);
          }
        })
        .catch((error) => {
          clearInterval(interval);
        });
    }, 2000);
    return interval;
  }

  function logout() {
    authAPI.logout().catch(() => {});
    return dispatch({ type: LOGGED_OUT });
  }

  return {
    standardLogin,
    login2fResendCode,
    bankIDLoginCheck,
    logout,
    updateUserProps,
    loggedInAction,
  };
}

export { useAuthState, useAuthDispatch };
