import DatePicker from "react-datepicker";
import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import { DATE_FORMAT, SHORT_DATE_FORMAT } from "utils/date";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import useModalOnPage from "components/modals/hooks/useModalOnPage";
import { filterByTextStatsWithAndIncludes } from "utils/filters";
import CreatableSelect from "react-select/creatable";

function BDatePicker({ field, label, ...pickerProps }) {
  const startRef = React.useRef();
  const onKeyDown = (event) => {
    if (event.keyCode === 9 || event.which === 9) {
      startRef.current.setOpen(false);
    }
  };
  return (
    <DatePicker
      ref={startRef}
      key={field.name}
      dateFormat={[DATE_FORMAT, SHORT_DATE_FORMAT]}
      {...field}
      {...pickerProps}
      onKeyDown={onKeyDown}
      selected={field.value}
      autoComplete="off"
      popperProps={{
        positionFixed: true, // use this to make the popper position: fixed
      }}
      popperContainer={({ children }) => createPortal(children, document.body)}
      className="form-control"
      style={{ width: "100%" }}
    />
  );
}

function BDateTimePicker({ field, label, ...pickerProps }) {
  const startRef = React.useRef();

  const onKeyDown = (event) => {
    if (event.keyCode === 9 || event.which === 9) {
      startRef.current.setOpen(false);
    }
  };

  return (
    <DatePicker
      ref={startRef}
      key={field.name}
      {...field}
      {...pickerProps}
      showTimeSelect
      timeIntervals={15}
      onKeyDown={onKeyDown}
      dateFormat={["yyyy-MM-dd, HH:mm:ss"]}
      selected={field.value}
      autoComplete="off"
      popperProps={{
        positionFixed: true, // use this to make the popper position: fixed
      }}
      className="form-control"
    />
  );
}

function BSelect({ options, filterOptionStartsWith = false, ...props }) {
  const { t } = useTranslation("common");
  const [filteredOptions, setFilteredOptions] = useState(options);

  const handleInputChange = useCallback(
    (inputValue) => {
      if (filterOptionStartsWith) {
        const newFilteredOptions = filterByTextStatsWithAndIncludes(inputValue, options);
        setFilteredOptions(newFilteredOptions);
      }
    },
    [options, filterOptionStartsWith]
  );

  useEffect(() => {
    if (filterOptionStartsWith) {
      const initialFilteredOptions = filterByTextStatsWithAndIncludes("", options);
      setFilteredOptions(initialFilteredOptions);
    }
  }, [options, filterOptionStartsWith]);

  const SelectInput = props.creatable ? CreatableSelect : Select;
  return (
    <SelectInput
      options={filterOptionStartsWith ? filteredOptions : options}
      maxMenuHeight={250}
      cacheOptions
      className="react-select"
      classNamePrefix="select"
      placeholder={props.placeholder || t("common:actions.select")}
      noOptionsMessage={({ inputValue }) => t("common:noOptions")}
      {...props}
      disabled={props?.disabled}
      onInputChange={handleInputChange}
    />
  );
}

function BSelectAsync({ field, loadOptions, minSearchLength, customNoOptionsMessage, ...props }) {
  const { t } = useTranslation("common");
  const isModal = useModalOnPage();

  const _loadOptions = (inputValue, callback) => {
    if (inputValue.length < minSearchLength) {
      return callback([]);
    }
    return loadOptions({ term: inputValue }, callback);
  };

  return (
    <AsyncSelect
      {...field}
      cacheOptions
      defaultOptions
      menuPlacement={isModal ? "bottom" : "auto"}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      maxMenuHeight={250}
      noOptionsMessage={({ inputValue }) => {
        if (inputValue.length < minSearchLength) {
          return t("minSearchInput", { minSearchLength });
        }

        if (customNoOptionsMessage) {
          return customNoOptionsMessage;
        }

        return t("common:noResultsFound");
      }}
      loadOptions={_loadOptions}
      className="react-select"
      classNamePrefix="select"
      placeholder={props.placeholder || t("common:actions.select")}
      {...props}
    />
  );
}

export { BDatePicker, BDateTimePicker, BSelect, BSelectAsync };
