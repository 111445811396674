import React from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";

import SupplierInvoiceFormWithActions from "components/forms/SupplierInvoiceForm";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { isAssetToAutoAddForSupplierInvoice } from "utils/assets";
import { HasPermCode } from "components/perms";
import { codesForInventoryRegister } from "components/perms/PermCodes";
import * as siAPI from "../../../../api2/supplier-invoices";

function SupplierInvoiceAddPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const invoice = {
    booking_date: new Date(),
    due_date: undefined,
    status: 0,
    sub_status: "",
    amount: undefined,
    amount_currency: "SEK",
    vat_amount: 0,
    invoice_no: "",
    ocr: "",
    periodisation_enabled: false,
    periodisation_date_start: null,
    periodisation_months: 1,
    project: null,
    cost_center: null,
    manual_booking_enabled: false,
    manual_booking: [],
    account_5615_half_vat: true,
    deactivate_payment: false,
  };
  const hasPermForInventoryRead = HasPermCode(codesForInventoryRegister.enabled);
  const onCreate = async (newInvoice) => {
    reloadMenuNumbers();
    if (hasPermForInventoryRead && newInvoice.status === 1 && isAssetToAutoAddForSupplierInvoice(newInvoice)) {
      window.location.href = `${
        pathname.includes("consult") ? "/consult" : ""
      }/inventory-register?draftsForVer=nr.${newInvoice.invoice_no.toString()}`;
    } else {
      const { next } = await siAPI.getNextDraft(company.id, newInvoice.id);
      if (next) {
        navigate(`/consult/supplier-invoices/${next.id}/change`, { replace: true });
      } else {
        navigate(-1);
      }
    }
  };

  return <SupplierInvoiceFormWithActions invoice={invoice} company={company} onSave={onCreate} />;
}

export default SupplierInvoiceAddPage;
