import React, { useState } from "react";
import { Alert, Button, Col, Row, Table } from "react-bootstrap";
import { FieldArray, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { ConfirmButton, RemoveButton, SubmitButton } from "components/ui/buttons";
import { AllError, FormGroup, TableGroup } from "components/formik";
import * as userAPI from "api2/users";
import * as authAPI from "api2/auth";
import * as options from "api/options";
import * as selectAPI from "api2/selects";
import { BSelect, BSelectAsync } from "components/formik/pickers";
import { confirmExecute } from "components/modals/ConfirmModal";
import UserConnectedCompanies from "./UserConnectedCompanies";
import "./user-form.scss";

const getAgencies = debounce((params, callback) => {
  selectAPI.agencies(params).then((data) => {
    return callback(data);
  });
}, 500);

function AddPermBox({ userRoles, onAddPerm }) {
  const [agency, setAgency] = useState(null);
  const [role, setRole] = useState(userRoles[0]);
  const { t } = useTranslation("common");

  const handleAdd = () => {
    onAddPerm({
      agency: agency.value,
      agency_name: agency.label,
      role,
      draft: true,
    });
    setRole(userRoles[0]);
    setAgency(null);
  };

  return (
    <tr>
      <td className="select">
        <BSelectAsync
          loadOptions={getAgencies}
          className="table-select"
          value={agency}
          onChange={(selected) => setAgency(selected)}
          classNamePrefix="table-select"
        />
      </td>
      <td className="select">
        <BSelect
          options={userRoles}
          className="table-select"
          value={role}
          onChange={(selected) => setRole(selected)}
          classNamePrefix="table-select"
        />
      </td>
      <td width={100} className="text-center">
        <Button variant="primary" size="sm" onClick={handleAdd} disabled={!agency || !role}>
          <i className="fas fa-plus" /> {t("common:actions.add")}
        </Button>
      </td>
    </tr>
  );
}

function UserGlobalForm({ user, successCallback, deleteCallback, formName }) {
  const { t } = useTranslation("common");
  const userRoles = options.userRoles.asList();

  const formikProps = {
    initialValues: {
      ...user,
      is_active: user.is_active || false,
      agencies: user.agencies.map((agency) => ({
        ...agency,
        role: options.userRoles.getOption(agency.role),
      })),
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      personal_number: yup
        .string()
        .nullable()
        .trim()
        .required()
        .test("isNum", t("onlyDigitsAllowed"), (val) => !Number.isNaN(+val))
        .test("len", t("requireDigits", { number: 12 }), (val) => val && val.toString().length === 12),
      email: yup.string().email(t("common:errors.invalid.email")).required(),
    }),
    onSubmit: async (values, { setErrors, resetForm, setFieldValue, ...props }) => {
      const payload = {
        id: user.id,
        is_active: values.is_active,
        email: values.email.toLowerCase(),
        first_name: values.first_name,
        last_name: values.last_name,
        personal_number: values.personal_number,
        can_use_standard_sign_in: values.can_use_standard_sign_in,
        agencies: values.agencies.map((agency) => ({
          agency: agency.agency,
          role: agency.role.value,
        })),
      };
      await userAPI
        .save(payload)
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (!user.id) {
            resetForm();
            setFieldValue("agencies", []);
          }
          if (successCallback) {
            successCallback({
              ...payload,
              ...response.data,
              name: `${payload.first_name} ${payload.last_name}`,
            });
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  const onDelete = async () => {
    return userAPI.deactivate(user.id).then(() => {
      toast.success(t("msg:deleted"));
      if (deleteCallback) {
        deleteCallback();
      }
    });
  };

  const startResetPassword = async () => {
    return authAPI
      .resetPasswordStartByConsult(user.id, user.email)
      .then((response) => {
        toast.success(t("msg:sent"));
      })
      .catch(() => {
        toast.error(t("msg:canNotExecuteAction"));
      });
  };

  function onAddPerm(perm, exitedAgencies, push) {
    const found = exitedAgencies.find((agency) => agency.agency === perm.agency);
    if (!found) {
      push(perm);
    }
  }

  async function onRemoveAgency(agencyUser, index, remove) {
    if (agencyUser.draft) {
      remove(index);
      return;
    }
    const answer = await confirmExecute(
      t("others:confirm.disconnectAgency", {
        agencyName: agencyUser.agency_name,
      })
    );
    if (!answer) {
      return;
    }
    userAPI.agencies
      .disconnect(user.id, agencyUser.agency)
      .then(() => {
        toast.success(t("msg:updated"));
        remove(index);
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__, { autoClose: 10000 });
        }
      });
  }

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors }) => {
        return (
          <Form noValidate>
            <Row>
              <Col lg={9}>
                <Row>
                  <Col lg={5}>
                    <FormGroup.Input
                      label={t("common:contact.personalNumber")}
                      name="personal_number"
                      placeholder="YYYYMMDDXXXX"
                      required
                    />
                  </Col>
                  <Col lg={5}>
                    <FormGroup.Input label={t("common:contact.emailLong")} name="email" required />
                  </Col>
                </Row>
                <Row>
                  <Col lg={5}>
                    <FormGroup.Input label={t("common:contact.firstName")} name="first_name" required />
                  </Col>
                  <Col lg={5}>
                    <FormGroup.Input label={t("common:contact.lastName")} name="last_name" required />
                  </Col>
                </Row>
                <Row>
                  <Col lg={5}>
                    <FormGroup.Checkbox
                      formName={formName}
                      wrapperClass="mb-3"
                      label={t("others:canUseStandardSignIn")}
                      name="can_use_standard_sign_in"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={8} lg={10}>
                    <FieldArray
                      name="agencies"
                      render={({ remove, push }) => (
                        <Table striped={false} bordered className="global-user-perm-table">
                          <thead>
                            <tr>
                              <th>{t("common:agency")}</th>
                              <th>{t("common:role")}</th>
                              <th style={{ width: 120 }} />
                            </tr>
                          </thead>
                          <tbody>
                            {!values.agencies.length && "id" in values ? (
                              <tr>
                                <td colSpan={3}>{t("common:noResultsFound")}</td>
                              </tr>
                            ) : (
                              values.agencies.map((agencyUser, idx) => (
                                <tr key={agencyUser.agency}>
                                  <td className="agency">{agencyUser.agency_name}</td>
                                  <TableGroup.SimpleSelect options={userRoles} name={`agencies[${idx}].role`} />
                                  <td width={100} className="text-center">
                                    <Button
                                      variant="red"
                                      size="sm"
                                      disabled={isSubmitting}
                                      onClick={() => onRemoveAgency(agencyUser, idx, remove)}
                                    >
                                      <i className="fas fa-trash" />
                                    </Button>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                          <tfoot>
                            <AddPermBox
                              userRoles={userRoles}
                              onAddPerm={(perm) => onAddPerm(perm, values.agencies, push)}
                            />
                          </tfoot>
                        </Table>
                      )}
                    />
                  </Col>
                </Row>
              </Col>
              {user.id && (
                <Col lg={3} className="position-relative">
                  <UserConnectedCompanies user={user} />
                </Col>
              )}
            </Row>
            <AllError errors={errors} />
            {!user.is_active && (
              <>
                <Alert variant="warning">{t("others:warnUserInactive")}</Alert>
                <FormGroup.Checkbox name="is_active" label={t("common:statuses.active")} />
              </>
            )}
            <hr />
            <SubmitButton isSubmitting={isSubmitting} />
            {user.id && user.is_active && (
              <>
                <RemoveButton
                  label={t("actions.deactivate")}
                  variant="red"
                  confirmMessage={t("others:confirm.removeUser", {
                    name: user.name,
                  })}
                  disabled={isSubmitting}
                  className="float-right"
                  onClick={onDelete}
                />
                <ConfirmButton
                  variant="secondary"
                  confirmMessage={t("others:confirm.userResetPwd", {
                    email: user.email,
                  })}
                  label={t("others:userSendResetPwd")}
                  className="ml-2"
                  icon="fas fa-key"
                  onClick={startResetPassword}
                />
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default UserGlobalForm;
