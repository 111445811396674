const types = [
  {
    label: "ROT",
    options: [
      { value: "Construction", label: "Construction", type: "ROT" },
      { value: "Electricity", label: "Electricity", type: "ROT" },
      { value: "GlassMetalWork", label: "Glass/metal work", type: "ROT" },
      {
        value: "GroundDrainageWork",
        label: "Ground drainage work",
        type: "ROT",
      },
      { value: "Masonry", label: "Masonry", type: "ROT" },
      {
        value: "PaintingWallpapering",
        label: "Painting/Wallpapering",
        type: "ROT",
      },
      { value: "Hvac", label: "HVAC", type: "ROT" },
    ],
  },
  {
    label: "RUT",
    options: [
      { value: "BabySitting", label: "Baby sitting", type: "RUT" },
      { value: "Moving", label: "Moving", type: "RUT" },
      { value: "ITServices", label: "IT-services", type: "RUT" },
      {
        value: "TextileClothing",
        label: "Clothing and textile care",
        type: "RUT",
      },
      { value: "PersonalCare", label: "Personal care", type: "RUT" },
      { value: "SnowPlowing", label: "Snow plowing", type: "RUT" },
      { value: "Cleaning", label: "Cleaning", type: "RUT" },
      { value: "Gardening", label: "Gardening", type: "RUT" },
      { value: "WhiteGoods", label: "WhiteGoods", type: "RUT" },
      { value: "Furnituring", label: "Furnituring", type: "RUT" },
      { value: "HouseSupervision", label: "HouseSupervision", type: "RUT" },
    ],
  },
];

export function rotRutByValue(value) {
  for (let i = 0; i < types.length; i++) {
    for (let j = 0; j < types[i].options.length; j++) {
      if (types[i].options[j].value === value) {
        return types[i].options[j];
      }
    }
  }
  return null;
}

export default types;
