import React, { useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Form, Formik, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { toast } from "react-toastify";
import {
  findCountryByCode,
  getCountries,
  isAddressRequired,
  isBankCodeRequired,
  isClearingNumberRequired,
  isIBANSupported,
  isStateRequired,
} from "utils/countries";

import * as selectAPI from "api2/selects";
import { SubmitButton } from "components/ui/buttons";
import { AllError, FormGroup } from "components/formik";
import * as supplierAPI from "api2/suppliers";
import * as options from "api/options";
import { useModalOnPage } from "components/modals/hooks/useModalOnPage";
import { debounce } from "lodash";

function BGCButton(props) {
  const [loading, setLoading] = useState(false);
  const {
    values: { id, bankgiro_number },
    setFieldValue,
  } = useFormikContext();
  const { t } = useTranslation("si");

  const getData = async () => {
    setLoading(true);
    const response = await supplierAPI.globals.pullDataFromBGC(bankgiro_number).catch(() => {});
    if (response.status === 200 && response.data) {
      if (!id) {
        setFieldValue("name", response.data.name, false);
      }
      setFieldValue("org_no", response.data.org_no, false);
      setFieldValue("bankgiro_number", response.data.bankgiro_number, false);
    }
    setLoading(false);
  };

  return (
    <Button disabled={!bankgiro_number} type="button" variant="purple" onClick={getData} {...props}>
      {loading ? (
        <span>
          <i className="fas fa-spinner fa-pulse mr-1" /> {t("msg:pulling").toUpperCase()}...
        </span>
      ) : (
        <span>
          <i className="fas fa-search mr-1" /> {t("actions.pullFromBGC")}
        </span>
      )}
    </Button>
  );
}

const getAccounts = debounce((params, callback) => {
  selectAPI.accounts({ ...params, active_only: true }).then((data) => {
    return callback(data);
  });
}, 500);

function GlobalSupplierForm({ supplier, successCallback, deleteCallback }) {
  const { t } = useTranslation("si");
  const supplierPaymentMethods = options.supplierPaymentMethods.asList();
  const countries = useMemo(() => getCountries(t), [t]);
  const isModal = useModalOnPage();
  const formikProps = {
    initialValues: {
      ...supplier,
      contact_email: supplier.contact_email || "",
      payment_method: options.supplierPaymentMethods.getOption(supplier.payment_method) || supplierPaymentMethods[0],
      contra_account: supplier.contra_account && {
        value: supplier.contra_account,
        label: `${supplier.contra_account} - ${supplier.contra_account_name}`,
      },
      country: findCountryByCode(supplier.country),
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      alias_name: yup.string(),
      contra_account: yup.object().required(),
      payment_method: yup.object().required(),
      org_no: yup.string().when("payment_method", ([method], schema) => {
        return method && method.value !== "ForeignAccount" ? schema.required() : schema;
      }),
      bankgiro_number: yup.string().when("payment_method", ([method], schema) => {
        return method && method.value === "BankGiro" ? schema.required() : schema;
      }),
      plusgiro_number: yup.string().when("payment_method", ([method], schema) => {
        return method && method.value === "PlusGiro" ? schema.required() : schema;
      }),
      clearing_number: yup.string().when("payment_method", ([method], schema) => {
        return method && method.value === "BankAccount" ? schema.required() : schema;
      }),
      bank_account_number: yup.string().when("payment_method", ([method], schema, { parent }) => {
        if (method && method.value === "BankAccount") {
          return schema.required();
        }
        if (method && method.value === "ForeignAccount") {
          const ibanSupported = isIBANSupported(parent.country?.value);
          if (!ibanSupported) {
            return schema.required();
          }
        }
        return schema;
      }),
      bic: yup.string().when("payment_method", ([method], schema) => {
        return method && method.value === "ForeignAccount" ? schema.required() : schema;
      }),
      bank_name: yup.string().when("payment_method", ([method], schema) => {
        return method && method.value === "ForeignAccount" ? schema.required() : schema;
      }),
      bank_code: yup.string().when("payment_method", ([method], schema, { parent }) => {
        if (method && method.value === "ForeignAccount") {
          if (isBankCodeRequired(parent.country?.value)) {
            return schema.required();
          }
        }
        return schema;
      }),
      iban: yup.string().when("payment_method", ([method], schema, { parent }) => {
        if (method && method.value === "ForeignAccount") {
          const ibanSupported = isIBANSupported(parent.country?.value);
          if (ibanSupported) {
            return schema.required();
          }
        }
        return schema;
      }),
      contact_email: yup.string().email(),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      const ibanSupported = isIBANSupported(values.country.value);
      let bankAccountNumber = values.bank_account_number;
      if (values.payment_method === "ForeignAccount" && ibanSupported) {
        bankAccountNumber = "";
      }
      const _data = {
        ...values,
        id: supplier.id,
        name: values.name || supplier.name,
        country: values.country.value,
        payment_method: values.payment_method.value,
        contra_account: values.contra_account.value,
        iban: ibanSupported ? values.iban.split(" ").join("") : "",
        bank_account_number: bankAccountNumber,
      };
      await supplierAPI.globals
        .save(_data)
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (!supplier.id) {
            resetForm();
          }
          if (successCallback) {
            successCallback(_data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors }) => {
        const ibanSupported = isIBANSupported(values.country.value);
        const bankCodeRequired = isBankCodeRequired(values.country.value);
        const addressRequired = isAddressRequired(values.country.value);
        const stateRequired = isStateRequired(values.country.value);
        const clearingNoRequired = isClearingNumberRequired(values.country.value);

        return (
          <Form noValidate>
            <Row>
              {!supplier.id && (
                <Col lg={3}>
                  <FormGroup.Input label={t("common:name")} name="name" required />
                </Col>
              )}
              <Col lg={!supplier.id ? 3 : 4}>
                <FormGroup.Input label={t("common:aliasName")} name="alias_name" />
              </Col>
              <Col lg={!supplier.id ? 3 : 4}>
                <FormGroup.Input
                  label={t("common:orgNo")}
                  name="org_no"
                  required={values.payment_method.value !== "ForeignAccount"}
                />
              </Col>
              <Col lg={!supplier.id ? 3 : 4}>
                <FormGroup.SimpleSelect
                  name="country"
                  label={t("common:contact.country")}
                  options={countries}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <FormGroup.AsyncSelect
                  name="contra_account"
                  label={t("common:costAccount")}
                  loadOptions={getAccounts}
                  required
                  minSearchLength={0}
                />
              </Col>
              <Col lg={4}>
                <FormGroup.Input label={t("common:money.vatNo")} name="vat_number" />
              </Col>
              <Col lg={4}>
                <FormGroup.Input label={t("common:contact.emailLong")} name="contact_email" />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <FormGroup.SimpleSelect
                  name="payment_method"
                  menuPlacement={isModal ? "bottom" : "auto"}
                  menuPosition={isModal ? "absolute" : "fixed"}
                  label={t("common:paymentMethod")}
                  options={supplierPaymentMethods}
                />
              </Col>
              {values.payment_method.value === "BankGiro" && (
                <>
                  <Col sm={6} xl={4}>
                    <FormGroup.Input label={`BankGiro ${t("common:no")}`} name="bankgiro_number" required />
                  </Col>
                  <Col sm={6} xl={4}>
                    <BGCButton className="mt-3" />
                  </Col>
                </>
              )}
              {values.payment_method.value === "PlusGiro" && (
                <Col sm={6} xl={3}>
                  <FormGroup.Input label={`PlusGiro ${t("common:no")}`} name="plusgiro_number" required />
                </Col>
              )}
              {values.payment_method.value === "BankAccount" && (
                <>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("common:clearingNo")} name="clearing_number" type="number" required />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("common:bankAccountNo")} name="bank_account_number" required />
                  </Col>
                </>
              )}
              {values.payment_method.value === "ForeignAccount" && (
                <>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label="BIC" name="bic" required />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("company:bankName")} name="bank_name" required />
                  </Col>
                  {bankCodeRequired && (
                    <Col sm={6} xl={3}>
                      <FormGroup.Input label={t("company:bankCode")} name="bank_code" required />
                    </Col>
                  )}
                  {!ibanSupported ? (
                    <Col sm={6} xl={3}>
                      <FormGroup.Input label="BBAN" name="bank_account_number" required />
                    </Col>
                  ) : (
                    <Col sm={6} xl={3}>
                      <FormGroup.Input label="IBAN" name="iban" required />
                    </Col>
                  )}
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("common:contact.street")} name="street" required={addressRequired} />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("common:contact.city")} name="city" required={addressRequired} />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("common:contact.zipCode")} name="zip_code" required={addressRequired} />
                  </Col>
                  {stateRequired && (
                    <Col sm={6} xl={3}>
                      <FormGroup.Input label={t("common:contact.state")} name="state" required />
                    </Col>
                  )}
                  {clearingNoRequired && (
                    <Col sm={6} xl={3}>
                      <FormGroup.Input label={t("common:clearingNo")} name="clearing_number" type="number" required />
                    </Col>
                  )}
                </>
              )}
            </Row>
            <AllError errors={errors} />
            <hr />
            <SubmitButton isSubmitting={isSubmitting} />
          </Form>
        );
      }}
    </Formik>
  );
}

export default GlobalSupplierForm;
