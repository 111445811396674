import React, { useContext, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button, Card } from "react-bootstrap";
import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import useAsync from "hooks/useAsync";
import * as companyAPI from "api2/companies";
import {
  OnboardingDispatchContext,
  OnboardingStateContext,
} from "pages/companies/OnboardingWizard/provider/OnboardingProvider";
import { handleActionErrors } from "../../../../../api/errors";
import FortnoxIncorrectCostCentersTable from "./FortnoxIncorrectCostCentersTable";
import FortnoxIncorrectProjectsTable from "./FortnoxIncorrectProjectsTable";
import FortnoxIncorrectCustomersTable from "./FortnoxIncorrectCustomersTable";
import FortnoxIncorrectProductsTable from "./FortnoxIncorrectProductsTable";
import { RocketLoader } from "../../../../../components/ui/loaders";

function FortnoxDataStep({ companyId, agencyId }) {
  const { t } = useTranslation("company");
  const { fetchDetails } = useContext(OnboardingDispatchContext);
  const { activeStep } = useContext(OnboardingStateContext);

  const dataSource = useCallback(
    (cancelToken) => companyAPI.onboardings.steps.fortnoxData(companyId, agencyId, { cancelToken }),
    [companyId, agencyId]
  );
  const [{ data, loading }, { reload }] = useAsync(dataSource, []);

  useEffect(() => {
    if (!loading) {
      fetchDetails();
    }
  }, [loading, fetchDetails]);

  if (loading) {
    return <RocketLoader />;
  }

  const onFinish = () => {
    return companyAPI.onboardings.steps
      .fortnoxDataFinish(companyId, agencyId, null)
      .then(() => {
        fetchDetails(true);
      })
      .catch((error) => {
        handleActionErrors(error);
      });
  };

  const totalIncorrectItems =
    data.cost_centers_incorrect_count +
      data.customers_incorrect_count +
      data.products_incorrect_count +
      data.projects_incorrect_count || 0;

  return (
    <Card className="fortnox-validate-data-step" data-testid="fortnox-validate-data-step">
      <Card.Body className="pb-5">
        <StepHeader
          rightComponent={
            <Button onClick={onFinish} size="lg">
              {t("common:actions.goNext")}
            </Button>
          }
        />
      </Card.Body>

      {activeStep?.status === "finished" ? (
        <Card.Body>
          <Alert variant="success">{t("company:onboarding.steps.fortnox_noIncorrectData")}</Alert>
        </Card.Body>
      ) : (
        <>
          {totalIncorrectItems === 0 && (
            <Card.Body>
              <Alert variant="success">{t("company:onboarding.steps.fortnox_noIncorrectData")}</Alert>
            </Card.Body>
          )}
          <FortnoxIncorrectCostCentersTable companyId={companyId} agencyId={agencyId} reloadStep={reload} />
          <FortnoxIncorrectProjectsTable companyId={companyId} agencyId={agencyId} reloadStep={reload} />
          <FortnoxIncorrectCustomersTable companyId={companyId} agencyId={agencyId} reloadStep={reload} />
          <FortnoxIncorrectProductsTable companyId={companyId} agencyId={agencyId} reloadStep={reload} />
        </>
      )}
    </Card>
  );
}

export default FortnoxDataStep;
