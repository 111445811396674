import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

import { BExpandedTable } from "components/tables/btable";
import AgencyConsultPermForm from "components/forms/AgencyConsultPermForm";
import Filters from "./AgencyConsultFilters";

function AgencyConsultExtendedTable({ agencyId }) {
  const { t } = useTranslation("common");
  const [insideCompaniesFilters, setInsideCompaniesFilters] = useState({});
  const headers = useMemo(
    () => [
      {
        field: "name",
        label: t("common:name"),
        sortField: "last_name",
      },
      {
        field: "email",
        label: t("contact.emailLong"),
      },
    ],
    [t]
  );

  return (
    <Row>
      <Col>
        <BExpandedTable
          headers={headers}
          FiltersComponent={
            <Filters
              agencyId={agencyId}
              insideCompaniesFilters={insideCompaniesFilters}
              setInsideCompaniesFilters={setInsideCompaniesFilters}
            />
          }
          renderExpandedRow={(row) => (
            <AgencyConsultPermForm agencyId={agencyId} userId={row.id} companiesFilters={insideCompaniesFilters} />
          )}
        />
      </Col>
    </Row>
  );
}

export default AgencyConsultExtendedTable;
