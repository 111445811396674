import React from "react";
import { TooltipActionButton } from "components/ui/buttons";
import { useTranslation } from "react-i18next";
import { psd2 } from "api2/integrations";
import { confirmExecute } from "components/modals/ConfirmModal";
import useTable from "components/tables/btable/useTable";
import { toast } from "react-toastify";

import { useMenuNumbersDispatch } from "../../../hooks/useMenuNumbers";
import { formatDatetime } from "../../../utils/date";

function RestoredTooltip({ row }) {
  const { t } = useTranslation();
  if (row.is_restored) {
    return (
      <div className="restore-tooltip">
        <div>
          <strong>
            {t("common:restoredBy")} {row.restored_by_name}
          </strong>
        </div>
        <div className="date">{formatDatetime(row.restored_at)}</div>
      </div>
    );
  }
  return t("common:actions.restore");
}

function IsoBasketActions({ row, companyId }) {
  const { t } = useTranslation("common");
  const {
    dataActions: { reload },
  } = useTable();
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const onDelete = async () => {
    const answer = await confirmExecute(
      t("others:confirm.removeIsoBasket", { fileName: row.bank_file_name }),
      t("others:yesRestore")
    );
    if (answer) {
      psd2.iso.basket
        .remove(companyId, row.id)
        .then(() => {
          reload();
          reloadMenuNumbers();
        })
        .catch(() => {
          toast.error(t("msg:canNotExecuteAction"));
        });
    }
  };
  if (row.status === "finalised") {
    return null;
  }
  return (
    <TooltipActionButton
      variant="action"
      text={<RestoredTooltip row={row} />}
      onClick={onDelete}
      disabled={row.is_restored}
      icon="fe-corner-down-left"
    />
  );
}

export default IsoBasketActions;
