import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CostCenterForm from "components/forms/company-settings-forms/CostCenterForm";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";

function CCAddModal({ companyId, handleClose, onSuccess, initialSerialOrName = "" }) {
  const { t } = useTranslation("company");
  const isSerial = Number.isFinite(+initialSerialOrName);
  const cc = {
    name: !isSerial ? initialSerialOrName : "",
    serial: isSerial ? initialSerialOrName : "",
  };
  const successCallback = (newCC) => {
    if (onSuccess) {
      onSuccess(newCC);
    }
    handleClose();
  };

  return (
    <Modal show onHide={handleClose} size="xl" dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("actions.addCostCenter")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CostCenterForm companyId={companyId} costCenter={cc} successCallback={successCallback} />
      </Modal.Body>
    </Modal>
  );
}

export default CCAddModal;
