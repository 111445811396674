import React from "react";

import * as supportApi from "api2/office-support";
import { withInitialAsync } from "hooks/useAsync";
import OfficeSupportClientDetailsForm from "./OfficeSupportClientDetailsForm";

function OfficeSupportClientDetailsWidget({ data: item, companyId, changeEditedForm }) {
  return <OfficeSupportClientDetailsForm companyId={companyId} changeEditedForm={changeEditedForm} data={item} />;
}

const EnhancedOfficeSupportClientDetailsWidget = withInitialAsync(
  OfficeSupportClientDetailsWidget,
  ({ companyId }) =>
    React.useCallback((cancelToken) => supportApi.clientDetails.details(companyId, { cancelToken }), [companyId]),
  [],
  false,
  true
);

export default EnhancedOfficeSupportClientDetailsWidget;
