import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useTable from "components/tables/btable/useTable";
import UserForm from "./UserForm";
import UserGlobalForm from "./UserGlobalForm";

function UserAddWidget({ agencyId }) {
  const {
    dataActions: { reload },
  } = useTable();
  const user = {
    first_name: "",
    last_name: "",
    email: "",
    personal_number: "",
    agency_role: "regular",
    is_active: true,
    agencies: [],
  };

  const FormComponent = agencyId ? UserForm : UserGlobalForm;

  const { t } = useTranslation("common");
  return (
    <Row>
      <Col>
        <Card className="card-border">
          <Card.Header className="border-success">
            <Card.Title>{t("actions.addUser")}</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <FormComponent formName="add-form" user={user} agencyId={agencyId} successCallback={reload} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default UserAddWidget;
