import { NavLink, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { routes } from "routes/routes";
import "./Breadcrumbs.scss";
import { useTranslation } from "react-i18next";
import { AppendDocTitle } from "components/ui/titles/PageTitle";
import cx from "classnames";
import { GetLayout } from "utils/layout";

function Breadcrumb({ link, breadcrumb, section, isLast }) {
  const { t } = useTranslation("bread");
  const title = t(breadcrumb.props.children);
  const breadcrumbTitle = t(breadcrumb.props.children) || breadcrumb;
  // workaround for missing breadcrumb after section (eg. main settings page - missing "General")
  if (section && isLast) {
    return (
      <>
        {title && <AppendDocTitle appendText={title} />}
        <span className={cx("breadcrumbs__item breadcrumbs__item--section", GetLayout())}>{breadcrumbTitle}</span>
      </>
    );
  }
  if (section) {
    return <span className="breadcrumbs__item breadcrumbs__item--section">{breadcrumbTitle}</span>;
  }
  if (isLast) {
    return (
      <>
        {title && <AppendDocTitle appendText={title} />}
        <span className="breadcrumbs__item breadcrumbs__item--disabled">{breadcrumbTitle}</span>
      </>
    );
  }
  return (
    <NavLink key={link} to={link} className="breadcrumbs__item">
      <span>{breadcrumbTitle}</span>
    </NavLink>
  );
}

function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs(routes, { excludePaths: ["/"] }).slice(-2);
  const navigate = useNavigate();
  const location = useLocation();
  const { length } = breadcrumbs;
  const { t } = useTranslation("navigation");
  const goBack = () => {
    if (location.pathname.includes("/admin/")) {
      navigate("/companies");
    } else if (location.pathname.includes("/agencies/")) {
      navigate("/agencies");
    } else {
      navigate(-1);
    }
  };

  const showBackBtn = location.pathname !== "/" && location.pathname !== "/companies";
  return (
    <li className={cx("breadcrumbs", GetLayout())}>
      {showBackBtn && (
        <button type="button" className="breadcrumbs__item breadcrumbs__item--back" onClick={goBack}>
          <i className="fe-chevron-left mr-1" />
          <span className="breadcrumbs__item--back-text">&nbsp;{t("goBack")}</span>
        </button>
      )}
      {breadcrumbs.map(({ match, breadcrumb, section }, index) => {
        return (
          <React.Fragment key={match.pathname}>
            <Breadcrumb link={match.pathname} breadcrumb={breadcrumb} section={section} isLast={index === length - 1} />
            {index < length - 1 && (
              <span className="breadcrumbs__divider">
                <i className="fe-chevron-right" />
              </span>
            )}
          </React.Fragment>
        );
      })}
    </li>
  );
}

export default Breadcrumbs;
