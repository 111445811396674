import React from "react";
import { Alert, ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { AllError, FormGroup } from "components/formik";
import { formatMoney } from "utils/money";
import * as selectAPI from "api2/selects";
import * as reportAPI from "api2/reports";
import * as dpAPI from "api2/direct-payments";
import { formatDate } from "utils/date";
import { SubmitButton } from "components/ui/buttons";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { debounce } from "lodash";

function DirectPaymentForm({ companyId, directPayment, onSave }) {
  const { t } = useTranslation("common");
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();

  const getEmployees = debounce((params, callback) => {
    selectAPI.companyEmployees(companyId, { ...params, with_account: true }, "user_id").then((data) => {
      return callback(data.map((item) => ({ ...item, label: `${item.user_name} - ${item.account}` })));
    });
  }, 500);

  const formikProps = {
    initialValues: {
      ...directPayment,
      balanceBefore: 0,
      account: null,
    },
    validationSchema: yup.object().shape({
      booking_date: yup.date().nullable().required(),
      amount: yup.number().positive().required(),
      description: yup.string().required(),
      receiver: yup.object().nullable().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      await dpAPI
        .save(companyId, {
          account: values.receiver.account || 2820,
          receiver: values.receiver.value,
          booking_date: formatDate(values.booking_date),
          payment_date: formatDate(values.booking_date),
          amount: values.amount,
          description: values.description,
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          reloadMenuNumbers();
          if (onSave) {
            onSave(response.data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  async function onEmployeeChange(employee, setFieldValue) {
    const balanceBefore = await reportAPI
      .accountGlobalBalance(companyId, { account: employee.account })
      .then((response) => {
        if (response.data.length) {
          return response.data[0].balance || 0;
        }
        return 0;
      });
    setFieldValue("balanceBefore", balanceBefore);
  }

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, setFieldValue, handleSubmit, errors }) => {
        const balanceAfter = values.balanceBefore + (values.amount || 0);
        return (
          <Form id="dpForm">
            <Card>
              <Card.Body>
                <Row>
                  <Col xl={3} lg={5}>
                    <FormGroup.BookingDatePicker
                      name="booking_date"
                      label={t("common:dates.paymentDate")}
                      minDate={new Date()}
                    />
                  </Col>
                </Row>
                <Alert variant="info">
                  {t("common:money.balanceBefore")}: <strong>{formatMoney(values.balanceBefore)} SEK</strong>
                </Alert>
                <Row>
                  <Col xl={3} lg={6}>
                    <FormGroup.AsyncSelect
                      name="receiver"
                      label={t("common:employee")}
                      loadOptions={getEmployees}
                      onChange={(selected) => onEmployeeChange(selected, setFieldValue)}
                      minSearchLength={0}
                      required
                    />
                  </Col>
                  <Col xl={3} lg={4}>
                    <FormGroup.MoneyInput name="amount" label={t("common:money.toBePaid")} required />
                  </Col>
                </Row>
                <Alert variant="info">
                  {t("common:money.balanceAfter")}: <strong>{formatMoney(balanceAfter)} SEK</strong>
                </Alert>
                <Row>
                  <Col xl={6} lg={12}>
                    <FormGroup.Input
                      as="textarea"
                      name="description"
                      label={t("others:textForOutpayment")}
                      rows={2}
                      required
                    />
                  </Col>
                </Row>
                <AllError errors={errors} />
              </Card.Body>
              <Card.Footer>
                <ButtonGroup>
                  <SubmitButton isSubmitting={isSubmitting} />
                </ButtonGroup>
              </Card.Footer>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default DirectPaymentForm;
