import React from "react";
import "./StepProgressBar.scss";

function StepProgressBar({ current, max }) {
  const percent = (current / max) * 100;
  return (
    <div className="step-progress-bar">
      <div className="progress">
        <div
          className="progress-bar"
          style={{ width: `${percent}%` }}
          role="progressbar"
          aria-valuenow={percent}
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
      <div className="progress-title">
        {current}/{max}
      </div>
    </div>
  );
}

export default StepProgressBar;
