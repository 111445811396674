import React, { useEffect, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { Document } from "components/ui/documents";
import { useTranslation } from "react-i18next";
import client from "api2/client";
import axios from "axios";
import { Loader } from "components/ui/loaders";
import { saveBlobToComputer } from "utils/file";
import { DraggableModalDialog } from "../DraggableModalDialog";

function FileUrlModal({
  handleClose,
  fileUrl,
  downloadFileName = false,
  autoDownload = false,
  companyId,
  children,
  headerChildren,
  extraHeaders,
}) {
  const { t } = useTranslation("common");
  const [data, setData] = useState({
    url: "",
    type: "",
    info: null,
    error: null,
    size: 0,
    loading: true,
  });
  useEffect(() => {
    const signal = axios.CancelToken.source();
    client
      .get(fileUrl, {
        responseType: "blob",
        cancelToken: signal.token,
        params: { company_id: companyId },
        headers: {
          "x-company": companyId,
          ...extraHeaders,
        },
      })
      .then((response) => {
        const blob = response.data;
        if (autoDownload || downloadFileName) {
          saveBlobToComputer(response, downloadFileName, false);
          setData({
            url: "",
            type: "",
            size: 0,
            loading: false,
            info: t("common:file.fileSavedPreviewNotPossible"),
            error: null,
          });
        } else {
          setData({
            url: window.URL.createObjectURL(blob),
            type: blob.type,
            size: blob.size,
            loading: false,
            info: null,
            error: null,
          });
        }
      })
      .catch(async (error) => {
        let errorMsg = t("common:file.cannotLoadFile");
        if (error.data && error.data.__all__) {
          errorMsg = error.data.__all__;
        }
        if (!axios.isCancel(error)) {
          setData({
            url: "",
            type: "",
            size: 0,
            loading: false,
            info: null,
            error: errorMsg,
          });
        }
      });
    return () => {
      signal.cancel("aborted");
    };
  }, [fileUrl, autoDownload, downloadFileName, companyId, t, extraHeaders]);

  return (
    <Modal show onHide={handleClose} size="xl" scrollable contentClassName="vh-100" dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("documents")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {headerChildren}
        {data.loading && <Loader />}
        {data.url && <Document url={data.url} type={data.type} fullMode />}
        {!data.loading && data.error && <Alert variant="danger">{data.error}</Alert>}
        {!data.loading && data.info && <Alert variant="info">{data.info}</Alert>}
      </Modal.Body>
      {children}
    </Modal>
  );
}

export default FileUrlModal;
