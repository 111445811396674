import React, { useCallback } from "react";
import { toast } from "react-toastify";

import { VerificationDispatchContext, VerificationStateContext } from "state/providers/VerificationProvider";
import { OPEN_MODAL, TOGGLE_MODAL } from "state/types";
import * as verificationAPI from "api2/verification";
import { useCompanyState } from "./useCompany";

const emptyTransaction = {
  credit: null,
  debit: null,
  amount: 0,
  account: null,
  title: "",
  is_deleted: false,
};

function useVerificationState() {
  const context = React.useContext(VerificationStateContext);
  if (context === undefined) {
    throw new Error("useVerificationState must be used within a VerificationProvider");
  }
  return context;
}

function useVerificationDispatch() {
  const dispatch = React.useContext(VerificationDispatchContext);
  if (dispatch === undefined) {
    throw new Error("useVerificationDispatch must be used within a VerificationProvider");
  }
  const {
    accounts: { byId: accountById },
    costCenters: { byId: centerById },
    projects: { byId: projectById },
  } = useCompanyState();

  const open = async (companyId, verification) => {
    if (verification && verification.id) {
      await verificationAPI
        .details(companyId, verification.id)
        .then((verification2) => {
          dispatch({
            type: OPEN_MODAL,
            payload: {
              ...verification2,
              transactions: [
                ...verification2.transactions.map((item) => ({
                  ...item,
                  account: accountById[item.account],
                  project: item.project ? projectById[item.project] : null,
                  cost_center: item.cost_center ? centerById[item.cost_center] : null,
                  debit: item.amount >= 0 ? item.amount : null,
                  credit: item.amount < 0 ? Math.abs(item.amount) : null,
                  creditDebit: false,
                  periodisation_config: item.periodisation_config
                    ? {
                        ...item.periodisation_config,
                        pl_account: accountById[item.periodisation_config.pl_account],
                      }
                    : null,
                })),
                emptyTransaction,
              ],
            },
          });
        })
        .catch(() => {
          toast.error("You are not allowed to display it.", {
            autoClose: 3000,
          });
        });
    } else {
      dispatch({
        type: TOGGLE_MODAL,
        payload: {
          id: null,
          title: verification ? verification.title : "",
          transactions:
            verification && verification.transactions
              ? verification.transactions.map((trans) => {
                  if (trans.account && !trans.account.value) {
                    trans.account = accountById[trans.account];
                  }
                  return trans;
                })
              : [
                  {
                    credit: null,
                    debit: null,
                    amount: 0,
                    account: null,
                    title: "",
                    is_deleted: false,
                  },
                ],
          booking_date: verification && verification.booking_date ? verification.booking_date : new Date(),
          newDocuments: verification && verification.newDocuments ? verification.newDocuments : undefined,
          documents: [],
          archive: verification && verification.archive,
          series: "M",
          create_another: verification && !!verification.create_another,
        },
      });
    }
  };

  const close = useCallback(() => {
    dispatch({ type: TOGGLE_MODAL, payload: null });
    document.body.dispatchEvent(new Event("verification/closed"));
  }, [dispatch]);

  const remove = useCallback(
    ({ companyId, verification }) => {
      verificationAPI
        .remove(companyId, verification.id)
        .then(() => {
          close();
        })
        .catch(() => {
          // pass
        });
    },
    [close]
  );

  const duplicate = async (companyId, verification) => {
    const series = "M";
    return verificationAPI
      .getPreliminaryNumber(companyId, series, verification.financial_year)
      .then((response) => {
        dispatch({
          type: TOGGLE_MODAL,
          payload: {
            ...verification,
            status: 0,
            preliminary: true,
            documents: [],
            related: [],
            has_assets: false,
            is_last_from_series: false,
            id: undefined,
            title: `${verification.title}`,
            series,
            series_number: response.data.series_number,
            booking_date: verification.booking_date,
            transactions: verification.transactions
              .filter((item) => !item.is_deleted)
              .map((item) => ({
                ...item,
                id: undefined,
                periodisation_config: null,
              })),
          },
        });
      })
      .catch((e) => {
        // toast.error('You are not allowed to duplicate verification.', {autoClose: 3000});
      });
  };

  return {
    open,
    close,
    remove,
    duplicate,
  };
}

export { useVerificationState, useVerificationDispatch };
