import React from "react";

import logoSrc from "assets/images/bflow-beige.png";
import { GetMainPageUrl } from "utils/layout";

function LogoBox() {
  return (
    <div className="logo-box">
      <a href={GetMainPageUrl()} className="logo">
        <span className="logo-lg">
          <img src={logoSrc} alt="" height="20" />
        </span>
        <span className="logo-sm">
          <img src={logoSrc} alt="" height="20" />
        </span>
      </a>
    </div>
  );
}

export default LogoBox;
