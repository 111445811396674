import React, { useState } from "react";
import { NavLink, useResolvedPath } from "react-router-dom";

import LeftSidebar from "components/ui/sidebar";
import { useTranslation } from "react-i18next";
import { useCompanyState } from "hooks/useCompany";
import { HasPermCode, PermCodeRequired } from "components/perms";
import { codesFor } from "components/perms/PermCodes";
import MenuSwitcher from "./MenuSwitcher";
import LayoutSwitcherMenuItem from "../LayoutSwitcherMenuItem";

function DashboardTTOnlyMenu() {
  const { t } = useTranslation("navigation");
  const { company } = useCompanyState();
  const currPath = useResolvedPath("").pathname;
  const hasConsultManage = HasPermCode(codesFor.consultManage.enabled);
  const [menuRegular, setMenuRegular] = useState(
    !hasConsultManage || (!currPath.includes("consult") && currPath !== "/")
  );
  const hasSalaryArchiveAccess =
    HasPermCode(codesFor.salaries.archive_all) || HasPermCode(codesFor.salaries.archive_own);

  return (
    <LeftSidebar extraDeps={menuRegular}>
      <LayoutSwitcherMenuItem />
      <li>
        <PermCodeRequired code={codesFor.companySettings.enabled}>
          <NavLink
            end
            to={{
              pathname: `/companies/${company.id}/settings`,
              state: { prevPath: currPath, companyId: company.id },
            }}
          >
            <i className="fe-settings" />
            <span> {t("settings")}</span>
          </NavLink>
        </PermCodeRequired>
      </li>
      {menuRegular && (
        <li>
          <PermCodeRequired code={codesFor.companyArchive.enabled}>
            <NavLink to="/archive/company-archive">
              <i className="fe-archive" />
              <span> {t("companyArchive")}</span>
            </NavLink>
          </PermCodeRequired>
          {hasSalaryArchiveAccess && (
            <NavLink to="/archive/salary-archive">
              <i className="fe-dollar-sign" />
              <span> {t("salaryArchive")}</span>
            </NavLink>
          )}
        </li>
      )}
      {/* Consult Menu New */}
      {!menuRegular && (
        <>
          <li className="menu-title">{t("consultMenu")}</li>
          <li>
            <PermCodeRequired code={codesFor.companyArchive.enabled}>
              <NavLink to="/archive/company-archive">
                <i className="fe-archive" />
                <span> {t("companyArchive")}</span>
              </NavLink>
            </PermCodeRequired>
            {hasSalaryArchiveAccess && (
              <NavLink to="/archive/salary-archive">
                <i className="fe-dollar-sign" />
                <span> {t("salaryArchive")}</span>
              </NavLink>
            )}
          </li>
        </>
      )}
      <MenuSwitcher menuRegular={menuRegular} onChange={setMenuRegular} />
    </LeftSidebar>
  );
}

export default DashboardTTOnlyMenu;
