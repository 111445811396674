import { debounce } from "lodash";
import client from "./client";

// to be refactored within CI API
export const getCompanyCustomers = debounce((companyId, params, callback) => {
  client
    .get(`/companies/${companyId}/billecta-customers/`, { params })
    .then((response) =>
      callback(
        response.data.map((item) => ({
          value: item.DebtorPublicId,
          label: `${item.Name}`,
          ...item,
        }))
      )
    )
    .catch(() => callback([]));
}, 500);

// to be refactored within CI API
export const getCompanyProducts = debounce((companyId, params, callback) => {
  client
    .get(`/companies/${companyId}/billecta-products/`, { params })
    .then((response) =>
      callback(
        response.data.map((item) => ({
          value: item.ProductPublicId,
          label: `${item.ArticleNumber ? `${item.ArticleNumber} - ` : ""} ${item.Description}`.trim(),
          ...item,
        }))
      )
    )
    .catch(() => callback([]));
}, 500);

// to be refactored within CI API
export const getCompanyContractTemplates = debounce((params, callback) => {
  client.get("/select/contract-templates/", { params }).then((response) =>
    callback(
      response.data.map((item) => ({
        value: item.id,
        label: item.name,
        ...item,
      }))
    )
  );
}, 500);
