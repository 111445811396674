import React from "react";
import { useOutletContext } from "react-router-dom";
import { Card } from "react-bootstrap";
import { psd2 } from "api2/integrations";
import TableProvider from "components/tables/btable/provider";
import { IsoBasketTable } from "components/tables";

function IsoBasketListPage() {
  const company = useOutletContext();
  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId="isoPayments"
          dataSource={(filters, config) => psd2.iso.basket.list(company.id, filters, config)}
          initialFilters={null}
        >
          <IsoBasketTable companyId={company.id} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default IsoBasketListPage;
