import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";

import TableProvider from "components/tables/btable/provider";
import { EmployeeExtendedTable } from "components/tables";
import * as companyAPI from "api2/companies";
import { EmployeeAddWidget } from "components/forms/company-settings-forms/EmployeeForm";
import { codesForEmployees } from "components/perms/PermCodes";
import { CodeRequiredInList } from "components/perms";
import { CompanySettingStateContext } from "state/providers/CompanySettingProvider";

function EmployeeListPage({ tableId = "employees" }) {
  const { companyId } = useParams();
  const initialFilters = {};
  const { userPermCodes } = useContext(CompanySettingStateContext);
  return (
    <TableProvider
      tableId={tableId}
      dataSource={(filters, config) => companyAPI.employees.list(companyId, filters, config)}
      initialFilters={initialFilters}
    >
      <Card>
        <Card.Body>
          <EmployeeExtendedTable companyId={companyId} />
        </Card.Body>
      </Card>
      <CodeRequiredInList code={codesForEmployees.create} codeList={userPermCodes}>
        <EmployeeAddWidget companyId={companyId} />
      </CodeRequiredInList>
    </TableProvider>
  );
}

export default EmployeeListPage;
