const assetAccountsList = [
  1010, 1011, 1050, 1060, 1070, 1100, 1101, 1102, 1103, 1104, 1105, 1106, 1107, 1108, 1120, 1130, 1131, 1132, 1150,
  1210, 1220, 1240, 1290,
];

const depreciationAccountsList = [
  1019, 1059, 1069, 1079, 1112, 1113, 1114, 1115, 1116, 1117, 1118, 1119, 1129, 1159, 1219, 1229, 1249, 1299,
];

const disallowedAssetAccountsForDepreciationList = [1130, 1131, 1132];

function filterDepreciationAccountOptions(option) {
  return depreciationAccountsList.includes(option.number);
}

function filterAssetCostAccountOptions(option) {
  return [7810, 7820, 7824, 7830, 7834, 7840].includes(option.number);
}

function filterAssetAccountOptions(option) {
  return assetAccountsList.includes(option.number);
}

function isAssetToAutoAddBasedOnTransactions(transactions) {
  return transactions.some((transaction) => {
    const account = transaction.account?.number || transaction.account; // needed for handle SI manual and Ver transactions
    return assetAccountsList.includes(account) && (transaction.amount > 0 || transaction.debit > 0);
  });
}

function isDepreciationTransactionIn(transactions) {
  return transactions.some((transaction) => {
    const account = transaction.account?.number || transaction.account; // needed for handle SI manual and Ver transactions
    return depreciationAccountsList.includes(account);
  });
}

function getDefaultAccountsBasedOnAssetAccount(assetAccountNumber) {
  return {
    1010: { deprecation: 1019, cost: 7810 },
    1011: { deprecation: 1019, cost: 7810 },
    1050: { deprecation: 1059, cost: 7810 },
    1060: { deprecation: 1069, cost: 7810 },
    1070: { deprecation: 1079, cost: 7810 },
    1100: { deprecation: 1119, cost: 7820 },
    1101: { deprecation: 1119, cost: 7820 },
    1102: { deprecation: 1112, cost: 7820 },
    1103: { deprecation: 1113, cost: 7820 },
    1104: { deprecation: 1114, cost: 7820 },
    1105: { deprecation: 1115, cost: 7820 },
    1106: { deprecation: 1116, cost: 7820 },
    1107: { deprecation: 1117, cost: 7820 },
    1108: { deprecation: 1118, cost: 7820 },
    1120: { deprecation: 1129, cost: 7840 },
    1130: { deprecation: null, cost: null },
    1131: { deprecation: null, cost: null },
    1132: { deprecation: null, cost: null },
    1150: { deprecation: 1159, cost: 7824 },
    1210: { deprecation: 1219, cost: 7830 },
    1220: { deprecation: 1229, cost: 7830 },
    1240: { deprecation: 1249, cost: 7834 },
    1290: { deprecation: 1299, cost: 7830 },
  }[assetAccountNumber];
}

function isAssetToAutoAddForSupplierInvoice(data) {
  if (
    !data.manual_booking_enabled &&
    assetAccountsList.includes(data.account?.number || data.account) &&
    data.amount > 0
  ) {
    return true;
  }
  if (data.manual_booking_enabled) {
    return isAssetToAutoAddBasedOnTransactions(data.manual_booking);
  }
  return false;
}

export {
  assetAccountsList,
  depreciationAccountsList,
  filterAssetAccountOptions,
  filterDepreciationAccountOptions,
  filterAssetCostAccountOptions,
  isAssetToAutoAddForSupplierInvoice,
  isAssetToAutoAddBasedOnTransactions,
  getDefaultAccountsBasedOnAssetAccount,
  disallowedAssetAccountsForDepreciationList,
  isDepreciationTransactionIn,
};
