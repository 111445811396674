import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import BTable from "components/tables/btable";
import { useCompanyState } from "hooks/useCompany";
import Filters from "./TaxAuthorityFilters";
import Actions from "./TaxAuthorityActions";

function TaxAuthorityTable({ companyId, forStatus, selectable = false }) {
  const { t } = useTranslation("tax");
  const {
    company: { ocr_tax },
  } = useCompanyState();
  const headers = useMemo(
    () => [
      {
        field: "ocr",
        label: "OCR",
        render: () => ocr_tax,
        canSort: false,
      },
      {
        field: "supplier",
        label: t("common:supplier"),
        render: () => "Skatteverket",
        canSort: false,
      },
      {
        field: "due_date",
        label: t("common:dates.dueDate"),
      },
    ],
    [ocr_tax, t]
  );

  const getRowProps = (row) => {
    return {
      className: cx({
        "has-message": !!row.comments.length,
      }),
    };
  };

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      FiltersComponent={<Filters companyId={companyId} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      getRowProps={getRowProps}
    />
  );
}

export default TaxAuthorityTable;
