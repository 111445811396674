import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "./ConnectBoxes.scss";
import { useTranslation } from "react-i18next";
import useModal from "hooks/useModal";
import { psd2 as psd2Api, skatteverket as skattApi } from "api2/integrations";
import cx from "classnames";
import BankConnectModal from "pages/companies/BankConnections/BankConnectModal";
import SkatteverketConnectModal from "pages/companies/SkatteverketConnectModal";
import ConnectKYCModal from "components/modals/ConnectKYCModal";
import { useCompanyState } from "../../../hooks/useCompany";

function ConnectBankBox({ companyId }) {
  const { t } = useTranslation("common");
  const [bankInfo, setInfo] = useState({
    connected: false,
    consent_id: null,
    balance: null,
    expire_in_days: 0,
    expireClass: "",
    iconClass: "",
  });
  const connectModal = useModal();

  const getBankStatus = useCallback(() => {
    psd2Api.connectionStatus(companyId).then((response) => {
      let expireClass = "";
      let iconClass = "";
      if (response.data.connected) {
        expireClass = "connection-ok";
        iconClass = "fe-check-circle";
        if (response.data.expire_in_days === 0) {
          expireClass = "connection-danger";
          iconClass = "fe-alert-triangle";
        } else if (response.data.expire_in_days <= 30) {
          expireClass = "connection-warning";
          iconClass = "fe-alert-triangle";
        }
      }
      setInfo({ ...response.data, expireClass, iconClass });
    });
  }, [companyId]);

  useEffect(() => {
    getBankStatus();
  }, [getBankStatus]);

  const handleModalClose = () => {
    getBankStatus();
    connectModal.close();
  };

  return (
    <>
      <div className={cx("connect-box", bankInfo.expireClass)}>
        <div className="title">
          {t("common:accountP")} {bankInfo.iconClass && <i className={bankInfo.iconClass} />}
        </div>
        {bankInfo.connected ? (
          <div className="box-container">
            <div className="box-info">
              <div>
                {t("common:expireIn")}:{" "}
                <span>
                  {bankInfo.expire_in_days} {t("common:days")}
                </span>
              </div>
            </div>
            <Button type="button" className="renew" onClick={connectModal.open}>
              {t("common:actions.renew")}
            </Button>
          </div>
        ) : (
          <Button type="button" className="connect" onClick={connectModal.open}>
            {t("common:actions.connect")} <i className="fe-chevron-right" />
          </Button>
        )}
      </div>
      {connectModal.show && (
        <BankConnectModal companyId={companyId} handleClose={handleModalClose} renewForBic={bankInfo.bic_fi} />
      )}
    </>
  );
}

function ConnectTaxBox({ companyId }) {
  const { t } = useTranslation("common");
  const taxModal = useModal();
  const [skatInfo, setInfo] = useState({
    connected: false,
    expireClass: "",
    iconClass: "",
  });
  const getSkatteverketStatus = useCallback(() => {
    skattApi.connectionStatus(companyId).then((response) => {
      let expireClass = "";
      let iconClass = "";
      if (response.data.connected) {
        expireClass = "connection-ok";
        iconClass = "fe-check-circle";
      }
      setInfo({ ...response.data, expireClass, iconClass });
    });
  }, [companyId]);

  useEffect(() => {
    getSkatteverketStatus();
  }, [getSkatteverketStatus]);

  const handleModalClose = () => {
    getSkatteverketStatus();
    taxModal.close();
  };

  return (
    <>
      <div className={cx("connect-box", skatInfo.expireClass)}>
        <div className="title">Skatteverket {skatInfo.iconClass && <i className={skatInfo.iconClass} />}</div>
        {!skatInfo.connected ? (
          <Button type="button" className="connect" onClick={taxModal.open}>
            {t("common:actions.connect")} <i className="fe-chevron-right" />
          </Button>
        ) : (
          <div className="box-container">
            <div className="box-info">
              <div>{t("common:status.connected")}</div>
            </div>
          </div>
        )}
      </div>
      {taxModal.show && <SkatteverketConnectModal companyId={companyId} handleClose={handleModalClose} />}
    </>
  );
}

function ConnectKYCBox({ companyId }) {
  const { t } = useTranslation("company");
  const modal = useModal();
  const {
    company: { kyc_enabled },
  } = useCompanyState();
  const handleModalClose = () => {
    modal.close();
  };

  return (
    <>
      <div className={cx("connect-box")}>
        <div className="title">{t("kyc.interPayments")}</div>
        {!kyc_enabled ? (
          <Button type="button" className="connect" onClick={modal.open}>
            {t("kyc.startBtn")} <i className="fe-chevron-right" />
          </Button>
        ) : (
          <div className="box-container">
            <div className="box-info">
              <div className="text-primary">{t("common:status.connected")}</div>
            </div>
          </div>
        )}
      </div>
      {modal.show && <ConnectKYCModal companyId={companyId} handleClose={handleModalClose} />}
    </>
  );
}

export { ConnectBankBox, ConnectTaxBox, ConnectKYCBox };
