import React, { useContext, useEffect } from "react";
import { Card } from "react-bootstrap";

import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import { SaveButton } from "pages/companies/OnboardingWizard/utils";
import CompanySettingProvider from "state/providers/CompanySettingProvider";
import * as companyAPI from "api2/companies";
import { EmployeeExtendedTable } from "components/tables";
import { EmployeeAddWidget } from "components/forms/company-settings-forms/EmployeeForm";
import TableProvider from "components/tables/btable/provider";
import { handleActionErrors } from "api/errors";
import { useTranslation } from "react-i18next";
import { OnboardingDispatchContext } from "pages/companies/OnboardingWizard/provider/OnboardingProvider";
import { withInitialAsync } from "hooks/useAsync";

function EmployeesStep({ companyId, agencyId, initialLoading }) {
  const initialFilters = {};
  const { fetchDetails } = useContext(OnboardingDispatchContext);
  const { t } = useTranslation("common");

  useEffect(() => {
    if (!initialLoading) {
      fetchDetails();
    }
  }, [initialLoading, fetchDetails]);

  const onFinish = () => {
    return companyAPI.onboardings.steps
      .ownerAndEmployeesFinish(companyId, agencyId, null)
      .then(() => {
        fetchDetails(true);
      })
      .catch((error) => {
        handleActionErrors(error);
      });
  };
  return (
    <CompanySettingProvider>
      <Card className="employees-step" data-testid="employees-step">
        <Card.Body>
          <StepHeader
            rightComponent={<SaveButton onSave={onFinish} withIcon={false} buttonText={t("actions.goNext")} />}
          />
        </Card.Body>
        <TableProvider
          tableId="onboarding-employees"
          dataSource={(filters, config) => companyAPI.employees.list(companyId, filters, config)}
          initialFilters={initialFilters}
        >
          <Card.Body>
            <EmployeeExtendedTable companyId={companyId} forOnboarding />
          </Card.Body>
          <EmployeeAddWidget companyId={companyId} forOnboarding />
        </TableProvider>
      </Card>
    </CompanySettingProvider>
  );
}

const EnhancedEmployeesStep = withInitialAsync(
  EmployeesStep,
  ({ companyId, agencyId }) => {
    return React.useCallback(
      (cancelToken) => companyAPI.onboardings.steps.ownerAndEmployees(companyId, agencyId, { cancelToken }),
      [companyId, agencyId]
    );
  },
  {},
  true
);

export default EnhancedEmployeesStep;
