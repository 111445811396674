import React, { useContext, useEffect } from "react";
import { Outlet, useOutletContext, useResolvedPath } from "react-router-dom";
import { FiltersContext, FiltersProviderRelatedToFY } from "state/providers/FiltersProviderRelatedToFY";
import { FinancialYearStateContext } from "state/providers/FinancialYearProvider";
import { isAfter } from "date-fns";
import { useQuery } from "../../utils/others";
import { isSameDay, parseDate } from "../../utils/date";

function FinancialYearTrigger({ children }) {
  const { financialYear } = useContext(FinancialYearStateContext);
  const queryParams = useQuery();
  const startParam = queryParams.get("start");
  const endParam = queryParams.get("end");
  const { filters, updateFilter } = useContext(FiltersContext);

  useEffect(() => {
    let { start, end } = { start: filters.start, end: filters.end };
    if ((!start || !end) && (!startParam || !endParam)) {
      const d = new Date();
      d.setHours(0, 0, 0, 0);
      end = isAfter(financialYear.date_end, d) ? d : financialYear.date_end;
      start = financialYear.date_start;
    } else if (!filters.start && !filters.end) {
      start = parseDate(startParam);
      end = parseDate(endParam);
    }
    if (!filters.start || !isSameDay(start, filters.start) || !filters.end || !isSameDay(end, filters.end)) {
      updateFilter({ fYear: financialYear, start, end });
    }
  }, [startParam, endParam, updateFilter, financialYear, filters.start, filters.end]);

  if (!filters.fYear) {
    return null;
  }

  return children;
}

function AccountingReportsLayout() {
  const parentPath = useResolvedPath("").pathname;
  const company = useOutletContext();
  return (
    <FiltersProviderRelatedToFY filtersId="accounting-filters">
      <FinancialYearTrigger>
        <div className="tab-card">
          <Outlet context={{ company, parentPath }} />
        </div>
      </FinancialYearTrigger>
    </FiltersProviderRelatedToFY>
  );
}

export default AccountingReportsLayout;
