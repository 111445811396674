import { ButtonGroup, ToggleButton } from "react-bootstrap";
import React from "react";

import "pages/dashboard/reports/ViewSwitcher.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function BudgetViewSwitcher({ view, disabled }) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  return (
    <div className="view-switcher form-group">
      <label className="form-label">{t("common:view")}</label>
      <ButtonGroup toggle className="d-block">
        <ToggleButton
          disabled={disabled}
          type="checkbox"
          variant="switch with-icon"
          checked={view === "monthly"}
          onClick={() => !disabled && navigate("profit-and-loss", { replace: true })}
        >
          {t("standard")}
        </ToggleButton>
        <ToggleButton
          type="checkbox"
          disabled={disabled}
          variant="switch with-icon"
          checked={view === "yearly"}
          onChange={() => !disabled && navigate("profit-and-loss/year", { replace: true })}
        >
          12 {t("months")}
        </ToggleButton>
      </ButtonGroup>
    </div>
  );
}

export default BudgetViewSwitcher;
