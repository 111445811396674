import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import BTable from "components/tables/btable";
import { SalaryDocumentsLink } from "components/ui/buttons/DocumentsButton";
import * as options from "api/options";
import { truncateText } from "utils/text";
import { formatMonth } from "utils/date";
import { Button } from "react-bootstrap";
import * as documentsAPI from "api2/documents";
import { useCompanyState } from "hooks/useCompany";
import Actions from "./SalaryArchiveActions";
import Filters from "./SalaryArchiveFilters";
import confirmShareCustomer from "../CompanyArchiveTable/ConfirmShareCustomer";
import { codesFor, HasPermCode } from "../../perms";

function ShareColumn({ companyId, values }) {
  const { t } = useTranslation("company");
  const [forCustomer, setForCustomer] = useState(!!values.for_customer);
  const shareWithCustomer = useCallback(async () => {
    let confirmed = true;
    let notifyCustomer = false;
    if (forCustomer === false) {
      const res = await confirmShareCustomer(t("confirm.shareCustomer", { fileName: values.title }));
      confirmed = res.answer;
      notifyCustomer = res.notifyCustomer;
    }
    if (confirmed) {
      documentsAPI.salaryDocuments.toggleShareCustomer(companyId, values.id, notifyCustomer).then(() => {
        setForCustomer(!forCustomer);
      });
    }
  }, [t, values.title, forCustomer, companyId, values.id]);

  return (
    <div>
      <Button
        variant="action-toggle"
        size="sm"
        active={forCustomer}
        onClick={shareWithCustomer}
        title={t("others:shareCustomer")}
      >
        <i className="fe-user" />
      </Button>
    </div>
  );
}

function SalaryArchiveTable({ companyId, selectable = false, projection }) {
  const { t } = useTranslation("common");
  const { perms } = useCompanyState();
  const isCompanyConsult = HasPermCode(codesFor.consultManage.enabled) || perms.isAdmin;
  const hasViewAll = HasPermCode(codesFor.salaries.archive_all) || perms.isAdmin;
  const hasConsultViewAll = isCompanyConsult && hasViewAll;

  const headers = useMemo(() => {
    const list = hasConsultViewAll
      ? [
          {
            field: "share",
            width: 50,
            label: t("common:actions.share"),
            className: "p-0 text-center",
            canSort: false,
            render: (values) => <ShareColumn companyId={companyId} values={values} />,
          },
        ]
      : [];
    list.push(
      {
        field: "title",
        label: t("common:name"),
        canSort: false,
        render: (values) =>
          values.type === "document" ? (
            <SalaryDocumentsLink companyId={companyId} documentsIds={[values.id]} text={values.title || "-"} />
          ) : (
            values.title
          ),
      },
      {
        field: "category",
        label: t("common:category"),
        canSort: true,
        render: (values) => options.salaryArchiveCategories.getLabel(values.category),
      },
      {
        field: "description",
        label: t("common:descriptionOrNote"),
        className: "w-300px",
        canSort: false,
        render: (values) => truncateText(values.description, 103),
      },
      {
        field: "month",
        label: t("common:dates.month"),
        canSort: true,
        render: (values) => (values.month ? formatMonth(values.month) : "-"),
      },
      {
        field: "created_by_name",
        label: t("common:createdBy"),
        canSort: false,
      }
    );
    return list;
  }, [t, companyId, hasConsultViewAll]);

  const getRowProps = (row) => {
    return {
      className: cx({ "has-message": !!row.comments.length, archived: !!row.is_archived }),
    };
  };

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      FiltersComponent={<Filters companyId={companyId} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      getRowProps={getRowProps}
    />
  );
}

export default SalaryArchiveTable;
