import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { useAuthDispatch } from "hooks/useAuth";
import BankIdQrCode from "components/ui/BankIdQrCode";
import * as authAPI from "api2/auth";
import "./BankIDLoginModal.scss";
import { Loader } from "../../../components/ui/loaders";

function BankIDLoginModal({ loginData, handleClose }) {
  const { t } = useTranslation();
  const statusRef = useRef(false);
  const [signData, setSignData] = useState(() => ({ ...loginData, error: "" }));
  const { bankIDLoginCheck, loggedInAction } = useAuthDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      if (!statusRef.current) {
        statusRef.current = true;
        authAPI
          .bankIDLoginCheck(signData.order_ref)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.status === "failed") {
                setSignData((_d) => ({
                  ..._d,
                  hint_code: response.data.hint_code,
                  hint_msg: "",
                  error: response.data.hint_msg,
                }));
              } else {
                setSignData((_d) => ({
                  ..._d,
                  hint_code: response.data.hint_code,
                  hint_msg: response.data.hint_msg,
                  error: "",
                }));
                statusRef.current = false;
              }
            } else if (response.status === 201) {
              loggedInAction(response.data.token, response.data.user);
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              setSignData((_d) => ({
                ..._d,
                hint_msg: "",
                error: error.response.data.errors[0].detail,
              }));
            }
          });
      }
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [t, bankIDLoginCheck, loggedInAction, signData.order_ref]);

  return (
    <Modal show onHide={handleClose} size="lg" scrollable className="bankID-login-modal">
      <button type="button" className="close" onClick={handleClose}>
        <i className="fe-x" />
      </button>
      {signData.error ? (
        <Modal.Body>
          <p className="modal-title">{t("common:errors.sthWentWrong")}</p>
          <p style={{ width: 350, textAlign: "center" }}>{signData.error}</p>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <p className="modal-title">{t("others:bankid.loginTitle")}</p>

          {signData.hint_code === "userSign" ? (
            <div>
              <p>{signData.hint_msg}</p>
              <Loader withText={false} />
            </div>
          ) : (
            <>
              <ol>
                <Trans i18nKey="others:bankid.steps">
                  <li>Open the BankID app on your phone</li>
                  <li>Tap the QR symbol in the BankID app</li>
                  <li>Scan the QR code with your camera</li>
                </Trans>
              </ol>
              <div className="qrCode">
                <BankIdQrCode initialQrCode={signData.qr_code} orderRef={signData.order_ref} />
              </div>
              <a
                href={`https://app.bankid.com/?autostarttoken=${signData.auto_start_token}&redirect=null`}
                rel="noreferrer"
                target="_blank"
                className="btn btn-link underline"
              >
                {t("others:bankid.sameDevice")}
              </a>
            </>
          )}
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button variant="link" onClick={handleClose}>
          {t("common:actions.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default BankIDLoginModal;
