import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ProjectForm from "components/forms/company-settings-forms/ProjectForm";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";

function ProjectAddModal({ companyId, handleClose, onSuccess, initialSerialOrName = "" }) {
  const { t } = useTranslation("company");
  const isSerial = Number.isFinite(+initialSerialOrName);
  const project = {
    name: !isSerial ? initialSerialOrName : "",
    serial: isSerial ? initialSerialOrName : "",
  };
  const successCallback = (newProject) => {
    if (onSuccess) {
      onSuccess(newProject);
    }
    handleClose();
  };

  return (
    <Modal show onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("actions.addProject")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProjectForm companyId={companyId} project={project} successCallback={successCallback} />
      </Modal.Body>
    </Modal>
  );
}

export default ProjectAddModal;
