import { Alert, Modal } from "react-bootstrap";
import React from "react";
import { SubmitButton } from "components/ui/buttons";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as companyAPI from "api2/companies";
import { formatDate, parseDate } from "utils/date";
import { FormGroup } from "components/formik";
import * as yup from "yup";
import { DraggableModalDialog } from "../DraggableModalDialog";

function LockAccountingModal({ company, handleClose }) {
  const { t } = useTranslation("company");
  const formikProps = {
    initialValues: {
      lockDate: parseDate(company.lock_accounting_date),
    },
    validationSchema: yup.object().shape({
      lockDate: yup.date().nullable().required(),
    }),
    onSubmit: async (values) => {
      const fDate = values.lockDate ? formatDate(values.lockDate) : null;
      await companyAPI
        .lockAccountingDate(company.id, fDate)
        .then((response) => {
          handleClose({
            ...company,
            lock_accounting_date: fDate,
          });
        })
        .catch(() => {
          // errors
        });
    },
  };

  return (
    <Modal show onHide={handleClose} animation={false} dialogAs={DraggableModalDialog}>
      <Formik {...formikProps}>
        {({ isSubmitting }) => {
          return (
            <Form noValidate>
              <Modal.Header closeButton>
                <Modal.Title as="h4">{company.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormGroup.DatePicker
                  label={t("lockAccountingDate")}
                  popperClassName="popper-in-modal"
                  name="lockDate"
                  required
                />
                <Alert variant="info">{t("lockAccountingInfo")}</Alert>
              </Modal.Body>
              <Modal.Footer>
                <SubmitButton isSubmitting={isSubmitting} />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default LockAccountingModal;
