import React, { useCallback } from "react";
import { toast } from "react-toastify";

import { CompanyDispatchContext, CompanyStateContext } from "state/providers/CompanyProvider";
import * as companyAPI from "api2/companies";
import {
  RESET_COMPANY,
  SET_COMPANY_ERROR,
  SET_COMPANY_OK,
  SET_COMPANY_WAIT,
  SET_LOADED,
  SET_LOADING,
  UPDATE_COMPANY,
  UPDATE_COMPANY_PROJECTS,
  UPDATE_COMPANY_COST_CENTERS,
} from "state/types";

import { resetSavedFilters as resetSavedTableFilters } from "components/tables/btable/provider";

function useCompanyState() {
  const context = React.useContext(CompanyStateContext);
  if (context === undefined) {
    throw new Error("useCompanyState must be used within a CompanyProvider");
  }
  return context;
}

function useCompanyDispatch() {
  const dispatch = React.useContext(CompanyDispatchContext);
  if (dispatch === undefined) {
    throw new Error("useCompanyDispatch must be used within a CompanyProvider");
  }

  function reset() {
    dispatch({ type: RESET_COMPANY });
  }

  const selectCompany = useCallback(
    async (companyId) => {
      localStorage.setItem("lastCompanyId", companyId);
      resetSavedTableFilters();
      return dispatch({ type: SET_LOADING });
    },
    [dispatch]
  );

  const loadCompany = useCallback(
    (companyId, forOfficeSupport) => {
      if (!companyId) {
        return dispatch({ type: RESET_COMPANY });
      }
      dispatch({ type: SET_COMPANY_WAIT });
      return companyAPI
        .selectCompany(companyId, forOfficeSupport)
        .then(async (response) => {
          dispatch({ type: SET_COMPANY_OK, payload: response.data });
          localStorage.setItem("lastCompanyId", companyId);
          dispatch({ type: SET_LOADED });
          return response;
        })
        .catch((error) => {
          dispatch({ type: SET_COMPANY_ERROR });
          localStorage.removeItem("lastCompanyId");
          if (error.data && error.data.__all__) {
            toast.error(error.data.__all__);
          }
          return error;
        });
    },
    [dispatch]
  );

  const updateCompanyData = useCallback(
    (payload) => {
      dispatch({ type: UPDATE_COMPANY, payload });
    },
    [dispatch]
  );
  const updateCompanyProjectsOptions = useCallback(
    (payload) => {
      dispatch({ type: UPDATE_COMPANY_PROJECTS, payload });
    },
    [dispatch]
  );
  const updateCompanyCostCentersOptions = useCallback(
    (payload) => {
      dispatch({ type: UPDATE_COMPANY_COST_CENTERS, payload });
    },
    [dispatch]
  );
  return {
    selectCompany,
    loadCompany,
    updateCompanyData,
    updateCompanyProjectsOptions,
    updateCompanyCostCentersOptions,
    reset,
  };
}

export { useCompanyState, useCompanyDispatch };
