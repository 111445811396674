import React, { useEffect, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { Document } from "components/ui/documents/document-box";
import client from "api2/client";

import { Loader } from "components/ui/loaders";
import { DraggableModalDialog } from "../DraggableModalDialog";

function OrderPreviewCreateModal({ onClose, companyId, orderData }) {
  const { t } = useTranslation("ci");
  const [data, setData] = useState({
    url: "",
    type: "",
    size: 0,
    loading: true,
    error: "",
  });
  const endpoint = `/orders/preview-live/`;
  useEffect(() => {
    const signal = axios.CancelToken.source();
    client
      .post(endpoint, orderData, {
        cancelToken: signal.token,
        responseType: "blob",
        headers: {
          "x-company": companyId,
        },
      })
      .then((response) => {
        const blob = response.data;
        setData({
          url: window.URL.createObjectURL(blob),
          type: blob.type,
          size: blob.size,
          loading: false,
          error: "",
        });
      })
      .catch(async (error) => {
        if (!axios.isCancel(error)) {
          setData({
            url: "",
            type: "",
            size: 0,
            loading: false,
            error: error.data && error.data.__all__ ? error.data.__all__ : t("common:file.cannotLoadFile"),
          });
        }
      });
    return () => {
      signal.cancel("aborted");
    };
  }, [companyId, setData, orderData, endpoint, t]);

  return (
    <Modal show onHide={onClose} size="xl" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("actions.previewOrder")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data.loading && <Loader />}
        {data.url && <Document url={data.url} type={data.type} fullMode />}
        {!data.loading && data.error && <Alert variant="danger">{data.error}</Alert>}
      </Modal.Body>
    </Modal>
  );
}

export default OrderPreviewCreateModal;
