import { formatDate } from "utils/date";
import client from "./client";

export function getCurrencyRate(code, date) {
  return client.get("/utils/currency-rate/", {
    params: {
      code,
      target_date: formatDate(date),
    },
  });
}
