import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Masonry from "react-masonry-css";
import cx from "classnames";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import BankConnectionItem from "pages/companies/BankConnections/BankConnectionItem";
import { psd2 as psd2Api } from "api2/integrations";
import useModal from "hooks/useModal";

import { confirmRemove } from "components/modals/ConfirmModal";
import PaymentAccountProvider, { PaymentAccountContext } from "state/providers/PaymentAccountProvider";
import NewConnectionBox from "./AddNewConnectionBox";
import "./BankConnections.scss";
import BankConnectModal from "../../BankConnections/BankConnectModal";

function BankConnectionsPage({ companyId }) {
  const [consents, setConsents] = useState([]);
  const { t } = useTranslation("company");
  const { setIsPaymentAccount } = useContext(PaymentAccountContext);

  useEffect(() => {
    const isPaymentAccount = consents.some((consent) => consent.accounts.some((account) => account.is_payment_account));
    setIsPaymentAccount(isPaymentAccount);
  }, [consents, setIsPaymentAccount]);

  const getConsents = useCallback(() => {
    psd2Api.consents
      .list(companyId)
      .then((response) => {
        response.data.forEach((connection) => {
          connection.expire_class = "connection-ok";
          if (connection.expire_in_days === 0) {
            connection.expire_in_days = 0;
            connection.expire_class = "connection-danger";
          } else if (connection.expire_in_days <= 30) {
            connection.expire_class = "connection-warning";
          }
        });

        setConsents(response.data);
      })
      .catch((error) => {});
  }, [companyId]);

  useEffect(() => {
    getConsents();
  }, [getConsents]);

  useEffect(() => {
    document.body.addEventListener("psd/reload", getConsents);
    return () => document.body.removeEventListener("psd/reload", getConsents);
  }, [getConsents]);

  const onRemoveConnection = async (connection, index) => {
    const answer = await confirmRemove(
      t("confirm.removePSDConsent", {
        bank: t(`options:bank.${connection.bic_fi}`),
      })
    );
    if (consents.length > 1 && connection.accounts.filter((acc) => acc.is_payment_account).length) {
      toast.error(t("company:psd.errors.selectDiffPayAccount"));
      return;
    }
    if (answer) {
      psd2Api.consents
        .delete(companyId, connection.id)
        .then(() => {
          toast.success(t("psd.consentRemoved"));
        })
        .finally(() => {
          getConsents();
        })
        .catch(() => {});
    }
  };

  const breakpointColumnsObj = {
    default: 2,
    1400: 1,
  };
  const firstItems = consents.slice(0, 1);
  const restItems = consents.slice(1);

  return (
    <div className={cx("bank-connections", { "bank-connections__empty": !consents.length })}>
      <h1>{t("psd.title")}</h1>
      <Masonry breakpointCols={breakpointColumnsObj} className="bank-connection-items">
        {firstItems.map((consent, index) => (
          <BankConnectionItem
            key={consent.id}
            companyId={companyId}
            connection={consent}
            handleRemove={() => onRemoveConnection(consent, index)}
          />
        ))}
        <NewConnectionBox companyId={companyId} onCloseModal={getConsents} />
        {restItems.map((consent, index) => (
          <BankConnectionItem
            key={consent.id}
            companyId={companyId}
            connection={consent}
            handleRemove={() => onRemoveConnection(consent, index)}
          />
        ))}
      </Masonry>
    </div>
  );
}

function BankConnectionsPageWrapper() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { companyId } = useParams();
  const connectModal = useModal();
  const initialConsentId = searchParams.get("cid");

  const handleModalClose = () => {
    setSearchParams(undefined);
    connectModal.close();
  };

  return (
    <PaymentAccountProvider>
      <BankConnectionsPage companyId={companyId} />
      {initialConsentId && (
        <BankConnectModal companyId={companyId} handleClose={handleModalClose} initialConsentId={initialConsentId} />
      )}
    </PaymentAccountProvider>
  );
}

export default BankConnectionsPageWrapper;
