import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import useTable from "components/tables/btable/useTable";
import {
  ConsultFilter,
  DateRangeFilters,
  NumberRangeFilter,
  SearchFilter,
  SelectSimpleFilter,
} from "components/filters";
import * as options from "api/options";
import { FilterButton } from "components/ui/buttons";

function DayToDayFilters() {
  const bankChoices = options.bankChoices.asList();
  const vatPeriodChoices = options.vatPeriodNumber.asList();
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("company");
  const filtersCount =
    !!filters.consult +
    !!filters.capital_min +
    !!filters.capital_max +
    !!filters.balance_1630_min +
    !!filters.balance_1630_max +
    !!filters.last_reconciliation_date__start +
    !!filters.last_reconciliation_date__end;
  const [more, setMore] = useState(!!filtersCount);
  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        <SelectSimpleFilter
          onFilter={setFilters}
          options={bankChoices}
          placeholder={t("bankName")}
          isClearable
          name="bank_name"
        />
        <SelectSimpleFilter
          onFilter={setFilters}
          options={vatPeriodChoices}
          placeholder={t("manage.vatPeriod")}
          isClearable
          name="vat_report_period"
        />
        <Button
          variant="toggle"
          onClick={() => setFilters({ favourites: filters.favourites ? null : true })}
          active={filters.favourites}
        >
          {t("favouritesOnly")}
        </Button>
      </section>
      {more && (
        <section className="more-filters">
          <NumberRangeFilter
            label={t("manage.shareCapital")}
            numberFrom={filters.capital_min}
            numberTo={filters.capital_max}
            onChange={(values) =>
              setFilters({
                capital_min: values.numberFrom,
                capital_max: values.numberTo,
              })
            }
          />
          <NumberRangeFilter
            label={t("manage.1630Tax")}
            numberFrom={filters.balance_1630_min}
            numberTo={filters.balance_1630_max}
            onChange={(values) =>
              setFilters({
                balance_1630_min: values.numberFrom,
                balance_1630_max: values.numberTo,
              })
            }
          />
          <DateRangeFilters
            label={t("manage.lastRecon")}
            dateStart={filters.last_reconciliation_date__start}
            dateEnd={filters.last_reconciliation_date__end}
            onChange={({ start, end }) =>
              setFilters({
                last_reconciliation_date__start: start,
                last_reconciliation_date__end: end,
              })
            }
          />
          <ConsultFilter onFilter={setFilters} label={t("common:consult")} defaultValue={filters.consult} />
        </section>
      )}
    </>
  );
}

export default DayToDayFilters;
