import React from "react";
import { Card } from "react-bootstrap";
import { subMonths } from "date-fns";
import { StaffPermRequired } from "components/perms";
import CompanyListLayout from "components/layouts/CompanyListLayout";
import TableProvider from "components/tables/btable/provider";
import EuVatTable from "components/tables/EuVatTable";
import { SelectionProvider } from "state/providers/SelectionProvider";
import * as vatAPI from "api2/vat";

function EuVatPage() {
  const initialFilters = {
    period: "monthly",
    period_month: subMonths(new Date(), 1),
    favourites: true,
  };

  return (
    <StaffPermRequired agencyConsult>
      <CompanyListLayout>
        <Card>
          <Card.Body>
            <SelectionProvider>
              <TableProvider tableId="vatEu" dataSource={vatAPI.vatEU.list} initialFilters={initialFilters}>
                <EuVatTable />
              </TableProvider>
            </SelectionProvider>
          </Card.Body>
        </Card>
      </CompanyListLayout>
    </StaffPermRequired>
  );
}

export default EuVatPage;
