import React from "react";
import * as selectAPI from "api2/selects";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { Button, InputGroup } from "react-bootstrap";
import { UserAddModal } from "components/modals";
import useModal from "hooks/useModal";
import FormGroup from "./FormGroup";

function UserPicker({
  name = "user",
  label,
  agencyId,
  isDisabled = false,
  showAddButton = true,
  addUserCallback,
  setValue,
  ...otherProps
}) {
  const { t } = useTranslation("common");
  const userModal = useModal();
  const getUsers = debounce((params, callback) => {
    selectAPI.agencyUsers({ ...params, agency: agencyId }).then((data) => {
      return callback(data);
    });
  }, 500);

  const onAddUser = (newUser) => {
    if (setValue) {
      setValue(name, { value: newUser.id, label: newUser.name });
    }
  };

  return (
    <div className="user-picker">
      <InputGroup>
        <FormGroup.AsyncSelect
          wrapperStyle={{ width: "100%" }}
          name={name}
          label={label || t("common:user")}
          loadOptions={getUsers}
          minSearchLength={3}
          isDisabled={isDisabled}
          {...otherProps}
        />
        {showAddButton && (
          <InputGroup.Append>
            <Button variant="secondary" className="float-right" onClick={userModal.open}>
              <i className="fas fa-plus" />
            </Button>
          </InputGroup.Append>
        )}
      </InputGroup>
      {userModal.show && <UserAddModal agencyId={agencyId} onSuccess={onAddUser} handleClose={userModal.close} />}
    </div>
  );
}

export default UserPicker;
