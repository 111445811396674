import React from "react";
import { codesFor, HasPermCode } from "components/perms";
import SalaryListPage from "./SalaryListPage";

function ApproveSalaryListPage() {
  const canApprove = HasPermCode(codesFor.salaries.approve);
  const allowedModes = [{ value: "prelim", label: "common:statuses.prelim", variant: "toggle-orange" }];
  return (
    <SalaryListPage
      tableId="prelimSalaries"
      allowedModes={allowedModes}
      initialMode={allowedModes[0].value}
      selectable={canApprove}
    />
  );
}

export default ApproveSalaryListPage;
