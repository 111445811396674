import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function LogoutPage() {
  const { t } = useTranslation("others");
  return (
    <div className="box">
      <h3>{t("others:auth.seeAgain")}</h3>
      <p className="text-white">
        <Trans i18nKey="others:auth.logoutMsg">
          You are now successfully signed out.
          <Link className="text-white m1-1 underline" to="/auth/login">
            Sign In
          </Link>
        </Trans>
      </p>
    </div>
  );
}

export default LogoutPage;
