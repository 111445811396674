import React, { useCallback, useContext, useMemo, useRef, useState } from "react";
import cx from "classnames";

const DragStateContext = React.createContext(undefined);

function DragProvider({ handleSwapItems, children }) {
  const dragItem = useRef(-1);
  const [dragOverItem, setDragOverItem] = useState(-1);

  const onDragStart = useCallback((e) => {
    dragItem.current = parseInt(e.target.dataset.index, 10);
  }, []);

  const onDragEnter = useCallback((e) => {
    setDragOverItem(parseInt(e.currentTarget.dataset.index, 10));
  }, []);

  const onDragEnd = useCallback(() => {
    if (dragItem.current !== dragOverItem) {
      handleSwapItems(dragItem.current, dragOverItem);
    }
    dragItem.current = -1;
    setDragOverItem(-1);
  }, [dragOverItem, handleSwapItems]);

  const stateValue = useMemo(
    () => ({
      dragOverItem,
      onDragStart,
      onDragEnter,
      onDragEnd,
    }),
    [dragOverItem, onDragStart, onDragEnter, onDragEnd]
  );
  return <DragStateContext.Provider value={stateValue}>{children}</DragStateContext.Provider>;
}

function DraggableItem({ index, dragEnabled, children }) {
  const { onDragStart, onDragEnter, onDragEnd, dragOverItem } = useContext(DragStateContext);
  return (
    <div
      data-index={index}
      draggable={dragEnabled}
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragEnd={onDragEnd}
      className={cx("drag-wait", { "drag-enter": dragOverItem === index })}
    >
      {children}
    </div>
  );
}

export { DragStateContext, DraggableItem };
export default DragProvider;
