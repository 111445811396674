import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { TooltipActionButton } from "components/ui/buttons";
import { confirmRemove } from "components/modals/ConfirmModal";
import * as companyAPI from "api2/companies";
import useTable from "components/tables/btable/useTable";
import { codesForAuditors } from "components/perms/PermCodes";
import { CodeRequiredInList } from "components/perms";

function AuditorActions({ companyId, row, userPermCodes }) {
  const { t } = useTranslation("company");
  const {
    dataActions: { reload },
  } = useTable();

  const onDelete = async () => {
    const answer = await confirmRemove(t("confirm.removeAuditor", { userName: row.user_name }));
    if (answer) {
      companyAPI.auditors
        .delete(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reload();
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
    }
  };

  return (
    <CodeRequiredInList code={codesForAuditors.manage} codeList={userPermCodes}>
      <TooltipActionButton
        variant="action-red"
        text={t("common:actions.remove")}
        onClick={onDelete}
        icon="fas fa-trash"
      />
    </CodeRequiredInList>
  );
}

export default AuditorActions;
