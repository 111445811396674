import React, { useCallback } from "react";
import cx from "classnames";
import "./SystemNotificationBar.scss";
import * as notAPI from "api2/notifications";
import useAsync from "hooks/useAsync";
import { useTranslation } from "react-i18next";

function SystemNotificationBar({ companyId }) {
  const { i18n } = useTranslation();
  const dataSource = useCallback(
    (cancelToken) => notAPI.adminNotification.list(companyId, { cancelToken }),
    [companyId]
  );
  const [{ data: notifications, loading }, { reload }] = useAsync(dataSource, []);

  if (loading || !notifications) {
    return null;
  }

  const hideNotification = (notificationId) => {
    notAPI.adminNotification
      .hide(companyId, notificationId)
      .finally(() => {
        reload();
      })
      .catch(() => {});
  };

  return notifications.map((notification) => (
    <div className={cx("system-notification-bar", `bg-${notification.color}`)} key={notification.id}>
      <i
        className={cx({
          "fe-alert-triangle": notification.icon === "warn",
          "fe-info": notification.icon === "info",
          "fe-bell": notification.icon === "bell",
        })}
      />
      {i18n.language === "en" ? notification.message_en : notification.message_sv}
      {notification.can_be_closed && (
        <button type="button" onClick={() => hideNotification(notification.id)}>
          X
        </button>
      )}
    </div>
  ));
}

export default SystemNotificationBar;
