import React from "react";
import { codesFor, HasPermCode } from "components/perms";
import TaxDirectPaymentListPage from "./TaxDirectPaymentListPage";

function PayTaxDirectPaymentListPage() {
  const allowedModes = [{ value: "unpaid", label: "common:statuses.unpaid", variant: "toggle-red" }];
  const canPay = HasPermCode(codesFor.taxes.pay);
  return (
    <TaxDirectPaymentListPage
      tableId="unpaidTaxDP"
      allowedModes={allowedModes}
      initialMode={allowedModes[0].value}
      selectable={canPay}
    />
  );
}

export default PayTaxDirectPaymentListPage;
