import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import * as tableAPI from "api/table";
import { formatMonth, formatQuarter, parseDate } from "utils/date";
import { Loader } from "components/ui/loaders";
import cx from "classnames";
import * as options from "api/options";
import { toast } from "react-toastify";

function NoneView({ eventsData }) {
  const part1 = eventsData.slice(0, 6);
  const part2 = eventsData.slice(6, 12);
  const part3 = eventsData.slice(12, 20);
  const renderTable = (part) => {
    if (!part) {
      return null;
    }
    return (
      <Table bordered className="manage-overview">
        <thead>
          <tr>
            {part.map((event) => (
              <th key={event.period_start}>{formatMonth(event.period_start)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {part.map((event) => (
              <td key={event.period_end} className={cx("gray")}>
                {options.vatReportOptions.getLabel("none")}
              </td>
            ))}
          </tr>
        </tbody>
      </Table>
    );
  };
  return (
    <>
      {renderTable(part1)}
      {renderTable(part2)}
      {renderTable(part3)}
    </>
  );
}

function MonthlyView({ eventsData }) {
  const { t } = useTranslation("common");
  const part1 = eventsData.slice(0, 6);
  const part2 = eventsData.slice(6, 12);
  const part3 = eventsData.slice(12, 20);
  const renderTable = (part) => {
    if (!part) {
      return null;
    }
    return (
      <Table bordered className="manage-overview">
        <thead>
          <tr>
            {part.map((event) => (
              <th key={event.period_start}>{formatMonth(event.period_start)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {part.map((event) => (
              <td key={event.period_end} className={cx(event.id ? "green" : "red")}>
                {event.id ? t("common:reported") : t("common:notReported")}
              </td>
            ))}
          </tr>
        </tbody>
      </Table>
    );
  };
  return (
    <>
      {renderTable(part1)}
      {renderTable(part2)}
      {renderTable(part3)}
    </>
  );
}

function QuarterlyView({ eventsData }) {
  const { t } = useTranslation("common");
  return (
    <Table bordered className="manage-overview">
      <thead>
        <tr>
          {eventsData.map((event) => (
            <th key={event.period_start}>{formatQuarter(event.period_start)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {eventsData.map((event) => (
            <td key={event.period_end} className={cx(event.id ? "green" : "red")}>
              {event.id ? t("common:reported") : t("common:notReported")}
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  );
}

function YearlyView({ eventsData }) {
  const { t } = useTranslation("common");
  const FormatYear = ({ startDate, endDate }) => {
    if (startDate.getFullYear() !== endDate.getFullYear()) {
      return `${startDate.getFullYear()}/${endDate.getFullYear()}`;
    }
    return startDate.getFullYear();
  };
  return (
    <Table bordered className="manage-overview">
      <thead>
        <tr>
          {eventsData.map((event) => (
            <th key={event.period_start}>
              <FormatYear startDate={event.period_start} endDate={event.period_end} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {eventsData.map((event) => (
            <td key={event.period_start} className={cx(event.id ? "green" : "red")}>
              {event.id ? t("common:reported") : t("common:notReported")}
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  );
}

function MixedView({ eventsData }) {
  const { t } = useTranslation("common");
  const groupVATData = (data) => {
    const grouped = [];
    let currentGroup = [];
    let currentType = "";

    data.forEach((item, index) => {
      if (item.period_type !== currentType || index === 0) {
        if (currentGroup.length > 0) {
          grouped.push(currentGroup);
        }
        currentGroup = [item];
        currentType = item.period_type;
      } else {
        currentGroup.push(item);
      }
    });
    if (currentGroup.length > 0) {
      grouped.push(currentGroup);
    }
    return grouped;
  };
  const groupedData = groupVATData(eventsData);
  const FormatYear = ({ startDate, endDate }) => {
    if (startDate.getFullYear() !== endDate.getFullYear()) {
      return `${startDate.getFullYear()}/${endDate.getFullYear()}`;
    }
    return startDate.getFullYear();
  };
  const renderTable = (part) => {
    if (!part) {
      return null;
    }

    const getCellHeader = (event) => {
      if (event.period_type === "vat_yearly_se" || event.period_type === "vat_yearly_eu") {
        return <FormatYear startDate={event.period_start} endDate={event.period_end} />;
      }
      if (event.period_type === "vat_quarterly") {
        return formatQuarter(event.period_start);
      }
      return formatMonth(event.period_start);
    };
    const getCellClassName = (event) => {
      if (event.id) {
        return "green";
      }
      if (event.period_type === "none" || new Date(event.period_end) > new Date()) {
        return "gray";
      }
      return "red";
    };

    const getCellContent = (event) => {
      if (event.id) {
        return t("common:reported");
      }
      if (event.period_type === "none") {
        return options.vatReportOptions.getLabel("none");
      }
      if (new Date(event.period_end) > new Date()) {
        return t("common:statuses.ongoing");
      }
      return t("common:notReported");
    };

    return (
      <Table bordered className="manage-overview">
        <thead>
          <tr>
            {part.map((event) => {
              return <th key={event.period_start}>{getCellHeader(event)}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            {part.map((event) => {
              return (
                <td key={event.period_end} className={cx(getCellClassName(event))}>
                  {getCellContent(event)}
                </td>
              );
            })}
          </tr>
        </tbody>
      </Table>
    );
  };

  return (
    <>
      {renderTable(groupedData[0])}
      {renderTable(groupedData[1])}
      {renderTable(groupedData[2])}
      {renderTable(groupedData[3])}
    </>
  );
}

function VatReportedPreviewModal({ companyId, vatPeriod, vatPeriods, handleClose }) {
  const { t } = useTranslation("common");
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    tableAPI
      .skvCustomerEventsVAT(companyId)
      .then((response) => {
        setData(
          response.data.map((event) => ({
            ...event,
            period_start: parseDate(event.period_start),
            period_end: parseDate(event.period_end),
          }))
        );
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__, { autoClose: 4000 });
        } else {
          toast.error(t("msg:canNotExecuteAction"));
        }
      })
      .finally(() => {
        setFetching(false);
      });
  }, [companyId, t]);

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={handleClose}
      dialogClassName="confirmation-modal"
      size="lg"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("common:actions.preview")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {fetching ? (
          <Loader />
        ) : (
          <>
            {vatPeriods.length === 1 && vatPeriod.includes("none") && <NoneView eventsData={data} />}
            {vatPeriods.length === 1 && vatPeriod.includes("monthly") && <MonthlyView eventsData={data} />}
            {vatPeriods.length === 1 && vatPeriod.includes("quarterly") && <QuarterlyView eventsData={data} />}
            {vatPeriods.length === 1 && vatPeriod.includes("yearly") && <YearlyView eventsData={data} />}
            {vatPeriods.length > 1 && <MixedView eventsData={data} />}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default VatReportedPreviewModal;
