import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import useTable from "components/tables/btable/useTable";
import { ConsultFilter, MonthOnlyDateFilter, SearchFilter } from "components/filters";

function BMD1Filters() {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("company");
  return (
    <section className="table-filters-left">
      <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
      <ConsultFilter onFilter={setFilters} defaultValue={filters.consult} placeholder={t("common:consult")} />
      <MonthOnlyDateFilter
        onChange={(date) => setFilters({ fmonth: date })}
        defaultValue={filters.fmonth}
        placeholder={t("common:dates.month")}
      />
      <Button
        variant="toggle"
        onClick={() => setFilters({ favourites: filters.favourites ? null : true })}
        active={filters.favourites}
      >
        {t("favouritesOnly")}
      </Button>
      {/* <TooltipActionButton variant="primary" icon="fas fa-info" text="Text Text Text" onClick={() => {}}/> */}
    </section>
  );
}

export default BMD1Filters;
