import React from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import TableProvider from "components/tables/btable/provider";
import * as companyAPI from "api2/companies";
import * as selectAPI from "api2/selects";

import { BankRulesExtendedTable } from "components/tables";
import { BankRuleAddWidget } from "components/forms/company-settings-forms/BankRuleForm";
import { withInitialAsync } from "hooks/useAsync";

function BankMatchingRulesPage({ data: companyAccounts }) {
  const { companyId } = useParams();

  const initialFilters = {};

  return (
    <TableProvider
      tableId="bankRulesTable"
      dataSource={(filters, config) => companyAPI.bankMatchingRules.list(companyId, filters, config)}
      initialFilters={initialFilters}
    >
      <Card>
        <Card.Body>
          <BankRulesExtendedTable companyId={companyId} companyAccounts={companyAccounts} />
        </Card.Body>
      </Card>
      <BankRuleAddWidget companyId={companyId} companyAccounts={companyAccounts} />
    </TableProvider>
  );
}

const EnhancedBankMatchingRulesPage = withInitialAsync(
  BankMatchingRulesPage,
  () => {
    const { companyId } = useParams();
    return React.useCallback(
      (cancelToken) => selectAPI.companyAccounts(companyId, { for_bank_rules: true }, { cancelToken }),
      [companyId]
    );
  },
  [],
  false
);

export default EnhancedBankMatchingRulesPage;
