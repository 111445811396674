import React, { useCallback } from "react";
import { Button as BootstrapButton, Form, OverlayTrigger, Tooltip } from "react-bootstrap";

function ButtonFilter({
  defaultSelected = false,
  onFilter,
  wrapperClass,
  title,
  hiddenLabel = false,
  helpText,
  ...props
}) {
  const [isSelected, setIsSelected] = React.useState(defaultSelected);

  const toggleSelection = useCallback(() => {
    const newValue = !isSelected;
    setIsSelected(newValue);
    onFilter(newValue);
  }, [isSelected, onFilter]);

  const button = (
    <BootstrapButton variant="toggle" onClick={toggleSelection} active={isSelected} {...props}>
      {title}
    </BootstrapButton>
  );

  const buttonWithTooltip = helpText ? (
    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${title}`}>{helpText}</Tooltip>}>
      {button}
    </OverlayTrigger>
  ) : (
    button
  );

  if (hiddenLabel) {
    return (
      <Form.Group className={wrapperClass}>
        <Form.Label>&nbsp;</Form.Label>
        {buttonWithTooltip}
      </Form.Group>
    );
  }
  return (
    <Form.Group className={wrapperClass}>
      <Form.Label>{title}</Form.Label>
      {buttonWithTooltip}
    </Form.Group>
  );
}

export default ButtonFilter;
