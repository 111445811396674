import React from "react";
import { Card } from "react-bootstrap";
import CompanyListLayout from "components/layouts/CompanyListLayout";
import TableProvider from "components/tables/btable/provider";
import { OnboardingProgressTable } from "components/tables";
import * as companiesAPI from "api2/companies";

function OnboardingProgressPage() {
  const initialFilters = {
    onboarding_statuses: ["in_progress", "error"],
  };

  return (
    <CompanyListLayout>
      <Card>
        <Card.Body>
          <TableProvider
            tableId="onboarding-progress"
            dataSource={companiesAPI.onboardings.list}
            initialFilters={initialFilters}
            keepTerm={false}
          >
            <OnboardingProgressTable />
          </TableProvider>
        </Card.Body>
      </Card>
    </CompanyListLayout>
  );
}

export default OnboardingProgressPage;
