import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useTable from "components/tables/btable/useTable";
import BankRuleForm from "./BankRuleForm";

function BankRuleAddWidget({ companyId, companyAccounts }) {
  const {
    dataActions: { reload },
  } = useTable();
  const bankRule = {
    priority: 1,
    search_text: "",
    verification_title: "",
    condition: "starts_with",
    main: { percent: 100, account_ids: [], charge: "debit" },
    rules: [{ percent: 100, account_ids: [], charge: "credit" }],
  };
  const { t } = useTranslation("company");
  return (
    <Row>
      <Col>
        <Card className="card-border">
          <Card.Header className="border-success">
            <Card.Title>{t("bankRule.addBankRule")}</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <BankRuleForm
              companyId={companyId}
              bankRule={bankRule}
              successCallback={reload}
              companyAccounts={companyAccounts}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default BankRuleAddWidget;
