import React, { useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as options from "api/options";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { FormGroup } from "components/formik";
import useModal from "hooks/useModal";
import { DiscountModal } from "components/modals/invoices";
import { SubmitButton } from "components/ui/buttons";
import * as contractAPI from "api/contracts";
import { isVatDisabled, RecordsTool, RecordsForm } from "components/forms/CustomerInvoiceForm/helpers";
import { DeliveryMethodSwitcher } from "components/forms/CustomerInvoiceForm/CustomerInvoiceForm";
import { formatData } from "./helpers";

function ContractInvoiceTemplateForm({ company, invoice, onSave }) {
  const paymentTerms = options.paymentTermsOptionsWithoutDefault();
  const { t } = useTranslation("ci");
  const discountModal = useModal();
  const [connectedProducts, setConnectedProducts] = useState({});
  const [originalPrices, setOriginalPrices] = useState({});

  const formikProps = {
    initialValues: {
      ...invoice,
      payment_terms: options.paymentTerms.getOption(invoice.payment_terms) || paymentTerms[1],
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      contract_length: yup.number().required(),
      invoice_interval: yup.number().required(),
    }),
    onSubmit: async (values, { setErrors, setFieldError }) => {
      await contractAPI.templates
        .save(company.id, formatData(values))
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (onSave) {
            onSave(response.data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  return (
    <Card>
      <Formik {...formikProps}>
        {({ values, errors, setFieldValue, setTouched, setFieldError, isSubmitting }) => {
          return (
            <>
              <Form id="ciForm">
                <Card.Body>
                  <Row xl={4} sm={3}>
                    <Col>
                      <FormGroup.Input type="text" label={t("common:name")} name="name" required />
                    </Col>
                    {!values.ongoing && (
                      <Col>
                        <FormGroup.Input type="number" label={t("contractLength")} name="contract_length" required />
                      </Col>
                    )}
                    <Col>
                      <FormGroup.Input
                        type="number"
                        label={t("invoiceIntervalMonths")}
                        name="invoice_interval"
                        required
                      />
                    </Col>
                    <Col>
                      <FormGroup.SimpleSelect
                        name="payment_terms"
                        label={t("common:paymentTerms")}
                        options={paymentTerms}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>{t("common:options")}</label>
                      <FormGroup.Checkbox wrapperClass="mb-3" label={t("common:statuses.ongoing")} name="ongoing" />
                    </Col>
                  </Row>
                  <FieldArray
                    name="records"
                    render={(arrayHelper) => (
                      <>
                        <RecordsForm values={values} currency="SEK" arrayHelper={arrayHelper} t={t} />
                        <RecordsTool
                          companyId={company.id}
                          arrayHelper={arrayHelper}
                          currency="SEK"
                          vatDisabled={isVatDisabled(company, values)}
                          rotRutEnabled={false}
                          discountModal={discountModal}
                          rotRutModal={null}
                          ccProjModal={null}
                          centerOptions={[]}
                          projectOptions={[]}
                          prices={[]}
                          priceGroup="A"
                          connectedProducts={connectedProducts}
                          setConnectedProducts={setConnectedProducts}
                          originalPrices={originalPrices}
                          setOriginalPrices={setOriginalPrices}
                          forOffer={false}
                          t={t}
                          bookingDate={values.booking_date}
                        />
                      </>
                    )}
                  />
                  <Row>
                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label">{t("sendInvoiceBy")}</label>
                        <DeliveryMethodSwitcher
                          t={t}
                          deliveryMethod={values.delivery_method}
                          changeDeliveryMethod={(newMethod) => setFieldValue("delivery_method", newMethod)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <SubmitButton className="ml-2" isSubmitting={isSubmitting} />
                </Card.Footer>
              </Form>
              {discountModal.show && (
                <DiscountModal
                  show
                  currency="SEK"
                  records={values.records}
                  onSave={(records) => {
                    setFieldValue("records", records);
                    discountModal.close();
                  }}
                  onCancel={discountModal.close}
                />
              )}
            </>
          );
        }}
      </Formik>
    </Card>
  );
}

export default ContractInvoiceTemplateForm;
