import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import * as companyAPI from "api2/companies";
import { InvoicingForm } from "components/forms/company-settings-forms";
import { withInitialAsync } from "hooks/useAsync";

function InvoicingFormPage({ data: item }) {
  return (
    <Row>
      <Col>
        <InvoicingForm company={item} />
      </Col>
    </Row>
  );
}

const EnhancedInvoicingFormPage = withInitialAsync(
  InvoicingFormPage,
  () => {
    const { companyId } = useParams();
    return React.useCallback((cancelToken) => companyAPI.invoicingDetails(companyId, { cancelToken }), [companyId]);
  },
  {},
  true
);

export default EnhancedInvoicingFormPage;
