import React from "react";

import useTable from "components/tables/btable/useTable";
import AgencyScheduleTemplateForm from "./AgencyScheduleTemplateForm";

function AgencyScheduleTemplateEditWidget({ agencyId, scheduleTemplate }) {
  const {
    dataActions: { reload },
  } = useTable();

  return (
    <AgencyScheduleTemplateForm
      agencyId={agencyId}
      scheduleTemplate={scheduleTemplate}
      onSuccess={reload}
      onDelete={reload}
    />
  );
}

export default AgencyScheduleTemplateEditWidget;
