import React, { useCallback, useEffect } from "react";
import { useAuthState } from "hooks/useAuth";
import { freshchatRestoreIdUpdate } from "api2/auth";

function Freshchat({ token, externalId, restoreId, firstName, lastName, email, agenciesNames }) {
  const loadScript = useCallback(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://wchat.freshchat.com/js/widget.js";
    script.id = "freshchat";

    script.onload = () => {
      window.fcWidget.init({
        token,
        host: "https://wchat.freshchat.com",
        // host: "https://milosolutions-team-86a840692dc031b16909153.freshchat.com",
        externalId,
        restoreId: restoreId || null,
      });
      window.fcWidget.user.get(function (getResponse) {
        const getStatus = getResponse.status;
        if (getStatus !== 200) {
          window.fcWidget.user.setProperties({
            firstName,
            lastName,
            agencies: agenciesNames,
            email,
          });

          window.fcWidget.on("user:created", function (createResponse) {
            if (createResponse.status === 200 && createResponse.data && createResponse.data.restoreId) {
              freshchatRestoreIdUpdate(createResponse.data.restoreId).catch(() => {});
            }
          });
        }
      });

      window.fcWidget.on("unreadCount:notify", function (response) {
        setTimeout(() => {
          const title = document.title.replace(/\((\d+)\) /, "");
          localStorage.setItem("chat-count", response.count);
          if (response.count > 0) {
            document.title = `(${response.count}) ${title}`;
          } else {
            document.title = title;
          }
        }, 2000);
      });
    };
    document.head.appendChild(script);
  }, [token, firstName, lastName, externalId, restoreId, email, agenciesNames]);

  useEffect(() => {
    loadScript();
    return () => {
      // remove script
      if (window.fcWidget) {
        window.fcWidget.destroy();
      }
    };
  }, [loadScript]);
  return null;
}

function FreshchatWrapper() {
  const { user } = useAuthState();
  const token = process.env.REACT_APP_FRESHCHAT_TOKEN;
  if (!token || !user) {
    return null;
  }
  if (!user.is_consult) {
    return null;
  }
  const agenciesNames = user.agencies.map((o) => o.agency_name).join(", ");
  return (
    <Freshchat
      token={token}
      externalId={user.id}
      restoreId={user.freshchat_restore_id}
      email={user.email}
      firstName={user.first_name}
      lastName={user.last_name}
      agenciesNames={agenciesNames}
    />
  );
}

export default FreshchatWrapper;
