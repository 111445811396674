import React from "react";
import { Card } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";

import TableProvider from "components/tables/btable/provider";
import { AssetExtendedTable } from "components/tables";
import AssetAddWidget from "components/forms/AssetForm/AssetAddWidget";
import { PermCodeRequired } from "components/perms";
import { codesForInventoryRegister } from "components/perms/PermCodes";
import * as assetAPI from "api2/assets";

function AssetListPage({ selectable = false, tableId = "assetTable" }) {
  const company = useOutletContext();
  const draftsForVer = new URLSearchParams(window.location.search).get("draftsForVer");
  const initialFilters = draftsForVer
    ? {
        term: draftsForVer,
        mode: "draft",
      }
    : {};

  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) => assetAPI.list(company.id, filters, config)}
          initialFilters={initialFilters}
        >
          <Card>
            <Card.Body>
              <AssetExtendedTable companyId={company.id} selectable={selectable} />
            </Card.Body>
          </Card>
          <PermCodeRequired code={codesForInventoryRegister.manage}>
            <AssetAddWidget companyId={company.id} />
          </PermCodeRequired>
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default AssetListPage;
