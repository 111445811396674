import React, { useCallback, useEffect, useState } from "react";
import { humanFileSize } from "utils/text";
import client from "api/client";
import { useTranslation, withTranslation } from "react-i18next";
import axios from "axios";
import { Loader } from "components/ui/loaders";
import { Alert } from "react-bootstrap";
import loadImage from "blueimp-load-image";
import { getFileNameDispositionContent } from "utils/file";

function Document({ url, fileName, type, fullMode = false, rotation = 0 }) {
  const { t } = useTranslation("common");

  if (url) {
    if (["image/png", "image/jpeg"].includes(type)) {
      return <img id="test2" alt="Document" src={url} style={{ maxWidth: "100%" }} />;
    }
    if (["application/pdf"].includes(type)) {
      return (
        <object width="100%" style={{ height: fullMode ? "99%" : "50vh" }} data={url} type={type}>
          File can not be loaded
        </object>
      );
    }
  }

  return (
    <div className="mt-5 pl-2">
      <p>
        {t("errors.documentDisplayError")}{" "}
        {url && (
          <a href={url} rel="noreferrer" target="_blank">
            {t("actions.download")}
          </a>
        )}
      </p>
    </div>
  );
}

function FileInfo({ size, type, removable, onRemove, onRotate, extraActionData, link, fileName }) {
  const { t } = useTranslation("common");
  return (
    <div className="file-info">
      <div style={{ color: "green" }}>{t("saved")}</div>
      <div>{humanFileSize(size)}</div>
      <div>{type}</div>
      {type.includes("image") ? (
        <div className="rotate" onClick={onRotate}>
          <i className="fas fa-redo" /> {t("rotate")}
        </div>
      ) : null}
      <div>
        <a href={link} rel="noreferrer" download={fileName} target="_blank">
          <i className="fas fa-download" /> {t("actions.download")}
        </a>
      </div>
      {removable && (
        <div className="remove" onClick={(event) => onRemove(event)}>
          {t("actions.remove")}
        </div>
      )}
      {!!extraActionData && (
        <div
          className={extraActionData.divClassName}
          onClick={() => extraActionData.onAction(extraActionData.documentId)}
        >
          {extraActionData.actionName}
        </div>
      )}
    </div>
  );
}

function DocumentBox({
  documentId,
  companyId,
  removable = false,
  partUrl = "verification-documents",
  extraActionData = {},
}) {
  const { t } = useTranslation("common");
  const [data, setData] = useState({
    url: "",
    type: "",
    fileName: "",
    size: 0,
    loading: true,
    error: "",
  });
  const dataUrl = data ? data.url : null;
  useEffect(() => {
    const signal = axios.CancelToken.source();
    client
      .get(`/companies/${companyId}/${partUrl}/${documentId}/file/`, {
        responseType: "blob",
        cancelToken: signal.token,
      })
      .then((response) => {
        const blob = response.data;
        const fileName = getFileNameDispositionContent(response) || "";
        setData({
          url: window.URL.createObjectURL(blob),
          fileName,
          type: blob.type,
          size: blob.size,
          loading: false,
          error: "",
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setData({
            url: "",
            fileName: "",
            type: "",
            size: 0,
            rotation: 0,
            loading: false,
            error: t("common:file.cannotLoadFile"),
          });
        }
      });
    return () => {
      signal.cancel("aborted");
    };
  }, [companyId, documentId, partUrl, t]);

  const onRemove = useCallback(() => {
    const confirm = window.confirm("Are you sure?");
    if (confirm) {
      client.delete(`/companies/${companyId}/${partUrl}/${documentId}/`).then(() => setData(null));
    }
  }, [companyId, documentId, partUrl]);

  const onRotate = useCallback(() => {
    loadImage(
      dataUrl,
      (image) => {
        image.toBlob((blob) => {
          setData((_old) => ({
            url: window.URL.createObjectURL(blob),
            fileName: _old.fileName,
            type: blob.type,
            size: blob.size,
            loading: false,
            error: "",
          }));
        });
      },
      { meta: true, orientation: 6 }
    );
  }, [dataUrl]);

  if (!data) {
    return <div />;
  }
  return (
    <div className="document-box rotate90">
      {data.loading && <Loader />}
      {data.url && !data.loading && (
        <FileInfo
          type={data.type}
          size={data.size}
          removable={removable}
          onRemove={onRemove}
          onRotate={onRotate}
          link={data.url}
          extraActionData={extraActionData}
          fileName={data.fileName}
        />
      )}
      {data.url && (
        <Document url={data.url} fileName={data.fileName} type={data.type} rotation={data.rotation} fullMode={false} />
      )}
      {!data.loading && data.error && <Alert variant="danger">{data.error}</Alert>}
    </div>
  );
}

const NoDocumentBox = withTranslation("common")(({ t }) => (
  <div className="document-box document-empty">{t("common:file.noFile")}</div>
));

export { DocumentBox, Document, FileInfo, NoDocumentBox };
