import React from "react";

import { withInitialAsync } from "hooks/useAsync";
import * as companyAPI from "api2/companies";
import useTable from "components/tables/btable/useTable";
import CostCenterForm from "./CostCenterForm";

function CostCenterEditWidget({ data: item, companyId, id }) {
  const {
    dataActions: { reload, updateRow },
  } = useTable();

  const successCallback = (newData) => {
    updateRow(newData);
  };

  const deleteCallback = () => {
    reload();
  };
  const costCenter = {
    ...item,
    approver: item.approver ? { value: item.approver, label: item.approver_name } : null,
  };
  return (
    <CostCenterForm
      companyId={companyId}
      costCenter={costCenter}
      successCallback={successCallback}
      deleteCallback={deleteCallback}
    />
  );
}

const EnhancedCostCenterEditWidget = withInitialAsync(
  CostCenterEditWidget,
  ({ companyId, id }) =>
    React.useCallback(
      (cancelToken) => {
        return companyAPI.costCenters.details(companyId, id, { cancelToken });
      },
      [companyId, id]
    ),
  {},
  false
);

export default EnhancedCostCenterEditWidget;
