import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as statusAPI from "api2/status-list";
import { formatDate } from "utils/date";
import useInitialAsync from "hooks/useInitialAsync";
import { useAuthState } from "hooks/useAuth";

const StatusListStateContext = React.createContext(undefined);

function StatusListProvider({ section, children }) {
  const { user } = useAuthState();
  const [filters, setFilters] = useState({
    term: "",
    assignees: [user.id],
    customer_managers: [],
    view: section === "yearly" ? "rolling12" : "delivery",
    date_month: new Date(),
    section,
  });

  useEffect(() => {
    if (section !== filters.section) {
      setFilters((_filters) => ({ ..._filters, section, view: section === "yearly" ? "rolling12" : "delivery" }));
    }
  }, [section, filters.section]);

  const [options, setOptions] = useState({ extended: true });
  const getEvents = useCallback(
    ({ cancelToken }) => {
      return statusAPI.events.list(
        {
          ...filters,
          date_month: formatDate(filters.date_month),
        },
        { cancelToken }
      );
    },
    [filters]
  );
  const getTemplates = useCallback(
    ({ cancelToken }) => {
      return statusAPI.templates.list({ section: filters.section }, { cancelToken });
    },
    [filters.section]
  );

  const { loading: loadingTpl, item: templates } = useInitialAsync(
    ({ cancelToken }) => getTemplates({ cancelToken }),
    [],
    [getTemplates]
  );

  const {
    loading: loadingEvents,
    item: events,
    set: setEvents,
  } = useInitialAsync(({ cancelToken }) => getEvents({ cancelToken }), [], [getEvents]);

  const updateEvent = useCallback(
    (eventId, newEventData) => {
      setEvents(
        events.map((event) => {
          if (event.id === eventId) {
            return { ...event, ...newEventData };
          }
          return event;
        })
      );
    },
    [events, setEvents]
  );

  const stateValue = useMemo(
    () => ({
      loading: loadingTpl || loadingEvents,
      templates,
      events,
      updateEvent,
      filters,
      setFilters,
      options,
      setOptions,
    }),
    [loadingTpl, loadingEvents, templates, events, filters, setFilters, updateEvent, options, setOptions]
  );

  return <StatusListStateContext.Provider value={stateValue}>{children}</StatusListStateContext.Provider>;
}

export { StatusListStateContext };

export default StatusListProvider;
