import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as reconAPI from "api2/reconciliation";
import { formatDate } from "utils/date";
import { toast } from "react-toastify";
import { AUTO_SPECIFICATION_ACCOUNTS } from "./helpers";

function FetchSpecificationButton({ companyId, financialYear, reconciliationDate, accountNumber, onFetchSuccess }) {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const fetchSpecificationForAccount = React.useCallback(() => {
    setLoading(true);
    reconAPI.specifications
      .fetch(companyId, {
        financial_year: financialYear.id,
        date_from: formatDate(financialYear.date_start),
        date_to: reconciliationDate,
        account: accountNumber,
      })
      .then((response) => {
        if (response.status === 201) {
          if (onFetchSuccess) {
            onFetchSuccess(response.data);
          }
        } else {
          toast.error(t("reports:specNotGeneratedDuoToAmount0"));
        }
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [t, companyId, onFetchSuccess, financialYear.id, financialYear.date_start, reconciliationDate, accountNumber]);

  if (!AUTO_SPECIFICATION_ACCOUNTS.includes(accountNumber)) {
    return null;
  }
  return (
    <Button className="secondary" size="sm" onClick={fetchSpecificationForAccount} disabled={loading}>
      {loading ? (
        <span>
          <i className="fas fa-spinner fa-pulse" /> {t("actions.processing")}...
        </span>
      ) : (
        <span>
          <i className="fas fa-plus" /> {t("actions.fetch")}
        </span>
      )}
    </Button>
  );
}

export default FetchSpecificationButton;
