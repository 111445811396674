import React from "react";
import { Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { HintButton } from "components/ui/buttons";
import { formatMoney } from "utils/money";

function BflowAccountTable({ data }) {
  const { t } = useTranslation("reports");
  if (!data.settlementAccounts) {
    return null;
  }
  return (
    <Card>
      <Card.Body className="p-0">
        <Table responsive>
          <thead>
            <tr>
              <th>
                {t("dashboard.bflowAccounts")} <HintButton hint={t("dashboard.bflowAccountsHint")} />
              </th>
              <th className="text-right">SEK</th>
            </tr>
          </thead>
          <tbody>
            {data.settlementAccounts.map((employee, idx) => (
              <tr key={idx}>
                <td>
                  {employee.balance > 0
                    ? `${employee.name} ${t("dashboard.owesCompany")}`
                    : `${t("dashboard.companyOwes")} ${employee.name}`}
                </td>
                <td className="text-right">{formatMoney(Math.abs(employee.balance), 0, 0)}</td>
              </tr>
            ))}
            {!data.settlementAccounts.length && (
              <tr>
                <td colSpan={3}>{t("common:noResultsFound")}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

export default BflowAccountTable;
