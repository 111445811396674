import React, { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useResolvedPath } from "react-router-dom";

import { FilterButton } from "components/ui/buttons";
import useTable from "components/tables/btable/useTable";
import { CompanyEmployeeFilter, MonthOnlyDateFilter, SearchFilter, YearDateFilter } from "components/filters";
import { codesForSalaries } from "components/perms/PermCodes";
import { PermCodeRequired } from "components/perms";

function SalaryFilters({ companyId, forStatus, allowedModes }) {
  const { filters, setFilters } = useTable();
  const url = useResolvedPath("").pathname;
  const { t } = useTranslation("common");
  const filtersCount = !!filters.receiver + !!filters.payment_date;
  const [more, setMore] = useState(!!filtersCount);
  const show = {
    createBtn: !forStatus && !url.includes("/reports/"),
  };
  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        {forStatus && allowedModes && (
          <div className="status-filter btn-group">
            {allowedModes.map((mode) => (
              <Button
                key={mode.value}
                variant={mode.variant}
                active={filters.mode === mode.value}
                onClick={() => setFilters({ mode: mode.value })}
              >
                {t(mode.label)}
              </Button>
            ))}
          </div>
        )}
        {forStatus && !allowedModes && (
          <Button variant={forStatus} onClick={() => {}}>
            {t(`common:statuses.${forStatus}`)}
          </Button>
        )}
        {!forStatus && (
          <ButtonGroup aria-label="Status filter" className="status-filter btn-group">
            <Button
              variant="toggle"
              active={!filters.mode || filters.mode === "all"}
              onClick={() => setFilters({ mode: "" })}
            >
              {t("common:statuses.all")}
            </Button>
            <Button
              variant="toggle-orange"
              active={filters.mode === "prelim"}
              onClick={() => setFilters({ mode: "prelim" })}
            >
              {t("common:statuses.inApproval")}
            </Button>
            <Button
              variant="toggle-red"
              active={filters.mode === "unpaid"}
              onClick={() => setFilters({ mode: "unpaid" })}
            >
              {t("common:statuses.unpaid")}
            </Button>
            <Button
              variant="toggle-blue"
              active={filters.mode === "sent_bank"}
              onClick={() => setFilters({ mode: "sent_bank" })}
            >
              {t("common:statuses.sentBank")}
            </Button>
            <Button
              variant="toggle-green"
              active={filters.mode === "paid"}
              onClick={() => setFilters({ mode: "paid" })}
            >
              {t("common:statuses.paid")}
            </Button>
          </ButtonGroup>
        )}
      </section>
      <section className="table-filters-right">
        <PermCodeRequired code={codesForSalaries.manage}>
          {show.createBtn && (
            <ButtonGroup>
              <Link to={`${url}/form`} className="btn btn-blue">
                <i className="fas fa-plus" /> {t("common:actions.create")}
              </Link>
              <Link to={`${url}/aga-pdf-reduction`} className="btn btn-secondary">
                <i className="fas fa-undo" /> {t("salary:agaPdfReduction")}
              </Link>
              <Link to={`${url}/holiday-soc`} className="btn btn-secondary">
                <i className="fas fa-book-open" /> {t("salary:holidayAllSoc")}
              </Link>
            </ButtonGroup>
          )}
        </PermCodeRequired>
      </section>
      {more && (
        <section className="more-filters">
          <YearDateFilter
            label={t("common:dates.year")}
            defaultValue={filters.payment_year}
            onChange={(date) => setFilters({ payment_year: date })}
          />
          <MonthOnlyDateFilter
            label={t("common:dates.month")}
            defaultValue={filters.payment_month}
            onChange={(date) => setFilters({ payment_month: date })}
          />
          <CompanyEmployeeFilter
            label={t("common:employee")}
            isClearable
            companyId={companyId}
            name="receiver"
            valueField="user_id"
            onFilter={setFilters}
            defaultValue={filters.receiver}
          />
        </section>
      )}
    </>
  );
}

export default SalaryFilters;
