import React, { useContext, useEffect } from "react";
import { Form, Formik } from "formik";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import * as options from "api/options";
import * as companyAPI from "api2/companies";
import { AllError, ConsultPicker, FormGroup } from "components/formik";
import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import { OnboardingDispatchContext } from "pages/companies/OnboardingWizard/provider/OnboardingProvider";
import { SaveButton } from "pages/companies/OnboardingWizard/utils";
import "./CompanyInformationStep.scss";
import { withInitialAsync } from "hooks/useAsync";
import { formatDate, parseDate } from "utils/date";
import { isLastDayOfMonth } from "date-fns";
import { getBooleanValueAsString } from "components/formik/utils/booleanHelpers";
import VATPeriodModal from "components/forms/OfficeSupportForms/OfficeSupportAccountingForm/VATPeriodModal";
import useModal from "hooks/useModal";

function CompanyInformationStep({ companyId, agencyId, data: initialData, initialLoading }) {
  const { t } = useTranslation("company");
  const { fetchDetails } = useContext(OnboardingDispatchContext);
  const vatPeriodModal = useModal();

  useEffect(() => {
    if (!initialLoading) {
      fetchDetails();
    }
  }, [initialLoading, fetchDetails]);

  const refreshVATPeriod = async (setFieldValue, resetForm, values) => {
    try {
      const response = await companyAPI.onboardings.steps.companyInformation(companyId, agencyId);
      const { vat_report_period } = response.data;
      const newVatReportPeriod =
        options.vatReportOptions.getLabel(vat_report_period) || options.vatReportOptions.getLabel("none");

      setFieldValue("vat_report_period", newVatReportPeriod);
      resetForm({
        values: { ...values, vat_report_period: newVatReportPeriod },
        initialValues: { ...values, vat_report_period: newVatReportPeriod },
      });
    } catch (error) {
      setFieldValue("vat_report_period", options.vatReportOptions.getLabel("none"));
    }
  };

  return (
    <Formik
      initialValues={{
        name: initialData.name || "",
        org_no: initialData.org_no || "",
        company_type: options.companyTypes.getOption(initialData.company_type || "limited"),
        consult: initialData.consult && {
          value: initialData.consult,
          label: initialData.consult_name,
        },
        registered_aga: initialData.registered_aga,
        auditor: getBooleanValueAsString(initialData.auditor_enabled),
        vat_report_period:
          options.vatReportOptions.getLabel(initialData.vat_report_period) || options.vatReportOptions.getLabel("none"),
        eu_vat: options.euVATChoices.getOption(initialData.eu_vat),
        approved_company_tax: initialData.approved_company_tax,
        reconciliation_period: options.reconciliationPeriods.getOption(initialData.reconciliation_period),
        financial_year_date_start: parseDate(initialData.financial_year_date_start),
        financial_year_date_end: parseDate(initialData.financial_year_date_end),
      }}
      validationSchema={yup.object().shape({
        name: yup.string().required(),
        org_no: yup.string().required(),
        financial_year_date_start: yup.date().nullable().required(),
        financial_year_date_end: yup
          .date()
          .nullable()
          .required()
          .test("lastDayMonth", function (dateEnd) {
            if (dateEnd && !isLastDayOfMonth(dateEnd)) {
              return this.createError({
                path: this.path,
                message: t("msg:lastDayOfMonth"),
              });
            }
            return true;
          })
          .when("financial_year_date_start", ([startDate], schema) => {
            return startDate ? schema.min(startDate, t("common:errors.mustLaterStartDate")) : schema;
          }),
        consult: yup.object().nullable().required(),
      })}
      onSubmit={(values, { setErrors }) => {
        return companyAPI.onboardings.steps
          .companyInformationFinish(companyId, agencyId, {
            ...values,
            company_type: values.company_type.value,
            consult: values.consult.value,
            registered_aga: values.registered_aga,
            auditor: values.auditor,
            eu_vat: values.eu_vat.value,
            approved_company_tax: values.approved_company_tax,
            financial_year_date_start: formatDate(values.financial_year_date_start),
            financial_year_date_end: formatDate(values.financial_year_date_end),
            reconciliation_period: values.reconciliation_period.value,
          })
          .then(() => {
            fetchDetails(true);
          })
          .catch((error) => {
            setErrors(error.data);
          });
      }}
    >
      {({ isSubmitting, handleSubmit, errors, setFieldValue, resetForm, values }) => (
        <Card className="company-information-step" data-testid="company-information-step">
          <Card.Body>
            <StepHeader
              rightComponent={
                <SaveButton
                  disabled={isSubmitting}
                  onSave={handleSubmit}
                  withIcon={false}
                  buttonText={t("common:actions.saveAndContinue")}
                />
              }
            />
          </Card.Body>
          <Card.Body className="btn-toggle-grey">
            <CompanyInformationForm
              financialYearSet={!!initialData.financial_year_date_end}
              agencyId={agencyId}
              companyId={companyId}
              vatPeriodModal={vatPeriodModal}
              refreshVATPeriod={refreshVATPeriod}
              setFieldValue={setFieldValue}
              resetForm={resetForm}
              values={values}
              errors={errors}
            />
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
}

function CompanyInformationForm({
  financialYearSet,
  agencyId,
  companyId,
  vatPeriodModal,
  refreshVATPeriod,
  setFieldValue,
  resetForm,
  values,
  errors,
}) {
  const { t } = useTranslation("company");
  return (
    <Form noValidate>
      <Row className="mb-3">
        <Col>
          <Alert variant="info">{t("company:onboarding.infoUpdateNotice")}</Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup.Input label={t("common:companyName")} name="name" required />
        </Col>
        <Col>
          <FormGroup.LabeledInlineYesNoRadio label={t("registeredGeneralTax")} name="registered_aga" />
        </Col>
        <Col>
          <FormGroup.LabeledInlineYesNoRadio label={t("companyTax")} name="approved_company_tax" />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup.Input label={t("common:orgNo")} name="org_no" required disabled />
        </Col>
        <Col>
          <FormGroup.LabeledInlineRadio
            options={[
              { value: "1", label: t("common:actions.yes") },
              { value: "0", label: t("common:actions.no") },
            ]}
            label={t("auditor")}
            name="auditor"
          />
        </Col>
        <Col>
          <Row>
            <Col>
              <FormGroup.DatePicker
                label={`${t("common:financialYear")} ${t("common:starts")}`}
                name="financial_year_date_start"
                disabled={financialYearSet}
                isClearable={!financialYearSet}
                required
              />
            </Col>
            <Col>
              <FormGroup.DatePicker
                label={t("common:ends")}
                name="financial_year_date_end"
                disabled={financialYearSet}
                isClearable={!financialYearSet}
                required
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup.SimpleSelect
            label={t("company:companyType")}
            name="company_type"
            options={options.companyTypes.asList()}
          />
        </Col>
        <Col>
          <div className="d-flex">
            <FormGroup.Input label={t("company:vatReport")} name="vat_report_period" disabled />{" "}
            <Button variant="link" type="button" className="mt-1" onClick={() => vatPeriodModal.open()}>
              {t("common:actions.change")}
            </Button>
          </div>
        </Col>
        <Col>
          <FormGroup.SimpleSelect
            label={t("company:reconciliationPeriod")}
            name="reconciliation_period"
            options={options.reconciliationPeriods.asList()}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ConsultPicker agencyId={agencyId} label={t("common:customerManager")} name="consult" required />
        </Col>
        <Col>
          <FormGroup.SimpleSelect label={t("company:euVAT")} name="eu_vat" options={options.euVATChoices.asList()} />
        </Col>
        <Col />
      </Row>
      {vatPeriodModal.show && (
        <VATPeriodModal
          companyId={companyId}
          handleClose={() => {
            refreshVATPeriod(setFieldValue, resetForm, values);
            vatPeriodModal.close();
          }}
        />
      )}
      <AllError errors={errors} />
    </Form>
  );
}

const EnhancedCompanyInformationStep = withInitialAsync(
  CompanyInformationStep,
  ({ companyId, agencyId }) => {
    return React.useCallback(
      (cancelToken) => companyAPI.onboardings.steps.companyInformation(companyId, agencyId, { cancelToken }),
      [companyId, agencyId]
    );
  },
  {},
  true
);

export default EnhancedCompanyInformationStep;
