import React, { useContext, useMemo, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { FilterButton } from "components/ui/buttons";
import useTable from "components/tables/btable/useTable";
import { CompanyEmployeeFilter, DateRangeFilters, SearchFilter, SelectSimpleFilter } from "components/filters";
import { useCompanyState } from "hooks/useCompany";
import FileUrlModal from "components/modals/FileUrlModal/FileUrlModal";
import useModal from "hooks/useModal";
import qs from "qs";
import { formatDate } from "utils/date";
import { SelectionContext } from "state/providers/SelectionProvider";

function TimeReportFilters({ companyId }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");
  const filtersCount = !!filters.user + !!(filters.at_date__start || filters.at_date__end);
  const [more, setMore] = useState(!!filtersCount);
  const { selection } = useContext(SelectionContext);
  const pdfModal = useModal();
  const csvModal = useModal();
  const {
    projects: { asOptions: projectOptions },
  } = useCompanyState();

  const openPDFModal = () => {
    const params = qs.stringify({
      at_date__start: filters.at_date__start && formatDate(filters.at_date__start),
      at_date__end: filters.at_date__end && formatDate(filters.at_date__end),
      project: filters.project,
      mode: filters.mode,
      user: filters.user,
    });
    pdfModal.open(`/reports/time-reports/pdf/?${params}`);
  };

  const openCSVModal = () => {
    const params = qs.stringify({
      at_date__start: filters.at_date__start && formatDate(filters.at_date__start),
      at_date__end: filters.at_date__end && formatDate(filters.at_date__end),
      project: filters.project,
      mode: filters.mode,
      user: filters.user,
      ids: selection.timeReports ? Object.keys(selection.timeReports) : [],
    });
    csvModal.open(`/reports/time-reports/csv/?${params}`);
  };

  const allProjectOptions = useMemo(
    () => projectOptions.map((proj) => ({ ...proj, isDisabled: false })),
    [projectOptions]
  );
  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        <ButtonGroup aria-label="Status filter" className="status-filter">
          <Button
            variant="toggle"
            active={!filters.mode || filters.mode === "all"}
            onClick={() => setFilters({ mode: "" })}
          >
            {t("common:statuses.all")}
          </Button>
          <Button variant="toggle-grey" active={filters.mode === "draft"} onClick={() => setFilters({ mode: "draft" })}>
            {t("common:statuses.draft")}
          </Button>
          <Button
            variant="toggle-green"
            active={filters.mode === "approved"}
            onClick={() => setFilters({ mode: "approved" })}
          >
            {t("common:statuses.invoiced")}
          </Button>
        </ButtonGroup>
      </section>
      <section className="table-filters-right">
        <ButtonGroup>
          <Button variant="secondary" onClick={openPDFModal}>
            <i className="fas fa-file-pdf" /> {t("reports:reportPDF")}
          </Button>
          <Button variant="secondary" onClick={openCSVModal}>
            <i className="fas fa-file-csv" /> {t("reports:reportCSV")}
          </Button>
        </ButtonGroup>
      </section>
      {more && (
        <section className="more-filters">
          <CompanyEmployeeFilter
            label={t("common:employee")}
            isClearable
            valueField="user_id"
            companyId={companyId}
            name="user"
            onFilter={setFilters}
            defaultValue={filters.receiver}
          />
          <SelectSimpleFilter
            name="project"
            label={t("common:project")}
            options={allProjectOptions}
            defaultValue={filters.project}
            isClearable
            onFilter={setFilters}
          />
          <DateRangeFilters
            label={t("common:dates.date")}
            dateStart={filters.at_date__start}
            dateEnd={filters.at_date__end}
            onChange={({ start, end }) => setFilters({ at_date__start: start, at_date__end: end })}
          />
        </section>
      )}
      {pdfModal.show && <FileUrlModal companyId={companyId} fileUrl={pdfModal.data} handleClose={pdfModal.close} />}
      {csvModal.show && (
        <FileUrlModal
          companyId={companyId}
          fileUrl={csvModal.data}
          downloadFileName="time_log_report.csv"
          handleClose={csvModal.close}
        />
      )}
    </>
  );
}

export default TimeReportFilters;
