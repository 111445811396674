export const AGENCY_PERMISSION_ROLES = ["officeSupport", "standardAccess", "salaryAccess"];

export const AGENCY_PERMISSION_MODULES = [
  {
    label: {
      en: "Office support",
      sv: "Byråstödet",
    },
    id: 0,
    children: [
      {
        code: "OS00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "officeSupport",
      },
    ],
  },
  {
    label: {
      en: "Company archive",
      sv: "Företagsarkiv",
    },
    id: 1,
    children: [
      {
        code: "CA00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "officeSupport",
      },
      {
        code: "CA01",
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        role: "officeSupport",
      },
      {
        code: "CA03",
        label: {
          en: "Delete",
          sv: "Ta bort",
        },
        role: "officeSupport",
      },
    ],
  },
  {
    label: {
      en: "Verifications",
      sv: "Verifikationer",
    },
    id: 2,
    children: [
      {
        code: "V00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "standardAccess",
      },
      {
        code: "V01",
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        role: "standardAccess",
      },
    ],
  },
  {
    label: {
      en: "Supplier Invoices",
      sv: "Leverantörsfakturor",
    },
    id: 3,
    children: [
      {
        code: "S00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "standardAccess",
      },
      {
        code: "S01",
        label: {
          en: "Create",
          sv: "Skapa",
        },
        role: "standardAccess",
      },
      {
        code: "S02",
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        role: "standardAccess",
      },
      {
        code: "S05",
        label: {
          en: "Book payment",
          sv: "Bokför betalning",
        },
        role: "standardAccess",
      },
      {
        code: "S06",
        label: {
          en: "Credit Invoice",
          sv: "Kreditfaktura",
        },
        role: "standardAccess",
      },
      {
        code: "S07",
        label: {
          en: "Cancel payment",
          sv: "Avbryt betalning",
        },
        role: "standardAccess",
      },
      {
        code: "S08",
        label: {
          en: "Local suppliers",
          sv: "Lokala leverantörer",
        },
        role: "standardAccess",
      },
    ],
  },
  {
    label: {
      en: "Outlays",
      sv: "Utlägg",
    },
    id: 4,
    children: [
      {
        code: "O00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "standardAccess",
      },
      {
        code: "O01",
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        role: "standardAccess",
      },
      {
        code: "O04",
        label: {
          en: "Book payment",
          sv: "Bokför betalning",
        },
        role: "standardAccess",
      },
      {
        code: "O05",
        label: {
          en: "Cancel payment",
          sv: "Avbryt betalning",
        },
        role: "standardAccess",
      },
    ],
  },
  {
    label: {
      en: "Customer invoices",
      sv: "Kundfakturor",
    },
    id: 5,
    children: [
      {
        code: "C00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "standardAccess",
      },
      {
        code: "C01",
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        role: "standardAccess",
      },
      {
        code: "C02",
        label: {
          en: "Book payment",
          sv: "Bokför betalning",
        },
        role: "standardAccess",
      },
      {
        code: "C03",
        label: {
          en: "Contract invoice",
          sv: "Avtalsfakturering",
        },
        role: "standardAccess",
      },
      {
        code: "C04",
        label: {
          en: "Offer and order",
          sv: "Order & Offert",
        },
        role: "standardAccess",
      },
    ],
  },
  {
    label: {
      en: "Direct payments",
      sv: "Direktbetalningar",
    },
    id: 6,
    children: [
      {
        code: "DP00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "standardAccess",
      },
      {
        code: "DP01",
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        role: "standardAccess",
      },
      {
        code: "DP03",
        label: {
          en: "Cancel payment",
          sv: "Avbryt betalning",
        },
        role: "standardAccess",
      },
      {
        code: "DP04",
        label: {
          en: "Book payment",
          sv: "Bokför betalning",
        },
        role: "standardAccess",
      },
    ],
  },
  {
    label: {
      en: "Taxes",
      sv: "Skatter",
    },
    id: 7,
    children: [
      {
        code: "T00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "standardAccess",
      },
      {
        code: "T01",
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        role: "standardAccess",
      },
      {
        code: "T03",
        label: {
          en: "Cancel payment",
          sv: "Avbryt betalning",
        },
        role: "standardAccess",
      },
      {
        code: "T04",
        label: {
          en: "Book payment",
          sv: "Bokför betalning",
        },
        role: "standardAccess",
      },
    ],
  },
  {
    label: {
      en: "Time reports",
      sv: "Tidrapporter",
    },
    id: 8,
    children: [
      {
        code: "TR00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "standardAccess",
      },
      {
        code: "TR01",
        label: {
          en: "Approve",
          sv: "Godkänna",
        },
        role: "standardAccess",
      },
    ],
  },
  {
    label: {
      en: "File archive",
      sv: "Filarkiv",
    },
    id: 9,
    children: [
      {
        code: "FA00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "standardAccess",
      },
      {
        code: "FA01",
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        role: "standardAccess",
      },
    ],
  },
  {
    label: {
      en: "Upload",
      sv: "Ladda upp",
    },
    id: 10,
    children: [
      {
        code: "UP00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "standardAccess",
      },
    ],
  },
  {
    label: {
      en: "Company settings",
      sv: "Företagsinställningar",
    },
    id: 11,
    children: [
      {
        code: "CS00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "standardAccess",
      },
      {
        code: "CS01",
        label: {
          en: "Edit",
          sv: "Ändra",
        },
        role: "standardAccess",
      },
      {
        code: "CS02",
        label: {
          en: "Account plan",
          sv: "Kontoplan",
        },
        role: "standardAccess",
      },
      {
        code: "CS03",
        label: {
          en: "Bank connection",
          sv: "Bankförbindelse",
        },
        role: "standardAccess",
      },
      {
        code: "CS04",
        label: {
          en: "Time tracker",
          sv: "Time tracker",
        },
        role: "standardAccess",
      },
      {
        code: "CS05",
        label: {
          en: "Bank matching rules",
          sv: "Bankmatchningsregler",
        },
        role: "standardAccess",
      },
      {
        code: "CS06",
        label: {
          en: "SIE file import",
          sv: "Import av SIE",
        },
        role: "standardAccess",
      },
      {
        code: "CS07",
        label: {
          en: "Export",
          sv: "Export",
        },
        role: "standardAccess",
      },
      {
        code: "CS08",
        label: {
          en: "Processing History",
          sv: "Användarhistorik",
        },
        role: "standardAccess",
      },
    ],
  },
  {
    label: {
      en: "Employees",
      sv: "Anställda",
    },
    id: 12,
    children: [
      {
        code: "CS12",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "standardAccess",
      },
      {
        code: "CS13",
        label: {
          en: "Create",
          sv: "Skapa",
        },
        role: "standardAccess",
      },
      {
        code: "CS14",
        label: {
          en: "Edit",
          sv: "Ändra",
        },
        role: "standardAccess",
      },
      {
        code: "CS15",
        label: {
          en: "Delete",
          sv: "Ta bort",
        },
        role: "standardAccess",
      },
      {
        code: "CS16",
        label: {
          en: "Edit permissions",
          sv: "Ändra behörigheter",
        },
        role: "standardAccess",
      },
      {
        code: "CS17",
        label: {
          en: "Edit schedules",
          sv: "Ändra scheman",
        },
        role: "standardAccess",
      },
    ],
  },
  {
    label: {
      en: "Auditors",
      sv: "Revisorer",
    },
    id: 13,
    children: [
      {
        code: "CS18",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "standardAccess",
      },
      {
        code: "CS19",
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        role: "standardAccess",
      },
    ],
  },
  {
    label: {
      en: "Financial years",
      sv: "Räkenskapsår",
    },
    id: 14,
    children: [
      {
        code: "FY00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "standardAccess",
      },
      {
        code: "FY01",
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        role: "standardAccess",
      },
      {
        code: "FY02",
        label: {
          en: "Export SIE",
          sv: "Export av SIE",
        },
        role: "standardAccess",
      },
    ],
  },
  {
    label: {
      en: "Reports",
      sv: "Rapporter",
    },
    id: 15,
    children: [
      {
        code: "R00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "standardAccess",
      },
      {
        code: "R01",
        label: {
          en: "Ledger",
          sv: "Huvudbok",
        },
        role: "standardAccess",
      },
      {
        code: "R02",
        label: {
          en: "Reconciliation",
          sv: "Avstämning",
        },
        role: "standardAccess",
      },
    ],
  },
  {
    label: {
      en: "Inventory register",
      sv: "Anläggningsregister",
    },
    id: 16,
    children: [
      {
        code: "IR00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "standardAccess",
      },
      {
        code: "IR01",
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        role: "standardAccess",
      },
    ],
  },
  {
    label: {
      en: "Consult manage",
      sv: "Konsultbehörighet",
    },
    id: 17,
    children: [
      {
        code: "X00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "standardAccess",
      },
    ],
  },
  {
    label: {
      en: "Salaries",
      sv: "Löner",
    },
    id: 18,
    children: [
      {
        code: "SAL00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "salaryAccess",
      },
      {
        code: "SAL01",
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        role: "salaryAccess",
      },
      {
        code: "SAL04",
        label: {
          en: "Book payment",
          sv: "Bokför betalning",
        },
        role: "salaryAccess",
      },
      {
        code: "SAL05",
        label: {
          en: "Cancel payment",
          sv: "Avbryt betalning",
        },
        role: "salaryAccess",
      },
      {
        code: "SAL06",
        label: {
          en: "Salary archive",
          sv: "Lönearkiv",
        },
        role: "salaryAccess",
      },
    ],
  },
  {
    label: {
      en: "Employee absences",
      sv: "Avvikelserapporter",
    },
    id: 19,
    children: [
      {
        code: "EA00",
        label: {
          en: "Enabled",
          sv: "Se",
        },
        role: "salaryAccess",
      },
      {
        code: "EA02",
        label: {
          en: "Download",
          sv: "Ladda ned",
        },
        role: "salaryAccess",
      },
    ],
  },
];
