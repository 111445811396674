import React, { useCallback } from "react";
import { Formik, Form, FieldArray } from "formik";
import { Card, Table } from "react-bootstrap";
import TableGroup from "components/formik/TableGroup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { formatDate } from "utils/date";
import * as companyAPI from "api2/companies";
import * as yup from "yup";
import { handleFormErrors } from "api/errors";
import useAsync from "hooks/useAsync";
import { SaveButton } from "../../utils";
import { Loader } from "../../../../../components/ui/loaders";

export default function FortnoxIncorrectProjectsTable({ companyId, agencyId, reloadStep }) {
  const { t } = useTranslation("company");
  const dataSource = useCallback(
    (cancelToken) => companyAPI.onboardings.steps.fortnoxDataProjectsRecheck(companyId, agencyId, { cancelToken }),
    [companyId, agencyId]
  );

  const [{ data, loading }, { reload }] = useAsync(dataSource, []);

  if (loading) {
    return <Loader />;
  }

  const validationSchema = yup.object().shape({
    projects: yup.array().of(
      yup.object().shape({
        ProjectNumber: yup.string().required(),
        Description: yup.string().required(),
        StartDate: yup.date().required(),
        EndDate: yup.date().nullable(),
      })
    ),
  });

  if (data?.incorrect_count === 0) return null;
  return (
    <Formik
      initialValues={{ projects: data.incorrect }}
      enableReinitialize
      onSubmit={(values, { setSubmitting, setErrors }) => {
        const projects = values.projects.map((project) => ({
          ...project,
          StartDate: formatDate(project.StartDate),
          EndDate: formatDate(project.EndDate),
        }));
        companyAPI.onboardings.steps
          .fortnoxDataProjectsUpdate(companyId, agencyId, projects)
          .then(() => {
            toast.success(t("msg:updated"));
            reload();
            reloadStep();
          })
          .catch((error) => {
            handleFormErrors(error, setErrors);
          });

        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({ values, errors, touched, isSubmitting }) => (
        <Form>
          <Card.Body key="projects" className="mb-1">
            <Card.Title>{t("navigation:projects")}</Card.Title>
            <FieldArray name="projects">
              {() => (
                <>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>{t("serialOrNumber")}</th>
                        <th>{t("common:name")}</th>
                        <th>{t("common:starts")}</th>
                        <th>{t("common:ends")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.projects && values.projects.length > 0 ? (
                        values.projects.map((project, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <TableGroup.Input name={`projects[${index}].ProjectNumber`} required />
                              <TableGroup.Input name={`projects[${index}].Description`} />
                              <TableGroup.DatePicker name={`projects[${index}].StartDate`} />
                              <TableGroup.DatePicker name={`projects[${index}].EndDate`} />
                            </tr>
                            <TableGroup.RowErrors errors={project.errors} cols={4} />
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            {t("common:noResultsFound")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <SaveButton disabled={isSubmitting} />
                </>
              )}
            </FieldArray>
          </Card.Body>
        </Form>
      )}
    </Formik>
  );
}
