import client from "api2/client";

export function companyDirectPayments(companyId, filters, config) {
  return client.get(`/direct-payments/`, {
    params: filters,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function save(companyId, data, config) {
  return client.post(`/direct-payments/create/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function remove(companyId, dpId, params, config) {
  return client.delete(`/direct-payments/${dpId}/delete/`, {
    params,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function manualPay(companyId, dpId, data, config) {
  return client.post(`/direct-payments/${dpId}/payments/pay-manual/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function cancelPayment(companyId, dpId, config) {
  return client.delete(`/direct-payments/${dpId}/payments/cancel/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function pay(companyId, dpId, bankIDToken, config) {
  return client.post(
    `/direct-payments/${dpId}/payments/pay-bgc/`,
    {
      bank_id_token: bankIDToken,
    },
    {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }
  );
}

export function history(companyId, dpId, config) {
  return client.get(`/direct-payments/${dpId}/history/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}
