import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { SearchFilter } from "components/filters";
import useTable from "components/tables/btable/useTable";
import useModal from "hooks/useModal";
import { FileUrlModal } from "../../modals";

function AccountPlanFilters({ companyId }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("company");
  const accountPlanPDFModal = useModal();
  const openAccountPlanPDFModal = async () => {
    return accountPlanPDFModal.open(`/companies/config/accounts/pdf/`);
  };

  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} />
        <ButtonGroup>
          <Button
            variant="switch"
            active={!filters.mode || filters.mode === "all"}
            onClick={() => setFilters({ mode: "" })}
          >
            {t("common:statuses.all")}
          </Button>
          <Button variant="switch" active={filters.mode === "B"} onClick={() => setFilters({ mode: "B" })}>
            {t("assets")}
          </Button>
          <Button variant="switch" active={filters.mode === "C"} onClick={() => setFilters({ mode: "C" })}>
            {t("liabilities")}
          </Button>
          <Button variant="switch" active={filters.mode === "D"} onClick={() => setFilters({ mode: "D" })}>
            {t("revenues")}
          </Button>
          <Button variant="switch" active={filters.mode === "E"} onClick={() => setFilters({ mode: "E" })}>
            {t("expenses")}
          </Button>
        </ButtonGroup>
      </section>
      <section className="table-filters-right">
        <ButtonGroup>
          <Button variant="secondary" onClick={openAccountPlanPDFModal}>
            <i className="fas fa-file-pdf" /> {t("reports:accountPlanPDF")}
          </Button>
        </ButtonGroup>
      </section>
      {accountPlanPDFModal.show && (
        <FileUrlModal
          show
          fileUrl={accountPlanPDFModal.data}
          companyId={companyId}
          handleClose={accountPlanPDFModal.close}
        />
      )}
    </>
  );
}

export default AccountPlanFilters;
