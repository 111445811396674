export const bicMapper = {
  AACSDE33: { name: "SPARKASSE AACHEN", country: "GERMANY" },
  ABBYGB2L: { name: "Santander UK plc", country: "UNITED KINGDOM" },
  ABCADEFF: { name: "ARAB BANKING CORPORATION SA FRANKFURT BRANCH", country: "GERMANY" },
  ABCEGB2L: { name: "ABC INTERNATIONAL BANK PLC", country: "UNITED KINGDOM" },
  ABCOBHBM: { name: "ARAB BANKING CORPORATION BSC", country: "BAHRAIN" },
  ABCOFRPP: { name: "ARAB BANKING CORPORATION SA", country: "FRANCE" },
  ABCOITMM: { name: "ARAB BANKING CORPORATION SA MILAN BRANCH", country: "ITALY" },
  ABCOUS33: { name: "ARAB BANKING CORPORATION", country: "UNITED STATES" },
  ABDIAEAD: { name: "ABU DHABI ISLAMIC BANK", country: "UNITED ARAB EMIRATES" },
  ABDIIQBA: { name: "ABU DHABI ISLAMIC BANK", country: "IRAQ" },
  ABKKAEAD: { name: "AL AHLI BANK OF KUWAIT K.S.C.P DUBAI BRANCH", country: "UNITED ARAB EMIRATES" },
  ABKKKWKW: { name: "AL AHLI BANK OF KUWAIT K.S.C.P", country: "KUWAIT" },
  ABMZMZMA: { name: "ABSA BANK MOCAMBIQUE, SA", country: "MOZAMBIQUE" },
  ABNAINBB: { name: "NATWEST MARKETS PLC, INDIA BRANCH", country: "INDIA" },
  ABNANL2A: { name: "ABN AMRO BANK N.V", country: "NETHERLANDS" },
  ABNASG2A: { name: "ABN AMRO BANK N.V, SINGAPORE BRANCH", country: "SINGAPORE" },
  ABNCNL2A: { name: "ABN AMRO CLEARING BANK N.V.", country: "NETHERLANDS" },
  ABNGGB2L: { name: "THE ACCESS BANK UK LIMITED", country: "UNITED KINGDOM" },
  ABNGNGLA: { name: "ACCESS BANK PLC", country: "NIGERIA" },
  ABOCCNBJ: { name: "THE AGRICULTURAL BANK OF CHINA", country: "CHINA" },
  ABOCDEFF: { name: "AGRICULTURAL BANK OF CHINA FRANKFURT BRANCH", country: "GERMANY" },
  ABOCHKHH: { name: "AGRICULTURAL BANK OF CHINA LIMITED HONG KONG BRANCH", country: "HONG KONG" },
  ABPAPKKA: { name: "ALLIED BANK LIMITED ABL Head Office (HOL), 6th Floor", country: "PAKISTAN" },
  ABQQQAQA: { name: "Ahli Bank QSC", country: "QATAR" },
  ABSAZAJJ: { name: "ABSA BANK LIMITED", country: "SOUTH AFRICA" },
  ABSBBA22: { name: "Sparkasse Bank dd BiH", country: "BOSNIA AND HERZEGOVINA" },
  ABSGCHGG: { name: "S.P. HINDUJA BANQUE PRIVEE SA", country: "SWITZERLAND" },
  ACCCGHAC: { name: "CALBANK PLC", country: "GHANA" },
  ACLBKHPP: { name: "ACLEDA BANK PLC. Mr Cheam Teang", country: "CAMBODIA" },
  ADCBAEAA: { name: "ABU DHABI COMMERCIAL BANK", country: "UNITED ARAB EMIRATES" },
  ADIBUS33: { name: "FIRST ABU DHABI BANK USA N.V.", country: "UNITED STATES" },
  ADICAEAA: { name: "ABU DHABI INVESTMENT COMPANY", country: "UNITED ARAB EMIRATES" },
  AEBAGRAA: { name: "AEGEAN BALTIC BANK S.A.", country: "GREECE" },
  AEFAUS44: { name: "COLUMBIA MANAGEMENT INVESTMENT ADVISERS, LLC", country: "UNITED STATES" },
  AEGBRUMM: { name: "AMERICAN EXPRESS BANK LLC", country: "RUSSIAN FEDERATION" },
  AFEIUS6L: { name: "ASSOCIATED FOREIGN EXCHANGE, INC", country: "UNITED STATES" },
  AFIBAFKA: { name: "AFGHANISTAN INTERNATIONAL BANK", country: "AFGHANISTAN" },
  AFIRMXMT: { name: "BANCA AFIRME S.A.", country: "MEXICO" },
  AFIUCOBB: { name: "ALIANZA FIDUCIARIA S.A.", country: "COLOMBIA" },
  AFRIBFBF: { name: "BANK OF AFRICA - BURKINA FASO", country: "BURKINA FASO" },
  AFRIBJBJ: { name: "BANK OF AFRICA - BENIN", country: "BENIN" },
  AFRICIAB: { name: "BANK OF AFRICA COTE D'IVOIRE", country: "COTE D'IVOIRE" },
  AFRIKENX: { name: "BANK OF AFRICA KENYA LTD", country: "KENYA" },
  AFRIMGMG: { name: "BANK OF AFRICA MADAGASCAR", country: "MADAGASCAR" },
  AFRINENI: { name: "BANK OF AFRICA NIGER", country: "NIGER" },
  AFRISNDA: { name: "BANK OF AFRICA-SENEGAL Residence Excellence", country: "SENEGAL" },
  AFRITGTG: { name: "BANK OF AFRICA TOGO", country: "TOGO" },
  AFRIUGKA: { name: "BANK OF AFRICA - UGANDA LTD.", country: "UGANDA" },
  AGBACZPP: { name: "MONETA MONEY BANK, A.S.", country: "CZECHIA" },
  AGBLLT2X: { name: "LUMINOR BANK AS LITHUANIAN BRANCH", country: "LITHUANIA" },
  AGBMDEMM: { name: "AIRBUS BANK GMBH", country: "GERMANY" },
  AGMOMNUB: { name: "KHAN BANK LLC", country: "MONGOLIA" },
  AGRICHGG: { name: "CA INDOSUEZ (SWITZERLAND) SA", country: "SWITZERLAND" },
  AGRIEGCX: { name: "CREDIT AGRICOLE EGYPT", country: "EGYPT" },
  AGRIFRPP: { name: "CREDIT AGRICOLE SA", country: "FRANCE" },
  AGRIGPGX: { name: "CREDIT AGRICOLE SA", country: "FRANCE" },
  AGRILULA: { name: "CA INDOSUEZ WEALTH (EUROPE)", country: "LUXEMBOURG" },
  AGRIMQMX: { name: "CREDIT AGRICOLE SA", country: "FRANCE" },
  AGRIPLPR: { name: "CREDIT AGRICOLE BANK POLSKA S.A.", country: "POLAND" },
  AGRIRERX: { name: "CREDIT AGRICOLE SA", country: "FRANCE" },
  AGRIUAUK: { name: "CREDIT AGRICOLE BANK JSC", country: "UKRAINE" },
  AIBKGB2X: { name: "ALLIED IRISH BANKS PLC", country: "UNITED KINGDOM" },
  AIBKIE2D: { name: "AIB, PUBLIC LIMITED COMPANY", country: "IRELAND" },
  AKBKTRIS: { name: "AKBANK T.A.S.", country: "TURKEY" },
  AKROITMM: { name: "BANCA AKROS SPA", country: "ITALY" },
  ALBISARI: { name: "BANK AL BILAD", country: "SAUDI ARABIA" },
  ALBPPLPW: { name: "ALIOR BANK SPOLKA AKCYJNA", country: "POLAND" },
  ALETITMM: { name: "BANCA ALETTI E C. S.P.A.", country: "ITALY" },
  ALEXEGCX: { name: "BANK OF ALEXANDRIA", country: "EGYPT" },
  ALFARUMM: { name: "ALFA-BANK", country: "RUSSIAN FEDERATION" },
  ALFAUAUK: { name: "JSC ALFA-BANK", country: "UKRAINE" },
  ALFBTRIS: { name: "ALTERNATIFBANK A.S", country: "TURKEY" },
  ALLFESMM: { name: "ALLFUNDS BANK", country: "SPAIN" },
  ALLFITMM: { name: "ALLFUNDS BANK, ITALIAN BRANCH", country: "ITALY" },
  ALLFLULL: { name: "ALLFUNDS BANK INTERNATIONAL S.A.", country: "LUXEMBOURG" },
  ALLFSGSG: { name: "ALLFUNDS SINGAPORE BRANCH", country: "SINGAPORE" },
  ALMNKZKA: { name: "FIRST HEARTLAND JUSAN BANK JOINT-STOCK COMPANY", country: "KAZAKHSTAN" },
  AMAMPYPA: { name: "BANCO BASA S.A.", country: "PARAGUAY" },
  AMCNGTGT: { name: "BANCO DE AMERICA CENTRAL SOCIEDAD ANOMINA", country: "GUATEMALA" },
  ANTSGB2L: { name: "Santander UK plc", country: "UNITED KINGDOM" },
  ANZBAU3M: { name: "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED", country: "AUSTRALIA" },
  ANZBCNSH: { name: "AUSTRALIA AND NEW ZEALAND BANK (CHINA) COMPANY LIMITED", country: "CHINA" },
  ANZBFJFX: { name: "AUSTRALIA & NEW ZEALAND BANKING GROUP LIMITED", country: "FIJI" },
  ANZBGB2L: { name: "AUSTRALIA & NEW ZEALAND BANKING GROUP", country: "UNITED KINGDOM" },
  ANZBGUGG: { name: "ANZ GUAM, INC. (FORMERLY:CITIZENS SECURITY BANK (GUAM),INC)", country: "GUAM" },
  ANZBHKHX: { name: "AUSTRALIA & NEW ZEALAND BANKING GROUP LIMITED", country: "HONG KONG" },
  ANZBIDJX: { name: "BANK ANZ INDONESIA, P.T.", country: "INDONESIA" },
  ANZBINBX: { name: "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED", country: "INDIA" },
  ANZBJPJX: { name: "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED", country: "JAPAN" },
  ANZBKRSX: { name: "AUSTRALIA & NEW ZEALAND BANKING GROUP LTD", country: "KOREA, REPUBLIC OF" },
  ANZBLALA: {
    name: "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED,LAO BRANCH",
    country: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
  },
  ANZBMMMY: { name: "AUSTRALIA AND NEWZEALAND BANKING GROUP LIMITED", country: "MYANMAR" },
  ANZBNZ22: { name: "ANZ BANK NEW ZEALAND LIMITED", country: "NEW ZEALAND" },
  ANZBPGPX: {
    name: "AUSTRALIA & NEW ZEALAND BANKING GROUP (PNG) LTD",
    country: "PAPUA NEW GUINEA, INDEPENDENT STATE OF",
  },
  ANZBPHMX: { name: "AUSTRALIA & NEW ZEALAND BANKING GP (PHILIPPINES) LTD", country: "PHILIPPINES" },
  ANZBSBSB: { name: "AUSTRALIA & NEW ZEALAND BANKING GROUP LIMITED", country: "SOLOMON ISLANDS" },
  ANZBSGSX: { name: "AUSTRALIA & NEW ZEALAND BANKING GROUP LTD.", country: "SINGAPORE" },
  ANZBTHBK: { name: "ANZ BANK (THAI) PUBLIC COMPANY LIMITED", country: "THAILAND" },
  ANZBTLDI: { name: "AUSTRALIA AND NEW ZEALAND BANKING GROUP LTD", country: "TIMOR-LESTE" },
  ANZBTONN: { name: "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED", country: "TONGA" },
  ANZBTWTP: {
    name: "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED, TAIPEI BRANCH",
    country: "TAIWAN, REPUBLIC OF CHINA",
  },
  ANZBUS33: { name: "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED", country: "UNITED STATES" },
  ANZBVNVX: { name: "ANZ Bank (Vietnam) Limited", country: "VIET NAM" },
  ANZBVUVX: { name: "ANZ BANK (VANUATU) LIMITED", country: "VANUATU" },
  ANZBWSWW: { name: "ANZ BANK (SAMOA) LTD", country: "SAMOA, INDEPENDENT STATE OF" },
  APAHGB2L: { name: "ALPHA FX LIMITED", country: "UNITED KINGDOM" },
  APAHMTMT: { name: "ALPHA FX EUROPE LIMITED", country: "MALTA" },
  ARABAEAD: { name: "ARAB BANK PLC", country: "UNITED ARAB EMIRATES" },
  ARABBHBM: { name: "ARAB BANK PLC", country: "BAHRAIN" },
  ARABDEFF: { name: "EUROPE ARAB BANK SA FRANKFURT BRANCH", country: "GERMANY" },
  ARABGB2L: { name: "EUROPE ARAB BANK", country: "UNITED KINGDOM" },
  ARABJOAX: { name: "ARAB BANK PLC", country: "JORDAN" },
  ARABQAQA: { name: "ARAB BANK PLC", country: "QATAR" },
  ARAIEGCX: { name: "ARAB AFRICAN INTERNATIONAL BANK (HEAD OFFICE)", country: "EGYPT" },
  ARBSCHZZ: { name: "ARAB BANK (SWITZERLAND) LTD", country: "SWITZERLAND" },
  AREBESMM: { name: "ARESBANK S.A", country: "SPAIN" },
  ARMIAM22: { name: "AMERIABANK CJSC (FORMERLY ARMIMPEXBANK)", country: "ARMENIA" },
  ARMJAM22: { name: "VTB BANK (ARMENIA) CJSC", country: "ARMENIA" },
  ARNBSARI: { name: "ARAB NATIONAL BANK", country: "SAUDI ARABIA" },
  ARONUS33: { name: "J. ARON AND COMPANY LLC", country: "UNITED STATES" },
  ASBBNZ2A: { name: "ASB BANK LIMITED", country: "NEW ZEALAND" },
  ASPKAT2L: { name: "ALLGEMEINE SPARKASSE OBEROESTERREICH BANK AG", country: "AUSTRIA" },
  ATBFBFBF: { name: "BANQUE ATLANTIQUE BURKINA FASO", country: "BURKINA FASO" },
  ATBJBJBJ: { name: "BANQUE ATLANTIQUE DU BENIN", country: "BENIN" },
  ATBRCA6E: { name: "ATB FINANCIAL", country: "CANADA" },
  ATCICIAB: { name: "BANQUE ATLANTIQUE COTE D'IVOIRE", country: "COTE D'IVOIRE" },
  ATCRCMCM: { name: "BANQUE ATLANTIQUE CAMEROUN", country: "CAMEROON" },
  ATGWGWGW: { name: "BANQUE ATLANTIQUE DA GUINE-BISSAU, S.A.", country: "GUINEA-BISSAU" },
  ATMLMLBA: { name: "BANQUE ATLANTIQUE MALI", country: "MALI" },
  ATNENENI: { name: "BANQUE ATLANTIQUE NIGER", country: "NIGER" },
  ATSNSNDA: { name: "BANQUE ATLANTIQUE SENEGAL", country: "SENEGAL" },
  ATTDHNTE: { name: "BANCO ATLANTIDA S.A.", country: "HONDURAS" },
  ATTGTGTG: { name: "BANQUE ATLANTIQUE TOGO", country: "TOGO" },
  AUBBBHBM: { name: "AHLI UNITED BANK B.S.C.", country: "BAHRAIN" },
  AUDBEGCA: { name: "FIRST ABU DHABI BANK MISR S.A.E.", country: "EGYPT" },
  AUDBLBBX: { name: "Bank Audi s.a.l.", country: "LEBANON" },
  AVALUAUK: { name: "RAIFFEISEN BANK JOINT STOCK COMPANY", country: "UKRAINE" },
  AWINETAA: { name: "AWASH INTERNATIONAL BANK S.C.", country: "ETHIOPIA" },
  AXISINBB: { name: "AXIS BANK LIMITED", country: "INDIA" },
  AYUDLALA: { name: "BANK OF AYUDHYA PCL VIENTIANE BRANCH", country: "LAO PEOPLE'S DEMOCRATIC REPUBLIC" },
  AYUDTHBK: { name: "BANK OF AYUDHYA PUBLIC COMPANY LTD", country: "THAILAND" },
  AZTKMXMM: { name: "BANCO AZTECA , S.A. INSTITUCION DE BANCA MULTIPLE", country: "MEXICO" },
  BAAWMRMR: { name: "BANQUE AL WAVA MAURITANIENNE ISLAMIQUE (BAMIS)", country: "MAURITANIA" },
  BACIARBA: { name: "BANCO DE LA CIUDAD DE BUENOS AIRES", country: "ARGENTINA" },
  BACOARBA: { name: "HSBC BANK ARGENTINA SA", country: "ARGENTINA" },
  BACRIT22: { name: "CREDITO EMILIANO SPA", country: "ITALY" },
  BACUSVSS: { name: "BANCO CUSCATLAN DE EL SALVADOR S.A", country: "EL SALVADOR" },
  BACXCZPP: { name: "UNICREDIT BANK CZECH REPUBLIC AND SLOVAKIA, A.S.", country: "CZECHIA" },
  BACXHUHB: { name: "UNICREDIT BANK HUNGARY ZRT.", country: "HUNGARY" },
  BACXROBU: { name: "UNICREDIT BANK SA", country: "ROMANIA" },
  BACXRSBG: { name: "UNICREDIT BANK SRBIJA A.D.", country: "REPUBLIC OF SERBIA" },
  BACXSI22: { name: "UNICREDIT BANKA SLOVENIJA D.D.", country: "SLOVENIA" },
  BAERCHZZ: { name: "BANK JULIUS BAER AND CO.LTD.,ZURICH", country: "SWITZERLAND" },
  BAFUITRR: { name: "BANCA DEL FUCINO", country: "ITALY" },
  BAGAGE22: { name: "JSC BANK OF GEORGIA", country: "GEORGIA" },
  BAIPAOLU: { name: "BANCO ANGOLANO DE INVESTIMENTOS, SA", country: "ANGOLA" },
  BAIPPTPL: { name: "BANCO BAI EUROPA, SA", country: "PORTUGAL" },
  BAKHSDKH: { name: "BANK OF KHARTOUM", country: "SUDAN" },
  BAKOSI2X: { name: "BANKA INTESA SANPAOLO D.D.", country: "SLOVENIA" },
  BALPLI22: { name: "BANK ALPINUM AG", country: "LIECHTENSTEIN" },
  BAMCNIMA: { name: "BANCO DE AMERICA CENTRAL, S.A.", country: "NICARAGUA" },
  BAMCSVSS: { name: "BANCO DE AMERICA CENTRAL, S.A.", country: "EL SALVADOR" },
  BAMEITMM: { name: "MEDIOBANCA BANCA DI CREDITO FINANZIARIO SPA", country: "ITALY" },
  BAMRVECA: { name: "MERCANTIL C.A. BANCO UNIVERSAL", country: "VENEZUELA" },
  BANIBOLX: { name: "BANCO BISA S.A.", country: "BOLIVIA" },
  BANSPAPA: { name: "BANESCO (PANAMA) S.A.", country: "PANAMA" },
  BAOKUS44: { name: "BOKF, NA", country: "UNITED STATES" },
  BAPAPTPL: { name: "BANCO ATLANTICO EUROPA, SA", country: "PORTUGAL" },
  BAPPIT22: { name: "BANCO BPM SPA", country: "ITALY" },
  BAPRNIMA: { name: "BANCO DE LA PRODUCCION SOCIEDAD ANONIMA", country: "NICARAGUA" },
  BARCAEAD: { name: "BARCLAYS BANK PLC", country: "UNITED ARAB EMIRATES" },
  BARCBEBB: { name: "BARCLAYS BANK IRELAND PLC, BELGIUM BRANCH", country: "BELGIUM" },
  BARCBWGX: { name: "ABSA BANK BOTSWANA LIMITED", country: "BOTSWANA" },
  BARCCHGG: { name: "BARCLAYS BANK (SUISSE) S.A", country: "SWITZERLAND" },
  BARCCNSH: { name: "BARCLAYS BANK PLC, SHANGHAI BRANCH", country: "CHINA" },
  BARCDEFF: { name: "BARCLAYS BANK IRELAND PLC FRANKFURT", country: "GERMANY" },
  BARCFRPC: { name: "BARCLAYS BANK IRELAND PLC PARIS", country: "FRANCE" },
  BARCFRPP: { name: "BARCLAYS BANK IRELAND PLC PARIS", country: "FRANCE" },
  BARCGB22: { name: "BARCLAYS BANK PLC", country: "UNITED KINGDOM" },
  BARCGB2Z: { name: "BARCLAYS BANK PLC", country: "UNITED KINGDOM" },
  BARCGB33: { name: "BARCLAYS CAPITAL SECURITIES LTD", country: "UNITED KINGDOM" },
  BARCGB5G: { name: "BARCLAYS BANK PLC", country: "UNITED KINGDOM" },
  BARCGHAC: { name: "ABSA BANK OF GHANA LIMITED", country: "GHANA" },
  BARCHKHH: { name: "BARCLAYS BANK PLC", country: "HONG KONG" },
  BARCIE2D: { name: "BARCLAYS BANK IRELAND PLC", country: "IRELAND" },
  BARCINBB: { name: "BARCLAYS BANK PLC", country: "INDIA" },
  BARCIT4G: { name: "BARCLAYS BANK IRELAND PLC MILAN BRANCH", country: "ITALY" },
  BARCITMM: { name: "BARCLAYS BANK IRELAND PLC MILAN BRANCH", country: "ITALY" },
  BARCJPJ2: { name: "BARCLAYS BANK PLC TOKYO BRANCH", country: "JAPAN" },
  BARCJPJ9: { name: "BARCLAYS SECURITIES JAPAN LIMITED", country: "JAPAN" },
  BARCKENX: { name: "ABSA BANK KENYA PLC", country: "KENYA" },
  BARCLULL: { name: "BARCLAYS BANK IRELAND PLC, LUXEMBOURG BRANCH", country: "LUXEMBOURG" },
  BARCMCMX: { name: "BARCLAYS BANK PLC, MONACO", country: "MONACO" },
  BARCMUMU: { name: "ABSA BANK (MAURITIUS) LIMITED", country: "MAURITIUS" },
  BARCMXMM: { name: "BARCLAYS BANK MEXICO S.A.", country: "MEXICO" },
  BARCNL22: { name: "BARCLAYS BANK IRELAND PLC, NETHERLANDS BRANCH", country: "NETHERLANDS" },
  BARCPTPC: { name: "BARCLAYS BANK IRELAND PLC - SUCURSAL EM PORTUGAL", country: "PORTUGAL" },
  BARCSCSC: { name: "ABSA (SEYCHELLES) LIMITED", country: "SEYCHELLES" },
  BARCSGSG: { name: "BARCLAYS BANK PLC", country: "SINGAPORE" },
  BARCTZTZ: { name: "ABSA BANK TANZANIA LIMITED", country: "TANZANIA, UNITED REPUBLIC OF" },
  BARCUGKX: { name: "ABSA BANK UGANDA LIMITED", country: "UGANDA" },
  BARCUS33: { name: "BARCLAYS BANK PLC", country: "UNITED STATES" },
  BARCUS3B: { name: "BARCLAYS CAPITAL INC", country: "UNITED STATES" },
  BARCZMLX: { name: "ABSA BANK ZAMBIA PLC", country: "ZAMBIA" },
  BARCZWHX: { name: "FIRST CAPITAL BANK LIMITED", country: "ZIMBABWE" },
  BASKES2B: { name: "KUTXABANK, S.A.", country: "SPAIN" },
  BAVICOBB: { name: "BANCO COMERCIAL AV VILLAS S.A.", country: "COLOMBIA" },
  BAWAATWW: {
    name: "BAWAG P.S.K. BANK FUER ARBEIT UND WIRTSCHAFT UND OESTERREICHISCHE POSTSPARKASSE AG",
    country: "AUSTRIA",
  },
  BBAIIDJA: { name: "PT. BANK BUMI ARTA TBK", country: "INDONESIA" },
  BBDABMHM: { name: "HSBC BANK BERMUDA LIMITED", country: "BERMUDA" },
  BBDAHKHX: { name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED", country: "HONG KONG" },
  BBDALULX: { name: "HSBC CONTINENTAL EUROPE", country: "LUXEMBOURG" },
  BBDASGSX: { name: "HSBC INSTITUTIONAL TRUST SERVICES (SINGAPORE) LIMITED", country: "SINGAPORE" },
  BBDEBRSP: { name: "BANCO BRADESCO SA", country: "BRAZIL" },
  BBDELULL: { name: "BANCO BRADESCO EUROPA S.A.", country: "LUXEMBOURG" },
  BBDEUS33: { name: "BANCO BRADESCO S.A.", country: "UNITED STATES" },
  BBHCUS33: { name: "BROWN BROTHERS HARRIMAN AND CO", country: "UNITED STATES" },
  BBHCUS3I: { name: "BROWN BROTHERS HARRIMAN AND CO", country: "UNITED STATES" },
  BBIJIDJA: { name: "PT. BANK UOB INDONESIA UOB Plaza", country: "INDONESIA" },
  BBMEAEAD: { name: "HSBC BANK MIDDLE EAST LIMITED", country: "UNITED ARAB EMIRATES" },
  BBMEBHBX: { name: "HSBC BANK MIDDLE EAST LIMITED", country: "BAHRAIN" },
  BBMEOMRX: { name: "HSBC BANK OMAN S.A.O.G", country: "OMAN" },
  BBMEQAQX: { name: "HSBC BANK MIDDLE EAST LIMITED", country: "QATAR" },
  BBOGCOBB: { name: "BANCO DE BOGOTA", country: "COLOMBIA" },
  BBOGUS3M: { name: "BANCO DE BOGOTA, MIAMI AGENCY", country: "UNITED STATES" },
  BBOLECEG: { name: "BANCO BOLIVARIANO", country: "ECUADOR" },
  BBPIPTPL: { name: "BANCO BPI, S.A.", country: "PORTUGAL" },
  BBRUBEBB: { name: "ING BELGIUM NV/SA", country: "BELGIUM" },
  BBRUCHGT: { name: "ING BANK N.V., AMSTERDAM, LANCY/GENEVA BRANCH", country: "SWITZERLAND" },
  BBVABEBB: { name: "BANCO BILBAO VIZCAYA ARGENTARIA SA", country: "BELGIUM" },
  BBVADEFF: { name: "BANCO BILBAO VIZCAYA ARGENTARIA SA.", country: "GERMANY" },
  BBVAESMM: { name: "BANCO BILBAO VIZCAYA ARGENTARIA S.A", country: "SPAIN" },
  BBVAFRPP: { name: "BANCO BILBAO VIZCAYA ARGENTARIA", country: "FRANCE" },
  BBVAGB2L: { name: "BANCO BILBAO VIZCAYA ARGENTARIA S.A.", country: "UNITED KINGDOM" },
  BBVAHKHH: { name: "BANCO BILBAO VIZCAYA ARGENTARIA,SA", country: "HONG KONG" },
  BBVAITMM: { name: "BANCO BILBAO VIZCAYA ARGENTARIA S.A", country: "ITALY" },
  BBVAJPJT: { name: "BANCO BILBAO VIZCAYA ARGENTARIA TOKYO BRANCH", country: "JAPAN" },
  BBVAPTPL: { name: "BANCO BILBAO VIZCAYA ARGENTARIA S.A., SUCURSAL EM PORTUGAL", country: "PORTUGAL" },
  BBVAPYPA: { name: "BANCO GNB S.A.(EN PROCESO DE FUSION POR ABSORCION)", country: "PARAGUAY" },
  BBVASGSG: { name: "BANCO BILBAO VIZCAYA ARGENTARIA SINGAPORE BRANCH", country: "SINGAPORE" },
  BBVAUS33: { name: "BANCO BILBAO VIZCAYA ARGENTARIA, S.A. NEW YORK", country: "UNITED STATES" },
  BBVAUYMM: { name: "BANCO BILBAO VIZCAYA ARGENTARIA URUGUAY S.A.", country: "URUGUAY" },
  BCAIEGCX: { name: "BANQUE DU CAIRE", country: "EGYPT" },
  BCBBBRPR: { name: "BANCO BRADESCO SA", country: "BRAZIL" },
  BCBHDOSD: { name: "BANCO MULTIPLE BHD LEON S.A.", country: "DOMINICAN REPUBLIC" },
  BCBIEGCX: { name: "ATTIJARIWAFA BANK EGYPT S.A.E", country: "EGYPT" },
  BCCBAOLU: { name: "BANCO BIC, S.A.", country: "ANGOLA" },
  BCCECRSJ: { name: "BANCO LAFISE S.A. 50 MTS ESTE DE LA", country: "COSTA RICA" },
  BCCEDOSD: { name: "BANCO MULTIPLE LAFISE S.A.", country: "DOMINICAN REPUBLIC" },
  BCCEHNTE: { name: "BANCO LAFISE (HONDURAS), S.A.", country: "HONDURAS" },
  BCCENIMA: { name: "BANCO LAFISE BANCENTRO, S.A.", country: "NICARAGUA" },
  BCCEPAPA: { name: "BANCO LAFISE PANAMA S.A.", country: "PANAMA" },
  BCCXTDND: { name: "BANQUE COMMERCIALE DU CHARI", country: "CHAD" },
  BCDCCDKI: { name: "EQUITY BANQUE COMMERCIALE DU CONGO", country: "DEMOCRATIC REPUBLIC OF CONGO" },
  BCEELULL: { name: "BANQUE ET CAISSE D'EPARGNE DE L'ETAT, LUXEMBOURG", country: "LUXEMBOURG" },
  BCGAAOLU: { name: "BANCO CAIXA GERAL DE ANGOLA S.A.", country: "ANGOLA" },
  BCGECHGG: { name: "BANQUE CANTONALE DE GENEVE", country: "SWITZERLAND" },
  BCHICLRM: { name: "BANCO DE CHILE", country: "CHILE" },
  BCINPAPA: { name: "BAC INTERNATIONAL BANK, INC.", country: "PANAMA" },
  BCIRLULL: { name: "BANKING CIRCLE S.A.", country: "LUXEMBOURG" },
  BCITAEAD: { name: "INTESA SANPAOLO DUBAI BRANCH", country: "UNITED ARAB EMIRATES" },
  BCITCNSH: { name: "INTESA SANPAOLO SPA SHANGHAI", country: "CHINA" },
  BCITDEFF: { name: "INTESA SANPAOLO SPA FILIALE FRANKFURT", country: "GERMANY" },
  BCITESMM: { name: "INTESA SANPAOLO S.P.A. MADRID", country: "SPAIN" },
  BCITFRPP: { name: "INTESA SANPAOLO SPA Succursale de Paris", country: "FRANCE" },
  BCITGB2L: { name: "INTESA SANPAOLO S.P.A LONDON BRANCH", country: "UNITED KINGDOM" },
  BCITHKHH: { name: "INTESA SANPAOLO SPA", country: "HONG KONG" },
  BCITITMM: { name: "INTESA SANPAOLO SPA", country: "ITALY" },
  BCITJPJT: { name: "INTESA SANPAOLO S.P.A. - TOKYO", country: "JAPAN" },
  BCITNL2A: { name: "INTESA SANPAOLO S.P.A. AMSTERDAM BRANCH", country: "NETHERLANDS" },
  BCITPLPW: { name: "INTESA SANPAOLO SPA", country: "POLAND" },
  BCITSGSG: { name: "INTESA SANPAOLO S.P.A. SINGAPORE", country: "SINGAPORE" },
  BCITTRIS: { name: "INTESA SANPAOLO ISTANBUL BRANCH", country: "TURKEY" },
  BCITUS33: { name: "INTESA SANPAOLO S.P.A - NEW YORK", country: "UNITED STATES" },
  BCLRCHBB: { name: "BANK CLER AG, BASEL", country: "SWITZERLAND" },
  BCMACGCG: { name: "CREDIT DU CONGO", country: "CONGO" },
  BCMACMCX: { name: "SOCIETE COMMERCIALE DE BANQUE CAMEROUN S.A.", country: "CAMEROON" },
  BCMAFRPP: { name: "ATTIJARIWAFA BANK (FORMERLY BANQUE COMMERCIALE DU MAROC)", country: "FRANCE" },
  BCMAMAMC: { name: "ATTIJARIWAFA BANK (FORMERLY BANQUE COMMERCIALE DU MAROC)", country: "MOROCCO" },
  BCMAMRMR: { name: "ATTIJARI BANK MAURITANIE", country: "MAURITANIA" },
  BCMASNDA: { name: "CREDIT DU SENEGAL", country: "SENEGAL" },
  BCMATDND: { name: "ATTIJARI BANK TCHAD SA", country: "CHAD" },
  BCMRMXMM: {
    name: "BBVA MEXICO, S.A. INSTITUCION DE BANCA MULTIPLE GRUPO FINANCIERO BBVA MEXICO",
    country: "MEXICO",
  },
  BCNNCH22: { name: "BANQUE CANTONALE NEUCHATELOISE", country: "SWITZERLAND" },
  BCOEESMM: { name: "BANCO COOPERATIVO ESPANOL", country: "SPAIN" },
  BCOMPTPL: { name: "BANCO COMERCIAL PORTUGUES, LISBOA", country: "PORTUGAL" },
  BCONPEPL: { name: "BANCO BBVA PERU", country: "PERU" },
  BCOOARBA: { name: "BANCO CREDICOOP COOPERATIVO LTD", country: "ARGENTINA" },
  BCPCIT2P: { name: "BANCA DI PIACENZA", country: "ITALY" },
  BCPLBOLX: { name: "BANCO DE CREDITO DE BOLIVIA SA", country: "BOLIVIA" },
  BCPLPEPL: { name: "BANCO DE CREDITO DEL PERU", country: "PERU" },
  BCPOMAMC: { name: "BANQUE CENTRALE POPULAIRE", country: "MOROCCO" },
  BCPQUS33: { name: "BAIN CAPITAL PRIVATE EQUITY, LP.", country: "UNITED STATES" },
  BCRICRSJ: { name: "BANCO DE COSTA RICA", country: "COSTA RICA" },
  BCTOCOBB: { name: "ITAU CORPBANCA COLOMBIA S.A.", country: "COLOMBIA" },
  BCTOPAPA: { name: "ITAU (PANAMA) S.A.", country: "PANAMA" },
  BCVAIT2V: { name: "BANCA VALSABBINA S.C.P.A.", country: "ITALY" },
  BCVLCH2L: { name: "BANQUE CANTONALE VAUDOISE", country: "SWITZERLAND" },
  BCVSCH2L: { name: "BANQUE CANTONALE DU VALAIS Vallischer Kantonalbank", country: "SWITZERLAND" },
  BCYPCY2N: { name: "BANK OF CYPRUS PUBLIC COMPANY LIMITED", country: "CYPRUS" },
  BDCPITTT: { name: "BANCA DEL PIEMONTE S.P.A.", country: "ITALY" },
  BDFEFRPP: { name: "BANQUE DE FRANCE", country: "FRANCE" },
  BDINIDJA: { name: "PT. BANK DANAMON INDONESIA, TBK MENARA BANK DANAMON", country: "INDONESIA" },
  BDWBDEMM: { name: "BAADER BANK AG", country: "GERMANY" },
  BEARUS33: { name: "J.P. Morgan Securities LLC", country: "UNITED STATES" },
  BECHCLRM: { name: "BANCO DEL ESTADO DE CHILE", country: "CHILE" },
  BEFRCH22: { name: "BANQUE CANTONALE DE FRIBOURG", country: "SWITZERLAND" },
  BEGOCHZZ: { name: "BERGOS AG", country: "SWITZERLAND" },
  BEGODEHH: { name: "JOH. BERENBERG,GOSSLER AND CO KG", country: "GERMANY" },
  BELADEBE: { name: "LANDESBANK BERLIN AG", country: "GERMANY" },
  BESCPTPL: { name: "NOVO BANCO, SA", country: "PORTUGAL" },
  BESMESMM: { name: "ABANCA Corporacion Bancaria, S.A.", country: "SPAIN" },
  BESVFRPP: { name: "MY MONEY BANK", country: "FRANCE" },
  BFCEVNVX: { name: "BPCE IOM - HO CHI MINH CITY BRANCH", country: "VIET NAM" },
  BFCOFRPP: { name: "BANQUE FRANCAISE COMMERCIALE DE L'OCEAN INDIEN", country: "FRANCE" },
  BFCORERX: { name: "BANQUE FRANCAISE COMMERCIALE DE L'OCEAN INDIEN", country: "FRANCE" },
  BFCOYTYT: { name: "BANQUE FRANCAISE COMMERCIALE DE L'OCEAN INDIEN", country: "FRANCE" },
  BFKKAT2K: { name: "BKS BANK AG", country: "AUSTRIA" },
  BFKKHR22: { name: "BKS BANK AG, GLAVNA PODRUZNICA HRVATSKA", country: "CROATIA" },
  BFKKSI22: { name: "BKS BANK AG, BANCNA PODRUZNICA", country: "SLOVENIA" },
  BFKKSKBB: { name: "BKS BANK AG POBOCKA ZAHRANICNEJ BANKY", country: "SLOVAKIA" },
  BFMXAOLU: { name: "BANCO DE FOMENTO ANGOLA, S.A.", country: "ANGOLA" },
  BFRPARBA: { name: "BANCO BBVA ARGENTINA S.A", country: "ARGENTINA" },
  BFTVVNVX: { name: "JOINT STOCK COMMERCIAL BANK FOR FOREIGN TRADE OF VIETNAM", country: "VIET NAM" },
  BGENIT2T: { name: "BANCA GENERALI SPA", country: "ITALY" },
  BGFICGCG: { name: "BGFIBANK CONGO", country: "CONGO" },
  BGFICMCX: { name: "BGFIBANK CAMEROUN SA", country: "CAMEROON" },
  BGFIFRPP: { name: "BGFIBANK EUROPE", country: "FRANCE" },
  BGFIGALI: { name: "BGFIBANK", country: "GABON" },
  BGLLLULL: { name: "BGL BNP PARIBAS", country: "LUXEMBOURG" },
  BHFBDEFF: { name: "ODDO BHF AKTIENGESELLSCHAFT", country: "GERMANY" },
  BIANNENI: { name: "BANQUE INTERNATIONALE POUR L'AFRIQUE AU NIGER", country: "NIGER" },
  BIAOCIAB: { name: "NSIA BANQUE COTE D'IVOIRE", country: "COTE D'IVOIRE" },
  BIBUBFBF: { name: "UNITED BANK FOR AFRICA BURKINA (UBA BURKINA)", country: "BURKINA FASO" },
  BICECLRM: { name: "BANCO BICE", country: "CHILE" },
  BICFCHGG: { name: "BANQUE INTERNATIONALE DE COMMERCE", country: "SWITZERLAND" },
  BICFFRPP: { name: "BANQUE INTERNATIONALE DE COMMERCE", country: "FRANCE" },
  BICIGALX: { name: "BANQUE INTERNATIONALE POUR LE COMMERCE ET L'INDUSTRIE DU GABON", country: "GABON" },
  BIDVVNVX: {
    name: "JOINT STOCK COMMERCIAL BANK FOR INVESTMENT AND DEVELOPMENT OF VIETNAM",
    country: "VIET NAM",
  },
  BIGBPLPW: { name: "BANK MILLENNIUM S.A.", country: "POLAND" },
  BIGTIE2D: { name: "BANK OF IRELAND HEAD OFFICE", country: "IRELAND" },
  BILLLULL: { name: "BANQUE INTERNATIONALE A LUXEMBOURG S.A.", country: "LUXEMBOURG" },
  BIMEMXMM: {
    name: "HSBC MEXICO, S.A., INSTITUCION DE BANCA MULTIPLE, GRUPO FINANCIERO HSBC",
    country: "MEXICO",
  },
  BIMOMZMX: { name: "BANCO INTERNACIONAL DE MOCAMBIQUE, S.A.", country: "MOZAMBIQUE" },
  BINPPEPL: { name: "BANCO INTERNACIONAL DEL PERU (INTERBANK)", country: "PERU" },
  BINTECEQ: { name: "BANCO INTERNACIONAL SA", country: "ECUADOR" },
  BIPMMLBA: { name: "BANQUE INTERNATIONALE POUR LE MALI", country: "MALI" },
  BJIOMXML: { name: "BANCO DEL BAJIO, S.A., INSTITUCION DE BANCA MULTIPLE", country: "MEXICO" },
  BKAUATWW: { name: "UniCredit Bank Austria AG", country: "AUSTRIA" },
  BKBBCHBB: { name: "BASLER KANTONALBANK", country: "SWITZERLAND" },
  BKBKESMM: { name: "BANKINTER, S.A", country: "SPAIN" },
  BKBKPTPL: { name: "BANKINTER SA- SUCURSAL EM PORTUGAL", country: "PORTUGAL" },
  BKCHATWW: { name: "BANK OF CHINA (CENTRAL AND EASTERN EUROPE) LIMITED VIENNA BRANCH", country: "AUSTRIA" },
  BKCHAU2S: { name: "BANK OF CHINA LIMITED, SYDNEY BRANCH", country: "AUSTRALIA" },
  BKCHCNBJ: { name: "BANK OF CHINA, BEIJING (HEAD OFFICE)", country: "CHINA" },
  BKCHDEFF: { name: "BANK OF CHINA", country: "GERMANY" },
  BKCHGB2L: { name: "BANK OF CHINA", country: "UNITED KINGDOM" },
  BKCHHKHH: { name: "BANK OF CHINA (HONG KONG) LIMITED", country: "HONG KONG" },
  BKCHITMM: { name: "BANK OF CHINA,MILAN BRANCH", country: "ITALY" },
  BKCHKRSE: { name: "BANK OF CHINA SEOUL BRANCH Floor 1-2", country: "KOREA, REPUBLIC OF" },
  BKCHLULL: { name: "BANK OF CHINA, LUXEMBOURG BRANCH", country: "LUXEMBOURG" },
  BKCHMOMX: { name: "BANK OF CHINA, MACAU BRANCH", country: "MACAO" },
  BKCHSGSG: { name: "BANK OF CHINA LIMITED", country: "SINGAPORE" },
  BKCHUS33: { name: "BANK OF CHINA N.Y.", country: "UNITED STATES" },
  BKIGRWRW: { name: "BANK OF KIGALI PLC", country: "RWANDA" },
  BKKBGB2L: { name: "BANGKOK BANK PUBLIC COMPANY LIMITED", country: "UNITED KINGDOM" },
  BKKBIDJA: { name: "BANGKOK BANK PUBLIC COMPANY LIMITED", country: "INDONESIA" },
  BKKBPHMM: { name: "BANGKOK BANK PUBLIC COMPANY LIMITED", country: "PHILIPPINES" },
  BKKBSGSG: { name: "BANGKOK BANK PUBLIC COMPANY LIMITED", country: "SINGAPORE" },
  BKKBTHBK: { name: "BANGKOK BANK PUBLIC COMPANY LIMITED", country: "THAILAND" },
  BKKBVNVX: { name: "BANGKOK BANK PUBLIC COMPANY LIMITED", country: "VIET NAM" },
  BKMEKWKW: { name: "AHLI UNITED BANK K.S.C.P.", country: "KUWAIT" },
  BKMGNL2A: { name: "BANK MENDES GANS N.V", country: "NETHERLANDS" },
  BKNBCN22: { name: "BANK OF NINGBO", country: "CHINA" },
  BKNZNZ22: { name: "BANK OF NEW ZEALAND", country: "NEW ZEALAND" },
  BKRIUS33: { name: "BANK RHODE ISLAND", country: "UNITED STATES" },
  BKSACLRM: { name: "SCOTIABANK CHILE", country: "CHILE" },
  BKTRTRIS: { name: "DEUTSCHE BANK A.S.", country: "TURKEY" },
  BKTRUS33: { name: "DEUTSCHE BANK TRUST COMPANY AMERICAS", country: "UNITED STATES" },
  BKTWTWTP: { name: "BANK OF TAIWAN", country: "TAIWAN, REPUBLIC OF CHINA" },
  BLEMCHGG: { name: "BANQUE DU LEMAN SA", country: "SWITZERLAND" },
  BLFLCHBB: { name: "LGT BANK (SCHWEIZ) AG", country: "SWITZERLAND" },
  BLFLLI2X: { name: "LGT BANK AG", country: "LIECHTENSTEIN" },
  BLFLSGSG: { name: "LGT BANK (SINGAPORE) LTD.", country: "SINGAPORE" },
  BLFSLBBX: { name: "BANQUE LIBANO FRANCAISE", country: "LEBANON" },
  BLICCHGG: { name: "HSBC Private Bank (Suisse) SA", country: "SWITZERLAND" },
  BLICCLRM: { name: "HSBC BANK (CHILE)", country: "CHILE" },
  BLICRUMM: { name: "HSBC BANK (RR) OOO", country: "RUSSIAN FEDERATION" },
  BLKBCH22: { name: "BASELLANDSCHAFTLICHE KANTONALBANK", country: "SWITZERLAND" },
  BLOMAEAD: { name: "BANQUE BANORIENT FRANCE Prime Tower, 26th Floor", country: "UNITED ARAB EMIRATES" },
  BLOMLBBX: { name: "BLOM BANK S.A.L.", country: "LEBANON" },
  BLSMUS33: { name: "BLACKSTONE ALTERNATIVE ASSET MANAGEMENT LP", country: "UNITED STATES" },
  BLUXLULL: { name: "BANQUE DE LUXEMBOURG S.A", country: "LUXEMBOURG" },
  BMARES2M: { name: "BANCA MARCH S.A", country: "SPAIN" },
  BMCEMAMC: { name: "BANK OF AFRICA", country: "MOROCCO" },
  BMILHNTE: { name: "BANCO DE AMERICA CENTRAL HONDURAS, S.A", country: "HONDURAS" },
  BMISEGCX: { name: "BANQUE MISR", country: "EGYPT" },
  BMMMFR2A: { name: "ROTHSCHILD MARTIN MAUREL", country: "FRANCE" },
  BMOIMGMG: { name: "BANQUE MALGACHE DE L'OCEAN INDIEN", country: "MADAGASCAR" },
  BMPBBEBB: { name: "AION", country: "BELGIUM" },
  BMRIIDJA: { name: "BANK MANDIRI (PERSERO), PT.", country: "INDONESIA" },
  BMSXMXMM: {
    name: "BANCO SANTANDER MEXICO, S.A., INSTITUCION DE BANCA MULTIPLE, GRUPO FINANCIERO SANTANDER MEXICO",
    country: "MEXICO",
  },
  BMUSOMRX: { name: "BANK MUSCAT SAOG", country: "OMAN" },
  BNBOBOLX: { name: "BANCO NACIONAL DE BOLIVIA S.A", country: "BOLIVIA" },
  BNCRCRSJ: { name: "BANCO NACIONAL DE COSTA RICA", country: "COSTA RICA" },
  BNDCCAMM: { name: "BANQUE NATIONALE DU CANADA", country: "CANADA" },
  BNGHNL2G: { name: "BNG BANK N.V.", country: "NETHERLANDS" },
  BNIAIDJA: { name: "PT. BANK CIMB NIAGA, TBK", country: "INDONESIA" },
  BNINIDJA: { name: "PT BANK NEGARA INDONESIA", country: "INDONESIA" },
  BNLIITRR: { name: "BANCA NAZIONALE DEL LAVORO SPA", country: "ITALY" },
  BNMXMXMM: { name: "BANCO NACIONAL DE MEXICO S.A. INTEGRANTE DEL GRUPO FINANCIERO BANAMEX", country: "MEXICO" },
  BNORPHMM: { name: "BDO UNIBANK, INC.", country: "PHILIPPINES" },
  BNPAAEAA: { name: "BNP PARIBAS, ABU DHABI", country: "UNITED ARAB EMIRATES" },
  BNPAAEAD: { name: "BNP PARIBAS, ABU DHABI", country: "UNITED ARAB EMIRATES" },
  BNPAAU2S: { name: "BNP PARIBAS AUSTRALIA", country: "AUSTRALIA" },
  BNPABHBC: { name: "BNP PARIBAS MANAMA", country: "BAHRAIN" },
  BNPABHBM: { name: "BNP PARIBAS BAHRAIN", country: "BAHRAIN" },
  BNPABRSP: { name: "BANCO BNP PARIBAS BRASIL S/A JK Financial Center", country: "BRAZIL" },
  BNPACNSH: { name: "BNP PARIBAS (CHINA) LTD", country: "CHINA" },
  BNPACOBB: { name: "BNP PARIBAS COLOMBIA CORPORACION FINANCIERA S.A.", country: "COLOMBIA" },
  BNPADEFF: { name: "BNP PARIBAS S.A. NIEDERLASSUNG DEUTSCHLAND", country: "GERMANY" },
  BNPADZAL: { name: "BNP PARIBAS EL DJAZAIR", country: "ALGERIA" },
  BNPAESMS: { name: "BNP PARIBAS S.A. SUCURSAL EN ESPANA", country: "SPAIN" },
  BNPAFRPP: { name: "BNP PARIBAS", country: "FRANCE" },
  BNPAGB22: { name: "BNP PARIBAS LONDON BRANCH", country: "UNITED KINGDOM" },
  BNPAHKHH: { name: "BNP PARIBAS (HONG KONG BRANCH) TWO INTERNATIONAL FINANCE CENTRE", country: "HONG KONG" },
  BNPAHUHX: { name: "BNP PARIBAS, HUNGARY BRANCH", country: "HUNGARY" },
  BNPAIDJA: { name: "PT BANK BNP PARIBAS INDONESIA", country: "INDONESIA" },
  BNPAIE2D: { name: "BNP Paribas SA", country: "IRELAND" },
  BNPAINBB: { name: "BNP PARIBAS", country: "INDIA" },
  BNPAKRSE: { name: "BNP PARIBAS SEOUL BRANCH", country: "KOREA, REPUBLIC OF" },
  BNPAKWKW: { name: "BNP PARIBAS KUWAIT", country: "KUWAIT" },
  BNPALULS: { name: "BNP PARIBAS, SUCCURSALE DE LUXEMBOURG", country: "LUXEMBOURG" },
  BNPAMYKL: { name: "BNP PARIBAS MALAYSIA BERHAD", country: "MALAYSIA" },
  BNPANL2A: { name: "BNP PARIBAS S.A.", country: "NETHERLANDS" },
  BNPAPTPL: { name: "BNP PARIBAS", country: "PORTUGAL" },
  BNPAQAQA: { name: "BNP PARIBAS, DOHA", country: "QATAR" },
  BNPARUMM: { name: "BNP PARIBAS BANK JSC", country: "RUSSIAN FEDERATION" },
  BNPASARI: { name: "BNP PARIBAS SAUDI ARABIA", country: "SAUDI ARABIA" },
  BNPASGSG: { name: "BNP PARIBAS - SINGAPORE BRANCH", country: "SINGAPORE" },
  BNPATHBK: { name: "BNP PARIBAS BANGKOK BRANCH", country: "THAILAND" },
  BNPAUS3N: { name: "BNP PARIBAS", country: "UNITED STATES" },
  BNPAVNVN: { name: "BNP PARIBAS HO CHI MINH CITY BRANCH", country: "VIET NAM" },
  BNPAZAJJ: { name: "BNP PARIBAS", country: "SOUTH AFRICA" },
  BNTBBMHM: { name: "THE BANK OF N.T. BUTTERFIELD AND SON LIMITED", country: "BERMUDA" },
  BNTBGGSX: { name: "BUTTERFIELD BANK (GUERNSEY) LIMITED", country: "GUERNSEY C.I." },
  BNTBJESH: { name: "BUTTERFIELD BANK (JERSEY) LIMITED", country: "JERSEY C.I." },
  BNTBKYKY: { name: "BUTTERFIELD BANK (CAYMAN)LIMITED", country: "CAYMAN ISLANDS" },
  BNULMOMX: { name: "BANCO NACIONAL ULTRAMARINO, S.A.", country: "MACAO" },
  BODEVE2M: { name: "BANCO OCCIDENTAL DE DESCUENTO", country: "VENEZUELA" },
  BOFAAUSX: { name: "BANK OF AMERICA, N.A. SYDNEY", country: "AUSTRALIA" },
  BOFABE3X: { name: "BANK OF AMERICA EUROPE DESIGNATED ACTIVITY COMPANY, BRUSSELS BRANCH", country: "BELGIUM" },
  BOFACATT: { name: "BANK OF AMERICA, N.A.,CANADA BRANCH", country: "CANADA" },
  BOFACH2X: {
    name: "BANK OF AMERICA EUROPE DESIGNATED ACTIVITY COMPANY, DUBLIN, ZURICH BRANCH",
    country: "SWITZERLAND",
  },
  BOFACN3X: { name: "BANK OF AMERICA, N.A. SHANGHAI", country: "CHINA" },
  BOFACN4X: { name: "BANK OF AMERICA, N.A. SHANGHAI", country: "CHINA" },
  BOFADEFX: { name: "BANK OF AMERICA, N.A. FRANKFURT", country: "GERMANY" },
  BOFAES2X: { name: "BANK OF AMERICA EUROPE DESIGNATED ACTIVITY COMPANY, MADRID BRANCH", country: "SPAIN" },
  BOFAFRP3: { name: "BOFA SECURITIES EUROPE S.A.", country: "FRANCE" },
  BOFAFRPP: { name: "BANK OF AMERICA EUROPE DESIGNATED ACTIVITY COMPANY, PARIS BRANCH", country: "FRANCE" },
  BOFAGB22: { name: "BANK OF AMERICA NA", country: "UNITED KINGDOM" },
  BOFAGB2U: { name: "BANK OF AMERICA NA", country: "UNITED KINGDOM" },
  BOFAGB4T: { name: "BANK OF AMERICA NA", country: "UNITED KINGDOM" },
  BOFAGR2X: { name: "BANK OF AMERICA EUROPE DESIGNATED ACTIVITY COMPANY, ATHENS BRANCH", country: "GREECE" },
  BOFAHKHX: { name: "BANK OF AMERICA NATIONAL ASSOCIATION", country: "HONG KONG" },
  BOFAID2X: { name: "BANK OF AMERICA, N.A.JAKARTA BRANCH", country: "INDONESIA" },
  BOFAIE3X: { name: "BANK OF AMERICA EUROPE DESIGNATED ACTIVITY COMPANY", country: "IRELAND" },
  BOFAIN4X: { name: "BANK OF AMERICA N.A. MUMBAI", country: "INDIA" },
  BOFAIT2X: { name: "BANK OF AMERICA EUROPE DESIGNATED ACTIVITY COMPANY, MILAN BRANCH", country: "ITALY" },
  BOFAJPJX: { name: "BANK OF AMERICA, N.A., TOKYO BRANCH", country: "JAPAN" },
  BOFAKR2X: { name: "BANK OF AMERICA, N.A. SEOUL BRANCH", country: "KOREA, REPUBLIC OF" },
  BOFAMXMX: { name: "BANK OF AMERICA, MEXICO S.A.", country: "MEXICO" },
  BOFAMY2X: { name: "BANK OF AMERICA MALAYSIA BERHAD", country: "MALAYSIA" },
  BOFANLNX: {
    name: "BANK OF AMERICA EUROPE DESIGNATED ACTIVITY COMPANY, AMSTERDAM BRANCH",
    country: "NETHERLANDS",
  },
  BOFAPH2X: { name: "BANK OF AMERICA, N.A. MANILA", country: "PHILIPPINES" },
  BOFASG2X: { name: "BANK OF AMERICA NATIONAL ASSOCIATION", country: "SINGAPORE" },
  BOFATH2X: { name: "BANK OF AMERICA, N.A. BANGKOK", country: "THAILAND" },
  BOFATW2X: { name: "BANK OF AMERICA, N.A. TAIPEI", country: "TAIWAN, REPUBLIC OF CHINA" },
  BOFAUS3M: { name: "BANK OF AMERICA, N.A.", country: "UNITED STATES" },
  BOFAUS3N: { name: "BANK OF AMERICA, N.A.", country: "UNITED STATES" },
  BOFAUS6M: { name: "BANK OF AMERICA, N.A.", country: "UNITED STATES" },
  BOFAUS6N: { name: "BANK OF AMERICA, N.A.", country: "UNITED STATES" },
  BOFAUS6S: { name: "BANK OF AMERICA, N.A.", country: "UNITED STATES" },
  BOFCUS33: { name: "MUFG UNION BANK, N.A.", country: "UNITED STATES" },
  BOFIGB2B: { name: "BANK OF IRELAND (UK) PLC", country: "UNITED KINGDOM" },
  BOFIIE2D: { name: "BANK OF IRELAND HEAD OFFICE", country: "IRELAND" },
  BOFIUS33: { name: "BANK OF IRELAND GLOBAL MARKET US", country: "UNITED STATES" },
  BOFMCAM2: { name: "BANK OF MONTREAL", country: "CANADA" },
  BOFMCAM3: { name: "BANK OF MONTREAL", country: "CANADA" },
  BOFMCAT2: { name: "BANK OF MONTREAL", country: "CANADA" },
  BOFMCNSH: { name: "BANK OF MONTREAL (CHINA) CO. LTD.", country: "CHINA" },
  BOFMGB2X: { name: "BANK OF MONTREAL", country: "UNITED KINGDOM" },
  BOFMUS4X: { name: "BANK OF MONTREAL", country: "UNITED STATES" },
  BOJSCNBN: { name: "BANK OF JIANGSU CO., LTD", country: "CHINA" },
  BOMLAEAD: { name: "MASHREQBANK PSC. PLOT NO.345 DOWNTOWN", country: "UNITED ARAB EMIRATES" },
  BOMLBHBM: { name: "MASHREQBANK PSC", country: "BAHRAIN" },
  BOPIPHMM: { name: "BANK OF THE PHILIPPINE ISLANDS", country: "PHILIPPINES" },
  BOSHCNSH: { name: "BANK OF SHANGHAI", country: "CHINA" },
  BOSPCKCR: { name: "BSP FINANCIAL GROUP LIMITED", country: "COOK ISLANDS" },
  BOSPFJFJ: { name: "BSP FINANCIAL GROUP LIMITED", country: "FIJI" },
  BOSPPGPM: { name: "BSP FINANCIAL GROUP LIMITED", country: "PAPUA NEW GUINEA, INDEPENDENT STATE OF" },
  BOSPSBSB: { name: "BSP FINANCIAL GROUP LIMITED", country: "SOLOMON ISLANDS" },
  BOSPTONU: { name: "BANK OF SOUTH PACIFIC TONGA LIMITED", country: "TONGA" },
  BOSPVUVX: { name: "BANK SOUTH PACIFIC (VANUATU) LIMITED", country: "VANUATU" },
  BOSPWSWS: { name: "BANK SOUTH PACIFIC (SAMOA) LIMITED", country: "SAMOA, INDEPENDENT STATE OF" },
  BOSUARBA: { name: "BANCO MACRO S.A.", country: "ARGENTINA" },
  BOTKAU2X: { name: "MUFG BANK, LTD.", country: "AUSTRALIA" },
  BOTKCNBD: { name: "MUFG BANK (CHINA),LTD. DALIAN BRANCH", country: "CHINA" },
  BOTKCNBJ: { name: "MUFG BANK(CHINA),LTD. BEIJING BRANCH", country: "CHINA" },
  BOTKCNBS: { name: "MUFG BANK (CHINA), LTD. SHENZHEN BRANCH", country: "CHINA" },
  BOTKCNBT: { name: "MUFG BANK (CHINA), LTD. TIANJIN BRANCH", country: "CHINA" },
  BOTKCNSH: { name: "MUFG BANK(CHINA), LTD., SHANGHAI BRANCH", country: "CHINA" },
  BOTKDEDX: { name: "MUFG BANK (EUROPE) N.V. GERMANY BRANCH", country: "GERMANY" },
  BOTKESMX: { name: "MUFG BANK (EUROPE) N.V. SUCURSAL EN ESPANA", country: "SPAIN" },
  BOTKFRPX: { name: "MUFG BANK, LTD. PARIS BRANCH", country: "FRANCE" },
  BOTKGB2L: { name: "MUFG BANK, LTD.", country: "UNITED KINGDOM" },
  BOTKHKHH: { name: "MUFG BANK, LTD.", country: "HONG KONG" },
  BOTKIDJX: { name: "MUFG BANK, LTD. JAKARTA BRANCH", country: "INDONESIA" },
  BOTKJPJ2: { name: "MUFG BANK, LTD.", country: "JAPAN" },
  BOTKJPJT: { name: "MUFG BANK, LTD.", country: "JAPAN" },
  BOTKKRSX: { name: "MUFG BANK, LTD., SEOUL BRANCH", country: "KOREA, REPUBLIC OF" },
  BOTKMYKX: { name: "MUFG BANK (MALAYSIA) BERHAD", country: "MALAYSIA" },
  BOTKNL2X: { name: "MUFG BANK (EUROPE) N.V. AMSTERDAM", country: "NETHERLANDS" },
  BOTKPHMM: { name: "MUFG BANK, LTD., MANILA BRANCH", country: "PHILIPPINES" },
  BOTKRUMM: { name: "JOINT STOCK COMPANY MUFG BANK (EURASIA)", country: "RUSSIAN FEDERATION" },
  BOTKSGSX: { name: "MUFG BANK, LTD.", country: "SINGAPORE" },
  BOTKTHBX: { name: "BANK OF AYUDHYA PUBLIC COMPANY LIMITED", country: "THAILAND" },
  BOTKTRIS: { name: "MUFG BANK TURKEY A.S.", country: "TURKEY" },
  BOTKUS33: { name: "MUFG BANK LTD.", country: "UNITED STATES" },
  BOTKVNVX: { name: "MUFG BANK, LTD., HO CHI MINH CITY BRANCH", country: "VIET NAM" },
  BPAAIT2B: { name: "BANCA POPOLARE DELL ALTO ADIGE S.P.A.", country: "ITALY" },
  BPABBRRJ: { name: "BANCO BTG PACTUAL S.A", country: "BRAZIL" },
  BPABKYKG: { name: "BANCO BTG PACTUAL SA CAYMAN BRANCH", country: "CAYMAN ISLANDS" },
  BPCPCHGG: { name: "BANQUE DE COMMERCE ET DE PLACEMENTS S.A", country: "SWITZERLAND" },
  BPCPLULL: { name: "BANQUE DE COMMERCE ET DE PLACEMENTS S.A.", country: "LUXEMBOURG" },
  BPDCCRSJ: { name: "BANCO POPULAR Y DE DESARROLLO COMUNAL", country: "COSTA RICA" },
  BPDODOSX: { name: "BANCO POPULAR DOMINICANO, S.A. BANCO MULTIPLE", country: "DOMINICAN REPUBLIC" },
  BPHKPLPK: { name: "BANK BPH SPOLKA AKCYJNA", country: "POLAND" },
  BPKOPLPW: { name: "PKO BANK POLSKI S.A.", country: "POLAND" },
  BPLCESMM: { name: "BARCLAYS BANK IRELAND PLC, SUCURSAL EN ESPANA", country: "SPAIN" },
  BPLCITMM: { name: "BARCLAYS BANK IRELAND PLC MILAN BRANCH", country: "ITALY" },
  BPMOIT22: { name: "BPER BANCA S.P.A.", country: "ITALY" },
  BPOPCOBB: { name: "BANCO POPULAR SA - COLOMBIA", country: "COLOMBIA" },
  BPPBCHGG: { name: "BNP PARIBAS (SUISSE) SA", country: "SWITZERLAND" },
  BPPBMCMC: { name: "BNP PARIBAS Wealth Management Monaco", country: "MONACO" },
  BPPNIT2P: { name: "CREDIT AGRICOLE FRIULADRIA SPA", country: "ITALY" },
  BPPRPRSB: { name: "BANCO POPULAR DE PUERTO RICO", country: "PUERTO RICO" },
  BPPRPRSX: { name: "BANCO POPULAR DE PUERTO RICO", country: "PUERTO RICO" },
  BPRCGTGC: { name: "BANCO PROMERICA, S.A.", country: "GUATEMALA" },
  BPRHHNTE: { name: "BANCO PROMERICA, S.A.", country: "HONDURAS" },
  BPROVECA: { name: "BANCO PROVINCIAL S.A. BANCO UNIVERSAL", country: "VENEZUELA" },
  BPSFARBA: { name: "NUEVO BANCO DE SANTA FE S.A.", country: "ARGENTINA" },
  BQBHCHGG: { name: "ONE SWISS BANK SA", country: "SWITZERLAND" },
  BRASBRRJ: { name: "BANCO DO BRASIL S.A.", country: "BRAZIL" },
  BRASDEFF: { name: "BANCO DO BRASIL S.A FRANKFURT BRANCH", country: "GERMANY" },
  BRASFRPP: { name: "BANCO DO BRASIL AG SUCCURSALE FRANCE", country: "FRANCE" },
  BRASUS33: { name: "BANCO DO BRASIL S.A", country: "UNITED STATES" },
  BRASUS3M: { name: "BANCO DO BRASIL S.A", country: "UNITED STATES" },
  BRBTUS33: { name: "TRUIST BANK", country: "UNITED STATES" },
  BRBTUS3X: { name: "TRUIST BANK", country: "UNITED STATES" },
  BRDDGB2L: { name: "BRIDGEPOINT ADVISERS LIMITED", country: "UNITED KINGDOM" },
  BRDEROBU: { name: "BRD - GROUPE SOCIETE GENERALE SA", country: "ROMANIA" },
  BREDFRPP: { name: "BRED BANQUE POPULAIRE", country: "FRANCE" },
  BREXPLPW: { name: "mBank S.A. (FORMERLY BRE BANK S.A.)", country: "POLAND" },
  BRGEFRPP: { name: "BRED GESTION", country: "FRANCE" },
  BRGSBRRS: { name: "BANCO DO ESTADO DO RIO GRANDE DO SUL S/A", country: "BRAZIL" },
  BRINIDJA: { name: "PT. BANK RAKYAT INDONESIA (PERSERO), Tbk", country: "INDONESIA" },
  BRKLUS3A: { name: "BROOKLINE BANK", country: "UNITED STATES" },
  BRLADE22: { name: "NORDDEUTSCHE LANDESBANK", country: "GERMANY" },
  BROUUYMM: { name: "BANCO DE LA REPUBLICA ORIENTAL DEL URUGUAY", country: "URUGUAY" },
  BRRLGTGC: { name: "BANCO DE DESARROLLO RURAL S.A.", country: "GUATEMALA" },
  BRWAQAQA: { name: "DUKHAN BANK", country: "QATAR" },
  BSABESBB: { name: "BANCO DE SABADELL, S.A", country: "SPAIN" },
  BSABFRPP: { name: "BANCO DE SABADELL", country: "FRANCE" },
  BSABGB2L: { name: "BANCO DE SABADELL", country: "UNITED KINGDOM" },
  BSABMAMC: { name: "BANCO DE SABADELL, S.A.", country: "MOROCCO" },
  BSABUS3X: { name: "BANCO DE SABADELL S.A", country: "UNITED STATES" },
  BSALSVSS: { name: "BANCO DAVIVIENDA SALVADORENO SA", country: "EL SALVADOR" },
  BSANADAD: { name: "BANC SABADELL D'ANDORRA S.A.", country: "ANDORRA" },
  BSAPPEPL: { name: "BANCO SANTANDER PERU S.A.", country: "PERU" },
  BSCHARBA: { name: "BANCO SANTANDER RIO S.A.", country: "ARGENTINA" },
  BSCHBRSP: { name: "BANCO SANTANDER (BRASIL) S.A.", country: "BRAZIL" },
  BSCHCLRM: { name: "BANCO SANTANDER CHILE", country: "CHILE" },
  BSCHDEFF: { name: "BANCO SANTANDER S.A.", country: "GERMANY" },
  BSCHESMG: { name: "BANCO SANTANDER S.A", country: "SPAIN" },
  BSCHESMM: { name: "BANCO SANTANDER S.A", country: "SPAIN" },
  BSCHFRPP: { name: "BANCO SANTANDER S.A. (FRANCE)", country: "FRANCE" },
  BSCHGB2L: { name: "BANCO SANTANDER S.A", country: "UNITED KINGDOM" },
  BSCHHKHH: { name: "BANCO SANTANDER, S. A. HONG KONG BRANCH", country: "HONG KONG" },
  BSCHITMM: { name: "BANCO SANTANDER, S.A.MILANO BRANCH", country: "ITALY" },
  BSCHKYKX: { name: "BANCO SANTANDER (BRASIL) S/A GRAND CAYMAN BRANCH", country: "CAYMAN ISLANDS" },
  BSCHUS33: { name: "BANCO SANTANDER S.A", country: "UNITED STATES" },
  BSCHUYMM: { name: "BANCO SANTANDER", country: "URUGUAY" },
  BSCLCLRM: { name: "BANCO SECURITY", country: "CHILE" },
  BSCTCH22: { name: "BANCA STATO CANTONE TICINO", country: "SWITZERLAND" },
  BSDTGB2X: { name: "THE BANK OF NEW YORK MELLON", country: "UNITED KINGDOM" },
  BSDTUS33: { name: "MELLON BANK, N.A.", country: "UNITED STATES" },
  BSEAROBU: { name: "CREDIT AGRICOLE BANK ROMANIA S.A.", country: "ROMANIA" },
  BSNJCRSJ: { name: "BANCO BAC SAN JOSE", country: "COSTA RICA" },
  BSPFFRPP: { name: "Banque Palatine SA", country: "FRANCE" },
  BSSSESM2: { name: "CACEIS BANK SPAIN SAU", country: "SPAIN" },
  BSUDARBA: { name: "BANCO PATAGONIA S.A.", country: "ARGENTINA" },
  BSUDCOBB: { name: "BANCO GNB SUDAMERIS S.A.", country: "COLOMBIA" },
  BSUDPEPL: { name: "SCOTIABANK PERU S.A.A.", country: "PERU" },
  BSUIAEAD: { name: "CREDIT AGRICOLE CIB", country: "UNITED ARAB EMIRATES" },
  BSUIAU2S: { name: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK", country: "AUSTRALIA" },
  BSUIDEFF: { name: "CREDIT AGRICOLE CIB DEUTSCHLAND", country: "GERMANY" },
  BSUIESMM: { name: "CREDIT AGRICOLE CIB", country: "SPAIN" },
  BSUIFIHH: { name: "CREDIT AGRICOLE CIB HELSINKI BRANCH", country: "FINLAND" },
  BSUIFRPP: { name: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK", country: "FRANCE" },
  BSUIITMM: { name: "CREDIT AGRICOLE CIB MILANO", country: "ITALY" },
  BSUIJPJT: { name: "CREDIT AGRICOLE CIB", country: "JAPAN" },
  BSUISESS: { name: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK(FRANCE) SWEDEN", country: "SWEDEN" },
  BSUPARBA: { name: "BANCO SUPERVIELLE S.A", country: "ARGENTINA" },
  BTBKTNTT: { name: "BANQUE DE TUNISIE", country: "TUNISIA" },
  BTDLIE2D: { name: "HSBC CONTINENTAL EUROPE", country: "IRELAND" },
  BTFEHKHH: { name: "THE HSBC INSTITUTIONAL TRUST SERVICES (ASIA) LTD", country: "HONG KONG" },
  BTRLRO22: { name: "BANCA TRANSILVANIA S.A", country: "ROMANIA" },
  BTVAAT22: { name: "BANK FUER TIROL UND VORARLBERG Aktiengesellschaft", country: "AUSTRIA" },
  BUDAHUHB: { name: "MKB BANK NYRT.", country: "HUNGARY" },
  BUINBGSF: { name: "ALLIANZ BANK BULGARIA AD", country: "BULGARIA" },
  BUKBGB22: { name: "BARCLAYS BANK PLC", country: "UNITED KINGDOM" },
  BUNONIMA: { name: "BANCO FICOHSA NICARAGUA S.A.", country: "NICARAGUA" },
  BVBEHKHH: { name: "UniCredit Bank AG Hong Kong", country: "HONG KONG" },
  BVBESGSG: { name: "UNICREDIT BANK AG", country: "SINGAPORE" },
  BWSTUS66: { name: "BANK OF THE WEST", country: "UNITED STATES" },
  BXBACRSJ: { name: "BANCO DAVIVIENDA (COSTA RICA), S.A.", country: "COSTA RICA" },
  BYBAIQBA: { name: "BYBLOS BANK SAL ARBIL BRANCH", country: "IRAQ" },
  BYBALBBX: { name: "BYBLOS BANK S.A.L", country: "LEBANON" },
  BYBBBEBB: { name: "BYBLOS BANK EUROPE S.A.", country: "BELGIUM" },
  BYLADEMM: { name: "BAYERISCHE LANDESBANK, MUENCHEN", country: "GERMANY" },
  BYLAGB22: { name: "BAYERISCHE LANDESBANK, LONDON", country: "UNITED KINGDOM" },
  BZITTNTT: { name: "BANQUE ZITOUNA", country: "TUNISIA" },
  CACDDJJD: {
    name: "COOPERATIVE AND AGRICULTURAL CREDIT INTERNATIONAL BANK CAC INTERNATIONAL BANK",
    country: "DJIBOUTI",
  },
  CADKSNDA: { name: "LA BANQUE AGRICOLE", country: "SENEGAL" },
  CAFECOBB: { name: "BANCO DAVIVIENDA S.A.", country: "COLOMBIA" },
  CAFEUS3M: { name: "BANCO DAVIVIENDA S.A.", country: "UNITED STATES" },
  CAGLESMM: { name: "ABANCA Corporacion Bancaria, S.A.", country: "SPAIN" },
  CAGRSVSS: { name: "BANCO AGRICOLA, S.A.", country: "EL SALVADOR" },
  CAHMESMM: { name: "CAIXABANK, S.A.", country: "SPAIN" },
  CAIXESBB: { name: "CAIXABANK, S.A.", country: "SPAIN" },
  CAIXMAMC: { name: "CAIXABANK,S.A. SUCCURSALE AU MAROC", country: "MOROCCO" },
  CAIXPLPW: { name: "CAIXABANK, S.A. (SPOLKA AKCYJNA) ODDZIAL W POLSCE", country: "POLAND" },
  CAKTUS44: { name: "CASTLELAKE, L.P.", country: "UNITED STATES" },
  CARBVECA: { name: "BANCO DEL CARIBE C.A. BANCO UNIVERSAL", country: "VENEZUELA" },
  CARPRO22: { name: "PATRIA BANK S.A.", country: "ROMANIA" },
  CASOCOBB: { name: "BANCO CAJA SOCIAL S.A.", country: "COLOMBIA" },
  CAZRES2Z: { name: "IBERCAJA BANCO S A", country: "SPAIN" },
  CBAFKENX: { name: "NCBA BANK KENYA PLC", country: "KENYA" },
  CBAOSNDA: { name: "COMPAGNIE BANCAIRE DE L'AFRIQUE OCC. CBAO", country: "SENEGAL" },
  CBBABRSP: { name: "ITAU UNIBANCO S.A", country: "BRAZIL" },
  CBBABSNS: { name: "ITAU UNIBANCO S.A. - NASSAU BRANCH", country: "BAHAMAS" },
  CBBKUS6L: { name: "CITIZENS BUSINESS BANK", country: "UNITED STATES" },
  CBDUAEAD: { name: "COMMERCIAL BANK OF DUBAI (P.S.C.)", country: "UNITED ARAB EMIRATES" },
  CBETETAA: { name: "COMMERCIAL BANK OF ETHIOPIA", country: "ETHIOPIA" },
  CBKCUS44: { name: "COMMERCE BANK", country: "UNITED STATES" },
  CBLUCH22: { name: "CORNER BANK LIMITED", country: "SWITZERLAND" },
  CBMVMVMV: { name: "COMMERCIAL BANK OF MALDIVES PRIVATE LIMITED", country: "MALDIVES" },
  CBQAQAQA: { name: "THE COMMERCIAL BANK (Q.S.C)", country: "QATAR" },
  CBSWCHZZ: { name: "CITIBANK (SWITZERLAND) AG", country: "SWITZERLAND" },
  CCAICOBB: { name: "BANCO AGRARIO DE COLOMBIA S.A.", country: "COLOMBIA" },
  CCATNL2A: { name: "CITCO NEDERLAND B.V.", country: "NETHERLANDS" },
  CCBGCNBJ: { name: "CHINA CONSTRUCTION BANK", country: "CHINA" },
  CCBPFRPP: { name: "BANQUES POPULAIRES-GROUPE BPCE", country: "FRANCE" },
  CCBQHKAX: { name: "CHINA CONSTRUCTION BANK (ASIA) CORPORATION LIMITED", country: "HONG KONG" },
  CCCMPTPL: { name: "CAIXA CENTRAL CREDITO AGRICOLA MUTUO", country: "PORTUGAL" },
  CCDQCAMM: { name: "FEDERATION DES CAISSES DESJARDINS DU QUEBEC", country: "CANADA" },
  CCEGITR2: { name: "CASSA COMPENSAZIONE E GARANZIA S.P.A.", country: "ITALY" },
  CCEICMCX: { name: "AFRILAND FIRST BANK", country: "CAMEROON" },
  CCEYBDDH: { name: "COMMERCIAL BANK OF CEYLON PLC", country: "BANGLADESH" },
  CCEYLKLX: { name: "COMMERCIAL BANK OF CEYLON PLC", country: "SRI LANKA" },
  CCFRFRPP: { name: "HSBC CONTINENTAL EUROPE", country: "FRANCE" },
  CCIOCRSJ: { name: "BANCO BCT S.A.", country: "COSTA RICA" },
  CCOCESMM: { name: "BANCO CAMINOS, S.A.", country: "SPAIN" },
  CCRIES2A: { name: "CAJAMAR CAJA RURAL, SOCIEDAD COOPERATIVA DE CREDITO", country: "SPAIN" },
  CDCGFRPP: { name: "CAISSE DES DEPOTS ET CONSIGNATIONS", country: "FRANCE" },
  CDMAMAMC: { name: "CREDIT DU MAROC", country: "MOROCCO" },
  CECAESMM: { name: "CECABANK, S.A.", country: "SPAIN" },
  CECBBGSF: { name: "CENTRAL COOPERATIVE BANK PLC", country: "BULGARIA" },
  CEDELULL: { name: "CLEARSTREAM BANKING S.A.", country: "LUXEMBOURG" },
  CEKOCZPP: { name: "CESKOSLOVENSKA OBCHODNI BANKA AS", country: "CZECHIA" },
  CELLLULL: { name: "ING LUXEMBOURG S.A.", country: "LUXEMBOURG" },
  CENAIDJA: { name: "BANK CENTRAL ASIA", country: "INDONESIA" },
  CEPAFRPP: { name: "CAISSES D'EPARGNE-GROUPE BPCE", country: "FRANCE" },
  CEPANCNM: { name: "BANQUE DE NOUVELLE CALEDONIE", country: "NEW CALEDONIA" },
  CEPAPFTP: { name: "BANQUE DE TAHITI S.A.", country: "FRENCH POLYNESIA" },
  CFFRFRPP: { name: "CREDIT FONCIER DE FRANCE", country: "FRANCE" },
  CFMOMCMX: { name: "CFM INDOSUEZ WEALTH", country: "MONACO" },
  CFSHIE2D: { name: "CITCO FUND SERVICES IRELAND LIMITED Mr Wayne Gibbons", country: "IRELAND" },
  CFVACOBC: { name: "CORPORACION FINANCIERA COLOMBIANA S.A.", country: "COLOMBIA" },
  CGDIFRPP: { name: "CAIXA GERAL DE DEPOSITOS", country: "FRANCE" },
  CGDIMZMA: { name: "BANCO COMERCIAL E DE INVESTIMENTOS, SA.", country: "MOZAMBIQUE" },
  CGDIPTPL: { name: "CAIXA GERAL DE DEPOSITOS", country: "PORTUGAL" },
  CHASARBA: { name: "JPMORGAN CHASE BANK, N.A. - BUENOS AIRES BRANCH", country: "ARGENTINA" },
  CHASAU2X: { name: "JPMORGAN CHASE BANK, N. A. - SYDNEY BRANCH", country: "AUSTRALIA" },
  CHASBEBX: { name: "J.P. MORGAN SE BRUSSELS BRANCH", country: "BELGIUM" },
  CHASBRSP: { name: "BANCO JP MORGAN S.A.", country: "BRAZIL" },
  CHASCATT: { name: "JPMORGAN CHASE BANK, TORONTO BRANCH", country: "CANADA" },
  CHASCHGX: { name: "JPMORGAN CHASE BANK, N.A.,COLOMBUS, ZURICH BRANCH", country: "SWITZERLAND" },
  CHASCLRM: { name: "JPMORGAN CHASE BANK, N.A. - SANTIAGO BRANCH", country: "CHILE" },
  CHASCN22: { name: "JPMORGAN CHASE BANK (CHINA) COMPANY LIMITED, BEIJING BRANCH", country: "CHINA" },
  CHASCN2G: { name: "JPMORGAN CHASE BANK (CHINA) COMPANY LIMITED, GUANGZHOU BRANCH", country: "CHINA" },
  CHASCNBC: { name: "JPMORGAN CHASE BANK (CHINA) COMPANY LIMITED, CHENGDU BRANCH", country: "CHINA" },
  CHASCNBX: { name: "JPMORGAN CHASE BANK (CHINA) COMPANY LIMITED, TIANJIN BRANCH", country: "CHINA" },
  CHASCNSH: { name: "JPMORGAN CHASE BANK (CHINA) COMPANY LIMITED, SHANGHAI BRANCH", country: "CHINA" },
  CHASCOBB: { name: "BANCO J.P. MORGAN COLOMBIA S.A.", country: "COLOMBIA" },
  CHASDEFX: { name: "J.P. MORGAN SE", country: "GERMANY" },
  CHASESM3: { name: "JPMORGAN CHASE BANK, N.A. - MADRID BRANCH", country: "SPAIN" },
  CHASFRPP: { name: "JPMORGAN CHASE BANK, N.A. - PARIS BRANCH", country: "FRANCE" },
  CHASGB22: { name: "J.P. MORGAN EUROPE LIMITED", country: "UNITED KINGDOM" },
  CHASGB2L: { name: "JPMORGAN CHASE BANK, N.A. - LONDON BRANCH", country: "UNITED KINGDOM" },
  CHASHKHH: { name: "JPMORGAN CHASE BANK, N.A. - HONG KONG BRANCH", country: "HONG KONG" },
  CHASIDJX: { name: "JPMORGAN CHASE BANK, N.A. - JAKARTA BRANCH", country: "INDONESIA" },
  CHASIE2D: { name: "J.P. MORGAN SE, DUBLIN BRANCH", country: "IRELAND" },
  CHASIE2X: { name: "J.P. MORGAN SE, DUBLIN BRANCH", country: "IRELAND" },
  CHASIE4L: { name: "J.P. MORGAN SE, DUBLIN BRANCH", country: "IRELAND" },
  CHASINBX: { name: "JPMORGAN CHASE BANK, N.A. - MUMBAI BRANCH", country: "INDIA" },
  CHASITMX: { name: "JPMORGAN CHASE BANK, N.A. - MILAN BRANCH", country: "ITALY" },
  CHASJPJT: { name: "JPMORGAN CHASE BANK, N.A. - TOKYO BRANCH", country: "JAPAN" },
  CHASKRSX: { name: "JPMORGAN CHASE BANK, N.A.- SEOUL BRANCH", country: "KOREA, REPUBLIC OF" },
  CHASLULA: { name: "J.P. MORGAN SE - LUXEMBOURG BRANCH", country: "LUXEMBOURG" },
  CHASLULX: { name: "J.P. MORGAN SE - LUXEMBOURG BRANCH", country: "LUXEMBOURG" },
  CHASMXMX: { name: "BANCO J.P. MORGAN S.A.", country: "MEXICO" },
  CHASMY2A: { name: "JPMORGAN CHASE BANK, N.A. ? LABUAN BRANCH", country: "MALAYSIA" },
  CHASMYKX: { name: "J.P. MORGAN CHASE BANK BERHAD", country: "MALAYSIA" },
  CHASNL2X: { name: "J.P. MORGAN SE AMSTERDAM BRANCH", country: "NETHERLANDS" },
  CHASNZ2A: { name: "JPMORGAN CHASE BANK, N.A. NEW ZEALAND BRANCH", country: "NEW ZEALAND" },
  CHASPHMM: { name: "JPMORGAN CHASE BANK, N.A. - MANILA BRANCH", country: "PHILIPPINES" },
  CHASRUMX: { name: "CB ''J.P. MORGAN BANK INTERNATIONAL'' (LLC)", country: "RUSSIAN FEDERATION" },
  CHASSARI: { name: "JPMORGAN CHASE BANK, N.A. - RIYADH BRANCH", country: "SAUDI ARABIA" },
  CHASSGSG: { name: "JPMORGAN CHASE BANK, N.A. - SINGAPORE BRANCH", country: "SINGAPORE" },
  CHASTHBX: { name: "JPMORGAN CHASE BANK, N.A. - BANGKOK BRANCH", country: "THAILAND" },
  CHASTWTX: { name: "JPMORGAN CHASE BANK, N.A. - TAIPEI BRANCH", country: "TAIWAN, REPUBLIC OF CHINA" },
  CHASUS33: { name: "JPMORGAN CHASE BANK, N.A.", country: "UNITED STATES" },
  CHASVNVX: { name: "JPMORGAN CHASE BANK, N.A. - HO CHI MINH CITY BRANCH", country: "VIET NAM" },
  CHBHCNBT: { name: "CHINA BOHAI BANK CO., LTD", country: "CHINA" },
  CHDUFRPP: { name: "CHOLET DUPONT OUDART", country: "FRANCE" },
  CIALCHBB: { name: "BANK CIC (SWITZERLAND) LTD.", country: "SWITZERLAND" },
  CIBBKHPP: { name: "CIMB BANK PLC", country: "CAMBODIA" },
  CIBBMY2L: { name: "CIMB BANK BERHAD, LABUAN OFFSHORE BRANCH", country: "MALAYSIA" },
  CIBBMYKL: { name: "CIMB BANK BERHAD", country: "MALAYSIA" },
  CIBBSGSG: { name: "CIMB BANK BERHAD", country: "SINGAPORE" },
  CIBCCATT: { name: "CANADIAN IMPERIAL BANK OF COMMERCE", country: "CANADA" },
  CIBCGB2L: { name: "CANADIAN IMPERIAL BANK OF COMMERCE", country: "UNITED KINGDOM" },
  CIBEEGCX: { name: "COMMERCIAL INTERNATIONAL BANK, SAE", country: "EGYPT" },
  CIBHHUHB: { name: "CIB BANK LTD. (FORMERLY CENTRAL- EUROPEAN INT.BANK LTD.)", country: "HUNGARY" },
  CIBKCNBB: { name: "CHINA CITIC BANK", country: "CHINA" },
  CIBKCNBJ: { name: "CHINA CITIC BANK", country: "CHINA" },
  CIHMMAMC: { name: "CREDIT IMMOBILIER ET HOTELIER", country: "MOROCCO" },
  CINAUS6L: { name: "CITY NATIONAL BANK", country: "UNITED STATES" },
  CITCNL2A: { name: "CITCO BANK NEDERLAND N.V Teleport 8 - Telestone", country: "NETHERLANDS" },
  CITIAEAD: { name: "CITIBANK N.A.", country: "UNITED ARAB EMIRATES" },
  CITIARBA: { name: "CITIBANK N.A.", country: "ARGENTINA" },
  CITIATWX: { name: "CITIBANK EUROPE PLC AUSTRIA BRANCH", country: "AUSTRIA" },
  CITIAU2X: { name: "CITIBANK N.A.", country: "AUSTRALIA" },
  CITIAU3X: { name: "CITIBANK N.A.", country: "AUSTRALIA" },
  CITIAUSX: { name: "CITIBANK N.A.", country: "AUSTRALIA" },
  CITIBDDX: { name: "CITIBANK N.A.", country: "BANGLADESH" },
  CITIBEBX: { name: "CITIBANK EUROPE PLC BELGIUM BRANCH", country: "BELGIUM" },
  CITIBGSF: { name: "Citibank Europe Plc., Bulgaria Branch", country: "BULGARIA" },
  CITIBHBX: { name: "CITIBANK N.A.", country: "BAHRAIN" },
  CITIBRBR: { name: "BANCO CITIBANK SA", country: "BRAZIL" },
  CITIBRSP: { name: "CITIBANK NA - BRAZIL", country: "BRAZIL" },
  CITIBRSX: { name: "CITIBANK NA - BRAZIL", country: "BRAZIL" },
  CITIBSNA: { name: "CITIBANK N.A.", country: "BAHAMAS" },
  CITICATT: { name: "CITIBANK CANADA", country: "CANADA" },
  CITICDKX: { name: "CITIGROUP CONGO SA", country: "DEMOCRATIC REPUBLIC OF CONGO" },
  CITICHZZ: { name: "CITIBANK N.A.", country: "SWITZERLAND" },
  CITICIAX: { name: "CITIBANK COTE D'IVOIRE SA", country: "COTE D'IVOIRE" },
  CITICLRS: { name: "BANCO DE CHILE", country: "CHILE" },
  CITICMCX: { name: "CITIBANK N.A.", country: "CAMEROON" },
  CITICNSX: { name: "CITIBANK (CHINA) CO., LTD.", country: "CHINA" },
  CITICOBB: { name: "CITIBANK COLOMBIA", country: "COLOMBIA" },
  CITICZPX: { name: "CITIBANK EUROPE PLC, ORGANIZACNI SLOZKA", country: "CZECHIA" },
  CITIDE55: { name: "CITIBANK N.A, FILIALE FRANKFURT AM MAIN", country: "GERMANY" },
  CITIDEFF: { name: "CITIGROUP GLOBAL MARKETS EUROPE AG", country: "GERMANY" },
  CITIDKKK: { name: "CITIBANK EUROPE PLC DENMARK BRANCH", country: "DENMARK" },
  CITIDKKX: { name: "CITIBANK EUROPE PLC DENMARK BRANCH", country: "DENMARK" },
  CITIDOSD: { name: "CITIBANK N.A. SUCURSAL REPUBLICA DOMINICANA", country: "DOMINICAN REPUBLIC" },
  CITIDZAL: { name: "CITIBANK N.A. ALGERIA", country: "ALGERIA" },
  CITIEGCX: { name: "CITIBANK N.A. - CAIRO BRANCH", country: "EGYPT" },
  CITIESMX: { name: "CITIBANK EUROPE PLC SPAIN BRANCH", country: "SPAIN" },
  CITIFIHX: { name: "CITIBANK EUROPE PLC FINLAND BRANCH", country: "FINLAND" },
  CITIFRPP: { name: "CITIBANK EUROPE PLC FRANCE BRANCH", country: "FRANCE" },
  CITIGALX: { name: "CITIBANK N.A.", country: "GABON" },
  CITIGB22: { name: "CITIBANK N.A. INTERNATIONAL", country: "UNITED KINGDOM" },
  CITIGB2L: { name: "CITIBANK N.A.", country: "UNITED KINGDOM" },
  CITIGB2P: { name: "CITIBANK N.A.", country: "UNITED KINGDOM" },
  CITIGBK2: { name: "CITIBANK N.A.", country: "UNITED KINGDOM" },
  CITIGBKA: { name: "CITIBANK N.A.", country: "UNITED KINGDOM" },
  CITIGBKB: { name: "CITIBANK N.A.", country: "UNITED KINGDOM" },
  CITIGBKC: { name: "CITIBANK N.A.", country: "UNITED KINGDOM" },
  CITIGBKD: { name: "CITIBANK N.A.", country: "UNITED KINGDOM" },
  CITIGBKE: { name: "CITIBANK N.A.", country: "UNITED KINGDOM" },
  CITIGBKF: { name: "CITIBANK N.A.", country: "UNITED KINGDOM" },
  CITIGRAA: { name: "CITIBANK EUROPE PLC GREECE BRANCH", country: "GREECE" },
  CITIGTGC: { name: "CITIBANK N.A. SUCURSAL GUATEMALA", country: "GUATEMALA" },
  CITIHKAX: { name: "CITIBANK (HONG KONG) LIMITED", country: "HONG KONG" },
  CITIHKHC: { name: "CITICORP INTERNATIONAL LTD", country: "HONG KONG" },
  CITIHKHX: { name: "CITIBANK NA", country: "HONG KONG" },
  CITIHUHX: { name: "CITIBANK EUROPE PLC HUNGARIAN BRANCH", country: "HUNGARY" },
  CITIIDJX: { name: "CITIBANK N.A", country: "INDONESIA" },
  CITIIE2X: { name: "CITIBANK EUROPE PLC", country: "IRELAND" },
  CITIIE3D: { name: "CITIBANK EUROPE PLC", country: "IRELAND" },
  CITIIE4X: { name: "CITIBANK EUROPE PLC", country: "IRELAND" },
  CITIILIT: { name: "CITIBANK N.A. Plantinum Building", country: "ISRAEL" },
  CITIINBX: { name: "CITIBANK, N.A", country: "INDIA" },
  CITIITMX: { name: "CITIBANK N.A", country: "ITALY" },
  CITIJEHX: { name: "CITICORP BANKING CORPORATION", country: "JERSEY C.I." },
  CITIJEJX: { name: "CITIBANK N.A., JERSEY BRANCH", country: "JERSEY C.I." },
  CITIJESX: { name: "CITIBANK N.A., JERSEY BRANCH", country: "JERSEY C.I." },
  CITIJOAX: { name: "CITIBANK N.A", country: "JORDAN" },
  CITIJPJT: { name: "CITIBANK, N.A. TOKYO BRANCH", country: "JAPAN" },
  CITIKENA: { name: "CITIBANK N.A.", country: "KENYA" },
  CITIKRSX: { name: "CITIBANK KOREA INC.", country: "KOREA, REPUBLIC OF" },
  CITIKWKW: { name: "CITIBANK N.A.", country: "KUWAIT" },
  CITIKZKA: { name: "CJSC CITIBANK KAZAKHSTAN", country: "KAZAKHSTAN" },
  CITILBBE: { name: "CITIBANK NA-BEIRUT BRANCH", country: "LEBANON" },
  CITILKLX: { name: "CITIBANK N.A", country: "SRI LANKA" },
  CITILUL2: { name: "CITIBANK EUROPE PLC LUXEMBOURG BRANCH", country: "LUXEMBOURG" },
  CITILULX: { name: "CITIBANK EUROPE PLC LUXEMBOURG BRANCH", country: "LUXEMBOURG" },
  CITIMAMC: { name: "CITIBANK MAGHREB Immeuble 1", country: "MOROCCO" },
  CITIMOMX: { name: "CITIBANK NA, MACAU", country: "MACAO" },
  CITIMYKL: { name: "CITIBANK BERHAD", country: "MALAYSIA" },
  CITINGLA: { name: "CITIBANK NIGERIA LIMITED", country: "NIGERIA" },
  CITINL2X: { name: "CITIBANK EUROPE PLC NETHERLANDS BRANCH", country: "NETHERLANDS" },
  CITINOKX: { name: "CITIBANK EUROPE PLC NORWAY BRANCH", country: "NORWAY" },
  CITINZ2X: { name: "CITIBANK N.A., NEW ZEALAND BRANCH", country: "NEW ZEALAND" },
  CITIPAPA: { name: "CITIBANK N.A. SUCURSAL PANAMA", country: "PANAMA" },
  CITIPEPL: { name: "CITIBANK DEL PERU S.A.", country: "PERU" },
  CITIPHMX: { name: "CITIBANK N.A (MANILA BRANCH)", country: "PHILIPPINES" },
  CITIPKKX: { name: "CITIBANK N. A., PAKISTAN", country: "PAKISTAN" },
  CITIPLPX: { name: "BANK HANDLOWY W, WARSZAWIE SA", country: "POLAND" },
  CITIPRSJ: { name: "CITIBANK N.A.", country: "PUERTO RICO" },
  CITIPTPX: { name: "CITIBANK EUROPE PLC PORTUGAL BRANCH", country: "PORTUGAL" },
  CITIQAQA: { name: "CITIBANK N.A, QATAR", country: "QATAR" },
  CITIROBU: { name: "CITIBANK EUROPE PLC, DUBLIN - SUCURSALA ROMANIA", country: "ROMANIA" },
  CITIRUMX: { name: "AO CITIBANK, MOSCOW", country: "RUSSIAN FEDERATION" },
  CITISESX: { name: "CITIBANK EUROPE PLC SWEDEN BRANCH", country: "SWEDEN" },
  CITISGSG: { name: "CITIBANK N.A", country: "SINGAPORE" },
  CITISKBA: { name: "CITIBANK EUROPE PLC, POBOCKA ZAHRANICNEJ BANKY", country: "SLOVAKIA" },
  CITISNDA: { name: "CITIBANK SENEGAL", country: "SENEGAL" },
  CITITHBX: { name: "CITIBANK N.A", country: "THAILAND" },
  CITITNTX: { name: "CITIBANK N.A", country: "TUNISIA" },
  CITITRIX: { name: "CITIBANK A.S.", country: "TURKEY" },
  CITITTPS: { name: "CITIBANK (TRINIDAD AND TOBAGO) LIMITED", country: "TRINIDAD AND TOBAGO" },
  CITITW2P: { name: "CITIBANK N.A", country: "TAIWAN, REPUBLIC OF CHINA" },
  CITITWTX: { name: "CITIBANK TAIWAN LIMITED", country: "TAIWAN, REPUBLIC OF CHINA" },
  CITITZTZ: { name: "CITIBANK TANZANIA LTD", country: "TANZANIA, UNITED REPUBLIC OF" },
  CITIUAUK: { name: "JOINT STOCK COMPANY 'CITIBANK'", country: "UKRAINE" },
  CITIUGKA: { name: "CITIBANK UGANDA LIMITED", country: "UGANDA" },
  CITIUS33: { name: "CITIBANK N.A.", country: "UNITED STATES" },
  CITIUS3P: { name: "CITIBANK N.A.", country: "UNITED STATES" },
  CITIUYMM: { name: "CITIBANK N.A. URUGUAY", country: "URUGUAY" },
  CITIVECA: { name: "CITIBANK N.A. SUCURSAL VENEZUELA", country: "VENEZUELA" },
  CITIVNVX: { name: "CITIBANK N.A", country: "VIET NAM" },
  CITIZAJX: { name: "CITIBANK SOUTH AFRICA", country: "SOUTH AFRICA" },
  CITIZMLU: { name: "CITIBANK ZAMBIA LTD", country: "ZAMBIA" },
  CITTGB2L: { name: "CITIBANK EUROPE PLC UK BRANCH", country: "UNITED KINGDOM" },
  CITTITMM: { name: "CITIBANK EUROPE PLC ITALY BRANCH", country: "ITALY" },
  CIWMGB2L: { name: "CIBC WORLD MARKETS PLC", country: "UNITED KINGDOM" },
  CKBCMEPG: {
    name: "CRNOGORSKA KOMERCIJALNA BANKA AD PODORICA (MONOTENEGRIN COM. BANKA)",
    country: "MONTENEGRO",
  },
  CLAOGB2L: { name: "CALASTONE LTD", country: "UNITED KINGDOM" },
  CLIBLBBX: { name: "CREDIT LIBANAIS S.A.L", country: "LEBANON" },
  CLMDMGMG: { name: "BNI MADAGASCAR", country: "MADAGASCAR" },
  CLPEES2M: { name: "CAJA LABORAL POPULAR, COOP. DE CREDITO (CAJA LABORAL)", country: "SPAIN" },
  CMBCAU2S: { name: "CHINA MERCHANTS BANK CO., LTD.", country: "AUSTRALIA" },
  CMBCCNB2: { name: "CHINA MERCHANTS BANK H.O.", country: "CHINA" },
  CMBCCNBS: { name: "CHINA MERCHANTS BANK H.O.", country: "CHINA" },
  CMBMMCMX: { name: "COMPAGNIE MONEGASQUE DE BANQUE SAM", country: "MONACO" },
  CMBRFR2B: { name: "CREDIT MUTUEL ARKEA", country: "FRANCE" },
  CMCIFRPA: { name: "BANQUE FEDERATIVE DU CREDIT MUTUEL", country: "FRANCE" },
  CMCIFRPP: { name: "CREDIT INDUSTRIEL ET COMMERCIAL", country: "FRANCE" },
  CMCIGB2L: { name: "CREDIT INDUSTRIEL ET COMMERCIAL LONDON BRANCH", country: "UNITED KINGDOM" },
  CMCISGSG: { name: "CREDIT INDUSTRIEL ET COMMERCIAL", country: "SINGAPORE" },
  CMCIUS33: { name: "CREDIT INDUSTRIEL ET COMMERCIAL, NEW YORK BRANCH", country: "UNITED STATES" },
  CMFXGB2L: { name: "CAMBRIDGE MERCANTILE CORP. (UK) LIMITED", country: "UNITED KINGDOM" },
  CNBFUS3M: { name: "CITY NATIONAL BANK OF FLORIDA", country: "UNITED STATES" },
  CNORGB22: { name: "NORTHERN TRUST COMPANY, THE", country: "UNITED KINGDOM" },
  CNORGB2X: { name: "NORTHERN TRUST COMPANY, THE", country: "UNITED KINGDOM" },
  CNORIE2D: { name: "NORTHERN TRUST FIDUCIARY SERVICES (IRELAND) LIMITED", country: "IRELAND" },
  CNORLULX: { name: "NORTHERN TRUST GLOBAL SERVICES SE", country: "LUXEMBOURG" },
  CNORUS33: { name: "THE NORTHERN TRUST INTERNATIONAL BANKING CORPORATION", country: "UNITED STATES" },
  CNORUS44: { name: "THE NORTHERN TRUST COMPANY", country: "UNITED STATES" },
  COBAATWX: { name: "COMMERZBANK AG, NIEDERLASSUNG WIEN WIEN", country: "AUSTRIA" },
  COBABEBX: { name: "COMMERZBANK AG BRUSSELS BRANCH", country: "BELGIUM" },
  COBACHZH: { name: "COMMERZBANK AG", country: "SWITZERLAND" },
  COBACNSX: { name: "COMMERZBANK AG", country: "CHINA" },
  COBACZPX: { name: "COMMERZBANK AG", country: "CZECHIA" },
  COBADEFF: { name: "COMMERZBANK AG", country: "GERMANY" },
  COBADEFI: { name: "COMMERZBANK AG", country: "GERMANY" },
  COBAESMX: { name: "COMMERZBANK AG", country: "SPAIN" },
  COBAFRPX: { name: "COMMERZBANK AG", country: "FRANCE" },
  COBAGB2X: { name: "COMMERZBANK AG Global Custodian Network Management", country: "UNITED KINGDOM" },
  COBAHUHX: { name: "COMMERZBANK ZRT.", country: "HUNGARY" },
  COBAITMM: { name: "COMMERZBANK AG ZFO", country: "ITALY" },
  COBALULU: { name: "COMMERZBANK AG", country: "LUXEMBOURG" },
  COBANL2X: { name: "COMMERZBANK AG KANTOOR AMSTERDAM", country: "NETHERLANDS" },
  COBARUMM: { name: "COMMERZBANK EURASIJA AO", country: "RUSSIAN FEDERATION" },
  COBASKBX: { name: "COMMERZBANK Aktiengesellschaft pobocka zahranicnej bankyBratislava", country: "SLOVAKIA" },
  COBBBJBJ: { name: "UNITED BANK FOR AFRICA BENIN", country: "BENIN" },
  COBZZWHA: { name: "CBZ BANK LIMITED", country: "ZIMBABWE" },
  COEBLALA: {
    name: "BANQUE POUR LE COMMERCE EXTERIEUR LAO PUBLIC",
    country: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
  },
  COFIFRPP: { name: "EDMOND DE ROTHSCHILD (FRANCE)", country: "FRANCE" },
  COKSDE33: { name: "KREISSPARKASSE KOELN", country: "GERMANY" },
  COLBUS66: { name: "COLUMBIA STATE BANK", country: "UNITED STATES" },
  COLOCOBM: { name: "BANCOLOMBIA S.A.", country: "COLOMBIA" },
  COLOPAPA: { name: "BANCOLOMBIA (PANAMA), S.A. PLAZA MARBELLA", country: "PANAMA" },
  COLPCOBB: { name: "SCOTIABANK COLPATRIA S.A.", country: "COLOMBIA" },
  COLSDE33: { name: "SPARKASSE KOELNBONN", country: "GERMANY" },
  COMLAOLU: { name: "BANCO COMERCIAL ANGOLANO, S.A.", country: "ANGOLA" },
  COMMCNSH: { name: "BANK OF COMMUNICATIONS, CO. LTD.", country: "CHINA" },
  COMMDEFF: { name: "BANK OF COMMUNICATIONS CO. LTD FRANKFURT BRANCH", country: "GERMANY" },
  COMMUS33: { name: "BANK OF COMMUNICATIONS CO.,LTD. NEW YORK BRANCH", country: "UNITED STATES" },
  CONBCLRM: { name: "ITAU CORPBANCA", country: "CHILE" },
  CORUTZTZ: { name: "CRDB BANK PLC", country: "TANZANIA, UNITED REPUBLIC OF" },
  CPBKGB22: { name: "CO-OPERATIVE BANK PLC", country: "UNITED KINGDOM" },
  CPBLKHPP: { name: "CAMBODIAN PUBLIC BANK PLC", country: "CAMBODIA" },
  CPOBMMMY: { name: "CB BANK PCL", country: "MYANMAR" },
  CPPBCATT: { name: "CANADA PENSION PLAN INVESTMENT BOARD", country: "CANADA" },
  CRAGCGCG: { name: "LA CONGOLAISE DE BANQUE (LCB)", country: "CONGO" },
  CRASGB2L: { name: "CROWN AGENTS BANK LIMITED", country: "UNITED KINGDOM" },
  CRBAGRAA: { name: "ALPHA BANK S.A.", country: "GREECE" },
  CRBZIT2B: { name: "CASSA DI RISPARMIO DI BOLZANO SPA SUEDTIROLER SPARKASSE AG", country: "ITALY" },
  CRDEUA2N: { name: "JSC 'BANK CREDIT DNEPR'", country: "UKRAINE" },
  CREDCLRM: { name: "BANCO DE CREDITO E INVERSIONES", country: "CHILE" },
  CRESCHZF: { name: "CREDIT SUISSE (SCHWEIZ) AG", country: "SWITZERLAND" },
  CRESCHZH: { name: "CREDIT SUISSE AG", country: "SWITZERLAND" },
  CRESCHZP: { name: "CREDIT SUISSE (SCHWEIZ) AG", country: "SWITZERLAND" },
  CRESCHZZ: { name: "CREDIT SUISSE (SCHWEIZ) AG", country: "SWITZERLAND" },
  CRESESM2: { name: "CREDIT SUISSE BANK (EUROPE) S.A.", country: "SPAIN" },
  CRESGB2L: { name: "CREDIT SUISSE AG, LONDON BRANCH", country: "UNITED KINGDOM" },
  CRESGB2X: { name: "CREDIT SUISSE AG, LONDON BRANCH", country: "UNITED KINGDOM" },
  CRESKRSE: { name: "CREDIT SUISSE AG, SEOUL BRANCH", country: "KOREA, REPUBLIC OF" },
  CRESLULL: { name: "CREDIT SUISSE (LUXEMBOURG) S.A.", country: "LUXEMBOURG" },
  CRGEITGG: { name: "BANCA CARIGE SPA", country: "ITALY" },
  CRLYBEBB: { name: "CREDIT AGRICOLE CIB", country: "BELGIUM" },
  CRLYCNSH: { name: "CREDIT AGRICOLE CIB (CHINA) LIMITED", country: "CHINA" },
  CRLYFRPP: { name: "CREDIT LYONNAIS", country: "FRANCE" },
  CRLYGB2L: { name: "CREDIT AGRICOLE CIB", country: "UNITED KINGDOM" },
  CRLYHKHH: { name: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK", country: "HONG KONG" },
  CRLYINBB: { name: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK INDIA BRANCH", country: "INDIA" },
  CRLYJPJ9: { name: "CREDIT AGRICOLE SECURITIES ASIA B.V. TOKYO BRANCH", country: "JAPAN" },
  CRLYKRSE: { name: "CREDIT AGRICOLE CIB, SEOUL BRANCH", country: "KOREA, REPUBLIC OF" },
  CRLYRU2P: { name: "CREDIT AGRICOLE CIB AO", country: "RUSSIAN FEDERATION" },
  CRLYSGSG: { name: "CREDIT AGRICOLE CIB", country: "SINGAPORE" },
  CRLYTWTP: { name: "CREDIT AGRICOLE CORPORATE AND INVESTMENT", country: "TAIWAN, REPUBLIC OF CHINA" },
  CRLYUS33: { name: "CREDIT AGRICOLE CIB", country: "UNITED STATES" },
  CROAHR2X: { name: "CROATIA BANKA D.D", country: "CROATIA" },
  CRPPIT2P: { name: "CREDIT AGRICOLE ITALIA S.P.A.", country: "ITALY" },
  CRRDCOBB: { name: "CORREDORES DAVIVIENDA S.A. COMISIONISTA DE BOLSA", country: "COLOMBIA" },
  CRVLCOBB: { name: "CREDICORP CAPITAL COLOMBIA S.A.", country: "COLOMBIA" },
  CRVOIT3V: { name: "CASSA DI RISPARMIO DI VOLTERRA", country: "ITALY" },
  CSFBUS33: { name: "CREDIT SUISSE SECURITIES (USA) LLC", country: "UNITED STATES" },
  CSFPGB2L: { name: "CREDIT SUISSE INTERNATIONAL", country: "UNITED KINGDOM" },
  CSRVUS33: { name: "CROSS RIVER BANK", country: "UNITED STATES" },
  CSUKGB2L: { name: "CREDIT SUISSE (UK) LIMITED", country: "UNITED KINGDOM" },
  CSURES2C: { name: "CAJASUR BANCO, S.A.", country: "SPAIN" },
  CTBAAU2S: { name: "COMMONWEALTH BANK OF AUSTRALIA", country: "AUSTRALIA" },
  CTBAGB2L: { name: "COMMONWEALTH BANK OF AUSTRALIA", country: "UNITED KINGDOM" },
  CTBASGSG: { name: "COMMONWEALTH BANK OF AUSTRALIA", country: "SINGAPORE" },
  CTCBSGSG: { name: "CTBC BANK CO., LTD. SINGAPORE BRANCH", country: "SINGAPORE" },
  CTCBTWTP: { name: "CTBC BANK CO., LTD", country: "TAIWAN, REPUBLIC OF CHINA" },
  CTZIUS33: { name: "CITIZENS BANK NATIONAL ASSOCIATION", country: "UNITED STATES" },
  CXECCVCV: { name: "CAIXA ECONOMICA DE CABO VERDE, SA", country: "CAPE VERDE" },
  CZNBCNBJ: { name: "KOOKMIN BANK (CHINA) LIMITED", country: "CHINA" },
  CZNBGB2L: { name: "KOOKMIN BANK CO. LTD, LONDON BRANCH", country: "UNITED KINGDOM" },
  CZNBINDD: { name: "KOOKMIN BANK", country: "INDIA" },
  CZNBKRSE: { name: "KOOKMIN BANK", country: "KOREA, REPUBLIC OF" },
  DABADKKK: { name: "DANSKE BANK A/S", country: "DENMARK" },
  DABAFIHH: { name: "DANSKE BANK A/S, FINLAND BRANCH", country: "FINLAND" },
  DABAGB2B: { name: "NORTHERN BANK LIMITED", country: "UNITED KINGDOM" },
  DABAGB2L: { name: "DANSKE BANK", country: "UNITED KINGDOM" },
  DABAIE2D: { name: "DANSKE BANK A/S", country: "IRELAND" },
  DABALULL: { name: "DANSKE BANK INTERNATIONAL S.A.", country: "LUXEMBOURG" },
  DABANO22: { name: "DANSKE BANK", country: "NORWAY" },
  DABAPLPW: { name: "DANSKE BANK A/S S.A. ODDZIAL W POLSCE", country: "POLAND" },
  DABASESX: { name: "DANSKE BANK A/S", country: "SWEDEN" },
  DAKVDEFF: { name: "CLEARSTREAM BANKING AG Finance", country: "GERMANY" },
  DBDBRSBG: { name: "BANCA INTESA AD, BEOGRAD", country: "REPUBLIC OF SERBIA" },
  DBSBIDJA: { name: "BANK DBS INDONESIA, PT", country: "INDONESIA" },
  DBSSCNSH: { name: "DBS BANK (CHINA) LIMITED", country: "CHINA" },
  DBSSGB2L: { name: "DBS BANK LTD, LONDON BRANCH", country: "UNITED KINGDOM" },
  DBSSHKHH: { name: "DBS BANK LTD, HONG KONG BRANCH", country: "HONG KONG" },
  DBSSINBB: { name: "DBS BANK INDIA LIMITED", country: "INDIA" },
  DBSSKRSE: { name: "DBS BANK LTD, SEOUL BRANCH", country: "KOREA, REPUBLIC OF" },
  DBSSMOMX: { name: "DBS BANK (HONG KONG) LIMITED, MACAU BRANCH", country: "MACAO" },
  DBSSSGSG: { name: "DBS BANK LTD", country: "SINGAPORE" },
  DBSSTWTP: { name: "DBS BANK (TAIWAN) LTD", country: "TAIWAN, REPUBLIC OF CHINA" },
  DBSSTWTX: { name: "DBS BANK LTD, TAIPEI BRANCH", country: "TAIWAN, REPUBLIC OF CHINA" },
  DBSSVNVX: { name: "DBS BANK LTD.- HO CHI MINH CITY BRANCH", country: "VIET NAM" },
  DEKTDE7G: { name: "DEUTSCHE HANDELSBANK AG", country: "GERMANY" },
  DELBDE33: { name: "BETHMANN BANK AG", country: "GERMANY" },
  DELEBE22: { name: "DELEN PRIVATE BANK", country: "BELGIUM" },
  DELELULL: { name: "DELEN PRIVATE BANK LUXEMBOURG S.A.", country: "LUXEMBOURG" },
  DELUFR22: { name: "BANQUE DELUBAC ET CIE", country: "FRANCE" },
  DENITRIS: { name: "DENIZBANK A.S.", country: "TURKEY" },
  DEUTAEAA: { name: "DEUTSCHE BANK AG, ABU DHABI", country: "UNITED ARAB EMIRATES" },
  DEUTAEAD: { name: "DEUTSCHE BANK AG, ABU DHABI", country: "UNITED ARAB EMIRATES" },
  DEUTATWW: { name: "DEUTSCHE BANK AKTIENGESELLSCHAFT Filiale Wien", country: "AUSTRIA" },
  DEUTAU2S: { name: "DEUTSCHE BANK AG", country: "AUSTRALIA" },
  DEUTBEBE: { name: "DEUTSCHE BANK AG", country: "BELGIUM" },
  DEUTBRSP: { name: "DEUTSCHE BANK S.A.- BANCO ALEMAO", country: "BRAZIL" },
  DEUTCATT: { name: "DEUTSCHE BANK AG, CANADA BRANCH", country: "CANADA" },
  DEUTCHGG: { name: "DEUTSCHE BANK (SUISSE) S.A.", country: "SWITZERLAND" },
  DEUTCHZZ: { name: "DEUTSCHE BANK AG Frankfurt Am Main Zurich Branch", country: "SWITZERLAND" },
  DEUTCNSH: { name: "DEUTSCHE BANK (CHINA) CO. LTD.", country: "CHINA" },
  DEUTCZPX: { name: "DEUTSCHE BANK AKTIENGESELLSCHAFT FILIALE PRAG, ORGANIZACNI SLOZKA", country: "CZECHIA" },
  DEUTDE2H: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDE3B: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDE5D: { name: "DWS INVESTMENT GMBH", country: "GERMANY" },
  DEUTDE5F: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDE5M: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDE5X: { name: "DEUTSCHE BANK EUROPE GMBH", country: "GERMANY" },
  DEUTDE6F: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDE8C: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDE8E: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDE8L: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDE8M: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDEAB: { name: "DWS INTERNATIONAL GMBH", country: "GERMANY" },
  DEUTDEAM: { name: "DWS INTERNATIONAL GMBH", country: "GERMANY" },
  DEUTDEBB: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDEBR: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDEDB: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDEDD: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDEDE: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDEDK: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDEDW: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDEFF: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDEHB: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDEHH: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDEMM: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDESM: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTDESS: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  DEUTESBB: { name: "DEUTSCHE BANK, SOCIEDAD ANONIMA ESPANOLA", country: "SPAIN" },
  DEUTFRPP: { name: "DEUTSCHE BANK AG", country: "FRANCE" },
  DEUTGB2L: { name: "DEUTSCHE BANK A.G.", country: "UNITED KINGDOM" },
  DEUTHKHH: { name: "DEUTSCHE BANK AG", country: "HONG KONG" },
  DEUTHU2B: { name: "DEUTSCHE BANK AG HUNGARY BRANCH", country: "HUNGARY" },
  DEUTIDJA: { name: "DEUTSCHE BANK AG - JAKARTA", country: "INDONESIA" },
  DEUTINBB: { name: "DEUTSCHE BANK AG, MUMBAI BRANCH", country: "INDIA" },
  DEUTITMM: { name: "DEUTSCHE BANK SPA", country: "ITALY" },
  DEUTJPJT: { name: "DEUTSCHE BANK AG", country: "JAPAN" },
  DEUTKRSE: { name: "DEUTSCHE BANK AG, SEOUL BRANCH", country: "KOREA, REPUBLIC OF" },
  DEUTKYKX: { name: "DEUTSCHE BANK AG", country: "CAYMAN ISLANDS" },
  DEUTLKLX: { name: "DEUTSCHE BANK AG, COLOMBO BRANCH", country: "SRI LANKA" },
  DEUTLULB: { name: "DEUTSCHE BANK AG FILIALE LUXEMBOURG", country: "LUXEMBOURG" },
  DEUTMYKL: { name: "DEUTSCHE BANK (MALAYSIA) BERHAD", country: "MALAYSIA" },
  DEUTNL2A: { name: "DEUTSCHE BANK AG", country: "NETHERLANDS" },
  DEUTNL2N: { name: "DEUTSCHE BANK AG", country: "NETHERLANDS" },
  DEUTPHMM: { name: "DEUTSCHE BANK AG", country: "PHILIPPINES" },
  DEUTPKKA: { name: "DEUTSCHE BANK AG, KARACHI BRANCH", country: "PAKISTAN" },
  DEUTPLPX: { name: "DEUTSCHE BANK POLSKA S.A", country: "POLAND" },
  DEUTPTPL: { name: "DEUTSCHE BANK AG PORTUGAL BRANCH", country: "PORTUGAL" },
  DEUTRUMM: { name: "DEUTSCHE BANK LTD.", country: "RUSSIAN FEDERATION" },
  DEUTSGSG: { name: "DEUTSCHE BANK AG, SINGAPORE BRANCH", country: "SINGAPORE" },
  DEUTTHBK: { name: "DEUTSCHE BANK AG, BANGKOK BRANCH", country: "THAILAND" },
  DEUTTRIS: { name: "DEUTSCHE BANK A.S.", country: "TURKEY" },
  DEUTTWTP: { name: "DEUTSCHE BANK AG, TAIPEI BRANCH", country: "TAIWAN, REPUBLIC OF CHINA" },
  DEUTUS33: { name: "DEUTSCHE BANK AG", country: "UNITED STATES" },
  DEUTVNVX: { name: "DEUTSCHE BANK AG, HO CHI MINH CITY BRANCH", country: "VIET NAM" },
  DEUTZAJX: { name: "DEUTSCHE BANK AG", country: "SOUTH AFRICA" },
  DGCCCN22: { name: "DONGGUAN RURAL COMMERCIAL BANK CO.LTD.", country: "CHINA" },
  DGZFDEFF: { name: "DEKABANK DEUTSCHE GIROZENTRALE", country: "GERMANY" },
  DGZFLULI: { name: "DEKABANK DEUTSCHE GIROZENTRALE SUCCURSALE DE LUXEMBOURG", country: "LUXEMBOURG" },
  DHBKHKHH: { name: "DBS BANK (HONG KONG) LIMITED", country: "HONG KONG" },
  DIBBEGCA: { name: "INDUSTRIAL DEVELOPMENT BANK", country: "EGYPT" },
  DIBIGTGC: { name: "BANCO INTERNACIONAL, S.A.", country: "GUATEMALA" },
  DIWAJPJT: { name: "RESONA BANK, LTD.", country: "JAPAN" },
  DNBADEHX: { name: "DNB BANK ASA, FILIALE DEUTSCHLAND", country: "GERMANY" },
  DNBADKKX: { name: "DNB BANK ASA, FILIAL AF DNB BANK ASA, NORGE", country: "DENMARK" },
  DNBAFIHX: { name: "DNB BANK ASA, FILIAL FINLAND", country: "FINLAND" },
  DNBAGB2L: { name: "DNB BANK ASA, LONDON BRANCH", country: "UNITED KINGDOM" },
  DNBANOKK: { name: "DNB BANK ASA", country: "NORWAY" },
  DNBANOKX: { name: "DNB BANK ASA", country: "NORWAY" },
  DNBASESX: { name: "DNB BANK ASA, FILIAL SVERIGE", country: "SWEDEN" },
  DNBAUS33: { name: "DNB BANK ASA, NEW YORK BRANCH", country: "UNITED STATES" },
  DOHBQAQA: { name: "DOHA BANK", country: "QATAR" },
  DOMICHZZ: { name: "TELLCO AG", country: "SWITZERLAND" },
  DRESDEFF: { name: "COMMERZBANK AG (FORMERLY DRESDNER BANK AG)", country: "GERMANY" },
  DSBAHKHH: { name: "DAH SING BANK, LIMITED", country: "HONG KONG" },
  DWRBCNSU: { name: "BANK OF SUZHOU CO.,LTD", country: "CHINA" },
  EBBKEGCX: { name: "HSBC BANK EGYPT S.A.E", country: "EGYPT" },
  EBILAEAD: { name: "EMIRATES NBD BANK PJSC", country: "UNITED ARAB EMIRATES" },
  EBILEGCX: { name: "Emirates National Bank of Dubai SAE", country: "EGYPT" },
  EBILGB2L: { name: "EMIRATES NBD BANK (P.J.S.C)", country: "UNITED KINGDOM" },
  EBLDBDDH: { name: "EASTERN BANK LTD.", country: "BANGLADESH" },
  ECABAGAG: { name: "EASTERN CARIBBEAN AMALGAMATED BANK LTD. (ECAB)", country: "ANTIGUA AND BARBUDA" },
  ECOCBFBF: { name: "ECOBANK - BURKINA", country: "BURKINA FASO" },
  ECOCBIBI: { name: "ECOBANK BURUNDI", country: "BURUNDI" },
  ECOCBJBJ: { name: "ECOBANK BENIN", country: "BENIN" },
  ECOCCDKI: { name: "ECOBANK REPUBLIQUE DEMOCRATIQUE DU CONGO SA", country: "DEMOCRATIC REPUBLIC OF CONGO" },
  ECOCCFCF: { name: "ECOBANK CENTRAFRIQUE", country: "CENTRAL AFRICAN REPUBLIC" },
  ECOCCGCG: { name: "ECOBANK CONGO", country: "CONGO" },
  ECOCCIAB: { name: "ECOBANK - COTE D'IVOIRE S.A", country: "COTE D'IVOIRE" },
  ECOCCMCX: { name: "ECOBANK CAMEROUN S.A.", country: "CAMEROON" },
  ECOCCVCV: { name: "ECOBANK CABO VERDE SA", country: "CAPE VERDE" },
  ECOCFRPP: { name: "EBI SA", country: "FRANCE" },
  ECOCGALI: { name: "ECOBANK GABON", country: "GABON" },
  ECOCGHAC: { name: "ECOBANK GHANA LIMITED", country: "GHANA" },
  ECOCGNCN: { name: "ECOBANK GUINEE", country: "GUINEA" },
  ECOCGQGQ: { name: "ECOBANK GUINEE EQUATORIALE", country: "EQUATORIAL GUINEA" },
  ECOCGWGW: { name: "ECOBANK GUINEE BISSAU", country: "GUINEA-BISSAU" },
  ECOCKENA: { name: "ECOBANK KENYA LTD.", country: "KENYA" },
  ECOCLRLM: { name: "ECOBANK LIBERIA", country: "LIBERIA" },
  ECOCMLBA: { name: "ECOBANK - MALI", country: "MALI" },
  ECOCMWMW: { name: "ECOBANK MALAWI LIMITED", country: "MALAWI" },
  ECOCMZMP: { name: "ECOBANK MOCAMBIQUE, S.A.", country: "MOZAMBIQUE" },
  ECOCNENI: { name: "ECOBANK NIGER", country: "NIGER" },
  ECOCNGLA: { name: "ECOBANK NIGERIA LIMITED", country: "NIGERIA" },
  ECOCRWRW: { name: "ECOBANK RWANDA LTD", country: "RWANDA" },
  ECOCSLFR: { name: "ECOBANK SIERRA LEONE LIMITED", country: "SIERRA LEONE" },
  ECOCSNDA: { name: "ECOBANK SENEGAL", country: "SENEGAL" },
  ECOCSSJB: { name: "ECOBANK SOUTH SUDAN LIMITED", country: "SOUTH SUDAN" },
  ECOCTDND: { name: "ECOBANK TCHAD", country: "CHAD" },
  ECOCTGTG: { name: "ECOBANK TOGO", country: "TOGO" },
  ECOCTZTZ: { name: "ECOBANK TANZANIA LIMITED", country: "TANZANIA, UNITED REPUBLIC OF" },
  ECOCUGKA: { name: "ECOBANK UGANDA", country: "UGANDA" },
  ECOCZWHX: { name: "ECOBANK ZIMBABWE LIMITED", country: "ZIMBABWE" },
  EFGBCHZZ: { name: "EFG BANK AG", country: "SWITZERLAND" },
  EFGBJESH: { name: "EFG PRIVATE BANK LIMITED, JERSEY BRANCH", country: "JERSEY C.I." },
  EHBBAT2E: { name: "HYPO-BANK BURGENLAND AG", country: "AUSTRIA" },
  EHKVDEHH: { name: "Euler Hermes Deutschland, Niederlassung der Euler Hermes SA", country: "GERMANY" },
  EIFLLULB: { name: "EUROPEAN INVESTMENT FUND - EIF", country: "LUXEMBOURG" },
  ENTITWTP: { name: "ENTIE COMMERCIAL BANK", country: "TAIWAN, REPUBLIC OF CHINA" },
  EQBLKENA: { name: "EQUITY BANK (KENYA) LIMITED", country: "KENYA" },
  ERBKGRAA: { name: "EUROBANK S.A.", country: "GREECE" },
  ESCBFRPP: { name: "BANQUE D'ESCOMPTE", country: "FRANCE" },
  ESPCESMM: { name: "BANCO SANTANDER S.A", country: "SPAIN" },
  ESSEDEFF: { name: "SKANDINAVISKA ENSKILDA BANKEN AB (PUBL) FRANKFURT BRANCH", country: "GERMANY" },
  ESSEDKKK: { name: "SKANDINAVISKA ENSKILDA BANKEN", country: "DENMARK" },
  ESSEGB2L: { name: "Skandinaviska Enskilda Banken AB (Publ)", country: "UNITED KINGDOM" },
  ESSESESS: { name: "SKANDINAVISKA ENSKILDA BANKEN AB", country: "SWEDEN" },
  ESSEUAUK: { name: "JSC SEB CORPORATE BANK", country: "UKRAINE" },
  ETHNGRAA: { name: "NATIONAL BANK OF GREECE SA", country: "GREECE" },
  EUXCDEFZ: { name: "EUREX CLEARING AG", country: "GERMANY" },
  EVBKUS3M: { name: "TIAA, FSB", country: "UNITED STATES" },
  EVERCNBJ: { name: "EVERBRIGHT BANK OF CHINA", country: "CHINA" },
  EWBKUS66: { name: "EAST WEST BANK", country: "UNITED STATES" },
  EXBSUAUX: {
    name: "JOINT STOCK COMPANY THE STATE EXPORT-IMPORT BANK OF UKRAINE - 'JSC UKREXIMBANK'",
    country: "UKRAINE",
  },
  EXPCMK22: { name: "HALKBANK A.D. SKOPJE", country: "NORTH MACEDONIA" },
  EXTNDJJD: { name: "EXIM BANK (DJIBOUTI) S.A.", country: "DJIBOUTI" },
  FBBVCOBB: { name: "BBVA ASSET MANAGEMENT S.A. SOCIEDAD FIDUCIARIA", country: "COLOMBIA" },
  FBHLNL2A: { name: "CREDIT EUROPE BANK N.V.", country: "NETHERLANDS" },
  FBHLTRIS: { name: "FIBABANKA A.S.", country: "TURKEY" },
  FBNASRPA: { name: "FINABANK N.V.", country: "SURINAME" },
  FBNIGB2L: { name: "FBN BANK (UK) LIMITED", country: "UNITED KINGDOM" },
  FBNINGLA: { name: "FIRST BANK OF NIGERIA LTD", country: "NIGERIA" },
  FBOGCOBB: { name: "FIDUCIARIA BOGOTA S.A.", country: "COLOMBIA" },
  FCBKTWTP: { name: "FIRST COMMERCIAL BANK", country: "TAIWAN, REPUBLIC OF CHINA" },
  FCIBBBBB: { name: "FIRSTCARIBBEAN INTERNATIONAL BANK (BARBADOS) LIMITED", country: "BARBADOS" },
  FCMBNGLA: { name: "FIRST CITY MONUMENT BANK LIMITED", country: "NIGERIA" },
  FCMCUS33: { name: "MIZUHO CAPITAL MARKETS LLC", country: "UNITED STATES" },
  FCOHHNTE: { name: "BANCO FINANCIERA COMERCIAL HONDURENA S.A. (BANCO FICOHSA)", country: "HONDURAS" },
  FCOICOBB: { name: "FIDUCIARIA CORFICOLOMBIANA S.A.", country: "COLOMBIA" },
  FCTTTTPS: { name: "FIRST CITIZENS BANK LIMITED", country: "TRINIDAD AND TOBAGO" },
  FDHFMWMW: { name: "FDH BANK PLC", country: "MALAWI" },
  FDOCCOBB: { name: "FIDUCIARIA DE OCCIDENTE S.A.", country: "COLOMBIA" },
  FEINTWTP: { name: "FAR EASTERN INTERNATIONAL BANK", country: "TAIWAN, REPUBLIC OF CHINA" },
  FETALULL: { name: "RBC INVESTOR SERVICES BANK S.A.", country: "LUXEMBOURG" },
  FGBMAEAA: { name: "FIRST ABU DHABI BANK PJSC", country: "UNITED ARAB EMIRATES" },
  FGBMSGSG: { name: "FIRST ABU DHABI BANK PJSC SINGAPORE BRANCH", country: "SINGAPORE" },
  FHBKUS77: { name: "FIRST HAWAIIAN BANK", country: "UNITED STATES" },
  FICOUS44: { name: "SYNOVUS BANK", country: "UNITED STATES" },
  FIDTNGLA: { name: "FIDELITY BANK PLC", country: "NIGERIA" },
  FIDVCOBB: { name: "FIDUCIARIA DAVIVIENDA S.A.", country: "COLOMBIA" },
  FIMBMTM3: { name: "FIMBANK P.L.C.", country: "MALTA" },
  FIRBILIT: { name: "THE FIRST INTERNATIONAL BANK OF ISRAEL LTD", country: "ISRAEL" },
  FIRNBWGX: { name: "FIRST NATIONAL BANK OF BOTSWANA LIMITED", country: "BOTSWANA" },
  FIRNGHAC: { name: "FIRST NATIONAL BANK GHANA LIMITED", country: "GHANA" },
  FIRNLSMX: { name: "FIRST NATIONAL BANK OF LESOTHO", country: "LESOTHO" },
  FIRNMZMX: { name: "FNB MOZAMBIQUE,SA Building 33, Floor 4", country: "MOZAMBIQUE" },
  FIRNNANX: { name: "FIRST NATIONAL BANK OF NAMIBIA LTD", country: "NAMIBIA" },
  FIRNNGLA: { name: "RAND MERCHANT BANK NIGERIA LIMITED", country: "NIGERIA" },
  FIRNSZMX: { name: "FIRST NATIONAL BANK OF ESWATINI LIMITED", country: "ESWATINI" },
  FIRNZAJJ: { name: "FIRSTRAND BANK LIMITED", country: "SOUTH AFRICA" },
  FIRNZMLX: { name: "FIRST NATIONAL BANK ZAMBIA LIMITED", country: "ZAMBIA" },
  FJIBCNBA: { name: "INDUSTRIAL BANK CO.,LTD.", country: "CHINA" },
  FLICHKHH: { name: "FWD LIFE INSURANCE COMPANY (BERMUDA) LIMITED", country: "HONG KONG" },
  FMBZBWGA: { name: "ACCESS BANK BOTSWANA LIMITED", country: "BOTSWANA" },
  FMSWDEMM: { name: "FMS WERTMANAGEMENT AOER", country: "GERMANY" },
  FMTCUS3T: { name: "FIDELITY MANAGEMENT TRUST CO.", country: "UNITED STATES" },
  FNNBBHBM: { name: "FINANSBANK A.S. BAHRAIN BRANCH", country: "BAHRAIN" },
  FNNBROBU: { name: "CREDIT EUROPE BANK (ROMANIA) S.A.", country: "ROMANIA" },
  FNNBTRIS: { name: "QNB FINANSBANK A.S.", country: "TURKEY" },
  FRBBUS6S: { name: "FIRST REPUBLIC BANK", country: "UNITED STATES" },
  FRCGZWHX: { name: "FIRST CAPITAL BANK LIMITED", country: "ZIMBABWE" },
  FRSTUS44: { name: "FROST BANK", country: "UNITED STATES" },
  FSBKDZAL: { name: "FRANSABANK EL DJAZAIR SPA", country: "ALGERIA" },
  FSUICHGG: { name: "CREDIT EUROPE BANK (SUISSE) S.A.", country: "SWITZERLAND" },
  FTBCUS3C: { name: "FIFTH THIRD BANK, NATIONAL ASSOCIATION", country: "UNITED STATES" },
  FTBMUS44: { name: "FIRST HORIZON BANK", country: "UNITED STATES" },
  FTMDMD2X: { name: "BANCA DE FINANTE SI COMERT S.A. Mrs Dina Pynzari", country: "MOLDOVA, REPUBLIC OF" },
  FTSBDKKK: { name: "BNP PARIBAS S.A. DENMARK FILIAL AF BNP PARIBAS S.A. FRANKRIG", country: "DENMARK" },
  FTSBROBU: { name: "BNP PARIBAS S.A. PARIS SUCURSALA BUCURESTI", country: "ROMANIA" },
  FTSBSESS: { name: "BNP PARIBAS S.A., BANKFILIAL SVERIGE", country: "SWEDEN" },
  GABAARBA: { name: "BANCO DE GALICIA Y BUENOS AIRES", country: "ARGENTINA" },
  GAZPLULL: { name: "BANK GPB INTERNATIONAL S.A.", country: "LUXEMBOURG" },
  GAZPRUMM: { name: "GAZPROMBANK (JOINT STOCK COMPANY)", country: "RUSSIAN FEDERATION" },
  GBGCPLPK: { name: "GETIN NOBLE BANK S.A.", country: "POLAND" },
  GDBKCN22: { name: "CHINA GUANGFA BANK CO., LTD", country: "CHINA" },
  GEBAATWW: { name: "BNP PARIBAS S.A. NIEDERLASSUNG OESTERREICH", country: "AUSTRIA" },
  GEBABEBB: { name: "BNP PARIBAS FORTIS SA/NV", country: "BELGIUM" },
  GEBACZPP: { name: "BNP PARIBAS S.A., POBOCKA CESKA REPUBLIKA", country: "CZECHIA" },
  GEBADE33: { name: "BNP PARIBAS S.A. NIEDERLASSUNG DEUTSCHLAND", country: "GERMANY" },
  GEBAESMM: { name: "BNP Paribas Fortis SA/NV Sucursal en Espana", country: "SPAIN" },
  GEBAGB22: { name: "BNP PARIBAS LONDON BRANCH", country: "UNITED KINGDOM" },
  GENODE6K: { name: "DZ BANK AG DEUTSCHE ZENTRAL- GENOSSENSCHAFTSBANK", country: "GERMANY" },
  GENODEDD: { name: "DZ BANK AG, DEUTSCHE ZENTRAL- GENOSSENSCHAFTSBANK", country: "GERMANY" },
  GENODEFF: { name: "DZ BANK AG, DEUTSCHE ZENTRAL- GENOSSENSCHAFTSBANK", country: "GERMANY" },
  GENOGB2L: { name: "DZ BANK AG DEUTSCHE ZENTRAL- GENOSSENSCHAFTSBANK", country: "UNITED KINGDOM" },
  GEROCOBB: { name: "BANCO BILBAO VIZCAYA ARGENTARIA COLOMBIA S.A.", country: "COLOMBIA" },
  GHCBGHAC: { name: "GCB BANK LIMITED", country: "GHANA" },
  GHIBGB2L: { name: "GHANA INTERNATIONAL BANK PLC Regina House", country: "UNITED KINGDOM" },
  GIBAATWG: { name: "ERSTE GROUP BANK AG", country: "AUSTRIA" },
  GIBAATWW: { name: "ERSTE BANK DER OESTERREICHISCHEN SPARKASSEN AG", country: "AUSTRIA" },
  GIBACZPX: { name: "CESKA SPORITELNA A.S.", country: "CZECHIA" },
  GIBAHUHB: { name: "ERSTE BANK HUNGARY ZRT", country: "HUNGARY" },
  GIBASKBX: { name: "SLOVENSKA SPORITELNA A.S", country: "SLOVAKIA" },
  GKCCBEBB: { name: "BELFIUS BANK SA/NV", country: "BELGIUM" },
  GLAMCHZU: { name: "GAM INVESTMENT MANAGEMENT (SWITZERLAND) AG", country: "SWITZERLAND" },
  GLITISRE: { name: "ISLANDSBANKI HF", country: "ICELAND" },
  GLMTMNUB: { name: "GOLOMT BANK OF MONGOLIA", country: "MONGOLIA" },
  GMGUGGSP: { name: "INVESTEC BANK ( CHANNEL ISLANDS) LIMITED", country: "GUERNSEY C.I." },
  GOLDAU2S: { name: "GOLDMAN SACHS FINANCIAL MARKETS PTY LTD", country: "AUSTRALIA" },
  GOLDDEFA: { name: "GOLDMAN SACHS BANK EUROPE SE", country: "GERMANY" },
  GOLDDEFB: { name: "GOLDMAN SACHS BANK EUROPE SE", country: "GERMANY" },
  GOLDJPJX: { name: "GOLDMAN SACHS JAPAN CO., LTD.", country: "JAPAN" },
  GOLDUS33: { name: "GOLDMAN SACHS AND CO. LLC", country: "UNITED STATES" },
  GRKBCH22: { name: "GRAUBUENDNER KANTONALBANK", country: "SWITZERLAND" },
  GSCMUS33: { name: "GOLDMAN SACHS BANK USA", country: "UNITED STATES" },
  GSCRJPJT: { name: "GOLDMAN SACHS BANK USA TOKYO BRANCH", country: "JAPAN" },
  GSCRUS33: { name: "GOLDMAN SACHS BANK USA", country: "UNITED STATES" },
  GSILGB2X: { name: "GOLDMAN SACHS INTERNATIONAL", country: "UNITED KINGDOM" },
  GSLDGB2L: { name: "GOLDMAN SACHS BANK USA LONDON BRANCH", country: "UNITED KINGDOM" },
  GTBIGHAC: { name: "GUARANTY TRUST BANK (GHANA) LTD", country: "GHANA" },
  GTBINGLA: { name: "GUARANTY TRUST BANK PLC", country: "NIGERIA" },
  GTCOGTGC: { name: "BANCO G AND T CONTINENTAL. S.A.", country: "GUATEMALA" },
  GUAYECEG: { name: "BANCO GUAYAQUIL S.A.", country: "ECUADOR" },
  GULBKWKW: { name: "GULF BANK, K.S.C.P.", country: "KUWAIT" },
  GULFAEAA: { name: "GULF INTERNATIONAL BANK B.S.C.", country: "UNITED ARAB EMIRATES" },
  GULFBHBM: { name: "GULF INTERNATIONAL BANK B.S.C.", country: "BAHRAIN" },
  GULFGB2L: { name: "GULF INTERNATIONAL BANK B.S.C.", country: "UNITED KINGDOM" },
  GULFSARI: { name: "GULF INTERNATIONAL BANK (GIB) - SAUDI ARABIA, S.C.J.S.C.", country: "SAUDI ARABIA" },
  GZCBCN22: { name: "BANK OF GUANGZHOU", country: "CHINA" },
  HAABHR22: { name: "ADDIKO BANK D.D.", country: "CROATIA" },
  HAABRSBG: { name: "ADDIKO BANK A.D. BEOGRAD", country: "REPUBLIC OF SERBIA" },
  HABAEE2X: { name: "SWEDBANK AS", country: "ESTONIA" },
  HABALT22: { name: "SWEDBANK AB", country: "LITHUANIA" },
  HABALV22: { name: "SWEDBANK AS Settlements departm.", country: "LATVIA" },
  HABBPKKA: { name: "HABIB BANK LIMITED HEAD OFFICE", country: "PAKISTAN" },
  HAKRSRPA: { name: "HAKRINBANK N.V.", country: "SURINAME" },
  HAMBGGSP: { name: "SG KLEINWORT HAMBROS BANK (CI) LIMITED - GUERNSEY BRANCH", country: "GUERNSEY C.I." },
  HAMBJESX: { name: "SG KLEINWORT HAMBROS BANK (CI) LIMITED", country: "JERSEY C.I." },
  HANDDKKK: { name: "HANDELSBANKEN, FILIAL AF SVENSKA HANDELSBANKEN AB (PUBL) SVERIGE", country: "DENMARK" },
  HANDFIHH: { name: "SVENSKA HANDELSBANKEN AB (PUBL) FILIALVERKSAMHETEN I FINLAND", country: "FINLAND" },
  HANDFRPP: { name: "SVENSKA HANDELSBANKEN AB", country: "FRANCE" },
  HANDGB22: { name: "HANDELSBANKEN PLC", country: "UNITED KINGDOM" },
  HANDLULB: { name: "SVENSKA HANDELSBANKEN AB (PUBL)", country: "LUXEMBOURG" },
  HANDNOKK: { name: "HANDELSBANKEN", country: "NORWAY" },
  HANDSESS: { name: "SVENSKA HANDELSBANKEN AB", country: "SWEDEN" },
  HANDUS33: { name: "SVENSKA HANDELSBANKEN AB", country: "UNITED STATES" },
  HASECNSH: { name: "HANG SENG BANK (CHINA) LIMITED", country: "CHINA" },
  HASEHKHH: { name: "HANG SENG BANK LIMITED Cables, EDP Operations", country: "HONG KONG" },
  HASPDEHH: { name: "HAMBURGER SPARKASSE AG", country: "GERMANY" },
  HATRUS44: { name: "BMO HARRIS BANK N.A.", country: "UNITED STATES" },
  HBBAMEPG: { name: "HIPOTEKARNA BANKA AD", country: "MONTENEGRO" },
  HBLILKLX: { name: "HATTON NATIONAL BANK PLC", country: "SRI LANKA" },
  HBMBMYKL: { name: "HSBC BANK MALAYSIA BERHAD", country: "MALAYSIA" },
  HBPEPEPL: { name: "BANCO GNB PERU SA", country: "PERU" },
  HBPHPHMM: { name: "HSBC SAVINGS BANK (PHILIPPINES) INC.", country: "PHILIPPINES" },
  HBUKGB2A: { name: "HSBC BANK PLC", country: "UNITED KINGDOM" },
  HBUKGB4B: { name: "HSBC BANK PLC", country: "UNITED KINGDOM" },
  HDFCINBB: { name: "HDFC BANK LTD", country: "INDIA" },
  HEBACY2N: { name: "HELLENIC BANK PUBLIC COMPANY LTD", country: "CYPRUS" },
  HEISDE66: { name: "KREISSPARKASSE HEILBRONN", country: "GERMANY" },
  HELADEFF: { name: "LANDESBANK HESSEN-THUERINGEN GZ", country: "GERMANY" },
  HELAGB2L: { name: "LANDESBANK HESSEN-THUERINGEN GIROZENTRALE", country: "UNITED KINGDOM" },
  HELSFIHH: { name: "AKTIA BANK P.L.C.", country: "FINLAND" },
  HIROJPJT: { name: "THE HIROSHIMA BANK LIMITED", country: "JAPAN" },
  HISFCNBQ: { name: "HISENSE FINANCE CO., LTD", country: "CHINA" },
  HKBAAU2S: { name: "HSBC BANK AUSTRALIA LIMITED", country: "AUSTRALIA" },
  HKBCCATT: { name: "HSBC BANK CANADA", country: "CANADA" },
  HLBBMYKL: { name: "HONG LEONG BANK BERHAD", country: "MALAYSIA" },
  HMABMYKL: { name: "HSBC AMANAH MALAYSIA BERHAD", country: "MALAYSIA" },
  HNADIE2D: { name: "COGENT INVESTMENT OPERATIONS IRELAND LTD", country: "IRELAND" },
  HNBKTWTP: { name: "HUA NAN COMMERCIAL BANK LIMITED", country: "TAIWAN, REPUBLIC OF CHINA" },
  HNBNCNBJ: { name: "KEB HANA BANK (CHINA) COMPANY LIMITED", country: "CHINA" },
  HPBZHR2X: { name: "HRVATSKA POSTANSKA BANKA D.D", country: "CROATIA" },
  HRPKGB2L: { name: "HIDDEN ROAD PARTNERS CIV UK LTD", country: "UNITED KINGDOM" },
  HRPSUS33: { name: "HIDDEN ROAD PARTNERS CIV US LLC", country: "UNITED STATES" },
  HSBCAU2S: { name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED", country: "AUSTRALIA" },
  HSBCBDDH: { name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED", country: "BANGLADESH" },
  HSBCBEBB: { name: "HSBC CONTINENTAL EUROPE", country: "BELGIUM" },
  HSBCCHZZ: { name: "HSBC BANK PLC, LONDON, ZWEIGNIEDERLASSUNG ZURICH", country: "SWITZERLAND" },
  HSBCCNSH: { name: "HSBC BANK (CHINA) COMPANY LIMITED", country: "CHINA" },
  HSBCDZAL: { name: "HSBC ALGERIA", country: "ALGERIA" },
  HSBCHKHH: { name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED", country: "HONG KONG" },
  HSBCHKHI: { name: "HSBC INTERNATIONAL TRUSTEE LTD", country: "HONG KONG" },
  HSBCIDJA: { name: "PT BANK HSBC INDONESIA", country: "INDONESIA" },
  HSBCIE2D: { name: "HSBC CONTINENTAL EUROPE", country: "IRELAND" },
  HSBCILIT: { name: "HSBC BANK PLC", country: "ISRAEL" },
  HSBCINAA: { name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED", country: "INDIA" },
  HSBCINBB: { name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED", country: "INDIA" },
  HSBCJPJT: { name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED", country: "JAPAN" },
  HSBCKRSE: {
    name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED, SEOUL BRANCH",
    country: "KOREA, REPUBLIC OF",
  },
  HSBCLKLX: { name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED", country: "SRI LANKA" },
  HSBCLULL: { name: "HSBC CONTINENTAL EUROPE", country: "LUXEMBOURG" },
  HSBCMOMX: { name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED", country: "MACAO" },
  HSBCMUMU: { name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED", country: "MAURITIUS" },
  HSBCMYKA: { name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED", country: "MALAYSIA" },
  HSBCNL2A: { name: "HSBC CONTINENTAL EUROPE", country: "NETHERLANDS" },
  HSBCNZ2A: { name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED", country: "NEW ZEALAND" },
  HSBCPHMM: {
    name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD - PHILIPPINE BRANCH",
    country: "PHILIPPINES",
  },
  HSBCPLPW: { name: "HSBC CONTINENTAL EUROPE (SPOLKA AKCYJNA) ODDZIAL W POLSCE", country: "POLAND" },
  HSBCSGSG: { name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED", country: "SINGAPORE" },
  HSBCTHBK: { name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED", country: "THAILAND" },
  HSBCTRIX: { name: "HSBC BANK A.S.", country: "TURKEY" },
  HSBCTWTP: { name: "HSBC BANK (TAIWAN) LIMITED", country: "TAIWAN, REPUBLIC OF CHINA" },
  HSBCVNVX: { name: "HSBC BANK (VIETNAM) LTD.", country: "VIET NAM" },
  HSBCZAJJ: { name: "HSBC BANK PLC", country: "SOUTH AFRICA" },
  HSHNDEHH: { name: "HAMBURG COMMERCIAL BANK AG", country: "GERMANY" },
  HSTMMYKL: { name: "HSBC (MALAYSIA) TRUSTEE BERHAD", country: "MALAYSIA" },
  HVBKAU2S: { name: "WOORI BANK", country: "AUSTRALIA" },
  HVBKBDDH: { name: "WOORI BANK, DHAKA", country: "BANGLADESH" },
  HVBKBHBM: { name: "WOORI BANK, MANAMA", country: "BAHRAIN" },
  HVBKCNBJ: { name: "WOORI BANK (CHINA) LIMITED", country: "CHINA" },
  HVBKGB2L: { name: "WOORI BANK, LONDON", country: "UNITED KINGDOM" },
  HVBKHKHH: { name: "WOORI BANK", country: "HONG KONG" },
  HVBKIDJA: { name: "PT.BANK WOORI SAUDARA INDONESIA 1906 TBK", country: "INDONESIA" },
  HVBKIN5M: { name: "WOORI BANK, CHENNAI BR.", country: "INDIA" },
  HVBKJPJT: { name: "WOORI BANK, TOKYO", country: "JAPAN" },
  HVBKKRSE: { name: "WOORI BANK, SEOUL", country: "KOREA, REPUBLIC OF" },
  HVBKSGSG: { name: "WOORI BANK, SINGAPORE", country: "SINGAPORE" },
  HVBKUS33: { name: "WOORI BANK, NEW YORK", country: "UNITED STATES" },
  HVBKUS3N: { name: "WOORI AMERICA BANK", country: "UNITED STATES" },
  HVBKUS6L: { name: "WOORI BANK, NEW YORK", country: "UNITED STATES" },
  HVBKVNVX: { name: "WOORI BANK VIETNAM LIMITED", country: "VIET NAM" },
  HXBKCNBJ: { name: "HUA XIA BANK", country: "CHINA" },
  HYPLCH22: { name: "HYPOTHEKARBANK LENZBURG AG", country: "SWITZERLAND" },
  HYPVAT2B: { name: "HYPO VORARLBERG BANK AG", country: "AUSTRIA" },
  HYVEDEMM: { name: "UNICREDIT BANK AG", country: "GERMANY" },
  HYVEGB2L: { name: "UNICREDIT BANK AG", country: "UNITED KINGDOM" },
  HYVEUS33: { name: "UNICREDIT BANK AG", country: "UNITED STATES" },
  IBAZAZ2X: { name: "THE INTERNATIONAL BANK OF AZERBAIJAN OPEN JOINT-STOCK COMPANY", country: "AZERBAIJAN" },
  IBBKIDJA: { name: "PT BANK MAYBANK INDONESIA TBK", country: "INDONESIA" },
  IBKLINBB: { name: "IDBI BANK LIMITED", country: "INDIA" },
  IBKOCNBT: { name: "INDUSTRIAL BANK OF KOREA (CHINA) LIMITED", country: "CHINA" },
  IBKOGB2L: { name: "INDUSTRIAL BANK OF KOREA", country: "UNITED KINGDOM" },
  IBKOHKHH: { name: "INDUSTRIAL BANK OF KOREA, HONG KONG BRANCH", country: "HONG KONG" },
  IBKOJPJT: { name: "INDUSTRIAL BANK OF KOREA, TOKYO BRANCH", country: "JAPAN" },
  IBKOKRSE: { name: "INDUSTRIAL BANK OF KOREA", country: "KOREA, REPUBLIC OF" },
  IBKOUS33: { name: "INDUSTRIAL BANK OF KOREA, NEW YORK BRANCH", country: "UNITED STATES" },
  IBKOVNVX: { name: "INDUSTRIAL BANK OF KOREA, HO CHI MINH BRANCH", country: "VIET NAM" },
  IBOQQAQA: { name: "DUKHAN BANK", country: "QATAR" },
  IBOYYESA: { name: "INTERNATIONAL BANK OF YEMEN Y.S.C.", country: "YEMEN" },
  ICBCTWTP: { name: "MEGA INTERNATIONAL COMMERCIAL BANK CO., LTD.", country: "TAIWAN, REPUBLIC OF CHINA" },
  ICBKAEAD: {
    name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA Ltd, DUBAI (DIFC) BRANCH",
    country: "UNITED ARAB EMIRATES",
  },
  ICBKARBA: { name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ARGENTINA) S.A.U.", country: "ARGENTINA" },
  ICBKAU2S: { name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA SYDNEY BRANCH", country: "AUSTRALIA" },
  ICBKBEBB: { name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (EUROPE) S.A.", country: "BELGIUM" },
  ICBKCHZZ: {
    name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LTD,BEIJING, ZURICH BRANCH",
    country: "SWITZERLAND",
  },
  ICBKCNBJ: { name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED", country: "CHINA" },
  ICBKDEFF: { name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED FRANKFURT BRANCH", country: "GERMANY" },
  ICBKESMM: {
    name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (EUROPE) S.A. SUCURSAL EN ESPANA",
    country: "SPAIN",
  },
  ICBKFRPP: { name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (EUROPE) S.A. PARIS BRANCH", country: "FRANCE" },
  ICBKGB2L: { name: "ICBC (LONDON) PLC", country: "UNITED KINGDOM" },
  ICBKGB3L: { name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED LONDON BRANCH", country: "UNITED KINGDOM" },
  ICBKITMM: { name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA EUROPE S.A.", country: "ITALY" },
  ICBKKHPP: {
    name: "BRANCH OF INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED AKA ICBC LIMITED PHNOM PENH BRANCH",
    country: "CAMBODIA",
  },
  ICBKKRSE: { name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA SEOUL BRANCH", country: "KOREA, REPUBLIC OF" },
  ICBKKZKX: { name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA", country: "KAZAKHSTAN" },
  ICBKMOMX: { name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA, MACAU BRANCH", country: "MACAO" },
  ICBKMYKL: { name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (MALAYSIA) BERHAD.", country: "MALAYSIA" },
  ICBKNL2A: { name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (EUROPE) S.A.", country: "NETHERLANDS" },
  ICBKPKKA: { name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA, KARACHI BRANCH", country: "PAKISTAN" },
  ICBKRUMM: { name: "BANK ICBC (JOINT STOCK COMPANY)", country: "RUSSIAN FEDERATION" },
  ICBKSGSG: { name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED", country: "SINGAPORE" },
  ICBKTRIS: { name: "ICBC TURKEY BANK A.S. (FORMERLY TEKSTIL BANKASI A.S)", country: "TURKEY" },
  ICBKUS33: { name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED", country: "UNITED STATES" },
  ICBKVNVN: { name: "ICBC HANOI BRANCH", country: "VIET NAM" },
  ICBVVNVX: { name: "VIETNAM JOINT STOCK COMMERCIAL BANK FOR INDUSTRY AND TRADE", country: "VIET NAM" },
  ICDFUS65: { name: "INSTITUTIONAL CASH DISTRIBUTORS, LLC", country: "UNITED STATES" },
  ICICDEFF: { name: "ICICI BANK UK PLC, GERMANY BRANCH", country: "GERMANY" },
  ICICGB2L: { name: "ICICI BANK UK PLC", country: "UNITED KINGDOM" },
  ICICINBB: { name: "ICICI BANK LIMITED", country: "INDIA" },
  ICICSGSG: { name: "ICICI BANK LIMITED", country: "SINGAPORE" },
  ICLRCMCX: { name: "BANQUE INTERNATIONALE DU CAMEROUN POUR L'EPARGNE ET LE CREDIT", country: "CAMEROON" },
  ICRAITRR: { name: "ICCREA BANCA SPA - ISTITUTO CENTRALE DEL CREDITO COOPERATIVO", country: "ITALY" },
  IDBLILIT: { name: "ISRAEL DISCOUNT BANK LTD", country: "ISRAEL" },
  IGLUGB2L: { name: "STONEX FINANCIAL LTD", country: "UNITED KINGDOM" },
  IIBAJOAM: { name: "ISLAMIC INTERNATIONAL ARAB BANK PLC", country: "JORDAN" },
  IKBZBA2X: { name: "ASA BANKA D.D. SARAJEVO", country: "BOSNIA AND HERZEGOVINA" },
  IMBKRUMM: { name: "UNICREDIT BANK AO", country: "RUSSIAN FEDERATION" },
  IMRWRWRW: { name: "I AND M BANK (RWANDA) PLC", country: "RWANDA" },
  INBUMXMM: { name: "BANCO INBURSA S.A.,INSTITUCION DE BANCAMULTIPLEGRUPOFINANCIEROINBURSA", country: "MEXICO" },
  INDBINBB: { name: "INDUSIND BANK LIMITED", country: "INDIA" },
  INDLGTGC: { name: "BANCO INDUSTRIAL S.A.", country: "GUATEMALA" },
  INGBCNSH: { name: "ING BANK N.V.", country: "CHINA" },
  INGBDEFF: { name: "ING-DIBA AG", country: "GERMANY" },
  INGBFRPP: { name: "ING BANK N.V", country: "FRANCE" },
  INGBHUHB: { name: "ING Bank N.V. Hungary Branch", country: "HUNGARY" },
  INGBKRSE: { name: "ING BANK N.V SEOUL", country: "KOREA, REPUBLIC OF" },
  INGBNL2A: { name: "ING BANK N.V.", country: "NETHERLANDS" },
  INGBPLPW: { name: "ING BANK SLASKI SA", country: "POLAND" },
  INGBRUMM: { name: "ING BANK (EURASIA) JSC", country: "RUSSIAN FEDERATION" },
  INGBSGSG: { name: "ING BANK N.V. SINGAPORE BRANCH", country: "SINGAPORE" },
  INGBSKBX: { name: "ING BANK N.V. POBOCKA ZAHRANICNEJ BANKY", country: "SLOVAKIA" },
  INGBTRIS: { name: "ING BANK A.S.", country: "TURKEY" },
  INGBUS33: { name: "ING CAPITAL MARKETS LLC", country: "UNITED STATES" },
  INGBUS3N: { name: "ING FINANCIAL MARKETS LLC", country: "UNITED STATES" },
  INSBMK22: { name: "SPARKASSE BANK AD SKOPJE", country: "NORTH MACEDONIA" },
  INTAZAJC: { name: "INTEMBEKO INVESTMENT ADMINISTRATORS (PTY) LTD", country: "SOUTH AFRICA" },
  INTEMXMM: {
    name: "INTERCAM BANCO SA INSTITUCION DE BANCA MULTIPLE INTERCAM GRUPO FINANCIERO",
    country: "MEXICO",
  },
  IRTYKZKA: { name: "FORTEBANK JSC", country: "KAZAKHSTAN" },
  IRVTBEBB: { name: "THE BANK OF NEW YORK MELLON SA/NV", country: "BELGIUM" },
  IRVTDEFX: { name: "THE BANK OF NEW YORK MELLON, FRANKFURT BRANCH", country: "GERMANY" },
  IRVTGB2X: { name: "THE BANK OF NEW YORK MELLON", country: "UNITED KINGDOM" },
  IRVTLULX: { name: "THE BANK OF NEW YORK MELLON SA/NV, LUXEMBOURG BRANCH", country: "LUXEMBOURG" },
  IRVTUS3N: { name: "THE BANK OF NEW YORK MELLON", country: "UNITED STATES" },
  ISAEFRPP: { name: "CACEIS BANK", country: "FRANCE" },
  ISAEGB2L: { name: "CACEIS BANK, UK BRANCH", country: "UNITED KINGDOM" },
  ISAENL2A: { name: "CACEIS BANK, NETHERLANDS BRANCH", country: "NETHERLANDS" },
  ISBKNL2A: { name: "ISBANK AG", country: "NETHERLANDS" },
  ISBKRUMM: { name: "ISBANK JOINT STOCK COMPANY", country: "RUSSIAN FEDERATION" },
  ISBKTRIS: { name: "TURKIYE IS BANKASI A.S", country: "TURKEY" },
  ISISTRIS: { name: "IS YATIRIM MENKUL DEGERLER AS", country: "TURKEY" },
  ITAUARBA: { name: "BANCO ITAU ARGENTINA S.A.", country: "ARGENTINA" },
  ITAUBRSP: { name: "ITAU UNIBANCO S.A", country: "BRAZIL" },
  ITAUCLRM: { name: "ITAU CORPBANCA", country: "CHILE" },
  ITAUGB2L: { name: "ITAU BBA INTERNATIONAL PLC", country: "UNITED KINGDOM" },
  ITAUUS33: { name: "ITAU UNIBANCO S.A., MIAMI BRANCH", country: "UNITED STATES" },
  ITAUUYMM: { name: "BANCO ITAU URUGUAY S.A.", country: "URUGUAY" },
  IVESGB2L: { name: "INVESTEC BANK PLC", country: "UNITED KINGDOM" },
  IVESZAJJ: { name: "INVESTEC BANK LIMITED", country: "SOUTH AFRICA" },
  JBBRFRPP: { name: "KEPLER CHEUVREUX", country: "FRANCE" },
  JERSILIT: { name: "BANK OF JERUSALEM LTD. TEL-AVIV", country: "ISRAEL" },
  JFUDKYKY: { name: "JUNOVA FUND LP", country: "CAYMAN ISLANDS" },
  JKBAJOAM: { name: "JORDAN KUWAIT BANK", country: "JORDAN" },
  JPMSGB2L: { name: "J.P. MORGAN SECURITIES PLC", country: "UNITED KINGDOM" },
  JTSBJPJT: { name: "CUSTODY BANK OF JAPAN, LTD.", country: "JAPAN" },
  JYBADKKK: { name: "JYSKE BANK A/S", country: "DENMARK" },
  KABAAZ22: { name: "YAPIKREDI AZERBAIJAN CLOSED JSC", country: "AZERBAIJAN" },
  KABANL2A: { name: "YAPI KREDI BANK NEDERLAND N.V.", country: "NETHERLANDS" },
  KASIHKHH: { name: "KASIKORNBANK PUBLIC COMPANY LIMITED", country: "HONG KONG" },
  KASITHBK: { name: "KASIKORNBANK PUBLIC COMPANY LIMITED", country: "THAILAND" },
  KBAGCH22: { name: "AARGAUISCHE KANTONALBANK", country: "SWITZERLAND" },
  KBBECH22: { name: "BERNER KANTONALBANK AG", country: "SWITZERLAND" },
  KBLXFRPP: { name: "BANQUE RICHELIEU FRANCE", country: "FRANCE" },
  KBLXLULL: { name: "QUINTET PRIVATE BANK (EUROPE) S.A.", country: "LUXEMBOURG" },
  KBMASI2X: { name: "NOVA KREDITNA BANKA MARIBOR D.D.", country: "SLOVENIA" },
  KBSGCH22: { name: "ST.GALLER KANTONALBANK AG", country: "SWITZERLAND" },
  KBZBMMMY: { name: "KANBAWZA BANK LTD", country: "MYANMAR" },
  KBZGCH22: { name: "ZUGER KANTONALBANK", country: "SWITZERLAND" },
  KCBLKENX: { name: "KCB BANK KENYA LIMITED", country: "KENYA" },
  KCOOKENA: { name: "THE CO-OPERATIVE BANK OF KENYA LTD", country: "KENYA" },
  KELRHUHB: { name: "KELER KOZPONTI ERTEKTAR ZARTKORUEN MUKODO RESZVENYTARSASAG", country: "HUNGARY" },
  KEYBUS33: { name: "KEYBANK NATIONAL ASSOCIATION", country: "UNITED STATES" },
  KFWIDEFF: { name: "KREDITANSTALT FUER WIEDERAUFBAU", country: "GERMANY" },
  KHABUA2K: { name: "UKRSIBBANK", country: "UKRAINE" },
  KHBAHKHH: { name: "KOOKMIN BANK", country: "HONG KONG" },
  KINIPGPG: { name: "KINA SECURITIES LIMITED", country: "PAPUA NEW GUINEA, INDEPENDENT STATE OF" },
  KKBKINBB: { name: "KOTAK MAHINDRA BANK LIMITED", country: "INDIA" },
  KLJIQAQA: { name: "AL KHALIJ COMMERCIAL BANK Q.S.C.", country: "QATAR" },
  KMBBRUMM: { name: "BANCA INTESA", country: "RUSSIAN FEDERATION" },
  KOBBRSBG: { name: "NLB KOMERCIJALNA BANKA AD BEOGRAD", country: "REPUBLIC OF SERBIA" },
  KOBSMK2X: { name: "KOMERCIJALNA BANKA AD SKOPJE", country: "NORTH MACEDONIA" },
  KODBCNSH: { name: "THE KOREA DEVELOPMENT BANK SHANGHAI BRANCH", country: "CHINA" },
  KODBHKHH: { name: "KDB ASIA LIMITED", country: "HONG KONG" },
  KODBKRSE: { name: "THE KOREA DEVELOPMENT BANK", country: "KOREA, REPUBLIC OF" },
  KODBUZ22: { name: "JSC KDB Bank Uzbekistan", country: "UZBEKISTAN" },
  KOEXAEAD: { name: "KEB HANA BANK ABUDHABI BRANCH", country: "UNITED ARAB EMIRATES" },
  KOEXBHBM: { name: "KEB HANA BANK, BAHRAIN BRANCH", country: "BAHRAIN" },
  KOEXCATT: { name: "KEB HANA BANK CANADA", country: "CANADA" },
  KOEXDEFA: { name: "KEB HANA BANK (D) AG", country: "GERMANY" },
  KOEXGB2L: { name: "KEB HANA BANK", country: "UNITED KINGDOM" },
  KOEXHKHH: { name: "KEB HANA BANK", country: "HONG KONG" },
  KOEXKRSE: { name: "KEB HANA BANK", country: "KOREA, REPUBLIC OF" },
  KOEXNL2A: { name: "KEB HANA BANK, AMSTERDAM BRCH", country: "NETHERLANDS" },
  KOEXPAPA: { name: "KEB HANA BANK", country: "PANAMA" },
  KOEXPHMM: { name: "KEB HANA BANK, MANILA BR.", country: "PHILIPPINES" },
  KOEXSGSG: { name: "KEB HANA BANK, SINGAPORE BR.", country: "SINGAPORE" },
  KOMBCZPP: { name: "KOMERCNI BANKA, A.S.", country: "CZECHIA" },
  KOMBSKBA: { name: "KOMERCNI BANKA, A.S., POBOCKA ZAHRANICNEJ BANKY", country: "SLOVAKIA" },
  KRAGRS22: { name: "EUROBANK DIREKTNA AKCIONARSKO DRUSTVO BEOGRAD", country: "REPUBLIC OF SERBIA" },
  KREDBEBB: { name: "KBC BANK N.V.", country: "BELGIUM" },
  KREDGB2X: { name: "KBC BANK NV LONDON", country: "UNITED KINGDOM" },
  KRTHTHBK: { name: "KRUNG THAI BANK PUBLIC COMPANY LIMITED", country: "THAILAND" },
  KTEFTRIS: { name: "KUVEYT TURK KATILIM BANKASI A.S.", country: "TURKEY" },
  KWHKHKHH: { name: "CHINA CITIC BANK INTERNATIONAL LIMITED", country: "HONG KONG" },
  KWIBKWKW: { name: "KUWAIT INTERNATIONAL BANK K.S.C.P", country: "KUWAIT" },
  KYNAKR22: { name: "KYONGNAM BANK", country: "KOREA, REPUBLIC OF" },
  KZKOTJ22: { name: "CJSC HALYK BANK TAJIKISTAN", country: "TAJIKISTAN" },
  LAMPDEDD: { name: "HAUCK AUFHAEUSER LAMPE PRIVATBANK AG", country: "GERMANY" },
  LATAPRSJ: { name: "TC LATIN AMERICA PARTNERS LLC", country: "PUERTO RICO" },
  LAZPFRPP: { name: "LAZARD FRERES BANQUE", country: "FRANCE" },
  LIBABZBZ: { name: "ATLANTIC BANK LIMITED", country: "BELIZE" },
  LLCMGB22: { name: "LLOYDS BANK PLC", country: "UNITED KINGDOM" },
  LLCMSGSA: { name: "LLOYDS BANK CORPORATE MARKETS PLC", country: "SINGAPORE" },
  LOCYCHGG: { name: "BANQUE LOMBARD ODIER ET CIE SA", country: "SWITZERLAND" },
  LOYDGB22: { name: "LLOYDS BANK PLC", country: "UNITED KINGDOM" },
  LOYDGB2E: { name: "LLOYDS BANK PLC", country: "UNITED KINGDOM" },
  LOYDGB2L: { name: "LLOYDS BANK PLC", country: "UNITED KINGDOM" },
  LUBASKBX: { name: "PRIMA BANKA SLOVENSKO, A.S.", country: "SLOVAKIA" },
  LUHSDE6A: { name: "SPARKASSE VORDERPFALZ", country: "GERMANY" },
  LUKBCH22: { name: "LUZERNER KANTONALBANK", country: "SWITZERLAND" },
  LUMIGB22: { name: "BANK LEUMI (U.K) PLC", country: "UNITED KINGDOM" },
  LUMIILIT: { name: "BANK LEUMI LE-ISRAEL B.M", country: "ISRAEL" },
  MACQAU2S: { name: "MACQUARIE BANK LIMITED", country: "AUSTRALIA" },
  MACQGB2L: { name: "MACQUARIE BANK LIMITED, LONDON", country: "UNITED KINGDOM" },
  MACQIE2D: { name: "MACQUARIE BANK EUROPE DESIGNATED ACTIVITY COMPANY", country: "IRELAND" },
  MACVGB22: { name: "ED AND F MAN CAPITAL MARKETS LIMITED", country: "UNITED KINGDOM" },
  MAFRQAQA: { name: "MASRAF AL RAYAN", country: "QATAR" },
  MANTUS33: { name: "M & T BANK, NYC", country: "UNITED STATES" },
  MANTUS3A: { name: "M & T BANK, NYC", country: "UNITED STATES" },
  MARIARBA: { name: "BANCO MARIVA S.A.", country: "ARGENTINA" },
  MARLUS44: { name: "BMO HARRIS BANK N.A.", country: "UNITED STATES" },
  MBBEBNBB: { name: "MALAYAN BANKING BERHAD", country: "BRUNEI DARUSSAL" },
  MBBECNSH: { name: "MALAYAN BANKING BERHAD SHANGHAI BRANCH", country: "CHINA" },
  MBBEGB2L: { name: "MALAYAN BANKING BERHAD", country: "UNITED KINGDOM" },
  MBBEHKHH: { name: "MALAYAN BANKING BERHAD", country: "HONG KONG" },
  MBBEKHPP: { name: "MAYBANK (CAMBODIA) PLC.", country: "CAMBODIA" },
  MBBEMY2L: { name: "MAYBANK INTERNATIONAL LABUAN BRANCH", country: "MALAYSIA" },
  MBBEMYKA: { name: "MAYBANK INTERNATIONAL (L) LTD", country: "MALAYSIA" },
  MBBEMYKL: { name: "MALAYAN BANKING BERHAD (MAYBANK)", country: "MALAYSIA" },
  MBBEPHMM: { name: "MAYBANK PHILIPPINES, INC", country: "PHILIPPINES" },
  MBBESGS2: { name: "MALAYAN BANKING BERHAD", country: "SINGAPORE" },
  MBBESGSG: { name: "MALAYAN BANKING BERHAD", country: "SINGAPORE" },
  MBBEUS33: { name: "MALAYAN BANKING BERHAD", country: "UNITED STATES" },
  MBBEVNVX: { name: "MALAYAN BANKING BERHAD-HANOI BRANCH", country: "VIET NAM" },
  MBCOMXMM: {
    name: "SCOTIABANK INVERLAT, S.A. INSTITUCION DE BANCA MULTIPLE GRUPO FINANC. SCOTIABANK INVERLAT",
    country: "MEXICO",
  },
  MBTCPHMM: { name: "METROPOLITAN BANK & TRUST COMPANY", country: "PHILIPPINES" },
  MBWMMTMT: { name: "MEDIRECT BANK (MALTA) PLC", country: "MALTA" },
  MCBLMUMU: { name: "THE MAURITIUS COMMERCIAL BANK LIMITED", country: "MAURITIUS" },
  MCBLMVMV: { name: "THE MAURITIUS COMMERCIAL BANK (MALDIVES) PRIVATE LIMITED", country: "MALDIVES" },
  MCBLSCSC: { name: "THE MAURITIUS COMMERCIAL BANK (SEYCHELLES) LIMITED", country: "SEYCHELLES" },
  MCTBPAPA: { name: "MULTIBANK INC.", country: "PANAMA" },
  MDCPSGSG: { name: "M-DAQ CASHPORT PTE LTD", country: "SINGAPORE" },
  MEBARS22: { name: "CREDIT AGRICOLE BANKA SRBIJA AD NOVI SAD", country: "REPUBLIC OF SERBIA" },
  MEBEDE6S: { name: "MERCEDES-BENZ BANK AG", country: "GERMANY" },
  MEDLLBBX: { name: "BANKMED,SAL", country: "LEBANON" },
  MEDSCHGG: { name: "BANKMED (SUISSE) S.A.", country: "SWITZERLAND" },
  MEDTGB2L: { name: "BANK OF AFRICA UNITED KINGDOM PLC", country: "UNITED KINGDOM" },
  MELNUS3P: { name: "BNY MELLON, NATIONAL ASSOCIATION USD", country: "UNITED STATES" },
  MENOMXMT: { name: "BANCO MERCANTIL DEL NORTE,S.A.,INST DE BCA MULTIPLE,GRUPO FIN BANORTE", country: "MEXICO" },
  MERBBOLX: { name: "BANCO MERCANTIL SANTA CRUZ S.A.", country: "BOLIVIA" },
  METZDEFF: { name: "B.METZLER SEEL. SOHN UND CO AG", country: "GERMANY" },
  MEZNPKKA: { name: "MEEZAN BANK LIMITED", country: "PAKISTAN" },
  MFBBMYKL: { name: "ALLIANCE BANK MALAYSIA BERHAD", country: "MALAYSIA" },
  MGCOGB2L: { name: "DB UK BANK LIMITED, LONDON", country: "UNITED KINGDOM" },
  MGRMUS44: { name: "MONEYGRAM PAYMENT SYSTEMS, INC", country: "UNITED STATES" },
  MGTCZAJJ: { name: "JPMORGAN CHASE BANK, N.A. - JOHANNESBURG BRANCH", country: "SOUTH AFRICA" },
  MHBFPLPW: { name: "DNB Bank Polska S.A.", country: "POLAND" },
  MHCBAU2S: { name: "MIZUHO BANK, LTD. SYDNEY BRANCH", country: "AUSTRALIA" },
  MHCBBEBB: { name: "MIZUHO BANK EUROPE N.V. BRUSSELS BRANCH", country: "BELGIUM" },
  MHCBCATT: { name: "MIZUHO BANK, LTD., CANADA BRANCH", country: "CANADA" },
  MHCBCNSH: { name: "MIZUHO BANK (CHINA),LTD. Shanghai World Financial Center", country: "CHINA" },
  MHCBDEDD: { name: "MIZUHO BANK LTD., DUESSELDORF BRANCH", country: "GERMANY" },
  MHCBFRPP: { name: "MIZUHO BANK LTD. PARIS BRANCH", country: "FRANCE" },
  MHCBGB2L: { name: "MIZUHO BANK, LTD. LONDON BRANCH", country: "UNITED KINGDOM" },
  MHCBHKHH: { name: "MIZUHO BANK, LTD. HONG KONG BRANCH", country: "HONG KONG" },
  MHCBINBB: { name: "MIZUHO BANK LTD", country: "INDIA" },
  MHCBITMM: { name: "MIZUHO BANK LTD., MILAN BRANCH", country: "ITALY" },
  MHCBJPJ6: { name: "MIZUHO BANK, LTD.", country: "JAPAN" },
  MHCBJPJT: { name: "MIZUHO BANK, LTD.", country: "JAPAN" },
  MHCBKRSE: { name: "MIZUHO BANK SEOUL BRANCH", country: "KOREA, REPUBLIC OF" },
  MHCBMYKA: { name: "MIZUHO BANK (MALAYSIA) BERHAD", country: "MALAYSIA" },
  MHCBNL2A: { name: "MIZUHO BANK EUROPE N.V.", country: "NETHERLANDS" },
  MHCBPHMM: { name: "MIZUHO BANK, LTD. MANILA BRANCH", country: "PHILIPPINES" },
  MHCBSGSG: { name: "MIZUHO BANK LTD., SINGAPORE BRANCH", country: "SINGAPORE" },
  MHCBTHB2: { name: "MIZUHO BANK, LTD., BANGKOK BRANCH", country: "THAILAND" },
  MHCBTHBK: { name: "MIZUHO BANK, LTD., BANGKOK BRANCH", country: "THAILAND" },
  MHCBTWTP: { name: "MIZUHO BANK LTD. TAIPEI BRANCH", country: "TAIWAN, REPUBLIC OF CHINA" },
  MHCBUS33: { name: "MIZUHO BANK, LTD. NEW YORK BRANCH", country: "UNITED STATES" },
  MHCBVNVX: { name: "MIZUHO BANK, LTD. - HANOI BRANCH", country: "VIET NAM" },
  MHCCIDJA: { name: "BANK MIZUHO INDONESIA, PT", country: "INDONESIA" },
  MHCCRUMM: { name: "AO MIZUHO BANK (MOSCOW)", country: "RUSSIAN FEDERATION" },
  MHCCUS33: { name: "MIZUHO BANK (USA)", country: "UNITED STATES" },
  MIBEDEFF: { name: "MISR BANK-EUROPE GMBH", country: "GERMANY" },
  MIBGGE22: { name: "JSC Procredit Bank", country: "GEORGIA" },
  MIDLAM22: { name: "HSBC BANK ARMENIA CLOSED JOINT STOCK COMPANY", country: "ARMENIA" },
  MIDLCZPP: { name: "HSBC CONTINENTAL EUROPE", country: "CZECHIA" },
  MIDLESMM: { name: "HSBC CONTINENTAL EUROPE, SUCURSAL EN ESPANA", country: "SPAIN" },
  MIDLFRPX: { name: "HSBC BANK PLC", country: "FRANCE" },
  MIDLGB22: { name: "HSBC BANK PLC", country: "UNITED KINGDOM" },
  MIDLGRAA: { name: "HSBC CONTINENTAL EUROPE", country: "GREECE" },
  MIDLITMX: { name: "HSBC CONTINENTAL EUROPE", country: "ITALY" },
  MIDLPAPA: { name: "BANISTMO S.A", country: "PANAMA" },
  MIGRCHZZ: { name: "MIGROS BANK AG", country: "SWITZERLAND" },
  MIRAFRPP: { name: "MIRABAUD ET CIE (EUROPE) SA, Succur sale en France", country: "FRANCE" },
  MIZBILIT: { name: "MIZRAHI TEFAHOT BANK LTD", country: "ISRAEL" },
  MKKBHUHB: { name: "MKB BANK NYRT.", country: "HUNGARY" },
  MLBOBRSX: { name: "BANK OF AMERICA MERRILL LYNCH BANCO MULTIPLO S.A", country: "BRAZIL" },
  MLCOUS3G: { name: "BANK OF AMERICA CORPORATION", country: "UNITED STATES" },
  MLIBGB2X: {
    name: "BANK OF AMERICA EUROPE DESIGNATED ACTIVITY COMPANY, LONDON BRANCH",
    country: "UNITED KINGDOM",
  },
  MLIBGB5X: {
    name: "BANK OF AMERICA EUROPE DESIGNATED ACTIVITY COMPANY, LONDON BRANCH",
    country: "UNITED KINGDOM",
  },
  MLILGB5X: { name: "MERRILL LYNCH INTERNATIONAL", country: "UNITED KINGDOM" },
  MMEBMTMT: { name: "HSBC BANK MALTA P.L.C.", country: "MALTA" },
  MNBDUS33: { name: "COMERICA BANK", country: "UNITED STATES" },
  MNBDUS3C: { name: "COMERICA BANK", country: "UNITED STATES" },
  MNBDUS4D: { name: "COMERICA BANK - TEXAS", country: "UNITED STATES" },
  MNBDUS6S: { name: "COMERICA BANK CALIFORNIA", country: "UNITED STATES" },
  MNEXCLRM: { name: "BANCO CONSORCIO Piso 7 Las Condes", country: "CHILE" },
  MNSACIAB: { name: "MANSA BANK", country: "COTE D'IVOIRE" },
  MOBBMD22: { name: "OTP BANK S.A.", country: "MOLDOVA, REPUBLIC OF" },
  MOYHAU22: { name: "MONEYTECH LIMITED", country: "AUSTRALIA" },
  MPIOPTPL: { name: "CAIXA ECONOMICA MONTEPIO GERAL, CAIXA ECONOMICA BANCARIA, S.A", country: "PORTUGAL" },
  MRMDUS33: { name: "HSBC BANK USA, NATIONAL ASSOCIATION", country: "UNITED STATES" },
  MSBCCNBJ: { name: "CHINA MINSHENG BANKING CORPORATION, LIMITED", country: "CHINA" },
  MSBCHKHH: { name: "CHINA MINSHENG BANKING CORP., LTD. HONG KONG BRANCH", country: "HONG KONG" },
  MSBKKRSE: { name: "MORGAN STANLEY BANK INTERNATIONAL LIMITED, SEOUL BRANCH", country: "KOREA, REPUBLIC OF" },
  MSBKUS5W: { name: "MORGAN STANLEY BANK N.A", country: "UNITED STATES" },
  MSCGUS33: { name: "MORGAN STANLEY CAPITAL GROUP INC.", country: "UNITED STATES" },
  MSCSUS33: { name: "MORGAN STANLEY CAPITAL SERVICES LLC", country: "UNITED STATES" },
  MSHQEGCA: { name: "MASHREQ BANK", country: "EGYPT" },
  MSHQGB2L: { name: "MASHREQ BANK PSC", country: "UNITED KINGDOM" },
  MSHQHKHH: { name: "MASHREQ BANK PUBLIC SHAREHOLDING COMPANY", country: "HONG KONG" },
  MSHQINBB: { name: "MASHREQ BANK", country: "INDIA" },
  MSHQKWKW: { name: "MASHREQBANK PSC.", country: "KUWAIT" },
  MSHQQAQA: { name: "MASHREQ BANK", country: "QATAR" },
  MSHQUS33: { name: "MASHREQ BANK PSC", country: "UNITED STATES" },
  MSLNGB2X: { name: "MORGAN STANLEY AND CO INTERNATIONAL PLC", country: "UNITED KINGDOM" },
  MSNYUS33: { name: "MORGAN STANLEY AND CO., LLC", country: "UNITED STATES" },
  MSSEDEFB: { name: "MORGAN STANLEY EUROPE SE", country: "GERMANY" },
  MSTKJPJX: { name: "Morgan Stanley MUFG Securities Co., LTD.", country: "JAPAN" },
  MTBCGB2L: { name: "MITSUBISHI UFJ TRUST & BANKING CORP", country: "UNITED KINGDOM" },
  MTBCHKHH: { name: "MITSUBISHI UFJ TRUST AND BANKING CORPORATION HONG KONG", country: "HONG KONG" },
  MTBJJPJT: { name: "THE MASTER TRUST BANK OF JAPAN LTD", country: "JAPAN" },
  MTFNFRPP: { name: "MATIGNON FINANCES S.A.", country: "FRANCE" },
  MUABIDJA: { name: "PT. BANK MUAMALAT INDONESIA , TBK", country: "INDONESIA" },
  MUCBPKKA: { name: "MCB BANK LIMITED", country: "PAKISTAN" },
  MUGCLULL: { name: "MITSUBISHI UFJ INVESTOR SERVICES AND BANKING (LUXEMBOURG) S.A.", country: "LUXEMBOURG" },
  MUGCLULX: { name: "MITSUBISHI UFJ INVESTOR SERVICES AND BANKING (LUXEMBOURG) S.A.", country: "LUXEMBOURG" },
  NACFKRSE: { name: "NONGHYUP BANK (NH BANK)", country: "KOREA, REPUBLIC OF" },
  NAMENGLA: { name: "STERLING BANK PLC", country: "NIGERIA" },
  NARAUS6L: { name: "BANK OF HOPE", country: "UNITED STATES" },
  NATAAU33: { name: "NATIONAL AUSTRALIA BANK LIMITED", country: "AUSTRALIA" },
  NATACNSH: { name: "NATIONAL AUSTRALIA BANK LIMITED, SHANGHAI BRANCH", country: "CHINA" },
  NATAGB2L: { name: "NATIONAL AUSTRALIA BANK LIMITED", country: "UNITED KINGDOM" },
  NATAHKHH: { name: "NATIONAL AUSTRALIA BANK LTD HONG KONG BRANCH", country: "HONG KONG" },
  NATAJPJT: { name: "NATIONAL AUSTRALIA BANK LTD", country: "JAPAN" },
  NATASGSG: { name: "NATIONAL AUSTRALIA BANK LIMITED", country: "SINGAPORE" },
  NATAUS33: { name: "NATIONAL AUSTRALIA BANK", country: "UNITED STATES" },
  NATXCNSH: { name: "NATIXIS SHANGHAI", country: "CHINA" },
  NATXDZAL: { name: "NATIXIS ALGERIE", country: "ALGERIA" },
  NATXFRPP: { name: "NATIXIS SA", country: "FRANCE" },
  NATXHKHX: { name: "NATIXIS", country: "HONG KONG" },
  NATXLULL: { name: "NATIXIS WEALTH MANAGEMENT LUXEMBOURG", country: "LUXEMBOURG" },
  NATXSGSG: { name: "NATIXIS", country: "SINGAPORE" },
  NBADAEAA: { name: "FIRST ABU DHABI BANK PJSC", country: "UNITED ARAB EMIRATES" },
  NBADBHBM: { name: "FIRST ABU DHABI BANK PJSC", country: "BAHRAIN" },
  NBADEGCA: { name: "FIRST ABU DHABI BANK MISR S.A.E.", country: "EGYPT" },
  NBADFRPP: { name: "FIRST ABU DHABI BANK", country: "FRANCE" },
  NBADGB2L: { name: "FIRST ABU DHABI BANK PJSC", country: "UNITED KINGDOM" },
  NBADHKHH: { name: "FIRST ABU DHABI BANK PJSC", country: "HONG KONG" },
  NBADKWKW: { name: "FIRST ABU DHABI BANK", country: "KUWAIT" },
  NBADMYKL: { name: "FIRST ABU DHABI BANK PJSC", country: "MALAYSIA" },
  NBADOMRX: { name: "FIRST ABU DHABI BANK PJSC", country: "OMAN" },
  NBFCUS55: { name: "COBANK, ACB", country: "UNITED STATES" },
  NBFUAEAF: { name: "NATIONAL BANK OF FUJAIRAH", country: "UNITED ARAB EMIRATES" },
  NBIIISRE: { name: "LANDSBANKINN HF", country: "ICELAND" },
  NBKEKENX: { name: "NATIONAL BANK OF KENYA", country: "KENYA" },
  NBMAMWMW: { name: "NATIONAL BANK OF MALAWI", country: "MALAWI" },
  NBOKGB2L: { name: "NATIONAL BANK OF KUWAIT (INTERNATIONAL) PLC", country: "UNITED KINGDOM" },
  NBOKKWKW: { name: "NATIONAL BANK OF KUWAIT S.A.K.P.", country: "KUWAIT" },
  NBPSCHGG: { name: "FAB PRIVATE BANK (SUISSE) S.A.", country: "SWITZERLAND" },
  NBSTMWMW: { name: "NBS BANK PLC", country: "MALAWI" },
  NCBAALTX: { name: "BANKA KOMBETARE TREGTARE SH.A.", country: "ALBANIA" },
  NCBGGDGD: { name: "REPUBLIC BANK GRENADA LIMITED", country: "GRENADA" },
  NCBKSAJE: { name: "THE SAUDI NATIONAL BANK", country: "SAUDI ARABIA" },
  NCBKSGSG: { name: "THE SAUDI NATIONAL BANK SINGAPORE BRANCH", country: "SINGAPORE" },
  NCCOSAJE: { name: "NCB CAPITAL COMPANY", country: "SAUDI ARABIA" },
  NDAQUS34: { name: "NASDAQ, INC.", country: "UNITED STATES" },
  NDEADKKK: { name: "NORDEA DANMARK, FILIAL AF NORDEA BANK ABP, FINLAND", country: "DENMARK" },
  NDEAFIHH: { name: "NORDEA BANK ABP", country: "FINLAND" },
  NDEAGB2L: { name: "NORDEA BANK ABP LONDON BRANCH", country: "UNITED KINGDOM" },
  NDEANOKK: { name: "NORDEA BANK ABP, FILIAL I NORGE", country: "NORWAY" },
  NDEASESS: { name: "NORDEA BANK ABP, FILIAL I SVERIGE", country: "SWEDEN" },
  NDEAUS3N: { name: "NORDEA BANK ABP NEW YORK BRANCH", country: "UNITED STATES" },
  NEDSGB2L: { name: "NEDBANK LTD", country: "UNITED KINGDOM" },
  NEDSNANX: { name: "NEDBANK NAMIBIA LIMITED", country: "NAMIBIA" },
  NEDSZAJJ: { name: "NEDBANK LIMITED", country: "SOUTH AFRICA" },
  NESBPLPW: { name: "NEST BANK S.A.", country: "POLAND" },
  NFIKKRSE: { name: "NOMURA FINANCIAL INVESTMENT (KOREA) CO., LTD.", country: "KOREA, REPUBLIC OF" },
  NISLAEAD: { name: "DUBAI ISLAMIC BANK", country: "UNITED ARAB EMIRATES" },
  NISPIDJA: { name: "PT BANK OCBC NISP TBK (FORMERLY KNOWN AS PT BANK NISP)", country: "INDONESIA" },
  NJCBCNBN: { name: "BANK OF NANJING", country: "CHINA" },
  NMIBTZTZ: { name: "NMB BANK PLC", country: "TANZANIA, UNITED REPUBLIC OF" },
  NOLADE2H: { name: "NORDDEUTSCHE LANDESBANK", country: "GERMANY" },
  NOLALULL: { name: "NORD/LB Luxembourg S.A. Covered Bond Bank", country: "LUXEMBOURG" },
  NOMAGB2L: { name: "NOMURA INTERNATIONAL PLC", country: "UNITED KINGDOM" },
  NORDFRPP: { name: "CREDIT DU NORD", country: "FRANCE" },
  NORTESMM: { name: "BANCO SANTANDER S.A", country: "SPAIN" },
  NOSCAGAG: { name: "THE BANK OF NOVA SCOTIA", country: "ANTIGUA AND BARBUDA" },
  NOSCBBBB: { name: "SCOTIABANK (BARBADOS) LIMITED", country: "BARBADOS" },
  NOSCBSNS: { name: "SCOTIABANK (BAHAMAS) LTD.", country: "BAHAMAS" },
  NOSCBSNT: { name: "BANK OF NOVA SCOTIA TRUST COMPANY (BAHAMAS) LTD,THE", country: "BAHAMAS" },
  NOSCCATM: { name: "THE BANK OF NOVA SCOTIA", country: "CANADA" },
  NOSCCATT: { name: "THE BANK OF NOVA SCOTIA", country: "CANADA" },
  NOSCCN22: { name: "THE BANK OF NOVA SCOTIA GUANGZHOU BRANCH", country: "CHINA" },
  NOSCCRSJ: { name: "SCOTIABANK DE COSTA RICA", country: "COSTA RICA" },
  NOSCDOSD: { name: "SCOTIABANK REPUBLICA DOMINICANA, S.A., BANCO MULTIPLE", country: "DOMINICAN REPUBLIC" },
  NOSCGB22: { name: "THE BANK OF NOVA SCOTIA", country: "UNITED KINGDOM" },
  NOSCGB2L: { name: "SCOTIABANK EUROPE PLC", country: "UNITED KINGDOM" },
  NOSCGYGE: { name: "THE BANK OF NOVA SCOTIA", country: "GUYANA" },
  NOSCHKHH: { name: "THE BANK OF NOVA SCOTIA", country: "HONG KONG" },
  NOSCINBB: { name: "THE BANK OF NOVA SCOTIA", country: "INDIA" },
  NOSCJMKN: { name: "THE BANK OF NOVA SCOTIA JAMAICA LTD", country: "JAMAICA" },
  NOSCKRSE: { name: "THE BANK OF NOVA SCOTIA", country: "KOREA, REPUBLIC OF" },
  NOSCKYKX: { name: "SCOTIABANK AND TRUST (CAYMAN) LTD.", country: "CAYMAN ISLANDS" },
  NOSCMYKL: { name: "THE BANK OF NOVA SCOTIA BERHAD", country: "MALAYSIA" },
  NOSCTCGP: { name: "SCOTIABANK (TURKS AND CAICOS) LTD", country: "TURKS AND CAICOS ISLANDS" },
  NOSCTTPS: { name: "SCOTIABANK TRINIDAD AND TOBAGO LTD.", country: "TRINIDAD AND TOBAGO" },
  NOSCUS33: { name: "BANK OF NOVA SCOTIA", country: "UNITED STATES" },
  NOSCUS3S: { name: "SCOTIA CAPITAL USA INC", country: "UNITED STATES" },
  NOSCUS4H: { name: "THE BANK OF NOVA SCOTIA", country: "UNITED STATES" },
  NSMBFRPP: { name: "BANQUE NEUFLIZE OBC", country: "FRANCE" },
  NUROTRIS: { name: "NUROL INVESTMENT BANK INC. (NUROL YATIRIM BANKASI A.S.)", country: "TURKEY" },
  NWBKGB2L: { name: "NATIONAL WESTMINSTER BANK PLC", country: "UNITED KINGDOM" },
  NWBKGB3C: { name: "NATIONAL WESTMINSTER BANK PLC", country: "UNITED KINGDOM" },
  NYKBDKKK: { name: "NYKREDIT BANK A/S", country: "DENMARK" },
  OBKLAT2L: { name: "OBERBANK AG", country: "AUSTRIA" },
  OBKLCZ2X: { name: "OBERBANK AG", country: "CZECHIA" },
  OBKLDEMX: { name: "OBERBANK AG, NIEDERLASSUNG DEUTSCHLAND", country: "GERMANY" },
  OBKLHUHB: { name: "OBERBANK AG", country: "HUNGARY" },
  OBKLSKBA: { name: "OBERBANK AG", country: "SLOVAKIA" },
  OBPRPRSJ: { name: "ORIENTAL BANK (FORMELY BBVA PUERTO RICO)", country: "PUERTO RICO" },
  OCBCAU2S: { name: "OVERSEA-CHINESE BANKING CORPORATION LIMITED", country: "AUSTRALIA" },
  OCBCCNSH: { name: "OCBC WING HANG BANK (CHINA) LIMITED", country: "CHINA" },
  OCBCKRSE: { name: "OVERSEA-CHINESE BANKING CORPORATION LIMITED, SEOUL BRANCH", country: "KOREA, REPUBLIC OF" },
  OCBCSGSG: { name: "OVERSEA-CHINESE BANKING CORPORATION LIMITED", country: "SINGAPORE" },
  OCCIBBBB: { name: "OCCIDENTAL BANK BARBADOS (LTD)", country: "BARBADOS" },
  OCCICOBC: { name: "BANCO DE OCCIDENTE S.A.", country: "COLOMBIA" },
  ODDOFRPP: { name: "ODDO BHF SCA", country: "FRANCE" },
  ODEATRIS: { name: "ODEABANK A.S.", country: "TURKEY" },
  OEKOATWW: { name: "OESTERREICHISCHE KONTROLLBANK AG", country: "AUSTRIA" },
  OKOYFIHH: { name: "OP CORPORATE BANK PLC", country: "FINLAND" },
  OLBODEH2: { name: "OLDENBURGISCHE LANDESBANK AG", country: "GERMANY" },
  OMFNCIAB: { name: "AFRILAND FIRST BANK CI", country: "COTE D'IVOIRE" },
  OPPOMEPG: { name: "ERSTE BANK AD PODGORICA", country: "MONTENEGRO" },
  ORBKCIAB: { name: "ORABANK COTE D'IVOIRE", country: "COTE D'IVOIRE" },
  ORBKGALI: { name: "ORABANK GABON", country: "GABON" },
  ORBKGNGN: { name: "ORABANK GUINEE", country: "GUINEA" },
  ORBKGWGW: { name: "ORABANK COTE D'IVOIRE SUCCURSALE DE GUINEE-BISSAU", country: "GUINEA-BISSAU" },
  ORBKNENI: { name: "ORABANK COTE D'IVOIRE SUCCURSALE DU NIGER", country: "NIGER" },
  ORBKSNDA: { name: "ORABANK COTE D'IVOIRE SUCCURSALE DU SENEGAL", country: "SENEGAL" },
  ORBKTGTG: { name: "ORABANK TOGO", country: "TOGO" },
  ORIRETAA: { name: "OROMIA INTERNATIONAL BANK S.C. Near Getu Commercial Building", country: "ETHIOPIA" },
  OSABJPJS: { name: "KANSAI MIRAI BANK, LIMITED", country: "JAPAN" },
  OTJKTJ22: { name: "ORIENBANK, OPEN JOINT STOCK COMPANY", country: "TAJIKISTAN" },
  OTPVHR2X: { name: "OTP BANKA HRVATSKA D.D.", country: "CROATIA" },
  OTPVHUHB: { name: "OTP BANK PLC.", country: "HUNGARY" },
  OTPVROBU: { name: "OTP BANK ROMANIA S.A.", country: "ROMANIA" },
  OTPVUAUK: { name: "JOINT-STOCK COMPANY OTP BANK", country: "UKRAINE" },
  OWHBDEFF: { name: "VTB BANK (EUROPE) SE", country: "GERMANY" },
  OZARUS44: { name: "BANK OZK", country: "UNITED STATES" },
  PABIPHMM: { name: "BANK OF COMMERCE", country: "PHILIPPINES" },
  PACIECEG: { name: "BANCO DEL PACIFICO", country: "ECUADOR" },
  PAHAAZ22: { name: "PASHA BANK OJSC", country: "AZERBAIJAN" },
  PALSPS22: { name: "BANK OF PALESTINE PLC", country: "PALESTINA" },
  PARBAU2S: { name: "BNP PARIBAS SECURITIES SERVICES, (AUSTRALIA BRANCH)", country: "AUSTRALIA" },
  PARBBEBZ: { name: "BNP PARIBAS SECURITIES SERVICES,", country: "BELGIUM" },
  PARBDEFF: { name: "BNP PARIBAS SECURITIES SERVICES, GERMANY", country: "GERMANY" },
  PARBESMX: { name: "BNP PARIBAS SECURITIES SERVICES, SPAIN", country: "SPAIN" },
  PARBFRPP: { name: "BNP PARIBAS SECURITIES SERVICES", country: "FRANCE" },
  PARBGB2L: { name: "BNP PARIBAS SECURITIES SERVICES, UNITED KINGDOM", country: "UNITED KINGDOM" },
  PARBITMM: { name: "BNP PARIBAS SECURITIES SERVICES, ITALY", country: "ITALY" },
  PARBJESH: { name: "BNP PARIBAS SECURITIES SERVICES S.C.A JERSEY BRANCH", country: "JERSEY C.I." },
  PARBLULL: { name: "BNP PARIBAS SECURITIES SERVICES", country: "LUXEMBOURG" },
  PARBSGSG: { name: "BNP PARIBAS SECURITIES SERVICES SA", country: "SINGAPORE" },
  PASBITGG: { name: "BANCA PASSADORE AND C - SOCIETA PER AZIONI", country: "ITALY" },
  PASCITMM: { name: "BANCA MONTE DEI PASCHI DI SIENA", country: "ITALY" },
  PBANUA2X: { name: "JOINT-STOCK COMPANY COMMERCIAL BANK PRIVATBANK", country: "UKRAINE" },
  PBBEMYKL: { name: "PUBLIC BANK BERHAD", country: "MALAYSIA" },
  PBNKDEFF: { name: "DEUTSCHE BANK AG", country: "GERMANY" },
  PBNKLULL: { name: "DEUTSCHE BANK AG FILIALE LUXEMBOURG", country: "LUXEMBOURG" },
  PBZGHR2X: { name: "PRIVREDNA BANKA ZAGREB D.D", country: "CROATIA" },
  PCBCAU2S: { name: "CHINA CONSTRUCTION BANK CORPORATION SYDNEY BRANCH", country: "AUSTRALIA" },
  PCBCCHZZ: { name: "CHINA CONSTRUCTION BANK CORP., BEIJING,SWISS BRANCH ZURICH", country: "SWITZERLAND" },
  PCBCCNBJ: { name: "CHINA CONSTRUCTION BANK", country: "CHINA" },
  PCBCDEFF: { name: "CHINA CONSTRUCTION BANK FRANKFURT BRANCH", country: "GERMANY" },
  PCBCGB2B: { name: "CHINA CONSTRUCTION BANK CORPORATION LONDON BRANCH", country: "UNITED KINGDOM" },
  PCBCGB2L: { name: "CHINA CONSTRUCTION BANK (LONDON) LIMITED", country: "UNITED KINGDOM" },
  PCBCKRSE: { name: "CHINA CONSTRUCTION BANK SEOUL BR.", country: "KOREA, REPUBLIC OF" },
  PCBCNL2A: { name: "CHINA CONSTRUCTION BANK (EUROPE) S.A., AMSTERDAM BRANCH", country: "NETHERLANDS" },
  PCBCSGSG: { name: "CHINA CONSTRUCTION BANK CORPORATION SINGAPORE BRANCH", country: "SINGAPORE" },
  PCBCZAJJ: { name: "CHINA CONSTRUCTION BANK CORPORATION JOHANNESBURG BRANCH", country: "SOUTH AFRICA" },
  PECSBEBB: { name: "DEGROOF PETERCAM ASSET MANAGEMENT", country: "BELGIUM" },
  PHBMMYKL: { name: "AFFIN BANK BERHAD", country: "MALAYSIA" },
  PICHCOBB: { name: "BANCO PICHINCHA S.A.", country: "COLOMBIA" },
  PICHECEQ: { name: "BANCO PICHINCHA C.A.", country: "ECUADOR" },
  PICTCHGG: { name: "BANQUE PICTET ET CIE SA", country: "SWITZERLAND" },
  PICTLULX: { name: "PICTET ET CIE (EUROPE) S.A.", country: "LUXEMBOURG" },
  PIRBGRAA: { name: "PIRAEUS BANK SA", country: "GREECE" },
  PJCBBY2X: { name: "PRIORBANK Joint-Stock Company", country: "BELARUS" },
  PKBSCH22: { name: "PKB PRIVATBANK AG", country: "SWITZERLAND" },
  PKOPPLPW: { name: "BANK POLSKA KASA OPIEKI SA", country: "POLAND" },
  PNBPCATT: { name: "WELLS FARGO BANK, N. A. CANADIAN BRANCH", country: "CANADA" },
  PNBPGB2L: { name: "WELLS FARGO BANK, N.A., LONDON BRANCH", country: "UNITED KINGDOM" },
  PNBPGB2W: { name: "WELLS FARGO SECURITIES INTERNATIONAL LIMITED", country: "UNITED KINGDOM" },
  PNBPIE2D: { name: "WELLS FARGO BANK INTERNATIONAL UNLIMITED COMPANY", country: "IRELAND" },
  PNBPUS33: { name: "WELLS FARGO BANK, N.A.", country: "UNITED STATES" },
  PNBPUS3C: { name: "WELLS FARGO BANK, N.A.", country: "UNITED STATES" },
  PNBPUS3M: { name: "WELLS FARGO BANK, N.A.", country: "UNITED STATES" },
  PNCCUS33: { name: "PNC BANK,N.A.,", country: "UNITED STATES" },
  POALILIT: { name: "BANK HAPOALIM", country: "ISRAEL" },
  POBNSKBA: { name: "365.BANK, A. S.", country: "SLOVAKIA" },
  POFICHBE: { name: "PostFinance AG", country: "SWITZERLAND" },
  POPUESMM: { name: "BANCO SANTANDER S.A", country: "SPAIN" },
  POSOCH22: { name: "BANCA POPOLARE DI SONDRIO (SUISSE)", country: "SWITZERLAND" },
  POSOIT22: { name: "BANCA POPOLARE DI SONDRIO", country: "ITALY" },
  PPABPLPK: { name: "BNP PARIBAS BANK POLSKA S.A", country: "POLAND" },
  PRABESMM: { name: "COOPERATIEVE RABOBANK U.A.", country: "SPAIN" },
  PRAVUAUK: { name: "JSCCB 'Pravex Bank'", country: "UKRAINE" },
  PRBAARBA: { name: "BANCO DE LA PROVINCIA DE BUENOS AIRES", country: "ARGENTINA" },
  PRCBBGSF: { name: "PROCREDIT BANK (BULGARIA) EAD", country: "BULGARIA" },
  PRDTNGLA: { name: "POLARIS BANK LIMITED", country: "NIGERIA" },
  PRIBCHGG: { name: "EDMOND DE ROTHSCHILD (SUISSE) S.A.", country: "SWITZERLAND" },
  PRMKCRSJ: { name: "BANCO PROMERICA DE COSTA RICA, S.A.", country: "COSTA RICA" },
  PROCFRPP: { name: "PROCAPITAL SA", country: "FRANCE" },
  PRODECEQ: { name: "BANCO DE LA PRODUCCION S.A. PRODUBANCO", country: "ECUADOR" },
  PRSHUS33: { name: "PERSHING LLC", country: "UNITED STATES" },
  PRTLAOLU: { name: "BANCO MILLENNIUM ATLANTICO, S.A.", country: "ANGOLA" },
  PSBCCNBJ: { name: "POSTAL SAVINGS BANK OF CHINA", country: "CHINA" },
  PSPBCAMA: { name: "PUBLIC SECTOR PENSION INVESTMENT BOARD", country: "CANADA" },
  PSSTFRPP: { name: "LA BANQUE POSTALE", country: "FRANCE" },
  PSTRESMM: { name: "BANCO SANTANDER S.A", country: "SPAIN" },
  PUILBEBB: { name: "QUINTET PRIVATE BANK (EUROPE) S.A.", country: "BELGIUM" },
  PUPPALTR: { name: "BANKA OTP ALBANIA SH.A.", country: "ALBANIA" },
  PUSBCNBQ: { name: "BUSAN BANK QINGDAO BRANCH", country: "CHINA" },
  PUSBKR2P: { name: "BUSAN BANK", country: "KOREA, REPUBLIC OF" },
  PUSBVNVX: { name: "THE BUSAN BANK CO., LTD. HOCHIMINH CITY BRANCH", country: "VIET NAM" },
  PXOSUS33: { name: "PAXOS TRUST COMPANY, LLC", country: "UNITED STATES" },
  PZHSDE66: { name: "SPARKASSE PFORZHEIM CALW", country: "GERMANY" },
  QIIBQAQA: { name: "QATAR INTERNATIONAL ISLAMIC BANK Information Services", country: "QATAR" },
  QISBQAQA: { name: "QATAR ISLAMIC BANK", country: "QATAR" },
  QNBAEGCX: { name: "QATAR NATIONAL BANK ALAHLI S.A.E (QNB ALAHLI)", country: "EGYPT" },
  QNBAFRPP: { name: "QATAR NATIONAL BANK", country: "FRANCE" },
  QNBAGB2L: { name: "QATAR NATIONAL BANK (Q.P.S.C.)", country: "UNITED KINGDOM" },
  QNBAKWKW: { name: "QATAR NATIONAL BANK", country: "KUWAIT" },
  QNBALBBE: { name: "QATAR NATIONAL BANK", country: "LEBANON" },
  QNBAMRMU: { name: "QATAR NATIONAL BANK (QNB)", country: "MAURITANIA" },
  QNBAOMRX: { name: "QATAR NATIONAL BANK", country: "OMAN" },
  QNBAQAQA: { name: "QATAR NATIONAL BANK", country: "QATAR" },
  QNBASDKH: { name: "QATAR NATIONAL BANK", country: "SUDAN" },
  QNBASGSG: { name: "QATAR NATIONAL BANK (Q.P.S.C)", country: "SINGAPORE" },
  QNBAYESA: { name: "QATAR NATIONAL BANK", country: "YEMEN" },
  RABOAU2B: { name: "RABOBANK AUSTRALIA LIMITED", country: "AUSTRALIA" },
  RABOAUSS: { name: "COOPERATIEVE RABOBANK U.A.", country: "AUSTRALIA" },
  RABOCNSH: { name: "COOPERATIEVE RABOBANK U.A.SHANGHAI BRANCH", country: "CHINA" },
  RABOHKHH: { name: "COOPERATIEVE RABOBANK U.A.", country: "HONG KONG" },
  RABOINBB: { name: "COOPERATIEVE RABOBANK U.A.", country: "INDIA" },
  RABONL2U: { name: "COOPERATIEVE RABOBANK U.A.", country: "NETHERLANDS" },
  RABOSGSG: { name: "COOPERATIEVE RABOBANK U.A.", country: "SINGAPORE" },
  RABOUS33: { name: "COOPERATIEVE RABOBANK U.A.", country: "UNITED STATES" },
  RAIFCH22: { name: "RAIFFEISEN SCHWEIZ GENOSSENSCHAFT", country: "SWITZERLAND" },
  RBKOXKPR: { name: "RAIFFEISEN BANK KOSOVO", country: "KOSOVO, REPUBLIC OF" },
  RBNKAIAI: { name: "REPUBLIC BANK (ANGUILLA) LIMITED", country: "ANGUILLA" },
  RBNKDMDM: { name: "REPUBLIC BANK (EC) LIMITED", country: "DOMINICA" },
  RBNKKNSK: { name: "REPUBLIC BANK (EC) LIMITED", country: "SAINT KITTS AND NEVIS" },
  RBNKLCLC: { name: "REPUBLIC BANK (EC) LIMITED", country: "SAINT LUCIA" },
  RBNKSXSM: { name: "REPUBLIC BANK (ST. MAARTEN) N.V.", country: "SINT MAARTEN" },
  RBNKTTPX: { name: "REPUBLIC BANK LIMITED", country: "TRINIDAD AND TOBAGO" },
  RBNKVCVC: { name: "REPUBLIC BANK (EC) LIMITED", country: "SAINT VINCENT AND THE GRENADINES" },
  RBNKVGVG: { name: "REPUBLIC BANK (BVI) LIMITED", country: "BRITISH VIRGIN ISLANDS" },
  RBOSGB2L: { name: "NATWEST MARKETS PUBLIC LIMITED COMPANY", country: "UNITED KINGDOM" },
  RBOSGB2R: { name: "NATWEST MARKETS PUBLIC LIMITED COMPANY", country: "UNITED KINGDOM" },
  RBOSGGSP: { name: "THE ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED", country: "GUERNSEY C.I." },
  RBOSGGSX: { name: "THE ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED", country: "GUERNSEY C.I." },
  RBOSGIGI: { name: "THE ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED", country: "GIBRALTAR" },
  RBOSHKHX: { name: "NATWEST MARKETS PLC HONG KONG BRANCH", country: "HONG KONG" },
  RBOSIMD2: { name: "THE ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED", country: "ISLE OF MAN" },
  RBOSIMDD: { name: "THE ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED", country: "ISLE OF MAN" },
  RBOSIMDX: { name: "THE ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED", country: "ISLE OF MAN" },
  RBOSJESH: { name: "THE ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED", country: "JERSEY C.I." },
  RBOSJESX: { name: "THE ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED", country: "JERSEY C.I." },
  RBOSLULL: { name: "THE ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED", country: "LUXEMBOURG" },
  RBOSNL2R: { name: "NATWEST MARKETS N.V.", country: "NETHERLANDS" },
  RBSIGB2L: { name: "THE ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED", country: "UNITED KINGDOM" },
  RBTTAWAW: { name: "RBC ROYAL BANK (ARUBA) N.V.", country: "ARUBA" },
  RBTTBQBN: { name: "RBC ROYAL BANK N.V.", country: "BONAIRE, SAINT EUSTATIUS AND SABA" },
  RBTTBQSA: { name: "RBC ROYAL BANK N.V.", country: "BONAIRE, SAINT EUSTATIUS AND SABA" },
  RBTTCWCU: { name: "RBC ROYAL BANK N.V.", country: "CURACAO" },
  RBTTSXSM: { name: "RBC ROYAL BANK N.V.", country: "SINT MAARTEN" },
  RBTTTTPX: {
    name: "RBC ROYAL BANK (TRINIDAD & TOBAGO) LIMITED (FORMERLY RBTT BANK LTD.)",
    country: "TRINIDAD AND TOBAGO",
  },
  RCBCPHMM: { name: "RIZAL COMMERCIAL BANKING CORP.", country: "PHILIPPINES" },
  RCBPFRPP: { name: "ROTHSCHILD MARTIN MAUREL", country: "FRANCE" },
  RFLCLULX: { name: "JPMORGAN ASSET MANAGEMENT (EUROPE) S.A.R.L.", country: "LUXEMBOURG" },
  RGIOMXMT: {
    name: "BANCO REGIONAL S.A. INSTITUCION DE BANCA MULTIPLE BANREGIO GRUPO FINANCIERO",
    country: "MEXICO",
  },
  RGSAPYPE: { name: "BANCO REGIONAL S.A.E.C.A.", country: "PARAGUAY" },
  RHBBMYKL: { name: "RHB BANK BERHAD", country: "MALAYSIA" },
  RIKOEE22: { name: "LUMINOR BANK AS", country: "ESTONIA" },
  RIKOLV2X: { name: "LUMINOR BANK AS LATVIAN BRANCH", country: "LATVIA" },
  RKBZCHZZ: { name: "GAZPROMBANK (SWITZERLAND) LTD", country: "SWITZERLAND" },
  RLBBAT2E: { name: "Raiffeisenlandesbank Burgenland und Revisionsverband eGen", country: "AUSTRIA" },
  RLNWATWW: { name: "RAIFFEISENLANDESBANK NIEDEROSTERREICH-WIEN AG", country: "AUSTRIA" },
  RNCBROBU: { name: "BANCA COMERCIALA ROMANA S.A ROMANIAN COMMERCIAL BANK N.A", country: "ROMANIA" },
  ROTRCATT: { name: "RBC INVESTOR SERVICES TRUST", country: "CANADA" },
  ROYCBBBB: { name: "RBC Royal Bank (Barbados ) Limited", country: "BARBADOS" },
  ROYCBSNS: {
    name: "RBC ROYAL BANK (BAHAMAS) LIMITED (FORMERLY KNOWN AS ROYAL BANK OF CANADA NASSAU)",
    country: "BAHAMAS",
  },
  ROYCCAT2: { name: "ROYAL BANK OF CANADA", country: "CANADA" },
  ROYCCAT3: { name: "ROYAL BANK OF CANADA", country: "CANADA" },
  ROYCFRPB: { name: "ROYAL BANK OF CANADA, PARIS BRANCH", country: "FRANCE" },
  ROYCGB22: { name: "RBC EUROPE LIMITED", country: "UNITED KINGDOM" },
  ROYCGB2L: { name: "ROYAL BANK OF CANADA", country: "UNITED KINGDOM" },
  ROYCHKHH: { name: "ROYAL BANK OF CANADA", country: "HONG KONG" },
  ROYCKYKY: {
    name: "RBC ROYAL BANK (CAYMAN) LIMITED (FORMERLY KNOWN AS ROYAL BANK OF CANADA GRAND CAYMAN)",
    country: "CAYMAN ISLANDS",
  },
  RSBNRUMM: { name: "Public joint-stock company ROSBANK", country: "RUSSIAN FEDERATION" },
  RTBSGB2L: { name: "RBC INVESTOR SERVICES TRUST, UK BRANCH", country: "UNITED KINGDOM" },
  RVSAAT2S: { name: "RAIFFEISENVERBAND SALZBURG, EGEN", country: "AUSTRIA" },
  RVVGAT2B: { name: "RAIFFEISENLANDESBANK VORARLBERG", country: "AUSTRIA" },
  RZBAATWW: { name: "RAIFFEISEN BANK INTERNATIONAL AG", country: "AUSTRIA" },
  RZBABA2S: { name: "RAIFFEISEN BANK D.D. BOSNA I HERCEGOVINA", country: "BOSNIA AND HERZEGOVINA" },
  RZBBBGSF: { name: "RAIFFEISENBANK (BULGARIA) EAD", country: "BULGARIA" },
  RZBCCZPP: { name: "RAIFFEISENBANK A.S.", country: "CZECHIA" },
  RZBHHR2X: { name: "RAIFFEISENBANK AUSTRIA D.D. ZA", country: "CROATIA" },
  RZBMRUMM: { name: "JOINT STOCK COMPANY RAIFFEISENBANK", country: "RUSSIAN FEDERATION" },
  RZBRROBU: { name: "RAIFFEISEN BANK S.A.", country: "ROMANIA" },
  RZBSRSBG: { name: "RAIFFEISEN BANK AD BELGRADE", country: "REPUBLIC OF SERBIA" },
  RZOOAT2L: { name: "RAIFFEISENLANDESBANK OBEROESTERREICH, AKTIENGESELLSCHAFT", country: "AUSTRIA" },
  RZOODE77: {
    name: "RAIFFEISENLANDESBANK OBEROSTERREICH AKTIENGESELLSCHAFT ZWEIGNIEDERLASUNG SUDDEUTSCHLAND",
    country: "GERMANY",
  },
  RZSBIT2B: { name: "RAIFFEISEN LANDESBANK SUEDTIROL AG", country: "ITALY" },
  RZTIAT22: { name: "RAIFFEISEN-LANDESBANK TIROL AG", country: "AUSTRIA" },
  SABBSARI: { name: "SAUDI BRITISH BANK, THE", country: "SAUDI ARABIA" },
  SABRATWW: { name: "SBERBANK EUROPE AG", country: "AUSTRIA" },
  SABRBA22: { name: "ASA BANKA NASA I SNAZNA D.D. SARAJEVO", country: "BOSNIA AND HERZEGOVINA" },
  SAFRBRSP: { name: "BANCO SAFRA SA", country: "BRAZIL" },
  SAIBJPJT: { name: "SAITAMA RESONA BANK, LIMITED", country: "JAPAN" },
  SALADE55: { name: "LANDESBANK SAAR ABTEILUNG SE IT", country: "GERMANY" },
  SAMBAEAD: { name: "THE SAUDI NATIONAL BANK", country: "UNITED ARAB EMIRATES" },
  SAMBQAQA: { name: "THE SAUDI NATIONAL BANK", country: "QATAR" },
  SAPYGB2L: { name: "BANKING CIRCLE S.A.", country: "UNITED KINGDOM" },
  SARACHBB: { name: "BANK J. SAFRA SARASIN LTD", country: "SWITZERLAND" },
  SARCIT2S: { name: "BANCA CASSA DI RISPARMIO DI SAVIGLIANO SPA", country: "ITALY" },
  SBHKHKHH: { name: "CITIGROUP GLOBAL MARKETS ASIA LTD", country: "HONG KONG" },
  SBICAOLU: { name: "STANDARD BANK DE ANGOLA S.A.", country: "ANGOLA" },
  SBICBWGX: { name: "STANBIC BANK BOTSWANA LIMITED International Trade Centre", country: "BOTSWANA" },
  SBICCDKX: { name: "STANDARD BANK RDC SA", country: "DEMOCRATIC REPUBLIC OF CONGO" },
  SBICGHAC: { name: "STANBIC BANK GHANA LIMITED", country: "GHANA" },
  SBICIMDX: { name: "STANDARD BANK ISLE OF MAN LTD", country: "ISLE OF MAN" },
  SBICKENX: { name: "STANBIC BANK KENYA LIMITED", country: "KENYA" },
  SBICLSMX: { name: "STANDARD LESOTHO BANK LTD.", country: "LESOTHO" },
  SBICMUMU: { name: "STANDARD BANK (MAURITIUS) LIMITED Level 9, Tower A, 1 CyberCity", country: "MAURITIUS" },
  SBICMWMX: { name: "STANDARD BANK LIMITED - MALAWI", country: "MALAWI" },
  SBICMZMX: { name: "STANDARD BANK SA", country: "MOZAMBIQUE" },
  SBICNGLX: { name: "STANBIC IBTC BANK PLC", country: "NIGERIA" },
  SBICSZMX: { name: "STANDARD BANK ESWATINI LIMITED", country: "ESWATINI" },
  SBICTZTX: { name: "STANBIC BANK TANZANIA LIMITED", country: "TANZANIA, UNITED REPUBLIC OF" },
  SBICUGKX: { name: "STANBIC BANK UGANDA LIMITED", country: "UGANDA" },
  SBICZMLX: { name: "STANBIC BANK ZAMBIA LIMITED", country: "ZAMBIA" },
  SBICZWHX: { name: "STANBIC BANK ZIMBABWE LIMITED", country: "ZIMBABWE" },
  SBILGB2L: { name: "CITIGROUP GLOBAL MARKETS LIMITED", country: "UNITED KINGDOM" },
  SBILGBKA: { name: "CITIGROUP GLOBAL MARKETS LIMITED", country: "UNITED KINGDOM" },
  SBILGBKB: { name: "CITIGROUP GLOBAL MARKETS LIMITED", country: "UNITED KINGDOM" },
  SBILGBKC: { name: "CITIGROUP GLOBAL MARKETS LIMITED", country: "UNITED KINGDOM" },
  SBILGBKD: { name: "CITIGROUP GLOBAL MARKETS LIMITED", country: "UNITED KINGDOM" },
  SBILGBKT: { name: "CITIGROUP GLOBAL MARKETS LIMITED", country: "UNITED KINGDOM" },
  SBINDEFF: { name: "STATE BANK OF INDIA", country: "GERMANY" },
  SBININBB: { name: "STATE BANK OF INDIA", country: "INDIA" },
  SBJKIDJA: { name: "PT. BANK SINARMAS, Tbk", country: "INDONESIA" },
  SBLLGB2L: { name: "ICBC STANDARD BANK PLC", country: "UNITED KINGDOM" },
  SBNMNANX: { name: "STANDARD BANK NAMIBIA LIMITED", country: "NAMIBIA" },
  SBNYUS33: { name: "CITIGROUP GLOBAL MARKETS INC.", country: "UNITED STATES" },
  SBOSDEMX: { name: "STATE STREET BANK INTERNATIONAL GMBH", country: "GERMANY" },
  SBOSGB2X: { name: "STATE STREET BANK AND TRUST COMPANY", country: "UNITED KINGDOM" },
  SBOSIE2D: { name: "STATE STREET FUND SERVICES (IRELAND) LIMITED", country: "IRELAND" },
  SBOSLULX: { name: "STATE STREET BANK INTERNATIONAL GMBH", country: "LUXEMBOURG" },
  SBOSUS3C: { name: "STATE STREET BANK AND TRUST COMPANY", country: "UNITED STATES" },
  SBOSUS3F: { name: "STATE STREET BANK AND TRUST COMPANY", country: "UNITED STATES" },
  SBOSUS3Q: { name: "STATE STREET BANK AND TRUST COMPANY", country: "UNITED STATES" },
  SBOSUS3T: { name: "STATE STREET BANK AND TRUST COMPANY", country: "UNITED STATES" },
  SBOSUS3U: { name: "STATE STREET BANK AND TRUST COMPANY", country: "UNITED STATES" },
  SBREDE22: { name: "SPARKASSE BREMEN AG, DIE", country: "GERMANY" },
  SBSIUS33: { name: "CITIGROUP GLOBAL MARKETS INC.", country: "UNITED STATES" },
  SBZAZAJJ: { name: "THE STANDARD BANK OF SOUTH AFRICA LIMITED", country: "SOUTH AFRICA" },
  SCAQCGCG: { name: "BANQUE SINO-CONGOLAISE POUR L'AFRIQUE", country: "CONGO" },
  SCBLAEAD: { name: "STANDARD CHARTERED BANK", country: "UNITED ARAB EMIRATES" },
  SCBLAU2S: { name: "STANDARD CHARTERED BANK, AUSTRALIA", country: "AUSTRALIA" },
  SCBLBDDX: { name: "STANDARD CHARTERED BANK", country: "BANGLADESH" },
  SCBLBHBM: { name: "STANDARD CHARTERED BANK", country: "BAHRAIN" },
  SCBLBNBB: { name: "STANDARD CHARTERED BANK", country: "BRUNEI DARUSSAL" },
  SCBLCIAB: { name: "STANDARD CHARTERED BANK COTE D'IVOIRE S.A.", country: "COTE D'IVOIRE" },
  SCBLCMCX: { name: "STANDARD CHARTERED BANK CAMEROON S.A.", country: "CAMEROON" },
  SCBLCNSX: { name: "STANDARD CHARTERED BANK (CHINA) LIMITED", country: "CHINA" },
  SCBLDEFX: { name: "STANDARD CHARTERED BANK AG", country: "GERMANY" },
  SCBLFKFK: { name: "STANDARD CHARTERED BANK", country: "FALKLAND ISLANDS" },
  SCBLGB2L: { name: "STANDARD CHARTERED BANK", country: "UNITED KINGDOM" },
  SCBLGHAC: { name: "STANDARD CHARTERED BANK GHANA PLC", country: "GHANA" },
  SCBLGMGM: { name: "STANDARD CHARTERED BANK GAMBIA LTD", country: "GAMBIA" },
  SCBLHKHH: { name: "STANDARD CHARTERED BANK (HONG KONG) LIMITED", country: "HONG KONG" },
  SCBLIDJX: { name: "STANDARD CHARTERED BANK", country: "INDONESIA" },
  SCBLINBB: { name: "STANDARD CHARTERED BANK", country: "INDIA" },
  SCBLJESH: { name: "Standard Chartered Bank, Jersey Branch", country: "JERSEY C.I." },
  SCBLJOAX: { name: "STANDARD CHARTERED BANK", country: "JORDAN" },
  SCBLJPJT: { name: "THE STANDARD CHARTERED BANK", country: "JAPAN" },
  SCBLKENX: { name: "STANDARD CHARTERED BANK KENYA LIMITED", country: "KENYA" },
  SCBLKRSE: { name: "STANDARD CHARTERED BANK KOREA LIMITED", country: "KOREA, REPUBLIC OF" },
  SCBLLKLX: { name: "STANDARD CHARTERED BANK", country: "SRI LANKA" },
  SCBLMOMX: { name: "STANDARD CHARTERED BANK", country: "MACAO" },
  SCBLMUMU: { name: "STANDARD CHARTERED BANK (MAURITIUS) LTD", country: "MAURITIUS" },
  SCBLMYKX: { name: "STANDARD CHARTERED BANK MALAYSIA BERHAD", country: "MALAYSIA" },
  SCBLNGLA: { name: "STANDARD CHARTERED BANK NIGERIA LTD Victoria Island", country: "NIGERIA" },
  SCBLNPKA: { name: "STANDARD CHARTERED BANK NEPAL LIMITED", country: "NEPAL" },
  SCBLOMRX: { name: "STANDARD CHARTERED BANK", country: "OMAN" },
  SCBLPHMM: { name: "STANDARD CHARTERED BANK", country: "PHILIPPINES" },
  SCBLPKKX: { name: "STANDARD CHARTERED BANK (PAKISTAN) LIMITED", country: "PAKISTAN" },
  SCBLQAQX: { name: "STANDARD CHARTERED BANK", country: "QATAR" },
  SCBLSG22: { name: "STANDARD CHARTERED BANK", country: "SINGAPORE" },
  SCBLSLFR: { name: "STANDARD CHARTERED BANK SIERRA LEONE LTD", country: "SIERRA LEONE" },
  SCBLTHBX: { name: "STANDARD CHARTERED BANK (THAI) PCL", country: "THAILAND" },
  SCBLTWTP: { name: "STANDARD CHARTERED BANK (TAIWAN) LIMITED", country: "TAIWAN, REPUBLIC OF CHINA" },
  SCBLTZTX: { name: "STANDARD CHARTERED BANK TANZANIA LIMITED", country: "TANZANIA, UNITED REPUBLIC OF" },
  SCBLUGKA: { name: "STANDARD CHARTERED BANK UGANDA LTD", country: "UGANDA" },
  SCBLUS33: { name: "STANDARD CHARTERED BANK", country: "UNITED STATES" },
  SCBLUS66: { name: "STANDARD CHARTERED BANK", country: "UNITED STATES" },
  SCBLVNVX: { name: "STANDARD CHARTERED BANK (VIETNAM) LIMITED", country: "VIET NAM" },
  SCBLZAJJ: { name: "STANDARD CHARTERED BANK", country: "SOUTH AFRICA" },
  SCBLZMLX: { name: "STANDARD CHARTERED BANK ZAMBIA PLC", country: "ZAMBIA" },
  SCBLZWHX: { name: "STANDARD CHARTERED BANK ZIMBABWE LIMITED", country: "ZIMBABWE" },
  SCHBBWGX: { name: "STANDARD CHARTERED BANK BOTSWANA LTD", country: "BOTSWANA" },
  SCIMLULL: { name: "SCHRODER INVESTMENT MANAGEMENT (EUROPE) S.A.", country: "LUXEMBOURG" },
  SCSBTWTP: { name: "SHANGHAI COMMERCIAL & SAVINGS BANK LIMITED", country: "TAIWAN, REPUBLIC OF CHINA" },
  SEAVVNVX: { name: "SOUTHEAST ASIA COMMERCIAL JOINT STOCK BANK", country: "VIET NAM" },
  SEBKLULL: { name: "INTESA SANPAOLO BANK LUXEMBOURG S.A", country: "LUXEMBOURG" },
  SEBPRU2P: { name: "SEB BANK", country: "RUSSIAN FEDERATION" },
  SEICUS33: { name: "SEI PRIVATE TRUST COMPANY", country: "UNITED STATES" },
  SEKETR2A: { name: "SEKERBANK T.A.S.", country: "TURKEY" },
  SELBIT2B: { name: "BANCA SELLA SPA", country: "ITALY" },
  SETCPHMM: { name: "SECURITY BANK CORPORATION", country: "PHILIPPINES" },
  SGABATWW: { name: "SOCIETE GENERALE ZWEIGNIEDERLASSUNG WIEN", country: "AUSTRIA" },
  SGABBEB2: { name: "SOCIETE GENERALE (SUCCURSALE DE LA GENERALE FRANCE)", country: "BELGIUM" },
  SGABCHZZ: { name: "SOCIETE GENERALE, PARIS, ZWEIGNIEDERLASSUNG ZURICH", country: "SWITZERLAND" },
  SGABLULL: { name: "SOCIETE GENERALE LUXEMBOURG", country: "LUXEMBOURG" },
  SGBLAU2S: { name: "ST.GEORGE BANK Westpac Banking Corporation", country: "AUSTRALIA" },
  SGCICIAB: { name: "SOCIETE GENERALE COTE D?IVOIRE", country: "COTE D'IVOIRE" },
  SGCLCNBJ: { name: "SOCIETE GENERALE (CHINA) LIMITED", country: "CHINA" },
  SGESGB2L: { name: "FIS GLOBAL EXECUTION SERVICES LIMITED", country: "UNITED KINGDOM" },
  SGLILBBX: { name: "SOCIETE GENERALE DE BANQUE AU LIBAN S.A.L.", country: "LEBANON" },
  SGMBMAMC: { name: "SOCIETE GENERALE MAROCAINE DE BANQUES", country: "MOROCCO" },
  SGNPJPJT: { name: "SOCIETE GENERALE SECURITIES JAPAN LIMITED", country: "JAPAN" },
  SGSBALTX: { name: "RAIFFEISEN BANK SH.A.", country: "ALBANIA" },
  SGSNSNDA: { name: "SOCIETE GENERALE SENEGAL", country: "SENEGAL" },
  SHBKCNBJ: { name: "SHINHAN BANK(CHINA) LTD", country: "CHINA" },
  SHBKDEFF: { name: "SHINHAN BANK EUROPE GMBH", country: "GERMANY" },
  SHBKGB2L: { name: "SHINHAN BANK, LONDON BRANCH", country: "UNITED KINGDOM" },
  SHBKHKHX: { name: "SHINHAN BANK, HONG KONG BRANCH", country: "HONG KONG" },
  SHBKINBB: { name: "SHINHAN BANK", country: "INDIA" },
  SHBKJPJX: { name: "SHINHAN BANK", country: "JAPAN" },
  SHBKKHPP: { name: "SHINHAN BANK (CAMBODIA) PLC", country: "CAMBODIA" },
  SHBKKRSE: { name: "SHINHAN BANK", country: "KOREA, REPUBLIC OF" },
  SHBKKZKA: { name: "SHINHAN BANK KAZAKHSTAN JSC", country: "KAZAKHSTAN" },
  SHBKSGSG: { name: "SHINHAN BANK, SINGAPORE BRANCH", country: "SINGAPORE" },
  SHBKUS33: { name: "SHINHAN BANK", country: "UNITED STATES" },
  SHBKUS6L: { name: "SHINHAN BANK AMERICA", country: "UNITED STATES" },
  SHBKVNVX: { name: "SHINHAN BANK VIETNAM LIMITED", country: "VIET NAM" },
  SICOTHBK: { name: "SIAM COMMERCIAL BANK PCL., THE", country: "THAILAND" },
  SIGNUS33: { name: "SIGNATURE BANK", country: "UNITED STATES" },
  SINSUS3N: { name: "SANTANDER INVESTMENT SECURITIES INC.", country: "UNITED STATES" },
  SISLISRE: { name: "CENTRAL BANK OF ICELAND", country: "ICELAND" },
  SIVBCIAB: { name: "SOCIETE IVOIRIENNE DE BANQUE", country: "COTE D'IVOIRE" },
  SKBASI2X: { name: "SKB BANKA D.D LJUBLJANA", country: "SLOVENIA" },
  SLBZCHZZ: { name: "SBERBANK (SWITZERLAND) AG", country: "SWITZERLAND" },
  SLCBSLFR: { name: "SIERRA LEONE COMMERCIAL BANK, LTD.", country: "SIERRA LEONE" },
  SLLULULL: { name: "SWISS LIFE (LUXEMBOURG) S.A.", country: "LUXEMBOURG" },
  SMBCAEAD: { name: "SUMITOMO MITSUI BANKING CORPORATION", country: "UNITED ARAB EMIRATES" },
  SMBCAU2S: { name: "SUMITOMO MITSUI BANKING CORPORATION SYDNEY BRANCH", country: "AUSTRALIA" },
  SMBCBEBB: { name: "SUMITOMO MITSUI BANKING CORPORATION", country: "BELGIUM" },
  SMBCCN22: { name: "SUMITOMO MITSUI BANKING CORPORATION (CHINA) LIMITED, GUANGZHOU BRANCH", country: "CHINA" },
  SMBCCN2H: { name: "SUMITOMO MITSUI BANKING CORPORATION (CHINA) LTD HANGZHOU BRANCH", country: "CHINA" },
  SMBCCNBJ: { name: "SUMITOMO MITSUI BANKING CORPORATION (CHINA) LIMITED BEIJING BRANCH", country: "CHINA" },
  SMBCCNBS: { name: "SUMITOMO MITSUI BANKING CORPORATION (CHINA) LIMITED SHENZHEN BRANCH", country: "CHINA" },
  SMBCCNBT: { name: "SUMITOMO MITSUI BANKING CORPORATION (CHINA) LTD TIANJIN BRANCH", country: "CHINA" },
  SMBCCNSH: { name: "SUMITOMO MITSUI BANKING CORPORATION (CHINA) LIMITED", country: "CHINA" },
  SMBCCNSU: { name: "SUMITOMO MITSUI BANKING CORPORATION (CHINA) LIMITED SUZHOU BRANCH", country: "CHINA" },
  SMBCDEDD: { name: "SUMITOMO MITSUI BANKING CORPORATION", country: "GERMANY" },
  SMBCDEFF: { name: "SMBC BANK EU AG", country: "GERMANY" },
  SMBCFRPP: { name: "SMBC BANK INTERNATIONAL PLC PARIS BRANCH", country: "FRANCE" },
  SMBCGB2L: { name: "SMBC BANK INTERNATIONAL PLC", country: "UNITED KINGDOM" },
  SMBCHKHH: { name: "SUMITOMO MITSUI BANKING CORPORATION", country: "HONG KONG" },
  SMBCINDD: { name: "SUMITOMO MITSUI BANKING CORPORATION NEW DELHI BRANCH", country: "INDIA" },
  SMBCITMM: { name: "SMBC BANK EU AG MILAN BRANCH", country: "ITALY" },
  SMBCJPJT: { name: "SUMITOMO MITSUI BANKING CORPORATION (FORMERLY SUMITOMO BANK)", country: "JAPAN" },
  SMBCKRSE: {
    name: "SUMITOMO MITSUI BANKING CORPORATION Mirae Asset CENTER1 West Tower",
    country: "KOREA, REPUBLIC OF",
  },
  SMBCMMMY: { name: "SUMITOMO MITSUI BANKING CORPORATION YANGON BRANCH", country: "MYANMAR" },
  SMBCMYKL: { name: "SUMITOMO MITSUI BANKING CORPORATION MALAYSIA BERHAD", country: "MALAYSIA" },
  SMBCRUMM: { name: "JSC SUMITOMO MITSUI RUS BANK", country: "RUSSIAN FEDERATION" },
  SMBCSGSG: { name: "SUMITOMO MITSUI BANKING CORPORATION (FORMERLY THE SAKURA BANK, LTD)", country: "SINGAPORE" },
  SMBCTHBK: { name: "SUMITOMO MITSUI BANKING CORPORATION", country: "THAILAND" },
  SMBCTWTP: { name: "SUMITOMO MITSUI BANKING CORPORATION TAIPEI BRANCH", country: "TAIWAN, REPUBLIC OF CHINA" },
  SMBCUS33: { name: "SUMITOMO MITSUI BANKING CORPORATION", country: "UNITED STATES" },
  SMBCVNVX: { name: "SUMITOMO MITSUI BANKING CORPORATION HO CHI MINH CITY BRANCH", country: "VIET NAM" },
  SMCTFR2A: { name: "SOCIETE MARSEILLAISE DE CREDIT", country: "FRANCE" },
  SMFDIE2D: { name: "SUMITOMO MITSUI FINANCE (DUBLIN) LTD", country: "IRELAND" },
  SMHBDEFF: { name: "UBS EUROPE SE", country: "GERMANY" },
  SMPOLT22: { name: "DANSKE BANK A/S LIETUVOS FILIALAS", country: "LITHUANIA" },
  SMTOCATT: { name: "SUMITOMO MITSUI BANKING CORPORATION, CANADA BRANCH", country: "CANADA" },
  SNBZCHZZ: { name: "SCHWEIZERISCHE NATIONALBANK", country: "SWITZERLAND" },
  SNCBBHBM: { name: "THE SAUDI NATIONAL BANK, BAHRAIN BRANCH", country: "BAHRAIN" },
  SNTRUS3A: { name: "TRUIST BANK", country: "UNITED STATES" },
  SOCBPFTX: { name: "BANQUE SOCREDO", country: "FRENCH POLYNESIA" },
  SOGEDEFF: { name: "SOCIETE GENERALE S.A.", country: "GERMANY" },
  SOGEDZAL: { name: "SOCIETE GENERALE ALGERIE SPA", country: "ALGERIA" },
  SOGEESMM: { name: "SOCIETE GENERALE SUCURSAL EN ESPANA", country: "SPAIN" },
  SOGEFRPP: { name: "SOCIETE GENERALE", country: "FRANCE" },
  SOGEGB2L: { name: "SOCIETE GENERALE", country: "UNITED KINGDOM" },
  SOGEGB44: { name: "SOCIETE GENERALE LONDON BRANCH", country: "UNITED KINGDOM" },
  SOGEGPGP: { name: "BANQUE DES CARAIBES", country: "GUADELOUPE" },
  SOGEHKHH: { name: "SOCIETE GENERALE HONG KONG BRANCH", country: "HONG KONG" },
  SOGEITMM: { name: "SOCIETE GENERALE S.A", country: "ITALY" },
  SOGEJPJT: { name: "SOCIETE GENERALE", country: "JAPAN" },
  SOGEKRSE: { name: "SOCIETE GENERALE, SEOUL BRANCH", country: "KOREA, REPUBLIC OF" },
  SOGENCNN: { name: "SOCIETE GENERALE", country: "FRANCE" },
  SOGENL2A: { name: "SOCIETE GENERALE", country: "NETHERLANDS" },
  SOGEPLPW: { name: "SOCIETE GENERALE SPOLKA AKCYJNA ODDZIAL W POLSCE", country: "POLAND" },
  SOGESGSG: { name: "SOCIETE GENERALE, SINGAPORE BRANCH", country: "SINGAPORE" },
  SOGEUS33: { name: "SOCIETE GENERALE", country: "UNITED STATES" },
  SOLADEST: { name: "LANDESBANK BADEN-WUERTTEMBERG", country: "GERMANY" },
  SOLAGB2L: { name: "LANDESBANK BADEN-WUERTTEMBERG", country: "UNITED KINGDOM" },
  SOLASGSG: { name: "LANDESBANK BADEN-WUERTTEMBERG", country: "SINGAPORE" },
  SPARNO22: { name: "SPAREBANKEN MOERE", country: "NORWAY" },
  SPDBCNSH: { name: "SHANGHAI PUDONG DEVELOPMENT BANK", country: "CHINA" },
  SPSONO22: { name: "SPAREBANKEN SOR", country: "NORWAY" },
  SQARUS33: { name: "PACIFIC WESTERN BANK", country: "UNITED STATES" },
  SSBSAU2S: { name: "CITIGROUP GLOBAL MARKETS AUSTRALIA PTY LIMITED", country: "AUSTRALIA" },
  SSBSZAJJ: { name: "CITIGROUP GLOBAL MARKETS (PTY) LTD", country: "SOUTH AFRICA" },
  SSEBGHAC: { name: "SOCIETE GENERALE GHANA PLC", country: "GHANA" },
  SSKMDEMM: { name: "STADTSPARKASSE MUENCHEN", country: "GERMANY" },
  STBCGB2L: { name: "SUMITOMO MITSUI TRUST BANK, LIMITED", country: "UNITED KINGDOM" },
  STBCSGSG: { name: "SUMITOMO MITSUI TRUST BANK, LIMITED SINGAPORE BRANCH", country: "SINGAPORE" },
  STBCUS33: { name: "SUMITOMO MITSUI TRUST BANK,LIMITED", country: "UNITED STATES" },
  STCBMUMU: { name: "SBM BANK (MAURITIUS) LTD", country: "MAURITIUS" },
  STDMDMDM: { name: "STANDARD COMMERCE BANK LTD.", country: "DOMINICA" },
  STETUS33: { name: "WEBSTER BANK, N.A.", country: "UNITED STATES" },
  STGBPAPA: { name: "ST. GEORGES BANK AND COMPANY, INC.", country: "PANAMA" },
  STOBMK2X: { name: "STOPANSKA BANKA A.D. skopje", country: "NORTH MACEDONIA" },
  STSABGSF: { name: "DSK BANK AD", country: "BULGARIA" },
  STSPAT2G: { name: "STEIERMAERKISCHE BANK UND SPARKASSEN AG", country: "AUSTRIA" },
  SUBACZPP: { name: "VSEOBECNA UVEROVA BANKA A.S. BRANCH PRAHA", country: "CZECHIA" },
  SUBASKBX: { name: "VSEOBECNA UVEROVA BANKA A.S", country: "SLOVAKIA" },
  SUNDUS33: { name: "FIS BROKERAGE AND SECURITIES SERVICES LLC", country: "UNITED STATES" },
  SUNDUS3A: { name: "FIS BROKERAGE AND SECURITIES SERVICES LLC", country: "UNITED STATES" },
  SUNDUS3B: { name: "FIS BROKERAGE AND SECURITIES SERVICES LLC", country: "UNITED STATES" },
  SUNDUS3D: { name: "FIS BROKERAGE AND SECURITIES SERVICES LLC", country: "UNITED STATES" },
  SUNIIDJA: { name: "PT BANK BTPN TBK", country: "INDONESIA" },
  SURIUYMM: { name: "BANQUE HERITAGE (URUGUAY) S.A.", country: "URUGUAY" },
  SVBKUS6S: { name: "SILICON VALLEY BANK", country: "UNITED STATES" },
  SVRNUS33: { name: "SANTANDER BANK, N.A", country: "UNITED STATES" },
  SWEDDKKK: { name: "SWEDBANK AB", country: "DENMARK" },
  SWEDNOKK: { name: "SWEDBANK AB", country: "NORWAY" },
  SWEDSESS: { name: "SWEDBANK AB", country: "SWEDEN" },
  SWILFRPP: { name: "SWISSLIFE BANQUE PRIVEE", country: "FRANCE" },
  SWLCLI22: { name: "SWISS LIFE (LIECHTENSTEIN) AG", country: "LIECHTENSTEIN" },
  SWLPLULL: { name: "SWISS LIFE PRODUCTS (LUXEMBOURG) S.A.", country: "LUXEMBOURG" },
  SWRILULL: { name: "SWISS RE INTERNATIONAL SE", country: "LUXEMBOURG" },
  SXPYDKKK: { name: "BANKING CIRCLE DENMARK FILIAL AF BANKING CIRCLE S.A., LUXEMBOURG", country: "DENMARK" },
  SYCBCNBY: { name: "SHENGJING BANK CO.,LTD", country: "CHINA" },
  SZDBCNBS: { name: "PING AN BANK CO., LTD.", country: "CHINA" },
  TACBTWTP: { name: "TAIWAN COOPERATIVE BANK LTD", country: "TAIWAN, REPUBLIC OF CHINA" },
  TATRSKBX: { name: "TATRA BANK", country: "SLOVAKIA" },
  TBCBGE22: { name: "JSC - TBC Bank", country: "GEORGIA" },
  TCABKHPP: { name: "J TRUST ROYAL BANK PLC.", country: "CAMBODIA" },
  TCZBTR2A: { name: "TURKIYE CUMHURIYETI ZIRAAT BANKASI", country: "TURKEY" },
  TDBMMNUB: { name: "TRADE AND DEVELOPMENT BANK OF MONGOLIA", country: "MONGOLIA" },
  TDOMCATT: { name: "THE TORONTO DOMINION BANK", country: "CANADA" },
  TDOMGB2L: { name: "TORONTO DOMINION BANK", country: "UNITED KINGDOM" },
  TDOMIE2D: { name: "TD GLOBAL FINANCE UNLIMITED COMPANY", country: "IRELAND" },
  TDOMSGSG: { name: "TORONTO DOMINION BANK", country: "SINGAPORE" },
  TDOMUS33: { name: "THE TORONTO-DOMINION BANK", country: "UNITED STATES" },
  TEBUTRIS: { name: "TURK EKONOMI BANKASI A.S", country: "TURKEY" },
  TECNBY22: { name: "JOINT STOCK COMPANY TECHNOBANK", country: "BELARUS" },
  TEKFTRIS: { name: "BURGAN BANK A.S.", country: "TURKEY" },
  TFSBUS66: { name: "TOYOTA FINANCIAL SAVINGS BANK", country: "UNITED STATES" },
  TGBAMTMT: { name: "TURKIYE GARANTI BANKASI ANONIM SIRKETI", country: "MALTA" },
  TGBATRIS: { name: "TURKIYE GARANTI BANKASI A.S.", country: "TURKEY" },
  TIBKYESA: { name: "TADHAMON BANK", country: "YEMEN" },
  TJSCRUMM: { name: "TRANSKAPITALBANK", country: "RUSSIAN FEDERATION" },
  TMBKTHBK: { name: "TMBTHANACHART BANK PUBLIC COMPANY LIMITED", country: "THAILAND" },
  TOTAPTPL: { name: "BANCO SANTANDER TOTTA, SA", country: "PORTUGAL" },
  TOWEPAPA: { name: "TOWERBANK INTERNATIONAL, INC.", country: "PANAMA" },
  TPBKHKHH: { name: "TAIPEI FUBON COMMERCIAL BANK CO., LTD. HONG KONG BRANCH", country: "HONG KONG" },
  TPBKTWTP: { name: "TAIPEI FUBON COMMERCIAL BANK CO. LTD", country: "TAIWAN, REPUBLIC OF CHINA" },
  TREFFRP2: { name: "TRADITION SECURITIES AND FUTURES", country: "FRANCE" },
  TRHBBHBM: { name: "TURKIYE HALK BANKASI A.S. BAHRAIN BRANCH", country: "BAHRAIN" },
  TRHBTR2A: { name: "TURKIYE HALK BANKASI A.S", country: "TURKEY" },
  TSIBHKHH: { name: "TAISHIN INTERNATIONAL BANK CO., LTD.", country: "HONG KONG" },
  TSIBTWTP: { name: "TAISHIN INTERNATIONAL BANK CO.,LTD.", country: "TAIWAN, REPUBLIC OF CHINA" },
  TUBDDEDD: { name: "HSBC TRINKAUS UND BURKHARDT GMBH", country: "GERMANY" },
  TUNZBEBB: { name: "INGENICO FINANCIAL SOLUTIONS S.A.", country: "BELGIUM" },
  TUTNMK22: { name: "NLB BANKA AD SKOPJE", country: "NORTH MACEDONIA" },
  TVBABHBM: { name: "TURKIYE VAKIFLAR BANKASI T.A.O. BAHRAIN BRANCH (OBU)", country: "BAHRAIN" },
  TVBATR2A: { name: "TURKIYE VAKIFLAR BANKASI T.A.O", country: "TURKEY" },
  TVSBTRIS: { name: "ISTANBUL TAKAS VE SAKLAMA BANKASI ANONIM SIRKETI", country: "TURKEY" },
  TXCBUS44: { name: "TEXAS CAPITAL BANK, N.A.", country: "UNITED STATES" },
  UBACGB2L: { name: "UNITED BANK FOR AFRICA (UK) LTD", country: "UNITED KINGDOM" },
  UBAFFRPP: { name: "UNION DES BANQUES ARABES ET FRANCAISES - UBAF", country: "FRANCE" },
  UBAFJPJX: { name: "U.B.A.F. - UNION DE BANQUES ARABES ET FRANCAISES TOKYO BRANCH", country: "JAPAN" },
  UBAFKRSX: { name: "UBAF - UNION DES BANQUES ARABES ET FRANCAISES", country: "KOREA, REPUBLIC OF" },
  UBAFSGSX: { name: "UNION DE BANQUES ARABES ET FRANCAISES-UBAF", country: "SINGAPORE" },
  UBAGGNCN: { name: "UNITED BANK FOR AFRICA GUINEA", country: "GUINEA" },
  UBAIITRR: { name: "BANCA UBAE SPA", country: "ITALY" },
  UBBRPYPX: { name: "BANCO ITAU PARAGUAY S.A.", country: "PARAGUAY" },
  UBHKHKHH: { name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LIMITED", country: "HONG KONG" },
  UBNINGLA: { name: "UNION BANK OF NIGERIA PLC International department", country: "NIGERIA" },
  UBNKHTPP: { name: "UNIBANK S.A.", country: "HAITI" },
  UBOBTHBK: { name: "CIMB THAI BANK PUBLIC COMPANY LIMITED", country: "THAILAND" },
  UBOTTWTP: { name: "UNION BANK OF TAIWAN", country: "TAIWAN, REPUBLIC OF CHINA" },
  UBPHPHMM: { name: "UNION BANK OF THE PHILIPPINES", country: "PHILIPPINES" },
  UBRTHUHB: { name: "RAIFFEISEN BANK Ltd", country: "HUNGARY" },
  UBSBCHZZ: { name: "UBS AG", country: "SWITZERLAND" },
  UBSWCHZ3: { name: "UBS SWITZERLAND AG", country: "SWITZERLAND" },
  UBSWCHZH: { name: "UBS AG", country: "SWITZERLAND" },
  UBSWDE24: { name: "UBS EUROPE SE", country: "GERMANY" },
  UBSWDEFF: { name: "UBS EUROPE SE", country: "GERMANY" },
  UBSWFRPP: { name: "UBS (FRANCE) SA", country: "FRANCE" },
  UBSWGB2L: { name: "UBS AG LONDON BRANCH", country: "UNITED KINGDOM" },
  UBSWGB55: { name: "UBS AG LONDON BRANCH", country: "UNITED KINGDOM" },
  UBSWHKHH: { name: "UBS AG HONG KONG BRANCH", country: "HONG KONG" },
  UBSWLULL: { name: "UBS EUROPE SE, LUXEMBOURG BRANCH", country: "LUXEMBOURG" },
  UBSWMCMX: { name: "UBS (MONACO) SA", country: "MONACO" },
  UBSWSGSG: { name: "UBS AG Singapore Branch", country: "SINGAPORE" },
  UBSWUS33: { name: "UBS AG STAMFORD BRANCH", country: "UNITED STATES" },
  UCJAES2M: { name: "UNICAJA BANCO, S.A.", country: "SPAIN" },
  UGABGALI: { name: "UNION GABONAISE DE BANQUE", country: "GABON" },
  UGBINL2A: { name: "GARANTIBANK INTERNATIONAL N.V.", country: "NETHERLANDS" },
  UGBIROBU: { name: "GARANTI BANK S.A.", country: "ROMANIA" },
  UIBMMK22: { name: "UNIVERSAL INVESTMENT BANK AD SKOPJE (UNIBANK)", country: "NORTH MACEDONIA" },
  ULSBGB2B: { name: "NATIONAL WESTMINSTER BANK PLC", country: "UNITED KINGDOM" },
  ULSBIE2D: { name: "ULSTER BANK IRELAND DAC", country: "IRELAND" },
  UMKCUS44: { name: "UMB BANK N.A", country: "UNITED STATES" },
  UMPLNGLA: { name: "PROVIDUSBANK PLC", country: "NIGERIA" },
  UNAFCGCG: { name: "UNITED BANK FOR AFRICA, CONGO BRAZZ", country: "CONGO" },
  UNAFCIAB: { name: "UNITED BANK FOR AFRICA COTE D'IVOIRE", country: "COTE D'IVOIRE" },
  UNAFCMCX: { name: "UNITED BANK FOR AFRICA CAMEROON PLC", country: "CAMEROON" },
  UNAFGALI: { name: "UBA GABON", country: "GABON" },
  UNAFLRLM: { name: "UNITED BANK FOR AFRICA (LIBERIA) LIMITED", country: "LIBERIA" },
  UNAFMLBA: { name: "UNITED BANK FOR AFRICA MALI SA", country: "MALI" },
  UNAFMZMA: { name: "UBA MOCAMBIQUE,SA", country: "MOZAMBIQUE" },
  UNAFNGLA: { name: "UNITED BANK FOR AFRICA PLC", country: "NIGERIA" },
  UNAFSLFR: { name: "UNITED BANK FOR AFRICA (S/L) LTD", country: "SIERRA LEONE" },
  UNAFSNDA: { name: "UNITED BANK FOR AFRICA SENEGAL", country: "SENEGAL" },
  UNAFTDND: { name: "UNITED BANK FOR AFRICA - TCHAD", country: "CHAD" },
  UNAFZMLU: { name: "UNITED BANK FOR AFRICA ZAMBIA LIMITED", country: "ZAMBIA" },
  UNBSBY2X: { name: "JSC BSB Bank", country: "BELARUS" },
  UNCRBA22: { name: "UNICREDIT BANK", country: "BOSNIA AND HERZEGOVINA" },
  UNCRBGSF: { name: "UNICREDIT BULBANK AD", country: "BULGARIA" },
  UNCRGB22: { name: "UNICREDIT SPA - LONDON BRANCH", country: "UNITED KINGDOM" },
  UNCRITMM: { name: "Unicredit S.p.A.", country: "ITALY" },
  UNCRSKBX: { name: "UNICREDIT BANK CZECH REPUBLIC AND SLOVAKIA, A.S., POBOCKA ZAHRANICNE", country: "SLOVAKIA" },
  UNCRUS33: { name: "UNICREDIT SPA", country: "UNITED STATES" },
  UNICMZMX: { name: "NEDBANK MOCAMBIQUE, S.A.", country: "MOZAMBIQUE" },
  UNILPKKA: { name: "UNITED BANK LIMITED", country: "PAKISTAN" },
  UNTDETAA: { name: "HIBRET BANK SHARE COMPANY", country: "ETHIOPIA" },
  UOVBCNSH: { name: "UNITED OVERSEAS BANK (CHINA) LIMITED", country: "CHINA" },
  UOVBHKHH: { name: "UNITED OVERSEAS BANK LTD", country: "HONG KONG" },
  UOVBKRSE: { name: "UNITED OVERSEAS BANK LIMITED, SEOUL BRANCH", country: "KOREA, REPUBLIC OF" },
  UOVBMYKL: { name: "UNITED OVERSEAS BANK (MALAYSIA) BHD", country: "MALAYSIA" },
  UOVBSGSG: { name: "UNITED OVERSEAS BANK LTD", country: "SINGAPORE" },
  UOVBTHBK: { name: "UNITED OVERSEAS BANK (THAI) PUBLIC COMPANY LIMITED", country: "THAILAND" },
  UPBKBA22: { name: "Intesa Sanpaolo Banka d.d. Bosna i Hercegovina", country: "BOSNIA AND HERZEGOVINA" },
  UPNBUS44: { name: "REGIONS BANK", country: "UNITED STATES" },
  USALALTR: { name: "INTESA SANPAOLO BANK ALBANIA SH.A.", country: "ALBANIA" },
  USBKUS44: { name: "U.S. BANK NATIONAL ASSOCIATION", country: "UNITED STATES" },
  USBKUS4T: { name: "U.S. BANK NATIONAL ASSOCIATION", country: "UNITED STATES" },
  UWCBTWTP: { name: "CATHAY UNITED BANK", country: "TAIWAN, REPUBLIC OF CHINA" },
  VABECH22: { name: "Valiant Bank AG", country: "SWITZERLAND" },
  VALLMTMT: { name: "BANK OF VALLETTA P.L.C.", country: "MALTA" },
  VBCRHR22: { name: "NOVA HRVATSKA BANKA D.D.", country: "CROATIA" },
  VBOEATWW: { name: "VOLKSBANK WIEN AG", country: "AUSTRIA" },
  VBOECZ2X: { name: "SBERBANK CZ, A.S.", country: "CZECHIA" },
  VGAGDEHH: { name: "VARENGOLD BANK AG", country: "GERMANY" },
  VOWADE2B: { name: "VOLKSWAGEN BANK GMBH", country: "GERMANY" },
  VOWAITMM: { name: "VOLKSWAGEN BANK GMBH", country: "ITALY" },
  VRBPCH22: { name: "BANCA ALETTI E C. (SUISSE) SA", country: "SWITZERLAND" },
  VSTRZMLU: { name: "INVESTRUST BANK PLC", country: "ZAMBIA" },
  VTBAAZ22: { name: "OJSC VTB BANK (AZERBAIJAN)", country: "AZERBAIJAN" },
  VTBAKZKZ: { name: "VTB BANK (KAZAKHSTAN)", country: "KAZAKHSTAN" },
  VTCBVNVX: { name: "VIETNAM TECHNOLOGICAL AND COMMERCIAL JOINT STOCK BANK", country: "VIET NAM" },
  VZCRVECA: { name: "VENEZOLANO DE CREDITO, S.A. BANCO UNIVERSAL", country: "VENEZUELA" },
  WBANRO22: { name: "BANCA COMERCIALA INTESA SANPAOLO ROMANIA S.A.", country: "ROMANIA" },
  WBKPPLPP: { name: "SANTANDER BANK POLSKA S.A.", country: "POLAND" },
  WELADEDD: { name: "LANDESBANK HESSEN-THUERINGEN GZ", country: "GERMANY" },
  WELADEDL: { name: "SPARKASSE LEVERKUSEN", country: "GERMANY" },
  WEMANGLA: { name: "WEMA BANK PLC", country: "NIGERIA" },
  WFBIUS6A: { name: "WELLS FARGO BANK, N.A.", country: "UNITED STATES" },
  WFBIUS6S: { name: "WELLS FARGO BANK, N.A.", country: "UNITED STATES" },
  WFBIUS6T: { name: "WELLS FARGO BANK, N.A.", country: "UNITED STATES" },
  WFBIUS6W: { name: "WELLS FARGO BANK, N.A.", country: "UNITED STATES" },
  WFLDUS33: { name: "WESTFIELD FINANCIAL, INC.", country: "UNITED STATES" },
  WHBKCNBJ: { name: "WEIHAI CITY COMMERCIAL BANK CO. LTD", country: "CHINA" },
  WIBADE5W: { name: "WIESBADENER VOLKSBANK EG", country: "GERMANY" },
  WPACAU2F: { name: "WESTPAC BANKING CORPORATION", country: "AUSTRALIA" },
  WPACAU2S: { name: "WESTPAC BANKING CORPORATION", country: "AUSTRALIA" },
  WPACCNSX: { name: "WESTPAC BANKING CORPORATION", country: "CHINA" },
  WPACFJFX: { name: "WESTPAC BANKING CORPORATION", country: "FIJI" },
  WPACGB2L: { name: "WESTPAC BANKING CORPORATION", country: "UNITED KINGDOM" },
  WPACGB3L: { name: "WESTPAC EUROPE LIMITED", country: "UNITED KINGDOM" },
  WPACHKHX: { name: "WESTPAC BANKING CORPORATION", country: "HONG KONG" },
  WPACNZ2W: { name: "WESTPAC NEW ZEALAND LIMITED", country: "NEW ZEALAND" },
  WPACPGPM: { name: "WESTPAC BANK PNG LIMITED", country: "PAPUA NEW GUINEA, INDEPENDENT STATE OF" },
  WPACSGSG: { name: "WESTPAC BANKING CORPORATION", country: "SINGAPORE" },
  WPACUS33: { name: "WESTPAC BANKING CORPORATION", country: "UNITED STATES" },
  WRMTGB2L: { name: "WORLDREMIT LTD", country: "UNITED KINGDOM" },
  WUIBATWW: { name: "WESTERN UNION INTERNATIONAL BANK GMBH", country: "AUSTRIA" },
  XPARFRPP: { name: "EURONEXT PARIS S.A.", country: "FRANCE" },
  YAPITRIS: { name: "YAPI VE KREDI BANKASI A.S", country: "TURKEY" },
  YESBINBB: { name: "YES BANK LIMITED", country: "INDIA" },
  YOMAMMMY: { name: "YOMA BANK LIMITED", country: "MYANMAR" },
  YOUIFRPP: { name: "YOUNITED S.A.", country: "FRANCE" },
  ZABAHR2X: { name: "ZAGREBACKA BANKA D.D.", country: "CROATIA" },
  ZEBLGHAC: { name: "ZENITH BANK (GHANA) LIMITED", country: "GHANA" },
  ZEIBGB2L: { name: "ZENITH BANK (UK) LTD", country: "UNITED KINGDOM" },
  ZEIBGMGM: { name: "ZENITH BANK GAMBIA", country: "GAMBIA" },
  ZEIBNGLA: { name: "ZENITH BANK PLC", country: "NIGERIA" },
  ZEMEETAA: { name: "ZEMEN BANK S.C.", country: "ETHIOPIA" },
  ZESLSLFR: { name: "ZENITH BANK (SIERRA LEONE) LIMITED", country: "SIERRA LEONE" },
  ZFNBUS55: { name: "ZIONS BANCORPORATION N.A.", country: "UNITED STATES" },
  ZGIEDEFF: { name: "ZUERICH BETEILIGUNGS-AG (DEUTSCHLAND)", country: "GERMANY" },
  ZJCBHKHH: { name: "CHINA ZHESHANG BANK CO., LTD.", country: "HONG KONG" },
  ZKBKCHZZ: { name: "ZUERCHER KANTONALBANK", country: "SWITZERLAND" },
  ZNCOZMLU: { name: "ZAMBIA NATIONAL COMMERCIAL BANK LIMITED", country: "ZAMBIA" },
};
