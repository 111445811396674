import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import Select, { components } from "react-select";
import { StatusListStateContext } from "state/providers/StatusListProvider";
import * as statusAPI from "api2/status-list";
import { formatMonth } from "../../../../utils/date";

function StatusTag({ max, current }) {
  const finalised = current === max;
  let classTag = "gray";
  if (finalised) {
    classTag = "green";
  } else if (current > 1 && current < max) {
    classTag = "purple";
  }
  return (
    <div className="overview-select-value">
      <div className={cx("status-tag", classTag)}>
        <i className="fe-check" />
        <div className="circle" />
      </div>
    </div>
  );
}

function SingleValue({ children, ...props }) {
  const { data, options } = props;
  const max = options.length;
  const current = data.index;
  return (
    <components.SingleValue {...props}>
      <StatusTag current={current} max={max} />
    </components.SingleValue>
  );
}

function MenuPeriod({ start, end, periodicity }) {
  const { i18n } = useTranslation();
  const singleMonth = periodicity === "monthly";
  return (
    <div className="menu-period-title">
      {singleMonth ? (
        <span>{formatMonth(start, "MMM yy", i18n.language)}</span>
      ) : (
        <span>
          {formatMonth(start, "MMM yy", i18n.language)} - {formatMonth(end, "MMM yy", i18n.language)}
        </span>
      )}
    </div>
  );
}

function Menu({ event, children, ...props }) {
  return (
    <div className="status-list-select status-list-select__overview-modal">
      <components.Menu {...props} className="select__menu-portal--overview-modal">
        <MenuPeriod start={event.period_start} end={event.period_end} periodicity={event.periodicity} />
        {children}
      </components.Menu>
    </div>
  );
}

function formatOptionLabel({ value, label, max, index, ...props }) {
  return (
    <>
      <StatusTag current={index} max={max} />
      <span className="ml-1">{label}</span>
    </>
  );
}

function OverviewEventSelect({ event, current, options, updateEvent: updateEventOverview }) {
  const { updateEvent } = useContext(StatusListStateContext);
  const { t } = useTranslation("officeSupport");
  const onChange = (selected) => {
    statusAPI.events.updateStatus(event.id, selected.value);
    updateEvent(event.id, { status: selected.value });
    updateEventOverview(event.id, { status: selected.value });
  };
  return (
    <Select
      className="status-list-select status-list-select__overview-modal"
      classNamePrefix="select"
      menuPlacement="auto"
      formatOptionLabel={(props) => formatOptionLabel({ max: options.length, ...props })}
      components={{
        SingleValue: (props) => <SingleValue current={current} {...props} />,
        Menu: (props) => <Menu event={event} {...props} />,
      }}
      onChange={onChange}
      options={options.map((item) => ({ ...item, label: t(`statuses.${item.value}`) }))}
      isSearchable={false}
      menuPortalTarget={document.body}
      // menuPortalTarget={document.querySelector(".status-list-overview-modal")}
      // menuPortalTarget={document.querySelector(".status-list-overview-modal.overview-table")}
      menuShouldBlockScroll
      menuPosition="fixed"
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        menu: (provided, state) => ({
          ...provided,
          width: 250,
        }),
        control: (base) => ({
          ...base,
          border: 0,
          boxShadow: "none",
        }),
      }}
      value={current}
    />
  );
}

export default OverviewEventSelect;
