import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import * as companyAPI from "api2/companies";
import { AllError, UserPicker } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import { CompanySettingStateContext } from "state/providers/CompanySettingProvider";

function AuditorForm({ companyId, auditor, successCallback }) {
  const { t } = useTranslation("company");
  const {
    info: { agency_id: agencyId },
  } = useContext(CompanySettingStateContext);
  const formikProps = {
    initialValues: {
      user: auditor.user && {
        value: auditor.user,
        label: auditor.user_name,
      },
    },
    validationSchema: yup.object().shape({
      user: yup.object().nullable().required(),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await companyAPI.auditors
        .save(companyId, {
          user: values.user && values.user.value,
        })
        .then((response) => {
          resetForm();
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (successCallback) {
            successCallback(response.data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ isSubmitting, setFieldValue, errors }) => {
        return (
          <Form>
            <p>{t("auditorAddInfo")}</p>
            <Row>
              <Col sm={6} xl={4}>
                <UserPicker
                  name="user"
                  agencyId={agencyId}
                  isDisabled={!!auditor.id}
                  setValue={setFieldValue}
                  showAddButton={!auditor.id}
                  required
                />
              </Col>
            </Row>
            <AllError errors={errors} />
            <hr />
            <SubmitButton isSubmitting={isSubmitting} />
          </Form>
        );
      }}
    </Formik>
  );
}

export default AuditorForm;
