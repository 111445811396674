import React from "react";
import { useTranslation } from "react-i18next";
import { FieldArray, Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import * as yup from "yup";
import { AllError, FormGroup } from "components/formik";
import { RemoveButton, SubmitButton } from "components/ui/buttons";
import * as agencyAPI from "api2/agencies";
import { toast } from "react-toastify";
import { formatDate } from "utils/date";
import AgencyExceptionTemplateForm from "./AgencyExceptionTemplateForm";

function AgencyScheduleTemplateForm({ agencyId, scheduleTemplate, onSuccess }) {
  const { t } = useTranslation(["common", "msg"]);
  const formName = `form-${scheduleTemplate.id || "create"}`;
  const formikProps = {
    initialValues: {
      ...scheduleTemplate,
      exceptions: [],
    },
    validationSchema: yup.object().shape({
      template_name: yup.string().required(),
      monday: yup.number().required().min(0).max(18),
      tuesday: yup.number().required().min(0).max(18),
      wednesday: yup.number().required().min(0).max(18),
      thursday: yup.number().required().min(0).max(18),
      friday: yup.number().required().min(0).max(18),
      saturday: yup.number().required().min(0).max(18),
      sunday: yup.number().required().min(0).max(18),
      exceptions: yup.array().of(
        yup.object().shape({
          exception_date: yup.date().required(),
          description: yup.string().required(),
          hours: yup.number().min(0).max(18).required(),
        })
      ),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await agencyAPI.scheduleTemplates
        .save(agencyId, {
          ...values,
          exceptions: values.exceptions.map((exception) => ({
            id: exception.id,
            exception_date: formatDate(exception.exception_date),
            hours: exception.hours,
            description: exception.description,
          })),
        })
        .then((response) => {
          if (onSuccess) {
            onSuccess();
          }
          if (!values.id) {
            resetForm();
          }
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  const handleRemove = () => {
    agencyAPI.scheduleTemplates
      .remove(agencyId, scheduleTemplate.id)
      .then((response) => {
        toast.success(t("msg:deleted"), { autoClose: 2000 });
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        if (error.data && error.data.__all__) {
          toast.error(error.data.__all__, { autoClose: 5000 });
        }
      });
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, setFieldValue, errors }) => {
        return (
          <Form key={`form-${scheduleTemplate.id || "create"}`}>
            <Row>
              <Col lg={4}>
                <FormGroup.Input label={t("common:name")} name="template_name" />
              </Col>
            </Row>
            <Row>
              <Col sm={4} md={2} lg={1}>
                <FormGroup.MoneyInput label={t("options:mon")} name="monday" />
              </Col>
              <Col sm={4} md={2} lg={1}>
                <FormGroup.MoneyInput label={t("options:tue")} name="tuesday" />
              </Col>
              <Col sm={4} md={2} lg={1}>
                <FormGroup.MoneyInput label={t("options:wed")} name="wednesday" />
              </Col>
              <Col sm={4} md={2} lg={1}>
                <FormGroup.MoneyInput label={t("options:thu")} name="thursday" />
              </Col>
              <Col sm={4} md={2} lg={1}>
                <FormGroup.MoneyInput label={t("options:fri")} name="friday" />
              </Col>
              <Col sm={4} md={2} lg={1}>
                <FormGroup.MoneyInput label={t("options:sat")} name="saturday" />
              </Col>
              <Col sm={4} md={2} lg={1}>
                <FormGroup.MoneyInput label={t("options:sun")} name="sunday" />
              </Col>
            </Row>
            <h4>{t("common:exceptions")}</h4>
            <Row>
              <FormGroup.Checkbox
                formName={formName}
                key={`holiday-active-${scheduleTemplate.id || "create"}`}
                label={t("others:isHolidaysActive")}
                name="is_holidays_active"
              />
            </Row>
            <FieldArray
              name="exceptions"
              render={(arrayHelpers) => <AgencyExceptionTemplateForm agencyId={agencyId} arrayHelpers={arrayHelpers} />}
            />
            <AllError errors={errors} />
            <hr />
            <SubmitButton isSubmitting={isSubmitting} />
            {scheduleTemplate.id && scheduleTemplate.template_name !== "100%" && (
              <RemoveButton
                className="float-right"
                onClick={handleRemove}
                confirmMessage={t("others:confirm.removeScheduleTemplate", {
                  name: scheduleTemplate.template_name,
                })}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default AgencyScheduleTemplateForm;
