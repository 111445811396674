import React, { useCallback, useMemo } from "react";

import * as agencyAPI from "api2/agencies";
import useAsync from "hooks/useAsync";

const AgencySettingStateContext = React.createContext(undefined);

function AgencySettingProvider({ agencyId, children }) {
  const dataSource = useCallback((cancelToken) => agencyAPI.select(agencyId, { cancelToken }), [agencyId]);
  const [{ data: item, loading }, { updateData }] = useAsync(dataSource, {});

  const stateValue = useMemo(
    () => ({
      info: item,
      loading,
      updateInfo: updateData,
    }),
    [item, loading, updateData]
  );

  return <AgencySettingStateContext.Provider value={stateValue}>{children}</AgencySettingStateContext.Provider>;
}

export { AgencySettingStateContext };

export default AgencySettingProvider;
