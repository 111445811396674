import React from "react";
import { Alert, Card } from "react-bootstrap";
import * as siAPI from "api2/supplier-invoices";
import { Link, useOutletContext } from "react-router-dom";
import { Trans } from "react-i18next";
import BaseUpload from "./BaseUpload";

function UploadSupplierInvoicePage() {
  const company = useOutletContext();
  const onFileUpload = (files) => {
    return siAPI.createDraftsFromFiles(company.id, files);
  };

  return (
    <Card className="upload-page">
      <Card.Body>
        <Alert variant="info">
          <Trans i18nKey="others:uploadSiInfo">
            <span>For each uploaded file there will be a Supplier invoice in draft.</span>
            <Link to="/reports/supplier-invoices">Link</Link>
          </Trans>
        </Alert>
        <BaseUpload onFileUpload={onFileUpload} />
      </Card.Body>
    </Card>
  );
}

export default UploadSupplierInvoicePage;
