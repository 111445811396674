import React, { useContext } from "react";
import { Modal } from "react-bootstrap";

import InitialStage from "./stages/InitialStage";
import "./ISOBasketModal.scss";
import { useCompanyState } from "../../../hooks/useCompany";
import "components/modals/SigningBasketModal/SigningBasketModal.scss";
import ISOSentStage from "./stages/ISOSentStage";
import IsoBasketProvider, { IsoBasketContext, STAGE_ISO_SENT } from "./IsoBasketProvider";

function ModalContentSwitcher({ initialSelection, handleClose }) {
  let content;
  const { stage } = useContext(IsoBasketContext);

  if (stage === STAGE_ISO_SENT) {
    content = <ISOSentStage handleClose={handleClose} />;
  } else {
    content = <InitialStage initialSelection={initialSelection} />;
  }

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={handleClose}
      backdrop="static"
      dialogClassName="signing-basket-modal iso-basket-modal"
    >
      <button className="close-btn" type="button" onClick={handleClose}>
        <i className="fa fa-times" />
      </button>
      {content}
    </Modal>
  );
}

function ModalWrapper({ verifications, handleClose }) {
  const {
    company: { id: companyId },
  } = useCompanyState();
  return (
    <IsoBasketProvider companyId={companyId}>
      <ModalContentSwitcher initialSelection={verifications} handleClose={handleClose} />
    </IsoBasketProvider>
  );
}

export default ModalWrapper;
