import { Col, Row } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";

function RelatedInfo({ related, onSelect, current, sie_file_name }) {
  const { t } = useTranslation("ver");
  return current.id ? (
    <Row>
      <Col>
        <div className="related-verifications">
          <p className="m-0 p-0">{t("relatedVerifications")}:</p>
          {related &&
            related.map((item) => (
              <button key={`re.${item.id}`} type="button" className="btn btn-secondary" onClick={() => onSelect(item)}>
                {item.series}
                {item.series_number}
              </button>
            ))}
          <button type="button" className="btn btn-secondary active" onClick={() => {}}>
            {current.series}
            {current.series_number}
          </button>
        </div>
      </Col>
      {sie_file_name && (
        <Col sm={6} xl={3}>
          <p className="m-0 p-0">Imported from:</p>
          <h6>{sie_file_name}</h6>
        </Col>
      )}
    </Row>
  ) : null;
}

export default RelatedInfo;
