import React, { useContext } from "react";

import useInitialAsync from "hooks/useInitialAsync";
import { FinancialYearStateContext } from "state/providers/FinancialYearProvider";
import * as statsAPI from "api2/stats";
import * as vatAPI from "api2/vat";
import * as reportAPI from "api2/reports";
import { RocketLoader } from "components/ui/loaders";
import { Navigate, useOutletContext } from "react-router-dom";
import { PermCodeRequired } from "components/perms";
import { codesForReports } from "components/perms/PermCodes";
import HomeContent from "./HomeContent";

function getDashboardData(companyId, financialYearId, cancelToken) {
  const promises = [
    statsAPI.company.yearToDateDetailed(companyId, financialYearId, { cancelToken }),
    statsAPI.company.materials(companyId, { cancelToken }),
    vatAPI.events.upcoming(companyId, financialYearId, { cancelToken }),
    reportAPI.employeeSettlementAccounts(companyId, financialYearId, { cancelToken }),
    statsAPI.company.authorities(companyId, financialYearId, { cancelToken }),
  ];
  let output = {};
  return Promise.all(promises).then((results) => {
    output = { ...results[0].data, ...results[1].data, events: results[2].data, settlementAccounts: results[3].data };
    output.authorities = results[4].data;
    return output;
  });
}

function HomePage({ company }) {
  const { financialYear } = useContext(FinancialYearStateContext);
  const financialYearId = financialYear ? financialYear.id : null;
  const { loading, item: data } = useInitialAsync(
    ({ cancelToken }) => getDashboardData(company.id, financialYearId, cancelToken),
    {},
    [financialYearId]
  );

  if (loading) {
    return <RocketLoader />;
  }
  return (
    <div style={{ paddingTop: 24 }}>
      <HomeContent data={data} financialYear={financialYear} />
    </div>
  );
}

function HomePageWrapper() {
  const company = useOutletContext();
  if (company.ttOnly) {
    return <Navigate to="archive/company-archive" />;
  }
  return (
    <PermCodeRequired code={codesForReports.enabled}>
      <HomePage company={company} />
    </PermCodeRequired>
  );
}

export default HomePageWrapper;
