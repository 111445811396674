import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import cx from "classnames";
import { ALLOWED_FILE_SIZE, ALLOWED_FILE_TYPES } from "../../../components/ui/documents/DropZoneInput";

function UploadButton({ fileSize, fileTypes, setFile, fileAttached }) {
  const fileRef = useRef(null);
  const { t } = useTranslation("common");

  function validate(selectedFile) {
    if (!fileTypes.includes(selectedFile.type)) {
      // allowed extension...
      toast.error(t("common:file.notSupported", { formats: fileTypes }), {
        autoClose: 4000,
      });
      return false;
    }
    if (selectedFile.size > fileSize) {
      toast.error(`The file size is too big. Max ${(fileSize / (1024 * 1024)).toFixed(1)} MB allowed`, {
        autoClose: 4000,
      });
      return false;
    }
    return true;
  }

  function onFileSelected(event) {
    event.preventDefault();
    const selectedFile = (event.target.files || event.dataTransfer.files)[0];
    if (selectedFile) {
      const isValid = validate(selectedFile);
      if (isValid) {
        // handle
        setFile(selectedFile);
      }
    }
  }

  return (
    <div
      className={cx("upload-btn", {
        disabled: !!fileAttached,
      })}
    >
      <i className="fe-upload" onClick={() => fileRef.current.click()} />
      <input ref={fileRef} type="file" onChange={onFileSelected} />
    </div>
  );
}

UploadButton.defaultProps = {
  fileTypes: ALLOWED_FILE_TYPES,
  fileSize: ALLOWED_FILE_SIZE,
};

export default UploadButton;
