import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, useFormikContext } from "formik";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import { toast } from "react-toastify";
import * as supportApi from "api2/office-support";
import * as yup from "yup";

function OfficeSupportRolesAdditionalForm({ companyId, changeEditedForm, initialData }) {
  const { t } = useTranslation("company");
  const [showMore, setShowMore] = useState(true);

  const formikProps = {
    initialValues: {
      ...initialData,
      other_employees: initialData.other_employees || "",
      others_involved: initialData.others_involved || "",
    },
    validationSchema: yup.object().shape({
      other_employees: yup.string().max(400),
      others_involved: yup.string().max(400),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await supportApi.roles.additional
        .update(companyId, {
          ...values,
        })
        .then((response) => {
          resetForm({ values });
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          if (error.data.__all__) {
            setErrors({ __all__: error.data.__all__ });
          } else {
            setErrors({ ...error.data });
          }
        });
    },
  };

  function FormUpdateChecker() {
    const { dirty } = useFormikContext();
    React.useEffect(() => {
      changeEditedForm({ id: "roles-additional-form", dirty });
    }, [dirty]);
    return null;
  }

  return (
    <Formik {...formikProps}>
      {({ values, touched, isSubmitting, setFieldValue, errors, resetForm, dirty }) => {
        return (
          <Form>
            <FormUpdateChecker />
            <Card>
              <Card.Header onClick={() => setShowMore((prevValue) => !prevValue)}>
                <Card.Title style={{ color: "#ADADAD", fontWeight: 400, fontSize: "12px" }}>
                  {t("header.businessAdditional")}
                  <i
                    style={{ textAlign: "right", float: "right" }}
                    className={showMore ? "fas fa-chevron-up fa-1x" : "fas fa-chevron-down fa-1x"}
                  />
                </Card.Title>
              </Card.Header>
              {showMore && (
                <Card.Body className="pt-0">
                  <Row>
                    <Col>
                      <FormGroup.TextAreaInput
                        label={t("title.otherEmployees")}
                        name="other_employees"
                        as="textarea"
                        rows={5}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup.TextAreaInput
                        label={t("title.othersInvolved")}
                        name="others_involved"
                        as="textarea"
                        rows={5}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-right">
                      {dirty && (
                        <Button variant="link" size="lg" onClick={() => resetForm()} className="cancel-btn">
                          {t("common:actions.cancel")}
                        </Button>
                      )}
                      <SubmitButton isSubmitting={isSubmitting} disabled={!dirty} />
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default OfficeSupportRolesAdditionalForm;
