import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import BTable from "components/tables/btable";
import { CompanyDocumentsLink } from "components/ui/buttons/DocumentsButton";
import { truncateText } from "utils/text";
import { formatMonth } from "utils/date";
import * as documentsAPI from "api2/documents";
import { confirmExecute } from "components/modals/ConfirmModal";
import { categoryByValue } from "utils/calc/companyArchiveCategories";
import cx from "classnames";
import Actions from "./CompanyArchiveActions";
import Filters from "./CompanyArchiveFilters";
import confirmShareCustomer from "./ConfirmShareCustomer";
import { useCompanyState } from "../../../hooks/useCompany";

function ShareColumn({ companyId, values, isConsult, isAuditor }) {
  const { t } = useTranslation("company");
  const [forAuditor, setForAuditor] = useState(values.for_auditor || false);
  const [forCustomer, setForCustomer] = useState(values.for_customer || false);
  const auditorShareDisabled = categoryByValue(values.category).type !== "Other";
  const shareWithAuditor = useCallback(async () => {
    let confirmed = true;
    if (forAuditor === false) {
      confirmed = await confirmExecute(t("confirm.shareAuditor", { fileName: values.title }));
    }
    if (confirmed) {
      documentsAPI.companyDocuments.toggleShareAuditor(companyId, values.id).then(() => {
        setForAuditor(!forAuditor);
      });
    }
  }, [t, values.title, forAuditor, companyId, values.id]);
  const shareWithCustomer = useCallback(async () => {
    let confirmed = true;
    let notifyCustomer = false;
    if (forCustomer === false) {
      const res = await confirmShareCustomer(t("confirm.shareCustomer", { fileName: values.title }));
      confirmed = res.answer;
      notifyCustomer = res.notifyCustomer;
    }
    if (confirmed) {
      documentsAPI.companyDocuments.toggleShareCustomer(companyId, values.id, notifyCustomer).then(() => {
        setForCustomer(!forCustomer);
      });
    }
  }, [t, values.title, forCustomer, companyId, values.id]);

  return (
    <div>
      <Button
        variant="action-toggle"
        size="sm"
        active={forCustomer}
        onClick={shareWithCustomer}
        title={t("others:shareCustomer")}
        disabled={!isConsult || (isAuditor && !isConsult)}
      >
        <i className="fe-user" />
      </Button>
      <Button
        variant="action-toggle"
        className="ml-1"
        size="sm"
        active={forAuditor}
        onClick={shareWithAuditor}
        title={t("others:shareAuditor")}
        disabled={auditorShareDisabled || (isAuditor && !isConsult)}
      >
        <i className="fe-clipboard" />
      </Button>
    </div>
  );
}

function CompanyArchiveTable({ companyId, selectable = false, projection }) {
  const { t } = useTranslation("common");
  const { perms } = useCompanyState();
  const getRowProps = (row) => {
    return {
      className: cx({ archived: !!row.is_archived }),
    };
  };
  const [isConsult, isEmployee] = useMemo(() => {
    if (projection === "cus") {
      return [false, true];
    }
    if (projection === "aud") {
      return [false, false];
    }
    return [perms.isAdmin || perms.isAgencyConsult, perms.isEmployee];
  }, [perms.isAdmin, perms.isAgencyConsult, perms.isEmployee, projection]);

  const headers = useMemo(() => {
    return [
      {
        field: "share",
        width: 90,
        label: t("common:actions.share"),
        className: "p-0 text-center",
        canSort: false,
        render: (values) => (
          <ShareColumn
            companyId={companyId}
            values={values}
            isConsult={isConsult}
            isEmployee={isEmployee}
            isAuditor={perms.isAuditor}
          />
        ),
      },
      {
        field: "title",
        label: t("common:name"),
        canSort: false,
        render: (values) => (
          <>
            <CompanyDocumentsLink companyId={companyId} documentsIds={[values.id]} text={values.title || "-"} />
            <small className="ml-1 text-muted font-11" title={values.description}>
              {truncateText(values.description, 130)}
            </small>
          </>
        ),
      },
      {
        field: "category",
        label: t("common:category"),
        canSort: true,
        render: (values) => t(`options:cdc:${values.category}`),
      },
      {
        field: "month",
        sortField: "month",
        label: t("common:dates.month"),
        canSort: true,
        render: (values) => (values.month ? formatMonth(values.month) : ""),
      },
      {
        field: "financial_year_label",
        sortField: "financial_year",
        label: t("common:financialYear"),
        canSort: true,
      },
    ];
  }, [t, companyId, isConsult, isEmployee, perms.isAuditor]);
  return (
    <BTable
      headers={headers}
      selectable={selectable}
      FiltersComponent={<Filters />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      getRowProps={getRowProps}
    />
  );
}

export default CompanyArchiveTable;
