import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useTable from "components/tables/btable/useTable";
import { SearchFilter } from "components/filters";

function CostCenterFilters() {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");

  return (
    <section className="table-filters-left">
      <SearchFilter onFilter={setFilters} defaultValue={filters.term} />
      <ButtonGroup aria-label="Status filter" className="status-filter">
        <Button
          variant="toggle"
          active={!filters.mode || filters.mode === "all"}
          onClick={() => setFilters({ mode: "" })}
        >
          {t("common:statuses.all")}
        </Button>
        <Button
          variant="toggle-green"
          active={filters.mode === "active"}
          onClick={() => setFilters({ mode: "active" })}
        >
          {t("common:statuses.active")}
        </Button>
        <Button
          variant="toggle-orange"
          active={filters.mode === "inactive"}
          onClick={() => setFilters({ mode: "inactive" })}
        >
          {t("common:statuses.inactive")}
        </Button>
      </ButtonGroup>
    </section>
  );
}

export default CostCenterFilters;
