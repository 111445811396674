import React from "react";
import { withTranslation } from "react-i18next";

function Loader({ t, withText = true }) {
  return (
    <div className="loader">
      <div>
        <i className="fas fa-2x fa-spin fa-spinner" />
      </div>
      {withText && <p className="text-muted">{t("loading")}...</p>}
    </div>
  );
}

export default withTranslation()(Loader);
