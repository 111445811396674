import React from "react";

import useTable from "components/tables/btable/useTable";
import OfficeForm from "./OfficeForm";

function OfficeEditWidget({ agencyId, office }) {
  const {
    dataActions: { reload },
  } = useTable();

  return <OfficeForm agencyId={agencyId} office={office} onSuccess={reload} onDelete={reload} />;
}

export default OfficeEditWidget;
