import client from "./client";

// TODO: move to api2/integrations.skatteverket
export const skatteverket = {
  checkAuth: () => {
    return client.get(`/skatteverket/check_user_skv_authorization/`);
  },
  doAuth: () => {
    return client.get(`/skatteverket/skv_acg_auth/`);
  },
  fetchCompanyEvents: (companyId) => {
    return client.post(`/companies/${companyId}/fetch_company_customer_events/`);
  },
  fetchAllEvents: (companyId) => {
    return client.post(`/skatteverket/fetch_all_companies_customer_events/`);
  },
};
