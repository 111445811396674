import React from "react";
import { useNavigate, useOutletContext, useParams, useResolvedPath } from "react-router-dom";

import useInitialAsync from "hooks/useInitialAsync";
import * as siAPI from "api2/supplier-invoices";
import { RocketLoader } from "components/ui/loaders";
import SupplierInvoiceFormWithActions from "components/forms/SupplierInvoiceForm";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { isAssetToAutoAddForSupplierInvoice } from "utils/assets";
import { HasPermCode } from "components/perms";
import { codesForInventoryRegister } from "components/perms/PermCodes";

function SupplierInvoiceEditPage() {
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const company = useOutletContext();
  const navigate = useNavigate();
  const currentPath = useResolvedPath("").pathname;
  const { id: verificationId } = useParams();
  const { loading, item } = useInitialAsync(
    ({ cancelToken }) => {
      return siAPI.details(company.id, verificationId, { cancelToken });
    },
    {},
    [verificationId]
  );
  const hasPermForInventoryRead = HasPermCode(codesForInventoryRegister.enabled);

  const onUpdate = async (invoice) => {
    reloadMenuNumbers();
    if (hasPermForInventoryRead && invoice.status === 1 && isAssetToAutoAddForSupplierInvoice(invoice)) {
      window.location.href = `${currentPath.includes("consult") ? "/consult" : ""}/inventory-register?draftsForVer=nr.${
        invoice.invoice_no
      }`;
    } else {
      const { next } = await siAPI.getNextDraft(company.id, invoice.id);
      if (next) {
        const nextLoc = currentPath.replace(item.id, next.id);
        navigate(nextLoc, {});
      } else {
        navigate(-1);
      }
    }
  };

  const onDelete = (invoice) => {
    reloadMenuNumbers();
    if (invoice.next) {
      const nextLoc = currentPath.replace(item.id, invoice.next.id);
      navigate(nextLoc, { replace: true });
    } else {
      navigate(-1);
    }
  };

  if (loading) {
    return <RocketLoader />;
  }

  return <SupplierInvoiceFormWithActions invoice={item} company={company} onSave={onUpdate} postDeleteFn={onDelete} />;
}

export default SupplierInvoiceEditPage;
