import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import LeftSidebar from "components/ui/sidebar";
import { useTranslation } from "react-i18next";
import { useAuthState } from "hooks/useAuth";
import { StaffPermRequired } from "components/perms";
import { codesForAgency } from "components/perms/PermCodes";
import TimeTrackerMenuItem from "./TimeTrackerMenuItem";
import LayoutSwitcherMenuItem from "./LayoutSwitcherMenuItem";

function CompanyListMenu() {
  const { t } = useTranslation("navigation");
  const { user } = useAuthState();
  const loc = useLocation().pathname;
  const agencyId = null;
  const canCompanyOnboarding =
    user.agencies.some((agency) => agency.perm_codes.includes(codesForAgency.companyOnboarding)) || user.is_superuser;
  const canSeeAgencies =
    user.agencies.some((agency) => agency.perm_codes.includes(codesForAgency.enabled)) || user.is_superuser;

  return (
    <LeftSidebar>
      <LayoutSwitcherMenuItem />
      <StaffPermRequired agencyConsult>
        <TimeTrackerMenuItem agencyId={agencyId} />
      </StaffPermRequired>
      <li>
        <NavLink to="/companies">
          <i className="fe-briefcase" />
          <span> {t("companies")}</span>
        </NavLink>
      </li>
      <StaffPermRequired agencyConsult>
        <li>
          <NavLink to="/eu-vat">
            <i className="fe-file-text" />
            <span> {t("euVAT")}</span>
          </NavLink>
        </li>
      </StaffPermRequired>
      <StaffPermRequired agencyConsult>
        {canCompanyOnboarding && (
          <li>
            <NavLink to="/onboarding">
              <i className="fe-clipboard" />
              <span> {t("onboarding")}</span>
            </NavLink>
          </li>
        )}
        <li>
          <NavLink to="/consult-draft-si">
            <i className="fe-archive" />
            <span> {t("allDraftSi")}</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/management/overview"
            className={({ isActive }) => {
              return isActive || loc.includes("/management/") ? "active" : undefined;
            }}
          >
            <i className="fe-check-square" />
            <span> Status List Old</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/manage/day-to-day"
            className={({ isActive }) => {
              return isActive || loc.includes("/manage/") ? "active" : undefined;
            }}
          >
            <i className="fe-edit" />
            <span> {t("managementDashboard")}</span>
          </NavLink>
        </li>
        {canSeeAgencies && (
          <li>
            <NavLink to="/agencies">
              <i className="fe-monitor" />
              <span> {t("agencyConfiguration")}</span>
            </NavLink>
          </li>
        )}
        <li>
          <NavLink to="/trash">
            <i className="fe-trash-2" />
            <span> {t("trashContainer")}</span>
          </NavLink>
        </li>
      </StaffPermRequired>
      <StaffPermRequired>
        <li>
          <NavLink to="/admin/users">
            <i className="fe-shield" />
            <span> {t("admin")}</span>
          </NavLink>
        </li>
      </StaffPermRequired>
    </LeftSidebar>
  );
}

export default CompanyListMenu;
