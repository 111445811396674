import React, { useMemo } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "react-bootstrap";
import { debounce } from "lodash";
import * as selectAPI from "api2/selects";
import * as supplierAPI from "api2/suppliers";
import useModal from "hooks/useModal";
import { GlobalSupplierAddModal } from "components/forms/GlobalSupplierForm";
import { SupplierAddModal } from "components/forms/SupplierForm";
import { toast } from "react-toastify";
import { codesForSupplierInvoices } from "components/perms/PermCodes";
import { HasPermCode, PermCodeRequired } from "components/perms";
import FormGroup from "./FormGroup";
import { confirmExecute } from "../modals/ConfirmModal";

const getCompanySuppliers = debounce((companyId, params, callback) => {
  selectAPI.companySuppliers(companyId, params).then((data) => {
    return callback(data);
  });
}, 500);

function SupplierPicker({ supplier, name, setFieldValue, label, companyId, onChange }) {
  const { t } = useTranslation("si");
  const globalSupplierModal = useModal();
  const connectSupplierModal = useModal();

  const canOpenGlobalSupplierModal = HasPermCode(codesForSupplierInvoices.manage);

  const formatSupplierGroupLabel = (data) => {
    return t(`common:supp${data.label}`);
  };

  async function updateLocalSupplierModal() {
    const response = await supplierAPI.locals.details(companyId, supplier.id);
    if (response.status === 200) {
      connectSupplierModal.open({
        ...response.data,
        value: response.data.supplier,
        label: `${response.data.supplier_name} | ${response.data.payment_method_str}`,
      });
    }
  }

  const supplierChanged = async (newSupplier) => {
    if (newSupplier.group === "global" && !newSupplier.deactivated_local_id) {
      connectSupplierModal.open({ ...newSupplier, id: null });
      setFieldValue(name, "", false);
    } else {
      if (newSupplier.group === "global" && newSupplier.deactivated_local_id) {
        const answer = await confirmExecute(t("confirm.activeLocalSupplier"));
        if (answer) {
          await supplierAPI.locals
            .toggleActive(companyId, newSupplier.deactivated_local_id)
            .then(() => {
              toast.success(t("msg:activated"));
            })
            .catch(() => toast.error(t("msg:canNotExecuteAction")));
        } else {
          return false;
        }
      }
      setFieldValue(name, newSupplier);
      onChange(newSupplier);
    }
    return newSupplier;
  };

  const onConnectModalClose = async (connectedSupplier) => {
    connectSupplierModal.close();
    if (connectedSupplier && connectedSupplier.id) {
      // const updatedSupplier = await supplierAPI.locals.details(companyId, connectedSupplier.id).then((r) => r.data);
      supplierChanged(connectedSupplier);
    }
  };

  const formatSupplierOptionLabel = (props) => (
    <>
      <span className={props.group === "local" ? "badge bg-secondary" : "badge bg-dark"}>
        {props.group ? props.group.toUpperCase() : ""}
      </span>
      <span style={{ paddingLeft: "10px" }}> {props.label}</span>
    </>
  );

  const customNoOptionsMessage = useMemo(
    () => (
      <div className="no-options-message-container">
        {t("common:noResultsFound")}
        <Button
          type="button"
          variant="toggle"
          size="sm"
          className="no-options-button"
          onClick={globalSupplierModal.open}
        >
          {t("si:actions.addGlobalSupplier")}
        </Button>
      </div>
    ),
    [globalSupplierModal.open, t]
  );

  return (
    <>
      {supplier && (
        <div className="supplier-picker-details form-group">
          <div className="details">
            <div>
              {t("common:supplier")}: <strong>{supplier.supplier_name}</strong>
            </div>
            <div>
              {t("common:paymentMethod")}: <strong>{supplier.payment_method_str}</strong>
            </div>
            <div>
              {t("common:costAccount")}: <strong>{supplier.contra_account}</strong>
            </div>
            <button
              type="button"
              className="btn btn-sm btn-outline-dark"
              onClick={() => {
                setFieldValue(name, "");
              }}
            >
              {t("common:actions.remove")}
            </button>
            <button type="button" className="btn btn-sm btn-outline-dark ml-2" onClick={updateLocalSupplierModal}>
              {t("common:actions.change")}
            </button>
          </div>
        </div>
      )}
      <div className={cx("mb-2", { "d-none": !!supplier })}>
        <FormGroup.AsyncSelect
          name={name}
          label={label}
          cacheOptions={false}
          onChange={supplierChanged}
          preventSetValue
          formatGroupLabel={formatSupplierGroupLabel}
          loadOptions={(params, callback) => getCompanySuppliers(companyId, params, callback)}
          minSearchLength={0}
          required
          customNoOptionsMessage={canOpenGlobalSupplierModal && customNoOptionsMessage}
          formatOptionLabel={formatSupplierOptionLabel}
        />
        <PermCodeRequired code={codesForSupplierInvoices.manage}>
          <ButtonGroup>
            <Button type="button" variant="toggle" size="sm" onClick={() => connectSupplierModal.open(null)}>
              {t("actions.connectSupplier")}
            </Button>
            <Button type="button" variant="toggle" size="sm" onClick={globalSupplierModal.open}>
              {t("si:actions.addGlobalSupplier")}
            </Button>
          </ButtonGroup>
        </PermCodeRequired>
      </div>
      {globalSupplierModal.show && <GlobalSupplierAddModal handleClose={globalSupplierModal.close} />}
      {connectSupplierModal.show && (
        <SupplierAddModal
          companyId={companyId}
          initialSupplier={connectSupplierModal.data}
          handleClose={onConnectModalClose}
        />
      )}
    </>
  );
}

export default SupplierPicker;
