import React from "react";
import { Link, useResolvedPath } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { SearchFilter } from "components/filters";
import useTable from "components/tables/btable/useTable";

function ContractTemplateFilters({ companyId }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");
  const path = useResolvedPath("").pathname;
  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
      </section>
      <section className="table-filters-right">
        <Link to={`${path}/form`} className="btn btn-blue">
          <i className="fas fa-plus" /> {t("common:actions.create")}
        </Link>
      </section>
    </>
  );
}

export default ContractTemplateFilters;
