import React from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import * as agencyAPI from "api2/agencies";
import { AgencyFreshdeskForm } from "components/forms/agency-settings-forms";
import { withInitialAsync } from "hooks/useAsync";

function AgencyAdminPage({ data: item }) {
  return (
    <Row>
      <Col xl={4} sm={6}>
        <AgencyFreshdeskForm agency={item} />
      </Col>
    </Row>
  );
}

const EnhancedAgencyAdminPage = withInitialAsync(
  AgencyAdminPage,
  () => {
    const { agencyId } = useParams();
    return React.useCallback((cancelToken) => agencyAPI.general.details(agencyId, { cancelToken }), [agencyId]);
  },
  {},
  true
);

export default EnhancedAgencyAdminPage;
