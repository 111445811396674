import React, { useEffect, useReducer } from "react";
import axios from "axios";
import _ from "lodash";

import { Loader, RocketLoader } from "components/ui/loaders";
import { ItemNotFoundErrorPage } from "pages/errors";

function dataFetchReducer(state, action) {
  switch (action.type) {
    case "FETCH_INIT":
      return { ...state, loading: true, error: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: true };
    case "FETCH_OK":
      return { ...state, loading: false, error: false, data: action.payload };
    case "UPDATE_DATA":
      return { ...state, data: action.payload };
    case "RELOAD":
      return { ...state, counter: state.counter + 1 };
    default:
      throw new Error();
  }
}

const useAsync = (dataSource, initialData) => {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    loading: true,
    error: false,
    counter: 0,
    data: initialData,
  });

  useEffect(() => {
    const signal = axios.CancelToken.source();

    async function fetchData() {
      dispatch({ type: "FETCH_INIT" });
      try {
        const result = await dataSource(signal.token);
        dispatch({ type: "FETCH_OK", payload: result.data || result });
      } catch (error) {
        if (!axios.isCancel(error)) {
          dispatch({ type: "FETCH_FAIL" });
        }
      }
    }

    fetchData();

    return () => {
      signal.cancel("aborted");
    };
  }, [dataSource, state.counter]);

  function reload() {
    dispatch({ type: "RELOAD" });
  }

  function updateData(newData) {
    dispatch({ type: "UPDATE_DATA", payload: newData });
  }

  return [state, { updateData, reload }];
};

const withInitialAsync = (
  WrappedComponent,
  dataSource,
  initialData,
  rocketLoader = true,
  allowEmptyAndUseInitialData = false
) => {
  function WithInitialAsync(props) {
    const [{ data, loading }, { reload }] = useAsync(dataSource(props), initialData);
    if (loading) {
      return rocketLoader ? <RocketLoader /> : <Loader />;
    }
    if (!loading && _.isEmpty(data) && !allowEmptyAndUseInitialData) {
      return <ItemNotFoundErrorPage />;
    }
    return <WrappedComponent data={data} reload={reload} initialLoading={loading} {...props} />;
  }

  WithInitialAsync.displayName = `WithInitialAsync(${WrappedComponent.displayName || WrappedComponent.name})`;
  return WithInitialAsync;
};

export { withInitialAsync };

export default useAsync;
