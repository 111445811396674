import React from "react";
import { codesFor, HasPermCode } from "components/perms";
import DirectPaymentListPage from "./DirectPaymentListPage";

function PayDirectPaymentListPage() {
  const canPay = HasPermCode(codesFor.directPayments.pay);
  const allowedModes = [{ value: "unpaid", label: "common:statuses.unpaid", variant: "toggle-red" }];
  return (
    <DirectPaymentListPage
      tableId="unpaidDirectPayments"
      allowedModes={allowedModes}
      initialMode={allowedModes[0].value}
      selectable={canPay}
    />
  );
}

export default PayDirectPaymentListPage;
