import React from "react";

import * as supportAPI from "api2/office-support";
import * as companyAPI from "api2/companies";
import { withInitialAsync } from "hooks/useAsync";
import OfficeSupportAccountingForm from "./OfficeSupportAccountingForm";

function OfficeSupportAccountingWidget({ data: item, companyId, changeEditedForm }) {
  return <OfficeSupportAccountingForm companyId={companyId} changeEditedForm={changeEditedForm} data={item} />;
}

const EnhancedOfficeSupportAccountingWidget = withInitialAsync(
  OfficeSupportAccountingWidget,
  ({ companyId }) =>
    React.useCallback(
      (cancelToken) => {
        const promises = [
          companyAPI.annualReports.details(companyId, { cancelToken }),
          supportAPI.accounting.details(companyId, { cancelToken }),
        ];
        return Promise.all(promises).then(([r1, r2]) => {
          return {
            data: { ...r2.data, annual_report: { data: r1.data.annual_report_data } },
          };
        });
      },
      [companyId]
    ),
  [],
  false,
  true
);

export default EnhancedOfficeSupportAccountingWidget;
