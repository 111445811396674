import React from "react";
import { FieldArray } from "formik";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TableGroup } from "components/formik";
import * as options from "api/options";
import _ from "lodash";

function TransactionRuleListForm({ companyId, companyAccounts }) {
  return (
    <FieldArray
      name="rules"
      render={(props) => <TransactionRuleForm companyId={companyId} companyAccounts={companyAccounts} {...props} />}
    />
  );
}

function TransactionRuleForm({ form, companyId, companyAccounts, ...props }) {
  const bankRuleCharges = options.bankRuleCharges.asList();
  const { values, errors } = form;
  const { push, remove } = props;
  const { t } = useTranslation("company");

  const addEmpty = () => {
    return push({
      key: _.uniqueId(),
      percent: 100,
      account_id: null,
      charge: bankRuleCharges[0],
    });
  };

  return (
    <Table bordered responsive size="sm" id="bank-rules-form">
      <thead>
        <tr>
          <th style={{ width: 100 }}>{t("bankRule.charge")}</th>
          <th style={{ width: 200 }}>{t("bankRule.percent")}</th>
          <th>{t("bankRule.accounts")}</th>
          <th style={{ width: 50 }} />
        </tr>
      </thead>
      <tbody>
        {values.rules.map((field, index) => (
          <React.Fragment key={field.key}>
            <tr>
              <TableGroup.SimpleSelect name={`rules[${index}].charge`} options={bankRuleCharges} />
              <TableGroup.MoneyInput name={`rules[${index}].percent`} />
              <TableGroup.SimpleSelect
                name={`rules[${index}].account_id`}
                options={companyAccounts}
                minSearchLength={0}
                filterOptionStartsWith
              />
              <td className="text-center">
                {values.rules.length > 1 && (
                  <Button size="sm" variant="secondary" onClick={() => remove(index)}>
                    <i className="fas fa-trash" />
                  </Button>
                )}
              </td>
            </tr>
            <TableGroup.RowErrors errors={errors.rules && errors.rules[index]} />
          </React.Fragment>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={3}>
            <Button type="button" variant="secondary" size="sm" onClick={addEmpty}>
              {t("common:actions.add")}
            </Button>
          </td>
        </tr>
      </tfoot>
    </Table>
  );
}

export default TransactionRuleListForm;
