import React from "react";

import { HistoryButton, VerificationDocumentsButton } from "components/ui/buttons";
import * as verAPI from "api2/verification";

function GlobalSearchActions({ row }) {
  const show = {
    documentsBtn: !!row.documents,
  };

  return (
    <>
      <HistoryButton apiResource={verAPI.historyGlobal} apiParams={[row.company, row.id]} />

      {show.documentsBtn && (
        <VerificationDocumentsButton companyId={row.company} verificationId={row.id} documentsIds={row.documents} />
      )}
    </>
  );
}

export default GlobalSearchActions;
