import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { confirmExecute } from "components/modals/ConfirmModal";

function ConfirmButton({ label, confirmMessage, onClick, variant = "red", icon, ...props }) {
  const { t } = useTranslation("common");
  if (!label) {
    label = t("actions.remove");
  }

  const confirm = async () => {
    const answer = await confirmExecute(confirmMessage);
    if (answer) {
      onClick();
    }
  };

  return (
    <Button type="button" variant={variant} {...props} onClick={confirm}>
      <span>
        {icon && <i className={`fas ${icon}`} />} {label}
      </span>
    </Button>
  );
}

export default ConfirmButton;
