import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as companyAPI from "api2/companies";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const OnboardingStateContext = React.createContext(undefined);
const OnboardingDispatchContext = React.createContext(undefined);

function OnboardingProvider({ companyId, initialStep, children }) {
  const [state, setState] = useState({
    companyName: "",
    loading: true,
    activeStep: undefined,
    prevSystem: undefined,
    steps: [],
  });
  const { t } = useTranslation("company");

  const fetchDetails = useCallback(
    (goNext = false, initialFromQueryParam = false, welcomeInteraction = false, forceRefresh = false) => {
      if (state.loading && !forceRefresh) return;

      companyAPI.onboardings
        .details(companyId)
        .then((response) => {
          const steps = response.data.progress || [];
          setState((_state) => {
            let activeStep;

            if (initialFromQueryParam && initialStep) {
              const initialStepIdx = steps.findIndex((step) => step.id === initialStep);
              if (initialStepIdx >= 0 && steps[initialStepIdx].status !== "locked") {
                activeStep = {
                  ...steps[initialStepIdx],
                  number: initialStepIdx + 1,
                };
              }
            }

            if (!activeStep) {
              if (!_state.activeStep) {
                const currentStepIdx = steps.findIndex((step) => step.id === response.data.current_step);
                activeStep = {
                  ...steps[currentStepIdx],
                  number: currentStepIdx + 1,
                };
              } else if (goNext) {
                const currentStepIdx = steps.findIndex((step) => step.id === _state.activeStep.id);

                let nextStepIdx = currentStepIdx + 1;
                while (nextStepIdx < steps.length && ["locked", "finished"].includes(steps[nextStepIdx].status)) {
                  nextStepIdx += 1;
                }

                if (
                  nextStepIdx < steps.length &&
                  ["not_started", "in_progress", "error"].includes(steps[nextStepIdx].status)
                ) {
                  activeStep = {
                    ...steps[nextStepIdx],
                    number: nextStepIdx + 1,
                  };
                } else {
                  const previousOpenStepIdx = steps
                    .slice(0, currentStepIdx)
                    .reverse()
                    .findIndex((step) => ["not_started", "in_progress", "error"].includes(step.status));

                  if (previousOpenStepIdx !== -1) {
                    const validStepIdx = currentStepIdx - previousOpenStepIdx - 1; // Adjust index because of reverse
                    activeStep = {
                      ...steps[validStepIdx],
                      number: validStepIdx + 1,
                    };
                  } else if (steps.every((step) => step.status === "finished")) {
                    if (currentStepIdx + 1 < steps.length) {
                      activeStep = {
                        ...steps[currentStepIdx + 1],
                        number: currentStepIdx + 2,
                      };
                    } else {
                      toast.warning(t("onboarding.stepLocked"));
                      return _state;
                    }
                  } else {
                    toast.warning(t("onboarding.stepLocked"));
                    return _state;
                  }
                }
              } else {
                activeStep = { ..._state.activeStep, ...steps.find((s) => s.id === _state.activeStep.id) };
              }
            }

            if (welcomeInteraction) {
              activeStep = { ..._state.activeStep, welcome: true };
            }

            return {
              companyName: response.data.company_name,
              loading: false,
              steps,
              activeStep,
              prevSystem: response.data.previous_system,
              sieImported: response.data.was_sie_file_imported,
              agencyId: response.data.agency_id,
            };
          });
        })
        .catch(() => {
          setState({ loading: false, activeStep: undefined, steps: [], prevSystem: undefined });
        });
    },
    [companyId, initialStep, t, state.loading]
  );

  useEffect(() => {
    fetchDetails(false, true, false, true);
  }, [fetchDetails]);

  const goTo = useCallback(
    (stepId) => {
      const stepIndex = state.steps.findIndex((s) => s.id === stepId);
      setState((s) => ({ ...s, activeStep: { ...s.steps[stepIndex], number: stepIndex + 1 } }));
      fetchDetails();
    },
    [state.steps, fetchDetails]
  );

  const goToNext = useCallback(() => {
    const nextIndex = state.steps.findIndex((s) => s.id === state.activeStep.id) + 1;
    if (nextIndex <= state.steps.length) {
      if (state.steps[nextIndex].status === "locked") {
        toast.warning(t("onboarding.stepLocked"));
        return;
      }
      setState((s) => ({ ...s, activeStep: { ...s.steps[nextIndex], number: nextIndex + 1 } }));
    }
  }, [t, state.activeStep, state.steps]);

  const goToPrev = useCallback(() => {
    const prevIndex = state.steps.findIndex((s) => s.id === state.activeStep.id) - 1;
    if (prevIndex >= 0) {
      if (state.steps[prevIndex].status === "locked") {
        toast.warning(t("onboarding.stepLocked"));
        return;
      }
      setState((s) => ({ ...s, activeStep: { ...s.steps[prevIndex], number: prevIndex + 1 } }));
    }
  }, [t, state.activeStep, state.steps]);

  const stateValue = useMemo(
    () => ({
      loading: state.loading,
      companyId,
      companyName: state.companyName,
      prevSystem: state.prevSystem,
      activeStep: state.activeStep,
      steps: state.steps,
      sieImported: state.sieImported,
      agencyId: state.agencyId,
    }),
    [
      companyId,
      state.loading,
      state.activeStep,
      state.steps,
      state.prevSystem,
      state.sieImported,
      state.companyName,
      state.agencyId,
    ]
  );
  const dispatchValue = useMemo(
    () => ({
      goToNext,
      goToPrev,
      goTo,
      fetchDetails,
    }),
    [goTo, goToNext, goToPrev, fetchDetails]
  );

  return (
    <OnboardingStateContext.Provider value={stateValue}>
      <OnboardingDispatchContext.Provider value={dispatchValue}>{children}</OnboardingDispatchContext.Provider>
    </OnboardingStateContext.Provider>
  );
}

export { OnboardingStateContext, OnboardingDispatchContext };

export default OnboardingProvider;
