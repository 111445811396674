import { formatDate, isBefore, parseDate } from "utils/date";
import client from "./client";

export function save(taskData) {
  if (taskData.id) {
    return client.put(`/time-tracker/tasks/${taskData.id}/`, taskData);
  }
  return client.post(`/time-tracker/tasks/`, taskData);
}

export function remove(taskId) {
  return client.delete(`/time-tracker/tasks/${taskId}/`);
}

export function details(taskId) {
  return client.get(`/time-tracker/tasks/${taskId}/`).then((response) => {
    response.data.deadline_date = parseDate(response.data.deadline_date);
    response.data.overdue = isBefore(response.data.deadline_date, new Date());
    return response;
  });
}

export function startTimer(taskId) {
  return client.patch(`/time-tracker/tasks/${taskId}/start/`);
}

export function stopTimer(taskId) {
  return client.patch(`/time-tracker/tasks/${taskId}/stop/`);
}

export function finish(taskId, data) {
  return client.patch(`/time-tracker/tasks/${taskId}/finish/`, data);
}

export function revertFinish(taskId) {
  return client.patch(`/time-tracker/tasks/${taskId}/not_finish/`);
}

export function getActiveTask() {
  return client.get(`/time-tracker/tasks/running_task/`).catch((error) => null);
}

export function updateTimeLog(logId, data) {
  return client.patch(`/time-tracker/time-logs/${logId}/`, data);
}

export function removeTimeLog(logId) {
  return client.delete(`/time-tracker/time-logs/${logId}/`);
}

export function createAbsenceTimeLogs(agencyId, absence) {
  return client.post("/time-tracker/time-logs/create_absence_time_logs/", {
    date_from: formatDate(absence.date_from),
    date_to: formatDate(absence.date_to),
    hours_per_day: parseFloat(absence.hours_per_day),
    comment: `${absence.comment}`,
    reason: absence.reason.value,
    agency: agencyId,
  });
}

export const workTime = {
  isPlaying: (agencyId) =>
    client.get(`/time-tracker/work-time/is_playing/`, {
      params: {
        agency: agencyId,
      },
    }),
  play: (agencyId) =>
    client.get("/time-tracker/work-time/play/", {
      params: {
        agency: agencyId,
      },
    }),
  stop: (agencyId) =>
    client.get("/time-tracker/work-time/stop/", {
      params: {
        agency: agencyId,
      },
    }),
};

export const timeGrid = {
  getLogs: (agencyId, dateFrom, dateTo) =>
    client.get(`/time-tracker/time-grid/`, {
      params: {
        agency: agencyId,
        date_from: formatDate(dateFrom),
        date_to: formatDate(dateTo),
      },
    }),
  getMonthlyLockDates: (agencyId, dateFrom, dateTo) =>
    client.get(`/time-tracker/lock-dates/${agencyId}/month/`, {
      params: {
        date_from: formatDate(dateFrom),
        date_to: formatDate(dateTo),
      },
    }),
  getDisputedAbsenceReports: (agencyId, config) =>
    client.get(`/time-tracker/lock-dates/${agencyId}/latest_disputed_absence_report/`, { ...config }),
  resendAbsenceReportApproval: (agencyId, reportId) =>
    client.post(`/time-tracker/lock-dates/${agencyId}/send_to_approval/`, {
      report: reportId,
    }),
  lockDate: (agencyId, day) =>
    client.post(`/time-tracker/lock-dates/${agencyId}/lock/`, {
      lock_date: formatDate(day),
    }),
  unlockDate: (agencyId, day) =>
    client.post(`/time-tracker/lock-dates/${agencyId}/unlock/`, {
      lock_date: formatDate(day),
    }),
};
