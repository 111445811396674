import React from "react";
import { Card, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useInitialAsync from "hooks/useInitialAsync";
import * as statsAPI from "api2/stats";
import { Loader } from "components/ui/loaders";
import { formatMoney } from "utils/money";
import { StatusBox } from "../helpers";

function StatsUsersPage() {
  const { t } = useTranslation("reports");
  const { item: data, loading } = useInitialAsync(({ cancelToken }) => statsAPI.admin.users({ cancelToken }), {});
  if (loading) {
    return <Loader />;
  }
  return (
    <Card>
      <Card.Body>
        <Row>
          <StatusBox label={t("stats.activeUsers")} value={formatMoney(data.numbers.active_users_count, 0, 0)} />
          <StatusBox label={t("stats.consults")} value={formatMoney(data.numbers.consults_count, 0, 0)} />
          <StatusBox label={t("stats.companyOwners")} value={formatMoney(data.numbers.bo_count, 0, 0)} />
          <StatusBox label={t("stats.employees")} value={formatMoney(data.numbers.employees_count, 0, 0)} />
          <StatusBox label={t("stats.approvers")} value={formatMoney(data.numbers.approvers_count, 0, 0)} />
          <StatusBox label={t("stats.auditors")} value={formatMoney(data.numbers.auditors_count, 0, 0)} />
        </Row>
      </Card.Body>
    </Card>
  );
}

export default StatsUsersPage;
