import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

import { BExpandedTable } from "components/tables/btable";
import { ProductEditWidget } from "components/forms/ProductForm";
import Filters from "./ProductFilters";

function ProductExtendedTable({ companyId }) {
  const { t } = useTranslation("ci");
  const headers = useMemo(
    () => [
      {
        field: "ArticleNumber",
        label: t("articleNumber"),
      },
      {
        field: "Description",
        label: t("common:name"),
      },
      {
        field: "ProductType",
        label: t("common:type"),
        render: (row) => t(`options:${row.ProductType}`),
      },
      {
        field: "UnitPrice",
        label: `${t("common:money.unitPrice")} (SEK)`,
        render: (row) => (
          <>
            {row.UnitPrice}/{row.Units}
          </>
        ),
      },
    ],
    [t]
  );

  const getRowProps = (row) => {
    return { className: row.IsActive === true ? "paid" : "inactive" };
  };

  return (
    <Row>
      <Col>
        <BExpandedTable
          headers={headers}
          idKey="ProductPublicId"
          getRowProps={getRowProps}
          FiltersComponent={<Filters companyId={companyId} />}
          renderExpandedRow={(row) => <ProductEditWidget companyId={companyId} product={row} />}
        />
      </Col>
    </Row>
  );
}

export default ProductExtendedTable;
