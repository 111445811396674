import React, { useEffect, useRef, useState } from "react";
import QRLib from "qrcode";
import * as authAPI from "api2/auth";

const qrCodeOptions = {
  width: 200,
  margin: 3,
  errorCorrectionLevel: "L",
};

function BankIdQrCode({ orderRef, initialQrCode }) {
  const [qrCode, setQrCode] = useState(initialQrCode);
  const [qrImage, setQrImage] = useState();
  const statusRef = useRef(false);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!statusRef.current) {
        statusRef.current = true;
        authAPI
          .bankIDRefreshQr(orderRef)
          .then((response) => {
            setQrCode(response.data.qr_code);
          })
          .catch(() => {})
          .finally(() => {
            statusRef.current = false;
          });
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [orderRef]);
  useEffect(() => {
    QRLib.toDataURL(qrCode, qrCodeOptions)
      .then((url) => {
        setQrImage(url);
      })
      .catch(() => {
        setQrImage(undefined);
      });
  }, [qrCode]);

  if (!qrImage) {
    return null;
  }
  return <img src={qrImage} alt="BankID QR Code" />;
}

export default BankIdQrCode;
