import React from "react";
import { Card } from "react-bootstrap";
import * as agencyAPI from "api2/agencies";
import TableProvider from "components/tables/btable/provider";
import { AgencyTable } from "components/tables";
import AgencyListLayout from "components/layouts/AgencyListLayout";

function AgencyListPage() {
  const initialFilters = {};

  return (
    <AgencyListLayout>
      <Card>
        <Card.Body>
          <TableProvider
            tableId="agencies"
            dataSource={agencyAPI.list}
            initialFilters={initialFilters}
            keepTerm={false}
          >
            <AgencyTable />
          </TableProvider>
        </Card.Body>
      </Card>
    </AgencyListLayout>
  );
}

export default AgencyListPage;
