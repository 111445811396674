import { ButtonGroup, ToggleButton } from "react-bootstrap";
import React from "react";

import "./ViewSwitcher.scss";
import { useTranslation } from "react-i18next";
import { useNavigate, useResolvedPath } from "react-router-dom";

function ViewSwitcher({ view }) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const path = useResolvedPath("").pathname;

  function gotTo(lastPart) {
    const pathParts = path.split("/");
    let link = "";
    for (let i = 0; i < pathParts.length - 1; i++) {
      link += `${pathParts[i]}/`;
    }
    link += lastPart;
    navigate(link);
  }

  return (
    <div className="view-switcher form-group">
      <label className="form-label">{t("common:view")}</label>
      <ButtonGroup toggle className="d-block">
        <ToggleButton
          type="checkbox"
          variant="switch"
          checked={view === "monthly"}
          onClick={(e) => path.match(/profit-and-loss\//) && gotTo("")}
        >
          {t("monthly")}
        </ToggleButton>
        <ToggleButton
          type="checkbox"
          variant="switch"
          checked={view === "yearly"}
          onChange={(e) =>
            path.match(/profit-and-loss\//)
              ? gotTo("year")
              : path.match(/profit-and-loss$/) && gotTo("profit-and-loss/year")
          }
        >
          12 {t("months")}
        </ToggleButton>
      </ButtonGroup>
    </div>
  );
}

export default ViewSwitcher;
