import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import * as yup from "yup";
import { AllError, FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import * as agencyAPI from "api2/agencies";
import { toast } from "react-toastify";

function AgencyFreshdeskForm({ agency }) {
  const { t } = useTranslation(["common", "msg"]);

  const formikProps = {
    initialValues: {
      ...agency,
    },
    validationSchema: yup.object(),
    onSubmit: async (values, { setErrors }) => {
      await agencyAPI.general
        .update(agency.id, {
          ...values,
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, setFieldValue, errors }) => {
        return (
          <Form>
            <Card>
              <Card.Header>
                <Card.Title>Freshdesk {t("company:integrations")}</Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                <Row>
                  <Col sm={12}>
                    <FormGroup.Input label="API KEY" name="freshdesk_api_key" />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormGroup.Input label="Domain" name="freshdesk_domain" />
                  </Col>
                </Row>
                <AllError errors={errors} />
              </Card.Body>
              <Card.Footer>
                <SubmitButton isSubmitting={isSubmitting} />
              </Card.Footer>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default AgencyFreshdeskForm;
