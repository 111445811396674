import React from "react";
import { Card } from "react-bootstrap";
import * as agencyAPI from "api2/agencies";
import TableProvider from "components/tables/btable/provider";
import { useParams } from "react-router-dom";
import OfficeExtendedTable from "components/tables/OfficeTable/OfficeTable";
import OfficeAddWidget from "components/forms/agency-settings-forms/OfficeAddWidget";

function OfficeListPage() {
  const { agencyId } = useParams();
  const initialFilters = {};

  return (
    <TableProvider
      tableId="offices"
      dataSource={(filters, config) => agencyAPI.offices.list(agencyId, filters, config)}
      initialFilters={initialFilters}
      keepTerm={false}
    >
      <Card>
        <Card.Body>
          <OfficeExtendedTable agencyId={agencyId} />
        </Card.Body>
      </Card>
      <OfficeAddWidget agencyId={agencyId} />
    </TableProvider>
  );
}

export default OfficeListPage;
