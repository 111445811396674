import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  MultiAgencyFilter,
  MultiCustomerManagerFilter,
  MultiCustomerNameFilter,
  MultiOfficeFilter,
  MultiOrgNoFilter,
  MultiSelectSimpleFilter,
} from "components/filters";

import * as options from "api/options";

function OfficeSupportExportFilters({ filters, setFilters }) {
  const { t } = useTranslation("company");
  const [isFilterLocked, setIsFilterLocked] = useState(false);
  const handleFilterChange = (selectedFilters) => {
    const orgNoIsSet = "org_no" in selectedFilters && !_.isEmpty(selectedFilters.org_no);
    const nameIsSet = "name" in selectedFilters && !_.isEmpty(selectedFilters.name);
    const isLocked = orgNoIsSet || nameIsSet;
    setIsFilterLocked(!!isLocked);
    setFilters((prevFilters) => ({ ...prevFilters, ...selectedFilters }));
  };
  return (
    <div className="table-filters-group">
      <section className="table-filters-left">
        <MultiOrgNoFilter
          onFilter={(selected) => {
            handleFilterChange(selected);
          }}
          label={t("common:orgNo")}
          defaultValue={filters.org_no}
          isDisabled={isFilterLocked && (_.isEmpty(filters.org_no) || filters.org_no.length === 0)}
          queryParams={{ agencies: filters.agencies, offices: filters.offices, consults: filters.consults }}
        />
        <MultiCustomerNameFilter
          isClearable
          label={t("customerName")}
          name="name"
          onFilter={(selected) => {
            handleFilterChange(selected);
          }}
          defaultValue={filters.name}
          isDisabled={isFilterLocked && (_.isEmpty(filters.name) || filters.name.length === 0)}
          queryParams={{ agencies: filters.agencies, offices: filters.offices, consults: filters.consults }}
        />
        <MultiAgencyFilter
          name="agencies"
          onFilter={(selected) => {
            handleFilterChange(selected);
          }}
          label={t("common:agencies")}
          defaultValue={filters.agencies}
          isDisabled={isFilterLocked}
        />
        <MultiOfficeFilter
          name="offices"
          onFilter={(selected) => {
            handleFilterChange(selected);
          }}
          label={t("common:office")}
          defaultValue={filters.offices}
          queryParams={{ belongsMyAgencies: true, agencies: filters.agencies }}
          isDisabled={isFilterLocked}
        />
        <MultiCustomerManagerFilter
          isClearable
          onFilter={(selected) => {
            handleFilterChange(selected);
          }}
          defaultValue={filters.consults}
          label={t("common:customerManager")}
          isDisabled={isFilterLocked}
          queryParams={{ agencies: filters.agencies, offices: filters.offices }}
        />
        <MultiSelectSimpleFilter
          isClearable
          label={t("company:companyType")}
          name="company_types"
          onFilter={(selected) => {
            handleFilterChange(selected);
          }}
          defaultValue={filters.company_types}
          options={options.companyTypes.asList()}
          isDisabled={isFilterLocked}
        />
        <MultiSelectSimpleFilter
          isClearable
          label={t("common:statuses.status")}
          name="statuses"
          onFilter={(selected) => {
            handleFilterChange(selected);
          }}
          defaultValue={filters.statuses}
          options={options.companyStatuses.asList()}
          isDisabled={isFilterLocked}
        />
        <div>
          <label className="d-block">&nbsp;</label>
          <Button
            variant="toggle"
            onClick={() => {
              setFilters({ ...filters, favourites: filters.favourites ? null : true });
            }}
            active={filters.favourites}
            disabled={isFilterLocked}
          >
            {t("favouritesOnly")}
          </Button>
        </div>
      </section>
    </div>
  );
}

export default OfficeSupportExportFilters;
