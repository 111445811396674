import { useTranslation } from "react-i18next";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { isWeekend } from "date-fns";

import * as trackerAPI from "api/time-tracker";
import { SubmitButton } from "components/ui/buttons";
import { FormGroup } from "components/formik";
import { formatDate, parseDate } from "utils/date";
import { confirmRemove } from "components/modals/ConfirmModal";
import { useModalOnPage } from "../hooks/useModalOnPage";

function TaskForm({ task, onSave, options }) {
  const { t } = useTranslation("tt");
  const isModal = useModalOnPage();

  const formikProps = {
    initialValues: {
      ...task,
      deadline_date: parseDate(task.deadline_date),
      company: task.company_id && options.companies.find((option) => option.value === task.company_id),
      assignee: task.assignee && options.consults.find((option) => option.value === task.assignee),
    },
    validationSchema: yup.object().shape({
      title: yup.string().required(),
      company: yup
        .object()
        .nullable()
        .when("category", ([category], schema) => {
          return category === "internal" ? schema : schema.required();
        }),
      deadline_date: yup.date().nullable().required(),
      assignee: yup.object().nullable().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      let company_id = task.category === "internal" ? null : values.company.value;
      if (values.company_id && !company_id) {
        company_id = values.company_id;
      }
      await trackerAPI
        .save({
          ...values,
          deadline_date: formatDate(values.deadline_date),
          company: company_id,
          assignee: values.assignee.value,
        })
        .then((response) => {
          onSave(response.data, !values.id);
        });
    },
  };

  const onRemove = async () => {
    if (!task.id) {
      return false;
    }
    const answer = await confirmRemove(t("confirm.removeTask", { title: task.title }));
    if (answer) {
      await trackerAPI.remove(task.id).then(() => {
        onSave(null, false);
        document.body.dispatchEvent(new Event("task/changed"));
      });
    }
    return true;
  };

  return (
    <Formik {...formikProps}>
      {({ errors }) => (
        <Form>
          <Modal.Body>
            <FormGroup.Input type="text" name="title" label={t("task")} required />
            {task.category !== "internal" && (
              <FormGroup.SimpleSelect
                name="company"
                label={t("common:company")}
                options={options.companies}
                menuPlacement={isModal ? "bottom" : "auto"}
                menuPosition={isModal ? "absolute" : "fixed"}
                required
              />
            )}
            <FormGroup.DatePicker
              name="deadline_date"
              popperClassName="popper-in-modal"
              label={t("deadline")}
              required
              filterDate={(date) => {
                return !isWeekend(date);
              }}
            />
            <FormGroup.SimpleSelect
              name="assignee"
              label={t("assignee")}
              menuPlacement={isModal ? "bottom" : "auto"}
              menuPosition={isModal ? "absolute" : "fixed"}
              options={options.consults}
              required
            />
            <FormGroup.Input as="textarea" name="comment" rows={3} label={t("common:comment")} />
          </Modal.Body>
          <Modal.Footer>
            {task.id && (
              <Button variant="red" onClick={onRemove}>
                {t("common:actions.remove")}
              </Button>
            )}
            <SubmitButton />
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
}

export default TaskForm;
