import React from "react";
import { Button, Card } from "react-bootstrap";

function Box({ title, info, onExecute, loading, t }) {
  return (
    <Card className="card-border">
      <Card.Header className="border-danger">
        <Card.Title>{title}</Card.Title>
      </Card.Header>
      <Card.Body className="pt-0" style={{ minHeight: 100 }}>
        {info}
      </Card.Body>
      <Card.Footer>
        <Button variant="red" onClick={onExecute} disabled={loading}>
          {loading ? `${t("common:actions.processing")}...` : t("common:actions.execute")}
        </Button>
      </Card.Footer>
    </Card>
  );
}

export default Box;
