import React, { useState, useContext, useCallback, useEffect } from "react";
import { Card } from "react-bootstrap";
import { SearchFilter } from "components/filters";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useAsync from "hooks/useAsync";

import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import { OnboardingDispatchContext } from "pages/companies/OnboardingWizard/provider/OnboardingProvider";
import CompanyConsultsPermsForm from "components/forms/CompanyConsultPermsForm";
import { handleActionErrors } from "api/errors";
import * as companyApi from "api2/companies";
import ConsultSettingsSaveButtons from "./ConsultSettingsSaveButtons";

function ConsultSettingsStep({ companyId, agencyId }) {
  const [filterParams, setFilterParams] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const { fetchDetails } = useContext(OnboardingDispatchContext);
  const { t } = useTranslation("msg");

  const dataSource = useCallback(
    (cancelToken) =>
      companyApi.onboardings.steps.consultantSettings(
        companyId,
        agencyId,
        { term: filterParams.term },
        { cancelToken }
      ),
    [filterParams.term, companyId, agencyId]
  );

  const [{ data, loading }, { updateData: setData }] = useAsync(dataSource, []);

  useEffect(() => {
    if (!loading) {
      fetchDetails();
    }
  }, [loading, fetchDetails]);

  const savePerms = useCallback(async () => {
    setSubmitting(true);

    try {
      await companyApi.onboardings.steps.consultantSettingsFinish(companyId, agencyId, data);
      await fetchDetails(true);
      toast.success(t("msg:updated"));
    } catch (error) {
      handleActionErrors(error);
    } finally {
      setSubmitting(false);
    }
  }, [companyId, agencyId, data, fetchDetails, t]);

  return (
    <Card className="consult-settings-step" data-testid="consult-settings-step">
      <Card.Body>
        <StepHeader rightComponent={<ConsultSettingsSaveButtons isSubmitting={isSubmitting} savePerms={savePerms} />} />
      </Card.Body>
      <Card.Body>
        <SearchFilter onFilter={setFilterParams} defaultValue={filterParams.term} />
        <br />
        <CompanyConsultsPermsForm data={data} setData={setData} loading={loading} />
      </Card.Body>
    </Card>
  );
}

export default ConsultSettingsStep;
