import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import { Alert, Button, Modal } from "react-bootstrap";
import { Form, Formik, useFormikContext } from "formik";
import { SubmitButton } from "components/ui/buttons";
import { Trans, useTranslation } from "react-i18next";
import * as statusAPI from "api2/status-list";
import { useCompanyState } from "hooks/useCompany";
import cx from "classnames";
import * as yup from "yup";
import { toast } from "react-toastify";
import { FormGroup } from "components/formik";
import { statusListPeriodicityOptions } from "api/options";
import { formatDate, parseDate } from "utils/date";

function ActivateFooter({ companyId, activityId, onClose }) {
  const { t } = useTranslation();
  const ref = useRef(0);
  const {
    values: {
      start_date,
      periodicity: { value: periodicityValue },
    },
    setErrors,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();
  const check = useCallback(() => {
    statusAPI.activities
      .activateActivityCheck(companyId, activityId, start_date, periodicityValue)
      .then((response) => {
        setFieldValue("deadline", parseDate(response.data.deadline), false);
        ref.current = response.data.events_count;
        setErrors({});
      })
      .catch((error) => {
        setErrors(error.data);
      });
  }, [companyId, activityId, start_date, periodicityValue, setFieldValue, setErrors]);

  useEffect(() => {
    check();
  }, [check]);

  return (
    <>
      {ref.current ? (
        <Modal.Body>
          <Alert variant="warning">
            <div>
              <i className="fe-alert-triangle" />
            </div>
            <div>
              <Trans i18nKey="officeSupport:warningTaskActivation" count={ref.current}>
                You are about to reactivate the task.{" "}
                <strong>
                  Note: All events ({{ count: ref.current }}) after given activation start month will be removed.
                </strong>
              </Trans>
            </div>
          </Alert>
        </Modal.Body>
      ) : null}
      <Modal.Footer className={cx({ "has-warning": ref.current > 0 })}>
        <div>
          <Button variant="link" onClick={onClose}>
            {t("common:actions.cancel")}
          </Button>
          {ref.current ? (
            <SubmitButton isSubmitting={isSubmitting} title="officeSupport:removeAndActivate" />
          ) : (
            <SubmitButton isSubmitting={isSubmitting} title="officeSupport:activateTask" />
          )}
        </div>
      </Modal.Footer>
    </>
  );
}

function ActivateModal({ activity, handleClose, index, consults }) {
  const {
    company: { id: companyId },
  } = useCompanyState();
  const { t } = useTranslation("officeSupport");
  const { values: tableValues, resetForm: tableResetForm } = useFormikContext();
  const periodicityOptions = statusListPeriodicityOptions.asList();
  const allowedPeriodicityOptions = useMemo(() => {
    const allowedValues = activity.allowed_periodicity.map((item) => item.periodicity);
    return periodicityOptions.filter((item) => allowedValues.includes(item.value));
  }, [periodicityOptions, activity.allowed_periodicity]);
  const formikProps = {
    initialValues: {
      ...activity,
      start_date: new Date(),
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: yup.object().shape({
      start_date: yup.date().nullable().required(),
      deadline: yup.date().nullable().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      return statusAPI.activities
        .activateActivity(companyId, activity.id, {
          start_date: formatDate(values.start_date),
          periodicity: values.periodicity.value,
          assignee: values.assignee.value,
          deadline: formatDate(values.deadline),
        })
        .then((response) => {
          const activities = [...tableValues.activities];
          const { periodicity } = activities[index];
          activities[index] = response.data;
          activities[index].periodicity = periodicity;
          activities[index].start_date = values.start_date;
          activities[index].assignee = values.assignee;
          tableResetForm({ values: { ...tableValues, activities } });
          toast.success(t("msg:activated"));
          handleClose();
        })
        .catch((error) => {
          setErrors(error.data);
          toast.error(t("msg:canNotExecuteAction"));
        });
    },
  };

  const onClose = () => {
    handleClose();
  };
  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={onClose}
      size="md"
      dialogClassName="verification-comments-modal status-list-config-modal activate-form"
      dialogAs={DraggableModalDialog}
    >
      <Formik {...formikProps}>
        {() => {
          return (
            <Form id="activationForm">
              <Modal.Header closeButton>
                <div>
                  <h1>{t("activationTitle")}</h1>
                  <h2>{activity.name_en}</h2>
                </div>
              </Modal.Header>
              <Modal.Body style={{ minHeight: 300 }}>
                <div className="pr-4 pl-4">
                  <FormGroup.DateMonthPicker
                    popperClassName="popper-in-modal"
                    label={t("common:dates.startMonth")}
                    name="start_date"
                  />
                  <FormGroup.DatePicker
                    popperClassName="popper-in-modal"
                    label={t("common:dates.deadline")}
                    name="deadline"
                  />
                  <FormGroup.SimpleSelect
                    options={allowedPeriodicityOptions}
                    label={t("config.periodicity")}
                    name="periodicity"
                  />
                  <FormGroup.SimpleSelect options={consults} label={t("config.assignee")} name="assignee" />
                </div>
              </Modal.Body>
              <ActivateFooter companyId={companyId} activityId={activity.id} onClose={onClose} />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default ActivateModal;
