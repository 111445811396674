import React, { useEffect } from "react";
import userReducer from "state/reducers/userReducer";
import * as authAPI from "api2/auth";
import { LOGGED_IN, UNAUTHORIZED } from "state/types";

const initialState = {
  user: null,
  authenticated: false,
  loading: true,
};

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();
const savedFilters = {}; // store until refresh page

function UserProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, undefined, () => initialState);

  function checkMe() {
    authAPI
      .me()
      .then((response) => {
        dispatch({ type: LOGGED_IN, payload: { user: response.data } });
      })
      .catch(() => {
        dispatch({ type: UNAUTHORIZED });
      });
  }

  useEffect(() => {
    checkMe();
  }, []);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

export { UserStateContext, UserDispatchContext };

export default UserProvider;
export { savedFilters };
