import React, { useCallback } from "react";
import { Modal, Table } from "react-bootstrap";
import useAsync from "hooks/useAsync";
import { Loader } from "components/ui/loaders";
import * as companyAPI from "api/company";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import { formatDatetime } from "utils/date";

function AssetHistoryLogsModal({ companyId, assetId, handleClose, t }) {
  return (
    <Modal
      key={`asset_history_${assetId}`}
      show
      onHide={handleClose}
      size="xl"
      scrollable
      className="asset-history-modal"
      dialogAs={DraggableModalDialog}
    >
      <div className="modal-content__wrapper">
        <Modal.Header closeButton>
          <Modal.Title className="m-0">
            {t("asset:asset")} {t("common:history")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AssetHistoryLogsContent companyId={companyId} assetId={assetId} t={t} />
        </Modal.Body>
      </div>
    </Modal>
  );
}

function AssetHistoryLogsContent({ companyId, assetId, t }) {
  const getLogs = useCallback(
    (cancelToken) =>
      companyAPI.asset.logs(companyId, assetId).then((response) => {
        return response.data;
      }),
    [companyId, assetId]
  );
  const [{ data: logs, loading }] = useAsync(getLogs, []);
  if (loading) {
    return <Loader />;
  }
  function liForDifference(difference) {
    const translatedKey = t(`historySnapshot:${difference.key}`);
    if (difference.old_value) {
      return (
        <li>{`${translatedKey}: ${difference.old_value} -> ${
          difference.new_value ? difference.new_value : t("unset")
        }`}</li>
      );
    }
    return <li>{`${translatedKey}: ${difference.new_value || "-"}`}</li>;
  }
  function tdForDifferences(log) {
    if (log.diff?.length) {
      return (
        <td>
          <ul>{log.diff.map((difference) => liForDifference(difference))}</ul>
        </td>
      );
    }
    return <td />;
  }
  return (
    <Table size="sm">
      <thead>
        <tr>
          <th className="text-left" style={{ width: 160 }}>
            {t("common:action")}
          </th>
          <th className="text-left" style={{ width: 200 }}>
            {t("common:user")}
          </th>
          <th className="text-left" style={{ width: 180 }}>
            {t("common:dates.date")}
          </th>
          <th className="text-left">{t("common:details")}</th>
        </tr>
      </thead>
      <tbody>
        {logs.map((log, index) => (
          <tr key={index}>
            <td className="text-left">{t(`common:logs.${log.action}`)}</td>
            <td className="text-left">{log.user_name}</td>
            <td className="text-left">{formatDatetime(log.created)}</td>
            {tdForDifferences(log)}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default AssetHistoryLogsModal;
