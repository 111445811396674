import React, { useState } from "react";
import * as yup from "yup";
import { Alert, Button, Card, Table } from "react-bootstrap";
import { FieldArray, Form, Formik } from "formik";

import * as companyAPI from "api2/companies";
import { TableGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useTable from "components/tables/btable/useTable";
import { formatMoney } from "utils/money";
import "./AccountMatchingForm.scss";
import { confirmExecute } from "components/modals/ConfirmModal";
import cx from "classnames";

function AccountMatchingFormBase({ formikProps, companyAccounts, series, onCancel, onSubmit, displayWarn = true }) {
  const { t } = useTranslation("company");

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors, setValues }) => {
        const onConfirm = (index) => {
          setValues((fields) => {
            const mapping = fields.mapping.map((account, idx) =>
              index === idx ? { ...account, confirmed: true } : account
            );
            return { mapping };
          });
        };

        return (
          <Form className="account-matching-form">
            <Card.Body data-testid="account-matching-form-body">
              <Card.Title>{t("accountMatching")}</Card.Title>
              <Table bordered className="table-form">
                <thead>
                  <tr>
                    <th>{t("common:accountF")}</th>
                    <th>{t("sieAccount")}</th>
                    {series === "Z" && (
                      <>
                        <th style={{ width: 150, textAlign: "right" }}>{t("outgoingBalance")}</th>
                        <th>{t("bflowName")}</th>
                      </>
                    )}
                  </tr>
                </thead>

                <FieldArray
                  name="mapping"
                  render={({ replace }) => (
                    <tbody>
                      {values.mapping.map((account, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr className={cx({ "account-matching-form__suggested-row": account.suggestion })}>
                              <TableGroup.SimpleSelect
                                options={companyAccounts.asOptions}
                                name={`mapping[${index}].bflow_account`}
                                {...(account.suggestion && {
                                  rightComponent: (
                                    <div
                                      tabIndex={0}
                                      onClick={() => onConfirm(index)}
                                      role="button"
                                      className="text-primary cursor-pointer confirm-button"
                                    >
                                      {!account.confirmed && t("common:actions.confirm")}
                                    </div>
                                  ),
                                })}
                                filterOptionStartsWith
                              />

                              <td className="disabled">
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  defaultValue={`${account.imported_account_number} - ${account.imported_account_name}`}
                                />
                              </td>
                              {series === "Z" && (
                                <>
                                  <td className="disabled">
                                    <input
                                      style={{ textAlign: "right" }}
                                      type="text"
                                      className="form-control"
                                      disabled
                                      defaultValue={
                                        account.imported_account_outgoing_balance !== null
                                          ? `${formatMoney(account.imported_account_outgoing_balance)} SEK`
                                          : "-"
                                      }
                                    />
                                  </td>

                                  <td rowSpan={account.rowSpan}>
                                    <input // speed up
                                      type="text"
                                      className="form-control"
                                      onBlur={(event) => {
                                        if (event.currentTarget.value === "") return;
                                        replace(index, {
                                          ...account,
                                          imported_account_name: event.currentTarget.value.trim(),
                                        });
                                        event.currentTarget.value = "";
                                      }}
                                      placeholder={account.imported_account_name}
                                    />
                                  </td>
                                </>
                              )}
                            </tr>
                            <TableGroup.RowErrors errors={errors.mapping && errors.mapping[index]} />
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  )}
                />
              </Table>
              {displayWarn && (
                <Alert variant="warning" data-testid="sie-mapping-warning">
                  {t("SIEMappingInfo")}
                </Alert>
              )}
            </Card.Body>
            <Card.Footer className={`d-flex ${onCancel ? "space" : "justify-content-end"}`}>
              {!!onCancel && (
                <Button variant="secondary" onClick={onCancel}>
                  {t("common:actions.cancel")}
                </Button>
              )}
              <SubmitButton className="px-4" size="lg" icon="" isSubmitting={isSubmitting} />
            </Card.Footer>
          </Form>
        );
      }}
    </Formik>
  );
}

function AccountMatchingForm({ companyId, onSubmit, mapping, companyAccounts, series, onCancel, onSuccess }) {
  const [result, setResult] = useState(null);
  const { t } = useTranslation("company");
  const {
    dataActions: { reload },
  } = useTable();

  const formattedMapping = mapping.map((row) => ({ ...row, confirmed: !row.suggestion }));
  const formikProps = {
    initialValues: {
      mapping: formattedMapping,
    },
    validationSchema: yup.object().shape({
      mapping: yup.array().of(
        yup.object().shape({
          bflow_account: yup.object().nullable().required(),
        })
      ),
    }),
    async onSubmit(values) {
      if (values.mapping.some((value) => !value.confirmed)) {
        const answer = await confirmExecute(t("confirm.suggestedMapping"));
        if (!answer) return;
      }

      await companyAPI.sieFiles
        .accountMatch(companyId, {
          mapped_accounts: values.mapping.map((item) => ({
            bflow_account_number: item.bflow_account.value,
            bflow_account_name: item.bflow_account.name,
            imported_account_number: item.imported_account_number,
            imported_account_name: item.imported_account_name || "",
            imported_account_outgoing_balance: item.imported_account_outgoing_balance || "-",
          })),
        })
        .then((response) => {
          reload();
          document.body.dispatchEvent(new Event("SIE/imported"));
          setResult(response.data); // use data..
          if (onSuccess) {
            onSuccess(response.data);
          }
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__, { autoClose: 4000 });
          } else {
            toast.error(error.data, { autoClose: 4000 });
          }
        });
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  };

  if (result) {
    return (
      <>
        <Card.Body data-testid="account-matching-result">
          <Card.Title>{t("SIECompleted")}</Card.Title>
          <pre>
            Imported:
            <br />
            {result.verifications} Verifications
            <br />
            {result.cost_centers} Cost centers
            <br />
            {result.projects} Projects
            <br />
          </pre>
        </Card.Body>
        <Card.Footer className="d-flex space">
          <Button variant="secondary" onClick={() => onCancel(true)}>
            {t("common:actions.goBack")}
          </Button>
        </Card.Footer>
      </>
    );
  }

  return (
    <AccountMatchingFormBase
      companyAccounts={companyAccounts}
      series={series}
      formikProps={formikProps}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
}

function OnboardingAccountMatchingForm({ companyId, agencyId, onSubmit, mapping, companyAccounts, onSuccess }) {
  const { t } = useTranslation("company");
  const formattedMapping = mapping.map((row) => ({ ...row, confirmed: !row.suggestion }));

  const formikProps = {
    initialValues: {
      mapping: formattedMapping,
    },
    validationSchema: yup.object().shape({
      mapping: yup.array().of(
        yup.object().shape({
          bflow_account: yup.object().nullable().required(),
        })
      ),
    }),
    async onSubmit(values) {
      if (values.mapping.some((value) => !value.confirmed)) {
        const answer = await confirmExecute(t("confirm.suggestedMapping"));
        if (!answer) return;
      }
      await companyAPI.onboardings.steps
        .saveAccountingFinish(companyId, agencyId, {
          mapped_accounts: values.mapping.map((item) => ({
            bflow_account_number: item.bflow_account.value,
            bflow_account_name: item.bflow_account.name,
            imported_account_number: item.imported_account_number,
            imported_account_name: item.imported_account_name || "",
            imported_account_outgoing_balance: item.imported_account_outgoing_balance || "-",
          })),
        })
        .then((response) => {
          if (onSuccess) {
            onSuccess(response.data);
          }
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__, { autoClose: 4000 });
          } else {
            toast.error(error.data, { autoClose: 4000 });
          }
        });
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  };

  return (
    <AccountMatchingFormBase
      companyAccounts={companyAccounts}
      formikProps={formikProps}
      onSubmit={onSubmit}
      displayWarn={false}
      series="Z"
    />
  );
}

export { OnboardingAccountMatchingForm };
export default AccountMatchingForm;
