import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import * as siAPI from "api2/supplier-invoices";
import useInitialAsync from "hooks/useInitialAsync";
import { Loader } from "components/ui/loaders";
import { DraggableModalDialog } from "../DraggableModalDialog";

function SIUseCreditModal({ handleClose, companyId, invoiceData }) {
  const { t } = useTranslation("si");
  const [submitting, setSubmitting] = useState(false);
  const { loading, item: creditInvoices } = useInitialAsync(
    ({ cancelToken }) => siAPI.credits.list(companyId, invoiceData.id, { cancelToken }),
    []
  );

  function creditInvoice(creditInvoiceInstance) {
    setSubmitting(true);
    return siAPI.credits
      .create(companyId, invoiceData.id, creditInvoiceInstance.id)
      .then((response) => {
        toast.success(t("msg:creditUsed", { invoice: creditInvoiceInstance.invoice_no }));
        handleClose(creditInvoiceInstance);
      })
      .catch((error) => {
        toast.error(t("msg:creditUsedFail", { invoice: creditInvoiceInstance.invoice_no }));
        handleClose(null);
      });
  }

  return (
    <Modal show onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">
          {t("useCreditModalTitle", {
            invoiceNo: invoiceData.invoice_no || invoiceData.ocr,
          })}
        </Modal.Title>
      </Modal.Header>
      <Table bordered>
        <thead>
          <tr>
            <th>{t("common:invoiceNo")}</th>
            <th className="text-right">{t("common:money.amount")}</th>
            <th className="text-right">{t("common:money.balance")}</th>
            <th style={{ width: 150 }} />
          </tr>
        </thead>
        {loading && (
          <tbody>
            <tr>
              <td colSpan={4}>
                <Loader />
              </td>
            </tr>
          </tbody>
        )}
        {!loading && creditInvoices.length ? (
          <tbody>
            {creditInvoices.map((invoice) => (
              <tr key={invoice.id}>
                <td>{invoice.invoice_no}</td>
                <td className="text-right">{invoice.amount_sek}</td>
                <td className="text-right">{invoice.balance}</td>
                <td className="text-center">
                  <Button
                    type="button"
                    size="xs"
                    variant="toggle-green"
                    onClick={() => creditInvoice(invoice)}
                    disabled={submitting}
                  >
                    {t("common:actions.useCredit")}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        ) : null}
        {!loading && !creditInvoices.length && (
          <tbody>
            <tr>
              <td colSpan={4}>{t("common:noResultsFound")}</td>
            </tr>
          </tbody>
        )}
      </Table>
    </Modal>
  );
}

export default SIUseCreditModal;
