import client from "api2/client";
import { parseISO } from "date-fns";

export const taxDP = {
  list: (companyId, filters, config) =>
    client.get(`/taxes/direct-payments/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  save: (companyId, data, config) =>
    client.post(`/taxes/direct-payments/create/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  remove: (companyId, dpId, params, config) =>
    client.delete(`/taxes/direct-payments/${dpId}/delete/`, {
      params,
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  manualPay: (companyId, dpId, data, config) =>
    client.post(`/taxes/direct-payments/${dpId}/payments/pay-manual/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  cancelPayment: (companyId, dpId, config) =>
    client.delete(`/taxes/direct-payments/${dpId}/payments/cancel/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  pay: (companyId, dpId, bankIDToken, config) =>
    client.post(
      `/taxes/direct-payments/${dpId}/payments/pay-bgc/`,
      {
        bank_id_token: bankIDToken,
      },
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    ),
  history: (companyId, dpId, config) => {
    return client.get(`/taxes/direct-payments/${dpId}/history/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const authority = {
  list: (companyId, filters, config) =>
    client.get(`/taxes/authorities/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  save: (companyId, data, config) =>
    client.post(`/taxes/authorities/create/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  verificationPreview: (companyId, data, config) =>
    client.post(`/taxes/authorities/verification-preview/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  remove: (companyId, taxId, params, config) =>
    client.delete(`/taxes/authorities/${taxId}/delete/`, {
      params,
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  latestSpec: (companyId, config) =>
    client.get(`/taxes/authorities/latest-spec/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  details: (companyId, taxId, config) => {
    return client
      .get(`/taxes/authorities/${taxId}/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      })
      .then((response) => ({
        ...response.data,
        booking_date: response.data.booking_date ? parseISO(response.data.booking_date) : null,
        payment_date: response.data.payment_date ? parseISO(response.data.payment_date) : null,
      }));
  },
  history: (companyId, taxId, config) => {
    return client.get(`/taxes/authorities/${taxId}/history/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};
