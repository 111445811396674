import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { addMonths, subDays } from "date-fns";
import qs from "qs";
import * as yup from "yup";
import "./EuVat.scss";
import BTable from "components/tables/btable";
import { formatDate, formatDatetime } from "utils/date";
import { SelectionContext } from "state/providers/SelectionProvider";
import * as vatAPI from "api2/vat";
import { euVATChoices } from "api/options";
import { HistoryButton } from "components/ui/buttons";
import { saveBlobToComputer } from "utils/file";
import { CheckboxFilter } from "components/filters";
import { confirmExecute } from "components/modals/ConfirmModal";
import useModal from "hooks/useModal";
import FileUrlModal from "components/modals/FileUrlModal/FileUrlModal";
import Filters from "./EuVatFilters";
import useTable from "../btable/useTable";

import ContactProvider, { ContactContext } from "./ContactContext";

function SelectActions() {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const {
    filters,
    dataActions: { reload },
  } = useTable();
  const {
    state: { contactName, contactEmail, contactPhone },
  } = useContext(ContactContext);
  const { getFlatten, clearSelection } = useContext(SelectionContext);
  const selected = getFlatten();
  const onScan = () => {
    setLoading(true);
    const promises = [];
    selected.forEach((item) => {
      const promise = vatAPI.vatEU.scan(item.company_id, filters.period, formatDate(filters.period_month));
      promises.push(promise);
    });
    Promise.all(promises).finally(() => {
      setLoading(false);
      reload();
      clearSelection();
    });
  };

  const onDownload = async () => {
    if (!contactName || !contactPhone) {
      toast.error(t("company:euVatContactRequired"));
      return false;
    }
    try {
      await yup.string().phone().validate(contactPhone);
    } catch (err) {
      toast.error(err.message);
      return null;
    }
    try {
      await yup.string().email().validate(contactEmail);
    } catch (err) {
      toast.error(t("company:euVatContactEmailInvalid"));
      return false;
    }

    setLoading(true);
    const promises = [];
    selected.forEach((item) => {
      if (item.vat_id) {
        const promise = vatAPI.vatEU
          .downloadSKV(item.company_id, item.vat_id, {
            contact_name: contactName,
            contact_phone: contactPhone,
            contact_email: contactEmail,
          })
          .then((response) => {
            saveBlobToComputer(response, "eu-vat.txt", false);
          })
          .catch(() => {});
        promises.push(promise);
      }
    });
    Promise.all(promises).finally(() => {
      setLoading(false);
      reload();
    });
    return true;
  };

  return (
    <div className="actions-vat_eu">
      <Button variant="secondary" onClick={onScan} disabled={loading || !selected.length}>
        {t("common:actions.scanSelected")}
      </Button>
      <Button variant="secondary" className="ml-3" onClick={onDownload} disabled={loading || !selected.length}>
        {t("common:actions.downloadSelected")}
      </Button>
      <Button variant="link" as="a" href="https://skatteverket.se/" target="_blank">
        {t("common:actions.openSKV")} <i className="fe-external-link" />
      </Button>
    </div>
  );
}

function ScanButton({ companyId, setting }) {
  const { t } = useTranslation("common");
  const { clearSelection } = useContext(SelectionContext);
  const {
    dataActions: { reload },
  } = useTable();
  const [loading, setLoading] = useState(false);
  const { filters } = useTable();
  const scan = useCallback(() => {
    setLoading(true);
    vatAPI.vatEU.scan(companyId, filters.period, formatDate(filters.period_month)).finally(() => {
      reload();
      clearSelection();
      setLoading(false);
    });
  }, [companyId, filters.period_month, filters.period, reload, clearSelection]);
  const disableException = setting === "yes_products" && filters.period === "quarterly";
  return (
    <Button
      variant="toggle"
      size="sm"
      disabled={loading || disableException}
      title={disableException ? t("company:euVatReportedMonthly") : ""}
      onClick={scan}
    >
      {t("common:actions.rescan")}
    </Button>
  );
}

function DownloadSKVButton({ companyId, vatId, error }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("company");
  const {
    dataActions: { reload },
  } = useTable();
  const {
    state: { contactName, contactEmail, contactPhone },
  } = useContext(ContactContext);
  const download = async () => {
    if (!contactName || !contactPhone) {
      toast.error(t("euVatContactRequired"));
      return null;
    }
    try {
      await yup.string().phone().validate(contactPhone);
    } catch (err) {
      toast.error(err.message);
      return null;
    }
    try {
      await yup.string().email().validate(contactEmail);
    } catch (err) {
      toast.error(t("euVatContactEmailInvalid"));
      return null;
    }

    setLoading(true);
    return vatAPI.vatEU
      .downloadSKV(companyId, vatId, {
        contact_name: contactName,
        contact_phone: contactPhone,
        contact_email: contactEmail,
      })
      .then((response) => {
        saveBlobToComputer(response, "eu-vat.txt", false);
        reload();
      })
      .catch(() => {
        toast.error(t("msg:canNotExecuteAction"));
        return null;
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Button disabled={loading || error || !vatId} size="sm" variant="secondary" onClick={download}>
      <i className="fe-download" />
    </Button>
  );
}

function UploadSKVButton({ companyId, vatId }) {
  const fileRef = useRef(undefined);
  const {
    dataActions: { reload },
  } = useTable();
  const { t } = useTranslation("msg");

  const clicked = () => {
    fileRef.current.click();
  };
  const onFileSelected = (event) => {
    const selectedFile = (event.target.files || event.dataTransfer.files)[0];
    event.target.value = null;
    if (selectedFile.type !== "text/plain" && selectedFile.type !== "application/pdf") {
      toast.error(t("common:euVatUploadFormat"));
      return false;
    }
    return vatAPI.vatEU
      .uploadSkv(companyId, vatId, selectedFile)
      .then((response) => {
        toast.success(t("msg:uploaded"));
        reload();
      })
      .catch(() => {
        toast.error(t("msg:fileError"));
      });
  };
  return (
    <div className="upload-btn">
      <Button disabled={!vatId} size="sm" variant="secondary" onClick={clicked}>
        <i className="fe-upload" />
      </Button>
      <input ref={fileRef} type="file" accept="text/plain, application/pdf" onChange={onFileSelected} />
    </div>
  );
}

function StatusBox({ status }) {
  const { t } = useTranslation("company");
  let className = "";
  let info = "";
  switch (status) {
    case "had_no_sale":
      className = "gray";
      info = "euVatNoSales";
      break;
    case "warning":
      className = "orange";
      info = "euVatPeriodInfo";
      break;
    case "good":
      className = "green";
      info = "";
      break;
    case "error":
      className = "red";
      info = "euVatNoSalesWrong";
      break;
    default:
      className = "black";
  }
  const component = (
    <div>
      <div className={`sb sb-${className}`} />
      {info && <i className="fe-info" />}
    </div>
  );

  if (info) {
    return (
      <OverlayTrigger placement="right" overlay={<Tooltip>{t(info)}</Tooltip>}>
        {component}
      </OverlayTrigger>
    );
  }
  return component;
}

function RowTitle({ companyId, name, lastScanDate }) {
  const { t } = useTranslation("common");
  const { filters } = useTable();
  const selectCompany = async () => {
    await localStorage.setItem("lastCompanyId", companyId);
    const dateFrom = filters.period_month;
    const dateTo = subDays(addMonths(filters.period_month, filters.period === "monthly" ? 1 : 3), 1);
    const params = qs.stringify(
      {
        start: formatDate(dateFrom),
        end: formatDate(dateTo),
      },
      { indices: false }
    );
    window.open(`/consult/profit-and-loss?${params}`);
  };

  return (
    <div className="row-title">
      <Button variant="link" onClick={selectCompany}>
        {name}
      </Button>
      {lastScanDate && <small>{t("scannedAt", { date: formatDatetime(lastScanDate) })}</small>}
    </div>
  );
}

function ReportedCheck({ companyId, vatEuId, initialValue, isDownloaded }) {
  const [reported, setReported] = useState(initialValue);

  useEffect(() => {
    setReported(initialValue);
  }, [initialValue]);

  const inputName = `is_reported_${companyId}`;
  const { t } = useTranslation("company");
  const onClick = async (value) => {
    if (!vatEuId) {
      return null;
    }
    if (value[inputName] === true) {
      if (!isDownloaded) {
        const confirm = await confirmExecute(t("confirm.markVat"));
        if (!confirm) {
          return null;
        }
      }
    } else {
      const confirm = await confirmExecute(t("confirm.unmarkVat"));
      if (!confirm) {
        return null;
      }
    }
    return vatAPI.vatEU
      .toggleReported(companyId, vatEuId)
      .then(() => {
        setReported(value[inputName]);
      })
      .catch(() => {});
  };
  return (
    <div className="reported-col">
      <CheckboxFilter name={inputName} disabled={!vatEuId} checked={reported} onFilter={onClick} />
    </div>
  );
}

function PDFPreviewButton({ companyId, vatId }) {
  const pdfModal = useModal();
  const open = () => {
    if (!vatId) {
      return null;
    }
    pdfModal.open(`/vat/eu/${vatId}/pdf/`);
    return null;
  };
  return (
    <>
      <Button variant="secondary" onClick={open} size="sm" disabled={!vatId}>
        <i className="fas fa-search" />
      </Button>
      {pdfModal.show && (
        <FileUrlModal show companyId={companyId} fileUrl={pdfModal.data} handleClose={pdfModal.close} />
      )}
    </>
  );
}

function EuVatTable() {
  const { t } = useTranslation("company");

  const headers = useMemo(
    () => [
      {
        field: "company_name",
        label: t("common:company"),
        render: (values) => (
          <RowTitle companyId={values.company_id} name={values.company_name} lastScanDate={values.last_scan_date} />
        ),
      },
      {
        field: "custom1",
        label: "",
        className: "text-center",
        canSort: false,
        render: (values) => <ScanButton companyId={values.company_id} setting={values.eu_vat} />,
      },
      {
        field: "status",
        label: t("common:statuses.status"),
        className: "status-box",
        sortField: "euvat_status",
        render: (values) => <StatusBox status={values.status} />,
      },
      {
        field: "eu_vat",
        label: t("common:setting"),
        className: "text-center",
        render: (values) => <span>{euVATChoices.getLabel(values.eu_vat)}</span>,
      },
      {
        field: "last_download",
        className: "text-center",
        label: t("common:lastDownload"),
        sortField: "last_download_date",
        render: (values) => (values.last_download_date ? formatDate(values.last_download_date) : null),
      },
      {
        field: "reported",
        className: "text-center",
        label: t("common:actions.reported"),
        sortField: "is_reported",
        render: (values) => (
          <ReportedCheck
            initialValue={values.is_reported}
            companyId={values.company_id}
            vatEuId={values.vat_id}
            isDownloaded={!!values.last_download_date}
          />
        ),
      },
      {
        field: "custom2",
        className: "text-center",
        label: t("common:actions.downloadSKVFile"),
        canSort: false,
        render: (values) => (
          <DownloadSKVButton
            companyId={values.company_id}
            vatId={values.vat_id}
            error={["warning", "error"].includes(values.status)}
          />
        ),
      },
      {
        field: "custom3",
        label: t("common:actions.uploadSKVdoc"),
        className: "text-center",
        canSort: false,
        render: (values) => <UploadSKVButton companyId={values.company_id} vatId={values.vat_id} />,
      },
      {
        field: "custom4",
        className: "text-center",
        label: t("common:actions.preview"),
        canSort: false,
        render: (values) => <PDFPreviewButton companyId={values.company_id} vatId={values.vat_id} />,
      },
      {
        field: "custom5",
        className: "text-center",
        label: t("common:history"),
        canSort: false,
        render: (values) => (
          <HistoryButton
            apiResource={vatAPI.vatEU.history}
            apiParams={[values.company_id, values.vat_id]}
            disabled={!values.vat_id}
            variant="secondary"
          />
        ),
      },
    ],
    [t]
  );

  const renderSelect = (row) => {
    if (row.status === "error" || row.status === "warning") {
      return "";
    }
    return undefined;
  };

  return (
    <BTable
      tableClassName="vat-eu"
      headers={headers}
      selectable
      FiltersComponent={<Filters />}
      renderTotals={SelectActions}
      renderSelect={renderSelect}
    />
  );
}

function EuVatTableContainer() {
  return (
    <ContactProvider>
      <EuVatTable />
    </ContactProvider>
  );
}

export default EuVatTableContainer;
