import React from "react";
import { codesFor, HasPermCode } from "components/perms";
import SupplierInvoiceListPage from "./SupplierInvoiceListPage";

function PaySupplierInvoiceListPage() {
  const canPay = HasPermCode(codesFor.supplierInvoices.pay);
  const allowedModes = [{ value: "unpaid", label: "common:statuses.unpaid", variant: "toggle-red-grey" }];
  return (
    <SupplierInvoiceListPage
      tableId="unpaidSupplierInvoices"
      initialMode="unpaid"
      allowedModes={allowedModes}
      selectable={canPay}
    />
  );
}

export default PaySupplierInvoiceListPage;
