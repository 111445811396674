import React, { useCallback, useMemo } from "react";
import { useFormikContext } from "formik";
import * as statusAPI from "api2/status-list";
import Checklist from "./Checklist";

function ActivityChecklist({ companyId, activity, index: configIndex, dirty, setDirty }) {
  const { values, resetForm } = useFormikContext();
  const dataSource = useCallback(
    ({ cancelToken }) => statusAPI.activities.checklist.list(companyId, activity.id, { cancelToken }),
    [companyId, activity.id]
  );

  const handleUpdate = useCallback(
    (checklist) => {
      const activities = [...values.activities];
      return statusAPI.activities.checklist.updateMany(companyId, activity.id, checklist).then((response) => {
        activities[configIndex].checklist = response.data.map((item) => item.id);
        resetForm({ values: { ...values, activities } });
      });
    },
    [companyId, activity.id, values, configIndex, resetForm]
  );

  const actions = useMemo(
    () => ({
      update: handleUpdate,
    }),
    [handleUpdate]
  );

  return (
    <Checklist
      companyId={companyId}
      dataSource={dataSource}
      dirty={dirty}
      setDirty={setDirty}
      actions={actions}
      checkDisabled
      dragEnabled
      showControls
    />
  );
}

export default ActivityChecklist;
