export const LOGGED_IN = "auth/logged_in";
export const LOGGED_OUT = "auth/logged_out";
export const CHECKING = "auth/checking";
export const UNAUTHORIZED = "auth/unauthorized";
export const UPDATE_USER_PROPS = "auth/update_user_props";
export const SET_COMPANY_WAIT = "company/set_wait";
export const SET_COMPANY_OK = "company/set_ok";
export const SET_LOADED = "company/set_loaded";
export const SET_LOADING = "company/set_loading";
export const SET_COMPANY_ERROR = "company/set_error";
export const UPDATE_COMPANY = "company/update";
export const RESET_COMPANY = "company/reset";
export const SET_MENU_NUMBERS = "company/set_menu";
export const SET_CURRENT_FYEAR = "company/set_fyear";

export const OPEN_MODAL = "verification/open_modal";
export const TOGGLE_MODAL = "verification/toggle_modal";

export const UPDATE_COMPANY_PROJECTS = "company/update_projects";
export const UPDATE_COMPANY_COST_CENTERS = "company/update_cost_centers";
