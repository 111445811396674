import React from "react";

import useInitialAsync from "hooks/useInitialAsync";
import * as supplierAPI from "api2/suppliers";
import { Loader } from "components/ui/loaders";
import useTable from "components/tables/btable/useTable";
import SupplierForm from "./SupplierForm";

function SupplierEditWidget({ companyId, id }) {
  const { loading, item } = useInitialAsync(
    ({ cancelToken }) => supplierAPI.locals.details(companyId, id, { cancelToken }),
    {}
  );

  const {
    dataActions: { reload },
  } = useTable();

  if (loading) {
    return <Loader />;
  }

  const successCallback = (newData) => {
    reload();
  };

  const deleteCallback = () => {
    reload();
  };

  return (
    <SupplierForm
      supplier={item}
      companyId={companyId}
      successCallback={successCallback}
      deleteCallback={deleteCallback}
      shouldResetForm={false}
    />
  );
}

export default SupplierEditWidget;
