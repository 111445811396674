import _ from "lodash";
import { differenceInCalendarDays, parseISO } from "date-fns";
import client from "./client";
import * as options from "./options";
import { _getDiscount } from "./customer-invoices";

export function save(companyId, data) {
  if (data.id) {
    return client.put(`/companies/${companyId}/offers/${data.id}/`, data, { timeout: 60000 });
  }
  return client.post(`/companies/${companyId}/offers/`, data, { timeout: 60000 });
}
export function details(companyId, offerId) {
  const vatOptions = options.vatOptions.asList();
  return client.get(`/companies/${companyId}/offers/${offerId}/`).then((response) => {
    const booking_date = response.data.booking_date ? parseISO(response.data.booking_date) : null;
    const due_date = response.data.due_date ? parseISO(response.data.due_date) : null;
    const payment_terms = differenceInCalendarDays(due_date, booking_date);
    const valid_date = response.data.valid_date ? parseISO(response.data.valid_date) : null;
    const valid_period = valid_date && differenceInCalendarDays(valid_date, booking_date);
    return {
      ...response.data,
      payment_terms,
      valid_period,
      customer: !_.isEmpty(response.data.customer_snapshot) && {
        value: response.data.customer_snapshot.DebtorPublicId,
        label: response.data.customer_snapshot.Name,
        ...response.data.customer_snapshot,
      },
      extra_receivers: response.data.extra_receivers
        ? response.data.extra_receivers.filter((item) => item.length > 0)
        : [],
      rot_rut: {
        Customers:
          response.data.rot_rut && response.data.rot_rut.Customers
            ? response.data.rot_rut.Customers.map((customer) => ({
                ...customer,
                AskedAmount: customer.AskedAmount.Value / 100,
              }))
            : [],
        PropertyDesignation: response.data.rot_rut ? response.data.rot_rut.PropertyDesignation : "",
        ResidenceAssociationOrgNo: response.data.rot_rut ? response.data.rot_rut.ResidenceAssociationOrgNo : "",
      },
      booking_date: response.data.delivery_date ? parseISO(response.data.delivery_date) : null,
      due_date: response.data.due_date ? parseISO(response.data.due_date) : null,
      records: response.data.records.map((record) => ({
        ...record,
        VAT: options.vatOptions.getOption(record.VAT) || vatOptions[0],
        UnitPrice: record.UnitPrice && record.UnitPrice.Value / 100,
        RotRutAmount: record.RotRutAmount && record.RotRutAmount.Value / 100,
        Discount: _getDiscount(record),
        key: _.uniqueId("ln"),
      })),
    };
  });
}

export function sendToCustomer(companyId, offerId) {
  return client.patch(`/companies/${companyId}/offers/${offerId}/send/`);
}

export function approveToCustomerInvoice(companyId, offerId) {
  return client.patch(`/companies/${companyId}/offers/${offerId}/approve_to_customer_invoice/`);
}

export function approveToOrder(companyId, offerId) {
  return client.patch(`/companies/${companyId}/offers/${offerId}/approve_to_order/`);
}

export function deny(companyId, offerId) {
  return client.patch(`/companies/${companyId}/offers/${offerId}/deny/`);
}

export function logs(offerId) {
  return client.get(`/offer-history/${offerId}/`);
}
