import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import * as companyAPI from "api2/companies";
import { AnnualReportForm } from "components/forms/company-settings-forms";
import { withInitialAsync } from "hooks/useAsync";
import GoogleSyncWidget from "./GoogleSyncWidget";

function AnnualReportFormPage({ data: item }) {
  const { companyId } = useParams();

  const reportData = item.annual_report_data || {
    aboutCompany: "",
    eventsDuring: "",
    eventsAfter: "",
    auditor: {
      name: "",
      haveAuditor: "0",
      firstName: "",
      surname: "",
      title: 1,
    },
    members: [],
  };

  return (
    <>
      <Row>
        <Col>
          <AnnualReportForm companyId={companyId} reportData={reportData} />
        </Col>
      </Row>
      <GoogleSyncWidget
        companyId={companyId}
        normalLink={item.google_sheet_link}
        annualLink={item.google_sheet_annual_link}
      />
    </>
  );
}

const EnhancedAnnualReportFormPage = withInitialAsync(
  AnnualReportFormPage,
  () => {
    const { companyId } = useParams();
    return React.useCallback(
      (cancelToken) => companyAPI.annualReports.details(companyId, { cancelToken }),
      [companyId]
    );
  },
  {},
  true
);

export default EnhancedAnnualReportFormPage;
