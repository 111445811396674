import React from "react";

import * as supportApi from "api2/office-support";
import { withInitialAsync } from "hooks/useAsync";
import OfficeSupportRolesTaxAgencyAttorneyForm from "./OfficeSupportRolesTaxAgencyAttorneyForm";

function OfficeSupportRolesTaxAgencyAttorneyWidget({ data: item, companyId, changeEditedForm }) {
  return (
    <OfficeSupportRolesTaxAgencyAttorneyForm
      companyId={companyId}
      changeEditedForm={changeEditedForm}
      initialData={item}
    />
  );
}

const EnhancedOfficeSupportRolesTaxAgencyAttorneyWidget = withInitialAsync(
  OfficeSupportRolesTaxAgencyAttorneyWidget,
  ({ companyId }) =>
    React.useCallback((cancelToken) => supportApi.roles.tax.list(companyId, { cancelToken }), [companyId]),
  [],
  false,
  true
);

export default EnhancedOfficeSupportRolesTaxAgencyAttorneyWidget;
