import React from "react";
import cx from "classnames";
import { Row, Col, Table, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatMoney, roundMoney } from "utils/money";
import { useCompanyState } from "hooks/useCompany";
import { RocketLoader } from "components/ui/loaders";
import { collectionSum } from "utils/list";
import { filterActiveCC, filterActiveProjects } from "utils/others";

function BaseVerificationsPreviewContainer({
  verifications,
  topText = "",
  showTotals = true,
  isLoading = false,
  errors = null,
  extraMissingVerText = "",
}) {
  const { t } = useTranslation("ver");
  const {
    accounts: { byId: accountById },
    costCenters: { byId: centerById, asOptions: centerOptions },
    projects: { byId: projectById, asOptions: projectOptions },
  } = useCompanyState();

  if (isLoading) {
    return <RocketLoader />;
  }
  if (errors) {
    return <Alert variant="danger">{errors}</Alert>;
  }
  if (verifications.length === 0) {
    return <Alert variant="warning">{t("missPreviewVers")}</Alert>;
  }

  const debitSum = (verification) => {
    return roundMoney(
      collectionSum(
        verification.transactions.filter((tr) => !tr.is_deleted && tr.amount > 0),
        "amount"
      )
    );
  };
  const creditSum = (verification) => {
    return (
      -1 *
      roundMoney(
        collectionSum(
          verification.transactions.filter((tr) => !tr.is_deleted && tr.amount < 0),
          "amount"
        )
      )
    );
  };

  const activeCenters = centerOptions.filter(filterActiveCC);

  return (
    <div className="verification-preview">
      {topText && <p className="text-center pb-1">{topText}</p>}
      {verifications.length === 0 && <Alert variant="warning">{extraMissingVerText || t("missPreviewVers")}</Alert>}
      {verifications.map((verification, index) => {
        const verificationTransactions = verification.transactions;

        const activeProjects = filterActiveProjects(projectOptions, verification.booking_date);
        const showProjects = activeProjects.length !== 0 || verificationTransactions.some((tr) => !!tr.project_id);
        const showCostCenters =
          activeCenters.length !== 0 || verificationTransactions.some((tr) => !!tr.cost_center_id);

        return (
          <React.Fragment key={index}>
            {verification.summary_for_below && <p className="text-center pb-1">{verification.summary_for_below}</p>}
            <Row>
              <Col>
                <div className="form-group">
                  <label className="form-label">{t("common:dates.bookingDate")}</label>
                  <input
                    name="dummy"
                    autoComplete="off"
                    disabled
                    type="text"
                    className="form-control"
                    value={verification.booking_date}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label className="form-label">{`${t("common:verShort")} ${t("common:name")}`}</label>
                  <input
                    name="dummy"
                    autoComplete="off"
                    disabled
                    type="text"
                    className="form-control"
                    value={verification.title}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pb-2">
              <Col>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>{t("common:account")}</th>
                      {showProjects && <th>{t("common:project")}</th>}
                      {showCostCenters && <th>{t("common:costCenter")}</th>}
                      <th>{t("common:money.debit")}</th>
                      <th>{t("common:money.credit")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {verification.transactions.map((transaction, trIndex) => (
                      <tr key={trIndex} className={cx({ removed: !!transaction.is_deleted })}>
                        <td className="disabled">{accountById[transaction.account_id]?.label}</td>
                        {showProjects && <td>{projectById[transaction.project_id]?.label}</td>}
                        {showCostCenters && <td>{centerById[transaction.cost_center_id]?.label}</td>}
                        {transaction.amount > 0 ? (
                          <>
                            <td>{formatMoney(transaction.amount)}</td>
                            <td />
                          </>
                        ) : (
                          <>
                            <td />
                            <td>{formatMoney(-1 * transaction.amount)}</td>
                          </>
                        )}
                      </tr>
                    ))}
                    {showTotals && (
                      <tr>
                        <td colSpan={1 + showCostCenters + showProjects} />
                        <td>{formatMoney(debitSum(verification))}</td>
                        <td>{formatMoney(creditSum(verification))}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default BaseVerificationsPreviewContainer;
