import React, { useState } from "react";
import cx from "classnames";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { FormGroup } from "components/formik";
import { useTranslation } from "react-i18next";
import { SubmitButton } from "components/ui/buttons";
import * as authAPI from "api2/auth";

function PasswordForgotPage() {
  const { t } = useTranslation(["others", "common"]);
  const [submitted, setSubmitted] = useState(false);
  const formikProps = {
    initialValues: {
      email: "",
    },
    validationSchema: yup.object().shape({
      email: yup.string().email().required(),
    }),
    onSubmit: (values) => {
      const { email } = values;
      authAPI
        .resetPasswordStartByConsult(null, email)
        .then((response) => {})
        .catch(() => {})
        .finally(() => {
          setSubmitted(true);
        });
    },
  };
  return (
    <Formik {...formikProps}>
      {({ isSubmitting, values }) => {
        return (
          <Form noValidate>
            <div className="box box-pwd">
              <h3 className="mb-4">{t("others:bflowWelcome")}</h3>
              {submitted ? (
                <div style={{ maxWidth: 350, textAlign: "justify" }}>
                  <p className="text-white-50">{t("others:auth.resetPasswordStarted", { email: values.email })}</p>
                  <Link to="/auth/standard-login">{t("common:actions.goBack")}</Link>
                </div>
              ) : (
                <div>
                  <div className={cx(["inner-box"])}>
                    <FormGroup.Input name="email" type="email" placeholder={t("common:contact.emailLong")} autoFocus />
                  </div>
                  <SubmitButton
                    size="lg"
                    variant="white"
                    className="width-lg"
                    title="others:auth.sendPasswordResetEmail"
                    icon=""
                    disabled={isSubmitting}
                  />
                  <div className="text-center mt-2">
                    <Link to="/auth/standard-login">{t("common:actions.goBack")}</Link>
                  </div>
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default PasswordForgotPage;
