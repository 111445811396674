import React from "react";
import { useTranslation } from "react-i18next";

function EmpNotificationTurnedOffPage() {
  const { t } = useTranslation("others");
  return (
    <div className="box">
      <p className="font-16 text-white">{t("auth.emailsNotificationDisabled")}</p>
    </div>
  );
}

export default EmpNotificationTurnedOffPage;
