import React from "react";
import { useTranslation } from "react-i18next";
import { useCompanyDispatch } from "hooks/useCompany";
import { Button, Card } from "react-bootstrap";

function UserConnectedCompanies({ user }) {
  const { t } = useTranslation("common");

  const { selectCompany: selectCompanyAction } = useCompanyDispatch();
  const selectCompany = async (companyId, nextRoute = "/") => {
    await selectCompanyAction(companyId);
    window.open(`/`);
  };
  return (
    <>
      <label>{t("company:connectedToCompanies", { count: user.companies.length })}</label>
      <Card className="position-absolute overflow-auto companies-container p-0">
        <Card.Body className="p-0">
          <ul className="">
            {user.companies.map((company) => (
              <li key={company.id}>
                <Button variant="link" onClick={() => selectCompany(company.id)}>
                  {company.name}
                </Button>
              </li>
            ))}
          </ul>
        </Card.Body>
      </Card>
    </>
  );
}

export default UserConnectedCompanies;
