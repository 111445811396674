import React from "react";

import * as documentsAPI from "api2/documents";
import useTable from "components/tables/btable/useTable";
import BaseCommentsModal from "./BaseCommentsModal";

function DocumentCommentsModal({ handleClose, companyId, document }) {
  const {
    dataActions: { updateRow },
  } = useTable();

  const onClose = (comments) => {
    handleClose();
    updateRow({ ...document, comments });
  };

  return (
    <BaseCommentsModal
      handleClose={onClose}
      companyId={companyId}
      itemId={document.id}
      apiCreate={documentsAPI.comment.create}
      apiList={documentsAPI.comment.list}
      apiRemove={documentsAPI.comment.remove}
    />
  );
}

export default DocumentCommentsModal;
