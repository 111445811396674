import React, { useCallback, useRef, useState } from "react";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import _ from "lodash";
import { differenceInSeconds, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { withInitialAsync } from "hooks/useAsync";
import useModal from "hooks/useModal";
import * as companyAPI from "api2/companies";
import { formatDatetime, formatLazyDuration } from "utils/date";
import ExportProgressModal from "./ExportProgressModal";

function ExpirationTimer({ expirationDate }) {
  const { t } = useTranslation("company");
  const diffSeconds = differenceInSeconds(parseISO(expirationDate), new Date());
  if (diffSeconds < 0) {
    return null;
  }
  return (
    <OverlayTrigger
      trigger={["hover", "click"]}
      placement="left"
      overlay={
        <Tooltip id={String(_.uniqueId("tooltip_"))}>
          {t("psd.expiresOn", { when: formatDatetime(expirationDate) })}
        </Tooltip>
      }
    >
      <div>
        <i className="fe-clock" />
        <span className="pl-1">{formatLazyDuration(diffSeconds)}</span>
      </div>
    </OverlayTrigger>
  );
}

function PrepareSection({ item, onPrepare }) {
  const { t } = useTranslation("company");
  if (item.allow_upload) {
    return null;
  }
  if (item.export_id) {
    return (
      <div>
        <Button variant="link" onClick={() => onPrepare(item.category)}>
          {t("actions.prepareAgain")}
        </Button>
      </div>
    );
  }
  return (
    <div>
      <Button type="button" size="sm" variant="secondary" onClick={() => onPrepare(item.category)}>
        {t("actions.prepareExport")}
      </Button>
    </div>
  );
}

function TTUploadSection({ item, companyId, financialYearId, reload }) {
  const [uploading, setUploading] = useState(false);
  const { category, export_id: exportId } = item;
  const { t } = useTranslation("common");
  const fileRef = useRef(null);
  const onDelete = useCallback(() => {
    return companyAPI.exporter
      .remove(companyId, exportId)
      .then(() => {
        toast.success(t("msg:deleted"));
        reload();
      })
      .catch(() => {});
  }, [companyId, exportId, reload, t]);
  const onUpload = useCallback(
    (event) => {
      event.preventDefault();
      const fileTypes = ["application/pdf", "se", ""];
      const selectedFile = (event.target.files || event.dataTransfer.files)[0];
      if (selectedFile) {
        if (!fileTypes.includes(selectedFile.type)) {
          toast.error(t("common:file.notSupported", { formats: fileTypes }), {
            autoClose: 4000,
          });
          return;
        }
        setUploading(true);
        companyAPI.exporter
          .upload(companyId, selectedFile, financialYearId, category)
          .then((response) => {
            toast.success(t("msg:saved"));
            reload();
          })
          .catch((error) => {
            if (error.data.__all__) {
              toast.error(error.data.__all__);
            }
          })
          .finally(() => {
            setUploading(false);
          });
      }
    },
    [companyId, financialYearId, category, reload, t]
  );
  if (!item.allow_upload) {
    return null;
  }
  if (item.export_id && item.status === "success") {
    return (
      <div>
        <Button type="button" size="sm" variant="red" onClick={onDelete}>
          <i className="fe-trash-2" />
        </Button>
      </div>
    );
  }
  return (
    <div className="upload-btn">
      <Button type="button" size="sm" variant="secondary" disabled={uploading} onClick={() => fileRef.current.click()}>
        {uploading ? <i className="fas fa-spinner fa-spin" /> : <i className="fe-upload" />}
      </Button>
      <input ref={fileRef} type="file" onChange={onUpload} />
    </div>
  );
}

function InfoSection({ item }) {
  const { t } = useTranslation("company");
  if (item.export_id) {
    if (item.status === "success") {
      return (
        <span className="pl-1 prepared">
          <i className="fe-check" /> {t("common:prepared")} {formatDatetime(item.created)}
        </span>
      );
    }
    if (item.status === "expired") {
      return (
        <span className="pl-1 prepared">
          {t("common:expired")} {formatDatetime(item.expiration_date)}
        </span>
      );
    }
    if (item.status === "pending") {
      return (
        <span className="pl-1 prepared">
          <i className="fe-refresh-cw" /> {t("exporter.notifyWhenReady", { email: item.created_by_email })}
        </span>
      );
    }
  }
  return null;
}

function ExportWidget({ companyId, financialYearId, reload, data }) {
  const { t } = useTranslation();
  const progressModal = useModal();
  const prepareExport = useCallback(
    (category) => {
      progressModal.open({ companyId, financialYearId, category });
    },
    [progressModal, companyId, financialYearId]
  );
  const onModalClose = useCallback(
    (generated) => {
      reload();
      progressModal.close();
      if (generated) {
        toast.success(t("msg:generated"));
      }
    },
    [reload, progressModal, t]
  );

  const getFile = useCallback(
    (exportId, category) => {
      companyAPI.exporter
        .getDownloadLink(companyId, exportId, category)
        .then((response) => {
          const url = response.data.link;
          const element = document.createElement("a");
          element.target = "_blank";
          element.href = url;
          document.body.appendChild(element);
          element.click();
          element.remove();
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          if (error.status.code === 404) {
            toast.error(t("msg:permissionError"));
          }
        });
    },
    [t, companyId]
  );

  return (
    <>
      <Table>
        <tbody>
          {data.map((item) => (
            <tr key={item.category} className={`export export-${item.category}`}>
              <td className="expand" />
              <td className="tw-50">
                <div className="category">
                  <div className="category--title">{item.category_title}</div>
                  <div className="category--action">
                    <PrepareSection item={item} onPrepare={prepareExport} />
                    <TTUploadSection
                      item={item}
                      financialYearId={financialYearId}
                      companyId={companyId}
                      reload={reload}
                    />
                  </div>
                </div>
              </td>
              <td className="tw-50">
                <div className="category">
                  <div>
                    <Button
                      type="button"
                      size="sm"
                      variant="secondary"
                      className="download"
                      disabled={item.status !== "success"}
                      onClick={() => getFile(item.export_id, item.category)}
                    >
                      <i className="fe-download" />
                    </Button>
                    <InfoSection item={item} />
                  </div>
                  <div className="category--expire">
                    {item.status === "success" && <ExpirationTimer expirationDate={item.expiration_date} />}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {progressModal.show && progressModal.data && (
        <ExportProgressModal
          companyId={progressModal.data.companyId}
          financialYearId={progressModal.data.financialYearId}
          category={progressModal.data.category}
          handleClose={onModalClose}
        />
      )}
    </>
  );
}

const EnhancedExportWidget = withInitialAsync(
  ExportWidget,
  ({ companyId, financialYearId }) =>
    React.useCallback(
      (cancelToken) => companyAPI.exporter.financialYearList(companyId, financialYearId, { cancelToken }),
      [companyId, financialYearId]
    ),
  {},
  false
);

const GlobalExportWidget = withInitialAsync(
  ExportWidget,
  ({ companyId }) =>
    React.useCallback((cancelToken) => companyAPI.exporter.documentsList(companyId, { cancelToken }), [companyId]),
  {},
  false
);
export { GlobalExportWidget };
export default EnhancedExportWidget;
