import React, { useState } from "react";
import { parseDate } from "utils/date";
import { Form, Formik, useFormikContext } from "formik";
import { Button, Card, Col, Row } from "react-bootstrap";
import { ConsultPicker, FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import useModal from "hooks/useModal";
import * as companyAPI from "api2/companies";
import * as supportAPI from "api2/office-support";
import * as options from "api/options";
import * as yup from "yup";
import { useCompanyState } from "hooks/useCompany";
import OtherInputSelect from "./OtherOptionSimpleSelect";
import VATPeriodModal from "./VATPeriodModal";

function OfficeSupportAccountingForm({ companyId, changeEditedForm, data }) {
  const { t } = useTranslation("company");
  const [showMore, setShowMore] = useState(true);
  const [vatReportPeriod, setVatReportPeriod] = useState(
    options.vatReportOptions.getLabel(data.vat_report_period) || options.vatReportOptions.getLabel("none")
  );
  const {
    company: { agency_id },
  } = useCompanyState();
  const vatPeriodModal = useModal();
  const accountingSoftwareOptions = options.accountingSoftware.asList();
  const payrollSoftwareOptions = options.payrollSoftware.asList();
  const accountingMethodOptions = [
    { value: "billing", label: t("billing") },
    { value: "cash", label: t("cash") },
  ];
  const bankOptions = options.bankChoices.asList();
  const auditorTitles = options.auditorTitles.asList();

  const softwareAccounting = () => {
    if (data?.software_accounting) {
      return data.software_accounting === "other"
        ? options.accountingSoftware.getOption("other")
        : options.accountingSoftware.getOption(data.software_accounting);
    }
    return null;
  };
  const softwarePayroll = () => {
    if (data?.software_payroll) {
      return data.software_payroll === "other"
        ? options.payrollSoftware.getOption("other")
        : options.payrollSoftware.getOption(data.software_payroll);
    }
    return null;
  };
  const bankName = () => {
    if (data?.bank_name) {
      return data.bank_name === "other"
        ? options.bankChoices.getOption("other")
        : options.bankChoices.getOption(data.bank_name);
    }
    return null;
  };

  const formikProps = {
    initialValues: {
      ...data,
      consult: data.consult && {
        value: data.consult,
        label: data.consult_name,
      },
      software_accounting: softwareAccounting(),
      software_payroll: softwarePayroll(),
      bank_name: bankName(),
      other_software_accounting: data.software_accounting === "other" ? data?.other_software_accounting : "",
      other_software_payroll: data.software_payroll === "other" ? data?.other_software_payroll : "",
      other_bank_name: data.bank_name === "other" ? data?.other_bank_name : "",
      accounting_method: data?.accounting_method || accountingMethodOptions[0].value,
      vat_report_period:
        options.vatReportOptions.getLabel(data.vat_report_period) || options.vatReportOptions.getLabel("none"),
      accounting_month: data.accounting_month ? parseDate(data.accounting_month) : null,
      auditor: {
        haveAuditor: data.annual_report?.data?.auditor?.haveAuditor?.toString() || "1",
        title: data.annual_report?.data?.auditor?.title?.toString() || "",
        surname: data.annual_report?.data?.auditor?.surname || "",
        firstName: data.annual_report?.data?.auditor?.firstName || "",
        name: data.annual_report?.data?.auditor?.name || "",
        email: data.annual_report?.data?.auditor?.email || "",
        phone: data.annual_report?.data?.auditor?.phone || "",
      },
    },
    validationSchema: yup.object().shape({
      consult: yup.object().nullable().required(),
      bank_name: yup.object().nullable().notRequired(),
      other_bank_name: yup.string().when("bank_name", {
        is: (bank_name) => bank_name?.value === "other",
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      other_software_accounting: yup.string().when("software_accounting", {
        is: (software_accounting) => software_accounting?.value === "other",
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      other_software_payroll: yup.string().when("software_payroll", {
        is: (software_payroll) => software_payroll?.value === "other",
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      auditor: yup.object().shape({
        haveAuditor: yup.string(),
        firstName: yup.string().when("haveAuditor", {
          is: "1",
          then: () => yup.string().required(),
          otherwise: () => yup.string().notRequired(),
        }),
        surname: yup.string().when("haveAuditor", {
          is: "1",
          then: () => yup.string().required(),
          otherwise: () => yup.string().notRequired(),
        }),
      }),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      try {
        const accountingDetailsData = {
          consult: values.consult.value,
          bank_name: values.bank_name?.value || null,
          other_bank_name: values.bank_name && values.bank_name.value === "other" ? values.other_bank_name || "" : "",
          software_accounting: values.software_accounting?.value || null,
          other_software_accounting:
            values.software_accounting && values.software_accounting.value === "other"
              ? values.other_software_accounting || ""
              : "",
          software_payroll: values.software_payroll?.value || null,
          other_software_payroll:
            values.software_payroll && values.software_payroll.value === "other"
              ? values.other_software_payroll || ""
              : "",
          accounting_method: values.accounting_method,
        };
        const annualReportData = {
          aboutCompany: data.annual_report?.data.aboutCompany || "",
          eventsDuring: data.annual_report?.data.eventsDuring || "",
          eventsAfter: data.annual_report?.data.eventsAfter || "",
          annual_report_information: data.annual_report?.data.annual_report_information || "",
          auditor: {
            haveAuditor: parseInt(values.auditor.haveAuditor, 10),
            firstName: values.auditor.haveAuditor
              ? values.auditor.firstName || ""
              : data.annual_report?.data?.auditor?.firstName || "",
            surname: values.auditor.haveAuditor
              ? values.auditor.surname || ""
              : data.annual_report?.data?.auditor?.surname || "",
            name: values.auditor.haveAuditor
              ? values.auditor.name || ""
              : data.annual_report?.data?.auditor?.name || "",
            title: values.auditor.haveAuditor
              ? parseInt(values?.auditor?.title, 10) || 1
              : data.annual_report?.data?.auditor?.title || 1,
            email: values.auditor.haveAuditor
              ? values.auditor.email || ""
              : data.annual_report?.data?.auditor?.email || "",
            phone: values.auditor.haveAuditor
              ? values.auditor.phone || ""
              : data.annual_report?.data?.auditor?.phone || "",
          },
          members: data.annual_report?.data.members || [],
        };
        const updateAccountingDetails = supportAPI.accounting.update(companyId, accountingDetailsData);
        const saveAnnualReport = companyAPI.annualReports.update(companyId, annualReportData);
        const promises = [saveAnnualReport, updateAccountingDetails];
        await Promise.all(promises).then((responses) => {
          resetForm({ values });
          toast.success(t("msg:saved"), { autoClose: 2000 });
        });
      } catch (error) {
        toast.error(t("msg:fixErrors"));
        setErrors({ ...error.data });
      }
    },
  };

  function FormUpdateChecker() {
    const { dirty } = useFormikContext();
    React.useEffect(() => {
      changeEditedForm({ id: "accounting-form", dirty });
    }, [dirty]);
    return null;
  }

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, resetForm, dirty, setFieldValue }) => {
        const refreshVATPeriod = async () => {
          await supportAPI.accounting.details(companyId).then((response) => {
            setVatReportPeriod(
              options.vatReportOptions.getLabel(response.data.vat_report_period) ||
                options.vatReportOptions.getLabel("none")
            );
          });
        };
        return (
          <Form>
            <FormUpdateChecker />
            <Card>
              <Card.Header onClick={() => setShowMore((prevValue) => !prevValue)}>
                <Card.Title style={{ color: "#ADADAD", fontWeight: 400, fontSize: "12px" }}>
                  {t("accounting")}
                  <i
                    style={{ textAlign: "right", float: "right" }}
                    className={showMore ? "fas fa-chevron-up fa-1x" : "fas fa-chevron-down fa-1x"}
                  />
                </Card.Title>
              </Card.Header>
              {showMore && (
                <Card.Body className="pt-0">
                  <Row>
                    <Col>
                      <ConsultPicker agencyId={agency_id} label={t("common:customerManager")} required />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <OtherInputSelect
                        label={t("company:bankName")}
                        name="bank_name"
                        options={bankOptions}
                        otherName="other_bank_name"
                        otherLabel={t("title.pleaseSpecifyBank")}
                        otherPlaceholder={t("placeholder.bankName")}
                        isClearable
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <OtherInputSelect
                        name="software_accounting"
                        label={t("softwareAccounting")}
                        options={accountingSoftwareOptions}
                        otherName="other_software_accounting"
                        otherLabel={t("title.pleaseSpecifySoftware")}
                        otherPlaceholder={t("placeholder.softwareName")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <OtherInputSelect
                        name="software_payroll"
                        label={t("softwarePayroll")}
                        options={payrollSoftwareOptions}
                        otherName="other_software_payroll"
                        otherLabel={t("title.pleaseSpecifySoftware")}
                        otherPlaceholder={t("placeholder.softwareName")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup.InlineOptionsSelect
                        name="accounting_method"
                        label={t("accountingMethod")}
                        placeholder="accounting_method"
                        options={accountingMethodOptions}
                      />
                    </Col>
                    <Col>
                      <FormGroup.DateMonthPicker
                        name="accounting_month"
                        label={t("title.accountingMonth")}
                        monthOnly
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="d-flex">
                        {/* BFLAKT-4060 - field not used in Form submit - so tracking it is not needed - issue with dirty */}
                        <FormGroup.Input
                          label={t("company:vatReport")}
                          name="fake_vat_report_period"
                          value={vatReportPeriod}
                          disabled
                        />{" "}
                        <Button variant="link" type="button" className="mt-1" onClick={() => vatPeriodModal.open()}>
                          {t("common:actions.change")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup.LabeledInlineRadio
                        label={t("hasAuditor")}
                        name="auditor.haveAuditor"
                        options={[
                          { value: "1", label: t("common:actions.yes") },
                          { value: "0", label: t("common:actions.no") },
                        ]}
                      />
                    </Col>
                  </Row>
                  {values.auditor?.haveAuditor === "1" && (
                    <>
                      <Row>
                        <Col>
                          <FormGroup.Input label={t("common:contact.firstName")} name="auditor.firstName" required />
                        </Col>
                        <Col>
                          <FormGroup.Input label={t("common:contact.lastName")} name="auditor.surname" required />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup.Input label={t("auditAgencyName")} name="auditor.name" />
                        </Col>
                        <Col />
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup.LabeledInlineRadio
                            label={t("common:role")}
                            name="auditor.title"
                            options={auditorTitles}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup.Input label={t("common:contact.emailLong")} name="auditor.email" />
                        </Col>
                        <Col>
                          <FormGroup.Input label={t("common:contact.phone")} name="auditor.phone" />
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col className="text-right">
                      {dirty && (
                        <Button variant="link" size="lg" onClick={() => resetForm()} className="cancel-btn">
                          {t("common:actions.cancel")}
                        </Button>
                      )}
                      <SubmitButton isSubmitting={isSubmitting} disabled={!dirty} />
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
            {vatPeriodModal.show && (
              <VATPeriodModal
                companyId={companyId}
                handleClose={() => {
                  vatPeriodModal.close();
                  refreshVATPeriod();
                }}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default OfficeSupportAccountingForm;
