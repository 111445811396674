import React from "react";
import { Card } from "react-bootstrap";

import TableProvider from "components/tables/btable/provider";
import { AgencyConsultExtendedTable } from "components/tables";
import * as userAPI from "api2/users";
import { useParams } from "react-router-dom";

function AgencyConsultPermPage({ tableId = "agency-consults" }) {
  const { agencyId } = useParams();
  const initialFilters = {
    is_active: true,
    agency_role: "consult",
  };
  return (
    <TableProvider
      tableId={tableId}
      dataSource={(filters, config) => userAPI.agencies.userList(agencyId, filters, config)}
      initialFilters={initialFilters}
    >
      <Card>
        <Card.Body>
          <AgencyConsultExtendedTable agencyId={agencyId} />
        </Card.Body>
      </Card>
    </TableProvider>
  );
}

export default AgencyConsultPermPage;
