import Select, { components } from "react-select";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Popover } from "react-bootstrap";
import _ from "lodash";
import cx from "classnames";
import * as statusAPI from "api2/status-list";
import { formatDate } from "utils/date";
import "./EventSelect.scss";
import { differenceInCalendarDays } from "date-fns";
import { StatusListStateContext } from "../../../state/providers/StatusListProvider";
import { truncateText } from "../../../utils/text";

export function EventPeriodTag({ period, current, max, deadline, status, popover = false }) {
  const finalised = current === max;
  const diffDays = differenceInCalendarDays(deadline, new Date());
  const showWarn = !finalised && diffDays <= 5;
  const showDanger = !finalised && diffDays <= 0;
  const Content = (
    <div
      className={cx("period-tag", {
        green: finalised,
        purple: current > 1 && current < max,
        "border-warn": showWarn,
        "border-danger": showDanger,
      })}
    >
      <span>{period}</span>
    </div>
  );
  if (!popover) {
    return Content;
  }
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Popover id={String(_.uniqueId("hint_"))} className="popover-deadline">
          <PopoverEventContent
            deadline={deadline}
            status={status}
            finalised={finalised}
            showWarn={showWarn}
            showDanger={showDanger}
          />
        </Popover>
      }
    >
      {Content}
    </OverlayTrigger>
  );
}

function PopoverEventContent({ deadline, status, finalised, showWarn, showDanger }) {
  const { t } = useTranslation("officeSupport");
  return (
    <Popover.Content>
      <div className="status">{t(`statuses.${status}`)}</div>
      <div className="deadline">
        <i className={cx("fe-calendar mr-1", { green: finalised, warn: showWarn, danger: showDanger })} />
        {formatDate(deadline)}
      </div>
    </Popover.Content>
  );
}

function SingleValue({ children, periodIndicator, extended, ...props }) {
  const { t } = useTranslation("officeSupport");
  const { data, options, deadline, inModal } = props;
  const max = options.length;
  return (
    <>
      <EventPeriodTag
        period={periodIndicator}
        max={max}
        current={data.index}
        status={data.value}
        deadline={deadline}
        popover
      />
      <components.SingleValue {...props}>
        {inModal
          ? truncateText(t(`statuses.${data.value}`), 25)
          : data.index < max && (
              <div className="status-tag">
                {extended ? (
                  t(`statuses.${data.value}`)
                ) : (
                  <>
                    <span>{data.index}</span>
                    <span>/{max}</span>
                  </>
                )}
              </div>
            )}
      </components.SingleValue>
    </>
  );
}

function formatOptionLabel({ value, label, ...props }) {
  const { periodIndicator, index, max } = props;
  return (
    <>
      <EventPeriodTag period={periodIndicator} current={index} max={max} />
      <span>{label}</span>
    </>
  );
}

function EventSelect({ eventId, current, options, deadline, periodIndicator, extended, inModal }) {
  const { updateEvent } = useContext(StatusListStateContext);
  const { t } = useTranslation("officeSupport");
  const onChange = (selected) => {
    statusAPI.events.updateStatus(eventId, selected.value);
    updateEvent(eventId, { status: selected.value });
  };
  return (
    <Select
      className="status-list-select"
      classNamePrefix="select"
      menuPlacement="auto"
      formatOptionLabel={(props) => formatOptionLabel({ periodIndicator, max: options.length, ...props })}
      components={{
        SingleValue: (props) => (
          <SingleValue
            periodIndicator={periodIndicator}
            deadline={deadline}
            extended={extended}
            inModal={inModal}
            {...props}
          />
        ),
      }}
      onChange={onChange}
      options={options.map((item) => ({ ...item, label: t(`statuses.${item.value}`) }))}
      isSearchable={false}
      styles={{
        menuPortal: (base) => ({ ...base }),
        menu: (provided, state) => ({
          ...provided,
          minWidth: 280,
        }),
        control: (base) => ({
          ...base,
          border: 0,
          boxShadow: "none",
        }),
      }}
      value={current}
    />
  );
}

export default EventSelect;
