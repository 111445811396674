import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useResolvedPath } from "react-router-dom";
import useTable from "components/tables/btable/useTable";
import { SearchFilter } from "components/filters";
import { Button, ButtonGroup } from "react-bootstrap";

function OfferFilters({ companyId }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");
  const path = useResolvedPath("").pathname;
  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} />
        <ButtonGroup aria-label="Status filter" className="status-filter">
          <Button
            variant="toggle"
            active={!filters.mode || filters.mode === "all"}
            onClick={() => setFilters({ mode: "" })}
          >
            {t("common:statuses.all")}
          </Button>
          <Button
            variant="toggle-red-grey"
            active={filters.mode === "active"}
            onClick={() => setFilters({ mode: "active" })}
          >
            {t("common:statuses.active2")}
          </Button>
          <Button
            variant="toggle-green"
            active={filters.mode === "accepted"}
            onClick={() => setFilters({ mode: "accepted" })}
          >
            {t("common:statuses.accepted")}
          </Button>
          <Button
            variant="toggle-grey"
            active={filters.mode === "denied"}
            onClick={() => setFilters({ mode: "denied" })}
          >
            {t("common:statuses.denied")}
          </Button>
        </ButtonGroup>
      </section>
      <section className="table-filters-right">
        <Link to={`${path}/form`} className="btn btn-blue">
          <i className="fas fa-plus" /> {t("common:actions.create")}
        </Link>
      </section>
    </>
  );
}

export default OfferFilters;
