import client from "api2/client";

export function list(filters, config) {
  return client.get(`/users/`, {
    params: filters,
    ...config,
  });
}

export function details(userId, config) {
  return client.get(`/users/${userId}/`, {
    ...config,
  });
}

export function save(data, config) {
  if (data.id) {
    return client.put(
      `/users/${data.id}/update/`,
      {
        ...data,
      },
      config
    );
  }
  return client.post(
    `/users/create/`,
    {
      ...data,
    },
    config
  );
}

export function deactivate(userId, config) {
  return client.delete(`/users/${userId}/deactivate/`, {
    ...config,
  });
}

export function consultToggleFavourite(companyId, config) {
  return client.patch(`/users/toggle-company-favourite/`, null, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export const agencies = {
  userSave: (agencyId, data, config) => {
    if (data.id) {
      return client.put(
        `/users/for-agency/${data.id}/update/`,
        {
          ...data,
        },
        { headers: { "x-agency": agencyId }, ...config }
      );
    }
    return client.post(
      `/users/for-agency/create/`,
      {
        ...data,
      },
      { headers: { "x-agency": agencyId }, ...config }
    );
  },
  userList: (agencyId, filters, config) => {
    return client.get(`/users/for-agency/`, {
      headers: { "x-agency": agencyId },
      params: filters,
      ...config,
    });
  },
  userDetails: (agencyId, userId, config) => {
    return client.get(`/users/for-agency/${userId}/`, { headers: { "x-agency": agencyId }, ...config });
  },
  disconnect: (userId, agencyId, config) => {
    return client.delete(`/users/for-agency/${userId}/disconnect/`, {
      headers: { "x-agency": agencyId },
      ...config,
    });
  },
};
