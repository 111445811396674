import React, { useContext, useEffect } from "react";
import { Card } from "react-bootstrap";

import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import { withInitialAsync } from "hooks/useAsync";
import * as companyApi from "api2/companies";
import { OnboardingAccountMatchingForm } from "components/forms/company-settings-forms/SIEFileForm/AccountMatchingForm";
import _ from "lodash";
import { OnboardingDispatchContext } from "../../provider/OnboardingProvider";

function AccountMappingStep({ data, companyId, agencyId, initialLoading }) {
  const { fetchDetails } = useContext(OnboardingDispatchContext);

  const onSuccess = () => {
    fetchDetails(true);
  };

  useEffect(() => {
    if (!initialLoading) {
      fetchDetails();
    }
  }, [initialLoading, fetchDetails]);

  return (
    <Card className="account-mapping-step" data-testId="account-mapping-step">
      <Card.Body>
        <StepHeader />
      </Card.Body>
      <OnboardingAccountMatchingForm
        companyId={companyId}
        agencyId={agencyId}
        mapping={data.mapping}
        companyAccounts={data.companyAccounts}
        onSuccess={onSuccess}
      />
    </Card>
  );
}

const EnhancedAccountMappingStep = withInitialAsync(
  AccountMappingStep,
  ({ companyId, agencyId }) => {
    return React.useCallback(
      (cancelToken) =>
        companyApi.onboardings.steps.saveAccounting(companyId, agencyId, { cancelToken }).then((response) => {
          const accounts = response.data.accounts.map((account) => ({
            ...account,
            value: account.number,
            label: `${account.number} - ${account.name}`,
          }));
          const byId = _.keyBy(accounts, (account) => account.number);
          response.data = {
            mapping: response.data.partially_mapped_accounts.map((item) => ({
              ...item,
              bflow_account: item.bflow_account_number ? byId[item.bflow_account_number] : null,
            })),
            companyAccounts: {
              byId,
              asOptions: accounts,
            },
          };
          return response;
        }),
      [companyId, agencyId]
    );
  },
  {},
  true
);

export default EnhancedAccountMappingStep;
