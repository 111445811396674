import React, { useCallback } from "react";
import useTable from "components/tables/btable/useTable";
import useAsync from "hooks/useAsync";
import { Loader } from "components/ui/loaders";
import * as assetAPI from "api2/assets";
import AssetForm from "./AssetForm";

function AssetEditWidget({ companyId, assetId }) {
  const {
    dataActions: { reload, updateRow },
  } = useTable();
  const onSuccessUpload = (updatedData) => {
    updateRow(updatedData, "id");
  };
  const getAsset = useCallback(
    (cancelToken) =>
      assetAPI.get(companyId, assetId).then((response) => {
        return response.data;
      }),
    [companyId, assetId]
  );
  const [{ data: asset, loading: loadingAsset }] = useAsync(getAsset, {});
  if (loadingAsset) {
    return <Loader />;
  }

  return (
    <AssetForm
      companyId={companyId}
      asset={asset}
      successCallback={reload}
      deleteCallback={reload}
      onSuccessUpload={onSuccessUpload}
    />
  );
}

export default AssetEditWidget;
