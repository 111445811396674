import React from "react";
import { Alert, Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FieldArray, Form, Formik } from "formik";
import { toast } from "react-toastify";

import { SubmitButton } from "components/ui/buttons";
import { FormGroup, TableGroup } from "components/formik";
import * as companyAPI from "api2/companies";

function TimeReportingForm({ company, data, projects }) {
  const { t } = useTranslation("company");
  const options = [
    { label: t("timeReportOption0"), value: "0" },
    { label: t("timeReportOption1"), value: "1" },
  ];

  if (!company) {
    return null;
  }
  if (company && !company.time_reporting_enabled) {
    return (
      <div className="mt-2">
        <Alert variant="warning">{t("timeReportWarn")}</Alert>
      </div>
    );
  }

  const formikProps = {
    initialValues: {
      time_report_access_option: `${data.time_report_access_option}`,
      employees: data.employees.map((e) => ({
        ...e,
        projects_ids: e.projects_ids.map((p) => projects.find((p2) => p2.value === p)).filter((item) => !!item),
      })),
    },
    onSubmit: async (values, { setErrors }) => {
      companyAPI.timeReports
        .update(company.id, {
          time_report_access_option: values.time_report_access_option,
          employees: values.employees.map((e) => ({
            id: e.id,
            projects_ids: e.projects_ids.map((p) => p.value),
          })),
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            setErrors(error.data);
          }
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting }) => {
        return (
          <Form>
            <Card>
              <Card.Body>
                <FormGroup.LabeledRadio
                  title={t("common:options")}
                  name="time_report_access_option"
                  options={options}
                />
                {values.time_report_access_option === "1" && (
                  <FieldArray
                    name="employees"
                    render={(arrayHelper) => (
                      <div>
                        <Table bordered>
                          <thead>
                            <tr>
                              <th style={{ width: 400 }}>{t("common:employee")}</th>
                              <th>{t("navigation:projects")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.employees.map((employee, index) => (
                              <tr key={index}>
                                <td>{employee.user_name}</td>
                                <TableGroup.SimpleSelect
                                  options={projects}
                                  isMulti
                                  isClearable
                                  placeholder={`${t("common:actions.select")}...`}
                                  name={`employees[${index}].projects_ids`}
                                />
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  />
                )}
              </Card.Body>
              <Card.Footer>
                <SubmitButton isSubmitting={isSubmitting} />
              </Card.Footer>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default TimeReportingForm;
