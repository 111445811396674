import { NewProductModal } from "components/modals/invoices";
import React, { useState } from "react";
import { Button, ButtonGroup, Table } from "react-bootstrap";

import { TableGroup } from "components/formik";
import { BSelectAsync } from "components/formik/pickers";
import { formatMoney, roundMoney } from "utils/money";
import { getCustomerCountryArea } from "utils/countries";
import { formatDate } from "utils/date";
import _ from "lodash";
import * as selectAPI from "api/select";
import * as ciAPI from "api/customer-invoices";
import useModal from "hooks/useModal";
import { confirmInfo } from "components/modals/ConfirmModal";

import { calculateRecordTotal, getSummaryNet, getSummaryVat, getTaxReduction } from "utils/calc/ci";
import { filterActiveCC, filterActiveProjects } from "utils/others";
import * as options from "api/options";

const vatOptions = options.vatOptions.asList();

const isReversedVATAllowed = (customer) => {
  if (!customer) {
    return false;
  }
  const countryArea = getCustomerCountryArea(customer.CountryCode);
  const isOutsideEU = countryArea === "outside_eu";
  const isCustomerCompany = customer.DebtorType !== "Private";
  const hasVATNumber = customer.VatNumber;
  return isCustomerCompany && (!!hasVATNumber || isOutsideEU);
};

const isReversedVATInitiallyTrue = (customer) => {
  if (!customer) {
    return false;
  }
  const countryArea = getCustomerCountryArea(customer.CountryCode);
  if (countryArea === "sweden") {
    return false;
  }
  const isOutsideEU = countryArea === "outside_eu";
  const isCustomerCompany = customer.DebtorType !== "Private";
  const hasVATNumber = customer.VatNumber;
  return isCustomerCompany && (!!hasVATNumber || isOutsideEU);
};

function handlePrice(prices, originalPrices, record, group) {
  let field = "";
  if (group === "B") {
    field = "b_unit_price";
  } else if (group === "C") {
    field = "c_unit_price";
  } else if (group === "D") {
    field = "d_unit_price";
  }
  const price = prices[record.ProductPublicId];
  if (!field || !price) {
    return originalPrices[record.ProductPublicId];
  }
  return price[field] || originalPrices[record.ProductPublicId];
}

async function onChangePriceGroup(priceGroup, prices, originalPrices, setOriginalPrices, values, setFieldValue) {
  setFieldValue("price_group", priceGroup);
  values.records
    .filter((record) => record.RecordType === "Standard")
    .map((record) => {
      if (!originalPrices[record.ProductPublicId]) {
        originalPrices[record.ProductPublicId] = record.UnitPrice;
        setOriginalPrices(originalPrices);
      }
      record.UnitPrice = handlePrice(prices, originalPrices, record, priceGroup.value);
      return record;
    });
}

const isVatDisabled = (company, values) => {
  return !!company.vat_disabled || !!values.reversed_vat;
};
const getValuesOnReverseVatChange = (isChecked, customer, values, connectedProducts) => {
  return {
    ...values,
    customer,
    reversed_vat: isChecked,
    reversed_vat_receiver_no: isChecked ? customer.VatNumber || "" : "",
    records: values.records.map((r) => {
      if (r.RecordType === "Standard") {
        return {
          ...r,
          VAT: isChecked
            ? vatOptions[0]
            : options.vatOptions.getOption(connectedProducts?.[r.ProductPublicId]?.VAT || r.VAT.value),
          BookKeepingAccount: connectedProducts?.[r.ProductPublicId]?.BookKeepingAccount || r.VAT.BookKeepingAccount,
        };
      }

      return { ...r };
    }),
  };
};

async function onReversedVatChange(isChecked, customer, values, setValues, connectedProducts) {
  const newValues = getValuesOnReverseVatChange(isChecked, customer, values, connectedProducts);
  setValues(newValues);
}

const onCustomerReset = (setFieldValue, values, setValues, connectedProducts, rotRutEnabled = false) => {
  // remove RotRut data
  values = {
    ...values,
    rot_rut: {
      Customers: [],
    },
    records: values.records.map((record) => {
      delete record.RotRutAmount;
      delete record.RotRutActivated;
      delete record.RotRutHours;
      delete record.RotRutMaterialCostAmount;
      delete record.RotRutType;
      delete record.WorkStart;
      delete record.WorkEnd;
      return record;
    }),
  };
  onReversedVatChange(false, null, values, setValues, connectedProducts);
};
const onCustomerChange = async (
  company,
  customer,
  values,
  setValues,
  setFieldValue,
  t,
  connectedProducts = {},
  prices = {},
  originalPrices = {},
  setOriginalPrices = null,
  forContract = false
) => {
  const countryArea = getCustomerCountryArea(customer && customer.CountryCode);
  const newValues = {
    ...values,
    our_reference: customer ? customer.DefaultActionConfig.OurReference || "" : "",
    your_reference: customer ? customer.ContactName : "",
    price_group:
      !forContract && customer?.Notes?.PriceGroup
        ? options.priceGroups.getOption(customer.Notes.PriceGroup)
        : options.priceGroups.getOption("A"), // only A for contracts
  };
  if (!forContract) {
    newValues.payment_terms =
      customer &&
      customer.DefaultActionConfig.InvoicePaymentTermsInDays &&
      customer.DefaultActionConfig.InvoicePaymentTermsInDays !== 365
        ? options.paymentTerms.getOption(customer.DefaultActionConfig.InvoicePaymentTermsInDays)
        : options.paymentTerms.getOption(company.default_invoice_payment_terms);
  }
  if (!values.id) {
    newValues.delivery_method = customer.DefaultActionConfig.DeliveryMethod || "Email";
  }
  const _newValuesOnReverseVatChange = getValuesOnReverseVatChange(
    isReversedVATInitiallyTrue(customer), // if reverse is allowed and customer is not from sweden then set is a true
    customer,
    newValues,
    connectedProducts
  );
  Object.keys(_newValuesOnReverseVatChange).forEach((key) => {
    newValues[key] = _newValuesOnReverseVatChange[key];
  });

  const isCustomerPrivate = customer && customer.DebtorType === "Private";
  if (countryArea === "within_eu" && !isCustomerPrivate) {
    ciAPI.checkVatVies(company.id, customer.VatNumber).then((response) => {
      if (!response.data.valid) {
        confirmInfo(t("ci:vatEuBecomeInvalid"));
      }
    });
  }
  setValues(newValues, true);
  // logic (price groups) not used in contracts
  if (!forContract && newValues.price_group.value !== values.price_group.value) {
    await onChangePriceGroup(
      newValues.price_group,
      prices,
      originalPrices,
      setOriginalPrices,
      newValues,
      setFieldValue
    );
  }
};

function RecordsForm({ values, currency, arrayHelper, forCreditInvoice, t }) {
  const standardRecords = values.records.filter((item) => item.RecordType === "Standard");
  const customerLang = values.customer ? values.customer?.DefaultActionConfig?.CommunicationLanguage : "SV";
  const summaryNet = getSummaryNet(standardRecords);
  const summaryVat = getSummaryVat(values.reversed_vat, standardRecords);
  const basis = summaryNet + summaryVat;
  const taxReduction = getTaxReduction(standardRecords);
  const summaryTotal = summaryNet + summaryVat + taxReduction;
  const summaryTotalRounded = roundMoney(summaryTotal, 0);
  const centRounding = summaryTotalRounded - summaryTotal;

  return (
    <Table bordered size="sm" className="records">
      <thead>
        <tr>
          <th style={{ width: "10%", minWidth: 100 }}>{t("artNo")}</th>
          <th style={{ width: "40%" }}>{t("common:description")}</th>
          <th style={{ width: "5%", minWidth: 100 }}>{t("common:money.quantity")}</th>
          <th style={{ width: "5%", minWidth: 100 }}>{t("unit")}</th>
          <th style={{ width: "5%", minWidth: 200 }} className="text-right">
            {t("common:money.unitPrice")} ({currency})
          </th>
          <th style={{ width: "5%", minWidth: 100 }}>{t("common:money.vat")}</th>
          <th style={{ width: "5%", minWidth: 100 }}>{t("common:money.discount")}</th>
          <th style={{ width: "10%", minWidth: 190 }} className="text-right">
            {t("common:money.total")}
          </th>
          <th style={{ width: "5%", minWidth: 120 }} />
        </tr>
      </thead>
      <tbody>
        {values.records
          .filter((e) => !["RotRutMsg"].includes(e.ArticleNumber))
          .map((record, index) => (
            <tr key={record.key}>
              {
                {
                  Message: (
                    <>
                      <td />
                      <TableGroup.Input
                        tdProps={{ colSpan: 7 }}
                        placeholder={t("freeTextRow")}
                        name={`records[${index}].ArticleDescription`}
                      />
                    </>
                  ),
                  Standard: (
                    <>
                      <td className="disabled">
                        <span>{record.ArticleNumber}</span>
                      </td>
                      <TableGroup.Input name={`records[${index}].ArticleDescription`} disabled={forCreditInvoice} />
                      {forCreditInvoice ? (
                        <TableGroup.MoneyInput // BFLAKT-3857  use Money for handle it with comma only
                          name={`records[${index}].Quantity`}
                          onBlur={(e) => {
                            if (record.maxQtyForCredit && e.target.value >= record.maxQtyForCredit) {
                              arrayHelper.replace(index, {
                                ...record,
                                Quantity: record.maxQtyForCredit,
                              });
                            }
                          }}
                          maxvalue={record.maxQtyForCredit ? record.maxQtyForCredit : undefined}
                        />
                      ) : (
                        <TableGroup.MoneyInput name={`records[${index}].Quantity`} /> // BFLAKT-3857
                      )}
                      <TableGroup.Input name={`records[${index}].Units`} disabled={forCreditInvoice} />
                      <TableGroup.MoneyInput name={`records[${index}].UnitPrice`} disabled={forCreditInvoice} />
                      <TableGroup.SimpleSelect isDisabled name={`records[${index}].VAT`} options={vatOptions} />
                      <td className="text-right disabled">
                        <span>
                          {record.DiscountType ? (
                            <>
                              {record.Discount} {record.DiscountType === "Amount" ? currency : "%"}
                            </>
                          ) : (
                            "-"
                          )}
                        </span>
                      </td>
                      <td className="text-right disabled">
                        <span>
                          {formatMoney(calculateRecordTotal(record), 2, 2)} {currency}
                        </span>
                      </td>
                    </>
                  ),
                }[record.RecordType]
              }
              <td className="text-center">
                <ButtonGroup>
                  <Button
                    variant="toggle"
                    size="sm"
                    onClick={() => arrayHelper.swap(index, index - 1)}
                    disabled={index === 0}
                  >
                    <i className="fas fa-long-arrow-alt-up" />
                  </Button>
                  <Button
                    variant="toggle"
                    size="sm"
                    onClick={() => arrayHelper.swap(index, index + 1)}
                    disabled={index === values.records.length - 1}
                  >
                    <i className="fas fa-long-arrow-alt-down" />
                  </Button>
                  <Button variant="toggle" size="sm" onClick={() => arrayHelper.remove(index)}>
                    <i className="fas fa-trash" />
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        {values.reversed_vat && (
          <tr>
            <td colSpan={9} className="p-1">
              <i>
                {customerLang === "EN"
                  ? "Reverse VAT according to chapter 1 2§ first section 4b in the VAT regulation"
                  : "Omvänd moms enligt 1 kap. 2 § 1 st. 4b Momsförordningen"}
              </i>
            </td>
          </tr>
        )}
        {taxReduction !== 0 && (
          <tr>
            <td colSpan={9} className="p-1">
              <i>
                {customerLang === "EN"
                  ? "We reserve the right to invoice the remaining amount, corresponding to the tax reduction, if the request from Skatteverket is rejected."
                  : "Vi förbehåller oss rätten att fakturera återstående belopp, motsvarande skattereduktionen, om begäran från Skatteverket avslås."}
              </i>
            </td>
          </tr>
        )}
        {values?.rot_rut?.WorkStart && values?.rot_rut?.WorkEnd && (
          <tr>
            <td colSpan={9} className="p-1">
              <i>
                {customerLang === "EN"
                  ? `Time period when work was performed: From ${formatDate(values?.rot_rut?.WorkStart)} to ${formatDate(values.rot_rut.WorkEnd)}`
                  : `Tidsperiod då arbetet utfördes: Från ${formatDate(values?.rot_rut?.WorkStart)} till ${formatDate(values.rot_rut.WorkEnd)}`}
              </i>
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={7} className="text-right">
            {t("common:money.net")}
          </td>
          <td className="text-right">
            {formatMoney(summaryNet, 2, 2)} {currency}
          </td>
          <td />
        </tr>
        <tr>
          <td colSpan={7} className="text-right">
            {t("common:money.vat")}
          </td>
          <td className="text-right">
            {formatMoney(summaryVat, 2, 2)} {currency}
          </td>
          <td />
        </tr>
        {centRounding !== 0 && (
          <tr>
            <td colSpan={7} className="text-right">
              {t("common:money.centRounding")}
            </td>
            <td className="text-right">
              {formatMoney(centRounding, 2, 2)} {currency}
            </td>
            <td />
          </tr>
        )}
        {taxReduction !== 0 && (
          <tr>
            <td colSpan={7} className="text-right">
              {t("basisTaxReduction")}
            </td>
            <td className="text-right">
              {formatMoney(basis)} {currency}
            </td>
            <td />
          </tr>
        )}
        {taxReduction !== 0 && (
          <tr>
            <td colSpan={7} className="text-right">
              {t("taxReduction")}
            </td>
            <td className="text-right">
              {formatMoney(taxReduction)} {currency}
            </td>
            <td />
          </tr>
        )}
        <tr>
          <td colSpan={7} className="text-right">
            <strong>{t("common:money.totalInclVat")}</strong>
          </td>
          <td className="text-right">
            <strong>
              {formatMoney(summaryTotalRounded, 2, 2)} {currency}
            </strong>
          </td>
          <td />
        </tr>
      </tfoot>
    </Table>
  );
}

// TODO DOUBLE TEST?RECHECK CYRRENCY - CI CURRENCY PROVIDE INDEASTED VALUES
function RecordsTool({
  t,
  companyId,
  arrayHelper,
  prices,
  priceGroup,
  vatDisabled,
  rotRutEnabled,
  discountModal,
  rotRutModal,
  ccProjModal,
  centerOptions,
  projectOptions,
  connectedProducts,
  setConnectedProducts,
  originalPrices,
  setOriginalPrices,
  forCreditInvoice,
  forOffer = false,
  forOrder = false,
  bookingDate,
}) {
  const productModal = useModal();
  const activeProjects = filterActiveProjects(projectOptions, bookingDate);
  const activeCenters = centerOptions.filter(filterActiveCC);
  const showProjCCBtn = !forOffer && !forOrder && (activeProjects.length || activeCenters.length);
  const [productsCacheKey, setProductsCacheKey] = useState(_.uniqueId());

  const appendProduct = (product) => {
    originalPrices[product.ProductPublicId] = product.UnitPrice;
    setOriginalPrices(originalPrices);
    connectedProducts[product.ProductPublicId] = product;
    setConnectedProducts(connectedProducts);
    arrayHelper.push({
      RecordType: "Standard",
      ProductPublicId: product.ProductPublicId,
      ProductType: product.ProductType,
      ArticleNumber: product.ArticleNumber,
      ArticleDescription: product.Description,
      Quantity: 1,
      ProductExternalId: product.ProductExternalId,
      BflowCostCenter: product.ProductExternalId ? parseInt(product.ProductExternalId, 10) : null,
      key: _.uniqueId("ln"),
      Units: product.Units,
      UnitPrice: handlePrice(
        prices,
        originalPrices,
        {
          ProductPublicId: product.ProductPublicId,
          UnitPrice: product.UnitPrice,
        },
        priceGroup.value
      ),
      VAT: vatDisabled ? vatOptions[0] : options.vatOptions.getOption(product.VAT) || vatOptions[0],
      BookKeepingAccount: product.BookKeepingAccount,
    });
  };

  const onProductCreate = () => {
    // refresh options
    setProductsCacheKey(_.uniqueId());
    productModal.close();
  };

  const appendText = () => {
    arrayHelper.push({
      key: _.uniqueId("ln"),
      RecordType: "Message",
      ArticleDescription: "",
    });
  };

  return (
    <>
      <div className="mb-4 records-tool">
        <div>
          <BSelectAsync
            key={productsCacheKey} // allow refreshing products if new product added
            className="react-select product-select"
            field={{ name: "product" }}
            menuPosition="portal"
            closeMenuOnSelect={false}
            isDisabled={forCreditInvoice}
            minSearchLength={0}
            maxMenuHeight={400}
            loadOptions={(params, callback) =>
              selectAPI.getCompanyProducts(
                companyId,
                { ...params, mode: "active", ordering: "-ArticleNumber" },
                callback
              )
            }
            placeholder={t("actions.selectProduct")}
            value={null}
            styles={{
              menu: (provided) => ({ ...provided, width: 500 }),
            }}
            onChange={appendProduct}
          />
          <Button variant="secondary" className="text-row" onClick={productModal.open}>
            <i className="fas fa-plus" /> {t("actions.addProduct")}
          </Button>
          <Button variant="secondary" className="text-row" onClick={appendText}>
            <i className="fas fa-comment" /> {t("textRow")}
          </Button>
        </div>
        <div>
          <ButtonGroup>
            <Button variant="secondary" onClick={discountModal.open}>
              <i className="fas fa-percent" /> {t("common:money.discount")}
            </Button>
            {showProjCCBtn && (
              <Button variant="secondary" onClick={ccProjModal.open}>
                <i className="fas fa-align-justify" /> {t("common:ccAndProj")}
              </Button>
            )}
            {rotRutEnabled && (
              <Button variant="secondary" onClick={rotRutModal.open}>
                <i className="fas fa-home" /> {t("rotRut")}
              </Button>
            )}
          </ButtonGroup>
        </div>
      </div>
      {productModal.show && (
        <NewProductModal companyId={companyId} onSuccess={onProductCreate} onCancel={productModal.close} />
      )}
    </>
  );
}

export {
  onCustomerChange,
  getValuesOnReverseVatChange,
  onChangePriceGroup,
  handlePrice,
  onCustomerReset,
  onReversedVatChange,
  isVatDisabled,
  isReversedVATAllowed,
  RecordsTool,
  RecordsForm,
};
