import React, { useContext } from "react";
import { TimeTrackerStateContext } from "state/providers/TimeTrackerProvider";
import UserMenu from "./UserMenu";
import AgencySwitcher from "./AgencySwitcher";
import NavBarLeftSide from "./NavBarLeftSide";

function TTNavBar() {
  const { selected, agencies, selectAgency } = useContext(TimeTrackerStateContext);
  return (
    <div className="navbar-custom tt-layout">
      <NavBarLeftSide company={null} forDashboard={false} />
      <ul className="list-unstyled topnav-menu float-right mb-0">
        <li>
          <AgencySwitcher selected={selected} agencies={agencies} onSelect={selectAgency} />
        </li>
        <li className="dropdown">
          <UserMenu />
        </li>
      </ul>
    </div>
  );
}

export default TTNavBar;
