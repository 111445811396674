import React, { useCallback, useContext, useEffect } from "react";
import { Form, Formik } from "formik";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useAsync from "hooks/useAsync";
import { SIEFileForm } from "components/forms/company-settings-forms";
import { FormGroup } from "components/formik";
import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import { SaveButton } from "pages/companies/OnboardingWizard/utils";
import {
  OnboardingDispatchContext,
  OnboardingStateContext,
} from "pages/companies/OnboardingWizard/provider/OnboardingProvider";
import * as companyAPI from "api2/companies";

function ImportAccountingStep({ companyId, agencyId }) {
  const { sieImported } = useContext(OnboardingStateContext);
  const { fetchDetails } = useContext(OnboardingDispatchContext);

  const onSIEImported = useCallback(() => {
    fetchDetails(true);
  }, [fetchDetails]);

  const dataSource = useCallback(
    (cancelToken) => companyAPI.onboardings.steps.importAccounting(companyId, agencyId, { cancelToken }),
    [companyId, agencyId]
  );

  const [{ loading }] = useAsync(dataSource, []);

  useEffect(() => {
    if (!loading) {
      fetchDetails();
    }
  }, [loading, fetchDetails]);

  React.useEffect(() => {
    // After change time tracker active task in calendar, update task on navbar as well
    document.body.addEventListener("SIE/imported", onSIEImported);
    return () => document.body.removeEventListener("SIE/imported", onSIEImported);
  }, [onSIEImported]);

  const { t } = useTranslation("company");

  const formikProps = {
    initialValues: {
      importSIE: sieImported || false,
    },
    onSubmit: () => {
      return companyAPI.onboardings.steps.importAccountingSkip(companyId, agencyId).then(() => {
        fetchDetails(true);
      });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, handleSubmit }) => (
        <Card className="import-accounting-step" data-testid="import-accounting-step">
          <Card.Body>
            <StepHeader
              rightComponent={!values.importSIE && <SaveButton disabled={isSubmitting} onSave={handleSubmit} />}
            />
          </Card.Body>
          <Card.Body>
            <Form noValidate>
              <FormGroup.LabeledInlineYesNoRadio name="importSIE" label={t("onboarding.wantImportSIE")} />
            </Form>
            {values.importSIE && <SIEFileForm companyId={companyId} forOnboarding />}
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
}

export default ImportAccountingStep;
