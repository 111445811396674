import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as options from "api/options";
import { useAuthState } from "hooks/useAuth";
import BTable from "components/tables/btable";
import { truncateText } from "utils/text";
import Filters from "./CompanyFilters";
import Actions from "./CompanyActions";

function RedLabelLink({ count, link, selectCompany, companyId }) {
  return (
    <span className="font-16">
      {count === 0 ? (
        count
      ) : (
        <Button
          variant="link"
          className="text-danger font-16 font-weight-bolder"
          onClick={() => selectCompany(companyId, link)}
        >
          {count}
        </Button>
      )}
    </span>
  );
}

function CompanyTable({ forStaff, selectCompany }) {
  const { t } = useTranslation("company");
  const {
    user: { id: userId, show_offices_filter: showOfficesFilter },
  } = useAuthState();

  const getPath = (objectType) => {
    let path;
    switch (objectType) {
      case "si":
        path = "supplier-invoices";
        break;
      case "out":
        path = "outlays";
        break;
      case "dp":
        path = "direct-payments";
        break;
      case "sal":
        path = "salaries";
        break;
      case "tax_dp":
        path = "taxes";
        break;
      case "abs_rep":
        path = "employee-absences";
        break;
      default:
        path = "supplier-invoices";
    }
    return path;
  };
  const headers = useMemo(() => {
    const _headers = [
      {
        field: "name",
        label: t("common:name"),
        className: "text-left",
        width: 200,
        render: (row) => (
          <Button variant="link" onClick={() => selectCompany(row.id)} title={row.name}>
            {truncateText(row.name, 19)}
          </Button>
        ),
      },
      {
        field: "org_no",
        label: t("common:orgNo"),
      },
    ];
    if (!forStaff) {
      return [
        ..._headers,
        {
          field: "draft_outlays_count",
          label: t("draftOutlayCount"),
          canSort: false,
          render: (row) => (
            <RedLabelLink
              count={row.summary.draft_outlays_count}
              link={`/reports/outlays?mode=draft&created_by=${userId}`}
              companyId={row.id}
              selectCompany={selectCompany}
            />
          ),
        },
        {
          field: "to_approve_count",
          label: t("toApproveCount"),
          canSort: false,
          render: (row) => (
            <RedLabelLink
              count={row.summary.to_approve_count}
              link={`/approve/${getPath(row.summary.to_approve_start_with)}`}
              companyId={row.id}
              selectCompany={selectCompany}
            />
          ),
        },
        {
          field: "to_pay_count",
          label: t("toPayCount"),
          canSort: false,
          render: (row) => (
            <RedLabelLink
              count={row.summary.to_pay_count}
              link={`/pay/${getPath(row.summary.to_pay_start_with)}`}
              companyId={row.id}
              selectCompany={selectCompany}
            />
          ),
        },
        {
          field: "conditional_last_reconciliation_date",
          canSort: false,
          label: t("manage.lastRecon"),
          className: "text-right",
          width: 110,
          render: (row) => row.summary.conditional_last_reconciliation_date || "",
        },
      ];
    }
    if (forStaff) {
      return [
        {
          field: "id",
          label: "#",
          canSort: true,
        },
        ..._headers,
        {
          field: "bank_name",
          label: t("common:bank"),
          width: 100,
          render: (row) =>
            row.bank_name === "other"
              ? row.other_bank_name || ""
              : options.bankChoices.getOption(row.bank_name)?.label || "",
        },
        {
          field: "unbooked_archives_count",
          label: t("unbookedArchives"),
          className: "text-right",
          width: 180,
          render: (row) =>
            row.summary.unbooked_archives_count ? (
              <Button variant="link" onClick={() => selectCompany(row.id, "/consult/file-archive?mode=new")}>
                {row.summary.unbooked_archives_count}
              </Button>
            ) : (
              ""
            ),
        },
        {
          field: "draft_si_count",
          label: t("draftSi"),
          className: "text-right",
          width: 100,
          render: (row) =>
            row.summary.draft_si_count ? (
              <Button variant="link" onClick={() => selectCompany(row.id, "/consult/supplier-invoices?mode=draft")}>
                {row.summary.draft_si_count}
              </Button>
            ) : (
              ""
            ),
        },
        {
          field: "investigate_si_count",
          label: t("investigateSi"),
          className: "text-right",
          width: 100,
          render: (row) =>
            row.summary.investigate_si_count ? (
              <Button
                variant="link"
                onClick={() => selectCompany(row.id, "/consult/supplier-invoices?mode=investigate")}
              >
                {row.summary.investigate_si_count}
              </Button>
            ) : (
              ""
            ),
        },
        {
          field: "unpaid_si_inter_count",
          label: t("unpaidSi"),
          className: "text-right",
          width: 100,
          render: (row) =>
            row.summary.unpaid_si_inter_count ? (
              <Button
                variant="link"
                onClick={() => selectCompany(row.id, "/consult/supplier-invoices?mode=unpaid&foreign=1")}
              >
                {row.summary.unpaid_si_inter_count}
              </Button>
            ) : (
              ""
            ),
        },
        {
          field: "investigate_outlay_count",
          label: t("investigateOutlays"),
          className: "text-right",
          width: 100,
          render: (row) =>
            row.summary.investigate_outlay_count ? (
              <Button variant="link" onClick={() => selectCompany(row.id, "/consult/outlays?mode=investigate")}>
                {row.summary.investigate_outlay_count}
              </Button>
            ) : (
              ""
            ),
        },
        {
          field: "unmatched_bank_count",
          sortField: "consult_info__unmatched_bank_count",
          label: t("common:bank"),
          className: "text-right",
          getRowClass: (row) => {
            return `text-right ${row.scanBankStatus}`;
          },
          width: 80,
          render: (row) =>
            !row.is_sole_type && row.summary.unmatched_bank_count ? (
              <Button variant="link" onClick={() => selectCompany(row.id, "/consult/bank-matching")}>
                {row.summary.unmatched_bank_count}
              </Button>
            ) : (
              ""
            ),
        },
        {
          field: "unmatched_tax_count",
          sortField: "consult_info__unmatched_tax_count",
          label: t("common:tax"),
          className: "text-right",
          width: 80,
          getRowClass: (row) => {
            return `text-right ${row.scanTaxStatus}`;
          },
          render: (row) =>
            row.summary.unmatched_tax_count ? (
              <Button variant="link" onClick={() => selectCompany(row.id, "/consult/bank-matching/1630")}>
                {row.summary.unmatched_tax_count}
              </Button>
            ) : (
              ""
            ),
        },
        {
          field: "last_reconciliation_date",
          label: t("manage.lastRecon"),
          width: 110,
          className: "text-right",
          getRowClass: (row) => {
            return `text-right ${row.lastReconStatus}`;
          },
          render: (row) => row.last_reconciliation_date || "",
        },
        {
          field: "consult_name",
          label: t("common:customerManager"),
          sortField: "consult__first_name",
          render: (row) => row.consult_name || "",
        },
      ];
    }
    _headers.push({
      field: "consult_name",
      label: t("common:customerManager"),
      sortField: "consult__first_name",
      render: (row) => row.consult_name || "",
    });
    return _headers;
  }, [t, forStaff, selectCompany, userId]);

  return (
    <BTable
      headers={headers}
      tableClassName="sticky-header"
      tableResponsive={false}
      FiltersComponent={<Filters forStaff={forStaff} showOfficesFilter={showOfficesFilter} />}
      renderActions={(row) => <Actions row={row} forStaff={forStaff} userId={userId} />}
    />
  );
}

export default CompanyTable;
