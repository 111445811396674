import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import * as reportAPI from "api2/reports";
import TaxDirectPaymentForm from "components/forms/TaxDirectPaymentForm";
import addBusinessDays from "date-fns/addBusinessDays";
import useInitialAsync from "hooks/useInitialAsync";
import { RocketLoader } from "components/ui/loaders";

function TaxDirectPaymentAddPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const taxDirectPayment = {
    balanceBefore: 0,
    booking_date: new Date(),
    payment_date: addBusinessDays(new Date(), 1),
    amount: 0,
    amount_currency: "SEK",
  };
  const { loading, item } = useInitialAsync(() =>
    reportAPI.accountGlobalBalance(company.id, { account: 1630 }).then((response) => {
      if (response.data.length) {
        return response.data[0].balance || 0;
      }
      return 0;
    })
  );

  const onCreate = (newItem) => {
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }

  return (
    <TaxDirectPaymentForm
      companyId={company.id}
      taxOCR={company.ocr_tax}
      taxDirectPayment={{
        ...taxDirectPayment,
        balanceBefore: item || 0,
      }}
      onSave={onCreate}
    />
  );
}

export default TaxDirectPaymentAddPage;
