import React from "react";

import * as companyAPI from "api2/companies";
import useTable from "components/tables/btable/useTable";
import { withInitialAsync } from "hooks/useAsync";
import ProjectForm from "./ProjectForm";

function ProjectEditWidget({ data: item, companyId, id }) {
  const {
    dataActions: { reload, updateRow },
  } = useTable();

  const successCallback = (newData) => {
    updateRow(newData);
  };

  const deleteCallback = () => {
    reload();
  };

  return (
    <ProjectForm
      companyId={companyId}
      project={item}
      successCallback={successCallback}
      deleteCallback={deleteCallback}
    />
  );
}

const EnhancedProjectEditWidget = withInitialAsync(
  ProjectEditWidget,
  ({ companyId, id }) =>
    React.useCallback((cancelToken) => companyAPI.projects.details(companyId, id, { cancelToken }), [companyId, id]),
  {},
  false
);

export default EnhancedProjectEditWidget;
