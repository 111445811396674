import { useTranslation } from "react-i18next";
import React from "react";
import { Modal } from "react-bootstrap";
import "./VerificationPreview.scss";

import { DraggableModalDialog } from "../DraggableModalDialog";
import BaseVerificationsPreviewContainer from "./BaseVerificationsPreviewContainer";
import { RocketLoader } from "../../ui/loaders";

function BaseVerificationsPreviewModal({ handleClose, verifications, topText = "", isLoading = false, errors = null }) {
  const { t } = useTranslation("ver");
  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={() => handleClose()}
      dialogClassName="task-modal"
      dialogAs={DraggableModalDialog}
      size="xl"
      className="verification-preview-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("previewVers")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <RocketLoader />
        ) : (
          <BaseVerificationsPreviewContainer verifications={verifications} errors={errors} topText={topText} />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default BaseVerificationsPreviewModal;
