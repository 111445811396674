import "react-checkbox-tree/lib/react-checkbox-tree.css";
import React, { useCallback, useState } from "react";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import * as agencyAPI from "api2/agencies";
import useAsync from "hooks/useAsync";
import { SubmitButton } from "components/ui/buttons";
import { Loader } from "components/ui/loaders";
import { codesForAgency } from "components/perms/PermCodes";
import cx from "classnames";
import { AGENCY_CONSULT_PERMISSION } from "utils/permissions/agencyConsultPermissions";

function ConsultPermissionsForm({ agencyId, consultId, permsChecked, setPermsChecked, t, language, handleModalClose }) {
  const formikProps = {
    initialValues: { permissions_options: AGENCY_CONSULT_PERMISSION },
    onSubmit: async (values, { setErrors }) => {
      return agencyAPI.permissions
        .update(agencyId, consultId, { checked: permsChecked })
        .then(() => {
          handleModalClose();
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  const parentPerm = codesForAgency.enabled;

  const onCheck = (value, isChecked) => {
    setPermsChecked((prevPerms) => {
      if (isChecked) {
        return parentPerm === value ? [] : prevPerms.filter((perm) => perm !== value);
      }

      return [...prevPerms, value];
    });
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting }) => {
        return (
          <Form className="agency-consult-perm-form">
            <div className="permissions">
              {values.permissions_options.map(({ value, label }) => {
                const isChecked = permsChecked.includes(value);
                const isDisabled = !isChecked && value !== parentPerm && !permsChecked.includes(parentPerm);

                return (
                  <div
                    key={value}
                    className={cx("permission-wrapper", {
                      checked: isChecked,
                      disabled: isDisabled,
                    })}
                  >
                    <div className="checkbox-wrapper">
                      <button
                        onClick={() => onCheck(value, isChecked)}
                        disabled={isDisabled}
                        type="button"
                        className="checkbox"
                      />
                    </div>

                    {label[language]}
                  </div>
                );
              })}
            </div>

            <SubmitButton isSubmitting={isSubmitting} icon="" />
          </Form>
        );
      }}
    </Formik>
  );
}

function ConsultPermissionsWrapper({ agencyId, consultId, handleModalClose }) {
  const [permsChecked, setPermsChecked] = useState([]);
  const {
    t,
    i18n: { language },
  } = useTranslation("company");

  const getCheckedPermissions = useCallback(
    () =>
      agencyAPI.permissions.detail(agencyId, consultId).then((response) => {
        setPermsChecked(response.data.checked);
      }),
    [agencyId, consultId]
  );

  const [{ loading: loadingPermissions }] = useAsync(getCheckedPermissions, []);

  if (loadingPermissions) {
    return <Loader />;
  }

  return ConsultPermissionsForm({
    agencyId,
    consultId,
    permsChecked,
    setPermsChecked,
    t,
    language,
    handleModalClose,
  });
}

export default ConsultPermissionsWrapper;
