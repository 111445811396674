import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { SubmitButton } from "components/ui/buttons";
import _ from "lodash";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { AllError, FormGroup } from "components/formik";
import * as options from "api/options";
import * as vatAPI from "api2/vat";
import { formatDate } from "utils/date";
import { addMonths, subDays } from "date-fns";

function isYearlyVat(eventType) {
  return _.includes(["vat_yearly_eu", "vat_yearly_se"], eventType.value);
}

function EventForm({ companyId, event, onSave }) {
  const { t } = useTranslation("common");
  const eventTypes = options.eventTypes.asList();
  const formikProps = {
    initialValues: {
      ...event,
      event_type: options.eventTypes.getOption(event.event_type),
    },
    validationSchema: yup.object().shape({
      date_start: yup
        .date()
        .nullable()
        .when("event_type", ([eventType], schema) => {
          return isYearlyVat(eventType) ? schema : schema.required();
        }),
      due_date: yup.date().nullable().required(),
      date_end: yup
        .date()
        .nullable()
        .required()
        .when("date_start", ([startDate], schema) => {
          return startDate ? schema.min(startDate, t("common:errors.mustLaterStartDate")) : schema;
        }),
    }),
    onSubmit: async (values, { setErrors }) => {
      await vatAPI.events
        .create({
          event_type: values.event_type.value,
          due_date: formatDate(values.due_date),
          date_end: formatDate(values.date_end),
          date_start: values.date_start ? formatDate(values.date_start) : null,
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (onSave) {
            onSave(response.data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors, setValues }) => {
        return (
          <Form id="salaryForm">
            <Card>
              <Card.Body>
                <Row>
                  <Col xl={4} lg={6}>
                    <FormGroup.SimpleSelect
                      options={eventTypes}
                      name="event_type"
                      label={t("common:eventType")}
                      onChange={(selected) => {
                        if (selected.value === "vat_quarterly") {
                          setValues({ event_type: selected, date_start: null, date_end: null, due_date: null });
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  {(() => {
                    switch (values.event_type.value) {
                      case "vat_yearly_eu":
                      case "vat_yearly_se":
                        return (
                          <Col xl={2} lg={4}>
                            <FormGroup.DatePicker name="date_end" label={t("others:fYearEnds")} required />
                          </Col>
                        );
                      case "vat_quarterly":
                        return (
                          <Col xl={2} lg={4}>
                            <FormGroup.DateQuarterPicker
                              name="date_start"
                              label={t("common:starts")}
                              required
                              onChange={(starts) => {
                                const ends = subDays(addMonths(starts, 3), 1);
                                setValues({ ...values, due_date: ends, date_end: ends, date_start: starts });
                              }}
                            />
                          </Col>
                        );
                      default:
                        return (
                          <>
                            <Col xl={2} lg={4}>
                              <FormGroup.DatePicker name="date_start" label={t("common:starts")} required />
                            </Col>
                            <Col xl={2} lg={4}>
                              <FormGroup.DatePicker name="date_end" label={t("common:ends")} required />
                            </Col>
                          </>
                        );
                    }
                  })()}
                </Row>
                {values.event_type.value !== "vat_quarterly" && (
                  <Row>
                    <Col xl={2} lg={4}>
                      <FormGroup.DatePicker name="due_date" label={t("common:dates.dueDate")} required />
                    </Col>
                  </Row>
                )}
                <AllError errors={errors} />
              </Card.Body>
              <Card.Footer>
                <SubmitButton isSubmitting={isSubmitting} />
              </Card.Footer>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default EventForm;
