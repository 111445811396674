import client from "api/client";
import _ from "lodash";

export function onNewFileChange({ file, index, allFiles, setFile, max = 6 }) {
  let newData;

  if (Array.isArray(file) && file.length) {
    const newFiles = file.map((_file) => ({ key: _.uniqueId("nd."), file: _file }));
    newData = [...allFiles.slice(0, index), ...newFiles, ...allFiles.slice(index + 1)];
  } else if (file) {
    newData = [
      ...allFiles.map((d, di) => {
        return di !== index
          ? d
          : {
              ...d,
              file,
            };
      }),
    ];
  } else {
    newData = [...allFiles.slice(0, index), ...allFiles.splice(index + 1)].filter((_file) => {
      return "file" in _file;
    });
  }

  if ((allFiles.length < max && allFiles.length - 1 === index) || !newData.length) {
    newData.push({ key: _.uniqueId("nd.") });
  }
  setFile(newData);
}

export function uploadOffer(companyId, offerId, files) {
  const formData = new FormData();
  files.forEach((file) => {
    if (file) {
      formData.append("file", file, file.name);
    }
  });
  return client.post(`/companies/${companyId}/upload/offer/${offerId}/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
