import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import BTable from "components/tables/btable";
import { ConsultDocumentsLink } from "components/ui/buttons/DocumentsButton";
import * as options from "api/options";
import cx from "classnames";
import Actions from "./ConsultArchiveActions";
import Filters from "./ConsultArchiveFilters";
import { formatMonth } from "../../../utils/date";
import { truncateText } from "../../../utils/text";

function ConsultArchiveTable({ companyId, selectable = false, setCounter, reloadIndicators }) {
  const { t } = useTranslation("common");

  const headers = useMemo(
    () => [
      {
        field: "title",
        label: t("common:name"),
        canSort: true,
        render: (values) => (
          <>
            {values.has_file ? (
              <ConsultDocumentsLink companyId={companyId} documentsIds={[values.id]} text={values.title || "-"} />
            ) : (
              values.title
            )}
            <small className="ml-1 text-muted font-11" title={values.description}>
              {truncateText(values.description, 130)}
            </small>
          </>
        ),
      },
      {
        field: "category",
        label: t("common:category"),
        canSort: true,
        render: (values) => options.consultArchiveCategories.getLabel(values.category),
      },
      {
        field: "month",
        label: t("common:dates.month"),
        canSort: true,
        render: (values) => (values.month ? formatMonth(values.month) : "-"),
      },
    ],
    [t, companyId]
  );

  const getRowProps = (row) => {
    return {
      className: cx({ archived: !!row.is_archived }),
    };
  };

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      FiltersComponent={<Filters companyId={companyId} />}
      renderActions={(row) => (
        <Actions companyId={companyId} row={row} setCounter={setCounter} reloadIndicators={reloadIndicators} />
      )}
      getRowProps={getRowProps}
    />
  );
}

export default ConsultArchiveTable;
