import React from "react";
import { Modal } from "react-bootstrap";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import CompanyConsultPermDetailedForm from "./CompanyConsultPermDetailedForm";

function CompanyConsultPermDetailedModal({ companyData, consultId, agencyId, handleClose }) {
  return (
    <Modal show onHide={handleClose} size="xl" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton className="no-border-bottom" />
      <Modal.Body>
        <CompanyConsultPermDetailedForm companyData={companyData} agencyId={agencyId} consultId={consultId} />
      </Modal.Body>
    </Modal>
  );
}

export default CompanyConsultPermDetailedModal;
