import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-locize-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { locizePlugin } from "locize";

const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const DETECTION_OPTIONS = {
  order: ["localStorage"],
  caches: ["localStorage"],
};

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECTID,
  version: process.env.REACT_APP_LOCIZE_VERSION,
  referenceLng: "en",
};

i18n
  // locize-lastused
  // sets a timestamp of last access on every translation segment on locize
  // -> safely remove the ones not being touched for weeks/months
  // https://github.com/locize/locize-lastused
  // .use(LastUsed)
  // locize-editor
  // InContext Editor of locize
  .use(locizePlugin)
  // i18next-locize-backend
  // loads translations from your project, saves new keys to it (saveMissing: true)
  // https://github.com/locize/i18next-locize-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: DETECTION_OPTIONS,
    fallbackLng: ["sv", "en"],
    ns: [
      "common",
      "msg",
      "navigation",
      "ci",
      "si",
      "company",
      "options",
      "others",
      "outlay",
      "reports",
      "salary",
      "tax",
      "tt",
      "ver",
      "officeSupport",
    ],
    defaultNS: "common",
    debug: isDevelopment,
    nsSeparator: ":",
    suspense: true,
    backend: locizeOptions,
    saveMissing: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
