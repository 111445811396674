import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useTable from "components/tables/btable/useTable";
import OfficeForm from "./OfficeForm";

function OfficeAddWidget({ agencyId }) {
  const { t } = useTranslation("others");
  const {
    dataActions: { reload },
  } = useTable();

  const office = {
    name: "",
    manager: null,
  };

  return (
    <Row>
      <Col>
        <Card className="card-border" id="create-customer-section">
          <Card.Header className="border-success">
            <Card.Title>{t("actions.createOffice")}</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <OfficeForm agencyId={agencyId} office={office} onSuccess={reload} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default OfficeAddWidget;
