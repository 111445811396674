import client from "./client";

export const locals = {
  list: (companyId, filters, config) =>
    client.get(`/suppliers/local/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  save: (companyId, data, config) => {
    if (data.id) {
      return client.put(`/suppliers/local/${data.id}/update/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    }
    return client.post(`/suppliers/local/create/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  details: (companyId, companySupplierId, config) =>
    client.get(`/suppliers/local/${companySupplierId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  remove: (companyId, companySupplierId, config) =>
    client.delete(`/suppliers/local/${companySupplierId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  toggleActive: (companyId, companySupplierId, config) =>
    client.patch(`/suppliers/local/${companySupplierId}/toggle-active/`, null, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
};

export const globals = {
  list: (filters, config) =>
    client.get(`/suppliers/global/`, {
      params: filters,
      ...config,
    }),
  details: (supplierId, config) =>
    client.get(`/suppliers/global/${supplierId}/`, {
      ...config,
    }),
  save: (data, config) => {
    if (data.id) {
      return client.put(`/suppliers/global/${data.id}/update/`, data, {
        ...config,
      });
    }
    return client.post(`/suppliers/global/create/`, data, {
      ...config,
    });
  },
  pullDataFromBGC: (bgNumber, config) =>
    client.get(`/suppliers/global/bgc-data/`, {
      params: { bankgiro_number: bgNumber },
      ...config,
    }),
};
